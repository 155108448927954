/** @format */

import { REPORT_TYPE } from 'apps/wms/utils/enum'
import {
	getApplicationMethodList,
	getEntranceDateList,
	getManufacturerList,
	heatNumberColumn,
	inspStatusColumn,
	reportColumns
} from './columns'
import { remarksColumn } from './columns'
import { measurementColumns } from './columns'
import { instrumentsColumn } from './columns'
import { customerItemColumn } from './columns'
import { salesItemColumn } from './columns'
import { materialDescColumn } from './columns'
import { ownershipColumn } from './columns'
import { ageColumn } from './columns'
import { lengthColumn } from './columns'
import { weightColumn } from './columns'
import { jointsColumn } from './columns'
import { entranceDateColumn } from './columns'
import { attributeColumns } from './columns'
import { mescColumn } from './columns'
import { salesOrderColumn } from './columns'
import { customerOrderColumn } from './columns'
import { imageHandlerColumn } from './columns'
import { inspectorColumn } from './columns'
import { validColumn } from './columns'
import { threadCompoundColumns } from './columns'

const columns = config => {
	getApplicationMethodList(config)
	getManufacturerList(config)
	return config.hasMeasure === true && config.hasThreadCompound === true
		? [validColumn()].concat(
				reportColumns(config),
				inspStatusColumn(config),
				inspectorColumn(config),
				remarksColumn(config),
				imageHandlerColumn(config),
				instrumentsColumn(config),
				threadCompoundColumns(config),
				measurementColumns(config)
		  )
		: config.hasMeasure === true
		? [validColumn()].concat(
				reportColumns(config),
				inspStatusColumn(config),
				inspectorColumn(config),
				remarksColumn(config),
				imageHandlerColumn(config),
				instrumentsColumn(config),
				measurementColumns(config)
		  )
		: config.hasThreadCompound === true
		? [validColumn()].concat(
				reportColumns(config),
				inspStatusColumn(config),
				inspectorColumn(config),
				remarksColumn(config),
				imageHandlerColumn(config),
				instrumentsColumn(config),
				threadCompoundColumns(config)
		  )
		: [validColumn()].concat(
				reportColumns(config),
				inspStatusColumn(config),
				inspectorColumn(config),
				remarksColumn(config),
				imageHandlerColumn(config),
				instrumentsColumn(config)
		  )
}

const AGE_MANAGEMENT_COLUMNS = function (config) {
	getEntranceDateList(config)
	return [
		customerOrderColumn(config),
		customerItemColumn(config),
		salesOrderColumn(config),
		salesItemColumn(config),
		mescColumn(config),
		materialDescColumn(config),
		...attributeColumns(config),
		ownershipColumn(config),
		entranceDateColumn(config),
		ageColumn(config),
		jointsColumn(config),
		lengthColumn(config),
		weightColumn(config)
	]
}

const defualtColumns = [columns]

const RECEIPT_NOTE = function (config) {
	return [
		mescColumn(config),
		materialDescColumn(config),
		validColumn(),
		heatNumberColumn(config),
		lengthColumn(config),
		weightColumn(config)
	]
}

const reports = [
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'wms:Dispatch',
	'wms:BackloadManifest',
	RECEIPT_NOTE,
	AGE_MANAGEMENT_COLUMNS
]

export const getReportColumns = config =>
	!config.isInspection &&
	(config.reportType === REPORT_TYPE.code.AGE_EXTRACTS || config.reportType === REPORT_TYPE.code.MILL_RECEIPT)
		? reports[config.reportType](config)
		: defualtColumns[0](config)
