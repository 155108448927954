/** @format */

import { FlexView } from 'components/common'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import VehicleInfo from '../containers/assets/vehicles/VehicleInfo'
import VehicleList from '../containers/assets/vehicles/VehicleList'
import PageTemplate from '../containers/common/PageTemplate'
import { AssetsTransportContext } from '../stores/AssetsTransportStore'
import { assets, genParams } from '../utils/hubs'

const AssetsTransportPage = () => {
	const { t } = useTranslation()
	const { selectedVehicle } = useContext(AssetsTransportContext)

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:Transport'),
				path: '/wms/assets/transport'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' height='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:Transport')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='55%' margin='0 8px 0 0'>
						<VehicleList />
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						{selectedVehicle && <VehicleInfo />}
					</FlexView>
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default AssetsTransportPage
