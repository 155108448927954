/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	searchPipe: (wid, valid, ippn, co, ci, token) =>
		new Promise((resolve, reject) => {
			if (valid) {
				instance
					.get(`content/${wid}/pipedata?valid=${encodeURIComponent(valid)}`, {
						headers: {
							Authorization: `${token}`
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) resolve(response.data.pipedata)
						else reject(new Error('CouldNotConnect'))
					})
					.catch(err => {
						reject(err.response || err)
					})
			}
			if (ippn) {
				instance
					.get(`content/${wid}/pipedatabyref?ippn=${encodeURIComponent(ippn)}&ponumber=${co}&poitem=${ci}`, {
						headers: {
							Authorization: `${token}`
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) resolve(response.data.pipedata)
						else reject(new Error('CouldNotConnect'))
					})
					.catch(err => {
						reject(err.response || err)
					})
			}
		}),
	getCertificates: token =>
		new Promise((resolve, reject) => {
			instance
				.get('content/certificatelist', {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.certificatelist)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	modifyPipeOrBatch: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`content/${wid}/updatepipedata`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	createCertificate: (params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post('content/save/certificate', params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.certificate)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
