/** @format */

import axios from 'axios'

const newInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/v2/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getReports: (wid, name, numberPerPage, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(
					`inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}&status=open&limit=${
						numberPerPage ? numberPerPage : 50
					}`,
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(response => {
					if (response.status === 200) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMoreReports: (wid, name, numberPerPage, page, token, keyWord) =>
		new Promise((resolve, reject) => {
			const url =
				keyWord != null
					? `inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}&status=open&limit=${
							numberPerPage ? numberPerPage : 50
					  }&page=${page}&search=${encodeURIComponent(keyWord)}`
					: `inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}&status=open&limit=${
							numberPerPage ? numberPerPage : 50
					  }&page=${page}`
			newInstance
				.get(url, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	getSummarizedInfo: (wid, piid, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`inspection/search/${wid}/periodical/${piid}/summarized`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getPeriodicDetails: (wid, type, piid, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`/inspection/search/${wid}/type/${type}/list/${piid}`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	setNewQuantity: (wid, type, inspid, params = {}, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.put(`inspection/execute/${wid}/type/${type}/list/${inspid}/updatecontent`, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	reopenPeriodic: (wid, payload = {}, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.put(`inspection/execute/${wid}/releasecontentinspection`, payload, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	searchInfo: (wid, name, token, searchParam) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(
					`inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(
						name
					)}&status=open&limit=30&search=${encodeURIComponent(searchParam)}`,
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	searchMoreInfo: (wid, name, numberPerPage, page, token, searchParam) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(
					`inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}&status=open&limit=${
						numberPerPage ? numberPerPage : 30
					}&page=${page}&search=${encodeURIComponent(searchParam)}`,
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		})
}
