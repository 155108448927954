/** @format */

/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import { FlexView } from 'components/common'
import Table from 'apps/wms/components/content/Table'

import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { WMSContext } from 'apps/wms/stores/WmsStore'

const AgeManagementItems = ({ issuedItems, height }) => {
	const { t, i18n } = useTranslation()
	const { lengthUnit, weightUnit } = useContext(WMSContext)

	const getAttributeColumns = items =>
		items[0].filtercolumns.map((col, index) => ({
			Header: col.attributedesc,
			width: '80px',
			Cell: ({ cell: { row } }) => (
				<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
					{row.original.filtercolumns[index].attributevalue}
				</FlexView>
			)
		}))

	const columns = useMemo(
		() => [
			{
				Header: t('wms:CustomerOrder'),
				accessor: 'customerpo',
				width: '100px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:CustomerItem'),
				accessor: 'customeritem',
				width: '80px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:SalesOrder'),
				accessor: 'salesorder',
				width: '100px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:SalesItem'),
				accessor: 'salesitem',
				width: '80px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:ErpReference'),
				accessor: 'erpreference',
				width: '100px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:Material'),
				accessor: 'materialdesc',
				width: '300px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{value}
					</FlexView>
				)
			},
			...getAttributeColumns(issuedItems),
			{
				Header: t('wms:Ownership'),
				accessor: 'ownershipdesc',
				width: '100px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:EntranceDate'),
				accessor: 'entrancedate',
				width: '100px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: `${t('wms:Age')}[${t('wms:Months')}]`,
				accessor: 'age',
				width: '80px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{value.toFixed(3)}
					</FlexView>
				)
			},
			{
				Header: `${t('wms:Joints')}/PCS`,
				accessor: 'quantity',
				width: '80px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: `${t('wms:Length')} [${lengthUnit}]`,
				accessor: 'length',
				width: '80px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: `${t('wms:Weight')} [${weightUnit}]`,
				accessor: 'weight',
				width: '80px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
						{value}
					</FlexView>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[issuedItems, t, lengthUnit, weightUnit]
	)

	return (
		<Table
			margin='8px'
			width='100%'
			style={{ backgroundColor: 'white', elevation: 'low', alignSelf: 'center' }}
			columns={columns}
			data={issuedItems}
			fontSize='12px'
			textAlign='center'
			sortable
			data-cy='table-inventory'
			height={`${height}px`}
		/>
	)
}

export default AgeManagementItems
