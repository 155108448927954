/** @format */

import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView } from 'components/common'

import PageTemplate from '../containers/common/PageTemplate'
import { ThreadCompoundContext } from '../stores/AssetsThreadCompoundStore'
import ThreadCompoundTypesList from '../containers/assets/threadCompound/TypesList'
import ApplicationMethodsList from '../containers/assets/threadCompound/MethodsList'
import NewTypeModal from '../containers/assets/threadCompound/NewTypeModal'
import NewApplicationMethod from '../containers/assets/threadCompound/NewMethod'
import { assets, genParams } from '../utils/hubs'

const AssetsThreadCompoundPage = () => {
	const { t } = useTranslation()
	const { loadingConfig } = useContext(ThreadCompoundContext)

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:ThreadCompoundManagement'),
				path: '/wms/assets/threadcompound'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs} loadingOverlay={loadingConfig}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:ThreadCompoundManagement')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<ThreadCompoundTypesList />
					<ApplicationMethodsList />
				</FlexView>
			</FlexView>
			<NewTypeModal />
			<NewApplicationMethod />
		</PageTemplate>
	)
}

export default AssetsThreadCompoundPage
