import React from 'react'
import Admin, { getSideMenu as getAdminSideMenu } from './admin'
import Wms, { getSideMenu as getWmsSideMenu } from './wms'

  export const getSideMenuSettings = (user, redirectTo, translate) => {
    return [
   ...getAdminSideMenu(user, redirectTo, translate),
   ...getWmsSideMenu(user, redirectTo, translate),
  ]
  }

  export default () => <>
  <Admin />
  <Wms />
</>