/** @format */

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import moment from 'moment'
import _ from 'lodash'
import { FlexView, LoadingOverlay, Table, Icon } from 'components/common'
import { CloudLogsContext } from '../../stores/CloudLogsStore'

const StyledTable = styled(Table)`
	background-color: white;
	margin: 0 16px 16px;
	width: calc(100% - 32px);
`

const CloudLogsTable = () => {
	const { t } = useTranslation()
	const { logsObject, setSelectedLog, fetchingLogs, fetchLogs, currentPage, setCurrentPage } =
		useContext(CloudLogsContext)
	const [logEvents, setLogEvents] = useState([])
	const [paginationTokens, setPaginationTokens] = useState({
		prevToken: null,
		nextToken: null
	})

	const columns = useMemo(() => {
		const getMessageProps = log =>
			log.message.length > 300
				? { onClick: setSelectedLog.bind(this, log), style: { cursor: 'pointer', overflow: 'hidden' } }
				: {}

		return [
			{
				Header: t('wms:Date'),
				accessor: row => row.timestamp.format('DD/MM/YYYY'),
				id: 'date',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:Time'),
				accessor: row => row.timestamp.format('HH:mm:ss'),
				id: 'time',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:Type'),
				accessor: 'type',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:Message'),
				accessor: 'message',
				Cell: ({ cell: { value, row } }) => (
					<FlexView {...getMessageProps(row.original)} width='700px'>
						{_.truncate(value, { length: 300, separator: ' ' })}
					</FlexView>
				)
			}
		]
	}, [t, setSelectedLog])

	const handleTableNavigate = (token, nextPage) => {
		if (!token || fetchingLogs) return
		fetchLogs(token)
		setCurrentPage(nextPage)
	}

	useEffect(() => {
		if (!logsObject) {
			setLogEvents([])
			return
		}

		if (currentPage === 1 && !logsObject.events.length) {
			setLogEvents([])
			toast.error(t(`wms:ErrorGettingCloudLogsNotFound`))
			return
		}

		setPaginationTokens({
			prevToken: logsObject.nextbackwardtoken,
			nextToken: logsObject.nextforwardtoken
		})

		if (!logsObject.events.length) return

		const logEvents = _.map(logsObject.events, e => {
			let msgArr = e.message.split(' ')
			return {
				timestamp: moment(e.timestamp, moment.ISO_8601),
				type: msgArr[0],
				message: msgArr.slice(2).join(' ')
			}
		})

		setLogEvents(logEvents)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logsObject])

	return (
		logEvents.length && (
			<>
				<FlexView flexDirection='row' alignItems='center' justifyContent='space-between' margin='16px 0px'>
					{paginationTokens.prevToken && paginationTokens.nextToken && (
						<>
							<Icon
								name='arrow-left'
								height='24px'
								width='24px'
								margin='0px 64px'
								color='gray'
								onClick={handleTableNavigate.bind(this, paginationTokens.prevToken, currentPage - 1)}
							/>
							<Icon
								name='arrow-right'
								height='24px'
								width='24px'
								margin='0px 64px'
								color='gray'
								onClick={handleTableNavigate.bind(this, paginationTokens.nextToken, currentPage + 1)}
							/>
						</>
					)}
					{!paginationTokens.prevToken && !paginationTokens.nextToken && (
						<>
							<Icon
								name='arrow-left'
								height='24px'
								width='24px'
								margin='0px 64px'
								color='lightGray'
								onClick={handleTableNavigate.bind(this, paginationTokens.prevToken, currentPage - 1)}
							/>
							<Icon
								name='arrow-right'
								height='24px'
								width='24px'
								margin='0px 64px'
								color='lightGray'
								onClick={handleTableNavigate.bind(this, paginationTokens.nextToken, currentPage + 1)}
							/>
						</>
					)}
				</FlexView>
				<FlexView margin='0px 16px' position='relative'>
					<StyledTable columns={columns} data={logEvents} />
					<LoadingOverlay visible={fetchingLogs} size='40px' />
				</FlexView>
			</>
		)
	)
}

export default CloudLogsTable
