/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { UserContext } from 'stores/UserStore'
import periodic from '../api/periodic'
import { WMSContext } from './WmsStore'
import _ from 'lodash'
import moment from 'moment'
import assetsInspSettings from '../api/assetsInspSettings'
import { INSPECTION_STAGES } from '../utils/enum'
import generalPerInspFormatter from '../utils/periodic/generalPeriodicInfo'

export const PerInspContext = React.createContext()

export const PerInspectionProvider = ({ children }) => {
	const { token } = useContext(UserContext)
	const { t } = useTranslation()
	const { warehouse, getLastLevelsList, getMaterialsDescpritions } = useContext(WMSContext)
	const [rackName, setRackName] = useState(null)
	const [inspections, setInspections] = useState(null)
	const [selectedInspection, setSelectedInspection] = useState(null)
	const [filteredReports, setFilteredReports] = useState([])
	const [loadingPerInspList, setLoadingPerInspList] = useState(false)
	const [openManageParts, setOpenManageParts] = useState(false)
	const [searchInfo, setSearchInfo] = useState(false)
	const [updateParts, setUpdateParts] = useState(false)
	const [loadingNewPart, setLoadingNewPart] = useState(false)
	const [loadingInfo, setLoadingInfo] = useState(false)
	const [loadingFilters, setLoadingFilters] = useState(false)
	const [uploadPerList, setUploadPerList] = useState(false)
	const [loadingInspections, setLoadingInspections] = useState(false)
	const [loadingReports, setLoadingReports] = useState(false)
	const [fetchingFirstPage, setFetchingFirstPage] = useState(false)
	const [fetchingLastPage, setFetchingLastPage] = useState(false)
	const [canPreviousPage, setCanPreviousPage] = useState(false)
	const [canNextPage, setCanNextPage] = useState(false)
	const [parts, setParts] = useState('')
	const [summarized, setSummarized] = useState({})
	const [now, setNow] = useState(moment().format())
	const [nextThirtyDays, setNextThirtyDays] = useState(moment(now).add('30', 'days').format())
	const [selectedReport, setSelectedReport] = useState(null)
	const [reports, setReports] = useState([])
	const [inspName, setInspName] = useState(null)
	const [reportLinks, setReportLinks] = useState(null)
	const [totalItems, setTotalItems] = useState(null)
	const [totalPages, setTotalPages] = useState(null)
	const [currentPage, setCurrentPage] = useState(1)
	const [inspInfo, setInspInfo] = useState([])
	const [rackList, setRackList] = useState([])
	const [materialList, setMaterialList] = useState([])
	const [keyWord, setKeyWord] = useState('')

	const getInspections = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				console.error(t(`wms:WarehouseNotDefined`))
			} else {
				assetsInspSettings
					.getInspections(warehouse.id, token)
					.then(response => {
						resolve()
						let filteredInspection = _.filter(
							response,
							res => res.enabled === true && res.stage === INSPECTION_STAGES.code.PERIODICAL
						)
						setInspections(filteredInspection)
						setSelectedInspection(filteredInspection)
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorGettingInspections')} [ ${e.status} ]: ${e.data}`)
					})
			}
		})
	const getReports = numbersPerPage =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			setSummarized({})
			let name = 'Periodic Inspection'
			warehouse.id &&
				name !== null &&
				periodic
					.getReports(warehouse.id, name, numbersPerPage, token)
					.then(response => {
						const perInspIdList = response.items.map(res => res.id)
						Promise.allSettled(
							perInspIdList.map(async id => {
								return await periodic.getSummarizedInfo(warehouse.id, id, token)
							})
						).then(listArray => {
							let preList = []
							listArray.forEach(
								(list, index) => list.status === 'fulfilled' && list.value && (preList[index] = list.value)
							)
							const generalPeriodicInfo = generalPerInspFormatter(response.items, preList)
							setReports(generalPeriodicInfo)
							setReportLinks(response.links)
							setTotalItems(response.total_items)
							setTotalPages(response.total_pages)
							setCurrentPage(response.current_page)
							setLoadingReports(false)
						})
						resolve()
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingPerInsp')} [${e.status} ]: ${e.data}`)
						console.error(e)
						reject(e)
						setLoadingReports(false)
					})
		})

	const getMoreReports = (page, numbersPerPage) =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			setSummarized({})
			let name = 'Periodic Inspection'
			periodic
				.getMoreReports(warehouse.id, name, numbersPerPage, page, token, keyWord === '' ? null : keyWord)
				.then(response => {
					const perInspIdList = response.items.map(res => res.id)
					const hydrate = async () => {
						try {
							const materials = await getMaterialsDescpritions()
							Promise.allSettled(
								perInspIdList.map(async id => {
									return await periodic.getSummarizedInfo(warehouse.id, id, token)
								})
							).then(listArray => {
								let preList = []
								listArray.forEach(
									(list, index) => list.status === 'fulfilled' && list.value && (preList[index] = list.value)
								)
								const generalPeriodicInfo = generalPerInspFormatter(response.items, preList, materials)
								setReports(generalPeriodicInfo)
								setReportLinks(response.links)
								setTotalItems(response.total_items)
								setTotalPages(response.total_pages)
								setCurrentPage(response.current_page)
								setLoadingReports(false)
								setFetchingFirstPage(false)
								setFetchingLastPage(false)
							})
						} catch (e) {
							console.error(e)
						}
					}
					hydrate()
					resolve()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingInspections')} [ ${e.status} ]: ${e.data}`)
					setFetchingFirstPage(false)
					setFetchingLastPage(false)
					setLoadingReports(false)
				})
		})

	const getSummarized = id =>
		new Promise((resolve, reject) => {
			setLoadingInfo(true)
			periodic
				.getSummarizedInfo(warehouse.id, id, token)
				.then(response => {
					setSummarized(response)
					resolve()
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingSummarized')} [ ${e.status} ]: ${e.data}`)
					console.error(e)
					setSummarized({})
					setSelectedReport(null)
					reject(e)
				})
				.finally(() => setLoadingInfo(false))
		})

	const setNewQuantity = (params, type, id) =>
		new Promise((resolve, reject) => {
			setUpdateParts(true)
			periodic
				.setNewQuantity(warehouse.id, type, id, params, token)
				.then(response => {
					setSelectedInspection(null)
					resolve(response)
					toast.success(`${t('wms:PeriodicInspectionSuccess')} ${selectedReport.entranceid}`)
					setOpenManageParts(false)
					getReports(30)
				})
				.catch(e => {
					console.error(e)
					toast.error(`${t('wms:ErrorUpdatingParts')} [ ${e.status} ]: ${e.data}`)
					reject(e)
					setOpenManageParts(false)
				})
				.finally(() => setUpdateParts(false))
		})

	const handleReopen = content =>
		new Promise((resolve, reject) => {
			setUploadPerList(true)
			let payload = {
				content_id: content.id
			}
			periodic
				.reopenPeriodic(warehouse.id, payload, token)
				.then(() => {
					toast.success(t(`wms:SuccessfullyReopenContent`))
					resolve(true)
					getReports(30)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorReopenContent')} [ ${e.status} ]: ${e.data}`)
				})
				.finally(() => setUploadPerList(false))
		})

	const searchPeriodicInsp = (name, searchParam) =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			setSummarized({})
			periodic
				.searchInfo(warehouse.id, name, token, searchParam)
				.then(response => {
					resolve(response)
					if (response.items.length === 0) {
						setLoadingReports(false)
						toast.error(t('wms:NoReportsFound'))
					} else {
						const perInspIdList = response.items.map(res => res.id)
						Promise.allSettled(
							perInspIdList.map(async id => {
								return await periodic.getSummarizedInfo(warehouse.id, id, token)
							})
						).then(listArray => {
							let preList = []
							listArray.forEach(
								(list, index) => list.status === 'fulfilled' && list.value && (preList[index] = list.value)
							)
							const generalPeriodicInfo = generalPerInspFormatter(response.items, preList)
							setFilteredReports(generalPeriodicInfo)
							setReportLinks(response.links)
							setTotalItems(response.total_items)
							setTotalPages(response.total_pages)
							setCurrentPage(response.current_page)
							setFetchingFirstPage(false)
							setFetchingLastPage(false)
							setLoadingReports(false)
						})
					}
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingReports')} [ ${e.status} ]: ${e.data}`)
					setLoadingReports(false)
				})
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		setSummarized({})
		setSearchInfo(false)
		setSelectedInspection(null)
		getMaterialsDescpritions().then(list => setMaterialList(list.sort((a, b) => a.id - b.id)))
		getLastLevelsList()
			.then(response => setRackList(response.sort((a, b) => a.id - b.id)))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingInspections(true)
				await getInspections()
			} catch (e) {
				console.error(e)
				setLoadingInspections(false)
			} finally {
				setLoadingInspections(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (keyWord === '') {
			setFilteredReports([])
			getReports()
			setSelectedReport(null)
			setSummarized({})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyWord])

	return (
		<PerInspContext.Provider
			value={{
				rackName,
				setRackName,
				loadingPerInspList,
				setLoadingPerInspList,
				openManageParts,
				setOpenManageParts,
				parts,
				setParts,
				inspections,
				setInspections,
				selectedInspection,
				setSelectedInspection,
				filteredReports,
				setFilteredReports,
				summarized,
				setSummarized,
				searchInfo,
				setSearchInfo,
				updateParts,
				setUpdateParts,
				loadingNewPart,
				setLoadingNewPart,
				now,
				setNow,
				nextThirtyDays,
				setNextThirtyDays,
				loadingInfo,
				setLoadingInfo,
				loadingFilters,
				setLoadingFilters,
				setNewQuantity,
				getInspections,
				handleReopen,
				selectedReport,
				setSelectedReport,
				loadingInspections,
				setLoadingInspections,
				reports,
				setReports,
				getReports,
				inspName,
				setInspName,
				loadingReports,
				setLoadingReports,
				fetchingFirstPage,
				setFetchingFirstPage,
				fetchingLastPage,
				setFetchingLastPage,
				getMoreReports,
				reportLinks,
				setReportLinks,
				totalItems,
				setTotalItems,
				totalPages,
				setTotalPages,
				currentPage,
				setCurrentPage,
				canPreviousPage,
				setCanPreviousPage,
				canNextPage,
				setCanNextPage,
				inspInfo,
				setInspInfo,
				rackList,
				setRackList,
				getSummarized,
				uploadPerList,
				setUploadPerList,
				materialList,
				setMaterialList,
				searchPeriodicInsp,
				keyWord,
				setKeyWord
			}}>
			{children}
		</PerInspContext.Provider>
	)
}
