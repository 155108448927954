/** @format */

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay } from 'components/common'
import { Select, DualListSelect } from 'components/form'
import DialogModal from 'apps/wms/components/forms/DialogModal'

import { UsageRightsContext } from '../stores/UsageRightsStore'
import PageTemplate from '../containers/common/PageTemplate'
import SourceFilter from '../containers/usageRights/SourceFilter'
import { useWindowSize } from '../utils/hooks'
import EndUserSourceFilter from '../containers/usageRights/EndUserSourceFilter'
import _ from 'lodash'
import { WMSContext } from '../stores/WmsStore'
import { toast } from 'react-toastify'
import theme from 'utils/theme'
import { TYPES_OF_RIGHTS, USAGE_RIGHTS_SOURCES } from '../utils/enum'

const UsageRightsPage = () => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const {
		sourceType,
		setSourceType,
		typeOfRight,
		setTypeOfRight,
		availablePipes,
		selectedPipes,
		setSelectedPipes,
		loadingPipes,
		openConfirmModal,
		setConfirmModal,
		levels,
		targetRight,
		setTargetRight,
		changeOwnership,
		changingUsageRights,
		changeEndUser,
		materialsList,
		loadingMaterials,
		targetMaterial,
		setTargetMaterial,
		endusers,
		selectedType,
		setSelectedType,
		selectedReport,
		setSelectedReport,
		reports,
		loadingReports,
		totalSelectedPipes,
		setTotalSelectedPipes,
		sourceMaterialList,
		sourceMaterial,
		setSourceMaterial,
		filteredPipes,
		setFilteredPipes,
		materialsByReport,
		inspections,
		setSourceEnduser
	} = useContext(UsageRightsContext)

	const [pipesToBeVerified, setPipesToBeVerified] = useState([])
	const [targetMaterialList, setTargetMaterialList] = useState([])

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	const ownershipString = t('wms:Ownership')
	const reportsString = t('wms:Reports')
	const sourceMaterialStr = t('wms:SourceMaterial')

	const handleUsageRightsChange = () => {
		if (typeOfRight !== null && typeOfRight === 'OWNERSHIP') {
			changeOwnership()
				.catch(e => console.error(e))
				.finally(() => {
					setTargetRight(null)
				})
		} else {
			changeEndUser()
				.catch(e => console.error(e))
				.finally(() => {
					setTargetRight(null)
				})
		}
	}

	const selectPipes = value => {
		setSelectedPipes(value)
		let pipes =
			typeOfRight === 'END_USER' && sourceType === 'REPORTS'
				? _.filter(filteredPipes, pipe => value.includes(pipe.id))
				: _.filter(availablePipes, pipe => value.includes(pipe.id))
		setPipesToBeVerified(pipes)
		setTotalSelectedPipes(value.length)
	}

	const checkProperties = () => {
		let isDisabled = true
		if (typeOfRight === 'END_USER' && targetMaterial != null && selectedPipes.length) {
			let material = _.find(materialsList, mat => mat.mvid === targetMaterial)
			let erpReference = material ? material.formatted.substring(0, material.formatted.indexOf(' ')) : null
			let unMatchedPipes =
				sourceType != null &&
				sourceType !== 'VALID' &&
				_.some(pipesToBeVerified, pipe => pipe.erpRef !== erpReference)
			if (sourceType === 'VALID' && material && material.mvid !== sourceMaterial && !targetRight && unMatchedPipes)
				isDisabled = true
			else isDisabled = false
		} else if (targetRight === null || (sourceType !== 'VALID' && selectedPipes.length === 0)) isDisabled = true
		else isDisabled = false

		return isDisabled
	}

	const getSourceMaterial = () => {
		const material = _.find(sourceMaterialList, mat => mat.mvid === sourceMaterial)
		return (material && material.formatted) || null
	}

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		setPipesToBeVerified([])
	}, [typeOfRight, sourceType])

	const renderEndUserOptions = () => {
		const material = sourceMaterialList.find(mat => mat.mvid === sourceMaterial)
		const filteredSourceMatList = sourceMaterialList.filter(mat => mat.erpreference === material.erpreference)
		const endUserIds = _.uniq(
			_.map(filteredSourceMatList, eu => {
				return eu.enduserid
			})
		)
		const filteredEndUsers = []
		endusers.forEach(eu => {
			if (endUserIds.includes(eu.id)) filteredEndUsers.push(eu)
		})
		return filteredEndUsers
	}

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		if (sourceType === 'RACK' && targetRight != null) {
			const filteredMatList = materialsList.filter(material => material.enduserid === targetRight)
			setTargetMaterialList(filteredMatList)
		}
	}, [sourceType, targetRight, materialsList])

	useEffect(() => {
		setTargetRight(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setPipesToBeVerified([])
		setFilteredPipes([])
		setSourceEnduser(null)
		setSourceMaterial(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceType])

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:UsageRights'),
				path: '/wms/manage/usagerights'
			}
		],
		[t]
	)

	const handleTypeOfRightsFieldChange = value => {
		setTypeOfRight(value)
		setTotalSelectedPipes(0)
	}

	const handleSourceFieldChange = value => {
		setSourceType(value)
		setTotalSelectedPipes(0)
	}

	const handleSelectedTypeFieldChange = value => {
		setSelectedType(value)
		setTotalSelectedPipes(0)
	}

	const handleSelectedReportFieldChange = value => {
		setSelectedReport(value)
		setTotalSelectedPipes(0)
	}

	const selectTargetMaterial = value => {
		setTargetMaterial(value)
		let unMatchedPipes = false
		let material = _.find(materialsList, mat => mat.mvid === value)
		let erpReference = material ? material.formatted.substring(0, material.formatted.indexOf(' ')) : null
		unMatchedPipes = pipesToBeVerified.some(pipe => pipe.erpRef !== erpReference) || false
		let mismatchMaterial = sourceType === 'VALID' && material && material.id !== sourceMaterial
		if (unMatchedPipes || mismatchMaterial) {
			toast.error(t('wms:UnmatchedPipes'))
		}
	}

	return (
		<PageTemplate crumbs={crumbs} loadingOverlay={changingUsageRights}>
			<FlexView width='100%' maxWidth='calc(100% - 200px)' flex='1' padding='0 0 40px 0'>
				<FlexView
					minWidth='100%'
					fontSize='40px'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 24px 0'
					justifyContent='space-between'>
					{t('wms:UsageRights')}
					<Select
						label={t('wms:TypeOfRights')}
						value={typeOfRight}
						options={TYPES_OF_RIGHTS.map(types => ({
							value: types.value,
							label: t(types.label)
						}))}
						onChange={value => {
							handleTypeOfRightsFieldChange(value)
						}}
						width='30%'
						inline={true}
						margin='0'
						data-cy='select-type-rights'
					/>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='20%' margin='0 8px 0 0'>
						<Card margin='0 0 16px 0' width='calc(100% - 48px)' data-cy='card-source-type'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:TypeOfSource')}
							</FlexView>
							<Select
								placeholder={t('wms:TypeOfSource')}
								value={sourceType}
								options={USAGE_RIGHTS_SOURCES.map(source => ({
									value: source.value,
									label: t(source.label)
								}))}
								onChange={value => {
									handleSourceFieldChange(value)
								}}
								width='100%'
								inline={true}
								margin='0'
								data-cy='select-source-type'
								disabled={typeOfRight === null}
							/>
						</Card>

						<Card margin='0' flex='1' width='calc(100% - 48px)' data-cy='card-source-filter'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:SourceFilters')}
							</FlexView>
							{typeOfRight !== null && typeOfRight === 'OWNERSHIP' && <SourceFilter data-cy='sourcefilter' />}
							{typeOfRight !== null && typeOfRight === 'END_USER' && (
								<EndUserSourceFilter data-cy='sourcefilter' />
							)}
							{sourceType === 'REPORTS' && (
								<>
									<Select
										width='100%'
										searchable
										label={t('wms:ReportType')}
										placeholder={t('wms:ReportType')}
										value={selectedType}
										options={
											inspections
												? inspections.map(inspection => ({
														value: inspection.name,
														label: inspection.name
												  }))
												: ''
										}
										onChange={value => handleSelectedTypeFieldChange(value)}
										margin='8px 0 0 0'
									/>
									<LoadingOverlay visible={loadingReports} borderRadius='card' />
									{reports && (
										<Select
											width='100%'
											searchable
											label={reportsString}
											placeholder={reportsString}
											value={selectedReport}
											options={reports.map(report => ({
												value: report.id,
												label: report.name
											}))}
											onChange={value => handleSelectedReportFieldChange(value)}
											margin='0'
											disabled={selectedType == null}
										/>
									)}
									{typeOfRight === 'END_USER' && (
										<FlexView width='100%' margin='0' padding='0' position='relative'>
											<LoadingOverlay visible={loadingMaterials} borderRadius='card' />
											<Select
												label={sourceMaterialStr}
												placeholder={sourceMaterialStr}
												value={sourceMaterial}
												options={materialsByReport.map(value => ({
													label: value.formatted,
													value: value.mvid
												}))}
												onChange={value => {
													setSourceMaterial(value)
												}}
												width='100%'
												inline={false}
												margin='0'
												data-cy='select-source-material'
												searchable={true}
												disabled={selectedReport === null}
											/>
										</FlexView>
									)}
								</>
							)}
							{typeOfRight === 'END_USER' &&
								(sourceType === 'VALID' || sourceType === 'REPORTS') &&
								getSourceMaterial() && (
									<FlexView flexDirection='column' width='100%' margin='8px 0 0 0'>
										<FlexView fontSize='tiny' color='metalic' fontWeight='bold' margin='16px 16px 0px 0px'>
											{sourceMaterialStr}
										</FlexView>
										<FlexView
											margin='8px 0 0 0'
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											<FlexView width='100%' fontSize='small'>
												{getSourceMaterial()}
											</FlexView>
										</FlexView>
									</FlexView>
								)}
						</Card>
					</FlexView>

					<FlexView flex='2' height='100%' margin='0 0 0 8px' width='60%'>
						<Card
							width='calc(100% - 48px)'
							flex='1'
							margin='0'
							ref={listRef}
							style={{ position: 'relative' }}
							data-cy='card-pipes-list'>
							<FlexView style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
								<FlexView width='90%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
									{t('wms:AvailablePipes')}
								</FlexView>
								<FlexView fontWeight='bold' fontSize='medium' width='500px' alignItems='flex-end'>
									{t('wms:TotalSelectedPipes') + `: ${totalSelectedPipes}`}
								</FlexView>
							</FlexView>
							<LoadingOverlay visible={loadingPipes} borderRadius='card' />
							{(typeOfRight === 'END_USER' || typeOfRight === 'OWNERSHIP') &&
								sourceType !== 'REPORTS' &&
								availablePipes.length && (
									<FlexView flex='1' margin='0' width='100%'>
										<DualListSelect
											searchable={true}
											width='100%'
											height={`${listHeight}px`}
											margin='0'
											selectedValues={selectedPipes}
											options={availablePipes.map(pipe => ({
												label: pipe.valid,
												value: pipe.valid
											}))}
											onChange={value => {
												selectPipes(value)
											}}
											data-cy='dualselect-pipes-list'
										/>
									</FlexView>
								)}{' '}
							{(typeOfRight === 'END_USER' || typeOfRight === 'OWNERSHIP') &&
								sourceType === 'REPORTS' &&
								filteredPipes.length && (
									<FlexView flex='1' margin='0' width='100%'>
										<DualListSelect
											searchable={true}
											width='100%'
											height={`${listHeight}px`}
											margin='0'
											selectedValues={selectedPipes}
											options={filteredPipes.map(pipe => ({
												label: pipe.valid,
												value: pipe.valid
											}))}
											onChange={value => selectPipes(value)}
											data-cy='dualselect-pipes-list'
										/>
									</FlexView>
								)}
							{(availablePipes.length === 0 || filteredPipes.length === 0) && (
								<FlexView
									width='100%'
									fontWeight='bold'
									fontSize='24px'
									color='lightGray'
									margin='auto 0'
									alignItems='center'
									justifyContent='center'
									data-cy='placeholder-pipes-list'>
									{t('wms:NoAvailablePipes')}
								</FlexView>
							)}
						</Card>
					</FlexView>
					{typeOfRight !== null && (
						<FlexView height='100%' margin='0 0 0 16px' width='20%'>
							<Card
								margin='0'
								flex='1'
								width='calc(100% - 48px)'
								justifyContent='flex-start'
								data-cy='card-target-ownership'>
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
									{typeOfRight === 'OWNERSHIP' ? t('wms:TargetOwnership') : t('wms:TargetEndUser')}
								</FlexView>
								<FlexView
									maxHeight='430px'
									width='100%'
									flex='1'
									style={typeOfRight === 'OWNERSHIP' ? { overflowY: 'scroll', overflowX: 'hidden' } : null}>
									{typeOfRight === 'OWNERSHIP' && levels && levels.length > 0 && (
										<Select
											placeholder={ownershipString}
											label={ownershipString}
											options={_.map(levels, lv => {
												return { value: lv.id, label: lv.label }
											})}
											value={targetRight}
											onChange={v => setTargetRight(v)}
											width='90%'
											margin='0'
											searchable
											data-cy='select-target-ownership'
										/>
									)}
									{typeOfRight === 'END_USER' && endusers && endusers.length && (
										<>
											<Select
												disabled={sourceMaterial === null}
												placeholder={t('wms:SelectEnduser')}
												value={targetRight}
												options={
													sourceMaterial != null &&
													renderEndUserOptions().map(({ id, name }) => ({
														value: id,
														label: name
													}))
												}
												onChange={value => {
													setTargetRight(value)
												}}
												width='100%'
												inline={false}
												margin='0 0 16px 0'
												searchable
												data-cy='select-target-ownership'
											/>
											<FlexView style={{ position: 'relative' }}>
												<LoadingOverlay visible={loadingMaterials} borderRadius='card' />
												<Select
													placeholder={t('wms:SelectMaterial')}
													value={targetMaterial}
													options={targetMaterialList.map(value => ({
														label: value.formatted,
														value: value.mvid
													}))}
													onChange={value => selectTargetMaterial(value)}
													width='calc(300px - 48px)'
													inline={false}
													margin='0'
													data-cy='select-target-material'
													searchable={true}
													disabled={targetRight === null}
												/>
											</FlexView>
										</>
									)}
								</FlexView>
								<Button
									fontSize='medium'
									margin='auto 0 0 auto'
									color='white'
									backgroundColor='secondary'
									disabled={checkProperties()}
									onClick={() => {
										setConfirmModal(true)
									}}
									data-cy='button-change-ownership'>
									{t('wms:MoveItems')}
								</Button>
							</Card>
						</FlexView>
					)}
				</FlexView>
			</FlexView>
			<DialogModal
				isOpen={openConfirmModal}
				text={typeOfRight === 'OWNERSHIP' ? t(`wms:ConfirmOwnershipChange`) : t(`wms:ConfirmEndUserChange`)}
				onConfirm={handleUsageRightsChange}
				onCancel={() => setConfirmModal(false)}
				data-cy='dialog-confirm-change'
			/>
		</PageTemplate>
	)
}

export default UsageRightsPage
