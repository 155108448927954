/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getAgeManagementListData: (wid, payload, token) =>
		new Promise((resolve, reject) => {
			const url = `/agemgmt/${wid}/query`
			instance
				.post(url, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					if (err.response.status === 403) reject({ err, lastListPending: true })
					else if (err.response.status === 404) reject({ err, lastListPending: false })
					else reject({ err, lastListPending: true })
				})
		}),
	createAgeManagementList: (wid, payload, token) =>
		new Promise((resolve, reject) => {
			const url = `/agemgmt/${wid}/issue`
			instance
				.post(url, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getExtracts: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`agemgmt/${wid}/config`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getReports: (wid, extractId, endUser, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`agemgmt/${wid}/reports/${extractId}/${endUser}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.list)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getEndUsers: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/${wid}/enduserlist`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getAgeManagementIssuedList: (wid, ageMgmtId, token) =>
		new Promise((resolve, reject) => {
			const url = `/agemgmt/${wid}/check/${ageMgmtId}`
			instance
				.get(url, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	rejectReport: (wid, ageMgmtId, payload, token) =>
		new Promise((resolve, reject) => {
			const url = `/agemgmt/${wid}/reject/${ageMgmtId}`
			instance
				.post(url, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	validateReport: (wid, ageMgmtId, payload, token) =>
		new Promise((resolve, reject) => {
			const url = `/agemgmt/${wid}/validate/${ageMgmtId}`
			instance
				.post(url, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getTitleTransferReports: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/agemgmt/${wid}/dashboard-list`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		})
}
