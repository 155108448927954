/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import assetsUsage from '../api/assetsUsageRights'
import { toast } from 'react-toastify'
import _ from 'lodash'

export const AssetsUsageRightsContext = React.createContext()

export const AssetsUsageRightsProvider = ({ children }) => {
	const { token, user } = useContext(UserContext)
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const [ownerships, setOwnerships] = useState([])
	const [ownershipSelected, setOwnershipSelected] = useState(null)
	const [loadingOwnerships, setLoadingOwnerships] = useState(false)
	const [loadingLocalEndUsers, setLoadingLocalEndUsers] = useState(false)
	const [loadingGlobalEndUsers, setLoadingGlobalEndUsers] = useState(false)
	const [create, setCreate] = useState(false)
	const [clearFilter, setClearFilter] = useState(false)
	const [removeLevel, setRemoveLevel] = useState(false)
	const [ownerDescriptions, setOwnerDescriptions] = useState([])
	const [ownerLevels, setOwnerLevels] = useState([])
	const [filteredOwnerships, setFilteredOwnerships] = useState(null)
	const [filteredOwnerLevels, setFilteredOwnerLevels] = useState(null)
	const [ownerLevelSelected, setOwnerLevelSelected] = useState({
		id: -1,
		parentLevel: 0
	})
	const [resultOwnership, setResultOwnership] = useState([])
	const [lastOwnershipId, setLastOwnershipId] = useState(null)
	const [item, setItem] = useState('')
	const [ownerValues, setOwnerValues] = useState([])
	const [localEndUsers, setLocalEndUsers] = useState(null)
	const [globalEndUsers, setGlobalEndUsers] = useState(null)
	const [newEndUser, setNewEndUser] = useState({
		value: null,
		name: '',
		description: ''
	})
	const [newLevel, setNewLevel] = useState('')
	const [levelDesc, setLevelDesc] = useState(null)
	const [levelSelected, setLevelSelected] = useState(null)
	const [levels, setLevels] = useState([])
	const [ownerValueSelected, setOwnerValueSelected] = useState(null)
	const [filteredLevels, setFilteredLevels] = useState([])
	const [firstLevel, setFirstLevel] = useState([])

	const getLocalEndUsers = () =>
		new Promise((resolve, reject) => {
			assetsUsage
				.getLocalEndUsers(warehouse.id, token)
				.then(response => {
					setLocalEndUsers(response)
					resolve()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingEndUser')} [${e.status} ]: ${e.data}`)
				})
		})

	const getGlobalEndUsers = () =>
		new Promise((resolve, reject) => {
			assetsUsage
				.getGlobalEndUsers(token)
				.then(response => {
					setGlobalEndUsers(response)
					resolve()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingEndUser')} [${e.status} ]: ${e.data}`)
				})
		})

	const importEndUserValue = () => {
		let globalEndUser = _.find(globalEndUsers, endUser => {
			return endUser.id === newEndUser.value
		})
		if (globalEndUser) {
			let payload = {
				globalid: globalEndUser.id,
				name: globalEndUser.name,
				description: globalEndUser.description
			}
			addEndUserValue(payload).catch(e => console.error(e))
		}
	}

	const addEndUserValue = payload =>
		new Promise((resolve, reject) => {
			assetsUsage
				.addEndUserValue(warehouse.id, { ...payload, responsible: `${user.firstName} ${user.lastName}` }, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:EndUserCreationSuccess'))
					setNewEndUser({
						value: null,
						name: '',
						description: ''
					})
					getLocalEndUsers()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorCreatingEndUser')} [${e.status} ]: ${e.data}`)
					setNewEndUser({
						value: null,
						name: '',
						description: ''
					})
				})
		})

	const deleteEndUser = endUserId =>
		new Promise((resolve, reject) => {
			assetsUsage
				.deleteEndUser(warehouse.id, endUserId, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:EndUserRemovalSuccess'))
					getLocalEndUsers()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorDeletingEndUser')} [${e.status} ]: ${e.data}`)
				})
		})

	const getLevels = () =>
		new Promise((resolve, reject) => {
			assetsUsage
				.getLevels(warehouse.id, token)
				.then(response => {
					const levelsWithParents = _.map(response, (res, index) => ({
						...res,
						level: index + 1
					}))
					resolve(levelsWithParents)
					setLevelDesc(levelsWithParents)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingLevels')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`)
				})
		})

	const getOwnerships = () =>
		new Promise((resolve, reject) => {
			setLoadingOwnerships(true)
			assetsUsage
				.getOwnerships(warehouse.id, token)
				.then(response => {
					getLevels().then(levels => {
						let ownerDesc = response.ownershipdescriptions
						let subLevels = levels.filter(lv => lv.level > 1)
						setOwnerDescriptions(ownerDesc)
						let ownerLevels = []
						let initialLevel = levels
							.filter(owner => owner.level === 1)
							.map(lv => ({
								id: lv.id,
								description: lv.description,
								values: ownerDesc
									.filter(owd => owd.level === lv.id)
									.map(lv => ({
										id: lv.id,
										label: lv.namepath[0]
									}))
									.sort((a, b) => a.id - b.id),
								level: lv.level,
								parentLevel: lv.level - 1,
								selectedOption: -1
							}))
						setOwnerLevelSelected(...initialLevel)
						setFirstLevel(...initialLevel)
						_.forEach(subLevels, lv => {
							ownerLevels.push({
								id: lv.id,
								description: lv.description,
								values: ownerDesc
									.filter(owl => owl.level === lv.id)
									.map(owner => {
										let idx = _.findIndex(owner.idpath, i => i === owner.id)
										return {
											id: owner.id,
											parentIds: _.filter(owner.idpath, id => id !== owner.id),
											label: owner.namepath[idx]
										}
									}),
								level: lv.level,
								parentLevel: lv.level - 1,
								selectedOption: -1
							})
						})
						setLevels(ownerLevels)
						resolve()
					})
				})
				.catch(e => {
					setLoadingOwnerships(false)
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingOwnerships')} [${e.status} ]: ${e.data}`)
				})
				.finally(() => setLoadingOwnerships(false))
		})

	const addOwnershipValues = () =>
		new Promise((resolve, reject) => {
			if (item) {
				let payload = {
					level: ownerLevelSelected && ownerLevelSelected.id,
					name: item,
					parent: ownerValueSelected !== null ? ownerValueSelected : -1
				}
				assetsUsage
					.addOwnershipValue(warehouse.id, payload, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:OwnerItemAddedSuccess'))
						setLevels([])
						setOwnerLevelSelected({ id: -1, parentLevel: 0 })
						setFilteredLevels([])
						setFirstLevel([])
						getOwnerships()
						setItem('')
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorCreatingOwnership')} [${e.status} ]: ${e.data}`)
					})
			}
		})

	const deleteOwnershipValues = owner =>
		new Promise((resolve, reject) => {
			assetsUsage
				.deleteOwnershipValue(warehouse.id, owner.id, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:OwnerItemDeletedSuccess'))
					setLevels([])
					setOwnerLevelSelected({ id: -1, parentLevel: 0 })
					setFilteredLevels([])
					setFirstLevel([])
					getOwnerships()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorDeletingOwnership')} [${e.status} ]: ${e.data}`)
				})
		})

	const createLevels = level =>
		new Promise((resolve, reject) => {
			setCreate(true)
			level &&
				assetsUsage
					.createLevels(warehouse.id, level, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:SuccessfullyCreatedLevel'))
						setLevels([])
						setOwnerLevelSelected({ id: -1, parentLevel: 0 })
						setFilteredLevels([])
						setFirstLevel([])
						getLevels()
						getOwnerships()
						setNewLevel('')
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorCreatingLevel')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`)
						setCreate(false)
					})
					.finally(() => setCreate(false))
		})

	const deleteLevel = () =>
		new Promise((resolve, reject) => {
			assetsUsage
				.deleteLevel(warehouse.id, levelSelected, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullyDeletedLevel'))
					setLevels([])
					setOwnerLevelSelected({ id: -1, parentLevel: 0 })
					setFilteredLevels([])
					setFirstLevel([])
					getLevels()
					getOwnerships()
					setLevelSelected(null)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorDeletingLevel')} [ ${e.status} ]: ${e.data}`)
				})
		})

	/*************************************************
	 *                USE EFFECT
	 **************************************************/

	useEffect(() => {
		if (ownerLevelSelected && ownerLevelSelected.length !== 0) {
			_.forEach(ownerLevelSelected, own => {
				_.forEach(filteredOwnerLevels, owners => {
					if (_.some(owners.values, o => o.id === own)) {
						owners.selected = _.find(owners.values, o => {
							return o.id === own
						})
						setFilteredOwnerLevels([...filteredOwnerLevels])
					}
				})
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownerLevelSelected, ownershipSelected])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingOwnerships(true)
				await getOwnerships()
			} catch (e) {
				console.error(e)
				setLoadingOwnerships(false)
			} finally {
				setLoadingOwnerships(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingLocalEndUsers(true)
				await getLocalEndUsers()
			} catch (e) {
				console.error(e)
				setLoadingLocalEndUsers(false)
			} finally {
				setLoadingLocalEndUsers(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingGlobalEndUsers(true)
				await getGlobalEndUsers()
			} catch (e) {
				console.error(e)
				setLoadingGlobalEndUsers(false)
			} finally {
				setLoadingGlobalEndUsers(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (removeLevel) {
			const hydrate = async () => {
				try {
					await deleteLevel()
				} catch (e) {
					console.error(e)
					setRemoveLevel(false)
				} finally {
					setRemoveLevel(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removeLevel])

	useEffect(() => {
		setNewEndUser({
			value: null,
			name: '',
			description: ''
		})
		setNewLevel('')
		setLevelSelected(null)
		setItem('')
	}, [warehouse])

	return (
		<AssetsUsageRightsContext.Provider
			value={{
				ownerships,
				setOwnerships,
				ownershipSelected,
				setOwnershipSelected,
				loadingOwnerships,
				setLoadingOwnerships,
				ownerDescriptions,
				setOwnerDescriptions,
				ownerLevels,
				setOwnerLevels,
				filteredOwnerships,
				setFilteredOwnerships,
				filteredOwnerLevels,
				setFilteredOwnerLevels,
				resultOwnership,
				setResultOwnership,
				lastOwnershipId,
				setLastOwnershipId,
				ownerLevelSelected,
				setOwnerLevelSelected,
				item,
				setItem,
				ownerValues,
				setOwnerValues,
				loadingLocalEndUsers,
				setLoadingLocalEndUsers,
				loadingGlobalEndUsers,
				setLoadingGlobalEndUsers,
				localEndUsers,
				setLocalEndUsers,
				globalEndUsers,
				setGlobalEndUsers,
				newEndUser,
				setNewEndUser,
				clearFilter,
				setClearFilter,
				newLevel,
				setNewLevel,
				create,
				setCreate,
				levelDesc,
				setLevelDesc,
				levelSelected,
				setLevelSelected,
				removeLevel,
				setRemoveLevel,
				importEndUserValue,
				addEndUserValue,
				deleteEndUser,
				addOwnershipValues,
				deleteOwnershipValues,
				createLevels,
				levels,
				setLevels,
				ownerValueSelected,
				setOwnerValueSelected,
				filteredLevels,
				setFilteredLevels,
				firstLevel,
				setFirstLevel
			}}>
			{children}
		</AssetsUsageRightsContext.Provider>
	)
}
