/** @format */

import { FlexView } from 'components/common'
import { Select } from 'components/form'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EndUserSettings from '../containers/assets/usagerights/EndUserSettings'
import OwnershipSettings from '../containers/assets/usagerights/OwnershipSettings'
import PageTemplate from '../containers/common/PageTemplate'
import { WMSContext } from '../stores/WmsStore'
import { assets, genParams } from '../utils/hubs'

const AssetsUsageRightsPage = () => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const [usageRightsSelected, setUsageRightsSelected] = useState(null)

	const usageType = [
		{
			value: 1,
			label: `${t('wms:Ownership')}`
		},
		{
			value: 2,
			label: `${t('wms:EndUser')}`
		}
	]

	useEffect(() => {
		setUsageRightsSelected(null)
	}, [warehouse])

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:UsageRights'),
				path: '/wms/assets/usagerights'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' height='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:UsageRights')}
				</FlexView>
				<FlexView flexDirection='column' width='100%' height='90%'>
					<Select
						inline
						label={`${t('wms:UsageType')}:`}
						placeholder={t('wms:SelectOption')}
						value={usageRightsSelected}
						options={usageType}
						onChange={v => setUsageRightsSelected(v)}
						margin='0'
						data-cy='select-usage-type'></Select>
					{usageRightsSelected !== null ? (
						usageRightsSelected === 1 ? (
							<OwnershipSettings />
						) : (
							<EndUserSettings />
						)
					) : null}
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default AssetsUsageRightsPage
