/** @format */

import { ContentContext } from 'apps/wms/stores/ContentStore'
import { WMSContext } from 'apps/wms/stores/WmsStore'
import { listStatusTranslateKey, REPORT_TYPE } from 'apps/wms/utils/enum'
import { Button, Card, FlexView, Icon } from 'components/common'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'utils/theme'
import { LoadingOverlay } from '../../../../components/common'

const PipeData = () => {
	const {
		myPipeLastStatus,
		pipe,
		pipeData,
		downloadCertificate,
		downloadReport,
		pageLoading,
		downloading,
		setOpenPipeData,
		setSearchValid,
		setSearchPuuid,
		setPipe
	} = useContext(ContentContext)
	const { lengthUnit, weightUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit, diameterUnit } =
		useContext(WMSContext)
	const { t } = useTranslation()

	const handleBack = () => {
		setOpenPipeData(false)
		setPipe(null)
		setSearchValid('')
		setSearchPuuid('')
	}

	const handlePdf = () => {
		downloadCertificate()
	}

	return (
		<FlexView
			flexDirection='column'
			width='100%'
			height='100%'
			maxHeight='100%'
			position='relative'
			style={{ overflowY: 'auto' }}>
			<LoadingOverlay visible={pageLoading} borderRadius='card' />
			<FlexView width='100%' fontSize='24px' flexDirection='row' alignItems='center' justifyContent='space-between'>
				{pipeData ? `${t('wms:Item')}: ${pipeData.pipevalid}` : null}
				<Icon
					margin='0px 16px'
					name='undo'
					width='24px'
					height='24px'
					color='black'
					style={{ float: 'right' }}
					onClick={handleBack}
				/>
			</FlexView>
			{pipeData && (
				<FlexView
					flexDirection='row'
					flexWrap='wrap'
					alignItems='flex-start'
					style={{ alignContent: 'flex-start' }}
					justifyContent='space-between'
					width='calc(100% - 16px)'
					height='calc(100% - 16px)'
					padding='8px'>
					<Card
						elevation='high'
						width='calc(30% - 48px)'
						height='calc(40% - 48px)'
						margin='8px 0'
						style={{ overflowY: 'auto' }}
						data-cy='card-item-identification'>
						<FlexView fontSize='24px' fontWeight='bold'>
							{t('wms:ItemId')}
						</FlexView>
						<FlexView
							flexDirection='row'
							justifyContent='space-between'
							width='100%'
							flexWrap='wrap'
							margin='16px 0 0 0'>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									Valid
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.pipevalid}
								</FlexView>
							</FlexView>
							<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									IPPN
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.pipepuuid || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:CustomerOrder')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.customerponumber || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:CustomerItem')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.customeritem || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:SalesOrder')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.vtsaordernumber || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:SalesItem')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.vtsaitemnumber || '*****'}
								</FlexView>
							</FlexView>
						</FlexView>
					</Card>
					<Card
						elevation='high'
						width='calc(36% - 48px)'
						height='calc(40% - 48px)'
						margin='8px 0'
						style={{ overflowY: 'auto' }}
						data-cy='card-nominal-data'>
						<FlexView fontSize='24px' fontWeight='bold'>
							{t('wms:NominalData')}
						</FlexView>
						<FlexView
							flexDirection='row'
							justifyContent='space-between'
							width='100%'
							flexWrap='wrap'
							margin='16px 0 0 0'>
							<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:FamilyProduct')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.producttype || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:ErpReference')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.catid || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									S/N
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.serialnumber || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:OD')} [{diameterUnit}]
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.od || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:LinearWeight')} [lb]/[ft]
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.weight ? pipeData.weight.toFixed(2) : '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:Grade')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.grade || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:Connection')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.connection || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:Drift')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.drift || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='34%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:Length')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.length || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:QualityStandard')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{(pipeData.standard && pipeData.revision && `${pipeData.standard} ${pipeData.revision}`) ||
										'*****'}
								</FlexView>
							</FlexView>
							{pipeData.modifiedproductdesc && (
								<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:ModifiedProductDesc')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{pipeData.modifiedproductdesc}
									</FlexView>
								</FlexView>
							)}
						</FlexView>
					</Card>
					<Card
						elevation='high'
						width='calc(30% - 48px)'
						height='calc(40% - 48px)'
						margin='8px 0'
						style={{ overflowY: 'auto' }}
						data-cy='card-actual-data'>
						<FlexView fontSize='24px' fontWeight='bold'>
							{t('wms:ActualData')}
						</FlexView>
						<FlexView
							flexDirection='row'
							justifyContent='space-between'
							width='100%'
							flexWrap='wrap'
							margin='16px 0 0 0'>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:HeatNo')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.heat || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:ConsignmentType')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.consignmenttype || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:Ownership')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.ownershipname || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:Rack')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{pipeData.rackfullname || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:ActualLength')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{`${convertToCurrentLengthUnit(pipeData.actuallength, lengthUnit)} ${lengthUnit}` || '*****'}
								</FlexView>
							</FlexView>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView flexDirection='column' width='100%'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:ActualWeight')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{`${convertToCurrentWeightUnit(pipeData.actualweight, weightUnit)} ${weightUnit}` ||
											'*****'}
									</FlexView>
								</FlexView>
							</FlexView>
							<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:PipeStatus')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{t(listStatusTranslateKey(myPipeLastStatus)) || t(listStatusTranslateKey(pipe.laststatus))}
								</FlexView>
							</FlexView>
						</FlexView>
					</Card>
					<Card
						elevation='high'
						width='calc(40% - 48px)'
						height='calc(40% - 48px)'
						margin='8px 0px'
						style={{ overflowY: 'auto' }}
						data-cy='card-quality-data'>
						<FlexView fontSize='24px' fontWeight='bold'>
							{t('wms:QualityData')}
						</FlexView>
						<FlexView flexDirection='row' justifyContent='space-between' width='100%'>
							<FlexView flexDirection='column' width='50%' margin='0 8px 0 0'>
								<FlexView flexDirection='column' width='100%'>
									<FlexView fontSize='tiny' color='metalic' fontWeight='bold' margin='16px 16px 0px 0px'>
										{t('wms:ReferenceMTC')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{pipeData.certificateref || '*****'}
									</FlexView>
								</FlexView>
							</FlexView>
							<FlexView flexDirection='column' width='50%' margin='0 0 0 16px'>
								<FlexView flexDirection='column' width='100%'>
									<FlexView fontSize='tiny' color='metalic' fontWeight='bold' margin='16px 16px 0px 0px'>
										{t('wms:MTCFile')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{pipeData.certificateref || '*****'}
									</FlexView>
								</FlexView>
							</FlexView>

							<Button
								backgroundColor='secondary'
								color='white'
								style={{ alignSelf: 'center' }}
								onClick={handlePdf}
								margin='16px'
								data-cy='button-show-certificate'>
								{t('wms:Open')}
							</Button>
						</FlexView>
					</Card>

					<Card
						elevation='high'
						width='calc(58% - 48px)'
						height='calc(40% - 48px)'
						margin='8px 0px'
						style={{ overflowY: 'auto' }}
						data-cy='card-quality-data'>
						<FlexView fontSize='24px' fontWeight='bold' margin='0'>
							{t('wms:Reports')}
						</FlexView>

						{pipeData.reports.length ? (
							pipeData.reports.map((r, index) => (
								<FlexView key={index} flexDirection='row' width='100%' alignItems='center' flexWrap='wrap'>
									<FlexView
										color='metalic'
										width='100%'
										margin='10px 0px 0px'
										padding='0px'
										fontSize='tiny'
										fontWeight='bold'>
										{r.isInspectorReport ? t(r.stageLabel) : t(REPORT_TYPE.translateKey[r.reportType])}
									</FlexView>
									<FlexView
										margin='0px'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										ID {r.id} - {r.name ? r.name : r.type}
									</FlexView>
									<Button
										backgroundColor='white'
										color='primary'
										margin='0 0 0 auto'
										padding='0px auto'
										style={{ display: 'flex' }}
										onClick={() => {
											r.isInspectorReport
												? downloadReport(r.slug, r.id, r.isInspectorReport, r.part)
												: downloadReport(r.reportType, r.id, r.isInspectorReport, null)
										}}>
										Download report
										<Icon name='download' margin='0 0 0 16px' color='primary' />
									</Button>
									<LoadingOverlay visible={downloading} borderRadius='card' />
								</FlexView>
							))
						) : (
							<FlexView
								width=' 100%'
								fontWeight='bold'
								color='lightGray'
								margin='auto 0'
								alignItems='center'
								style={{ alignContent: 'center' }}
								justifyContent='center'
								data-cy='no-reports-list'>
								{t('wms:NoReports')}
							</FlexView>
						)}
					</Card>
				</FlexView>
			)}
			{!pipeData ||
				(pageLoading && (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						data-cy='no-batch-list'>
						{t('wms:PipeDataNotFound')}
					</FlexView>
				))}
		</FlexView>
	)
}

export default PipeData
