/** @format */

import { BatchEntranceContext } from 'apps/wms/stores/BatchEntranceStore'
import { Button, Card, FlexView } from 'components/common'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import _ from 'lodash'
import { STATUS } from '../../utils/enum'
import { DATE_FORMAT } from '../../utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'
import theme from 'utils/theme'

const BatchListInfo = () => {
	const { batchContent, setBatchId, levels } = useContext(BatchEntranceContext)
	const { user } = useContext(UserContext)
	const { t, i18n } = useTranslation()
	const applicationID = process.env.REACT_APP_ID

	const listDeadSpace = 350
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const handleReopen = id => {
		setBatchId(id)
	}

	const allowReopen = true

	const levelDesc = id => {
		let ownership = _.find(levels, owner => owner.id === id)
		return id && ownership ? ownership.label : '*****'
	}

	return (
		<Card width='calc(100% - 48px)' flex='1' ref={listRef} margin='0' style={{ position: 'relative' }}>
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:EntranceInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:Name')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{batchContent.name}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:ExpectedDate')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(batchContent.expected, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:EndUser')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{batchContent.enduser.name}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:Ownership')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{levelDesc(batchContent.ownershipid)}
					</FlexView>
				</FlexView>
				{batchContent.customerpo && (
					<FlexView width='66%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:CustomerPoNo')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{batchContent.customerpo}
						</FlexView>
					</FlexView>
				)}
				{batchContent.salesorder && (
					<FlexView width='66%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:SalesOrder')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{batchContent.salesorder}
						</FlexView>
					</FlexView>
				)}
				{batchContent.customeritem && (
					<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:CustomerItem')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{batchContent.customeritem}
						</FlexView>
					</FlexView>
				)}
				{batchContent.salesitem && (
					<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:SalesItem')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{batchContent.salesitem}
						</FlexView>
					</FlexView>
				)}
				<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:Material')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{batchContent.matdesc}
					</FlexView>
				</FlexView>
			</FlexView>

			{batchContent && (batchContent.planrevision || batchContent.revision) ? (
				<FlexView width='100%' flexDirection='row'>
					<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:Revision')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{batchContent.revision ? batchContent.revision : batchContent.planrevision}
						</FlexView>
					</FlexView>
					<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:UpdatedBy')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{batchContent.validationresp ? batchContent.validationresp : batchContent.planresp}
						</FlexView>
					</FlexView>
					<FlexView width='33%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:On')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{DATE_FORMAT(
								batchContent.validationdate ? batchContent.validationdate : batchContent.plandate,
								i18n.language
							)}
						</FlexView>
					</FlexView>
				</FlexView>
			) : null}

			<FlexView width='100%' fontWeight='bold' fontSize='small' color='metalic' margin='0'>
				{t('wms:Content')}
			</FlexView>

			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'auto' }}>
				{batchContent &&
					batchContent.content.length &&
					batchContent.content.map((content, index) => (
						<FlexView
							key={content.belcid}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							data-cy='content-list-item'>
							<FlexView fontSize='small' margin='auto auto auto 0' flexDirection='row'>
								<strong>{`${t('wms:Part')} ${index + 1}`}</strong>
							</FlexView>
							<FlexView fontSize='small' margin='auto auto auto 0' flexDirection='row'>
								{`${t('wms:ExpectedQuantity')}: ${content.quantity}`}
							</FlexView>
							<FlexView margin='0' padding='0' flexDirection='row' justifyContent='center'>
								<FlexView
									fontSize='tiny'
									color={STATUS.color[content.status]}
									style={{
										borderLeft: `2px solid ${theme.colors[STATUS.color[content.status]]}`,
										lineHeight: '24px'
									}}
									margin='0 4px 0 auto'
									padding='8px 16px'>
									{t(`wms:${STATUS.literal[content.status]}`)}
								</FlexView>
								{allowReopen &&
								content.status === STATUS.code.RUNNING &&
								Object.keys(user.roles[applicationID]).find(r => r === 'ADMIN') ? (
									<Button
										fontSize='tiny'
										margin='0'
										color='white'
										backgroundColor='secondary'
										disabled={false}
										onClick={() => {
											handleReopen(content.belcid)
										}}>
										{t('wms:Reopen')}
									</Button>
								) : null}
							</FlexView>
						</FlexView>
					))}
			</FlexView>
		</Card>
	)
}

export default BatchListInfo
