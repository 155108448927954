/** @format */

import _ from 'lodash'
import moment from 'moment'

const downloadFormatCommon = item => ({
	customerpo: item.customerpo,
	customeritem: item.customeritem,
	salesorder: item.salesorder,
	salesitem: item.salesitem,
	entrancedate: moment(item.entrancedate).format('L'),
	erpreference: item.erpreference,
	length: item.length,
	materialdesc: item.materialdesc,
	ownershipdesc: item.ownershipdesc,
	quantity: item.quantity,
	weight: item.weight,
	age: item.age
})

const downloadAttributeColumns = data => ({
	attributedesc: data.attributedesc,
	attributevalue: data.attributevalue
})

const downloadFormatInspection = (data, ltpa) => {
	let items = []
	let ageColumns = []
	let entranceDateList = []
	data.forEach((item, index) => {
		item.filtercolumns.forEach(col => {
			items.push({
				...downloadFormatCommon(item),
				...downloadAttributeColumns(col)
			})
			ageColumns.push({
				...downloadAttributeColumns(col)
			})
			entranceDateList.push({
				value: index,
				label: moment(item.entrancedate).format('L')
			})
		})
	})

	ageColumns = _.uniqBy(ageColumns, 'attributedevalue')

	return { items, ltpa, ageColumns, entranceDateList }
}

export default function formatAgeReports(data, ltpa) {
	return downloadFormatInspection(data, ltpa)
}
