/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getBatchEntranceLists: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/${wid}/list`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.batchentrancelist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	confirmUpdateBatchList: (wid, params = {}, beId, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`batchentrance/${wid}/update/${beId}`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getEndUsers: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/${wid}/enduserlist`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.enduserlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMaterials: (wid, eu, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/${wid}/materiallist/${eu}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.materiallist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getPoList: (wid, eu, mid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/${wid}/polist/${eu}/${mid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.polist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getSalesList: (wid, eu, mid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/${wid}/saleslist/${eu}/${mid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.saleslist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	saveBatchList: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`batchentrance/${wid}/list`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve()
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	deleteBatchList: (wid, beid, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`batchentrance/${wid}/list/${beid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve()
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	reopenBatchList: (wid, beid, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(
					`batchentrance/${wid}/release/${beid}`,
					{},
					{
						headers: {
							Authorization: `${token}`
						}
					}
				)
				.then(response => {
					if (response.status === 200) {
						resolve()
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getEntranceList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/${wid}/validated`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.batchentrancelist)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getEntranceParts: (wid, beID, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/${wid}/list/${beID}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.batchentrance)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	modifyEntrance: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`content/${wid}/updatematerialid`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getPreparedEntrancesList: (endUser, fromDate, toDate, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/prepared/${endUser.id}/from/${fromDate.toISOString()}/to/${toDate.toISOString()}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.preparedlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.message || err)
				})
		}),
	getPreparedEntrancesData: (id, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/prepared/${id}/pipelist`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.pipelist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.message || err)
				})
		}),
	prepareEntrance: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`batchentrance/${wid}/prepare`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.loadedpipes)
					} else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getSupplierList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`batchentrance/${wid}/supplierlist`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
