/** @format */

/* eslint-disable react/prop-types */

import { WMSContext } from 'apps/wms/stores/WmsStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { REPORT_TYPE } from 'apps/wms/utils/enum'
import { FlexView } from 'components/common'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'utils/theme'

const ReportHeader = ({ reportData, instrumentList, ltpa, zoom, untraceable, reportType, isInspection }) => {
	const { t, i18n } = useTranslation()
	const { convertToCurrentWeightUnit, convertToCurrentLengthUnit, lengthUnit, weightUnit } = useContext(WMSContext)

	const ltpaNumberString = t('wms:NumberLTPA')

	const header = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='37%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
						{reportData.validation_revision && reportData.validation_revision !== 0
							? ` - Rev. ${reportData.validation_revision}`
							: reportData.revision && reportData.revision !== 0
							? ` - Rev. ${reportData.revision}`
							: ''}
					</FlexView>
				</FlexView>
				{reportData.part && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='37%'>
							{t('wms:Part')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.part}
						</FlexView>
					</FlexView>
				)}
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.updated_date
							? DATE_FORMAT(reportData.updated_date, i18n.language)
							: reportData.date
							? DATE_FORMAT(reportData.date, i18n.language)
							: '*****'}
					</FlexView>
				</FlexView>
				{reportData.enduser && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='30%'>
							{t('wms:EndUser')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.enduser}
						</FlexView>
					</FlexView>
				)}
				{reportData.tparty_name && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
							{t('wms:ThirdPartyName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.tparty_name}
						</FlexView>
					</FlexView>
				)}
				{reportData.tparty_location && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='55%'>
							{t('wms:ThirdPartyLocation')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.tparty_location}
						</FlexView>
					</FlexView>
				)}
				{reportData.delivery_location && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
							{t('wms:DeliveryLocation')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.delivery_location}
						</FlexView>
					</FlexView>
				)}
				{reportData.field_name && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='37%'>
							{t('wms:FieldName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.field_name}
						</FlexView>
					</FlexView>
				)}
				{reportData.rig_name && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='37%'>
							{t('wms:RigName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.rig_name}
						</FlexView>
					</FlexView>
				)}
				{reportData.well_name && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
							{t('wms:WellName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.well_name}
						</FlexView>
					</FlexView>
				)}
				{reportData.customer_request_reference && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='75%'>
							{t('wms:CustomerRequestReference')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.customer_request_reference}
						</FlexView>
					</FlexView>
				)}
				{reportData.customer_order && (
					<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='20%'>
							{t('wms:CustomerOrder')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.customer_order}
						</FlexView>
					</FlexView>
				)}
				{reportData.customer_item && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
							{t('wms:CustomerItem')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.customer_item}
						</FlexView>
					</FlexView>
				)}
				{reportData.sales_order && (
					<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='20%'>
							{t('wms:SalesOrder')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.sales_order}
						</FlexView>
					</FlexView>
				)}
				{reportData.sales_item && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
							{t('wms:SalesItem')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.sales_item}
						</FlexView>
					</FlexView>
				)}
				{reportData.ltpa_number ? (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='37%'>
							{ltpaNumberString}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.ltpa_number}
						</FlexView>
					</FlexView>
				) : ltpa ? (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
							{ltpaNumberString}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{ltpa}
						</FlexView>
					</FlexView>
				) : null}
				{reportData.total_joints != null && (
					<FlexView width='25%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
							{t('wms:TotalJoints')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.total_joints}
						</FlexView>
					</FlexView>
				)}
				{reportData.totallength && (
					<FlexView width='20%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='60%'>
							{`${t('wms:TotalLength')} ${[lengthUnit]}:`}
						</FlexView>
						<FlexView
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{convertToCurrentLengthUnit(reportData.totallength.toFixed(3), lengthUnit)}
						</FlexView>
					</FlexView>
				)}
				{reportData.totalweight && (
					<FlexView width='20%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='43%'>
							{`${t('wms:TotalWeight')} ${[weightUnit]}:`}
						</FlexView>
						<FlexView
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{convertToCurrentWeightUnit(reportData.totalweight.toFixed(3), weightUnit)}
						</FlexView>
					</FlexView>
				)}
				{untraceable >= 0 && _.isNumber(untraceable) && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='40%'>
							{t('wms:Untraceable')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{untraceable}
						</FlexView>
					</FlexView>
				)}
				{reportData.erp_reference && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='29%'>
							{t('wms:ErpReference')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.erp_reference}
						</FlexView>
					</FlexView>
				)}
				{reportData.erplist && reportData.erplist.length > 0 && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='29%'>
							{t('wms:ErpReferences')}
						</FlexView>
						{reportData.erplist.map(erp => (
							<FlexView
								key={erp.id}
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{erp.label}
							</FlexView>
						))}
					</FlexView>
				)}
				{reportData.material_description && (
					<FlexView width='100%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='7%'>
							{t('wms:Material')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.material_description}
						</FlexView>
					</FlexView>
				)}
				{reportData.materiallist && reportData.materiallist.length > 0 && (
					<FlexView width='100%' flexDirection='row' fontSize='small' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='7%'>
							{t('wms:Material')}
						</FlexView>
						{reportData.materiallist.map(material => (
							<FlexView
								key={material.mvid}
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{material.formatted}
							</FlexView>
						))}
					</FlexView>
				)}
			</>
		),
		[
			reportData.id,
			reportData.validation_revision,
			reportData.revision,
			reportData.part,
			reportData.updated_date,
			reportData.date,
			reportData.enduser,
			reportData.tparty_name,
			reportData.tparty_location,
			reportData.delivery_location,
			reportData.field_name,
			reportData.rig_name,
			reportData.well_name,
			reportData.customer_request_reference,
			reportData.customer_order,
			reportData.customer_item,
			reportData.sales_order,
			reportData.sales_item,
			reportData.ltpa_number,
			reportData.total_joints,
			reportData.totallength,
			reportData.totalweight,
			reportData.erp_reference,
			reportData.erplist,
			reportData.material_description,
			reportData.materiallist,
			t,
			i18n.language,
			ltpaNumberString,
			ltpa,
			lengthUnit,
			convertToCurrentLengthUnit,
			weightUnit,
			convertToCurrentWeightUnit,
			untraceable
		]
	)

	const ageManagementHeader = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='10%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='15%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.extractiondate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='30%'>
						{t('wms:EndUser')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.enduser}
					</FlexView>
				</FlexView>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='35%'>
						{ltpaNumberString}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{ltpa}
					</FlexView>
				</FlexView>
			</>
		),
		[reportData.id, reportData.extractiondate, reportData.enduser, t, i18n.language, ltpaNumberString, ltpa]
	)

	const receiptNoteHeader = useMemo(
		() => (
			<>
				<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='25%'>
						ID
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.id}
					</FlexView>
				</FlexView>
				{reportData.partnumber && (
					<FlexView width='25%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
						<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='37%'>
							{t('wms:Part')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportData.partnumber}
						</FlexView>
					</FlexView>
				)}
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='32%'>
						{t('wms:Date')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{DATE_FORMAT(reportData.closuredate, i18n.language)}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='25%'>
						{t('wms:CustomerOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.customerorders}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='32%'>
						{t('wms:CustomerItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.customeritems}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='25%'>
						{t('wms:SalesOrder')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.salesorders}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='32%'>
						{t('wms:SalesItem')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.salesitems}
					</FlexView>
				</FlexView>
				<FlexView width='50%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='25%'>
						{t('wms:TotalJoints')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportData.totaljoints}
					</FlexView>
				</FlexView>
				<FlexView width='20%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='60%'>
						{`${t('wms:TotalLength')} [${lengthUnit}]:`}
					</FlexView>
					<FlexView
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{convertToCurrentLengthUnit(reportData.totallength, lengthUnit)}
					</FlexView>
				</FlexView>
				<FlexView width='20%' fontSize='small' flexDirection='row' color='dark-gray' margin='0 0 8px 0'>
					<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='43%'>
						{`${t('wms:TotalWeight')} [${weightUnit}]:`}
					</FlexView>
					<FlexView
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{convertToCurrentWeightUnit(reportData.totalweight, weightUnit)}
					</FlexView>
				</FlexView>
			</>
		),
		[reportData, i18n, t, convertToCurrentLengthUnit, convertToCurrentWeightUnit, lengthUnit, weightUnit]
	)

	const getReportHeader = () => {
		if (!isInspection && reportType === REPORT_TYPE.code.AGE_EXTRACTS) return ageManagementHeader
		else if (!isInspection && reportType === REPORT_TYPE.code.MILL_RECEIPT) return receiptNoteHeader
		else return header
	}

	const getInstrumentsHeader = () => {
		return instrumentList && Object.values(instrumentList).length > 0 ? (
			<FlexView width='100%' flexDirection='row' fontSize='small' margin='0'>
				<FlexView fontWeight='bold' color='dark-gray' fontSize='small' margin='0 4px 0 0' width='7%'>
					{t('wms:Instruments')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{instrumentList.map(inst => (
						<FlexView key={inst.id} color='dark-gray' fontSize='small'>
							{inst.list.map(instCase => `${instCase.reference} [${instCase.type}], `)}
						</FlexView>
					))}
				</FlexView>
			</FlexView>
		) : null
	}

	return (
		<FlexView
			style={{ zoom: zoom }}
			flexDirection='row'
			flexWrap='wrap'
			width='100%'
			fontSize='small'
			margin='0 0 24px 0'>
			{getReportHeader()}
			{getInstrumentsHeader()}
		</FlexView>
	)
}

export default ReportHeader
