/** @format */

import { InventoryContext } from 'apps/wms/stores/InventoryStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line react/prop-types
const InventoryDetailsModal = ({ isOpen, onOutsideClick, name }) => {
	const { t, i18n } = useTranslation()
	const { loadingDetails, inventoryResult, inventoryId, exportInventory } = useContext(InventoryContext)
	const [extracting, setExtracting] = useState(false)
	const borderStr = '2px solid lightGray'

	const exportToExcel = () => {
		setExtracting(true)
		exportInventory(inventoryId)
			.then(response => {
				let fileName = `Extract - Inventory_1 (${moment(new Date()).format('M-DD-YYYY')}).xlsx`
				let a = document.createElement('a')
				let file = new Blob([response], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				})
				let fileURL = window.URL.createObjectURL(file)
				a.href = fileURL
				a.target = '_blank'
				a.download = fileName
				a.click()
			})
			.catch(e => console.error(e))
			.finally(() => {
				setExtracting(false)
			})
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='60%' height='50%' position='relative' data-cy='card-modal-details'>
				<LoadingOverlay visible={loadingDetails} borderRadius='card' />
				{inventoryResult && (
					<>
						<FlexView
							width='-webkit-fill-available'
							flexDirection='row'
							justifyContent='space-between'
							backgroundColor='info'
							fontSize='title'
							fontWeight='bold'
							color='white'
							margin='-16px -24px 8px'
							padding='8px 16px'
							borderRadius='8px 8px 0 0'>
							{t('wms:Rack')}: {name} (
							{inventoryResult && DATE_FORMAT(inventoryResult.lastinventorydate, i18n.language)})
							<Icon
								name='download'
								width='24px'
								height='24px'
								margin='8px'
								color='white'
								fontWeight='bold'
								onClick={() => exportToExcel()}
								tooltip={t('wms:ExportExcel')}
								tooltipPosition='top'
							/>
							<LoadingOverlay visible={extracting} borderRadius='card' />
						</FlexView>
						<FlexView
							flexDirection='row'
							width='100%'
							justifyContent='space-around'
							style={{ borderBottom: borderStr }}>
							<FlexView
								flexDirection='column'
								width='100%'
								alignItems='center'
								style={{ borderRight: borderStr }}>
								<FlexView fontWeight='bold' color='metalic' fontSize='small'>
									{t('wms:InventoryResponsible')}
								</FlexView>
								<FlexView fontWeight='bold' fontSize='medium' data-cy='list-label'>
									{inventoryResult.responsible}
								</FlexView>
							</FlexView>
							<FlexView
								flexDirection='column'
								width='100%'
								alignItems='center'
								style={{ borderRight: borderStr }}>
								<FlexView fontWeight='bold' color='metalic' fontSize='small'>
									{t('wms:InventoryDeviation')}
								</FlexView>
								<FlexView fontWeight='bold' fontSize='medium' data-cy='list-label'>
									{inventoryResult.errorpercentage} %
								</FlexView>
							</FlexView>
							<FlexView
								flexDirection='column'
								width='100%'
								alignItems='center'
								style={{ borderRight: borderStr }}>
								<FlexView fontWeight='bold' color='metalic' fontSize='small'>
									{t('wms:InventoryConform')}
								</FlexView>
								<FlexView fontWeight='bold' fontSize='medium' data-cy='list-label'>
									{inventoryResult.foundok.length}
								</FlexView>
							</FlexView>
							<FlexView
								flexDirection='column'
								width='100%'
								alignItems='center'
								style={{ borderRight: borderStr }}>
								<FlexView fontWeight='bold' color='metalic' fontSize='small'>
									{t('wms:InventoryMoved')}
								</FlexView>
								<FlexView fontWeight='bold' fontSize='medium' data-cy='list-label'>
									{inventoryResult.movedhere.length}
								</FlexView>
							</FlexView>
							<FlexView
								flexDirection='column'
								width='100%'
								alignItems='center'
								style={{ borderRight: borderStr }}>
								<FlexView fontWeight='bold' color='metalic' fontSize='small'>
									{t('wms:InventoryNotFound')}
								</FlexView>
								<FlexView fontWeight='bold' fontSize='medium' data-cy='list-label'>
									{inventoryResult.becameghost.length}
								</FlexView>
							</FlexView>
							<FlexView flexDirection='column' width='100%' alignItems='center'>
								<FlexView fontWeight='bold' color='metalic' fontSize='small'>
									{t('wms:InventoryUnknown')}
								</FlexView>
								<FlexView fontWeight='bold' fontSize='medium' data-cy='list-label'>
									{inventoryResult.notfound.length}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView
							flexDirection='row'
							width='100%'
							height='100%'
							justifyContent='space-around'
							margin='16px 0 0 0'
							padding='0'
							style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
							<FlexView
								flexDirection='column'
								width='33%'
								height='100%'
								alignItems='center'
								maxHeight='100%'
								margin='0 8px 0 0'>
								<FlexView
									fontWeight='bold'
									fontSize='medium'
									width='100%'
									alignItems='center'
									style={{ borderBottom: borderStr }}>
									{t('wms:InventoryReceived')}
								</FlexView>
								{inventoryResult.received.map((received, i) => (
									<FlexView
										alignItems='center'
										key={i}
										width='90%'
										margin='8px 0 0 0'
										style={{ border: borderStr, borderRadius: '8px' }}
										data-cy='list-label'>
										{received}
									</FlexView>
								))}
							</FlexView>
							<FlexView
								flexDirection='column'
								width='33%'
								height='100%'
								maxHeight='100%'
								alignItems='center'
								margin='0 8px 0 0'>
								<FlexView
									fontWeight='bold'
									fontSize='medium'
									alignItems='center'
									width='100%'
									style={{ borderBottom: borderStr }}>
									{t('wms:InventoryBefore')}
								</FlexView>
								{inventoryResult.before.map((before, i) => (
									<FlexView
										alignItems='center'
										key={i}
										width='90%'
										margin='8px 0 0 0'
										style={{ border: borderStr, borderRadius: '8px' }}
										data-cy='list-label'>
										{before}
									</FlexView>
								))}
							</FlexView>
							<FlexView
								flexDirection='column'
								width='34%'
								height='100%'
								maxHeight='100%'
								alignItems='center'
								margin='0 0 0 8px'>
								<FlexView
									fontWeight='bold'
									fontSize='medium'
									alignItems='center'
									width='100%'
									style={{ borderBottom: borderStr }}>
									{t('wms:InventoryAfter')}
								</FlexView>
								{inventoryResult.after.map((after, i) => (
									<FlexView
										alignItems='center'
										key={i}
										width='90%'
										margin='8px 0 0 0'
										style={{ border: borderStr, borderRadius: '8px' }}
										data-cy='list-label'>
										{after}
									</FlexView>
								))}
							</FlexView>
						</FlexView>
					</>
				)}
			</Card>
		</Modal>
	)
}

export default InventoryDetailsModal
