/** @format */

import { FlexView } from 'components/common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PageTemplate from '../containers/common/PageTemplate'
import InventoryTable from '../containers/Inventory/InventoryTable'

const InventoryPage = () => {
	const { t } = useTranslation()

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Inventory'),
				path: '/wms/manage/inventory/check/'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:Inventory')}
				</FlexView>
				<InventoryTable />
			</FlexView>
		</PageTemplate>
	)
}

export default InventoryPage
