/** @format */
import React, { useCallback, useContext, useState } from 'react'
import Card from 'components/common/Card'
import FlexView from 'components/common/FlexView'
import Button from 'components/common/Button'
import { Icon, Modal } from 'components/common'
import { useTranslation } from 'react-i18next'
import { ThreadCompoundContext } from 'apps/wms/stores/AssetsThreadCompoundStore'
import { THREAD_COMPOUND_TYPE } from 'apps/wms/utils/enum'
import { Input, RadioGroup } from 'components/form'

const NewTypeModal = () => {
	const { t } = useTranslation()
	const { typesList, setTypesList, newType, isTypeModalOpen, setIsTypeModalOpen } = useContext(ThreadCompoundContext)

	const typeData = {
		typedesc: '',
		state: THREAD_COMPOUND_TYPE.code.STORAGE,
		sortindex: 0,
		originalSortIndex: 0
	}
	const [newTypeData, setNewTypeData] = useState(typeData)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const resetTypeData = () => setNewTypeData(typeData)

	const _onNewType = async (typeData, newTypeFn, list, setListFn, resetDataFn) => {
		try {
			typeData.sortindex = list.length ? list[list.length - 1].sortindex + 1 : 1
			const typeid = await newTypeFn(typeData)
			setListFn([
				...list,
				{
					typedesc: typeData.typedesc,
					state: typeData.state,
					sortindex: typeData.sortindex,
					originalSortIndex: typeData.sortindex,
					typeid: typeid
				}
			])
			resetDataFn()
		} catch (error) {
			console.error(error)
		}
	}

	const onNewType = useCallback(
		async newTypeData => _onNewType(newTypeData, newType, typesList, setTypesList, resetTypeData),
		[newType, typesList, setTypesList, resetTypeData]
	)

	return (
		<Modal isOpen={isTypeModalOpen} onOutsideClick={() => setIsTypeModalOpen(false)}>
			<Card width='auto' data-cy='card-new-type-form-wrapper'>
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:New')} {t('wms:Type')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' onClick={() => setIsTypeModalOpen(false)}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flexDirection='row' justifyContent='space-between'>
					<Input
						type='text'
						label={`${t('wms:New')} ${t('wms:Type')}/${t('wms:Manufacturer')}`}
						placeholder={`${t('wms:New')} ${t('wms:Type')}/${t('wms:Manufacturer')}`}
						value={newTypeData.typedesc}
						onChange={e => setNewTypeData({ ...newTypeData, typedesc: e.target.value })}
						margin='0'
						minWidth='50%'
						max='20'
						data-cy='input-new-type-name'
					/>
					<RadioGroup
						inline
						name='typeState'
						label={`${t('wms:state')}`}
						value={newTypeData.state}
						margin='0 24px'
						data-cy='input-new-type-state'
						onChange={v => setNewTypeData({ ...newTypeData, state: v })}
						options={Object.values(THREAD_COMPOUND_TYPE.code).map(code => ({
							label: t(THREAD_COMPOUND_TYPE.translateKey[code]),
							value: code
						}))}
					/>
					<FlexView>
						<FlexView padding='22px 1px 0 0'></FlexView>
						<Button
							margin='8px 0'
							padding='8px 16px'
							color='white'
							backgroundColor='success'
							data-cy='button-save-new-type'
							onClick={() => onNewType(newTypeData)}>
							{`${t('wms:Save')}`}
							<Icon name='save' color='white' height='12px' width='12px' margin='0 0 0 8px' />
						</Button>
					</FlexView>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default NewTypeModal
