/** @format */
/* eslint-disable react/prop-types */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, LoadingOverlay, Button } from 'components/common'
import { REPORT_STATUS } from '../../utils/enum'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import theme from 'utils/theme'

const InspReportInfo = ({ details, loadingInfo = false, onCheckReport, onDownloadReport, subRow }) => {
	const { t, i18n } = useTranslation()

	const getStatus = () => {
		if (details && Object.keys(details).length === 0) return null

		let initialStatus = ''
		let isPendingWithDate

		const pendingStatus =
			details.overall_validation_status === REPORT_STATUS.code.PENDING ||
			details.status === REPORT_STATUS.code.PENDING

		const validatedStatus =
			details.overall_validation_status === REPORT_STATUS.code.VALIDATED ||
			details.status === REPORT_STATUS.code.VALIDATED
		const rejectedStatus =
			details.overall_validation_status === REPORT_STATUS.code.REJECTED ||
			details.status === REPORT_STATUS.code.REJECTED

		pendingStatus && details.validation_date && details.validation_revision > 0
			? (isPendingWithDate = true)
			: (isPendingWithDate = false)

		if (isPendingWithDate) initialStatus = t(`wms:Updated`)
		else initialStatus = t(`${REPORT_STATUS.translateKey[details.overall_validation_status || details.status]}`)
		if ((isPendingWithDate && details.validation_responsible) || validatedStatus || rejectedStatus)
			initialStatus += ` ${t('wms:By')} ${details.validation_responsible} ${t('wms:On')} ${DATE_FORMAT(
				details.validation_date,
				i18n.language
			)}`

		return initialStatus
	}

	const getRevisionResp = reportInfo =>
		reportInfo.plan_responsible && reportInfo.plan_date
			? `- ${t('wms:CreatedBy')} ${reportInfo.plan_responsible} ${t('wms:On')}
		${DATE_FORMAT(reportInfo.plan_date, i18n.language)}`
			: ''

	const handleCheckReport = () => {
		onCheckReport()
	}

	const handleDownloadReport = (reportId, partNumber) => {
		const isGeneralReport = details.partid === null
		reportId != null && onDownloadReport(reportId, isGeneralReport ? null : partNumber)
	}

	const displayCheckReportButton = () => {
		const isCorrectStatus =
			details.status === REPORT_STATUS.code.REJECTED || details.status === REPORT_STATUS.code.PENDING
		const hasMultipleParts = details.parts && details.parts.length > 1
		return (isCorrectStatus && !hasMultipleParts) || (isCorrectStatus && hasMultipleParts && subRow != null)
			? true
			: false
	}

	return (
		<Card data-cy='card-info-wrapper' height='90%' width='30%' margin='0 0 0 8px' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingInfo} borderRadius='card' />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:ReportDetails')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				height='90%'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'
				style={{ overflowY: 'auto', overflowX: 'hidden' }}>
				{details.id && (
					<FlexView
						data-cy='flexview-common-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							ID
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.id}
						</FlexView>
					</FlexView>
				)}
				{details.part && (
					<FlexView
						data-cy='flexview-common-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:Part')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.part}
						</FlexView>
					</FlexView>
				)}
				{(details.validation_date || details.date) && (
					<FlexView
						data-cy='flexview-common-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:Date')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{DATE_FORMAT(details.validation_date || details.date, i18n.language)}
						</FlexView>
					</FlexView>
				)}
				{getRevisionResp(details) && (
					<FlexView
						data-cy='flexview-common-info'
						width='100%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:Revision')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.revision || details.validation_revision} {getRevisionResp(details)}
						</FlexView>
					</FlexView>
				)}
				{getStatus() != null && (
					<FlexView
						data-cy='flexview-common-info'
						width='100%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							Status
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{getStatus()}
						</FlexView>
					</FlexView>
				)}
				{details.enduser && (
					<FlexView
						data-cy='flexview-mr-info'
						width='50%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:EndUser')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.enduser}
						</FlexView>
					</FlexView>
				)}
				{details.target_ownership && (
					<FlexView
						data-cy='flexview-mr-info'
						width='50%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:TargetOwnership')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.target_ownership}
						</FlexView>
					</FlexView>
				)}
				{details.customer_request_reference && (
					<FlexView
						data-cy='flexview-mr-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:CustomerRequestReference')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.customer_request_reference}
						</FlexView>
					</FlexView>
				)}
				{details.field_name && (
					<FlexView
						data-cy='flexview-mr-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:FieldName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.field_name}
						</FlexView>
					</FlexView>
				)}
				{details.well_name && (
					<FlexView
						data-cy='flexview-mr-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:WellName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.well_name}
						</FlexView>
					</FlexView>
				)}
				{details.rig_name && (
					<FlexView
						data-cy='flexview-mr-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:RigName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.rig_name}
						</FlexView>
					</FlexView>
				)}
				{details.tparty_name && (
					<FlexView
						data-cy='flexview-mr-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:ThirdPartyName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.tparty_name}
						</FlexView>
					</FlexView>
				)}
				{details.tparty_location && (
					<FlexView
						data-cy='flexview-mr-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:ThirdPartyLocation')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.tparty_location}
						</FlexView>
					</FlexView>
				)}
				{details.delivery_location && (
					<FlexView
						data-cy='flexview-mr-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:DeliveryLocation')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.delivery_location}
						</FlexView>
					</FlexView>
				)}
				{details.ltpa_number && (
					<FlexView
						data-cy='flexview-mr-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:NumberLTPA')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.ltpa_number}
						</FlexView>
					</FlexView>
				)}
				{details.work_order_description && (
					<FlexView
						data-cy='flexview-info'
						width='33%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='column'
						fontSize='small'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:WorkOrderDescription')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{details.work_order_description}
						</FlexView>
					</FlexView>
				)}

				{details.parts &&
					details.parts.length &&
					details.parts.map(report => (
						<FlexView
							key={report.partid}
							data-cy='flexview-info'
							width='100%'
							margin='0 0 8px 0'
							padding='0'
							flexDirection='row'
							flexWrap='wrap'
							fontSize='small'
							style={{ borderTop: '1px solid whitesmoke' }}>
							{details.parts.length > 1 && (
								<FlexView width='100%' flexDirection='row' justifyContent='space-between' margin='4px 0 8px 0'>
									<FlexView width='80%' fontSize='small' fontWeight='bold' margin='0'>
										{`${t('wms:Part')} ${report.part}`}
									</FlexView>
									<FlexView
										width='20%'
										fontSize='small'
										fontWeight='bold'
										color={REPORT_STATUS.color[report.status]}
										style={{ borderLeft: `2px solid ${theme.colors[REPORT_STATUS.color[report.status]]}` }}
										margin='0 0 0 auto'
										padding='8px 16px'>
										{t(`wms:${REPORT_STATUS.literal[report.status]}`)}
									</FlexView>
								</FlexView>
							)}
							{report.erp_reference.label && (
								<FlexView
									data-cy='flexview-common-info'
									width='33%'
									margin='0 0 8px 0'
									padding='0'
									fontSize='small'>
									<FlexView
										width='100%'
										fontWeight='bold'
										fontSize='tiny'
										color='metalic'
										margin={details.parts.length > 1 ? '0' : '8px 0 0 0'}>
										{t('wms:ErpReference')}
									</FlexView>
									<FlexView width='100%' margin='0'>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{report.erp_reference.label}
										</FlexView>
									</FlexView>
								</FlexView>
							)}
							{report.erplist && report.erplist.length > 0 && (
								<FlexView
									data-cy='flexview-common-info'
									width='100%'
									margin='0 0 8px 0'
									padding='0'
									fontSize='small'
									flexDirection='column'>
									<FlexView
										width='95%'
										fontWeight='bold'
										fontSize='tiny'
										color='metalic'
										margin={details.parts.length > 1 ? '0' : '8px 0 0 0'}>
										{t('wms:ErpReferences')}
									</FlexView>
									<FlexView width='100%' margin='0' flexDirection='row' flexWrap='wrap'>
										{report.erplist.map(erp => (
											<FlexView
												key={erp.id}
												width='25%'
												style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
												padding='0 0 0 8px'>
												{erp.label}
											</FlexView>
										))}
									</FlexView>
								</FlexView>
							)}
							{report.materiallist && report.materiallist.length > 0 && (
								<FlexView
									data-cy='flexview-common-info'
									width='95%'
									margin='0 0 8px 0'
									padding='0'
									fontSize='small'>
									<FlexView
										width='100%'
										fontWeight='bold'
										fontSize='tiny'
										color='metalic'
										margin={details.parts.length > 1 ? '0' : '8px 0 0 0'}>
										{t('wms:Materials')}
									</FlexView>
									{report.materiallist.map(material => (
										<FlexView width='100%' margin='0' key={material.mvid}>
											<FlexView
												width='100%'
												style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
												padding='0 0 0 8px'>
												{material.formatted}
											</FlexView>
										</FlexView>
									))}
								</FlexView>
							)}
							{report.customerorder && (
								<FlexView
									data-cy='flexview-common-info'
									width='33%'
									margin='0 0 8px 0'
									padding='0'
									flexDirection='column'
									fontSize='small'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:CustomerOrder')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{report.customerorder}
									</FlexView>
								</FlexView>
							)}
							{report.customeritem && (
								<FlexView
									data-cy='flexview-common-info'
									width='33%'
									margin='0 0 8px 0'
									padding='0'
									flexDirection='column'
									fontSize='small'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:CustomerItem')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{report.customeritem}
									</FlexView>
								</FlexView>
							)}
							{report.salesorder && (
								<FlexView
									data-cy='flexview-common-info'
									width='33%'
									margin='0 0 8px 0'
									padding='0'
									flexDirection='column'
									fontSize='small'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:SalesOrder')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{report.salesorder}
									</FlexView>
								</FlexView>
							)}
							{report.salesitem && (
								<FlexView
									data-cy='flexview-common-info'
									width='33%'
									margin='0 0 8px 0'
									padding='0'
									flexDirection='column'
									fontSize='small'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:SalesItem')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{report.salesitem}
									</FlexView>
								</FlexView>
							)}
							{report.material.formatted && (
								<FlexView
									data-cy='flexview-common-info'
									width='95%'
									margin='0 0 8px 0'
									padding='0'
									flexDirection='column'
									fontSize='small'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:Material')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{report.material.formatted}
									</FlexView>
								</FlexView>
							)}
							{report.status === REPORT_STATUS.code.VALIDATED && (
								<FlexView width='100%' flexDirection='row' justifyContent='space-between'>
									<FlexView
										data-cy='flexview-common-info'
										width='33%'
										margin='0 0 8px 0'
										padding='0'
										flexDirection='column'
										fontSize='small'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:TotalQuantity')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{report.qtyByStatus.totalquantity}
										</FlexView>
									</FlexView>
									<FlexView
										data-cy='flexview-common-info'
										width='33%'
										margin='0 0 8px 0'
										padding='0'
										flexDirection='column'
										fontSize='small'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:Accepted')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{report.qtyByStatus.accepted}
										</FlexView>
									</FlexView>
									<FlexView
										data-cy='flexview-common-info'
										width='33%'
										margin='0 0 8px 0'
										padding='0'
										flexDirection='column'
										fontSize='small'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:StatusToBeRepaired')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{report.qtyByStatus.toberepaired}
										</FlexView>
									</FlexView>
									<FlexView
										data-cy='flexview-common-info'
										width='33%'
										margin='0 0 8px 0'
										padding='0'
										flexDirection='column'
										fontSize='small'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:StatusToBeScrapped')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{report.qtyByStatus.tobescrapped}
										</FlexView>
									</FlexView>
								</FlexView>
							)}
						</FlexView>
					))}
				<FlexView
					data-cy='flexview-info'
					width='100%'
					margin='0 0 8px 0'
					padding='0'
					flexDirection='row'
					flexWrap='wrap'
					fontSize='small'
					style={{ borderTop: '1px solid whitesmoke' }}>
					{details.erp_reference && (
						<FlexView data-cy='flexview-common-info' width='33%' margin='0 0 8px 0' padding='0' fontSize='small'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='8px 0 0 0'>
								{t('wms:ErpReference')}
							</FlexView>
							<FlexView width='100%' margin='0'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{details.erp_reference}
								</FlexView>
							</FlexView>
						</FlexView>
					)}
					{details.erplist && details.erplist.length ? (
						<FlexView
							data-cy='flexview-common-info'
							width='100%'
							margin='0 0 8px 0'
							padding='0'
							fontSize='small'
							flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='8px 0 0 0'>
								{t('wms:ErpReferences')}
							</FlexView>
							<FlexView width='100%' margin='0' flexDirection='row' flexWrap='wrap'>
								{details.erplist.map(erp => (
									<FlexView
										key={erp.id}
										width='25%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{erp.label}
									</FlexView>
								))}
							</FlexView>
						</FlexView>
					) : null}
					{details.materiallist && details.materiallist.length ? (
						<FlexView data-cy='flexview-common-info' width='100%' margin='0 0 8px 0' padding='0' fontSize='small'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='8px 0 0 0'>
								{t('wms:Materials')}
							</FlexView>
							{details.materiallist.map(material => (
								<FlexView width='100%' margin='0' key={material.mvid}>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{material.formatted}
									</FlexView>
								</FlexView>
							))}
						</FlexView>
					) : null}

					{details.customer_order && (
						<FlexView
							data-cy='flexview-common-info'
							width='33%'
							margin='0 0 8px 0'
							padding='0'
							flexDirection='column'
							fontSize='small'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:CustomerOrder')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{details.customer_order}
							</FlexView>
						</FlexView>
					)}
					{details.customer_item && (
						<FlexView
							data-cy='flexview-common-info'
							width='33%'
							margin='0 0 8px 0'
							padding='0'
							flexDirection='column'
							fontSize='small'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:CustomerItem')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{details.customer_item}
							</FlexView>
						</FlexView>
					)}
					{details.sales_order && (
						<FlexView
							data-cy='flexview-common-info'
							width='33%'
							margin='0 0 8px 0'
							padding='0'
							flexDirection='column'
							fontSize='small'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:SalesOrder')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{details.sales_order}
							</FlexView>
						</FlexView>
					)}
					{details.sales_item && (
						<FlexView
							data-cy='flexview-common-info'
							width='33%'
							margin='0 0 8px 0'
							padding='0'
							flexDirection='column'
							fontSize='small'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:SalesItem')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{details.sales_item}
							</FlexView>
						</FlexView>
					)}
					{details.material_desc && (
						<FlexView
							data-cy='flexview-common-info'
							width='100%'
							margin='0 0 8px 0'
							padding='0'
							flexDirection='column'
							fontSize='small'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:Material')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{details.material_desc}
							</FlexView>
						</FlexView>
					)}
					{details.status === REPORT_STATUS.code.VALIDATED && (
						<FlexView width='100%' flexDirection='row' justifyContent='space-between'>
							{details.total_quantity != null && (
								<FlexView
									data-cy='flexview-common-info'
									width='33%'
									margin='0 0 8px 0'
									padding='0'
									flexDirection='column'
									fontSize='small'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:TotalQuantity')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{details.total_quantity}
									</FlexView>
								</FlexView>
							)}
							{details.accepted_quantity != null && (
								<FlexView
									data-cy='flexview-common-info'
									width='33%'
									margin='0 0 8px 0'
									padding='0'
									flexDirection='column'
									fontSize='small'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:Accepted')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{details.accepted_quantity}
									</FlexView>
								</FlexView>
							)}
							{details.toberepaired != null && (
								<FlexView
									data-cy='flexview-common-info'
									width='33%'
									margin='0 0 8px 0'
									padding='0'
									flexDirection='column'
									fontSize='small'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:StatusToBeRepaired')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{details.toberepaired}
									</FlexView>
								</FlexView>
							)}
							{details.tobescrapped != null && (
								<FlexView
									data-cy='flexview-common-info'
									width='33%'
									margin='0 0 8px 0'
									padding='0'
									flexDirection='column'
									fontSize='small'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:StatusToBeScrapped')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{details.tobescrapped}
									</FlexView>
								</FlexView>
							)}
						</FlexView>
					)}
				</FlexView>
			</FlexView>
			<FlexView width='100%' margin='auto 0 0 auto' flexDirection='row'>
				{details.id != null &&
				(details.status === REPORT_STATUS.code.VALIDATED ||
					details.overall_validation_status === REPORT_STATUS.code.VALIDATED) ? (
					<Button
						width='100%'
						fontSize='small'
						margin='4px 0 4px 4px'
						color='white'
						backgroundColor='primary'
						elevation='none'
						onClick={() => {
							handleDownloadReport(details.id, details.part)
						}}
						data-cy='button-download-report'>
						{t('wms:DownloadReport')}
					</Button>
				) : (
					displayCheckReportButton() && (
						<Button
							width='100%'
							fontSize='small'
							margin='4px 0 4px 4px'
							color='white'
							backgroundColor='secondary'
							onClick={() => {
								handleCheckReport()
							}}
							data-cy='button-check-report'>
							{t('wms:CheckReport')}
						</Button>
					)
				)}
			</FlexView>
		</Card>
	)
}

export default InspReportInfo
