/** @format */

import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'
import { toast } from 'react-toastify'
import reports from '../api/reports'
import formatReportData from '../utils/dataformater'
import instruments from '../api/instruments'
import ageManagement from '../api/ageManagement'
import assetsThreadCompound from '../api/assetsThreadCompound'
import formatAgeReports from '../utils/agemgmtdetailsformatter'

export const ReportDataContext = React.createContext()

export const ReportDataProvider = ({ children }) => {
	const { warehouse } = useContext(WMSContext)
	const { token, user } = useContext(UserContext)
	const { t } = useTranslation()
	const [isLoadingData, setIsLoadingData] = useState(false)
	const [rejectReasonsList, setRejectReasonsList] = useState([])
	const [availableInstruments, setAvailableInstruments] = useState([])
	const [instrumentTypes, setInstrumentTypes] = useState([])
	const [threadCompoundTypes, setThreadCompoundTypes] = useState([])
	const [applicationMethods, setApplicationMethods] = useState([])
	const warehouseNotDefined = t('wms:WarehouseNotDefined')
	const remarks = 'Edited on report editor'
	const errorDialogMessage = t('wms:ErrorDialogMessage')

	const getReceiptNoteReportRawData = (reportType, reportId, partId) =>
		new Promise((resolve, reject) => {
			reports
				.getReceiptNoteReportData(warehouse.id, reportType, reportId, partId, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingReportData')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	const getReceiptNoteReportData = (reportType, reportId, partId) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			const hydrate = async () => {
				try {
					setIsLoadingData(true)
					const rawData = await getReceiptNoteReportRawData(reportType, reportId, partId)
					const formattedData = formatReportData(rawData, reportType, true)
					resolve(formattedData)
				} catch (e) {
					console.error(e)
				} finally {
					setIsLoadingData(false)
				}
			}
			hydrate()
		})

	const getAgeManagementReportData = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			setIsLoadingData(true)
			ageManagement
				.getAgeManagementIssuedList(warehouse.id, reportId, token)
				.then(response => {
					let formattedData = formatAgeReports(response.list, response.ltpa)
					resolve(formattedData)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingReportData')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => {
					setIsLoadingData(false)
				})
		})

	const getRejectReasons = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else
				reports
					.getRejectReasons(warehouse.id, token)
					.then(response => resolve(response))
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingDefectList')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
					.finally(() => setIsLoadingData(false))
		})

	const getAvailableInstruments = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				instruments
					.getAvailableInstruments(warehouse.id, token)
					.then(response => resolve(response))
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingInstruments')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
					.finally(() => setIsLoadingData(false))
			}
		})

	const getInstrumentsTypes = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				instruments
					.getInstrumentsTypes(warehouse.id, token)
					.then(response => resolve(response))
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingInstrumentTypes')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
					.finally(() => setIsLoadingData(false))
			}
		})

	const getThreadCompoundTypes = () =>
		new Promise((resolve, reject) => {
			warehouse.id &&
				assetsThreadCompound
					.getTypesList(warehouse.id, token)
					.then(response => resolve(response))
					.catch(e => {
						toast.error(`${t('wms:ErrorGetting')} ${t('wms:ThreadCompoundTypes')} [ ${e.status} ] : ${e.data}`)
						reject(e)
					})
		})

	const getApplicationMethod = () =>
		new Promise((resolve, reject) => {
			warehouse.id &&
				assetsThreadCompound
					.getMethodsList(warehouse.id, token)
					.then(response => resolve(response))
					.catch(e => {
						reject(e)
						toast.error(`${t('wms:ErrorGetting')} ${t('wms:ApplicationMethods')} [ ${e.data} ]: ${e.status}`)
					})
		})

	const rejectReport = (reportType, reportId, revision) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			const payload = {
				responsible: `${user.firstName} ${user.lastName}`,
				remarks: remarks,
				revision: revision
			}
			reports
				.rejectReport(warehouse.id, reportType, reportId, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(`${errorDialogMessage} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const rejectAgeMgmtReport = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			const payload = {
				responsible: `${user.firstName} ${user.lastName}`,
				remarks: remarks
			}
			ageManagement
				.rejectReport(warehouse.id, reportId, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(`${errorDialogMessage} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const reopenMillReceipt = (reportId, remarks, revision) =>
		new Promise((resolve, reject) => {
			const payload = {
				responsible: `${user.firstName} ${user.lastName}`,
				remarks: remarks,
				revision: revision
			}
			reports
				.reopenReceiptNote(warehouse.id, reportId, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullyReopenedReport'))
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorReopeningReport')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const validateReport = (reportType, reportId, revision) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			const payload = {
				responsible: `${user.firstName} ${user.lastName}`,
				remarks: remarks,
				revision: revision
			}
			reports
				.validateReport(warehouse.id, reportType, reportId, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(`${errorDialogMessage} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const validateAgeMgmtReport = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			const payload = {
				responsible: `${user.firstName} ${user.lastName}`
			}
			ageManagement
				.validateReport(warehouse.id, reportId, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(`${errorDialogMessage} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const getNewInstrumentCase = instrumentIds =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}

			const payload = {
				uid: user.id,
				ids: instrumentIds
			}

			instruments
				.getNewInstrumentCase(warehouse.id, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(`${t('wms:ErrorUpdatingEquipments')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	const releaseInstruments = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(t(warehouseNotDefined)))
			}

			instruments
				.releaseInstruments(warehouse.id, user.id, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(`${t('wms:ErrorUpdatingEquipments')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => setIsLoadingData(false))
		})

	useEffect(() => {
		getRejectReasons()
			.then(list => setRejectReasonsList(list))
			.catch(e => console.error(e))
		getAvailableInstruments()
			.then(list => setAvailableInstruments(list))
			.catch(e => console.error(e))
		getInstrumentsTypes()
			.then(list => setInstrumentTypes(list))
			.catch(e => console.error(e))
		getThreadCompoundTypes()
			.then(list => setThreadCompoundTypes(list))
			.catch(e => console.error(e))
		getApplicationMethod()
			.then(list => setApplicationMethods(list))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	return (
		<ReportDataContext.Provider
			value={{
				isLoadingData,
				getReceiptNoteReportData,
				getAgeManagementReportData,
				rejectReasonsList,
				availableInstruments,
				instrumentTypes,
				getNewInstrumentCase,
				releaseInstruments,
				user,
				rejectReport,
				rejectAgeMgmtReport,
				validateReport,
				validateAgeMgmtReport,
				threadCompoundTypes,
				setThreadCompoundTypes,
				applicationMethods,
				setApplicationMethods,
				reopenMillReceipt
			}}>
			{children}
		</ReportDataContext.Provider>
	)
}
