/** @format */
/* eslint-disable react/prop-types */

import React from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { FlexView, Card, Button, LoadingOverlay } from 'components/common'
import { REPORT_STATUS, REPORT_TYPE } from '../../utils/enum'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { isReceiptNoteReport } from '../reportEditor/businessRules'
import theme from 'utils/theme'

const ReviewReportInfo = ({
	reportInfo,
	reportPart,
	reviewType,
	onCheckReport,
	onDownloadReport,
	loadingInfo = false,
	extractName,
	downloadingReport
}) => {
	const { t, i18n } = useTranslation()

	const part = () => {
		if (isReceiptNoteReport(reviewType, false) && reportPart)
			return _.find(reportInfo.content, c => c.partnumber === reportPart)
		return null
	}

	const handleDownloadReport = item => {
		item != null &&
			(isReceiptNoteReport(reviewType, false) ? onDownloadReport(item.id, reportPart) : onDownloadReport(item))
	}

	const handleCheckReport = item => {
		item != null && onCheckReport(item)
	}

	const getStatus = () => {
		let initialStatus = ''
		if (reportPart) {
			const content = reportInfo.content ? _.find(reportInfo.content, row => row.partnumber === reportPart) : null
			const status = content.validationdata.status
			const validationresp = content.validationdata?.responsible
			const validationdate = content.validationdata?.laststatuschange
			if (status === REPORT_STATUS.code.PENDING && validationdate) initialStatus = t(`wms:Updated`)
			else initialStatus = t(`${REPORT_STATUS.translateKey[status]}`)

			if (
				(status === REPORT_STATUS.code.PENDING && validationdate) ||
				status === REPORT_STATUS.code.VALIDATED ||
				status === REPORT_STATUS.code.REJECTED
			) {
				initialStatus += ` ${t('wms:By')} ${validationresp} ${t('wms:On')} ${DATE_FORMAT(
					validationdate,
					i18n.language
				)}`
			}
		} else {
			if (reportInfo.status === REPORT_STATUS.code.PENDING && reportInfo.validationdate)
				initialStatus = t(`wms:Updated`)
			else initialStatus = t(`${REPORT_STATUS.translateKey[reportInfo.status]}`)

			if (
				(reportInfo.status === REPORT_STATUS.code.PENDING && reportInfo.validationdate) ||
				reportInfo.status === REPORT_STATUS.code.VALIDATED ||
				reportInfo.status === REPORT_STATUS.code.REJECTED
			) {
				let content = _.chain(reportInfo.content)
					.orderBy(o => o.validationdata.laststatuschange, 'desc')
					.first()
					.value()
				const validationresp = reportInfo.validationresp
					? reportInfo.validationresp
					: content && content.validationdata && content.validationdata.responsible
				const validationdate = reportInfo.validationdate
					? reportInfo.validationdate
					: content && content.validationdata && content.validationdata.laststatuschange
				initialStatus += ` ${t('wms:By')} ${validationresp} ${t('wms:On')} ${DATE_FORMAT(
					validationdate,
					i18n.language
				)}`
			}
		}
		return initialStatus
	}

	const renderButtons = (item, part) => {
		const status = !part
			? item.status
			: _.find(item.content, c => c.partnumber === reportPart)?.validationdata?.status
		return (
			<>
				{status === REPORT_STATUS.code.VALIDATED ? (
					<Button
						width='100%'
						fontSize='tiny'
						margin='auto 0 0 0'
						color='white'
						backgroundColor='primary'
						disabled={false}
						onClick={() => {
							handleDownloadReport(item, reportPart)
						}}
						data-cy='button-download-report'>
						{t('wms:DownloadReport')}
					</Button>
				) : null}
				{part && (status === REPORT_STATUS.code.PENDING || status === REPORT_STATUS.code.REJECTED) ? (
					<Button
						width='100%'
						fontSize='tiny'
						margin='auto 0 0 0'
						color='white'
						backgroundColor='secondary'
						title={t('wms:CheckReport')}
						onClick={() => {
							handleCheckReport(item.id, reportPart)
						}}
						data-cy='button-check-report'>
						{t('wms:CheckReport')}
					</Button>
				) : null}
				<LoadingOverlay visible={downloadingReport} />
			</>
		)
	}

	const ageManagementInfo = (
		<>
			<FlexView
				data-cy='flexview-age-info'
				width='50%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Id')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.id}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-age-info'
				width='50%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:ThresholdDate')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{DATE_FORMAT(reportInfo.extractiondate, i18n.language)}
				</FlexView>
			</FlexView>
		</>
	)

	const millReceiptInfo = (
		<>
			<FlexView
				data-cy='flexview-mill-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Name')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.name}
				</FlexView>
			</FlexView>
			{reportPart && (
				<FlexView
					data-cy='flexview-common-info'
					width='33%'
					margin='0 0 8px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{t('wms:Part')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{reportPart}
					</FlexView>
				</FlexView>
			)}
			<FlexView
				data-cy='flexview-mill-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Id')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.id}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-mill-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:EntranceDate')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{DATE_FORMAT(reportInfo.closuredate, i18n.language)}
				</FlexView>
			</FlexView>
			<FlexView
				data-cy='flexview-mill-info'
				width='33%'
				margin='0 0 16px 0'
				padding='0'
				flexDirection='column'
				fontSize='small'>
				<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
					{t('wms:Revision')}
				</FlexView>
				<FlexView
					width='100%'
					style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
					padding='0 0 0 8px'>
					{reportInfo.revision}
				</FlexView>
			</FlexView>
			{reportInfo && reportInfo.customerpo && reportInfo.customeritem ? (
				<>
					<FlexView
						data-cy='flexview-mill-info'
						width='66%'
						margin='0 0 16px 0'
						padding='0'
						flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:CustomerOrder')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportInfo.customerpo}
						</FlexView>
					</FlexView>
					<FlexView
						data-cy='flexview-mill-info'
						width='33%'
						margin='0 0 16px 0'
						padding='0'
						flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:CustomerItem')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportInfo.customeritem}
						</FlexView>
					</FlexView>
				</>
			) : null}
			{reportInfo && reportInfo.salesorder && reportInfo.salesitem ? (
				<>
					<FlexView
						data-cy='flexview-mill-info'
						width='66%'
						margin='0 0 16px 0'
						padding='0'
						flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:SalesOrder')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportInfo.salesorder}
						</FlexView>
					</FlexView>
					<FlexView
						data-cy='flexview-mill-info'
						width='33%'
						margin='0 0 16px 0'
						padding='0'
						flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:SalesItem')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{reportInfo.salesitem}
						</FlexView>
					</FlexView>
				</>
			) : null}
		</>
	)

	const info = () => {
		if (isReceiptNoteReport(reviewType, false)) return millReceiptInfo
		else return ageManagementInfo
	}

	return (
		<Card data-cy='card-info-wrapper' width='calc(100% - 8px)' flex='1' margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingInfo} />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:ReportDetails')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				<FlexView
					width='100%'
					margin='0 0 16px 0'
					padding='0'
					flexDirection='column'
					fontSize='small'
					fontWeight='bold'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						{isReceiptNoteReport(reviewType, false) ? t('wms:Type') : t('wms:ExtractName')}
					</FlexView>
					<FlexView
						width='100%'
						fontWeight='bold'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{isReceiptNoteReport(reviewType, false) ? t(REPORT_TYPE.translateKey[reviewType]) : extractName}
					</FlexView>
				</FlexView>
				{info()}
				<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column' fontSize='small'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
						Status
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{getStatus()}
					</FlexView>
				</FlexView>
			</FlexView>
			{!reportPart &&
				reportInfo.content &&
				reportInfo.content.length &&
				reportInfo.content.map(report => (
					<FlexView
						key={report.belcid}
						data-cy='flexview-info'
						width='100%'
						margin='0 0 8px 0'
						padding='0'
						flexDirection='row'
						flexWrap='wrap'
						fontSize='small'
						style={{ borderTop: '1px solid whitesmoke' }}>
						{reportInfo.content.length > 1 && (
							<FlexView width='100%' flexDirection='row' justifyContent='space-between' margin='4px 0 8px 0'>
								<FlexView width='80%' fontSize='small' fontWeight='bold' margin='0'>
									{`${t('wms:Part')} ${report.partnumber}`}
								</FlexView>
								<FlexView
									width='20%'
									fontSize='small'
									fontWeight='bold'
									color={REPORT_STATUS.color[report.validationdata.status]}
									style={{
										borderLeft: `2px solid ${theme.colors[REPORT_STATUS.color[report.validationdata.status]]}`
									}}
									margin='0 0 0 auto'
									padding='8px 16px'>
									{t(`wms:${REPORT_STATUS.literal[report.validationdata.status]}`)}
								</FlexView>
							</FlexView>
						)}
						{report.validationdata.status === REPORT_STATUS.code.VALIDATED && (
							<FlexView width='100%' flexDirection='row' justifyContent='space-between'>
								<FlexView
									data-cy='flexview-common-info'
									width='33%'
									margin='0 0 8px 0'
									padding='0'
									flexDirection='column'
									fontSize='small'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
										{t('wms:TotalQuantity')}
									</FlexView>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{report.quantity}
									</FlexView>
								</FlexView>
							</FlexView>
						)}
					</FlexView>
				))}
			{reportPart ? (
				<FlexView
					data-cy='flexview-info'
					width='100%'
					margin='0 0 8px 0'
					padding='0'
					flexDirection='row'
					flexWrap='wrap'
					fontSize='small'
					style={{ borderTop: '1px solid whitesmoke' }}>
					<FlexView width='100%' flexDirection='row' justifyContent='space-between'>
						<FlexView
							data-cy='flexview-common-info'
							width='33%'
							margin='0 0 8px 0'
							padding='0'
							flexDirection='column'
							fontSize='small'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:TotalQuantity')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{part()?.quantity}
							</FlexView>
						</FlexView>
					</FlexView>
				</FlexView>
			) : null}
			{renderButtons(reportInfo, reportPart)}
		</Card>
	)
}

export default ReviewReportInfo
