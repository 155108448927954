/** @format */

import _ from 'lodash'

import { STOCK_STATUS, INSPECTION_STAGES, REPORT_STATUS } from '../enum'

const validationData = info => {
	if (!info.validation_data)
		return {
			revision: null,
			updated_date: null
		}
	else {
		const { revision, last_revision_change } = info.validation_data
		return { revision, updated_date: last_revision_change }
	}
}

const summaryData = info => {
	if (!info.summary)
		return {
			customer_request_reference: null,
			field_name: null,
			well_name: null,
			rig_name: null,
			tparty_name: null,
			tparty_location: null,
			delivery_location: null,
			ltpa_number: null,
			work_order_description: null
		}
	else {
		const {
			customer_request_reference,
			field_name,
			well_name,
			rig_name,
			tparty_name,
			tparty_location,
			delivery_location,
			ltpa_number,
			work_order_description
		} = info.summary
		return {
			customer_request_reference,
			field_name,
			well_name,
			rig_name,
			tparty_name,
			tparty_location,
			delivery_location,
			ltpa_number,
			work_order_description
		}
	}
}

const commonData = info => {
	return {
		part: info.part,
		partid: info.id,
		partdate: info.closure_date,
		id: info.id,
		date: info.closure_date,
		...validationData(info),
		...summaryData(info),
		plan_date: info.plan_date,
		plan_responsible: info.plan_responsible,
		plan_revision: info.plan_revision
	}
}

const downloadFormatInspection = (data, inspSelected, materialList) => {
	if (!(data.contents.length > 0 || inspSelected)) {
		return {}
	}

	const isRigReturn = inspSelected.stage === INSPECTION_STAGES.code.RETURN && !inspSelected.is_modified_product
	const isFilterOnContent = inspSelected.is_filter_on_content
	const rejectedParts = data.contents.filter(ct => ct.validation_data.status === REPORT_STATUS.code.REJECTED)
	const validatedParts = data.contents.filter(ct => ct.validation_data.status === REPORT_STATUS.code.VALIDATED)
	const filteredData = rejectedParts.concat(validatedParts)
	const isAllClosed = filteredData.length === data.contents.length

	let validationDate = null
	let validationResponsible = null
	const foundContent = data.contents.find(ct => ct.validation_data && ct.validation_data.last_status_change != null)
	if (isAllClosed && foundContent) {
		validationDate = foundContent.validation_data.last_status_change
		validationResponsible = foundContent.validation_data.responsible
	}

	const materialAndErp = info => {
		const unifiedMaterial = _.uniqBy(
			materialList.filter(mat => mat.contentid === info.id),
			'mvid'
		)

		if (isRigReturn || (isFilterOnContent && info.filters)) {
			const {
				customerorder,
				customeritem,
				salesorder,
				salesitem,
				level,
				material,
				material_description,
				erp_reference
			} = info.filters || {}

			return {
				materialList: unifiedMaterial,
				erplist: unifiedMaterial.map(list => ({
					id: list.mvid,
					label: list.formatted.substring(0, list.formatted.indexOf(' '))
				})),
				customerorder,
				customeritem,
				salesorder,
				salesitem,
				level,
				material: {
					id: material,
					formatted: material_description
				},
				erp_reference: {
					id: erp_reference,
					label: erp_reference
				}
			}
		} else if (data.filters) {
			const { customerorder, customeritem, salesorder, salesitem, material, material_description, erp_reference } =
				data.filters

			return {
				material: {
					id: material,
					formatted: material_description
				},
				erp_reference: {
					id: erp_reference,
					label: erp_reference
				},
				customerorder,
				customeritem,
				salesorder,
				salesitem
			}
		}
	}

	const returnQuantity = items => {
		const toBeScrapped = items.filter(item => item.inspection.final_report === STOCK_STATUS.code.ToBeScrapped).length
		const toBeRepaired = items.filter(item => item.inspection.final_report === STOCK_STATUS.code.ToBeRepaired).length

		return [toBeScrapped, toBeRepaired]
	}

	const qtyByStatus = info => {
		const quantities = returnQuantity(info.items)
		return {
			accepted: info.accepted_quantity,
			totalquantity: info.quantity,
			tobescrapped: quantities[0],
			toberepaired: quantities[1]
		}
	}

	const returnValidationData = info => {
		if (info.validation_data != null) {
			const { status, validation_date } = info.validation_data
			return {
				status,
				validation_date
			}
		} else if (data.validation_data != null) {
			return {
				status: data.validation_data.status,
				validation_date: null
			}
		}
	}

	const formatParts = content => {
		return content.map(info => ({
			...materialAndErp(info),
			qtyByStatus: qtyByStatus(info),
			part: info.part_number,
			partid: info.id,
			...returnValidationData(info)
		}))
	}

	return {
		...commonData(data),
		validation_date: validationDate,
		validation_responsible: validationResponsible,
		overall_validation_status: data.overall_validation_status || null,
		parts: formatParts(data.contents)
	}
}

export default function formatReports(data, inspSelected, materialList) {
	return downloadFormatInspection(data, inspSelected, materialList)
}
