/** @format */
/* eslint-disable react/prop-types */

import { FlexView, Link } from 'components/common'
import { Select } from 'components/form'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Pagination = ({
	canPreviousPage,
	setCanPreviousPage,
	canNextPage,
	setCanNextPage,
	totalPages,
	currentPage,
	setCurrentPage,
	setFetchingFirstPage,
	setFetchingLastPage,
	getMoreReports,
	totalItems
}) => {
	const { t } = useTranslation()

	const [pageSize, setPageSize] = useState(50)

	const gotoFirstPage = value => {
		setCurrentPage(value)
		setFetchingFirstPage(true)
		getMoreReports(value, pageSize)
	}

	const gotoLastPage = value => {
		setCurrentPage(value)
		setFetchingLastPage(true)
		getMoreReports(value, pageSize)
	}

	const previousPage = () => {
		setCurrentPage(currentPage - 1)
		getMoreReports(currentPage - 1, pageSize)
	}

	const nextPage = () => {
		setCurrentPage(currentPage + 1)
		getMoreReports(currentPage + 1, pageSize)
	}

	const selectPageSize = value => {
		setPageSize(value)
		getMoreReports(currentPage, value)
	}

	useEffect(() => {
		currentPage === 1 ? setCanPreviousPage(false) : setCanPreviousPage(true)
		currentPage === totalPages ? setCanNextPage(false) : setCanNextPage(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage])

	return (
		<FlexView width='100%' flexDirection='row' margin='auto 0 0 0' alignItems='center'>
			<FlexView flexDirection='row' alignItems='center' justifyContent='center' width='80%'>
				<Link
					noDecoration
					color='gray'
					fontSize='big'
					fontWeight='bold'
					padding='4px'
					margin='0px 4px'
					onClick={() => gotoFirstPage(1)}
					disabled={!canPreviousPage}>
					{'«'}
				</Link>{' '}
				<Link
					noDecoration
					color='gray'
					fontSize='big'
					fontWeight='bold'
					padding='4px'
					margin='0px 4px'
					onClick={() => previousPage()}
					disabled={!canPreviousPage}>
					{'‹'}
				</Link>{' '}
				<FlexView flexDirection='row' margin='0px 8px' fontWeight='bold'>
					{currentPage} {t('of')} {totalPages}
				</FlexView>
				<Link
					noDecoration
					color='gray'
					fontSize='big'
					fontWeight='bold'
					padding='4px'
					margin='0px 4px'
					onClick={() => nextPage()}
					disabled={!canNextPage}>
					{'›'}
				</Link>{' '}
				<Link
					noDecoration
					color='gray'
					fontSize='big'
					fontWeight='bold'
					padding='4px'
					margin='0px 4px'
					onClick={() => gotoLastPage(totalPages)}
					disabled={!canNextPage}>
					{'»'}
				</Link>{' '}
			</FlexView>
			<FlexView flexDirection='row' justifyContent='flex-end' alignItems='flex-end' width='18%'>
				{totalItems && (
					<Select
						width='80%'
						margin='0px'
						value={pageSize}
						options={[10, 20, 30, 40, 50, 100].map(pageSize => ({
							value: pageSize,
							label: `${t('Show')} ${pageSize}`
						}))}
						onChange={value => {
							selectPageSize(Number(value))
						}}
					/>
				)}
			</FlexView>
		</FlexView>
	)
}

export default Pagination
