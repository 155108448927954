/** @format */

import { NominalDataContext } from 'apps/wms/stores/NominalDataModifStore'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line react/prop-types
const ConfirmAllModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { modifyNominalData, savingChanges } = useContext(NominalDataContext)

	const saveNominalData = () => {
		modifyNominalData()
	}

	return (
		<Modal isOpen={isOpen} onOutisideClick={onOutsideClick}>
			<Card minWidth='300px' maxWidth='800px'>
				<FlexView flexDirection='column' width='100%'>
					<FlexView
						width='100%'
						fontSize='title'
						fontWeight='bold'
						margin='16px 0 0 0'
						style={{ textAlign: 'center' }}>
						{`${t('wms:ConfirmNominalDataModif')} ?`}
					</FlexView>
					<FlexView width='100%' fontSize='medium' margin='16px 0 16px 0' style={{ textAlign: 'justify' }}>
						{t('wms:EntranceWillBeModif')}
					</FlexView>
					<FlexView flexDirection='row' margin='auto 0 0 auto'>
						<Button backgroundColor='error' color='white' onClick={onOutsideClick}>
							{t('wms:Cancel')}
							<Icon name='cross-simple' color='white' width='14px' height='14px' margin='0 0 0 8px' />
						</Button>
						<Button
							backgroundColor='success'
							color='white'
							margin='8px 0 0 8px'
							onClick={() => saveNominalData()}>
							{t('wms:Save')}
							<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
							<LoadingOverlay visible={savingChanges} borderRadius='card' />
						</Button>
					</FlexView>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default ConfirmAllModal
