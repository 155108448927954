/** @format */

import { ContentContext } from 'apps/wms/stores/ContentStore'
import { FlexView, Icon } from 'components/common'
import React, { useCallback, useContext, useMemo } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { formatStockKeys } from 'apps/wms/utils/enum'

// eslint-disable-next-line react/prop-types
const Tag = ({ label, values, onClearClick, t }) => {
	const onClear = e => {
		e.stopPropagation()
		onClearClick()
	}

	return (
		<FlexView
			flexDirection='row'
			margin='0px 8px 8px 0px'
			fontSize='small'
			backgroundColor='whitesmoke'
			padding='8px'
			borderRadius='component'
			style={{ cursor: 'pointer' }}>
			<span>
				<strong>{label}: </strong>
				{t(values)}
			</span>
			<Icon name='cross' color='error' width='16px' height='16px' onClick={onClear} margin='0px 0px 0px 8px' />
		</FlexView>
	)
}

// eslint-disable-next-line react/prop-types
const ContentFIlterDisplay = ({ onTagClick }) => {
	const {
		filters,
		setFilters,
		actualDataSelected,
		setActualDataSelected,
		setNominalDataSelected,
		nominalDataSelected
	} = useContext(ContentContext)
	const { t } = useTranslation()

	const onClearClick = useCallback(
		accessor => () => {
			delete filters[accessor]
			delete actualDataSelected[accessor]
			delete nominalDataSelected[accessor]

			setFilters({ ...filters })
			setActualDataSelected({ ...actualDataSelected })
			setNominalDataSelected({ ...nominalDataSelected })
		},
		[filters, setFilters, actualDataSelected, setActualDataSelected, nominalDataSelected, setNominalDataSelected]
	)

	const renderTags = useMemo(
		() =>
			_.map(Object.keys(filters), (label, index) => {
				return (
					<Tag
						key={index}
						label={t(`wms:${label}`)}
						values={label === 'pipestatuses' ? formatStockKeys(filters[label]) : filters[label]}
						onTagClick={onTagClick}
						onClearClick={onClearClick(label)}
						t={t}
					/>
				)
			}),
		[filters, onClearClick, onTagClick, t]
	)

	return (
		<FlexView flexDirection='row' flexWrap='wrap' margin='0px'>
			{renderTags}
		</FlexView>
	)
}

export default ContentFIlterDisplay
