/** @format */

import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { FlexView } from 'components/common'

const getColor = props => {
	if (props.isDragAccept) {
		return props.theme.colors.success
	}
	if (props.isDragReject) {
		return props.theme.colors.error
	}
	if (props.isDragActive) {
		return props.theme.colors.primary
	}
	return props.theme.colors.lightGray
}

const Container = styled(FlexView)`
	font-family: 'Roboto';
	flex-wrap: nowrap;
	flex-direction: column;
	align-self: stretch;
	align-items: center;
	justify-content: center;
	margin: 16px 0px 16px 0px;
	border-width: 2px;
	border-radius: 2px;
	border-color: ${props => getColor(props)};
	border-style: dashed;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	min-width: 20rem;
	transition: border 0.24s ease-in-out;

	&:hover {
		border-color: ${({ theme }) => theme.colors.primary};
		color: ${({ theme }) => theme.colors.primary};
	}
`

// eslint-disable-next-line react/prop-types
const UploadFileBox = ({ text, fileTypes, setFile, height, fontSize = 3 }) => {
	const { t } = useTranslation()

	const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } =
		useDropzone({ accept: fileTypes })

	useEffect(() => {
		async function receiveFile() {
			try {
				if (fileRejections.length > 0) {
					toast.error(t('wms:FileTypeNotAllowed'))
				} else if (acceptedFiles && acceptedFiles.length && acceptedFiles[0].size <= 50000000) {
					if (acceptedFiles && acceptedFiles.length && acceptedFiles[0].size <= 50000000) {
						setFile(acceptedFiles[0])
					}
				} else if (acceptedFiles.length) toast.error(t('wms:MaximumSizeAllowed'))
			} catch (error) {
				toast.error(error.message)
			}
		}
		receiveFile()
	}, [acceptedFiles, fileRejections, setFile, t])

	return (
		<Container
			{...getRootProps({ isDragActive, isDragAccept, isDragReject, onClick: event => console.log(event) })}
			style={{ paddingTop: `${height / 2}px`, paddingBottom: `${height / 2}px` }}>
			<input {...getInputProps()} />
			{
				<font size={fontSize} style={{ 'text-align': 'center' }}>
					<b> {text} </b>
				</font>
			}
			{/* {isDragReject && t('Sorry, this file type is not permitted')+'!'} */}
		</Container>
	)
}

export default UploadFileBox
