/** @format */

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AssetsSettingsContext } from 'apps/wms/stores/AssetsSettingsStore'
import { Button, FlexView } from 'components/common'
import { Input } from 'components/form'

const InspectionExpirationTimeCard = () => {
	const { t } = useTranslation()
	const { expTimeStg, setExpTimeStg, setSaveExpTime } = useContext(AssetsSettingsContext)

	return (
		<FlexView
			backgroundColor='white'
			borderRadius='4px'
			width='45%'
			margin='16px 0 0 0'
			elevation='low'
			alignItems='stretch'
			style={{ position: 'relative' }}>
			<FlexView fontSize='medium' fontWeight='bold' margin='8px'>
				{t('wms:InspectionExpirationTime')}
			</FlexView>
			<FlexView flex='1' justifyContent='space-between' width='100%' height='100%' margin='0'>
				<Input
					type='number'
					min='1'
					placeholder={t('wms:InspectionExpirationTime')}
					value={expTimeStg.days_to_expire === undefined ? 0 : expTimeStg.days_to_expire}
					onChange={e => setExpTimeStg({ ...expTimeStg, days_to_expire: parseInt(e.target.value) })}
					width='90%'
					minWidth='90%'
					margin='8px'
					data-cy='input-exp-time-days-to-expire'
				/>
				<FlexView alignItems='center' margin='auto auto 0 auto'>
					<Button
						disabled={!expTimeStg.days_to_expire}
						backgroundColor='success'
						color='white'
						onClick={() => setSaveExpTime(true)}
						data-cy='button-save-exp-time'>
						{t('wms:Save')}
					</Button>
				</FlexView>
			</FlexView>
		</FlexView>
	)
}

export default InspectionExpirationTimeCard
