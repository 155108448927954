/** @format */

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { AssetsReportRefContext } from 'apps/wms/stores/AssetsReportRefStore'
import { AssetsInspSettingsContext } from 'apps/wms/stores/AssetsInspSettingsStore'
import { FlexView } from 'components/common'
import Card from 'components/common/Card'
import LoadingOverlay from 'components/common/LoadingOverlay'
import InspectionReportRefDetails from './InspectionReportRefDetails'

const InspectionReportsInfo = () => {
	const { t } = useTranslation()
	const { selectedInspectionReport, isUpdatingInspectionReportData, isUpdatingInspectionReportList } =
		useContext(AssetsReportRefContext)
	const { stagesList } = useContext(AssetsInspSettingsContext)
	const inspectionStage = _.find(stagesList, s => s.id === selectedInspectionReport.stage)

	return (
		<Card
			width='calc(100% - 48px)'
			flex='1'
			margin='0'
			padding='24px'
			style={{ position: 'relative' }}
			data-cy='card-report-info'>
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 32px 0'>
				{t('wms:InspectionReportInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'
				position='relative'>
				{inspectionStage &&
					_.map(inspectionStage.report_reference_types, (reportRefType, index) => (
						<FlexView key={reportRefType} width='100%' margin={!index ? '0' : '32px 0 0 0'}>
							<InspectionReportRefDetails reportRefType={reportRefType} />
						</FlexView>
					))}
				<LoadingOverlay
					borderRadius='0'
					visible={isUpdatingInspectionReportData || isUpdatingInspectionReportList}
				/>
			</FlexView>
		</Card>
	)
}

export default InspectionReportsInfo
