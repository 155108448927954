import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { FlexView, Icon } from 'components/common'
import { useMemo } from 'react'
import List from './List'
import { Input } from '..'
import { useTranslation } from 'react-i18next'

const Wrapper = styled(FlexView)`
  input {
    margin: 0px !important;
    min-height: 0px;
  }
`

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 0px 16px 0px 0px;
  white-space: nowrap;
  font-weight: bold;
`

const DualListSelect = ({ label, selectedValues, options, onChange, margin, width, height, searchable }) => {
  const [search, setSearch] = useState('')
  const [leftIndex, setLeftIndex] = useState(null)
  const [rightIndex, setRightIndex] = useState(null)
  const { t } = useTranslation()

  const currentOptions = useMemo(() => (
    _.filter(options, ({ value, label }) => !_.includes(selectedValues, (value)) && label?.toLowerCase().includes(search?.toLowerCase()))
  ), [options, search, selectedValues])

  const selectedOptions = useMemo(() => _.filter(options, ({ value }) => _.includes(selectedValues, (value))), [options, selectedValues])

  const onSelect = selectedValue => {
    onChange([...selectedValues, selectedValue])
  }

  const removeOption = selectedValue => {
    onChange(_.filter(selectedValues, value => value !== selectedValue))
  }

  const selectAll = () => {
    onChange(_.map(options, ({ value }) => value))
  }

  const selectCurrent = () => {
    const currentValue = _.get(currentOptions, `${leftIndex}.value`)
    currentValue && onSelect(currentValue)
  }

  const unselectAll = () => {
    onChange([])
  }

  const unselectCurrent = () => {
    const currentValue = _.get(selectedOptions, `${rightIndex}.value`)
    currentValue && removeOption(currentValue)
  }

  const handleSearchChange = e => {
    setSearch(e.target.value)
  }

  return (
    <Wrapper
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="stretch"
      position="relative"
      minWidth="400px"
      minHeihgt="200px"
      {...{ width, margin }}
    >
      <FlexView flexDirection="row" alignItems="center">
        {label && <Label>{label}</Label>}
        {searchable && <Input width="160px" margin="0px" fontSize="small" padding="8px" value={search} onChange={handleSearchChange} placeholder={t('Search')} />}
      </FlexView>
      <FlexView
        flexDirection="row"
        alignItems="stretch"
        height={height}
      >
        <List options={currentOptions} onCommit={onSelect} selectedIndex={leftIndex} setSelectedIndex={setLeftIndex} />
        <FlexView alignItems="center" justifyContent="center" margin="16px">
          <Icon name="double-chevron-right" margin="16px" onClick={selectAll} />
          <Icon name="chevron-right" margin="16px" onClick={selectCurrent} disabled={leftIndex === null} />
          <Icon name="chevron-left" margin="16px" onClick={unselectCurrent} disabled={rightIndex === null} />
          <Icon name="double-chevron-left" margin="16px" onClick={unselectAll} />
        </FlexView>
        <List options={selectedOptions} onCommit={removeOption} selectedIndex={rightIndex} setSelectedIndex={setRightIndex}  />
      </FlexView>
  </Wrapper>
  )
}

DualListSelect.propTypes = {
  /**
   * Label that accompanies the input
   */
  label: PropTypes.string,
  /**
   * Array of selected options' values
   */
  selectedValues: PropTypes.arrayOf(PropTypes.any),
  /**
   * Array of options
   */
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string
  })).isRequired,
  /**
   * Function that is called when the value is changed, being passed as parameter the array of selected options' values
   */
  onChange: PropTypes.func,
  /**
   * Defines if the component should render a search input to help filter unselected options
   */
  searchable: PropTypes.bool,
  /**
   * Override CSS width property. Must be a valid CSS width value as a string
   */
  width: PropTypes.string,
  /**
   * Override CSS height property. Must be a valid CSS height value as a string
   */
  height: PropTypes.string,
  /**
   * Override CSS margin property. Must be a valid CSS margin value as a string
   */
  margin: PropTypes.string
}

DualListSelect.defaultProps = {
  value: null,
  width: 'fit-content',
  fontSize: 'medium',
  margin: '8px 0px',
  ordered: true
}

export default DualListSelect
