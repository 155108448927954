import React, { useState, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { FlexView, Button } from 'components/common'
import { Input, DateRangePicker, Select } from 'components/form'
import { LogsContext } from 'apps/admin/stores/LogsStore'

const FormWrapper = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: 16px;
  }

  & > button {
    align-self: flex-end;
    margin: 0px 0px 20px;
  }

  @media (max-width: 940px) {
    flex-direction: column;

    & > button {
      align-self: flex-start;
    }

    & > *:not(:first-child):not(:last-child) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`

const defaultDate = moment().subtract('3', 'hours').minutes(0)

const LogsForm = () => {
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState(moment(defaultDate))
  const [endDate, setEndDate] = useState(moment(defaultDate).add('4', 'hours'))
  const [service, setService] = useState('')
  const [filterType, setFilterType] = useState('all')
  const [filter, setFilter] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { services, getLogs } = useContext(LogsContext)

  const onFilterChange = e => {
    setFilter(e.target.value)
  }

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    if (service && startDate && endDate) {
      try {
        await getLogs(service, startDate, endDate, filterType, filter)
      }
      catch (e) {
        console.log(e)
        toast.error(e.data || t(`admin:errors.${e.message}`))
      }
      finally {
        setLoading(false)
      }
    }
  }

  const serviceOptions = _.map(services, service => ({ label: service, value: service }))

  const filterTypeOptions = useMemo(() => [
    {
      value: 'error',
      label: t('admin:logs.filterTypes.error'),
    },
    {
      value: 'warning',
      label: t('admin:logs.filterTypes.warning'),
    },
    {
      value: 'info',
      label: t('admin:logs.filterTypes.info'),
    },
    {
      value: 'data',
      label: t('admin:logs.filterTypes.data'),
    },
    {
      value: 'exception',
      label: t('admin:logs.filterTypes.exception'),
    },
    {
      value: 'notrace',
      label: t('admin:logs.filterTypes.notrace'),
    },
    {
      value: 'full',
      label: t('admin:logs.filterTypes.full'),
    },
    {
      value: 'normal',
      label: t('admin:logs.filterTypes.normal'),
    },
    {
      value: 'all',
      label: t('admin:logs.filterTypes.all'),
    }
  ], [t])

  return (
    <>
      <FlexView fontSize="title" fontWeight="bold">{t('admin:logs.title')}</FlexView>
      {serviceOptions.length ? <FormWrapper onSubmit={onSubmit}>
        <Select width="240px" name="service" label={t('admin:logs.service')} value={service} onChange={setService} options={serviceOptions} />
        <DateRangePicker label={t('admin:logs.selectRange')} startValue={startDate} endValue={endDate} onStartChange={setStartDate} onEndChange={setEndDate} format="DD/MM HH:mm" timePicker />
        <Select width="160px" name="filterType" label={t('admin:logs.filterType')} value={filterType} onChange={setFilterType} options={filterTypeOptions} />
        <Input width="160px" name="filter" label={t('admin:logs.filter')} value={filter} onChange={onFilterChange} />
        <Button type="submit" isLoading={isLoading} backgroundColor="primary" color="white" disabled={!(service && startDate && endDate)}>{t('admin:logs.search')}</Button>
      </FormWrapper> : <FlexView alignSelf="stretch" margin="40px" justifyContent="center" alignItems="center" color="gray" fontSize="big">No Services Found</FlexView>}
    </>
  )
}

export default LogsForm