/** @format */

import React from "react"
import { FlexView } from "./components/common"
import Header from "./containers/layout/Header"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import PrivateRoute from "./containers/router/PrivateRoute"

import Home from "pages/Home"
import RedirectTo from "pages/RedirectTo"

const Router = () => {
  return (
    <FlexView height="100vh" width="100%">
      <BrowserRouter>
        <Header />
        <Switch>
          <Route path="/redirect/:to" exact>
            <RedirectTo />
          </Route>
          <PrivateRoute path="/">
            <Switch>
              <PrivateRoute path="/">
                <Home />
              </PrivateRoute>
            </Switch>
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </FlexView>
  )
}

export default Router
