/** @format */

import { ActualDataContext } from 'apps/wms/stores/ActualDataModifStore'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Input, Select } from 'components/form'
import React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const ModifSinglePipe = () => {
	const { t } = useTranslation()
	const {
		valid,
		setValid,
		ippn,
		setIPPN,
		searchPipe,
		endUsers,
		endUserSelected,
		setEndUserSelected,
		poNumbers,
		customerOrder,
		setCustomerOrder,
		customerItem,
		setCustomerItem,
		filteredPoItems,
		loadingCO,
		selectedSource,
		setSelectedSource
	} = useContext(ActualDataContext)

	const sourceType = [
		{
			value: 'VALID',
			label: 'VALID'
		},
		{
			value: 'IPPN',
			label: 'IPPN'
		}
	]

	const searchData = () => {
		searchPipe()
	}

	return (
		<FlexView flexDirection='column' width='31%' height='100%' margin='0 8px 0 0'>
			<Card width='calc(100% - 48px)' data-cy='card-type-source'>
				<Select
					margin='0'
					width='100%'
					label={t('wms:Source')}
					placeholder={t('wms:Source')}
					value={selectedSource}
					options={sourceType}
					onChange={v => setSelectedSource(v)}
					data-cy='select-type-source'
				/>
			</Card>
			{selectedSource === 'VALID' ? (
				<Card width='calc(100% - 48px)' margin='8px 0 0 8px' height='100%' data-cy='card-valid'>
					<FlexView flexDirection='column' width='100%' height='100%'>
						<Input
							type='text'
							width='100%'
							label='VALID'
							placeholder='VALID'
							value={valid}
							onChange={e => setValid(e.target.value)}
							data-cy='input-valid'
						/>
						<Button
							backgroundColor='secondary'
							color='white'
							onClick={() => searchData()}
							margin='auto auto 0 auto'
							disabled={!valid}
							data-cy='button-search-valid'>
							{t('wms:Search')}
							<Icon name='search' color='white' width='14px' height='14px' margin='0 0 0 8px' />
						</Button>
					</FlexView>
				</Card>
			) : selectedSource === 'IPPN' ? (
				<Card width='calc(100% - 48px)' margin='8px 0 0 8px' height='100%' data-cy='card-ippn'>
					<FlexView flexDirection='column' width='100%' height='100%' margin='8px 0 0 0'>
						<Select
							margin='0'
							width='100%'
							label={t('wms:EndUser')}
							placeholder={t('wms:EndUser')}
							value={endUserSelected}
							options={_.map(endUsers, eu => {
								return { value: eu.id, label: eu.name }
							})}
							onChange={v => setEndUserSelected(v)}
							searchable
							data-cy='select-end-user'
						/>
						<LoadingOverlay visible={loadingCO} borderRadius='card' />
						<Select
							style={{ transform: 'translate3d(5px, 35px, 0px)' }}
							width='100%'
							label={t('wms:OrderNo')}
							placeholder={t('wms:OrderNo')}
							value={customerOrder}
							options={
								poNumbers &&
								_.map(poNumbers, po => {
									return { value: po.label, label: po.label }
								})
							}
							onChange={v => setCustomerOrder(v)}
							disabled={!endUserSelected}
							searchable
							data-cy='select-order-number'
						/>
						<Select
							width='100%'
							label={t('wms:OrderItemNo')}
							placeholder={t('wms:OrderItemNo')}
							value={customerItem}
							options={
								filteredPoItems &&
								_.map(filteredPoItems, filtered => {
									return { value: filtered.label, label: filtered.label }
								})
							}
							onChange={v => setCustomerItem(v)}
							disabled={!customerOrder}
							data-cy='select-order-item-number'
						/>
						<Input
							type='text'
							width='100%'
							label='IPPN'
							placeholder='IPPN'
							value={ippn}
							onChange={e => setIPPN(e.target.value)}
							disabled={!customerItem}
							data-cy='input-ippn'
						/>
						<Button
							backgroundColor='secondary'
							color='white'
							onClick={() => searchData()}
							margin='auto auto 0 auto'
							disabled={!ippn}
							data-cy='button-search-ippn'>
							{t('wms:Search')}
							<Icon name='search' color='white' width='14px' height='14px' margin='0 0 0 8px' />
						</Button>
					</FlexView>
				</Card>
			) : null}
		</FlexView>
	)
}

export default ModifSinglePipe
