/** @format */

import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { WMSContext } from '../../stores/WmsStore'
import FlexView from 'components/common/FlexView'
import Select from 'components/form/Select'
import BreadCrumbs from './BreadCrumb'

// eslint-disable-next-line react/prop-types
const Subheader = ({ crumbs }) => {
	const { warehouse, setWarehouse, getLevelsList } = useContext(WMSContext)
	const { t } = useTranslation()
	const [warehouseList, setWarehouseList] = useState([])
	const [warehouseID, setWarehouseID] = useState(() => {
		if (warehouse && warehouse.id) return warehouse.id
		else return null
	})

	useEffect(() => {
		getLevelsList()
			.then(list => list && setWarehouseList(list))
			.catch(e => {
				console.error(e)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const selectWarehouse = useCallback(
		warehouseID => {
			if (warehouseID !== null && warehouseID !== undefined) {
				setWarehouseID(warehouseID)
				setWarehouse(warehouseList.find(warehouse => warehouse.id === warehouseID))
			}
		},
		[setWarehouseID, setWarehouse, warehouseList]
	)

	return (
		<FlexView
			margin='0 0 0 auto'
			padding='16px'
			flexDirection='row'
			justifyContent='flex-start'
			alignItems='center'
			width='calc(100% - 32px)'
			position='relative'>
			<FlexView margin='0 auto 0 0 '>
				<BreadCrumbs crumbs={crumbs}></BreadCrumbs>
			</FlexView>

			<FlexView
				padding='0px'
				margin='0 0 0 16px'
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				position='relative'>
				<Select
					data-cy='select'
					placeholder={t('wms:Warehouse')}
					value={warehouseID}
					options={warehouseList.map(warehouse => ({
						value: warehouse.id,
						label: `${warehouse.country} - ${warehouse.plant} - ${warehouse.name}`
					}))}
					onChange={value => {
						selectWarehouse(value)
					}}
					inline={false}
					width='350px'
					margin='0'
				/>
			</FlexView>
		</FlexView>
	)
}

export default Subheader
