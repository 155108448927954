import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import {
  ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend,
} from 'recharts'
import _ from 'lodash'
import moment from 'moment'
import { FlexView } from 'components/common'

const CpuChart = ({ time, cpu }) => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const data = _.map(time, (item, index) => {
    return {
      x: moment(item, 'YYYY-MM-DDTHH:mm:ssZ').format('DD/MM/YYYY HH:mm'),
      y: cpu[index]
    }
  })

  const formatCpu = value => value.toFixed(2) + '%'

  return (
    <FlexView flex="1" style={{ overflow: 'hidden' }} fontSize="small">
      <FlexView margin="0px 0px 8px" fontSize="big" fontWeight="bold">{t('admin:status.CPU')}</FlexView>
      <ResponsiveContainer Container width="100%" height={200}>
        <LineChart data={data} margin={{ top: 5, right: 10, left: 0, bottom: 5 }}>
          <XAxis dataKey="x" dy={5}/>
          <YAxis  domain={[0, 100]} dx={-5} label={{ value: '%', angle: -90, position: 'insideLeft' }}/>
          <Tooltip formatter={formatCpu} />
          <Legend />
          <Line type="monotone" dataKey="y" name={t('admin:status.CPU')} stroke={theme.colors.primary} activeDot={{ r: 6 }} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </FlexView>
  )
}

export default CpuChart
