/** @format */

import _ from 'lodash'

export const bundleOrItems = (warehouse, bundles, certificates) => {
	if (warehouse && bundles && bundles.length) {
		let items = undefined
		if (displayBundles(warehouse)) {
			items = bundles
		} else {
			items = []
			const commonVariables = (obj, bundle, certificateFound) => {
				return {
					...obj,
					certificatenumber: (certificateFound && certificateFound.certificate.id) || null,
					certicateversion: (certificateFound && certificateFound.certificate.version) || null,
					consignmenttype: bundle.consignmenttype,
					customerorder: bundle.customerorder,
					customeritem: bundle.customeritem,
					salesorder: bundle.salesorder,
					salesitem: bundle.salesitem
				}
			}
			_.forEach(bundles, b => {
				let bundle = b
				let bItems = bundle.items
				let aux = []
				_.forEach(bItems, it => {
					let certificateFound = certificates && certificates.find(cert => cert.valid === it.valid)
					let item = commonVariables(Object.assign({}, it), bundle, certificateFound)
					item.material = bundle.material || null
					item.level = bundle.level || null
					item.ownershipid = bundle.ownershipid || null
					aux.push(item)
				})
				bItems = aux
				items = _.concat(items, bItems)
			})
		}
		return items
	}
	return undefined
}

export const displayBundles = warehouse => {
	return warehouse.displayitem === 0
}

export const displayValids = warehouse => {
	return warehouse.displayitem === 1
}

export const displayPuuids = warehouse => {
	return warehouse.displayitem === 2
}

export const levelDesc = warehouse => {
	if (warehouse && warehouse.levels && warehouse.levels.length)
		return _.map(warehouse.levels, l => {
			return l.desc
		})
	return undefined
}
