/** @format */

import { AssetsSettingsContext } from 'apps/wms/stores/AssetsSettingsStore'
import { Button, FlexView } from 'components/common'
import { Input } from 'components/form'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const PerInspCard = () => {
	const { t } = useTranslation()
	const { perInspStg, setPerInspStg, setSavePerInsp } = useContext(AssetsSettingsContext)

	return (
		<FlexView
			backgroundColor='white'
			borderRadius='4px'
			width='45%'
			margin='16px 8px 0 0'
			elevation='low'
			style={{ position: 'relative' }}
			data-cy='card-perinsp'>
			<FlexView fontSize='medium' fontWeight='bold' margin='8px'>
				{t('wms:PeriodicInspection')}
			</FlexView>
			<Input
				type='number'
				min='1'
				placeholder={t('wms:InspectionFreq')}
				label={t('wms:InspectionFreq')}
				value={perInspStg.frequence}
				onChange={e => setPerInspStg({ ...perInspStg, frequence: parseInt(e.target.value) })}
				width='90%'
				minWidth='90%'
				margin='8px'
				data-cy='input-perinsp-frequency'
			/>
			<Input
				type='number'
				min='1'
				placeholder={t('wms:InspectionSampling')}
				label={t('wms:InspectionSampling')}
				value={perInspStg.sampling}
				onChange={e => setPerInspStg({ ...perInspStg, sampling: parseInt(e.target.value) })}
				width='90%'
				minWidth='90%'
				margin='8px'
				data-cy='input-perinsp-sampling'
			/>
			<FlexView alignItems='center' margin='auto auto 0 auto'>
				<Button
					disabled={!perInspStg.frequence || !perInspStg.sampling}
					backgroundColor='success'
					color='white'
					onClick={() => setSavePerInsp(true)}
					data-cy='button-save-perinsp'>
					{t('wms:Save')}
				</Button>
			</FlexView>
		</FlexView>
	)
}

export default PerInspCard
