/** @format */

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Modal, Card, FlexView, Icon } from 'components/common'
import { TextArea } from 'components/form'
import { CloudLogsContext } from '../../stores/CloudLogsStore'

const downloadString = (text, fileType, fileName) => {
	const blob = new Blob([text], { type: fileType })
	const a = document.createElement('a')
	a.download = fileName
	a.href = URL.createObjectURL(blob)
	a.dataset.downloadurl = [fileType, a.download, a.href].join(':')
	a.style.display = 'none'
	document.body.appendChild(a)
	a.click()
	document.body.removeChild(a)
	setTimeout(function () {
		URL.revokeObjectURL(a.href)
	}, 1500)
}

const CloudLogsModal = () => {
	const { t } = useTranslation()
	const {
		formData: { group: service },
		selectedLog,
		setSelectedLog
	} = useContext(CloudLogsContext)

	const onDownloadClick = () => {
		downloadString(
			selectedLog.message,
			'text/plain',
			`${service}-${selectedLog.type}-${selectedLog.timestamp.format('YYYY-MM-DD_HH-mm')}.txt`
		)
	}

	return (
		<Modal isOpen={!!selectedLog} onOutsideClick={setSelectedLog.bind(this, null)}>
			{selectedLog && (
				<Card maxWidth='800px' position='relative' alignItems='stretch'>
					<FlexView
						fontSize='big'
						fontWeight='bold'
						margin='0px 0px 16px'
						flexDirection='row'
						alignItems='center'
						justifyContent='space-between'>
						<span>
							{_.capitalize(service)} - {selectedLog.timestamp.format('DD/MM/YYYY HH:mm')} - {selectedLog.type}
						</span>
						<Icon
							tooltip={t('wms:Download')}
							name='download'
							width='24px'
							height='24px'
							onClick={onDownloadClick}
						/>
					</FlexView>
					<TextArea value={selectedLog.message} width='600px' rows={20} onChange={() => null} />
				</Card>
			)}
		</Modal>
	)
}

export default CloudLogsModal
