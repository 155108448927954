/** @format */

import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import { LoadingOverlay } from "components/common"
import newAuth from "api/newAuth"
import { errorHandle, responseHandle } from "utils/callback-handle"
import { toast } from "react-toastify"

export const UserContext = React.createContext()

export const UserProvider = ({ children }) => {
  const TOKEN_COOKIE = "_ssoToken"

  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [authState, setAuthState] = useState("LOGIN")
  const [settings, setSettings] = useState(null)
  const [tokenInterval, setTokenInterval] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const getUserDetails = (token) =>
    new Promise((resolve, reject) => {
      const headerToken = { Authorization: token }
      newAuth
        .getUserDetails(headerToken)
        .then((response) => {
          const data = responseHandle(response)
          setUser(data.user)
          setSettings(data.rootSettings)
          resolve()
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })

  const getUserVerify = (token) =>
    new Promise((resolve, reject) => {
      const headerToken = { Authorization: token }
      newAuth
        .getUserVerify(headerToken)
        .then((response) => {
          const data = responseHandle(response)
          resolve(data)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })

  const catchAuthState = (err) => {
    if (err.response) {
      if (err.response.status === 402) {
        setAuthState("WAITING_FOR_ACCESS")
      } else if (err.response.status === 403) {
        setAuthState("ACCESS_DENIED")
      } else if (err.response.status === 401) {
        setAuthState("REFRESH_TOKEN")
      }
    }
    return errorHandle(err)
  }

  const signOut = async () => {
    try {
      Cookies.remove(TOKEN_COOKIE, {
        path: "/",
        domain: process.env.REACT_APP_DOMAIN,
      })
      setUser(null)
      setAuthState(null)
    } catch (e) {
      console.debug(e)
    }
  }

  const refreshSession = async () => {
    try {
      Cookies.remove(TOKEN_COOKIE, {
        path: "/",
        domain: process.env.REACT_APP_DOMAIN,
      })
      setUser(null)
      setAuthState("REFRESH_TOKEN")
      setTokenInterval(null)
    } catch (e) {
      console.debug(e)
    }
  }

  useEffect(() => {
    const hydrate = async () =>
      new Promise((resolve, reject) => {
        const expires = new Date(new Date().getTime() + 60 * 60 * 1000)
        Cookies.set(
          "_ssoVlrToken",
          btoa(JSON.stringify({ id: process.env.REACT_APP_ID })),
          {
            expires: expires,
            domain: process.env.REACT_APP_DOMAIN,
          }
        )
        const idToken = Cookies.get(TOKEN_COOKIE)
        const query = new URLSearchParams(window.location.search)
        const verifyToken = query.get("token")
        if (idToken) {
          setToken(idToken)
          if (verifyToken) {
            setAuthState("RELOAD")
            resolve()
          } else {
            getUserDetails(idToken)
              .then(() => {
                setAuthState("SIGNED_IN")
                resolve()
              })
              .catch((e) => {
                reject(catchAuthState(e))
              })
          }
        } else {
          if (verifyToken) {
            getUserVerify(verifyToken)
              .then((idToken) => {
                const expires = new Date(new Date().getTime() + 60 * 60 * 1000)
                Cookies.set(TOKEN_COOKIE, idToken, {
                  expires: expires,
                  domain: process.env.REACT_APP_DOMAIN,
                })
                setAuthState("RELOAD")
                resolve()
              })
              .catch((e) => {
                reject(catchAuthState(e))
              })
          } else {
            setAuthState("REFRESH_TOKEN")
            reject("Token Expired")
          }
        }
      })

    hydrate()
      .catch((err) => {
        toast.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTokenInterval(
      setInterval(() => {
        const haveToken = Cookies.get(TOKEN_COOKIE)
        if (!haveToken) {
          refreshSession()
        }
      }, 1800000)
    )
  }, [])

  const renderStore = isLoading ? (
    <LoadingOverlay visible={true} />
  ) : (
    <UserContext.Provider
      value={{
        user,
        token,
        authState,
        refreshSession,
        /* signIn, */
        /* changePassword, */
        signOut,
        settings,
        setSettings,
        tokenInterval,
        setTokenInterval,
        /* forgotPassword */
      }}
    >
      {children}
    </UserContext.Provider>
  )

  return renderStore
}
