/** @format */

import { AssetsWarehouseContext } from 'apps/wms/stores/AssetsWarehouseStore'
import { WMSContext } from 'apps/wms/stores/WmsStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Input, RadioGroup, Toggle } from 'components/form'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import AddWarehouseModal from './AddWarehouseModal'
import { UserContext } from 'stores/UserStore'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import Checkbox from '../../../components/forms/Checkbox'

const WarehouseSettings = () => {
	const { t } = useTranslation()
	const { user } = useContext(UserContext)
	const { warehouse, setWarehouse } = useContext(WMSContext)
	const {
		openAddModal,
		setOpenAddModal,
		setNewWarehouse,
		saving,
		setSaving,
		openConfirmModal,
		setOpenConfirmModal,
		deleteWarehouse,
		saveWarehouse
	} = useContext(AssetsWarehouseContext)

	const displayOptions = [
		{
			value: 0,
			label: `${t('wms:Bundle')}`
		},
		{
			value: 1,
			label: 'VALID'
		},
		{
			value: 2,
			label: `${t('wms:Puuid')}`
		}
	]

	const listDeadSpace = 270
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const changeLevelDesc = (value, index) => {
		warehouse.levels[index].desc = value
		setWarehouse({ ...warehouse, levels: [...warehouse.levels] })
	}

	const changeMoveProp = (value, index) => {
		warehouse.levels[index].canmove = value
		setWarehouse({ ...warehouse, levels: [...warehouse.levels] })
	}

	const addLevel = () => {
		if (warehouse && warehouse.levels && warehouse.levels.length < 6) {
			warehouse.levels.push({
				desc: '',
				canmove: false
			})
		}
		setWarehouse({ ...warehouse })
	}

	const deleteLevel = index => {
		warehouse.levels && warehouse.levels.length > 0 && warehouse.levels.splice(index, 1)
		setWarehouse({ ...warehouse })
	}

	const handleSaveWarehouse = async () => {
		try {
			setSaving(true)
			await saveWarehouse(false, warehouse).then(response => response)
		} catch (e) {
			setSaving(false)
			console.error(e)
		} finally {
			setSaving(false)
		}
	}

	const closeModal = () => {
		setOpenAddModal(false)
		setNewWarehouse({
			country: '',
			displayitem: 1,
			name: '',
			pairingsite: '',
			plant: '',
			displayallocatedinstruments: false,
			levels: []
		})
	}

	const handleDelete = () => {
		setOpenConfirmModal(true)
	}

	return (
		<Card width='100%' height='100%' ref={listRef} data-cy='card-warehouse-settings'>
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 8px 8px'>
				{t('wms:WarehouseSettings')}
			</FlexView>
			<FlexView width='100%' flexDirection='row' margin='0'>
				<Input
					margin='16px 16px'
					type='text'
					label={t('wms:Country')}
					placeholder={t('wms:Country')}
					value={warehouse.country}
					onChange={e => setWarehouse({ ...warehouse, country: e.target.value })}
					data-cy='input-warehouse-country'
				/>
				<Input
					margin='16px 16px'
					type='text'
					label={t('wms:Plant')}
					placeholder={t('wms:Plant')}
					value={warehouse.plant}
					onChange={e => setWarehouse({ ...warehouse, plant: e.target.value })}
					data-cy='input-warehouse-plant'
				/>
				<Input
					margin='16px 16px'
					type='text'
					label={t('wms:Name')}
					placeholder={t('wms:Name')}
					value={warehouse.name}
					onChange={e => setWarehouse({ ...warehouse, name: e.target.value })}
					data-cy='input-warehouse-name'
				/>
				<Input
					margin='16px 16px'
					type='text'
					label={t('wms:PairingSite')}
					placeholder={t('wms:PairingSite')}
					value={warehouse.pairingsite}
					onChange={e => setWarehouse({ ...warehouse, pairingsite: e.target.value })}
					data-cy='input-warehouse-pairing-site'
				/>
			</FlexView>
			<FlexView flexDirection='row' width='100%' justifyContent='space-between'>
				<FlexView flexDirection='column' width='30%'>
					<RadioGroup
						label={t('wms:DisplayItem')}
						margin='0'
						value={warehouse.displayitem}
						options={displayOptions}
						onChange={v => setWarehouse({ ...warehouse, displayitem: v })}
					/>
					<FlexView fontSize='medium' fontWeight='bold' margin='16px 0 16px 0'>
						{t('wms:EquipmentManagement')}
					</FlexView>
					<Checkbox
						style={{ display: 'flex', whiteSpace: 'break-spaces' }}
						label={t('wms:DisplayAllInstruments')}
						checked={warehouse.displayallocatedinstruments}
						onChange={v => setWarehouse({ ...warehouse, displayallocatedinstruments: v })}
					/>
				</FlexView>
				<Card
					width='calc(75% - 48px)'
					margin='0 8px 8px 8px'
					height={`${listHeight}px`}
					style={{ overflowY: 'scroll', overflowX: 'hidden', position: 'relative' }}
					data-cy='card-levels'>
					<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 8px 8px'>
						{t('wms:Levels')}
					</FlexView>
					<FlexView flexDirection='row' width='100%' flexWrap='wrap'>
						{warehouse &&
							_.map(warehouse.levels, (level, index) => (
								<Card key={index} margin='0 16px 16px 0'>
									<FlexView fontSize='small' fontWeight='bold' color='metalic' width='100%'>
										{`${t('wms:Level')}`} {index}
									</FlexView>
									<Input
										type='text'
										label={t('wms:Description')}
										placeholder={t('wms:Description')}
										value={level.desc}
										onChange={e => changeLevelDesc(e.target.value, index)}
										data-cy='input-settings-level-description'
									/>
									<FlexView flexDirection='row' width='100%' justifyContent='space-between'>
										<FlexView fontSize='medium' fontWeight='bold' style={{ alignSelf: 'center' }}>{`${t(
											'wms:CanMove'
										)}`}</FlexView>
										<Toggle
											textOff={t('wms:No')}
											textOn={t('wms:Yes')}
											alternate
											state={level.canmove}
											onChange={v => changeMoveProp(v, index)}></Toggle>
									</FlexView>
									<Icon
										name='trash'
										margin='auto 0 0 auto'
										width='14px'
										height='14px'
										color='error'
										fontWeight='bold'
										onClick={() => deleteLevel(index)}
									/>
								</Card>
							))}
					</FlexView>
					<Button
						disabled={warehouse && Object.keys(warehouse).length > 0 && warehouse.levels.length >= 6}
						backgroundColor='secondary'
						color='white'
						margin='auto 0 0 auto'
						onClick={() => addLevel()}
						data-cy='button-add-level'>
						{t('wms:Add')} {t('wms:Level')}
						<Icon name='add' color='white' width='14px' height='14px' margin='0 0 0 8px' />
					</Button>
				</Card>
			</FlexView>
			<FlexView
				flexDirection='row'
				width='100%'
				margin='auto auto 0 0'
				alignItems='flex-end'
				justifyContent='flex-end'>
				{user && _.find(user.roles, u => u === 'administrators') ? (
					<Button
						backgroundColor='error'
						margin='8px 8px 0 0'
						color='white'
						onClick={() => handleDelete()}
						data-cy='button-delete-warehouse'>
						{t('wms:Delete')} {t('wms:Warehouse')}
						<Icon name='trash' color='white' width='14px' height='14px' margin='0 0 0 8px' />
					</Button>
				) : null}
				<Button
					margin='8px 8px 0 0'
					backgroundColor='secondary'
					color='white'
					onClick={() => setOpenAddModal(true)}
					data-cy='button-add-warehouse'>
					{t('wms:Add')} {t('wms:Warehouse')}
					<Icon name='add' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				</Button>
				<Button
					margin='8px 8px 0 0'
					backgroundColor='success'
					color='white'
					onClick={() => handleSaveWarehouse()}
					data-cy='button-save-warehouse'>
					{t('wms:Save')}
					<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				</Button>
				<LoadingOverlay visible={saving} borderRadius='card' />
			</FlexView>
			<AddWarehouseModal isOpen={openAddModal} onOutsideClick={() => closeModal()} />
			<DialogModal
				isOpen={openConfirmModal}
				text={t('wms:ConfirmWarehouseDeletion')}
				onConfirm={deleteWarehouse}
				onCancel={() => setOpenConfirmModal(false)}
			/>
		</Card>
	)
}

export default WarehouseSettings
