/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay, Icon } from 'components/common'

import { DispatchContext } from '../../stores/DispatchStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'
import theme from 'utils/theme'
import _ from 'lodash'

const DispatchInfo = () => {
	const {
		selectedDispatch,
		materialList,
		lastLevelsList,
		loadingDispatchInfo,
		setDispatchModal,
		endUserList,
		ownershipList
	} = useContext(DispatchContext)
	const { t, i18n } = useTranslation()

	const listDeadSpace = 260
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const matFormatted = id => {
		return id !== null &&
			id !== undefined &&
			materialList &&
			materialList.length &&
			materialList.find(mat => mat.mvid === id)
			? materialList.find(mat => mat.mvid === id).formatted
			: ''
	}

	const levelFullName = id => {
		let level = id && lastLevelsList.find(level => level.id === id)
		return level && level.fullname ? level.fullname : '*****'
	}

	const handleDispatch = () => {
		setDispatchModal(true)
	}

	const formatEndUser = id => {
		let endUser = id && _.find(endUserList, eu => eu.id === id)
		return endUser && endUser.name ? endUser.name : '*****'
	}

	const formatOwnership = id => {
		let ownership = id && _.find(ownershipList, o => o.id === id)
		return ownership && ownership.label ? ownership.label : '*****'
	}

	return (
		<Card width='calc(100% - 48px)' flex='1' margin='0' ref={listRef} style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingDispatchInfo} borderRadius='card' />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 8px 0'>
				{t('wms:DispatchInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				{selectedDispatch ? (
					<FlexView flexDirection='row' width='100%'>
						<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:DispatchExpectedDate')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{DATE_FORMAT(selectedDispatch.expecteddate, i18n.language)}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:WorkOrderDescription')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedDispatch.workorderdescription}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:DispatchOccupationFactor')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{selectedDispatch.occupationfactor}
							</FlexView>
						</FlexView>
					</FlexView>
				) : null}
				<FlexView width='100%' margin='0 0 8px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0'>
						{t('wms:TransportData')}
					</FlexView>
				</FlexView>
				{selectedDispatch.vehicleid && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							ID
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedDispatch.vehicleid}
						</FlexView>
					</FlexView>
				)}
				{selectedDispatch.vehicledata.Name && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:Name')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedDispatch.vehicledata.Name}
						</FlexView>
					</FlexView>
				)}
				{selectedDispatch.vehicledata.Weight && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:Weight')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedDispatch.vehicledata.Weight}
						</FlexView>
					</FlexView>
				)}
			</FlexView>
			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				{selectedDispatch && selectedDispatch.contents && (
					<FlexView width='100%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView
							width='100%'
							fontSize='medium'
							fontWeight='bold'
							margin='0'
							style={{ borderTop: '1px solid whitesmoke' }}>
							{t('wms:Content')}
						</FlexView>
					</FlexView>
				)}
				{selectedDispatch &&
					selectedDispatch.contents &&
					selectedDispatch.contents.map((content, index) => (
						<FlexView
							key={`${content.did}-${index}`}
							width='100%'
							margin='0'
							flexDirection='row'
							flexWrap='wrap'
							padding={!index ? '8px 0' : '16px 0 8px'}
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
							<FlexView width='50%' margin='0 0 8px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:EndUser')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{formatEndUser(content.enduser.id)}
								</FlexView>
							</FlexView>
							<FlexView width='50%' margin='0 0 8px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
									{t('wms:Ownership')}
								</FlexView>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{formatOwnership(content.ownership.id)}
								</FlexView>
							</FlexView>
							<FlexView flex='1' width='100%' fontSize='small' margin='auto 0'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{matFormatted(content.material.id)} <br />
									<strong>
										{`${levelFullName(content.level.id)} | ${t('wms:Quantity')}: ${content.quantity}`}
									</strong>
								</FlexView>
							</FlexView>
						</FlexView>
					))}
			</FlexView>
			<Button
				fontSize='small'
				margin='auto 0 0 auto'
				color='white'
				backgroundColor='secondary'
				disabled={false}
				onClick={() => {
					handleDispatch()
				}}>
				<Icon name='play' color='white' height='12px' width='12px' margin='0 8px 0 0' />
				{`${t('wms:ExecuteDispatch')}`}
			</Button>
		</Card>
	)
}

export default DispatchInfo
