/** @format */

const thirdPartyStr = 'Third Party Workshop'
const thirdPartyTranslate = 'wms:ThirdPartyWorkshop'
const thirdPartyPath = 'tpartywork/'

const materialModifStr = 'Material Modification'
const materialPhysTransStr = 'Material Physical Transfer'

const rigReturnStr = 'Rig Return'
const rigReturnTranslate = 'wms:RigReturn'
const rigReturnPath = 'rigreturn/'

const RigPrepTranslate = 'wms:RigPreparation'
const materialModifTranslate = 'wms:MaterialModification'
const materialPhysTransTranslate = 'wms:MaterialPhysicalTransfer'
const receivingTranslate = 'wms:ReceivingInspection'
const periodicTranslate = 'wms:PeriodicInspection'
const modifiedProdTranslate = 'wms:ModifiedProduct'
const dispatchTranslate = 'wms:Dispatch'
const ReceiptNoteTranslate = 'wms:ReceiptNote'
const ageManagementTranslate = 'wms:AgeManagement'

const ToBeScrappedStr = 'wms:StatusToBeScrapped'

export const INSPECTION = {
	literal: [
		'Rig Prep',
		thirdPartyStr,
		materialModifStr,
		materialPhysTransStr,
		'Receiving Inspection',
		'Spot Inspection',
		rigReturnStr,
		'Periodical',
		'Modified Products'
	],
	translateKey: [
		RigPrepTranslate,
		thirdPartyTranslate,
		materialModifTranslate,
		materialPhysTransTranslate,
		receivingTranslate,
		'wms:ActivityReport',
		rigReturnTranslate,
		periodicTranslate,
		modifiedProdTranslate
	],
	path: [
		'rigprep/',
		thirdPartyPath,
		'matmodif/',
		'phystrans/',
		'recinsp/',
		'',
		rigReturnPath,
		'perinsp/',
		'modifprod/'
	],
	code: {
		RIGPREP: 0,
		TPWORKSHOP: 1,
		MATMODIF: 2,
		PHYSTRANS: 3,
		RECINSP: 4,
		SPOT: 5,
		RIGRETURN: 6,
		PERIODICAL: 7,
		MODIFPROD: 8
	},
	abbreviation: ['RP', 'TW', 'MM', 'PT', 'R', '', 'RR', 'P', 'MP']
}

export const REPORT_TYPE = {
	literal: [],
	translateKey: [
		RigPrepTranslate,
		thirdPartyTranslate,
		materialModifTranslate,
		materialPhysTransTranslate,
		receivingTranslate,
		'wms:ActivityReport',
		rigReturnTranslate,
		periodicTranslate,
		'wms:ModifiedProductReports',
		dispatchTranslate,
		'wms:BackloadManifest',
		ReceiptNoteTranslate
	],
	path: [
		'rigprep/',
		thirdPartyPath,
		'matmodif/',
		'ptexcel/',
		'recinsp/',
		'',
		rigReturnPath,
		'perinsp/',
		'modifprod/',
		'dispatch/',
		rigReturnPath,
		'batchentrance/'
	],
	reportDownloadURL: [
		(wid, reportId, i18n) => `rigprep/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `tpartywork/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `matmodif/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `ptexcel/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `recinsp/${wid}/excel/${reportId}/${i18n.language}`,
		'',
		(wid, reportId, i18n) => `rigreturn/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `perinsp/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `modifprod/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `dispatch/${wid}/excel/${reportId}/${i18n.language}`,
		(wid, reportId, i18n) => `rigreturn/${wid}/backload/${reportId}/${i18n.language}`,
		(wid, beid) => `batchentrance/${wid}/receipt-note/${beid}/report/excel`,
		(wid, ageId) => `agemgmt/${wid}/extract/${ageId}/report/excel`
	],
	defectType: [1, 1, 1, 1, 4, 3, 2, 3, 1, 0, 0, 0, 0, 0, 0],
	code: {
		RIGPREP: 0,
		TPWORKSHOP: 1,
		MATMODIF: 2,
		PHYSTRANS: 3,
		RECINSP: 4,
		SPOT: 5,
		RIGRETURN: 6,
		PERIODICAL: 7,
		MODIF_PRODUCTS: 8,
		DISPATCH: 9,
		BACKLOAD_MANIFEST: 10,
		MILL_RECEIPT: 11,
		AGE_EXTRACTS: 12,
		TITLE_TRANSFER: 13
	}
}

export const REPORT_STATUS = {
	literal: ['Not concluded', 'Pending', 'Validated', 'Rejected'],
	translateKey: ['wms:NotConcluded', 'wms:Pending', 'wms:Validated', 'wms:NotOk'],
	color: ['gray', 'warning', 'success', 'error'],
	code: {
		NOTCONCLUDED: 0,
		PENDING: 1,
		VALIDATED: 2,
		REJECTED: 3
	}
}

export const REPORT_ITEM_STATUS = {
	literal: ['Ok', 'Not Ok', 'Accept', 'Spare', 'Repair', 'Reject', 'N.A.'],
	translateKey: ['wms:Ok', 'wms:NotOk', 'wms:Accept', '', 'wms:Repair', 'wms:Reject', ''],
	color: ['success', 'error', 'success', '', 'warning', 'error', ''],
	code: {
		OK: 0,
		NOTOK: 1,
		ACCEPT: 2,
		SPARE: 3,
		REPAIR: 4,
		REJECT: 5,
		NA: -1
	}
}

export const REPORT_ITEM = {
	literal: [
		null,
		'Pin',
		'Box',
		'Int. Body',
		'Ext. Body',
		'Marking Legibility',
		'Drift',
		'Coating Varnish condition',
		'Thread Protectors'
	],
	accessor: [null, 'pin', 'box', 'intbody', 'extbody', 'marklegibility', 'drift', 'coatingvarnish', 'threadprot'],
	code: {
		PIN: 1,
		BOX: 2,
		INTBODY: 3,
		EXTBODY: 4,
		MARKLEGIBILITY: 5,
		DRIFT: 6,
		COATINGVARNISH: 7,
		THREADPROT: 8
	}
}

export const MRType = {
	literal: ['Rig Prep', thirdPartyStr, materialModifStr, materialPhysTransStr],
	path: ['rigprep/', thirdPartyPath, 'matmodif/', 'phystrans/'],
	notes: ['rigprepreports', 'tpartyworkreports', 'matmodifreports', 'phystransreports'],
	code: {
		RIGPREP: 0,
		TPWORKSHOP: 1,
		MATMODIF: 2,
		PHYSTRANS: 3
	},
	cid: ['rpcid', 'twcid', 'mmcid', 'ptcid']
}

export const STATUS = {
	literal: ['NotStarted', 'Running', 'StandBy', 'Done'],
	color: ['primary', 'success', 'warning', 'metalic'],
	code: {
		NOTSTARTED: 0,
		RUNNING: 1,
		STANDBY: 2,
		DONE: 3
	}
}

export const listStatus = {
	notstarted: 0,
	running: 1,
	standby: 2,
	done: 3
}

export const listStatusLiteral = status => {
	let e = listStatus
	switch (status) {
		case e.notstarted:
			return 'NOT STARTED'
		case e.running:
			return 'RUNNING'
		case e.standby:
			return 'STAND BY'
		case e.done:
			return 'DONE'
		default:
			return 'UNKNOWN'
	}
}

const listStatusKey = {
	toberepaired: 'ToBeRepaired',
	ready: 'Ready',
	inspected: 'Inspected',
	tobescrapped: 'ToBeScrapped',
	instock: 'InStock',
	underinspection: 'UnderInspection',
	notdefined: 'NotDefined',
	underreceiving: 'UnderReceiving',
	tobeinspected: 'ToBeInspected',
	dispatched: 'Dispatched',
	delivered: 'Delivered',
	WIPO: 'WIPO',
	scrapped: 'Scrapped',
	split: 'Split',
	assembled: 'Assembled'
}

export const listStatusTranslateKey = status => {
	let s = listStatusKey
	switch (status) {
		case s.toberepaired:
			return 'wms:StatusToBeRepaired'
		case s.ready:
			return 'wms:StatusReady'
		case s.inspected:
			return 'wms:StatusInspected'
		case s.tobescrapped:
			return ToBeScrappedStr
		case s.instock:
			return 'wms:StatusInStock'
		case s.underinspection:
			return 'wms:StatusUnderInspection'
		case s.underreceiving:
			return 'wms:StatusUnderReceiving'
		case s.tobeinspected:
			return 'wms:StatusToBeInspected'
		case s.dispatched:
			return 'wms:StatusDispatched'
		case s.delivered:
			return 'wms:StatusDelivered'
		case s.WIPO:
			return 'wms:StatusWIPO'
		case s.scrapped:
			return 'wms:StatusScrapped'
		case s.split:
			return 'wms:StatusSplit'
		case s.assembled:
			return 'wms:StatusAssembled'
		case s.notdefined:
			return 'wms:StatusNotDefined'
		default:
			return ''
	}
}

export const consignmentType = ['Closed End', 'Open End', 'NA']

export const ageManagementType = {
	prefix: ['sm', 'tt', 'pt', '', '', '', '', '', '', '', '', 'sm', 'tt', 'pt'],
	queryPath: ['smquery/', 'ttquery/', 'ptquery/'],
	issuePath: ['smissue/', 'ttissue/', 'ptissue/'],
	listPath: ['smlist', 'ttlist', 'ptlist', '', '', '', '', '', '', '', '', 'smlist', 'ttlist', 'ptlist'],
	checkPath: ['smcheck/', 'ttcheck/', 'ptcheck/', '', '', '', '', '', '', '', '', 'smcheck/', 'ttcheck/', 'ptcheck/'],
	translateKey: ['wms:SlowMovingItems', 'wms:TransferOfTitle', materialPhysTransTranslate],
	code: {
		SLOW_ITEMS: 0,
		TITLE_TRANSFER: 1,
		PHYSTRANS: 2
	},
	slowmoving: 1,
	transferoftitle: 2,
	matphysicaltransfer: 3
}

export const SETTINGS = {
	translateKey: ['wms:Inspection', ageManagementTranslate, modifiedProdTranslate],
	code: {
		Inspection: 1,
		AgeManagement: 2,
		ModifiedProduct: 3
	}
}

export const REPORT_TYPE_REF = {
	RIGRETURN: 1,
	AGEMANAGEMENT: 2,
	DISPATCH: 3,
	MILLRECEIPTS: 4,
	DELIVERYNOTE: 5,
	MODIFYPRODUCT: 6,
	MATERIALMODIFY: 7,
	THIRDPARTYWORK: 8,
	BACKLOADMANIFEST: 9,
	PERIODICALINSPECTION: 10,
	PHYSICALTRANSFER: 11,
	RECEIVINGINSPECTION: 12,
	RIGPREP: 13
}

export const reportTypeTranslateKey = type => {
	let s = REPORT_TYPE_REF
	switch (type) {
		case s.RIGRETURN:
			return rigReturnTranslate
		case s.AGEMANAGEMENT:
			return ageManagementTranslate
		case s.DISPATCH:
			return dispatchTranslate
		case s.MILLRECEIPTS:
			return ReceiptNoteTranslate
		case s.DELIVERYNOTE:
			return 'wms:DeliveryNotes'
		case s.MODIFYPRODUCT:
			return modifiedProdTranslate
		case s.MATERIALMODIFY:
			return materialModifTranslate
		case s.THIRDPARTYWORK:
			return thirdPartyTranslate
		case s.BACKLOADMANIFEST:
			return 'wms:BackloadManifest'
		case s.PERIODICALINSPECTION:
			return periodicTranslate
		case s.PHYSICALTRANSFER:
			return materialPhysTransTranslate
		case s.RECEIVINGINSPECTION:
			return receivingTranslate
		case s.RIGPREP:
			return RigPrepTranslate
		default:
			return undefined
	}
}

export const THREAD_COMPOUND_TYPE = {
	literal: ['Storage', 'Running'],
	translateKey: ['', 'wms:Storage', 'wms:Running'],
	code: {
		STORAGE: 1,
		RUNNING: 2
	}
}

export const REPORT_ELEMENTS_STATUS = {
	literal: ['', 'Not Ok', 'Ok', 'N/A'],
	translateKey: ['', 'wms:Rejected', 'wms:Ok', 'N/A'],
	code: {
		NOTOK: 1,
		OK: 2,
		NA: -1
	},
	codeString: {
		NOTOK: 'Rejected',
		OK: 'Ok',
		NA: 'N/A'
	},
	color: {
		NOTOK: 'error',
		OK: 'success',
		NA: ''
	}
}
export const CONTENT_TABS = {
	literal: ['Filter Material', 'Filter Ownership', 'Filter Item', 'Dashboard'],
	translateKey: ['wms:FilterMaterial', 'wms:FilterOwnership', 'wms:FilterItem', 'wms:Dashboard'],
	code: {
		FILTER_MATERIAL: 0,
		FILTER_OWNERSHIP: 1,
		FILTER_ITEM: 2,
		DASHBOARD: 3
	}
}

export const INSPECTION_STAGES = {
	code: {
		ARRIVAL: 1,
		PERIODICAL: 2,
		MATERIAL_REQUISITION: 3,
		RETURN: 4
	},
	type: {
		MATERIAL_REQUISITION: 'material-requisition',
		ARRIVAL: 'receiving-inspection',
		PERIODICAL: 'periodical-inspection',
		RIG_RETURN: 'rig-return',
		MODIFIED_PRODUCT: 'modified-product'
	},
	translateKey: type => {
		if (type === INSPECTION_STAGES.type.ARRIVAL) return receivingTranslate
		if (type === INSPECTION_STAGES.type.PERIODICAL) return periodicTranslate
		if (type === INSPECTION_STAGES.type.MATERIAL_REQUISITION) return 'wms:MaterialRequisition'
		if (type === INSPECTION_STAGES.type.RIG_RETURN) return rigReturnTranslate
		if (type === INSPECTION_STAGES.type.MODIFIED_PRODUCT) return modifiedProdTranslate
		return 'N/A'
	}
}

export const INSPECTION_TYPE = {
	literal: [
		'Rig Prep',
		thirdPartyStr,
		materialModifStr,
		materialPhysTransStr,
		'Receiving Inspection',
		rigReturnStr,
		'Periodical',
		'Modified Products',
		'Batch Entrance',
		'Pipe Age Management',
		'Dispatch'
	],
	translateKey: [
		RigPrepTranslate,
		thirdPartyTranslate,
		materialModifTranslate,
		materialPhysTransTranslate,
		receivingTranslate,
		rigReturnTranslate,
		periodicTranslate,
		modifiedProdTranslate,
		ReceiptNoteTranslate,
		ageManagementTranslate,
		dispatchTranslate
	],
	feature: {
		RIGPREP: 'rigprep',
		TPWORKSHOP: 'tpartywork',
		MATMODIF: 'matmodif',
		PHYSTRANS: 'phystrans',
		RECINSP: 'recinsp',
		RIGRETURN: 'rigreturn',
		PERIODICAL: 'perinsp',
		MODIFPROD: 'modifprod',
		BATCHENTRANCE: 'batchentrance',
		AGEMANAGEMENT: 'extract',
		DISPATCH: 'dispatch'
	},
	code: {
		RIGPREP: 0,
		TPWORKSHOP: 1,
		MATMODIF: 2,
		PHYSTRANS: 3,
		RECINSP: 4,
		RIGRETURN: 5,
		PERIODICAL: 6,
		MODIFPROD: 7,
		BATCH: 8,
		AGEMANAGEMENT: 9,
		DISPATCH: 10
	}
}
export const featureReport = type => {
	let re = INSPECTION_TYPE.code
	switch (type) {
		case re.RIGPREP:
			return 'rigprep'
		case re.TPWORKSHOP:
			return 'tpartywork'
		case re.MATMODIF:
			return 'matmodif'
		case re.PHYSTRANS:
			return 'phystrans'
		case re.RECINSP:
			return 'recinsp'
		case re.RIGRETURN:
			return 'rigreturn'
		case re.PERIODICAL:
			return 'perinsp'
		case re.MODIFPROD:
			return 'modifprod'
		case re.BATCH:
			return 'batchentrance'
		case re.AGEMANAGEMENT:
			return 'extract'
		case re.DISPATCH:
			return 'dispatch'
		default:
			return null
	}
}

export const defaultSummary = {
	NAME: 'Name',
	NAME_ALT: 'name',
	FIELDNAME: 'FieldName',
	RIGNAME: 'RigName',
	WELLNAME: 'WellName',
	TPARTYNAME: 'TpartyName',
	TPARTYLOCATION: 'TpartyLocation',
	LTPANUMBER: 'LtpaNumber',
	DELIVERYLOCATION: 'DeliveryLocation',
	CUSTOMERREQUESTREFERENCE: 'CustomerRequestReference',
	FIELD_NAME: 'field_name',
	RIG_NAME: 'rig_name',
	WELL_NAME: 'well_name',
	TPARTY_NAME: 'tparty_name',
	TPARTY_LOCATION: 'tparty_location',
	LTPA_NUMBER: 'ltpa_number',
	DELIVERY_LOCATION: 'delivery_location',
	CUSTOMER_REQUEST_REFERENCE: 'customer_request_reference',
	WORKORDERDESCRIPTION: 'work_order_description',
	WORK_ORDER_DESCRIPTION: 'WorkOrderDescription',
	ATTACHED_CUSTOMER_REQUEST: 'AttachedCustomerRequest'
}

export const translateKeySummary = summary => {
	let s = defaultSummary
	switch (summary) {
		case s.FIELDNAME:
			return 'wms:FieldName'
		case s.RIGNAME:
			return 'wms:RigName'
		case s.WELLNAME:
			return 'wms:WellName'
		case s.TPARTYNAME:
			return 'wms:ThirdPartyName'
		case s.TPARTYLOCATION:
			return 'wms:ThirdPartyLocation'
		case s.LTPANUMBER:
			return 'wms:NumberLTPA'
		case s.DELIVERYLOCATION:
			return 'wms:DeliveryLocation'
		case s.CUSTOMERREQUESTREFERENCE:
			return 'wms:CustomerRequestReference'
		case s.FIELD_NAME:
			return 'wms:FieldName'
		case s.RIG_NAME:
			return 'wms:RigName'
		case s.WELL_NAME:
			return 'wms:WellName'
		case s.TPARTY_NAME:
			return 'wms:ThirdPartyName'
		case s.TPARTY_LOCATION:
			return 'wms:ThirdPartyLocation'
		case s.LTPA_NUMBER:
			return 'wms:NumberLTPA'
		case s.DELIVERY_LOCATION:
			return 'wms:DeliveryLocation'
		case s.CUSTOMER_REQUEST_REFERENCE:
			return 'wms:CustomerRequestReference'
		case s.WORK_ORDER_DESCRIPTION:
			return 'wms:WorkOrderDescription'
		case s.WORKORDERDESCRIPTION:
			return 'wms:WorkOrderDescription'
		case s.ATTACHED_CUSTOMER_REQUEST:
			return 'wms:AttachedCustomerRequest'
		default:
			return undefined
	}
}

export const formatSummaryKeys = summary => {
	let s = defaultSummary
	switch (summary) {
		case s.CUSTOMER_REQUEST_REFERENCE:
			return 'customer_request_reference'
		case s.CUSTOMERREQUESTREFERENCE:
			return 'customer_request_reference'
		case s.FIELDNAME:
			return 'field_name'
		case s.RIGNAME:
			return 'rig_name'
		case s.WELLNAME:
			return 'well_name'
		case s.TPARTYNAME:
			return 'tparty_name'
		case s.TPARTY_LOCATION:
			return 'tparty_location'
		case s.TPARTYLOCATION:
			return 'tparty_location'
		case s.LTPANUMBER:
			return 'ltpa_number'
		case s.DELIVERYLOCATION:
			return 'delivery_location'
		case s.WORK_ORDER_DESCRIPTION:
			return 'work_order_description'
		case s.WORKORDERDESCRIPTION:
			return 'work_order_description'
		default:
			return undefined
	}
}

export const STOCK_STATUS = {
	literal: [
		'UnderReceiving',
		'ToBeInspected',
		'Ready',
		'Inspected',
		'ToBeRepaired',
		'ToBeScrapped',
		'Dispatched',
		'Delivered',
		'WIPO',
		'Scrapped',
		'Split',
		'Assembled',
		'InStock',
		'UnderInspection'
	],
	color: [
		'black',
		'black',
		'success',
		'black',
		'warning',
		'error',
		'black',
		'black',
		'warning',
		'error',
		'black',
		'black',
		'success',
		'black'
	],
	code: {
		NotDefined: -1,
		UnderReceiving: 0,
		ToBeInspected: 1,
		Ready: 2,
		Inspected: 3,
		ToBeRepaired: 4,
		ToBeScrapped: 5,
		Dispatched: 6,
		Delivered: 7,
		WIPO: 8,
		Scrapped: 9,
		Split: 10,
		Assembled: 11,
		InStock: 12,
		UnderInspection: 13
	},
	key: {
		NotDefined: 'NotDefined',
		UnderReceiving: 'UnderReceiving',
		ToBeInspected: 'ToBeInspected',
		Ready: 'Ready',
		Inspected: 'Inspected',
		ToBeRepaired: 'ToBeRepaired',
		ToBeScrapped: 'ToBeScrapped',
		Dispatched: 'Dispatched',
		Delivered: 'Delivered',
		WIPO: 'WIPO',
		Scrapped: 'Scrapped',
		Split: 'Split',
		Assembled: 'Assembled',
		InStock: 'InStock',
		UnderInspection: 'UnderInspection'
	}
}

export const formatStockKeys = code => {
	let s = STOCK_STATUS.code
	switch (code) {
		case s.Assembled:
			return 'wms:StatusAssembled'
		case s.Delivered:
			return 'wms:StatusDelivered'
		case s.Dispatched:
			return 'wms:StatusDispatched'
		case s.InStock:
			return 'wms:StatusInStock'
		case s.Inspected:
			return 'wms:StatusInspected'
		case s.NotDefined:
			return 'wms:StatusNotDefined'
		case s.Ready:
			return 'wms:StatusReady'
		case s.Scrapped:
			return ToBeScrappedStr
		case s.Split:
			return 'wms:StatusSplit'
		case s.ToBeInspected:
			return 'wms:StatusToBeInspected'
		case s.ToBeRepaired:
			return 'wms:StatusToBeRepaired'
		case s.ToBeScrapped:
			return ToBeScrappedStr
		case s.UnderInspection:
			return 'wms:StatusUnderInspection'
		case s.UnderReceiving:
			return 'wms:StatusUnderReceiving'
		case s.WIPO:
			return 'wms:StatusWIPO'
		default:
			return undefined
	}
}

export const GROUPING_BUTTONS = {
	literal: ['Group by Material', 'Group by Rack'],
	translateKey: ['wms:GroupByMaterial', 'wms:GroupByRack'],
	code: {
		GROUP_BY_MATERIAL: 0,
		GROUP_BY_RACK: 1
	}
}

export const ALLOWED_FILES = {
	types: ['pdf', 'png', 'jpg', 'ods', 'doc', 'docx', 'odt', 'ppt', 'pptx', 'xls', 'xlsx']
}

export const FINAL_STATUS = {
	translateKey: ['wms:Accept', 'wms:Repair', 'wms:Reject'],
	code: {
		ACCEPT: 0,
		REPAIR: 1,
		REJECT: 2
	},
	color: ['success', 'warning', 'error']
}

export const EQUIPMENT_STATUS = {
	code: {
		ACTIVE: 'Active',
		INACTIVE: 'Inactive'
	}
}

export const STATUS_FEATURE_REF = {
	BATCHENTRANCE: 'batchentrance',
	DISPATCH: 'dispatch',
	RIGRETURN: rigReturnStr,
	MODIFPROD: 'Modified Product',
	RIGPREP: 'Rig Preparation'
}

export const statusFeatureTranslateKey = type => {
	let s = STATUS_FEATURE_REF
	switch (type) {
		case s.BATCHENTRANCE:
			return 'wms:BatchEntrance'
		case s.DISPATCH:
			return dispatchTranslate
		case s.RIGRETURN:
			return rigReturnTranslate
		case s.MODIFPROD:
			return modifiedProdTranslate
		case s.RIGPREP:
			return RigPrepTranslate
		default:
			return undefined
	}
}

export const fileTypeSummary = {
	PDF: 'application/pdf',
	PNG: 'image/png',
	JPG: 'image/jpeg',
	ODS: 'application/vnd.oasis.opendocument.spreadsheet',
	DOC: 'application/msword',
	DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	ODT: 'application/vnd.oasis.opendocument.text',
	PPT: 'application/vnd.ms-powerpoint',
	PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	XLS: 'application/vnd.ms-excel',
	XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const FILE_TYPES = type => {
	let t = fileTypeSummary
	switch (type) {
		case t.PDF:
			return 'pdf'
		case t.PNG:
			return 'png'
		case t.JPG:
			return 'jpg'
		case t.ODS:
			return 'ods'
		case t.DOC:
			return 'doc'
		case t.DOCX:
			return 'docx'
		case t.ODT:
			return 'odt'
		case t.PPT:
			return 'ppt'
		case t.PPTX:
			return 'pptx'
		case t.XLS:
			return 'xls'
		case t.XLSX:
			return 'xlsx'
		default:
			return undefined
	}
}

export const THREAD_COMPOUND_COLUMNS = {
	code: {
		MANUFACTURER: 1,
		APPLICATION_METHOD: 2
	}
}

export const SOURCE_TYPES_ENTRANCE = [
	{
		value: 'CUSTOMER_ITEM',
		label: 'wms:CustomerOrderAndItem'
	},
	{
		value: 'SALES_ITEM',
		label: 'wms:SalesOrderAndItem'
	},
	{
		value: 'ALL_SOURCES',
		label: 'wms:AllSources'
	}
]

export const TYPES_OF_RIGHTS = [
	{
		value: 'OWNERSHIP',
		label: 'wms:Ownership'
	},
	{
		value: 'END_USER',
		label: 'wms:EndUser'
	}
]

export const USAGE_RIGHTS_SOURCES = [
	{
		value: 'RACK',
		label: 'wms:Rack'
	},
	{
		value: 'CUSTOMER_ITEM',
		label: 'wms:CustomerOrderAndItem'
	},
	{
		value: 'SALES_ITEM',
		label: 'wms:SalesOrderAndItem'
	},
	{
		value: 'VALID',
		label: 'VALID'
	},
	{
		value: 'REPORTS',
		label: 'wms:Reports'
	}
]

export const MOVEMENT_SOURCE_TYPES = [
	{
		value: 'RACK',
		label: 'wms:Rack'
	},
	{
		value: 'REPORTS',
		label: 'wms:Reports'
	}
]

export const isRFID = [
	{
		value: 0,
		label: 'wms:No'
	},
	{
		value: 1,
		label: 'wms:Yes'
	}
]

export default {
	colors: {
		primary: '#59CBE4',
		secondary: '#6a5cff',
		tertiary: '#f8779c',
		success: '#59e480',
		fontSuccess: '#1ebf4b',
		info: '#59CBE4',
		warning: '#fbca4d',
		error: '#FB7171',
		background: '#f4f4f4',
		header: '#f8faff',
		black: '#000000',
		darkGray: '#303030',
		gray: '#777777',
		metalic: '#919098',
		lightGray: '#e0e0e0',
		whitesmoke: '#f1f1f1',
		offWhite: '#f5f5f5',
		white: '#ffffff',
		red: '#c50c29',
		green: '#1f8600'
	}
}
