/** @format */

import { AssetsAttributesContext } from 'apps/wms/stores/AssetsAttributesStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, FlexView, Icon } from 'components/common'
import { Input, Select } from 'components/form'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import AddAttributeModal from './AddAttributeModal'
import DialogModal from 'apps/wms/components/forms/DialogModal'

const OptionalAttributes = () => {
	const { t } = useTranslation()
	const { user } = useContext(UserContext)
	const {
		optionalAttr,
		attrSelected,
		setAttrSelected,
		openAddModal,
		setOpenAddModal,
		saveAttr,
		openConfirmModal,
		setOpenConfirmModal,
		deleteAttr,
		optAttrSelected,
		setOptAttrSelected
	} = useContext(AssetsAttributesContext)

	const listDeadSpace = 75
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	const selectAttribute = value => {
		setAttrSelected(value)
		setOptAttrSelected(_.find(optionalAttr, opt => opt.id === value))
	}

	const deleteAttribute = () => {
		setOpenConfirmModal(true)
	}

	const addAtrribute = () => {
		setOpenAddModal(true)
	}

	const saveAttribute = value => {
		saveAttr(value)
	}

	const checkRoles = () => {
		let applicationID = process.env.REACT_APP_ID
		let keys = user && user.roles != null && Object.keys(user.roles[applicationID])
		let hasRole = keys && _.some(keys, u => u !== 'ADMNISTRATORS')

		return hasRole && optAttrSelected && (!optAttrSelected.name || optAttrSelected.description) ? true : false
	}

	const checkFields = () => {
		let isDisabled = true
		isDisabled = isDisabled && optAttrSelected && !optAttrSelected.name
		return isDisabled
	}

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		user && checkRoles()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	return (
		<FlexView width='100%' ref={listRef} style={{ position: 'relative' }} height='100%' maxHeight='100%'>
			<FlexView
				flexDirection='column'
				width='100%'
				height={`${listHeight}px`}
				style={{ overflowY: 'auto', alignItems: 'center', overflowX: 'hidden' }}>
				<Select
					alignItems='center'
					style={{ alignSelf: 'center' }}
					width='90%'
					margin='16px 0 0 0'
					label={t('wms:SelectAttribute')}
					placeholder={t('wms:SelectAttribute')}
					options={
						optionalAttr &&
						_.map(optionalAttr, opt => {
							return { value: opt.id, label: opt.name }
						})
					}
					value={attrSelected}
					onChange={v => selectAttribute(v)}
					data-cy='select-optional-attributes'
				/>
				<Input
					alignSelf='center'
					width='95%'
					margin='8px 0 0 23px'
					type='text'
					value={optAttrSelected && optAttrSelected.name}
					placeholder={t('wms:Name')}
					label={t('wms:Name')}
					onChange={e => setOptAttrSelected({ ...optAttrSelected, name: e.target.value })}
					disabled={optAttrSelected && optAttrSelected.isnew === false}
					data-cy='input-opt-attribute-name'
				/>
				<Input
					style={{ alignSelf: 'center' }}
					width='95%'
					margin='8px 0 0 23px'
					type='text'
					value={optAttrSelected && optAttrSelected.description}
					placeholder={t('wms:Description')}
					label={t('wms:Description')}
					onChange={e => setOptAttrSelected({ ...optAttrSelected, description: e.target.value })}
					data-cy='input-opt-attribute-desc'
				/>
				<Input
					style={{ alignSelf: 'center' }}
					width='95%'
					margin='8px 0 0 23px'
					type='text'
					value={optAttrSelected && optAttrSelected.symbol}
					placeholder={t('wms:Symbol')}
					label={t('wms:Symbol')}
					onChange={e => setOptAttrSelected({ ...optAttrSelected, symbol: e.target.value })}
					data-cy='input-opt-attribute-symbol'
				/>
			</FlexView>
			<FlexView flexDirection='row' width='100%' justifyContent='center' aligItems='center' margin='auto 0 auto 0'>
				<Button
					backgroundColor='error'
					color='white'
					onClick={() => deleteAttribute()}
					disabled={checkRoles()}
					margin='0'
					data-cy='button-delete-attribute'>
					{t('wms:Delete')}
					<Icon name='trash' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				</Button>
				<Button
					margin='0 0 0 16px'
					backgroundColor='secondary'
					color='white'
					onClick={() => addAtrribute()}
					data-cy='button-add-attribute'>
					{t('wms:Add')}
					<Icon name='add' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				</Button>
				<Button
					backgroundColor='success'
					color='white'
					onClick={() => saveAttribute(optAttrSelected)}
					margin='0 0 0 16px'
					disabled={checkFields()}
					data-cy='button-save-attribute'>
					{t('wms:Save')}
					<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				</Button>
			</FlexView>
			<AddAttributeModal isOpen={openAddModal} onOutsideClick={() => setOpenAddModal(false)} />
			<DialogModal
				isOpen={openConfirmModal}
				text={t('wms:ConfirmAttributeDeletion')}
				onConfirm={deleteAttr}
				onCancel={() => setOpenConfirmModal(false)}
			/>
		</FlexView>
	)
}

export default OptionalAttributes
