/** @format */

import { WMSContext } from 'apps/wms/stores/WmsStore'
import { FlexView } from 'components/common'
import { Select } from 'components/form'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const UnitsSettingsCard = () => {
	const { lengthUnit, setLengthUnit, weightUnit, setWeightUnit, diameterUnit, setDiameterUnit } =
		useContext(WMSContext)
	const { t } = useTranslation()

	const availableLengthUnits = [
		{
			value: 'mm',
			label: `${t('wms:Milimeter')}`
		},
		{
			value: 'ft',
			label: `${t('wms:feet')}`
		}
	]

	const availableWeightUnits = [
		{
			value: 'kg',
			label: `${t('wms:kilogram')}`
		},
		{
			value: 'lb',
			label: `${t('wms:pound')}`
		}
	]

	const availableDiameterUnits = [
		{
			value: 'in',
			label: `${t('wms:Inch')}`
		},
		{
			value: 'mm',
			label: `${t('wms:Milimeter')}`
		}
	]

	return (
		<FlexView
			backgroundColor='white'
			borderRadius='4px'
			width='45%'
			margin='16px 0 0 0'
			elevation='low'
			alignItems='stretch'
			style={{ position: 'relative' }}>
			<FlexView padding='0px' margin='8px' flexDirection='row' width='90%'>
				<Select
					data-cy='select'
					value={weightUnit}
					options={availableWeightUnits}
					onChange={value => setWeightUnit(value)}
					label={t('wms:Weight')}
					width='100%'
				/>
			</FlexView>
			<FlexView width='90%' padding='0px' margin='8px' flexDirection='row'>
				<Select
					data-cy='select'
					value={lengthUnit}
					options={availableLengthUnits}
					onChange={value => setLengthUnit(value)}
					width='100%'
					label={t('wms:Length')}
				/>
			</FlexView>
			<FlexView width='90%' padding='0px' margin='8px' flexDirection='row'>
				<Select
					data-cy='select'
					value={diameterUnit}
					options={availableDiameterUnits}
					onChange={value => setDiameterUnit(value)}
					width='100%'
					label='OD / ID'
				/>
			</FlexView>
		</FlexView>
	)
}

export default UnitsSettingsCard
