/** @format */
/* eslint-disable react/prop-types */

import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import Resizer from 'react-image-file-resizer'

import { FlexView } from 'components/common'

const getColor = props => {
	if (props.isDragAccept) {
		return props.theme.colors.success
	}
	if (props.isDragReject) {
		return props.theme.colors.error
	}
	if (props.isDragActive) {
		return props.theme.colors.primary
	}
	return props.theme.colors.lightGray
}

const Container = styled(FlexView)`
	font-family: 'Roboto';
	flex-wrap: nowrap;
	flex-direction: column;
	align-self: stretch;
	align-items: center;
	justify-content: center;
	margin: 16px 0px 16px 0px;
	border-width: 2px;
	border-radius: 2px;
	border-color: ${props => getColor(props)};
	border-style: dashed;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	min-width: 20rem;
	transition: border 0.24s ease-in-out;

	&:hover {
		border-color: ${({ theme }) => theme.colors.primary};
		color: ${({ theme }) => theme.colors.primary};
	}
`

const IMAGE_MAX_WIDTH = 600
const IMAGE_MAX_HEIGHT = 400
const IMAGE_QUALITY = 100
const IMAGE_ROTATION = 0
const IMAGE_COMPRESS_FORMAT = 'JPEG'
const RETURN_CONTENT_TYPE = 'base64'

const UploadBox = ({
	text,
	fileTypes,
	setFile,
	height,
	multiple = false,
	fontSize = 3,
	setBase64,
	maxSize = 314572800
}) => {
	const { t } = useTranslation()

	const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } =
		useDropzone({ accept: fileTypes, multiple: multiple })

	const resizeFile = file =>
		new Promise(resolve => {
			Resizer.imageFileResizer(
				file,
				IMAGE_MAX_WIDTH,
				IMAGE_MAX_HEIGHT,
				IMAGE_COMPRESS_FORMAT,
				IMAGE_QUALITY,
				IMAGE_ROTATION,
				uri => {
					resolve(uri)
				},
				RETURN_CONTENT_TYPE
			)
		})

	useEffect(() => {
		async function receiveFile() {
			try {
				if (fileRejections.length > 0) {
					toast.error(t('wms:ImageFileTypeError'))
				} else if (acceptedFiles && acceptedFiles.length && acceptedFiles[0].size <= maxSize) {
					setFile(acceptedFiles[0])
					const image = await resizeFile(acceptedFiles[0])
					setBase64(image)
				} else if (acceptedFiles && acceptedFiles.length) toast.error(t('wms:ErrorImageSize'))
			} catch (error) {
				toast.error(error.message)
			}
		}
		receiveFile()
	}, [acceptedFiles, fileRejections, maxSize, setBase64, setFile, t])

	return (
		<Container
			{...getRootProps({ isDragActive, isDragAccept, isDragReject, onClick: event => console.log(event) })}
			style={{ paddingTop: `${height / 2}px`, paddingBottom: `${height / 2}px` }}>
			<input {...getInputProps()} />
			{
				<font size={fontSize} style={{ 'text-align': 'center' }}>
					<b> {text} </b>
				</font>
			}
			{/* {isDragReject && t('Sorry, this file type is not permitted')+'!'} */}
		</Container>
	)
}

export default UploadBox
