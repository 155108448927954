/** @format */

import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { AssetsReportRefContext } from 'apps/wms/stores/AssetsReportRefStore'
import { AssetsInspSettingsContext } from 'apps/wms/stores/AssetsInspSettingsStore'
import { reportTypeTranslateKey } from 'apps/wms/utils/enum'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { FlexView } from 'components/common'
import Button from 'components/common/Button'
import Card from 'components/common/Card'
import Icon from 'components/common/Icon'
import LoadingOverlay from 'components/common/LoadingOverlay'
import UpdateRefModal from './UpdateRefModal'

const ReportsList = () => {
	const { t } = useTranslation()
	const {
		loadingReports,
		reports,
		selectedReport,
		setSelectedReport,
		openUpdateModal,
		setOpenUpdateModal,
		setReference,
		inspectionReports,
		selectedInspectionReport,
		setSelectedInspectionReport
	} = useContext(AssetsReportRefContext)
	const { loadingConfig, stagesList } = useContext(AssetsInspSettingsContext)

	const listDeadSpace = 50
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		if (selectedReport) {
			handleSelectReport(selectedReport)
			return
		}

		if (selectedInspectionReport) handleSelectInspectionReport(selectedInspectionReport)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedReport])

	const handleSelectReport = report => {
		if (report) {
			setSelectedReport(report)
			setSelectedInspectionReport(null)
		}
	}

	const handleSelectInspectionReport = inspectionReport => {
		if (inspectionReport) {
			setSelectedInspectionReport(inspectionReport)
			setSelectedReport(null)
		}
	}

	const handleUpdate = type => {
		if (type !== null) {
			handleSelectReport(type)
			setOpenUpdateModal(true)
		}
	}

	const closeModal = () => {
		setOpenUpdateModal(false)
		setReference('')
	}

	return (
		<Card
			width='100%'
			height='100%'
			padding='16px 8px 16px 16px'
			margin='0'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='card-reports-list'>
			<FlexView
				width='100%'
				height={`${listHeight}px`}
				margin='8px 0 0 0'
				padding='0 3px 0 0'
				style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				<LoadingOverlay visible={loadingReports || loadingConfig} borderRadius='card' />
				<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
					{t('wms:Reports')}
				</FlexView>
				{reports && reports.length > 0 ? (
					_.map(reports, (rep, index) => (
						<FlexView
							key={rep.reporttype}
							backgroundColor={
								selectedReport && selectedReport.reporttype === rep.reporttype ? 'whitesmoke' : 'default'
							}
							borderRadius='4px'
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							onClick={() => handleSelectReport(rep)}
							data-cy='list-report'>
							<FlexView flex='1' fontSize='small' margin='auto 0 auto 8px' style={{ cursor: 'pointer' }}>
								{t(`${reportTypeTranslateKey(rep.reporttype)}`)}
							</FlexView>
							<Button
								backgroundColor='secondary'
								color='white'
								fontSize='tiny'
								margin='auto 8px auto 0'
								onClick={() => handleUpdate(rep.reporttype)}
								data-cy='button-update-report'>
								{t('wms:Update')}
								<Icon
									name='refresh'
									width='16px'
									height='16px'
									color='white'
									margin='0 0 0 8px'
									fontWeight='bold'
								/>
							</Button>
						</FlexView>
					))
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='16px'
						alignItems='center'
						justifyContent='center'
						data-cy='no-reports-list'>
						{t('wms:NoReports')}
					</FlexView>
				)}
				<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='24px 0 16px'>
					{t('wms:InspectionReports')}
				</FlexView>
				{stagesList ? (
					stagesList.map(stage => (
						<FlexView key={stage.id} width='100%' flexDirection='column' margin='0 0 8px 0'>
							<FlexView width='100%' fontSize='tiny'>
								{t('wms:Stage')}
							</FlexView>
							<FlexView
								width='100%'
								fontWeight='bold'
								margin='0 0 8px 0'
								style={{ borderBottom: '1px solid #E0E0E0' }}>
								{stage.name}
							</FlexView>
							{inspectionReports &&
								inspectionReports
									.filter(rep => rep.stage === stage.id)
									.map(inspection => (
										<FlexView
											backgroundColor={
												selectedInspectionReport && selectedInspectionReport.id === inspection.id
													? 'background'
													: ''
											}
											key={inspection.id}
											fontSize='small'
											width='100%'
											flexDirection='row'
											alignItems='center'
											justifyContent='space-between'
											padding='8px 0 '
											borderRadius='4px'
											style={{ cursor: 'pointer' }}
											onClick={() => handleSelectInspectionReport(inspection)}
											data-cy='list-inspection-report'>
											<FlexView flex='1' fontSize='small' margin='0 0 0 8px' style={{ cursor: 'pointer' }}>
												{inspection.name}
											</FlexView>
											<Button
												backgroundColor='secondary'
												color='white'
												fontSize='tiny'
												margin='0 8px 0 0'
												data-cy='button-update-inspection-report'>
												{t('wms:Details')}
												<Icon
													name='info'
													width='16px'
													height='16px'
													color='white'
													margin='0 0 0 8px'
													fontWeight='bold'
												/>
											</Button>
										</FlexView>
									))}
						</FlexView>
					))
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='16px'
						alignItems='center'
						justifyContent='center'
						data-cy='no-reports-list'>
						{t('wms:NoReports')}
					</FlexView>
				)}
			</FlexView>
			<UpdateRefModal isOpen={openUpdateModal} onOutsideClick={closeModal} />
		</Card>
	)
}

export default ReportsList
