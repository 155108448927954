/** @format */

import { ActualDataContext } from 'apps/wms/stores/ActualDataModifStore'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Select } from 'components/form'
import _ from 'lodash'
import React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const ModifBatchPipes = () => {
	const { t } = useTranslation()
	const {
		endUsers,
		endUserSelected,
		setEndUserSelected,
		poNumbers,
		customerOrder,
		setCustomerOrder,
		customerItem,
		setCustomerItem,
		filteredPoItems,
		mainSource,
		setMainSource,
		ownerships,
		selectedOwnership,
		setSelectedOwnership,
		salesNumbers,
		salesOrder,
		setSalesOrder,
		salesItem,
		setSalesItem,
		filteredSalesItems,
		setAvailablePipes,
		setLoadingPipes,
		getAvailablePipes,
		loadingOwnerships,
		loadingCO,
		loadingSO
	} = useContext(ActualDataContext)

	const typeofFilter = [
		{
			value: 'CUSTOMER_ITEM',
			label: t('wms:CustomerOrderAndItem')
		},
		{
			value: 'SALES_ITEM',
			label: t('wms:SalesOrderAndItem')
		}
	]

	const endUserStr = t('wms:EndUser')
	const ownershipStr = t('wms:Ownership')

	const searchData = async () => {
		try {
			setLoadingPipes(true)
			await getAvailablePipes()
				.then(response => {
					setAvailablePipes(response)
					setLoadingPipes(false)
				})
				.catch(e => {
					console.error(e)
					setLoadingPipes(false)
				})
		} catch (e) {
			console.error(e)
			setLoadingPipes(false)
		} finally {
			setLoadingPipes(false)
		}
	}

	const checkFields = () => {
		let isDisabled = true
		isDisabled =
			mainSource === 'CUSTOMER_ITEM'
				? isDisabled && (!endUserSelected || !customerOrder || !customerItem || !selectedOwnership)
				: mainSource === 'SALES_ITEM'
				? isDisabled && (!endUserSelected || !salesOrder || !salesItem || !selectedOwnership)
				: true
		return isDisabled
	}

	return (
		<FlexView flexDirection='column' width='31%' height='100%' margin='0 8px 0 0'>
			<Card width='calc(100% - 48px)' margin='8px 0 8px 8px' data-cy='card-select-source'>
				<Select
					width='100%'
					label={t('wms:TypeOfSource')}
					placeholder={t('wms:TypeOfSource')}
					value={mainSource}
					options={typeofFilter}
					onChange={v => setMainSource(v)}
					data-cy='select-type-source'
				/>
			</Card>
			<Card width='calc(100% - 48px)' margin='8px 0 0 8px' height='100%' data-cy='card-filter-items'>
				<FlexView flexDirection='column' width='100%' height='100%'>
					{mainSource && mainSource === 'CUSTOMER_ITEM' ? (
						<FlexView flexDirection='column' width='100%'>
							<Select
								width='100%'
								label={endUserStr}
								placeholder={endUserStr}
								value={endUserSelected}
								options={_.map(endUsers, eu => {
									return { value: eu.id, label: eu.name }
								})}
								onChange={v => setEndUserSelected(v)}
								searchable
								data-cy='select-end-user'
							/>
							<LoadingOverlay visible={loadingCO} borderRadius='card' />
							<Select
								width='100%'
								margin='16px 0 0 0'
								label={t('wms:CustomerOrder')}
								placeholder={t('wms:CustomerOrder')}
								value={customerOrder}
								options={
									poNumbers &&
									_.map(poNumbers, po => {
										return { value: po.label, label: po.label }
									})
								}
								onChange={v => setCustomerOrder(v)}
								disabled={!endUserSelected}
								searchable
								data-cy='select-customer-order'
							/>
							<Select
								width='100%'
								margin='16px 0 0 0'
								label={t('wms:CustomerItem')}
								placeholder={t('wms:CustomerItem')}
								value={customerItem}
								options={
									filteredPoItems &&
									_.map(filteredPoItems, filtered => {
										return { value: filtered.label, label: filtered.label }
									})
								}
								onChange={v => setCustomerItem(v)}
								disabled={!customerOrder}
								searchable
								data-cy='select-customer-item'
							/>
							<LoadingOverlay visible={loadingOwnerships} borderRadius='card' />
							{ownerships && (
								<Select
									width='100%'
									margin='16px 0 0 0'
									label={ownershipStr}
									placeholder={ownershipStr}
									value={selectedOwnership}
									options={
										ownerships &&
										_.map(ownerships, owner => {
											return { value: owner.id, label: owner.label }
										})
									}
									onChange={v => setSelectedOwnership(v)}
									disabled={!customerItem}
									searchable
									data-cy='select-ownership'
								/>
							)}
						</FlexView>
					) : mainSource && mainSource === 'SALES_ITEM' ? (
						<FlexView flexDirection='column' width='100%'>
							<Select
								width='100%'
								label={endUserStr}
								placeholder={endUserStr}
								value={endUserSelected}
								options={_.map(endUsers, eu => {
									return { value: eu.id, label: eu.name }
								})}
								onChange={v => setEndUserSelected(v)}
								searchable
								data-cy='select-end-user'
							/>
							<LoadingOverlay visible={loadingSO} borderRadius='card' />
							<Select
								width='100%'
								margin='16px 0 0 0'
								label={t('wms:SalesOrder')}
								placeholder={t('wms:SalesOrder')}
								value={salesOrder}
								options={
									salesNumbers &&
									_.map(salesNumbers, sn => {
										return { value: sn.label, label: sn.label }
									})
								}
								onChange={v => setSalesOrder(v)}
								disabled={!endUserSelected}
								searchable
								data-cy='select-sales-order'
							/>
							<Select
								width='100%'
								margin='16px 0 0 0'
								label={t('wms:SalesItem')}
								placeholder={t('wms:SalesItem')}
								value={salesItem}
								options={
									filteredSalesItems &&
									_.map(filteredSalesItems, filtered => {
										return { value: filtered.label, label: filtered.label }
									})
								}
								onChange={v => setSalesItem(v)}
								disabled={!salesOrder}
								searchable
								data-cy='select-sales-item'
							/>
							<LoadingOverlay visible={loadingOwnerships} borderRadius='card' />
							<Select
								width='100%'
								margin='16px 0 0 0'
								label={ownershipStr}
								placeholder={ownershipStr}
								value={selectedOwnership}
								options={
									ownerships &&
									_.map(ownerships, owner => {
										return { value: owner.id, label: owner.label }
									})
								}
								onChange={v => setSelectedOwnership(v)}
								disabled={!salesItem}
								searchable
								data-cy='select-ownership'
							/>
						</FlexView>
					) : (
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='24px'
							color='lightGray'
							margin='auto 0'
							alignItems='center'
							justifyContent='center'
							style={{ textAlign: 'center' }}
							data-cy='no-source-type-selected'>
							{t('wms:NoSourceTypeSelected')}
						</FlexView>
					)}
				</FlexView>
				<Button
					backgroundColor='secondary'
					color='white'
					onClick={() => searchData()}
					margin='auto auto 0 auto'
					disabled={checkFields()}
					data-cy='button-search-data'>
					{t('wms:Search')}
					<Icon name='search' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				</Button>
			</Card>
		</FlexView>
	)
}

export default ModifBatchPipes
