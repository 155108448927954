/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/metrolab`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

const newInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getInstruments: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`instrument/${wid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.list)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	addEquipment: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`instrument/${wid}`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	updateEquipment: (wid, equipId, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.put(`instrument/${wid}/${equipId}`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	releaseInstruments: (wid, supervisorid, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.post(
					`/instrument/${wid}/use`,
					{ uid: supervisorid, ids: [] },
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.usedlistid)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getInstrumentsTypes: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`instrument/${wid}/types`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.types)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getInstrumentCaseList: (wid, caseId, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`/instrument/${wid}/list/${caseId}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.list)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getNewInstrumentCase: (wid, payload, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.post(`/instrument/${wid}/use`, payload, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.usedlistid)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
