/** @format */

import { FlexView } from 'components/common'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BatchListInfo from '../containers/batch/BatchListInfo'
import MaterialList from '../containers/batch/MaterialList'
import Subheader from '../containers/common/Subheader'
import { BatchEntranceContext } from '../stores/BatchEntranceStore'
import { entrance, genParams } from '../utils/hubs'

const BatchEntrancePage = () => {
	const { t } = useTranslation()
	const { batchContent } = useContext(BatchEntranceContext)

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Entrance'),
				path: `/wms/entrance${genParams(entrance)}`
			},
			{
				name: t('wms:EntranceList'),
				path: '/wms/entrance/batch'
			}
		],
		[t]
	)

	return (
		<FlexView
			width='100%'
			maxWidth='100%'
			flex='1'
			alignItems='center'
			margin='0'
			padding='0'
			backgroundColor='#F8F8F9'
			position='relative'>
			<Subheader crumbs={crumbs} />
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:Entrance')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='55%' margin='0 8px 0 0'>
						{<MaterialList />}
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						{batchContent && <BatchListInfo />}
					</FlexView>
				</FlexView>
			</FlexView>
		</FlexView>
	)
}

export default BatchEntrancePage
