/** @format */

import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FlexView, Button } from 'components/common'
import Return from '../../assets/icons/return.svg'
import PerInspection from '../../assets/icons/periodic-inspection.svg'
import AgeManagement from '../../assets/icons/age-management.svg'
import Inventory from '../../assets/icons/inventory.svg'
import Content from '../../assets/icons/content.svg'
import BatchEntrance from '../../assets/icons/entrance.svg'
import Move from '../../assets/icons/move.svg'
import UsageRights from '../../assets/icons/usage-rights.svg'
import Dispatch from '../../assets/icons/dispatch.svg'
import TagPrinting from '../../assets/icons/tag-printing.svg'
import PrepareEntrance from '../../assets/icons/prepare-entrance.svg'
import NominalData from '../../assets/icons/change-nominal-data.svg'
import ActualData from '../../assets/icons/actual-data-modification.svg'
import { UserContext } from 'stores/UserStore'
import Entry from '../../assets/icons/receiving-inspection.svg'
import MatReq from '../../assets/icons/material-requisition.svg'
import _ from 'lodash'
import { toast } from 'react-toastify'

const NavCol = styled(FlexView)`
	width: 100%;
	display: grid;
	${props =>
		!props.cols
			? css`
					grid-template-columns: 1fr 1fr;
			  `
			: css`
					grid-template-columns: repeat(${props.cols}, 1fr);
			  `}
	grid-template-rows: auto;
	grid-column-gap: 16px;
	align-items: stretch;
`

const NavColHeader = styled(FlexView)`
	color: ${({ theme }) => theme.colors.secondary};
	padding: 8px;
	text-align: center;
	justify-content: center;
	width: calc(95% - 16px);
	font-size: 24px;
	flex-direction: row;
	margin: 0 auto 16px auto;
	border-bottom: 4px solid ${({ theme }) => theme.colors.secondary};
`

const ListCard = styled(Button)`
	margin: 0 0 16px 0;
	border-radius: 4px;
	padding: 16px;
	color: ${({ theme }) => theme.colors.gray};
	background: ${({ theme }) => theme.colors.white};
	white-space: break-spaces;
	${({ disabled }) => disabled && 'opacity: 0.4; cursor: not-allowed;'}
	flex-direction: column;

	> img {
		color: ${({ theme }) => theme.colors.secondary};
		height: 100px;
	}

	&.fill {
		grid-column-start: 1;
		grid-column-end: -1;
	}
`

const NavGrid = () => {
	const { t } = useTranslation()
	const { user } = useContext(UserContext)
	const history = useHistory()
	const redirectTo = path => {
		history.push(path)
	}
	const applicationID = process.env.REACT_APP_ID

	const checkRoles = () => {
		const roles = ['ADMINISTRATORS', 'anyOtherRoles', 'ADMIN', 'QUALITY', 'LOGISTIC', 'OPERATOR']
		const intersection =
			user && user.roles[applicationID] && _.intersection(Object.keys(user.roles[applicationID]), roles)
		return intersection.length === 0
	}

	useEffect(() => {
		const roles = ['ADMINISTRATORS', 'anyOtherRoles', 'ADMIN', 'QUALITY', 'LOGISTIC', 'OPERATOR']
		const intersection =
			user && user.roles[applicationID] && _.intersection(Object.keys(user.roles[applicationID]), roles)
		if (intersection.length === 0) toast.error(t('wms:NoPermissions'))
	}, [user, applicationID, t])

	return (
		<FlexView justifyContent='space-between' flexDirection='row' width='100%' height='100%' flexWrap='wrap'>
			<FlexView width='calc(32%)'>
				<NavColHeader>{t('wms:ReceiveMaterial')}</NavColHeader>
				<NavCol data-cy='nav-section'>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('entrance/batch')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={BatchEntrance} alt='Entrance'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:Entrance')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('entrance/printing')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={TagPrinting} alt='Tag Printing'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:TagPrinting')}
						</FlexView>
					</ListCard>
					{user &&
						user.roles[applicationID] &&
						!!Object.keys(user.roles[applicationID]).find(r => r === 'ADMIN') && (
							<ListCard
								data-cy='nav-button'
								onClick={() => redirectTo('entrance/prepare')}
								elevation='low'
								disabled={checkRoles()}>
								<img src={PrepareEntrance} alt='Entrance'></img>
								<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
									{t('wms:PrepareEntrance')}
								</FlexView>
							</ListCard>
						)}
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('prepare/inspection/entrance')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={Entry} alt='Entry'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:EntranceInspections')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('prepare/inspection/return')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={Return} alt='Entry'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:Return')}
						</FlexView>
					</ListCard>
				</NavCol>
			</FlexView>
			<FlexView width='calc(32%)'>
				<NavColHeader>{t('wms:ManageWarehouse')}</NavColHeader>
				<NavCol data-cy='nav-section'>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('content/main')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={Content} alt='Content'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:Content')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/inventory/check/')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={Inventory} alt='Inventory'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:Inventory')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/move')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={Move} alt='Move'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:Move')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/usagerights')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={UsageRights} alt='Ownership'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:UsageRights')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/inspection/perinsp')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={PerInspection} alt='Periodical Inspection'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:PeriodicInspection')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/pipeagemanagement')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={AgeManagement} alt='Pipe Age Management'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:AgeManagement')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('pipedata/modify')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={ActualData} alt='Content'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:ActualDataModif')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('pipedata/nominaldatamodif')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={NominalData} alt='Content'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:NominalDataModification')}
						</FlexView>
					</ListCard>
				</NavCol>
			</FlexView>
			<FlexView width='calc(32%)'>
				<NavColHeader>{t('wms:DispatchMaterial')}</NavColHeader>
				<NavCol data-cy='nav-section'>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('dispatch/manage')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={Dispatch} alt='Dispatch'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:Dispatch')}
						</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('prepare/inspection/matreq')}
						elevation='low'
						disabled={checkRoles()}>
						<img src={MatReq} alt='Material Requisition'></img>
						<FlexView fontSize='16px' justifyContent='flex-end' minHeight='calc(16px * 2.75)'>
							{t('wms:MaterialRequisition')}
						</FlexView>
					</ListCard>
				</NavCol>
			</FlexView>
			<FlexView width='calc(100%)'>
				<NavColHeader>{t('wms:Reports')}</NavColHeader>
				<NavCol data-cy='nav-section' cols={3}>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/review/mill_receipts')}
						elevation='low'
						disabled={checkRoles()}>
						<FlexView fontSize='16px'>{t('wms:ReceiptNote')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/edit/inspection')}
						elevation='low'
						disabled={checkRoles()}>
						<FlexView fontSize='16px'>{t('wms:InspectionReports')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/export/delivery_notes')}
						elevation='low'
						disabled={checkRoles()}>
						<FlexView fontSize='16px'>{t('wms:DeliveryNotes')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/export/modif_products')}
						elevation='low'
						disabled={checkRoles()}>
						<FlexView fontSize='16px'>{t('wms:ModifiedProducts')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/export/backload_manifest')}
						elevation='low'
						disabled={checkRoles()}>
						<FlexView fontSize='16px'>{t('wms:BackloadManifest')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/export/dispatch')}
						elevation='low'
						disabled={checkRoles()}>
						<FlexView fontSize='16px'>{t('wms:Dispatch')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/review/age_management')}
						elevation='low'
						disabled={checkRoles()}>
						<FlexView fontSize='16px'>{t('wms:AgeManagement')}</FlexView>
					</ListCard>
					<ListCard
						data-cy='nav-button'
						onClick={() => redirectTo('manage/reports/export/title_transfer')}
						elevation='low'
						disabled={checkRoles()}>
						<FlexView fontSize='16px'>{t('wms:TitleTransferDN')}</FlexView>
					</ListCard>
				</NavCol>
			</FlexView>
		</FlexView>
	)
}

export default NavGrid
