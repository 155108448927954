/** @format */

import { AssetsTransportContext } from 'apps/wms/stores/AssetsTransportStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Card, FlexView } from 'components/common'
import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'utils/theme'

const VehicleInfo = () => {
	const { t } = useTranslation()
	const { selectedVehicle } = useContext(AssetsTransportContext)

	const listDeadSpace = 200
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	return (
		<Card width='calc(100% - 48px)' flex='1' ref={listRef} margin='0' style={{ position: 'relative' }}>
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:TransportInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:TransportType')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedVehicle.name}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:MaximumPayload')} [kg]
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedVehicle.maxweightkg}
					</FlexView>
				</FlexView>
				<FlexView width='100%' margin='16px 0' fontSize='small' fontWeight='bold'>
					{t('wms:Properties')}:
				</FlexView>
				<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
					{selectedVehicle &&
						selectedVehicle.properties.length &&
						_.map(selectedVehicle.properties, (property, index) => (
							<Card width='90%' margin='16px 0' key={index} style={{ alignSelf: 'center' }}>
								<FlexView
									width='100%'
									margin='0'
									flexDirection='row'
									flexWrap='wrap'
									padding='0'
									justifyContent='space-between'>
									<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
										<FlexView
											width='100%'
											fontWeight='bold'
											fontSize='tiny'
											color='metalic'
											margin='0'
											data-cy='list-label'>
											{t('wms:AttributeName')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{property.name}
										</FlexView>
									</FlexView>
									<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
										<FlexView
											width='100%'
											fontWeight='bold'
											fontSize='tiny'
											color='metalic'
											margin='0'
											data-cy='list-label'>
											{t('wms:Description')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{property.description}
										</FlexView>
									</FlexView>
									<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
										<FlexView
											width='100%'
											fontWeight='bold'
											fontSize='tiny'
											color='metalic'
											margin='0'
											data-cy='list-label'>
											{t('wms:DefaultValue')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{property.defaultvalue === '' ? '---' : property.defaultvalue}
										</FlexView>
									</FlexView>
									<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
										<FlexView
											width='100%'
											fontWeight='bold'
											fontSize='tiny'
											color='metalic'
											margin='0'
											data-cy='list-label'>
											{t('wms:CanBeEdited')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{property.editable === false ? `${t('wms:No')}` : `${t('wms:Yes')}`}
										</FlexView>
									</FlexView>
								</FlexView>
							</Card>
						))}
				</FlexView>
			</FlexView>
		</Card>
	)
}

export default VehicleInfo
