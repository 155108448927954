/** @format */

import { FlexView } from 'components/common'
import React, { useContext, useMemo } from 'react'
import PreparedEntranceList from '../containers/tagPrint/PreparedEntrancesList'
import PageTemplate from '../containers/common/PageTemplate'
import TagPrintingSubHeader from '../containers/tagPrint/TagPrintingSubHeader'
import { TagPrintingContext } from '../stores/TagPrintingStore'
import { useTranslation } from 'react-i18next'
import { entrance, genParams } from '../utils/hubs'

const TagPrintingPage = () => {
	const { pageLoading } = useContext(TagPrintingContext)
	const { t } = useTranslation()

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Entrance'),
				path: `/wms/entrance${genParams(entrance)}`
			},
			{
				name: t('wms:TagPrinting'),
				path: '/wms/manage/usagerights'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs} loadingOverlay={pageLoading}>
			<FlexView width='95%' justifyContent='flex-start' height='calc(100% - 30px)' margin='0 0 20px 0'>
				<TagPrintingSubHeader />
				<PreparedEntranceList />
			</FlexView>
		</PageTemplate>
	)
}

export default TagPrintingPage
