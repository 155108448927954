/** @format */

import React, { useContext, useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { UserContext } from 'stores/UserStore'
import batch from '../api/batch'
import common from '../api/common'
import { WMSContext } from './WmsStore'
import moment from 'moment'

export const TagPrintingContext = React.createContext()
export const TagPrintingProvider = ({ children }) => {
	const { token } = useContext(UserContext)
	const { t } = useTranslation()
	const { warehouse, getMaterialsDescpritions } = useContext(WMSContext)
	const [pageLoading, setPageLoading] = useState(false)
	const [endUsers, setEndUsers] = useState([])
	const [printers, setPrinters] = useState([])
	const [preparedEntrances, setPreparedEntrances] = useState([])
	const [materialList, setMaterialList] = useState([])

	const getEndUsers = (warehouse, token) =>
		new Promise((resolve, reject) => {
			batch
				.getEndUsers(warehouse.id, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingEndUser')} [ ${e.status} ]: ${e.data}`)
					reject()
				})
		})

	const _getPreparedEntrances = (endUser, fromDate, toDate, token) =>
		new Promise((resolve, reject) => {
			if (endUser && fromDate && toDate) {
				setPageLoading(true)
				batch
					.getPreparedEntrancesList(
						endUser,
						moment(fromDate),
						new moment(toDate).add({ h: 23, m: 59, s: 59 }),
						token
					)
					.then(response => resolve(response))
					.catch(e => {
						setPageLoading(false)
						toast.error(`${t('wms:ErrorGettingPreparedEntrances')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
					.finally(() => {
						setPageLoading(false)
					})
			}
		})

	const getPreparedEntrances = useCallback(
		(endUser, fromDate, toDate) => {
			return _getPreparedEntrances(endUser, fromDate, toDate, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[token]
	)

	const _getPreparedEntranceData = id =>
		new Promise((resolve, reject) => {
			setPageLoading(true)
			batch
				.getPreparedEntrancesData(id, token)
				.then(response => {
					const valids = response.map(m => ({ puuid: m.puuid, valid: m.valid, erpref: m.erpref, checked: false }))
					const materials = _.uniqBy(
						response.map(m => ({ erpref: m.erpref, erpdsc: m.erpdsc, checked: true })),
						'erpref'
					)
					resolve({ valids, materials })
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingPreparedEntrances')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => setPageLoading(false))
		})

	const getPreparedEntranceData = useCallback(
		entranceId => {
			return _getPreparedEntranceData(entranceId)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[token]
	)

	const getPrinters = token =>
		new Promise((resolve, reject) => {
			common
				.getPrinters(token)
				.then(response => resolve(response.length ? response.map(p => ({ value: p, label: p })) : []))
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorGettingPrinterCfg')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const _printItems = (printer, items, token) =>
		new Promise((resolve, reject) => {
			let payload = {
				printer: printer,
				items: [...items]
			}
			common
				.setPrintersByItems(payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:Printing OK'))
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorPrinting')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const printItems = useCallback(
		(printer, items) => _printItems(printer, items, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[token]
	)

	//////////////////////////////////////////////////
	/////////////////// USE EFFECTS //////////////////
	//////////////////////////////////////////////////

	/**
	 * Used to get the endUsers by warehouse
	 */
	useEffect(() => {
		const hydrate = async () => {
			try {
				setPageLoading(true)
				const responses = await Promise.allSettled([
					getEndUsers(warehouse, token),
					getPrinters(token),
					getMaterialsDescpritions()
				])
				responses[0].status === 'fulfilled' && responses[0].value.length
					? setEndUsers(responses[0].value)
					: setEndUsers([])
				responses[1].status === 'fulfilled' && responses[1].value.length
					? setPrinters(responses[1].value)
					: setPrinters([])
				responses[2].status === 'fulfilled' && responses[2].value.length
					? setMaterialList(responses[2].value)
					: setMaterialList([])
			} catch (e) {
				console.error(e)
			} finally {
				setPageLoading(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, token])

	return (
		<TagPrintingContext.Provider
			value={{
				pageLoading,
				setPageLoading,
				endUsers,
				setEndUsers,
				preparedEntrances,
				getPreparedEntrances,
				setPreparedEntrances,
				getPreparedEntranceData,
				printers,
				printItems,
				materialList
			}}>
			{children}
		</TagPrintingContext.Provider>
	)
}
