import React from 'react'
import { FlexView } from 'components/common'
import PrivateRoute from 'containers/router/PrivateRoute'
import AppLogo from 'assets/images/logo.svg'
import Apps from 'apps'

const Home = () => (
  <>
    <PrivateRoute exact path='/'>
      <FlexView height="100vh" width="100vw" alignItems="center" justifyContent="center" fontSize="title" fontWeight="bold">
        <img src={AppLogo} alt="App Logo" height="140px" />
        <FlexView fontSize="title" fontWeight="bold" margin="16px 0px">Shalyn</FlexView>
      </FlexView>
    </PrivateRoute>
    <Apps />
  </>
)

export default Home