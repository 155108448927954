/** @format */
import React, { useCallback, useContext, useState } from 'react'
import Card from 'components/common/Card'
import FlexView from 'components/common/FlexView'
import Button from 'components/common/Button'
import { Icon, Modal } from 'components/common'
import { useTranslation } from 'react-i18next'
import { ThreadCompoundContext } from 'apps/wms/stores/AssetsThreadCompoundStore'
import { Input } from 'components/form'

const NewApplicationMethod = () => {
	const { t } = useTranslation()
	const { methodsList, setMethodsList, newMethod, isMethodModalOpen, setIsMethodModalOpen } =
		useContext(ThreadCompoundContext)

	const methodData = {
		methoddesc: '',
		sortindex: 0,
		originalSortIndex: 0
	}

	const [newMethodData, setNewMethodData] = useState(methodData)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const resetMethodData = () => setNewMethodData(methodData)

	const applicationMethodStr = t('wms:ApplicationMethod')

	const _onNewMethod = async (methodData, newMethodFn, list, setListFn, resetDataFn) => {
		try {
			methodData.sortindex = list.length ? list[list.length - 1].sortindex + 1 : 1
			const methodid = await newMethodFn(methodData)
			setListFn([
				...list,
				{
					methoddesc: methodData.methoddesc,
					sortindex: methodData.sortindex,
					originalSortIndex: methodData.sortindex,
					methodid: methodid
				}
			])
			resetDataFn()
		} catch (error) {
			console.error(error)
		}
	}

	const onNewMethod = useCallback(
		async newMethodData => _onNewMethod(newMethodData, newMethod, methodsList, setMethodsList, resetMethodData),
		[newMethod, methodsList, setMethodsList, resetMethodData]
	)

	return (
		<Modal isOpen={isMethodModalOpen} onOutsideClick={() => setIsMethodModalOpen(false)}>
			<Card width='auto' data-cy='card-new-method-form-wrapper'>
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:New')} {applicationMethodStr}
					<Button fontSize='tiny' margin='0' backgroundColor='error' onClick={() => setIsMethodModalOpen(false)}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flexDirection='row' justifyContent='space-between'>
					<Input
						type='text'
						label={`${t('wms:New')} ${applicationMethodStr}`}
						placeholder={`${t('wms:New')} ${applicationMethodStr}`}
						value={newMethodData.methoddesc}
						onChange={e => setNewMethodData({ ...newMethodData, methoddesc: e.target.value })}
						margin='0'
						minWidth='50%'
						max='20'
						data-cy='input-new-method-name'
					/>
					<FlexView margin='0 0 0 32px'>
						<FlexView padding='22px 1px 0 0'></FlexView>
						<Button
							margin='8px 0'
							padding='8px 16px'
							color='white'
							backgroundColor='success'
							data-cy='button-save-new-method'
							onClick={() => onNewMethod(newMethodData)}>
							{`${t('wms:Save')}`}
							<Icon name='save' color='white' height='12px' width='12px' margin='0 0 0 8px' />
						</Button>
					</FlexView>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default NewApplicationMethod
