/** @format */

import * as ExcelJS from 'exceljs'
import * as FileSaver from 'file-saver'
import * as _ from 'lodash'

const EXCEL_BLOB_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

/**
 * Receives data as a JSON and save it on an excel spreadsheet
 * @param {JSON} jsonData The data to be inserted on the excel spreadsheet
 * @param {string} fileName The name of the file
 */
export const exportAsExcelFile = (jsonData, fileName) => {
	const workbook = new ExcelJS.Workbook()
	const worksheet = workbook.addWorksheet('Data')
	worksheet.columns = _.chain(jsonData ? jsonData[0] : [])
		.keys()
		.map(m => ({ header: m, key: m }))
		.value()
	worksheet.addRows(jsonData)

	workbook.xlsx.writeBuffer().then(data => {
		const blob = new Blob([data], { type: EXCEL_BLOB_TYPE })
		FileSaver.saveAs(blob, `${encodeURIComponent(fileName)}.xlsx`)
	})
}
