import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import { Manager, Reference, Popper } from 'react-popper'
import { FlexView, Icon } from 'components/common'
import Input from './Input'
import { useMemo } from 'react'

const SelectBox = styled.div`
  width: calc(100% - 8px);
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  margin: ${({ label }) => label ? '8px 0px' : '0px'};
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  user-select: none;
  outline: none;

  input {
    width: 100%;
    min-height: auto;
  }
`

const Tag = styled.div`
  position: relative;
  margin: 4px;
  min-width: 16px;
  min-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.component};
    background-color: ${theme.colors.lightGray};
    color: ${theme.colors.darkGray};
  `}
  cursor: pointer;

  span {
    opacity: 1;
    transition: opacity 300ms ease;
  }

  & > div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    transition: opacity 300ms ease;
    opacity: 0;
  }

  &:hover {
    span {
      opacity: 0;
    }

    & > div {
      opacity: 1;
    }
  }
`

const SelectInput = styled(Input)`
  display: flex;
  width: auto;
  flex: 1;
  border-radius: 0px;
  border: none;
  padding: 0px;
  margin: 0px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  outline: none;
`

const OptionsWrapper = styled.div`
  font-family: 'Noto Sans';
  display: flex;
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  opacity: ${({ open }) => open ? '1' : '0'};
  transform: ${({ popperTransform, open }) => `${popperTransform} ${open ? 'translateX(0)' : 'scale(0.9)'} !important`};
  flex-direction: column;
  margin: 8px 0px;
  background: #FFFFFF;
  box-shadow: ${({ theme }) => theme.boxShadows.high};
  overflow-y: auto;
  border-radius: 8px;
  z-index: 999;
  transition: all 0.2s ease;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
  ${({ theme, searchable }) => searchable && `border-top: 1px solid ${theme.colors.lightGray};`};

  /* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

  /* total width */
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {display:none}
`

const Option = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Noto Sans';
  user-select: none;
  min-width: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray};
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.offWhite};
  }

  ${({ theme, selected }) => selected && `background-color: ${theme.colors.offWhite}`};
`

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  white-space: nowrap;
  font-weight: bold;
  margin-right: ${({ inline }) => inline ? '8px' : '0px'};
`

const OptionsPopper = React.forwardRef(({ style, scheduleUpdate, placement, isOpen, currentOptions, renderOptions }, ref) => {
  useEffect(() => {
    scheduleUpdate()
  }, [isOpen, currentOptions, scheduleUpdate])

  return <OptionsWrapper ref={ref} style={style} data-placement={placement} open={isOpen} popperTransform={style.transform}>
    <Options>
      {renderOptions()}
    </Options>
  </OptionsWrapper>
})

const MultiSelect = ({ label, selectedValues, options, onChange, inline, margin, width, fontSize, ordered }) => {
  const inputRef = useRef(null)
  const currentOptionRef = useRef(null)
  const [isOpen, setDropdown] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)

  const currentOptions = useMemo(() => (
    _.filter(options, ({ value, label }) => !_.includes(selectedValues, (value)) && label?.toLowerCase().includes(search?.toLowerCase()))
  ), [options, search, selectedValues])

  const toggleSelect = () => {
    setSelectedIndex(0)
    setDropdown(isOpen => !isOpen)
  }

  const onSelect = selectedValue => () => {
    setSearch('')
    onChange([...selectedValues, selectedValue])
  }

  const removeOption = selectedValue => () => {
    setSelectedIndex(0)
    onChange(_.filter(selectedValues, value => value !== selectedValue))
  }

  const handleSearchChange = e => {
    setSearch(e.target.value)
  }

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'Backspace':
        search.length === 0 && removeOption(_.last(selectedValues))()
        break
      case 'Enter':
        currentOptions.length && onSelect(currentOptions[selectedIndex].value)()
        break
      case 'ArrowDown':
        setSelectedIndex(currentIndex => currentOptions.length > (currentIndex + 1) ? (currentIndex + 1) : 0)
        break
      case 'ArrowUp':
        setSelectedIndex(currentIndex => currentIndex > 0 ? (currentIndex - 1) : (currentOptions.length - 1))
        break
      default:
        selectedIndex >= currentOptions.length && setSelectedIndex(0)
        break
    }
  }

  const renderOptions = () => {
    return _.map(currentOptions, ({ value, label }, index) => {
      const selected = index === selectedIndex
      return <Option key={value} value={value} ref={selected ? currentOptionRef : null} selected={selected} onClick={onSelect(value)}>{label}</Option>
    })
  }

  const getSelectedItems = () => {
    const selectedOptions = ordered ?
    _.filter(options, ({ value }) => _.includes(selectedValues, (value)))  :
    _.sortBy(_.filter(options, ({ value }) => _.includes(selectedValues, (value))), (item) => {
      return selectedValues.indexOf(item.value)
    })
    return _.map(selectedOptions, ({ value, label }) => (
      <Tag onClick={removeOption(value)} key={`selectedOption${value}`}>
        <span>{label}</span>
        <div>
          <Icon name="cross" width="20px" height="20px" color="error" />
        </div>
      </Tag>
    ))
  }

  const focusInput = () => {
    inputRef.current && inputRef.current.focus()
  }

  useEffect(() => {
    isOpen && currentOptionRef.current && currentOptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }, [selectedIndex, isOpen])

  useEffect(() => {
    currentOptions && selectedIndex >= currentOptions.length && setSelectedIndex(currentOptions.length - 1)
  }, [selectedIndex, currentOptions])

  return (
    <Manager>
      <FlexView
        flexDirection={inline ? 'row' : 'column'}
        alignItems={inline ? 'center' : 'flex-start'}
        justifyContent="flex-start"
        position="relative"
        {...{ width, margin, fontSize }}
      >
        {label && <Label inline={inline}>{label}</Label>}
        <Reference>
          {({ ref }) => (
            <SelectBox ref={ref} label={label} onClick={focusInput}>
              {getSelectedItems()}
              <SelectInput margin="0px" value={search} onChange={handleSearchChange} onKeyDown={handleKeyDown} onFocus={toggleSelect} onBlur={toggleSelect} ref={inputRef} />
            </SelectBox>
          )}
        </Reference>
        <Popper
          placement="bottom"
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          {({ ref, style, placement, scheduleUpdate }) => <OptionsPopper {...{ style, scheduleUpdate, placement, isOpen, currentOptions, renderOptions, ref }} />}
        </Popper>
      </FlexView>
    </Manager>
  )
}

MultiSelect.propTypes = {
  /**
   * Label that accompanies the input
   */
  label: PropTypes.string,
  /**
   * Array of selected options' values
   */
  selectedValues: PropTypes.arrayOf(PropTypes.any),
  /**
   * Array of options
   */
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string
  })).isRequired,
  /**
   * Function that is called when the value is changed, being passed as parameter the array of selected options' values
   */
  onChange: PropTypes.func,
  /**
   * Defines if the label should be rendered in the same line as the input
   */
  inline: PropTypes.bool,
  /**
   * Override CSS width property. Must be a valid CSS width value as a string
   */
  width: PropTypes.string,
  /**
   * Override CSS margin property. Must be a valid CSS margin value as a string
   */
  margin: PropTypes.string,
  /**
   * Defines if the selected values should be shown alphabetically ordered or not
   */
  ordered: PropTypes.bool
}

MultiSelect.defaultProps = {
  value: null,
  width: 'fit-content',
  fontSize: 'medium',
  margin: '8px 0px',
  ordered: true
}

export default MultiSelect
