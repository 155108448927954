/** @format */

import { AssetsAgeMgmtContext } from 'apps/wms/stores/AssetsAgeMgmtStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import AddExtractModal from './AddExtractModal'
import UpdateExtractModal from './UpdateExtractModal'
import DialogModal from 'apps/wms/components/forms/DialogModal'

const ExtractList = () => {
	const { t } = useTranslation()
	const {
		extracts,
		selectedExtract,
		setSelectedExtract,
		loadingExtracts,
		openAddModal,
		setOpenAddModal,
		openUpdateModal,
		setOpenUpdateModal,
		setNewExtract,
		openConfirmModal,
		setOpenConfirmModal,
		deleteExtract
	} = useContext(AssetsAgeMgmtContext)

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		selectedExtract && selectedExtract.extractid && handleSelect(selectedExtract.extractid)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extracts])

	const handleSelect = id => {
		id && setSelectedExtract(_.find(extracts, extract => extract.extractid === id))
	}

	const handleUpdate = id => {
		if (id !== null) {
			handleSelect(id)
			setOpenUpdateModal(true)
		}
	}

	const handleDelete = id => {
		if (id !== null) {
			handleSelect(id)
			setOpenConfirmModal(true)
		}
	}

	const closeAddModal = () => {
		setOpenAddModal(false)
		setNewExtract({
			extractdesc: '',
			periodthreshold: 0,
			consignmenttypefilters: [],
			ownershipfilters: [],
			producttypefilters: [],
			erpreferencefilters: []
		})
	}

	return (
		<Card
			width='calc(100% - 48px)'
			flex='1'
			margin='0'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='card-extract-list'>
			<LoadingOverlay visible={loadingExtracts} borderRadius='card' />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:ExtractList')}
			</FlexView>
			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				{extracts && extracts.length > 0 ? (
					_.map(extracts, (extract, index) => (
						<FlexView
							key={extract.extractid}
							backgroundColor={
								selectedExtract && selectedExtract.id === extract.extractid ? 'offWhite' : 'default'
							}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							onClick={() => handleSelect(extract.extractid)}
							data-cy='list-item'>
							<FlexView flex='1' fontSize='small' margin='auto 0'>
								{`${extract.extractid} ${extract.extractdesc}`}
							</FlexView>
							<Button
								backgroundColor='secondary'
								color='white'
								fontSize='tiny'
								margin='auto 8px auto 0'
								onClick={() => handleUpdate(extract.extractid)}
								data-cy='button-update-extract'>
								{t('wms:Update')}
								<Icon
									name='refresh'
									width='16px'
									height='16px'
									color='white'
									margin='0px 0px 0px 8px'
									fontWeight='bold'
								/>
							</Button>
							<Button
								backgroundColor='error'
								color='white'
								onClick={() => handleDelete(extract.extractid)}
								fontSize='tiny'
								margin='auto 0'
								data-cy='button-delete-extract'>
								{t('wms:Delete')}
								<Icon
									name='trash'
									width='16px'
									height='16px'
									color='white'
									margin='0px 0px 0px 8px'
									fontWeight='bold'
								/>
							</Button>
						</FlexView>
					))
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						data-cy='no-extract-list'>
						{t('wms:NoExtractList')}
					</FlexView>
				)}
			</FlexView>
			<Button
				backgroundColor='secondary'
				color='white'
				margin='auto 0 0 auto'
				style={{ alignSelf: 'flex-end' }}
				fontSize='small'
				onClick={() => setOpenAddModal(true)}
				data-cy='button-add-extract'>
				{t('wms:Add')}
				<Icon name='add' width='16px' height='16px' color='white' margin='0px 0px 0px 8px' fontWeight='bold' />
			</Button>
			<DialogModal
				isOpen={openConfirmModal}
				text={t('wms:ConfirmExtractDeletion')}
				onConfirm={deleteExtract}
				onCancel={() => setOpenConfirmModal(false)}
			/>
			<AddExtractModal isOpen={openAddModal} onOutsideClick={() => closeAddModal()} />
			<UpdateExtractModal isOpen={openUpdateModal} onOutsideClick={() => setOpenUpdateModal(false)} />
		</Card>
	)
}

export default ExtractList
