/** @format */

import React, { useContext } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import levels from '../api/assetsLevels'
import { levelDesc } from '../utils/warehouse'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

const initialState = {
	selectedLevels: {
		level0: null,
		level1: null,
		level2: null,
		level3: null,
		level4: null,
		level5: null,
		level6: null
	},
	newLevels: {
		level1: {
			name: '',
			isentrance: false,
			inventoryfreqindays: 30
		},
		level2: {
			name: '',
			isentrance: false,
			inventoryfreqindays: 30
		},
		level3: {
			name: '',
			isentrance: false,
			inventoryfreqindays: 30
		},
		level4: {
			name: '',
			isentrance: false,
			inventoryfreqindays: 30
		},
		level5: {
			name: '',
			isentrance: false,
			inventoryfreqindays: 30
		},
		level6: {
			name: '',
			isentrance: false,
			inventoryfreqindays: 30
		}
	}
}
export const AssetsLevelsContext = React.createContext(initialState)

export const AssetsLevelsProvider = ({ children }) => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const { token } = useContext(UserContext)
	const [loadingLevels, setLoadingLevels] = useState(false)
	const [openTagModal, setOpenTagModal] = useState(false)
	const [loadingPrinters, setLoadingPrinters] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [openPrintModal, setOpenPrintModal] = useState(false)
	const [isPrinting, setIsPrinting] = useState(false)
	const [levelDescription, setLevelDescription] = useState(null)
	const [levelArray, setLevelArray] = useState([])
	const [printers, setPrinters] = useState(null)
	const [printerSelected, setPrinterSelected] = useState(null)
	const [selectedLevels, setSelectedLevels] = useState(initialState.selectedLevels)
	const [newLevels, setNewLevels] = useState(initialState.newLevels)
	const [level, setLevel] = useState(null)
	const invalidParentLevel = t('wms:InvalidParentLevel')

	const initLevels = () => {
		setSelectedLevels(initialState.selectedLevels)
		setNewLevels(initialState.newLevels)
	}
	const getLevels = () =>
		new Promise((resolve, reject) => {
			setSelectedLevels(initialState.selectedLevels)
			warehouse &&
				levels
					.getLevels(warehouse.id, token)
					.then(response => {
						resolve()
						setSelectedLevels({ level0: response })
						let level = levelDesc(warehouse)
						setLevelDescription(level)
						let array = []
						for (var i = 0; i < level.length; i++) {
							array.push(i)
						}
						setLevelArray(array)
						setNewLevels({ ...newLevels })
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorGettingLevels')} [ ${e.status} ]: ${e.data}`)
					})
		})

	const getPrinters = () =>
		new Promise((resolve, reject) => {
			levels
				.getPrinters(token)
				.then(response => {
					let p = []
					_.forEach(response, res => {
						p.push({ value: res, label: res })
					})
					setPrinters(p)
					resolve(response)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorReadingPrinters')} [ ${e.status} ]: ${e.data}`)
					console.error(e)
					reject(e)
				})
		})

	const handleError = e => {
		if (e.status === 412) toast.error(`${t('wms:LevelHasMaterial')} [ ${e.status}] ${e.data}`)
		else toast.error(`${t('wms:ErrorDeletingLevel')} [ ${e.status} ]: ${e.data}`)
		console.error(e)
	}

	const deleteLevelSelected = (level, selected) =>
		new Promise((resolve, reject) => {
			if (level && selected) {
				let parentLevel = selectedLevels[`level${level - 1}`]
				!parentLevel && toast.error(invalidParentLevel)
				levels
					.deleteLevel(warehouse.id, level, selected.id, token)
					.then(response => {
						if (parentLevel) {
							let lv = parentLevel[`levels${level}`]
							_.forEach(lv, (l, index) => {
								if (l.id === selected.id) {
									lv.splice(index, 1)
								}
							})
						}
						level.forEach((_lv, index) => {
							selectedLevels[`level${index}`] = null
						})
						setSelectedLevels({ ...selectedLevels })
						resolve(response)
						setOpenConfirmModal(false)
						toast.success(t('wms:SuccessfullyDeletedLevel'))
					})
					.catch(e => {
						handleError(e)
						reject(e)
					})
			}
		})

	const saveSelectedLevel = (level, selected) =>
		new Promise((resolve, reject) => {
			let payload
			if (level && selected) {
				payload = {
					id: selected.id,
					name: selected.name,
					isentrance: selected.isentrance,
					inventoryfreqindays: selected.inventoryfreqindays
				}
				let parentLevel = undefined
				if (level > 1) {
					parentLevel = selectedLevels[`level${level - 1}`]
					parentLevel ? (payload.parentid = parentLevel.id) : toast.error(invalidParentLevel)
				}
				levels
					.saveLevel(warehouse.id, level, selected.id, payload, token)
					.then(response => {
						toast.success(t('wms:SuccessfullySavedLevel'))
						resolve(response)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorUpdatingLevel')} [ ${e.status} ]: ${e.data}`)
						reject(e)
						console.error(e)
					})
			}
		})

	const addNewLevel = level =>
		new Promise((resolve, reject) => {
			let selected = newLevels[`level${level}`]
			const currentLevel = selectedLevels.level1.levels2
			const isNameDuplicated = currentLevel.some(level => level.name === selected.name)
			if (isNameDuplicated) {
				toast.error(t('wms:RackNameAlreadyExists'))
				return
			}
			if (level && selected) {
				let payload = {
					name: selected.name,
					isentrance: selected.isentrance,
					inventoryfreqindays: selected.inventoryfreqindays
				}
				let parentLevel = selectedLevels[`level${level - 1}`]
				parentLevel ? (payload.parentid = parentLevel.id) : toast.error('wms:InvalidParentLevel')
				levels
					.addLevel(warehouse.id, level, payload, token)
					.then(response => {
						let ret = response
						if (parentLevel) {
							ret[`levels${level + 1}`] = []
							let lv = parentLevel[`levels${level}`]
							lv && lv.push(ret)
							setSelectedLevels({ ...selectedLevels })
						}
						selected.name = ''
						selected.isentrance = false
						selected.inventoryfreqindays = 30
						resolve()
						toast.success(t('wms:SuccessfullyAddedLevel'))
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorCreatingLevel')} [ ${e.status} ]: ${e.data}`)
						reject(e)
						console.error(e)
					})
			}
		})

	const printLevels = level =>
		new Promise((resolve, reject) => {
			setIsPrinting(true)
			let payload = level &&
				level.length && {
					printer: printerSelected,
					levelsid: level.map(lv => {
						return lv.id
					})
				}
			levels
				.printLevel(warehouse.id, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:Printing OK'))
					setOpenPrintModal(false)
					setIsPrinting(false)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorPrinting')} [ ${e.status} ]: ${e.data}`)
					setIsPrinting(false)
				})
				.finally(() => {
					setIsPrinting(false)
				})
		})
	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		warehouse && initLevels()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingLevels(true)
				await getLevels()
			} catch (e) {
				setLoadingLevels(false)
				console.error(e)
			} finally {
				setLoadingLevels(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingPrinters(true)
				await getPrinters()
			} catch (e) {
				setLoadingPrinters(false)
				console.error(e)
			} finally {
				setLoadingPrinters(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<AssetsLevelsContext.Provider
			value={{
				loadingLevels,
				setLoadingLevels,
				selectedLevels,
				setSelectedLevels,
				newLevels,
				setNewLevels,
				levelDescription,
				setLevelDescription,
				level,
				setLevel,
				levelArray,
				setLevelArray,
				openTagModal,
				setOpenTagModal,
				openConfirmModal,
				setOpenConfirmModal,
				deleteLevelSelected,
				saveSelectedLevel,
				addNewLevel,
				printers,
				setPrinters,
				printerSelected,
				setPrinterSelected,
				loadingPrinters,
				setLoadingPrinters,
				openPrintModal,
				setOpenPrintModal,
				isPrinting,
				setIsPrinting,
				printLevels
			}}>
			{children}
		</AssetsLevelsContext.Provider>
	)
}
