/** @format */

import React, { useContext, useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import common from '../api/common'
import usageRights from '../api/usageRights'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

export const WMSContext = React.createContext()

export const WMSProvider = ({ children }) => {
	const { token } = useContext(UserContext)
	const { i18n, t } = useTranslation()
	const history = useHistory()
	const lengthFeetRatio = 304.8
	const weightFeetRatio = 0.45359237
	const diameterRatio = 25.4
	const warehouseNotDefined = t('wms:WarehouseNotDefined')

	const [lengthUnit, setLengthUnit] = useState(() => {
		const storagedLengthUnit = JSON.parse(localStorage.getItem('@Shalyn:wms:usrLengthUnit'))
		return storagedLengthUnit ? (storagedLengthUnit.lengthUnit ? storagedLengthUnit.lengthUnit : 'mm') : 'mm'
	})
	const [weightUnit, setWeightUnit] = useState(() => {
		const storagedWeightUnit = JSON.parse(localStorage.getItem('@Shalyn:wms:usrWeightUnit'))
		return storagedWeightUnit ? (storagedWeightUnit.weightUnit ? storagedWeightUnit.weightUnit : 'kg') : 'kg'
	})
	const [warehouse, setWarehouse] = useState(() => {
		const storagedWarehouse = JSON.parse(localStorage.getItem('@Shalyn:wms:usrWarehouse'))
		return storagedWarehouse ? (storagedWarehouse.warehouse ? storagedWarehouse.warehouse : {}) : {}
	})

	const [diameterUnit, setDiameterUnit] = useState(() => {
		const storagedDiameterUnit = JSON.parse(localStorage.getItem('@Shalyn:wms:usrDiameterUnit'))
		return storagedDiameterUnit
			? storagedDiameterUnit.diameterUnitUnit
				? storagedDiameterUnit.diameterUnit
				: 'in'
			: 'in'
	})

	const [lastLevelsList, setLastLevelsList] = useState([])

	const formatNumberByCulture = useCallback(
		(value, digits) => {
			return new Intl.NumberFormat(i18n.language, { minimumFractionDigits: digits }).format(value)
		},
		[i18n.language]
	)

	const displayBundles = () => warehouse && warehouse.displayitem === 0
	const displayValids = () => warehouse && warehouse.displayitem === 1
	const displayPuuids = () => warehouse && warehouse.displayitem === 2

	const convertToCurrentDiameterUnit = useCallback(
		(value, unit) => {
			if (value === null || value === undefined || value === '') {
				return 0
			}
			if (unit === 'mm') {
				return formatNumberByCulture(value * diameterRatio, 3)
			} else if (unit === 'in') {
				return formatNumberByCulture(value, 3)
			}
			return formatNumberByCulture(value, 3)
		},
		[formatNumberByCulture]
	)

	const convertToCurrentLengthUnit = useCallback(
		(value, unit) => {
			if (value === null || value === undefined || value === '') {
				return 0
			}
			if (unit === 'mm') {
				return formatNumberByCulture(value, 3)
			} else if (unit === 'ft') {
				return formatNumberByCulture(value * (1 / 304.8), 3)
			}
			return formatNumberByCulture(value / (1 / 304.8), 3)
		},
		[formatNumberByCulture]
	)

	const convertToCurrentWeightUnit = useCallback(
		(value, unit) => {
			if (value === null || value === undefined || value === '') {
				return 0
			}
			if (unit === 'kg') {
				return formatNumberByCulture(value, 3)
			} else if (unit === 'lb') {
				return formatNumberByCulture(value / 0.45359237, 3)
			} else return formatNumberByCulture(value / 0.45359237, 3)
		},
		[formatNumberByCulture]
	)

	const numConvertToCurrentWeightUnit = useCallback((value, unit) => {
		if (value === null || value === undefined || value === '') {
			return 0
		}
		if (unit === 'kg') {
			return value
		} else if (unit === 'lb') {
			return value * 2.2046
		}
		return value / 2.2046
	}, [])

	const convertLengthToSend = useCallback(
		length => {
			if (!isNaN(length)) {
				if (lengthUnit === 'mm') return length
				else return fromFeetToMilimeter(length, lengthUnit)
			} else return length
		},
		[lengthUnit]
	)

	const convertWeightToSend = useCallback(
		weight => {
			if (weightUnit === 'kg') return weight
			else return fromPoundToKilogram(weight)
		},
		[weightUnit]
	)

	const fromFeetToMilimeter = (value, prefix) => {
		let unitPrefix = 1
		if (prefix === 'mm') unitPrefix = 1000
		else if (prefix === 'cm') unitPrefix = 100
		else unitPrefix = 1
		return (value * lengthFeetRatio * unitPrefix).toFixed(0)
	}

	const fromPoundToKilogram = value => {
		return value * weightFeetRatio
	}

	const getLastLevelsList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else
				common
					.getLastLevelsList(warehouse.id, token)
					.then(response => {
						resolve(response)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingLastLevelWarehouse')} [ ${e.status} ]: ${e.statusText || e.data}`)
						reject(e)
					})
		})

	const getLevelsList = () =>
		new Promise((resolve, reject) => {
			common
				.getLevelsList(token)
				.then(warehousesList => {
					resolve(warehousesList)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingWarehouse')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	const getLevelsData = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else
				usageRights
					.getOwnershipsList(warehouse.id, token)
					.then(response => {
						let ownerDesc = response.ownershipdescriptions
						let levelList = ownerDesc.map(res => ({
							id: res.id,
							label: res.namepath.join('..')
						}))
						const ownerships = _.uniqBy(levelList, 'id')
						resolve(ownerships)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingOwnerships')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
		})

	const getContentByOwnership = (sourceOwnership, physTrans) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else
				usageRights
					.getContentByOwnership(warehouse.id, sourceOwnership, physTrans, token)
					.then(response => {
						resolve(response)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingContent')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
		})

	const getContentByEndUser = (sourceEndUser, physTrans) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else
				usageRights
					.getContentByEndUser(warehouse.id, sourceEndUser, physTrans, token)
					.then(response => {
						resolve(response)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingContent')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
		})

	const getPrinters = () =>
		new Promise((resolve, reject) => {
			common
				.getPrinters(token)
				.then(response => {
					const printList = _.map(response, res => ({ value: res, label: res }))
					resolve(printList)
				})
				.catch(e => {
					reject(e)
				})
		})

	const bundlesOrItems = useCallback(
		bundles => {
			if (!(warehouse && bundles && bundles.length)) return []

			const propertyMappings = [
				{ property: 'material', value: 'material' },
				{ property: 'level', value: 'level' },
				{ property: 'ownershipid', value: 'ownershipid' },
				{ property: 'enduserid', value: 'enduserid' }
			]

			const processBundle = bundle => {
				return bundle.items.map(item => {
					let mergedItem = {
						...item,
						customerorder: bundle.customerorder,
						customeritem: bundle.customeritem,
						salesorder: bundle.salesorder,
						salesitem: bundle.salesitem
					}

					propertyMappings.forEach(mapping => {
						if (Object.hasOwnProperty.call(bundle, mapping.property)) {
							mergedItem = {
								...mergedItem,
								[mapping.property]: bundle[mapping.property]
							}
						}
					})

					return mergedItem
				})
			}

			return displayBundles() ? bundles : bundles.flatMap(processBundle)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse]
	)

	const processItem = item => {
		const commonProperties = {
			material: item.material,
			level: item.level,
			enduserid: item.enduserid,
			customerorder: item.customerorder,
			customeritem: item.customeritem,
			salesorder: item.salesorder,
			salesitem: item.salesitem
		}

		if (displayBundles()) {
			return {
				id: item.bundlelocalid,
				weight: 0,
				...commonProperties
			}
		}
		if (displayValids()) {
			return {
				id: item.valid,
				weight: item.weightkg,
				...commonProperties
			}
		}
		if (displayPuuids()) {
			return {
				id: item.puuid,
				weight: item.weightkg,
				...commonProperties
			}
		}
		return item
	}

	const getLevelContent = levelId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				common
					.getLevelContent(warehouse.id, levelId, token)
					.then(contentList => {
						const items = bundlesOrItems(contentList).map(item => processItem(item))
						resolve(items)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorReadingContent')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
			}
		})

	const getEnduserList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else
				usageRights
					.getEnduserList(warehouse.id, token)
					.then(response => response.length && resolve(response))
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingEndUser')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
		})

	const getMaterialsDescpritions = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else
				common
					.getMaterialsDescription(warehouse.id, token)
					.then(contentList => contentList && resolve(contentList))
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingMaterialDsc')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
		})

	const getReportList = selectedType =>
		new Promise((resolve, reject) => {
			let feature = selectedType
			feature &&
				common
					.getReports(warehouse.id, feature, token)
					.then(response => {
						resolve(response)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorGettingReports')} [ ${e.status} ]: ${e.data}`)
					})
		})

	const getReportItems = (selectedType, reportID) =>
		new Promise((resolve, reject) => {
			selectedType &&
				reportID &&
				common
					.getReportItems(warehouse.id, selectedType, reportID, token)
					.then(response => {
						let items = response.items.map(item => ({
							valid: item.valid,
							ippn: item.ippn,
							weight: item.weight,
							material: item.material,
							level: item.level
						}))
						resolve(items)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorReadingContent')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
		})

	const getOrderList = (enduserid, material, isCustomer, isSales) =>
		new Promise((resolve, reject) => {
			common
				.getOrder(warehouse.id, enduserid, material, isCustomer, isSales, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingCustomerOrderList')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	const getWeightDisplay = useCallback((warehouse, weightKg) => {
		return weightKg
	}, [])

	const getLengthDisplay = useCallback((warehouse, lengthmm) => {
		return lengthmm
	}, [])

	const getWeightKg = useCallback((warehouse, weight) => {
		let v = parseFloat(weight)
		return isNaN(v) ? 0 : v
	}, [])

	const getLengthMm = useCallback((warehouse, length) => {
		let v = parseInt(length)
		return isNaN(v) ? 0 : v
	}, [])

	useEffect(() => {
		_.isEmpty(warehouse) && history.location.pathname !== '/wms/' && history.push('/wms/')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location])

	useEffect(() => {
		localStorage.setItem('@Shalyn:wms:usrWarehouse', JSON.stringify({ warehouse }))
	}, [warehouse])

	useEffect(() => {
		localStorage.setItem('@Shalyn:wms:usrLengthUnit', JSON.stringify({ lengthUnit }))
	}, [lengthUnit])

	useEffect(() => {
		localStorage.setItem('@Shalyn:wms:usrWeightUnit', JSON.stringify({ weightUnit }))
	}, [weightUnit])

	useEffect(() => {
		localStorage.setItem('@Shalyn:wms:usrDiameterUnit', JSON.stringify({ diameterUnit }))
	}, [diameterUnit])

	useEffect(() => {
		warehouse &&
			warehouse.id &&
			getLastLevelsList()
				.then(list => list.length && setLastLevelsList(list))
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	return (
		<WMSContext.Provider
			value={{
				lengthUnit,
				weightUnit,
				convertToCurrentLengthUnit,
				convertToCurrentWeightUnit,
				setLengthUnit,
				setWeightUnit,
				numConvertToCurrentWeightUnit,
				setWarehouse,
				formatNumberByCulture,
				getLevelsList,
				warehouse,
				bundlesOrItems,
				displayBundles,
				displayValids,
				displayPuuids,
				getLastLevelsList,
				getLevelContent,
				getMaterialsDescpritions,
				lastLevelsList,
				getLevelsData,
				getContentByOwnership,
				getContentByEndUser,
				getEnduserList,
				getWeightDisplay,
				getLengthDisplay,
				getWeightKg,
				getLengthMm,
				convertLengthToSend,
				convertWeightToSend,
				getReportList,
				getReportItems,
				getOrderList,
				getPrinters,
				convertToCurrentDiameterUnit,
				diameterUnit,
				setDiameterUnit
			}}>
			{children}
		</WMSContext.Provider>
	)
}
