/** @format */
/* eslint-disable react/prop-types */

import { PrepareInspectionContext } from 'apps/wms/stores/PrepareInspectionStore'
import { INSPECTION_STAGES, STATUS, translateKeySummary } from 'apps/wms/utils/enum'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import { Input } from 'components/form'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import theme from 'utils/theme'
import Filters from './Filters'
import DatePicker from 'apps/wms/components/forms/DatePicker/DatePicker'
import _ from 'lodash'

const DateWrapper = styled.div`
	width: 30%;
	> div {
		width: 100%;
	}
`

const UpdateInspModal = ({ isOpen, onOutsideClick, selectedReport, selectedInspection, stage }) => {
	const { t } = useTranslation()
	const {
		qntAvailable,
		setQntAvailable,
		file,
		setFile,
		updateInspection,
		uploadFile,
		inspections,
		lastLevelsList,
		materials,
		setEnduser,
		setOwnership,
		ownershipList
	} = useContext(PrepareInspectionContext)
	const [updatedData, setUpdatedData] = useState({
		expected_date: '',
		contents: []
	})
	const [fileUpload, setFileUpload] = useState(false)
	const [savingInsp, setSavingInsp] = useState(false)
	const [isRigReturn, setIsRigReturn] = useState(false)
	const [rackList, setRackList] = useState([])
	const [materialList, setMaterialList] = useState([])
	const fileUploader = useRef(null)
	const [selectedMaterial, setSelectedMaterial] = useState(null)
	const [selectedRack, setSelectedRack] = useState(null)
	const [numberOfParts, setNumberOfParts] = useState(0)
	const [summaryKeys, setSummaryKeys] = useState(null)

	const quantityStr = t('wms:Quantity')

	const newContent = useCallback(
		newDataContent => {
			selectedInspection && selectedInspection.is_filter_on_content
				? setUpdatedData({ ...updatedData, contents: [...updatedData.contents, newDataContent] })
				: setUpdatedData({
						...updatedData,
						contents: [
							...updatedData.contents,
							{
								quantity: newDataContent.quantity,
								status: newDataContent.status
							}
						],
						filters: newDataContent.filters
				  })
		},
		[updatedData, selectedInspection]
	)

	const fillRackList = useCallback(list => {
		setRackList(list)
	}, [])

	const fillMaterialList = useCallback(list => {
		setMaterialList(list)
	}, [])

	const selectMaterial = useCallback(mat => {
		setSelectedMaterial(mat)
	}, [])

	const selectLevel = useCallback(rack => {
		setSelectedRack(rack)
	}, [])

	const changeParamName = (value, name) => {
		updatedData.summary[name] = value
		setUpdatedData({ ...updatedData })
	}

	const levelFullName = id => {
		return id !== null && lastLevelsList.length && lastLevelsList.find(level => level.id === id)
			? lastLevelsList.find(level => level.id === id).fullname
			: id !== null && rackList.length && rackList.find(level => level.id === id)
			? rackList.find(level => level.id === id).fullname
			: ''
	}

	const removeContent = index => {
		let contentMaterial =
			updatedData.contents[index].filters && updatedData.contents[index].filters.material
				? updatedData.contents[index].filters.material
				: updatedData.contents[index].mid
		let contentLevel =
			updatedData.contents[index].filters && updatedData.contents[index].filters.level
				? updatedData.contents[index].filters.level
				: updatedData.contents[index].levelid
		if (index !== null && index < updatedData.contents.length) {
			selectedMaterial === contentMaterial &&
				selectedRack === contentLevel &&
				setQntAvailable(qntAvailable + updatedData.contents[index].quantity)
			let remContent = updatedData.contents.splice(index, 1)
			updatedData.deletedcontents.push(...remContent)
			setUpdatedData({ ...updatedData, contents: [...updatedData.contents] })
		}
		setNumberOfParts(
			updatedData && updatedData.contents && updatedData.contents.length ? updatedData.contents.length : 0
		)
	}

	const validateData = () => {
		const now = new Date()
		const commonConditions =
			updatedData.expected_date >= now && updatedData && updatedData.contents && updatedData.contents.length > 0
		const isReturn = selectedInspection && selectedInspection.stage === INSPECTION_STAGES.code.RETURN
		const isArrival = selectedInspection && selectedInspection.stage === INSPECTION_STAGES.code.ARRIVAL
		const summaryNotEmpty =
			updatedData.summary && Object.values(updatedData.summary).every(x => x != null || x !== '' || x.length <= 0)
		const filteredValuesNotEmpty =
			updatedData &&
			updatedData.summary &&
			Object.values(
				Object.keys(updatedData.summary).filter(k => k !== 'id' && k !== 'created_at' && k !== 'modified_at')
			).every(x => x !== '' && x.length > 0 && x.length <= 30)
		if (isReturn)
			return (
				commonConditions &&
				Object.values(updatedData).every(x => x != null || x !== '' || x.length > 0) &&
				updatedData.number_of_parts > 0
			)

		if (isArrival) return filteredValuesNotEmpty

		return commonConditions && summaryNotEmpty
	}

	const updateInsp = async () => {
		try {
			if (file) {
				setFileUpload(true)
				await uploadFile(file, selectedInspection.stage, file.name.split('.').pop())
					.then(fileName => {
						setUpdatedData({ ...updatedData, file: fileName })
						updatedData.file = fileName
					})
					.finally(() => setFileUpload(false))
			}
			setSavingInsp(true)
			await updateInspection(updatedData, selectedInspection.stage, selectedReport.id, selectedReport.type)
				.then(response => response)
				.finally(() => setSavingInsp(false))
		} catch (e) {
			console.error(e)
		}
	}

	const matFormatted = id => {
		return id !== null && materials.length && materials.find(mat => mat.mvid === id)
			? materials.find(mat => mat.mvid === id).formatted
			: id !== null && materialList.length && materialList.find(mat => mat.id === id)
			? materialList.find(mat => mat.id === id).formatted
			: ''
	}

	const getTitle = useCallback(() => {
		return stage === INSPECTION_STAGES.code.ARRIVAL
			? t('wms:UpdateEntranceInspection')
			: stage === INSPECTION_STAGES.code.RETURN
			? t('wms:UpdateReturnInspection')
			: stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION
			? t('wms:UpdateMR')
			: t('wms:UpdateInspection')
	}, [stage, t])

	const mandatoryUploadFile = () => {
		let hasField = true
		hasField =
			hasField &&
			selectedInspection &&
			_.some(selectedInspection.config_summary, { name: 'CustomerRequestReference', enabled: true })
		return hasField
	}

	const formatOwnership = value => {
		let ownershipSelected =
			ownershipList && ownershipList.length && _.find(ownershipList, owner => owner.id === value)

		return ownershipSelected ? ownershipSelected.label : '****'
	}

	useEffect(() => {
		if (isOpen && selectedReport && selectedInspection) {
			const filteredSummary = Object.filter(selectedReport.summary, summary => summary != null)
			const keys = Object.keys(filteredSummary).filter(
				key => key !== 'id' && key !== 'created_at' && key !== 'modified_at'
			)
			setSummaryKeys(keys)
			const isRigReturn =
				selectedInspection.stage === INSPECTION_STAGES.code.RETURN && !selectedInspection.is_modified_product
			const hasFilterOnContent = selectedInspection.is_filter_on_content
			const defaultData = {
				expected_date: moment(selectedReport.expected_date) || null,
				closure_date: moment(selectedReport.closure_date) || null,
				contents: selectedReport.contents,
				summary: filteredSummary,
				file: selectedReport.file,
				deletedcontents: []
			}
			if (isRigReturn) {
				setIsRigReturn(isRigReturn)
				setUpdatedData({
					...defaultData,
					filters: selectedReport.filters,
					number_of_parts: selectedReport.contents.length
				})
				setNumberOfParts((selectedReport.contents && selectedReport.contents.length) || 0)
			} else if (hasFilterOnContent) {
				setUpdatedData({ ...defaultData })
				const eu = selectedReport.contents.find(report => report.filters.enduser)
				setEnduser(eu.filters.enduser || null)
				const owner = selectedReport.contents.find(report => report.filters.ownership)
				setOwnership((owner && owner.filters.ownership) || null)
			} else
				setUpdatedData({
					...defaultData,
					filters: selectedReport.filters
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, selectedReport, selectedInspection])

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='80%' height='90%' position='relative'>
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{getTitle()}
					<Button fontSize='tiny' margin='0' backgroundColor='white' disabled={false} onClick={onOutsideClick}>
						<Icon name='cross-simple' color='black' height='16px' width='16px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row' height='80%'>
					<FlexView maxWidth='35%' minWidth='35%' height='100%' maxHeight='100%' margin='0 16px 0 0'>
						{selectedInspection && (
							<Card margin='0 0 4px 0' width='calc(100% - 48px)' height='100%' flex='1'>
								<Filters
									inspectionType={selectedInspection}
									newContent={newContent}
									rackList={fillRackList}
									materialList={fillMaterialList}
									inspections={inspections}
									selectMaterial={selectMaterial}
									selectLevel={selectLevel}
									newData={updatedData}
								/>
							</Card>
						)}
					</FlexView>
					<FlexView width='calc(65% - 16px)' minHeight='100%' maxHeight='100%'>
						<Card
							margin='0 0 4px 0'
							width='calc(100% - 48px)'
							height='80%'
							maxHeight='90%'
							minHeight='inherit'
							flex='1'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:InspectionInfo')}
							</FlexView>
							{updatedData && updatedData.expected_date !== '' && (
								<FlexView
									flexDirection='row'
									width='100%'
									maxWidth='100%'
									flexWrap='wrap'
									margin='0 0 8px 0'
									justifyContent='flex-start'>
									{summaryKeys &&
										summaryKeys.map((sm, index) => (
											<FlexView key={index} width='30%' margin='0 16px 0 0'>
												<Input
													label={t(`${translateKeySummary(sm)}`)}
													placeholder={t(`${translateKeySummary(sm)}`)}
													margin='8px 0 0 10px'
													value={updatedData.summary[sm]}
													onChange={e => changeParamName(e.target.value, sm)}
													fontSize='small'
													width='90%'
												/>
											</FlexView>
										))}
									<DateWrapper>
										<DatePicker
											label={t('wms:DispatchExpectedDate')}
											placeholder={`${t('wms:SelectDate')}`}
											margin='8px 0 0 10px'
											timePicker={true}
											value={updatedData.expected_date}
											onChange={e => setUpdatedData({ ...updatedData, expected_date: e })}
											fontSize='small'
											minWidth='30%'
											noPast={true}
										/>
									</DateWrapper>
									{isRigReturn ? (
										<FlexView width='30%' margin='0'>
											<Input
												label={`${t('wms:NumberParts')}`}
												margin='8px 0 0 10px'
												min='1'
												type='number'
												value={numberOfParts}
												onChange={e => {
													setNumberOfParts(e.target.value)
													setUpdatedData({ ...updatedData, number_of_parts: e.target.value })
												}}
												fontSize='small'
												width='100%'
												data-cy='input-partsqty'
											/>
										</FlexView>
									) : null}
								</FlexView>
							)}
							<FlexView width='100%' height='90%' maxHeight='90%' style={{ overflowY: 'scroll' }}>
								{updatedData &&
								selectedInspection &&
								selectedInspection.is_filter_on_content &&
								updatedData &&
								updatedData.contents.length > 0
									? updatedData.contents.map((content, index) => (
											<FlexView
												key={index}
												width='100%'
												margin='0'
												flexDirection='row'
												padding='8px 0'
												style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
												<FlexView width='100%' flexDirection='column'>
													<FlexView key={index} flex='1' fontSize='small' margin='0' padding='0 0 0 4px'>
														{matFormatted(content && content.filters && content.filters.material)}
													</FlexView>
													{selectedInspection &&
														selectedInspection.stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION && (
															<FlexView
																flex='1'
																width='100%'
																fontWeight='bold'
																fontSize='small'
																padding='0 0 0 4px'>
																{`${
																	content.filters &&
																	content.filters.source_ownership &&
																	formatOwnership(content.filters.source_ownership)
																} | ${levelFullName(
																	content.filters && content.filters.level
																		? content.filters.level
																		: content.levelid
																)} | ${quantityStr}: ${content.quantity}`}
															</FlexView>
														)}
													{selectedInspection &&
														selectedInspection.stage !== INSPECTION_STAGES.code.MATERIAL_REQUISITION && (
															<FlexView
																flex='1'
																width='100%'
																fontWeight='bold'
																fontSize='small'
																padding='0 0 0 4px'>
																{`${quantityStr}: ${content.quantity}`}
															</FlexView>
														)}
												</FlexView>
												<FlexView
													width='35%'
													height='100%'
													margin='0'
													padding='0'
													flexDirection='row'
													justifyContent='center'>
													<FlexView
														fontSize='tiny'
														color={STATUS.color[content.execution_status]}
														style={{
															borderLeft: `2px solid ${
																theme.colors[STATUS.color[content.execution_status]]
															}`,
															lineHeight: '24px'
														}}
														margin='auto 0 auto auto'
														padding='8px 16px'>
														{t(`wms:${STATUS.literal[content.execution_status]}`)}
													</FlexView>
												</FlexView>
												{content.execution_status === STATUS.code.NOTSTARTED && !isRigReturn && (
													<Button
														fontSize='small'
														margin='0'
														padding='8px 16px'
														color='white'
														backgroundColor='error'
														disabled={false}
														onClick={() => removeContent(index)}>
														<Icon
															name='trash'
															color='white'
															height='16px'
															width='16px'
															margin='0'
															tootilp={t('wms:RemovePart')}
														/>
													</Button>
												)}
											</FlexView>
									  ))
									: selectedInspection &&
									  !selectedInspection.is_filter_on_content &&
									  updatedData.contents &&
									  updatedData.contents.length > 0
									? updatedData.contents.map((content, index) => (
											<FlexView
												key={index}
												width='100%'
												margin='0'
												flexDirection='row'
												padding='8px 0'
												style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
												{selectedInspection.is_modified_product && (
													<FlexView flex='1' fontSize='small' margin='auto 0'>
														{matFormatted(updatedData.filters && updatedData.filters.material)}
														<br />
														<strong>
															{`${levelFullName(
																updatedData.filters && updatedData.filters.level
															)} | ${quantityStr}: ${content.quantity}`}
														</strong>
													</FlexView>
												)}
												{selectedInspection.stage === INSPECTION_STAGES.code.RETURN &&
													!selectedInspection.is_modified_product && (
														<FlexView flex='1' fontSize='small' margin='auto 0'>
															<strong>{`${t('wms:Part')} ${index + 1}`}</strong>
														</FlexView>
													)}
												{selectedInspection &&
													selectedInspection.stage === INSPECTION_STAGES.code.ARRIVAL && (
														<FlexView
															flex='1'
															width='100%'
															fontWeight='bold'
															fontSize='small'
															padding='0 0 0 4px'>
															{`${quantityStr}: ${content.quantity}`}
														</FlexView>
													)}
												<FlexView
													width='35%'
													height='100%'
													margin='0'
													padding='0'
													flexDirection='row'
													justifyContent='center'>
													<FlexView
														fontSize='tiny'
														color={STATUS.color[content.execution_status]}
														style={{
															borderLeft: `2px solid ${
																theme.colors[STATUS.color[content.execution_status]]
															}`,
															lineHeight: '24px'
														}}
														margin='auto 0 auto auto'
														padding='8px 16px'>
														{t(`wms:${STATUS.literal[content.execution_status]}`)}
													</FlexView>
												</FlexView>
												{content.execution_status === STATUS.code.NOTSTARTED && !isRigReturn && (
													<Button
														fontSize='small'
														margin='0'
														padding='8px 16px'
														color='white'
														backgroundColor='error'
														disabled={false}
														onClick={() => removeContent(index)}>
														<Icon
															name='trash'
															color='white'
															height='16px'
															width='16px'
															margin='0'
															tootilp={t('wms:RemovePart')}
														/>
													</Button>
												)}
											</FlexView>
									  ))
									: null}
							</FlexView>
						</Card>
					</FlexView>
				</FlexView>
				<FlexView width='100%' flexDirection='row' margin='16px 0 0 0' alignItems='center'>
					{mandatoryUploadFile() ? (
						<>
							<Button
								fontSize='medium'
								margin='0 8px 0 0'
								style={{ marginLeft: 'calc(30% + 16px)' }}
								color='white'
								backgroundColor='secondary'
								onClick={() => fileUploader.current.click()}>
								{t('wms:UploadFile')}
								<Icon name='upload' color='white' height='16px' width='16px' margin='0 0 0 8px' />
							</Button>
							<FlexView
								style={{ texOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
								flex='1'
								margin='0 8px 0 0'
								flexDirection='row'>
								{file && file.name}
							</FlexView>
							<input
								style={{ display: 'none' }}
								id='fileInput'
								type='file'
								accept='.pdf, .png, .jpg, .ods, .doc, .docx, .odt, .ppt, .pptx, .xls, .xlsx'
								ref={fileUploader}
								onChange={e => setFile(e.target.files[0])}
							/>{' '}
						</>
					) : null}
					<Button
						fontSize='medium'
						margin='0 0 0 auto'
						color='white'
						backgroundColor='secondary'
						disabled={!validateData()}
						onClick={() => updateInsp()}>
						{t('wms:Update')}
						<Icon name={'refresh'} color='white' height='16px' width='16px' margin='0 0 0 8px' />
						<LoadingOverlay visible={savingInsp || fileUpload} borderRadius='card' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default UpdateInspModal
