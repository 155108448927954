/** @format */

import { AssetsInspSettingsContext } from 'apps/wms/stores/AssetsInspSettingsStore'
import { Button, FlexView } from 'components/common'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const InspTabs = () => {
	const { t } = useTranslation()
	const { chosenDefects, setChosenDefects, chosenItems, setChosenItems, chosenInspections, setChoseInspections } =
		useContext(AssetsInspSettingsContext)

	const selectItemsTab = value => {
		setChosenItems(value)
		setChosenDefects(false)
		setChoseInspections(false)
	}

	const selectDefectsTab = value => {
		setChosenDefects(value)
		setChosenItems(false)
		setChoseInspections(false)
	}

	const selectInspectionTab = value => {
		setChoseInspections(value)
		setChosenDefects(false)
		setChosenItems(false)
	}

	return (
		<FlexView
			flexDirection='row'
			alignItems='center'
			style={{ transform: 'rotate(-90deg)', top: '130px', right: '160px', position: 'relative' }}>
			<Button
				backgroundColor={chosenInspections ? 'secondary' : 'lightGray'}
				color={chosenInspections ? 'white' : 'default'}
				onClick={v => selectInspectionTab(v.isTrusted)}
				margin='8px'
				fontSize='small'>
				{t('wms:Inspections')}
			</Button>
			<Button
				backgroundColor={chosenDefects ? 'secondary' : 'lightGray'}
				color={chosenDefects ? 'white' : 'default'}
				onClick={v => selectDefectsTab(v.isTrusted)}
				margin='8px'
				fontSize='small'>
				{t('wms:Defects')}
			</Button>
			<Button
				backgroundColor={chosenItems ? 'secondary' : 'lightGray'}
				color={chosenItems ? 'white' : 'default'}
				onClick={v => selectItemsTab(v.isTrusted)}
				margin='8px'
				fontSize='small'>
				{t('wms:Items')}
			</Button>
		</FlexView>
	)
}

export default InspTabs
