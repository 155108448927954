/** @format */

import axios from "axios"

const instance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: {
    "Content-Type": "application/json",
    "VLR-Authorization": btoa(JSON.stringify({ id: process.env.REACT_APP_ID })),
  },
})

export default {
  getUserDetails: (headerToken) =>
    new Promise((resolve, reject) => {
      instance
        .post(
          "/user/detail",
          {},
          {
            headers: headerToken,
          }
        )
        .then((response) => {
          if (response.status === 200 || response.status === 204)
            resolve(response)
          else reject(new Error("CouldNotConnect"))
        })
        .catch((err) => reject(err.response || err))
    }),
  getUserVerify: (headerToken) =>
    new Promise((resolve, reject) => {
      instance
        .post(
          "/user/verify",
          {},
          {
            headers: headerToken,
          }
        )
        .then((response) => {
          if (response.status === 200 || response.status === 204)
            resolve(response)
          else reject(new Error("CouldNotConnect"))
        })
        .catch((err) => reject(err.response || err))
    }),
}
