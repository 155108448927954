import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { withWidth, withMargin } from 'utils/styled-decorators'

const Wrapper = styled.label`
  ${({ theme, fontSize }) => {
  const fs = theme.fontSizes[fontSize] || theme.fontSizes.medium

  return css`
    ${withWidth()}
    ${withMargin()}
    display: block;
    text-align: left;
    user-select: none;
    cursor: pointer;

    input {
      display: none;

      &+div {
        height: calc(${fs} + 4px);
        font-family: 'Noto Sans';
        font-size: ${fs};
        font-weight: normal;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        span {
          padding-left: calc(${fs} + 4px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        span:not(:empty) {
          padding-left: calc(${fs} + 12px);
        }

        &:before,
        &:after {
          content: '';
          width: calc(${fs} + 4px);
          height: calc(${fs} + 4px);
          display: block;
          border-radius: 20%;
          left: 0;
          top: 0;
          bottom: 0;
          position: absolute;
        }

        &:before {
          background: ${theme.colors.lightGray};
          transition: background .2s ease, transform .5s cubic-bezier(.175, .885, .32, 1.4);
        }

        &:after {
          background: #fff;
          transform: scale(.8);
          transition: transform .6s cubic-bezier(.175, .885, .32, 1.1);
        }

        &:hover {
          &:before {
            transform: scale(1.14);
          }

          &:after {
            transform: scale(.8);
          }
        }
      }

      &:checked+div {
        &:before {
          transform: scale(1.04);
          background: ${theme.colors.primary};
        }

        &:after {
          transform: scale(0);
          transition: transform .3s ease;
        }
      }
    }
  `}
  }
`


const Checkbox = ({ checked, onChange, label, disabled, ...rest }) => {
  const handleHTMLEventChange = (event) => {
    onChange && onChange(event.target.checked)
  }

  return (
    <Wrapper {...rest}>
      <input type="checkbox" disabled={disabled} checked={checked} onChange={handleHTMLEventChange} />
      <div>
        <span>{label}</span>
      </div>
    </Wrapper>
  )
}


Checkbox.propTypes = {
  /**
   * Boolean that defines if the checkbox is checked
   */
  checked: PropTypes.bool.isRequired,
  /**
   * Function that is called when the value is changed, being passed as parameter the current value.
   */
  onChange: PropTypes.func,
  /**
   * Override CSS width property. Must be a valid CSS width value as a string
   */
  width: PropTypes.string,
  /**
   * Override CSS margin property. Must be a valid CSS margin value as a string
   */
  margin: PropTypes.string,
  /**
   * A font size key defined in the theme
   */
  fontSize: PropTypes.string,
  /**
   * Checkbox label
   */
  label: PropTypes.string,
  /**
   * Checkbox disabled
   */
  disabled: PropTypes.bool
}

Checkbox.defaultProps = {
  onChange: null,
  width: 'fit-content',
  margin: '8px 0px',
  label: '',
  disabled: false
}

export default Checkbox