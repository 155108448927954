/** @format */

import { ContentContext } from 'apps/wms/stores/ContentStore'
import { Button, FlexView, Modal } from 'components/common'
import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Select, Toggle } from 'components/form'
import { formatStockKeys } from 'apps/wms/utils/enum'

// eslint-disable-next-line react/prop-types
const ContentFilterModal = ({ isOpen, onOutsideClick, options }) => {
	const { t } = useTranslation()
	const {
		setFilters,
		setShowTable,
		nominalDataFields,
		actualDataFields,
		actualDataSelected,
		setActualDataSelected,
		filterByNominal,
		setFilterByNominal,
		nominalDataSelected,
		setNominalDataSelected
	} = useContext(ContentContext)

	const onInputChange = useCallback(
		(accessor, value) =>
			setNominalDataSelected(currentState => ({
				...currentState,
				[accessor]: value
			})),
		[setNominalDataSelected]
	)

	const onActualDataChange = (value, key) =>
		setActualDataSelected(currentState => ({
			...currentState,
			[key]: value
		}))

	const renderNominalData = useMemo(() => {
		return _.map(options, ({ accessor, label }) => {
			const availableOptions = _.chain(nominalDataFields)
				.map(item => {
					const value = _.get(item, accessor)
					return {
						value,
						label: value
					}
				})
				.orderBy('label', 'asc')
				.filter(({ label }) => typeof label === 'string' || typeof label === 'number' || typeof label === 'boolean')
				.uniqBy('value')
				.map(({ value, label }) => ({
					value,
					label: `${label}`
				}))
				.value()
			return (
				availableOptions.length > 0 && (
					<Select
						margin='8px 8px 0 0'
						width='95%'
						key={accessor}
						label={
							label &&
							(label === 'erpreference' || label === 'modifiedproductdesc' || label === 'ismodifiedproduct')
								? t(`wms:${label}`)
								: label
						}
						value={nominalDataSelected[accessor]}
						options={availableOptions}
						onChange={value => onInputChange(accessor, value)}
						searchable
						data-cy='select-options'
					/>
				)
			)
		})
	}, [options, nominalDataSelected, nominalDataFields, onInputChange, t])

	const onFilterClick = () => {
		filterByNominal ? setFilters(nominalDataSelected) : setFilters(actualDataSelected)
		setShowTable(true)
		onOutsideClick()
	}

	const onClearFilters = () => {
		setActualDataSelected({})
		setFilters({})
		setNominalDataSelected({})
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<FlexView backgroundColor='white' borderRadius='8px' padding='16px' maxWidth='80%' width='40%' height='90%'>
				<FlexView width='100%' flexDirection='row'>
					<FlexView fontSize='subtitle' fontWeight='bold' width='40%'>
						{t('wms:FilterMaterial')}
					</FlexView>
					<FlexView width='50%' margin='0 8px 0 auto' alignItems='center' alignSelf='center'>
						<Toggle
							margin='0'
							textOff={`${t('wms:FilterBy')} ${t('wms:ActualData')}`}
							textOn={`${t('wms:FilterBy')} ${t('wms:NominalData')}`}
							alternate
							state={filterByNominal}
							onChange={value => setFilterByNominal(value)}
						/>
					</FlexView>
				</FlexView>
				<FlexView height='90%' margin='8px 0px' width='100%' style={{ overflowY: 'auto' }}>
					{filterByNominal
						? renderNominalData
						: actualDataFields &&
						  Object.keys(actualDataFields).length > 0 &&
						  Object.keys(actualDataFields).map((field, index) => (
								<Select
									margin='8px 8px 0 0'
									width='95%'
									key={index}
									label={t(`wms:${field}`)}
									value={actualDataSelected[field]}
									options={
										field === 'pipestatuses'
											? actualDataFields[field].map(actual => ({
													value: actual,
													label: t(formatStockKeys(actual))
											  }))
											: actualDataFields[field].map(actual => ({
													value: actual,
													label: actual
											  }))
									}
									onChange={value => onActualDataChange(value, field)}
									searchable
									data-cy='select-options'
								/>
						  ))}
				</FlexView>
				<FlexView flexDirection='row' margin='auto 0 0 auto'>
					<Button margin='8px' outline onClick={() => onClearFilters()} data-cy='button-clear-filter'>
						{t('wms:Clear')}
					</Button>
					<Button
						margin='8px 0px 8px'
						color='white'
						backgroundColor='secondary'
						height='39px'
						onClick={() => onFilterClick()}
						data-cy='button-apply-filters'>
						{t('wms:Apply')}
					</Button>
				</FlexView>
			</FlexView>
		</Modal>
	)
}

export default ContentFilterModal
