/** @format */

import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { UserContext } from 'stores/UserStore'
import common from '../api/common'
import inventory from '../api/inventory'
import { WMSContext } from './WmsStore'
import dispatch from '../api/dispatch'

export const InventoryContext = React.createContext()

export const InventoryProvider = ({ children }) => {
	const { token, user } = useContext(UserContext)
	const { t } = useTranslation()
	const { warehouse, bundlesOrItems, displayBundles, getMaterialsDescpritions } = useContext(WMSContext)
	const [reports, setReports] = useState(null)
	const [loadingReports, setLoadingReports] = useState(false)
	const [openDetailsModal, setOpenDetailsModal] = useState(false)
	const [loadingDetails, setLoadingDetails] = useState(false)
	const [openModalGhosts, setOpenModalGhosts] = useState(false)
	const [loadingGhosts, setLoadingGhosts] = useState(false)
	const [loadingPrinters, setLoadingPrinters] = useState(false)
	const [openPrint, setOpenPrint] = useState(false)
	const [confirmModal, setConfirmModal] = useState(false)
	const [inventoryId, setInventoryId] = useState(null)
	const [inventoryResult, setInventoryResult] = useState(null)
	const [items, setItems] = useState(null)
	const [printers, setPrinters] = useState(null)
	const [printerSelected, setPrinterSelected] = useState(null)
	const [itemSelected, setItemSelected] = useState(null)
	const [materialList, setMaterialList] = useState([])
	const [ownershipList, setOwnershipList] = useState([])
	const [contentList, setContentList] = useState([])
	const [vehicleList, setVehicleList] = useState([])

	const getInventories = () =>
		new Promise((resolve, reject) => {
			inventory
				.getInventories(warehouse.id, token)
				.then(response => {
					let data = _.map(response, res => {
						return {
							rackId: res.level.id,
							fullname: res.level.fullname,
							id: res.result.id,
							lastinventorydate: res.result.lastinventorydate,
							nextinventorydate: res.nextinventorydate,
							responsible: res.result.responsible,
							deviation: res.result.errorpercentage
						}
					})
					setReports(data)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject()
					toast.error(`${t('wms:ErrorGettingInventories')} [${e.status}]: ${e.data}`)
				})
		})

	const loadInventory = () =>
		new Promise((resolve, reject) => {
			inventory
				.loadInventory(warehouse.id, inventoryId, token)
				.then(response => {
					setInventoryResult(response.data)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject()
					toast.error(`${t('wms:ErrorReadingInvDetails')} [${e.status}]: ${e.data}`)
				})
		})

	const loadGhosts = () =>
		new Promise((resolve, reject) => {
			let bundles = null
			inventory
				.loadGhosts(warehouse.id, token)
				.then(response => {
					bundles = bundlesOrItems(response.bundles)
					let data = _.map(bundles, items => {
						return {
							levelid: items.level.id,
							fullname: items.level.fullname,
							valid: items.valid,
							puuid: items.puuid,
							material: items.material.id,
							formatted: items.material.formatted,
							status: items.laststatus,
							checked: false,
							checked_all: false
						}
					})
					setItems(data)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorReadingGhosts')} [${e.status}]: ${e.data}`)
				})
		})

	const getPrinters = () =>
		new Promise((resolve, reject) => {
			let p = []
			common
				.getPrinters(token)
				.then(response => {
					_.forEach(response, (res, i) => {
						p.push({ value: i, label: res })
					})
					setPrinters(p)
					resolve()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorReadingPrinters')} [${e.status}]: ${e.data}`)
				})
		})

	const putPrinters = () =>
		new Promise((resolve, reject) => {
			let p = _.find(printers, printer => {
				return printer.value === printerSelected
			})
			if (displayBundles()) {
				let payload = {
					printer: p.label,
					localids: [itemSelected.localid]
				}
				common
					.setPrintersByBundles(payload, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:Printing OK'))
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorPrinting')} [ ${e.status} ]: ${e.data}`)
					})
			} else {
				let payload = {
					printer: p.label,
					items: [itemSelected]
				}
				common
					.setPrintersByItems(payload, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:Printing OK'))
						setPrinterSelected(null)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorPrinting')} [ ${e.status} ]: ${e.data}`)
						setPrinterSelected(null)
					})
			}
		})

	const exportInventory = id =>
		new Promise((resolve, reject) => {
			inventory
				.exportInventory(warehouse.id, id, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorExtractingInventory')} [ ${e.status} ]: ${e.data}`)
					reject(e)
					console.error(e)
				})
		})

	const exportItemsNotFound = () =>
		new Promise((resolve, reject) => {
			inventory
				.exportGhost(warehouse.id, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorExtractingGhost')} [ ${e.status} ]: ${e.data}`)
					console.error(e)
				})
		})

	const getVehicles = () =>
		new Promise((resolve, reject) => {
			dispatch
				.getVehiclesList(warehouse.id, token)
				.then(response => {
					setVehicleList(response)
					resolve()
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorGettingVehicles')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const newDispatch = data =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			}
			dispatch
				.newDispatch(warehouse.id, data, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorCreatingDispatchingLists')} [${e.status}]: ${e.data}`)
					reject(e)
				})
		})

	const executeDispatch = res =>
		new Promise((resolve, reject) => {
			let payload = {
				responsible: `${user.firstName} ${user.lastName}`,
				dispatchListId: res.dispatchedlistid,
				valids: items.filter(item => item.checked === true).map(i => i.valid)
			}
			dispatch
				.executeDispatch(warehouse.id, payload, token)
				.then(() => {
					toast.success(t('wms:DispatchSuccessful'))
					resolve(true)
					setConfirmModal(false)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorDispatching')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => {
					setConfirmModal(false)
				})
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingReports(true)
				await getInventories()
			} catch (e) {
				setLoadingReports(false)
				console.error(e)
			} finally {
				setLoadingReports(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (openDetailsModal) {
			const hydrate = async () => {
				try {
					setLoadingDetails(true)
					await loadInventory()
				} catch (e) {
					setLoadingDetails(false)
					console.error(e)
				} finally {
					setLoadingDetails(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openDetailsModal])

	useEffect(() => {
		if (openModalGhosts) {
			const hydrate = async () => {
				try {
					setLoadingGhosts(true)
					await loadGhosts()
				} catch (e) {
					setLoadingGhosts(false)
					console.error(e)
				} finally {
					setLoadingGhosts(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openModalGhosts])

	useEffect(() => {
		if (openPrint) {
			const hydrate = async () => {
				try {
					setLoadingPrinters(true)
					await getPrinters()
				} catch (e) {
					setLoadingPrinters(false)
					console.error(e)
				} finally {
					setLoadingPrinters(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openPrint])

	useEffect(() => {
		getMaterialsDescpritions()
			.then(list => {
				setMaterialList(list)
			})
			.catch(e => console.error(e))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getVehicles()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	return (
		<InventoryContext.Provider
			value={{
				reports,
				setReports,
				loadingReports,
				setLoadingReports,
				openDetailsModal,
				setOpenDetailsModal,
				inventoryId,
				setInventoryId,
				loadingDetails,
				setLoadingDetails,
				inventoryResult,
				setInventoryResult,
				openModalGhosts,
				setOpenModalGhosts,
				items,
				setItems,
				loadingGhosts,
				setLoadingGhosts,
				openPrint,
				setOpenPrint,
				loadingPrinters,
				setLoadingPrinters,
				printers,
				setPrinters,
				printerSelected,
				setPrinterSelected,
				itemSelected,
				setItemSelected,
				putPrinters,
				exportInventory,
				exportItemsNotFound,
				confirmModal,
				setConfirmModal,
				executeDispatch,
				ownershipList,
				setOwnershipList,
				contentList,
				setContentList,
				materialList,
				setMaterialList,
				vehicleList,
				setVehicleList,
				newDispatch
			}}>
			{children}
		</InventoryContext.Provider>
	)
}
