/** @format */

import { AssetsInspSettingsContext } from 'apps/wms/stores/AssetsInspSettingsStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Input, Select } from 'components/form'
import React, { useEffect, useState, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Checkbox from 'apps/wms/components/forms/Checkbox'

const DefectsByItems = () => {
	const { t } = useTranslation()
	const {
		defects,
		items,
		selectedItem,
		setSelectedItem,
		loadingItems,
		saveInspItems,
		setSelectedDefects,
		selectedDefects
	} = useContext(AssetsInspSettingsContext)
	const [defect, setDefect] = useState('')
	const [filteredDef, setFilteredDef] = useState(null)
	const [isCheckAll, setIsCheckAll] = useState(false)
	const [savingConfig, setSavingConfig] = useState(false)

	const listDeadSpace = 350
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		defects && setFilteredDef(defects)
	}, [defects])

	const selectItem = value => {
		setIsCheckAll(false)
		setSelectedItem(value)
		let arrayDefects = []
		let item = _.find(items, it => it.id === value)
		_.forEach(item.defects, df => {
			let defect = _.find(defects, def => def.id === df)
			defect && arrayDefects.push(defect)
		})
		setSelectedDefects(_.map(arrayDefects, arr => arr.id))
		_.forEach(filteredDef, def => {
			selectDefects(def, item.defects)
		})
	}

	const selectAllDefects = v => {
		setIsCheckAll(v)
		if (v) {
			setSelectedDefects(_.map(filteredDef, def => def.id))
		} else {
			setSelectedDefects([])
		}
	}

	const selectDefects = (value, selected) => {
		return _.indexOf(selected, value.id) > -1
	}

	const handleSelect = (value, selected) => {
		let i = selected.indexOf(value.id)
		i > -1 ? selected.splice(i, 1) : selected.push(value.id)
		setSelectedDefects([...selectedDefects])
	}

	const saveConfig = () => {
		setSavingConfig(true)
		saveInspItems()
			.then(() => {
				setSelectedItem(null)
				setSelectedDefects([])
			})
			.catch(error => {
				console.error(error)
			})
			.finally(() => {
				setSavingConfig(false)
			})
	}

	const checkFields = () => {
		let isDisabled = true
		isDisabled = isDisabled && (!selectedItem || selectedDefects.length === 0)
		return isDisabled
	}

	return (
		<Card
			width='calc(100% - 48px)'
			ref={listRef}
			style={{ position: 'relative' }}
			height='100%'
			data-cy='card-items-settings'>
			<LoadingOverlay visible={loadingItems} borderRadius='card' />
			{items && items.length > 0 && defects ? (
				<>
					<Select
						width='35%'
						label={t('wms:Items')}
						placeholder={t('wms:Items')}
						options={_.map(items, item => {
							return { value: item.id, label: item.name }
						})}
						value={selectedItem}
						onChange={v => selectItem(v)}
						data-cy='select-items'
					/>
					{defects && defects.length > 0 && (
						<>
							<FlexView width='100%' fontSize='medium' fontWeight='bold'>
								{t('wms:Defects')}
							</FlexView>
							<Input
								disabled={!selectedItem}
								width='60%'
								type='text'
								margin='8px 0 8px 0'
								placeholder={t('wms:TypeToFilter')}
								value={defect}
								onChange={e => {
									const filtered = defects.filter(def => {
										return def.description.toUpperCase().includes(e.target.value.toUpperCase())
									})
									setFilteredDef(filtered)
									setDefect(e.target.value)
								}}
								data-cy='input-filter-defect'
							/>
							<Checkbox
								disabled={!selectedItem}
								width='50%'
								fontSize='small'
								margin='8px 0 16px 0'
								id='selectAll'
								label={t('wms:SelectAll')}
								onChange={v => selectAllDefects(v)}
								checked={isCheckAll}
								data-cy='checkbox-select-all'
							/>
							<FlexView
								flexDirection='row'
								flexWrap='wrap'
								width='100%'
								maxHeight={`${listHeight}px`}
								style={{ overflowY: 'auto', overflowX: 'hidden' }}>
								{filteredDef &&
									_.map(filteredDef, (filtered, index) => (
										<Checkbox
											disabled={!selectedItem}
											width='45%'
											fontSize='small'
											key={index}
											checked={selectDefects(filtered, selectedDefects)}
											onChange={() => handleSelect(filtered, selectedDefects)}
											label={filtered.description}
											data-cy='checkbox-available-defects'
										/>
									))}
							</FlexView>
						</>
					)}
				</>
			) : (
				<FlexView
					width='100%'
					height='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='no-defect-list'>
					{t('wms:NoAvailableItems')}
				</FlexView>
			)}
			<Button
				margin='auto 0 0 auto'
				backgroundColor='success'
				color='white'
				onClick={() => saveConfig()}
				disabled={checkFields()}
				data-cy='button-save-config'>
				{t('wms:Save')}
				<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				<LoadingOverlay visible={savingConfig} borderRadius='card' />
			</Button>
		</Card>
	)
}

export default DefectsByItems
