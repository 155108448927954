/** @format */

import moment from 'moment'

export const DATE_FORMAT = (date, language) => {
	if (!date) {
		return ''
	} else if (language === 'pt-BR') {
		return moment(date).utc().format('l HH:mm')
	} else {
		return moment(date).utc().format('l hh:mm a')
	}
}
