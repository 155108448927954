/** @format */

import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Movement from '../api/Movement'
import _ from 'lodash'
import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'
import content from '../api/content'
import assetsInspSettings from '../api/assetsInspSettings'

export const MovementContext = React.createContext()

export const MovementProvider = ({ children }) => {
	const {
		warehouse,
		displayBundles,
		displayValids,
		getLastLevelsList,
		getLevelContent,
		getReportList,
		getReportItems,
		getMaterialsDescpritions
	} = useContext(WMSContext)
	const { token } = useContext(UserContext)
	const { t } = useTranslation()
	const [sourceRack, setSourceRack] = useState(null)
	const [targetRack, setTargetRack] = useState(null)
	const [sourceRackList, setSourceRackList] = useState([])
	const [targetRackList, setTargetRackList] = useState([])
	const [loadingPipes, setLoadingPipes] = useState(false)
	const [availablePipes, setAvailablePipes] = useState([])
	const [selectedPipes, setSelectedPipes] = useState([])
	const [movingItems, setMovingItems] = useState(false)
	const [openConfirmModal, setConfirmModal] = useState(false)
	const [rackOccupation, setRackOccupation] = useState([])
	const [qntyTargetRack, setQntyTargetRack] = useState(null)
	const [loadingRackOccupation, setLoadingRackOccupation] = useState(false)
	const [selectedType, setSelectedType] = useState(null)
	const [reports, setReports] = useState([])
	const [loadingReports, setLoadingReports] = useState(false)
	const [selectedReport, setSelectedReport] = useState(null)
	const [materialList, setMaterialList] = useState([])
	const [selectedMaterial, setSelectedMaterial] = useState(null)
	const [filteredPipes, setFilteredPipes] = useState([])
	const [filteredRacks, setFilteredRacks] = useState([])
	const [filteredMaterials, setFilteredMaterials] = useState([])
	const [sourceFilter, setSourceFilter] = useState(null)
	const [customerOrderList, setCustomerOrderList] = useState([])
	const [customerItemList, setCustomerItemList] = useState([])
	const [customerOrder, setCustomerOrder] = useState(null)
	const [customerItem, setCustomerItem] = useState(null)
	const [fullCustomerList, setFullCustomerList] = useState([])
	const [loadingCustomerList, setLoadingCustomerList] = useState(false)
	const [inspections, setInspections] = useState([])
	const [loadingInspections, setLoadingInspections] = useState(false)

	const getRackOccupation = useCallback(availablePipes => {
		let group = _.groupBy(availablePipes, 'material.id')
		let keys = Object.keys(group)
		let materialGroup = []
		keys.forEach(key => {
			materialGroup.push({
				mid: group[key][0].material.id,
				formatted: group[key][0].material.formatted,
				quantity: group[key].length
			})
		})
		return materialGroup.length ? materialGroup : []
	}, [])

	const getFilteredRackList = mid =>
		new Promise((resolve, reject) => {
			content
				.getMaterialsBySearch(
					warehouse.id,
					{
						filtermaterialids: [mid],
						minlengthmm: null,
						maxlengthmm: null
					},
					token
				)
				.then(response => {
					setFilteredRacks(
						_.map(response, res => ({
							id: res.level.id,
							fullname: res.level.fullname
						}))
					)
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingLevels')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const filterMaterialList = list => {
		let filteredMaterialList = _.filter(list, material => material.level.id === sourceRack).map(mat => ({
			id: mat.material.id,
			formatted: mat.material.formatted,
			enduserid: mat.enduserid
		}))
		setFilteredMaterials(_.uniqBy(filteredMaterialList, materials => materials.id))
	}

	const filterCustomerList = contentList => {
		let filteredCustomerList =
			sourceRack && selectedMaterial
				? _.filter(
						contentList,
						content =>
							content.material.id === selectedMaterial && content.level && content.level.id === sourceRack
				  ).map(list => ({
						customerorder: list.customerorder,
						customeritem: list.customeritem
				  }))
				: sourceRack &&
				  _.filter(contentList, content => content.level && content.level.id === sourceRack).map(list => ({
						customerorder: list.customerorder,
						customeritem: list.customeritem
				  }))
		let uniqList = _.uniqBy(filteredCustomerList, 'customerorder')
		setFullCustomerList(uniqList)
	}

	const getInspections = () =>
		new Promise((resolve, reject) => {
			assetsInspSettings
				.getInspections(warehouse.id, token)
				.then(response => {
					const lastRevisions = _(response).sortBy('revision').reverse().uniqBy('name').value()
					setInspections(lastRevisions)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingInspConfig')} [ ${e.status} ]: ${e.data}`)
				})
		})

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingInspections(true)
				await getInspections()
			} catch (e) {
				console.error(e)
				setLoadingInspections(false)
			} finally {
				setLoadingInspections(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		selectedMaterial && sourceRack && availablePipes && availablePipes.length
			? filterCustomerList(availablePipes)
			: sourceRack && availablePipes && availablePipes.length && filterCustomerList(availablePipes)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceRack, selectedMaterial, availablePipes])

	useEffect(() => {
		let ciList = customerOrder && _.filter(fullCustomerList, co => co.customerorder === customerOrder)
		ciList &&
			setCustomerItemList(
				ciList.map(ci => ({
					value: ci.customeritem,
					label: ci.customeritem
				}))
			)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerOrder])

	useEffect(() => {
		let filteredPipesList =
			selectedMaterial && sourceRack && availablePipes
				? customerOrder
					? _.filter(
							availablePipes,
							pipe =>
								pipe.material.id === selectedMaterial &&
								pipe.level.id === sourceRack &&
								pipe.customerorder === customerOrder
					  )
					: customerOrder && customerItem
					? _.filter(
							availablePipes,
							pipe =>
								pipe.material.id === selectedMaterial &&
								pipe.level.id === sourceRack &&
								pipe.customerorder === customerOrder &&
								pipe.customeritem === customerItem
					  )
					: _.filter(availablePipes, pipe => pipe.material.id === selectedMaterial && pipe.level.id === sourceRack)
				: sourceRack &&
				  customerOrder &&
				  customerItem &&
				  availablePipes &&
				  _.filter(
						availablePipes,
						pipe =>
							pipe.level.id === sourceRack &&
							pipe.customerorder === customerOrder &&
							pipe.customeritem === customerItem
				  )

		setFilteredPipes(filteredPipesList)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMaterial, sourceRack, availablePipes, customerOrder, customerItem])

	useEffect(() => {
		getMaterialsDescpritions()
			.then(response => {
				setMaterialList(
					response.map(materials => ({
						id: materials.mvid,
						formatted: materials.formatted,
						enduserid: materials.enduserid
					}))
				)
			})
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (selectedMaterial) {
			const hydrate = async () => {
				try {
					await getFilteredRackList(selectedMaterial)
				} catch (e) {
					console.error(e)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMaterial])

	useEffect(() => {
		rackOccupation && rackOccupation.length
			? setQntyTargetRack(rackOccupation.reduce((a, b) => a + b.quantity, 0))
			: setQntyTargetRack(0)
	}, [rackOccupation])

	useEffect(() => {
		getLastLevelsList()
			.then(lastLevelsList => {
				setTargetRackList(
					lastLevelsList.sort((a, b) => (a.fullname > b.fullname ? 1 : b.fullname > a.fullname ? -1 : 0))
				)
				setSourceRackList(
					lastLevelsList.sort((a, b) => (a.fullname > b.fullname ? 1 : b.fullname > a.fullname ? -1 : 0))
				)
			})
			.catch(e => {
				console.error(e)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (sourceRack != null) {
			sourceRack === targetRack && setTargetRack(null)
			setSelectedPipes([])
			setLoadingPipes(true)
			setAvailablePipes([])
			getLevelContent(sourceRack)
				.then(contentList => {
					if (contentList && contentList.length) {
						setAvailablePipes(contentList)
						filterMaterialList(contentList)
					}
				})
				.catch(e => console.error(e))
				.finally(() => {
					setLoadingPipes(false)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceRack, getLevelContent])

	useEffect(() => {
		if (targetRack != null) {
			setLoadingRackOccupation(true)
			getLevelContent(targetRack)
				.then(contentList => {
					if (contentList && contentList.length) setRackOccupation(getRackOccupation(contentList))
				})
				.catch(e => {
					console.error(e)
					setRackOccupation([])
				})
				.finally(() => {
					setLoadingRackOccupation(false)
				})
		} else setRackOccupation([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetRack, getLevelContent])

	useEffect(() => {
		if (selectedType != null) {
			setLoadingReports(true)
			getReportList(selectedType)
				.then(list => {
					if (list && list.length) {
						let reportList = _.map(list, ls => ({
							id: ls.report_id,
							name: `${ls.type.name} - ${ls.report_id}`
						})).sort((a, b) => a.id - b.id)
						setReports(reportList)
					} else {
						setReports([])
						toast.error(t('wms:NoAvailableReports'))
					}
				})
				.catch(e => {
					console.error(e)
					setLoadingReports(false)
				})
				.finally(() => setLoadingReports(false))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedType])

	useEffect(() => {
		if (selectedType != null && selectedReport != null) {
			setLoadingPipes(true)
			let inspection = _.find(inspections, insp => insp.name === selectedType)
			inspection &&
				inspection.id &&
				getReportItems(inspection.id, selectedReport)
					.then(list => {
						setAvailablePipes(list)
					})
					.catch(e => {
						setLoadingPipes(false)
						console.error(e)
					})
					.finally(() => setLoadingPipes(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedType, selectedReport])

	useEffect(() => {
		setAvailablePipes([])
		setSelectedReport(null)
	}, [selectedType])

	useEffect(() => {
		setAvailablePipes([])
		setSelectedType(null)
		setSelectedReport(null)
		setSourceRack(null)
		setCustomerOrder(null)
		setCustomerItem(null)
	}, [warehouse])

	useEffect(() => {
		setCustomerOrder(null)
		setCustomerItem(null)
	}, [selectedMaterial])

	const moveItems = (sourceFilter, sourceRack, targetRack, pipes, report) =>
		new Promise((resolve, reject) => {
			setConfirmModal(false)
			setMovingItems(true)
			if (
				(sourceFilter === 'RACK' && !(!!sourceRack && !!targetRack && pipes.length !== 0)) ||
				(sourceFilter === 'REPORTS' && !(!!report && !!targetRack && pipes.length !== 0))
			) {
				setMovingItems(false)
				toast.error(t(`wms:InvalidMoveSelection`))
				return
			}
			let payload = { secondsago: 0 }
			if (displayBundles()) payload.bundlelocalids = pipes
			else if (displayValids()) payload.valids = pipes
			else payload.puuids = pipes
			Movement.moveItems(warehouse.id, targetRack, payload, token)
				.then(() => {
					setSelectedPipes([])
					setSourceRack(null)
					setTargetRack(null)
					setSelectedMaterial(null)
					setAvailablePipes([])
					setFilteredMaterials([])
					setFilteredRacks([])
					setFilteredPipes([])
					toast.success(t(`wms:MoveSuccessful`))
					resolve()
				})
				.catch(e => {
					console.error(e)
					toast.error(`${t('wms:ErrorReadingContent')} [${e.status}]: ${e.data}`)
					reject(e)
				})
				.finally(() => {
					setMovingItems(false)
				})
		})

	const getRackName = useCallback(
		rackId => {
			let rack = sourceRackList.find(rack => rack.id === rackId)
			return rack ? rack.fullname : ''
		},
		[sourceRackList]
	)

	return (
		<MovementContext.Provider
			value={{
				sourceRack,
				setSourceRack,
				targetRack,
				setTargetRack,
				sourceRackList,
				setSourceRackList,
				targetRackList,
				setTargetRackList,
				availablePipes,
				selectedPipes,
				setSelectedPipes,
				loadingPipes,
				moveItems,
				movingItems,
				openConfirmModal,
				setConfirmModal,
				getRackName,
				rackOccupation,
				qntyTargetRack,
				loadingRackOccupation,
				selectedType,
				setSelectedType,
				reports,
				setReports,
				loadingReports,
				setLoadingReports,
				selectedReport,
				setSelectedReport,
				setAvailablePipes,
				materialList,
				setMaterialList,
				selectedMaterial,
				setSelectedMaterial,
				filteredPipes,
				setFilteredPipes,
				filteredRacks,
				setFilteredRacks,
				filteredMaterials,
				setFilteredMaterials,
				sourceFilter,
				setSourceFilter,
				customerOrderList,
				setCustomerOrderList,
				customerItemList,
				setCustomerItemList,
				customerOrder,
				setCustomerOrder,
				customerItem,
				setCustomerItem,
				loadingCustomerList,
				setLoadingCustomerList,
				fullCustomerList,
				setFullCustomerList,
				inspections,
				setInspections,
				loadingInspections,
				setLoadingInspections
			}}>
			{children}
		</MovementContext.Provider>
	)
}
