/** @format */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Card from 'components/common/Card'
import FlexView from 'components/common/FlexView'
import Button from 'components/common/Button'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ThreadCompoundContext } from 'apps/wms/stores/AssetsThreadCompoundStore'
import { useDrop } from 'react-dnd'
import DraggableItem from './DraggableItem'
import { useWindowSize } from 'apps/wms/utils/hooks'

const ApplicationMethodsList = () => {
	const { t } = useTranslation()

	const {
		methodsList,
		setMethodsList,
		deleteMethod,
		updateMethodsList,
		reorderList,
		setIsMethodModalOpen,
		moveCardHandler,
		removeItemFromList
	} = useContext(ThreadCompoundContext)

	const listDeadSpace = 100
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const [, drop] = useDrop({
		accept: 'application method',
		drop: () => ({ name: 'Some name' })
	})

	const moveHandler = (dragIndex, hoverIndex) => moveCardHandler(dragIndex, hoverIndex, methodsList, setMethodsList)

	const _onRemoveMethod = async (m, mList, dMethod, rList, remList, uMList, t) => {
		try {
			await dMethod(m.methodid)
			const list = rList(remList(mList, m.methodid))
			await uMList(list)
		} catch (error) {
			console.error(error)
			toast.error(t(`${t('wms:RemoveApplicationMethodError')}. ${error.data}`))
		}
	}

	const onRemoveMethod = useCallback(
		async methodData =>
			_onRemoveMethod(methodData, methodsList, deleteMethod, reorderList, removeItemFromList, updateMethodsList, t),
		[methodsList, deleteMethod, reorderList, updateMethodsList, removeItemFromList, t]
	)

	const _onUpdateListOrder = async (list, rList, uMList, t) => {
		try {
			const newList = rList(list)
			await uMList(newList)
		} catch (error) {
			console.error(error)
			toast.error(t(`${t('wms:ErrorUpdatingApplicationMethodsOrder')}. ${error.data}`))
		}
	}

	const onUpdateListOrder = useCallback(
		async () => _onUpdateListOrder(methodsList, reorderList, updateMethodsList, t),
		[methodsList, reorderList, updateMethodsList, t]
	)

	return (
		<FlexView ref={listRef} flex='1' height='100%' maxWidth='450px' margin='0 8px 0 0'>
			<Card
				ref={drop}
				margin='0 0 16px 0'
				width='calc(100% - 48px)'
				data-cy='application-methods-list-card'
				height={`${listHeight}px`}
				style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				<FlexView width='100%' flexDirection='row' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
					{t('wms:AvailableMethods')}
					<Button
						fontSize='tiny'
						margin='auto 0 auto auto'
						padding='8px 16px'
						color='white'
						backgroundColor='secondary'
						disabled={false}
						onClick={() => setIsMethodModalOpen(true)}>
						{`${t('wms:New')} ${t('wms:ApplicationMethod')}`}
					</Button>
				</FlexView>
				{methodsList.map((method, index) => (
					<DraggableItem
						key={method.methodid}
						index={index}
						id={method.methodid}
						desc={method.methoddesc}
						data={method}
						onRemove={onRemoveMethod}
						moveHandler={moveHandler}
					/>
				))}
			</Card>
			<Button
				margin='0 0 auto auto'
				padding='8px 16px'
				color='white'
				backgroundColor='secondary'
				onClick={onUpdateListOrder}>
				{`${t('wms:Save')} ${t('wms:Order')}`}
			</Button>
		</FlexView>
	)
}

export default ApplicationMethodsList
