/** @format */

import _ from 'lodash'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { UserContext } from 'stores/UserStore'
import assetsEquipments from '../api/assetsEquipments'
import { EQUIPMENT_STATUS } from '../utils/enum'
import { WMSContext } from './WmsStore'

export const AssetsEquipmentsContext = React.createContext()

export const AssetsEquipmentsProvider = ({ children }) => {
	const { t } = useTranslation()
	const { token } = useContext(UserContext)
	const { warehouse } = useContext(WMSContext)
	const [equipmentList, setEquipmentList] = useState([])
	const [disabledEquipList, setDisabledEquipList] = useState([])
	const [loadingEquipments, setLoadingEquipments] = useState(false)
	const [isUpdate, setIsUpdate] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [creatingEquipment, setCreatingEquipment] = useState(false)
	const [updatingEquipment, setUpdatingEquipment] = useState(false)
	const [selectedEquipment, setSelectedEquipment] = useState(null)
	const [equipmentData, setEquipmentData] = useState(null)

	const getEquipmentList = () =>
		new Promise((resolve, reject) => {
			assetsEquipments
				.getInstruments(warehouse.id, token)
				.then(response => {
					setDisabledEquipList(
						_.filter(response, res => res.status === EQUIPMENT_STATUS.code.INACTIVE).sort((a, b) => a.id - b.id)
					)
					resolve()
					setEquipmentList(
						_.filter(response, res => res.status === EQUIPMENT_STATUS.code.ACTIVE).sort((a, b) => a.id - b.id)
					)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingEquipments')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const createEquipment = newEquipment =>
		new Promise((resolve, reject) => {
			setCreatingEquipment(true)
			newEquipment &&
				assetsEquipments
					.addEquipment(warehouse.id, newEquipment, token)
					.then(() => {
						toast.success(t('wms:SuccessfullyCreatedEquipment'))
						resolve()
						getEquipmentList()
						setIsModalOpen(false)
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorCreatingEquipment')} [${e.status} ]: ${e.data}`)
						setCreatingEquipment(false)
					})
					.finally(() => setCreatingEquipment(false))
		})

	const updateEquipment = (equipment, id) =>
		new Promise((resolve, reject) => {
			setUpdatingEquipment(true)
			equipment &&
				assetsEquipments
					.updateEquipment(warehouse.id, id, equipment, token)
					.then(() => {
						resolve()
						toast.success(t('wms:SuccessfullyUpdatedEquipment'))
						setIsModalOpen(false)
						setIsUpdate(false)
						setSelectedEquipment(null)
						getEquipmentList()
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorUpdatingEquipment')} [ ${e.status}]: ${e.data}`)
						setUpdatingEquipment(false)
					})
					.finally(() => setUpdatingEquipment(false))
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingEquipments(true)
				await getEquipmentList()
			} catch (e) {
				console.error(e)
			} finally {
				setLoadingEquipments(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	return (
		<AssetsEquipmentsContext.Provider
			value={{
				equipmentList,
				setEquipmentList,
				loadingEquipments,
				setLoadingEquipments,
				selectedEquipment,
				setSelectedEquipment,
				isUpdate,
				setIsUpdate,
				isModalOpen,
				setIsModalOpen,
				creatingEquipment,
				setCreatingEquipment,
				createEquipment,
				updateEquipment,
				updatingEquipment,
				setUpdatingEquipment,
				equipmentData,
				setEquipmentData,
				disabledEquipList,
				setDisabledEquipList
			}}>
			{children}
		</AssetsEquipmentsContext.Provider>
	)
}
