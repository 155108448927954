/** @format */

import React, { useContext, useEffect, useState, useRef, useCallback } from 'react'
import styled from 'styled-components'

import { FlexView, Card, Button, Modal, Icon, LoadingOverlay } from 'components/common'
import { useTranslation } from 'react-i18next'
import { Input, Select } from 'components/form'
import { PrepareInspectionContext } from 'apps/wms/stores/PrepareInspectionStore'
import DatePicker from 'apps/wms/components/forms/DatePicker/DatePicker'

import _ from 'lodash'
import { ALLOWED_FILES, formatSummaryKeys, INSPECTION_STAGES, STATUS, translateKeySummary } from 'apps/wms/utils/enum'
import Filters from './Filters'
import theme from 'utils/theme'
import { toast } from 'react-toastify'
import { defaultSummary } from '../../utils/enum'

const DateWrapper = styled.div`
	width: 30%;
`

// eslint-disable-next-line react/prop-types
const NewInspModal = ({ isOpen, onOutsideClick, stage, inspections }) => {
	const {
		endUsers,
		isUpdate,
		uploadFile,
		newInspection,
		sourceMaterial,
		qntAvailable,
		setQntAvailable,
		targetMaterial,
		file,
		setFile,
		setEnduser,
		setSelectedReport,
		materials,
		contentFilters,
		setContentFilters,
		cOrder,
		cItem,
		ownershipList
	} = useContext(PrepareInspectionContext)
	const { t } = useTranslation()

	const [inspectionType, setInspectionType] = useState(null)
	const [isRigReturn, setIsRigReturn] = useState(false)
	const [isMaterialRequisition, setIsMaterialRequisition] = useState(false)
	const [isArrival, setIsArrival] = useState(false)
	const [isModifiedProduct, setIsModifiedProduct] = useState(false)

	const borderTopDefinitionStr = '1px solid whitesmoke'
	const borderLeftDefinitionStr = '2px solid #667EEA'
	const quantityStr = t('wms:Quantity')
	const removePartStr = t('wms:RemovePart')

	const [newData, setNewData] = useState({
		summary: {},
		expected_date: '',
		contents: []
	})
	const fileUploader = useRef(null)
	const [selectedMaterial, setSelectedMaterial] = useState(null)
	const [selectedRack, setSelectedRack] = useState(null)
	const [summaryKeys, setSummaryKeys] = useState(null)

	const [materialList, setMaterialList] = useState([])

	const [fileUpload, setFileUpload] = useState(false)
	const [savingInsp, setSavingInsp] = useState(false)

	useEffect(() => {
		setSelectedMaterial(null)
		setInspectionType(null)
		setSelectedRack(null)
		setEnduser(null)
		setSelectedReport(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	useEffect(() => {
		if (inspectionType) {
			setContentFilters([])
			const summary =
				inspectionType &&
				inspectionType.config_summary &&
				inspectionType.config_summary.filter(s => s.enabled === true)
			const keys = summary.map(s => ({ [s.name.toLowerCase()]: '' })) || []
			const params = keys && keys.length > 0 && Object.assign({}, ...keys)
			newData.summary = {}
			setNewData({
				...newData,
				summary: params || {}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspections, inspectionType])

	const newContent = useCallback(
		newDataContent => {
			if (inspectionType) {
				const isFilterOnContent = inspectionType.is_filter_on_content
				const isModifiedProd = inspectionType.is_modified_product
				if (isFilterOnContent) {
					setNewData({
						...newData,
						contents: [...newData.contents, newDataContent]
					})
				} else if (isModifiedProd) {
					setNewData({
						...newData,
						contents: [
							...newData.contents,
							{
								material: newDataContent.filters.material,
								material_source: newDataContent.filters.material_source,
								quantity: newDataContent.quantity,
								customer_order: newDataContent.filters.customer_order,
								customer_item: newDataContent.filters.customer_item,
								target_ownership: newDataContent.filters.target_ownership
							}
						]
					})
				} else {
					setNewData({
						...newData,
						contents: [
							...newData.contents,
							{
								quantity: newDataContent.quantity,
								execution_status: newDataContent.execution_status
							}
						],
						filters: newDataContent.filters
					})
				}
			}
		},
		[newData, inspectionType]
	)

	useEffect(() => {
		if (inspectionType) {
			const isModifiedProd = inspectionType.is_modified_product
			const isReturn = inspectionType.stage === INSPECTION_STAGES.code.RETURN && !inspectionType.is_modified_product
			const isReceiving = inspectionType.stage === INSPECTION_STAGES.code.ARRIVAL
			const isMatReq = inspectionType.stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION
			setIsArrival(isReceiving)
			setIsMaterialRequisition(isMatReq)
			setIsModifiedProduct(isModifiedProd)
			setIsRigReturn(isReturn)
			const isFilterOnContent = inspectionType.is_filter_on_content === true
			const summary =
				inspectionType &&
				inspectionType.config_summary &&
				inspectionType.config_summary.filter(s => s.enabled === true)
			if (summary && summary.length > 0 && isRigReturn) {
				_.forEach(summary, sm => {
					newData.summary[formatSummaryKeys(sm.name)] = ''
					newData.expected_date = ''
				})
				isFilterOnContent
					? setNewData({ ...newData, contents: [], number_of_parts: 0 })
					: setNewData({ ...newData, contents: [], filters: [], number_of_parts: 0 })
			} else if (summary && summary.length > 0) {
				_.forEach(summary, sm => {
					newData.summary[formatSummaryKeys(sm.name)] = ''
					newData.expected_date = ''
				})
				isFilterOnContent
					? setNewData({ ...newData, contents: [] })
					: setNewData({ ...newData, contents: [], filters: [] })
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspectionType])

	useEffect(() => {
		if (isOpen) {
			let summary =
				inspectionType &&
				inspectionType.config_summary &&
				inspectionType.config_summary.filter(s => s.enabled === true)
			summary && setSummaryKeys(summary)
		}
	}, [isOpen, inspectionType])

	const fillMaterialList = useCallback(list => {
		setMaterialList(list)
	}, [])

	const selectMaterial = useCallback(mat => {
		setSelectedMaterial(mat)
	}, [])

	const selectLevel = useCallback(rack => {
		setSelectedRack(rack)
	}, [])

	const addParamName = (value, name) => {
		newData.summary[formatSummaryKeys(name)] = value
		setNewData({ ...newData })
	}

	const inputMessage = name => {
		const keys = _.keys(newData.summary)
		const hasMaxCharacter = keys && _.some(keys, k => k === name) && newData.summary[name].length <= 30
		return hasMaxCharacter ? t('wms:MaxCharacter') : t('wms:MaxCharacterNotAllowed')
	}

	const inputMessageType = name => {
		const keys = _.keys(newData.summary)
		const hasMaxCharacter = keys && _.some(keys, k => k === name) && newData.summary[name].length <= 30
		return hasMaxCharacter ? 'info' : 'error'
	}

	const removeContent = index => {
		if (index != null && index < newData.contents.length) {
			const sameMaterial = selectedMaterial === newData.contents[index].filters.material
			const sameRack = selectedRack === newData.contents[index].filters.level
			const sameCustomerOrder = cOrder === newData.contents[index].filters.customer_order
			const sameCustomerItem = cItem === newData.contents[index].filters.customer_item
			if ((sameMaterial && sameRack) || (sameCustomerOrder && sameCustomerItem))
				setQntAvailable(qntAvailable + newData.contents[index].quantity)
			contentFilters.splice(index, 1)
			setContentFilters([...contentFilters])
			const remContent = newData.contents.splice(index, 1)
			isUpdate && newData.deletedcontent.push(...remContent)
			setNewData({ ...newData, contents: [...newData.contents] })
		}
		if (sourceMaterial && targetMaterial) {
			newData.contents.splice(index, 1)
			setNewData({ ...newData, contents: [...newData.contents] })
		}
	}

	const validateData = () => {
		const now = new Date()
		const commonConditions = Object.values(newData).every(x => x !== '' || x !== null) && newData.expected_date >= now

		if (!inspectionType) return false

		if (inspectionType.stage === INSPECTION_STAGES.code.RETURN && !inspectionType.is_modified_product) {
			return (
				commonConditions &&
				newData.number_of_parts > 0 &&
				Object.values(newData.summary).every(x => x !== '' || x.length > 0) &&
				isSaveEnabledForFile()
			)
		}

		if (inspectionType.stage === INSPECTION_STAGES.code.ARRIVAL) {
			return (
				commonConditions &&
				newData.contents &&
				newData.contents.length > 0 &&
				Object.values(newData.summary).every(x => x !== '' && x.length > 0) &&
				(!newData.summary ||
					!newData.summary[defaultSummary.WORK_ORDER_DESCRIPTION] ||
					newData.summary[defaultSummary.WORK_ORDER_DESCRIPTION].length <= 30) &&
				isSaveEnabledForFile()
			)
		}

		return (
			commonConditions &&
			newData.contents &&
			newData.contents.length > 0 &&
			Object.values(newData.summary).every(x => x !== '' || x.length > 0) &&
			isSaveEnabledForFile()
		)
	}

	const saveInspection = async () => {
		let uploadedFile = ''
		const isMaterialRequisition = inspectionType.stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION
		const payload = isMaterialRequisition && {
			expected_date: newData.expected_date,
			summary: newData.summary,
			contents: newData.contents.map(data => ({
				filters: {
					source_ownership: data.filters.source_ownership,
					enduser: data.filters.enduser,
					material: data.filters.material,
					level: data.filters.level
				},
				quantity: data.quantity,
				execution_status: data.execution_status
			}))
		}
		if (mandatoryUploadFile()) {
			try {
				setFileUpload(true)
				await uploadFile(file, inspectionType.stage, file.name.split('.').pop())
					.then(fileName => {
						uploadedFile = fileName
					})
					.finally(() => setFileUpload(false))
				setSavingInsp(true)
				await newInspection(payload || newData, uploadedFile, inspectionType.stage, inspectionType.id)
					.then(() => {
						setNewData({
							summary: {},
							expected_date: ''
						})
					})
					.finally(() => {
						setSavingInsp(false)
					})
			} catch (e) {
				console.error(e)
			}
		} else {
			try {
				setSavingInsp(true)
				await newInspection(payload || newData, null, inspectionType.stage, inspectionType.id)
					.then(response => response)
					.finally(() => setSavingInsp(false))
			} catch (e) {
				console.error(e)
			}
		}
	}

	const matFormatted = id => {
		if (id && materials && materials.length) {
			const material = materials.find(mat => mat.mvid === id)
			return material.desc || material.formatted || ''
		}
	}

	const checkTypeFiles = (file, type) => {
		const fileSize = file.size
		const isAllowed = _.some(ALLOWED_FILES.types, t => t === type)

		return fileSize > 50000000
			? toast.error(t('wms:MaximumSizeAllowed'))
			: !isAllowed
			? toast.error(t('wms:FileTypeNotAllowed'))
			: setFile(file)
	}

	const getTitle = useCallback(() => {
		return stage === INSPECTION_STAGES.code.ARRIVAL
			? t('wms:PrepareEntranceInspections')
			: stage === INSPECTION_STAGES.code.RETURN
			? t('wms:PrepareReturnInspections')
			: stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION
			? t('wms:PrepareMatReqInspections')
			: t('wms:PrepareInspections')
	}, [stage, t])

	const mandatoryUploadFile = () => {
		let hasField = true
		hasField =
			hasField &&
			inspectionType &&
			_.some(inspectionType.config_summary, { name: 'CustomerRequestReference', enabled: true })
		return hasField
	}

	const isSaveEnabledForFile = () => {
		const isFileNecessary =
			inspectionType && _.some(inspectionType.config_summary, { name: 'CustomerRequestReference', enabled: true })
		if (!isFileNecessary) return true
		return isFileNecessary && file
	}

	const selectInspection = value => {
		let insp = _.find(inspections, insp => insp.id === value)
		setInspectionType(insp)
	}

	const formatOwnership = value => {
		let ownershipSelected =
			ownershipList && ownershipList.length && _.find(ownershipList, owner => owner.id === value)

		return ownershipSelected ? ownershipSelected.label : '****'
	}

	return (
		<Modal isOpen={isOpen}>
			<Card width='90%' height='90%' position='relative'>
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{getTitle()}
					<Button fontSize='tiny' margin='0' backgroundColor='white' disabled={false} onClick={onOutsideClick}>
						<Icon name='cross-simple' color='black' height='16px' width='16px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row' height='100%'>
					<FlexView maxWidth='35%' minWidth='35%' height='100%' margin='0 16px 0 0' flexDirection='column'>
						<Card margin='0 0 16px 0' width='calc(100% - 48px)'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:InspectionType')}
							</FlexView>
							<Select
								placeholder={t('wms:InspectionType')}
								value={inspectionType && inspectionType.id}
								options={
									inspections &&
									_.map(inspections, insp => ({
										value: insp.id,
										label: insp.name
									}))
								}
								onChange={value => {
									selectInspection(value)
								}}
								width='100%'
								inline={true}
								margin='0'
							/>
						</Card>
						<Card margin='0 0 4px 0' height='100%' width='calc(100% - 48px)' style={{ position: 'relative' }}>
							<Filters
								inspectionType={inspectionType}
								inspections={inspections}
								newContent={newContent}
								materialList={fillMaterialList}
								selectMaterial={selectMaterial}
								selectLevel={selectLevel}
								newData={newData}
							/>
						</Card>
					</FlexView>
					<FlexView width='calc(65% - 16px)' minHeight='100%' maxHeight='100%'>
						<Card margin='0 0 4px 0' width='calc(100% - 48px)' minHeight='inherit' flex='1'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:InspectionInfo')}
							</FlexView>
							{inspectionType && (
								<FlexView
									flexDirection='row'
									width='100%'
									maxWidth='100%'
									flexWrap='wrap'
									margin='0 0 8px 0'
									justifyContent='flex-start'>
									{summaryKeys && summaryKeys.length > 0 && (
										<FlexView flexDirection='row' width='100%' flexWrap='wrap'>
											{summaryKeys.map(sm => (
												<FlexView key={sm.name} width='30%' margin='0 16px 0 0' flexDirection='row'>
													<Input
														message={
															inspectionType.stage === INSPECTION_STAGES.code.ARRIVAL &&
															sm.name === defaultSummary.WORK_ORDER_DESCRIPTION
																? inputMessage(formatSummaryKeys(sm.name))
																: null
														}
														messageType={
															inspectionType.stage === INSPECTION_STAGES.code.ARRIVAL &&
															sm.name === defaultSummary.WORK_ORDER_DESCRIPTION
																? inputMessageType(formatSummaryKeys(sm.name))
																: null
														}
														label={t(`${translateKeySummary(sm.name)}`)}
														placeholder={t(`${translateKeySummary(sm.name)}`)}
														margin='8px 0 0 10px'
														value={newData.summary[formatSummaryKeys(sm.name)]}
														onChange={e => addParamName(e.target.value, sm.name)}
														fontSize='small'
														minWidth='30%'
														width='90%'
													/>
												</FlexView>
											))}
											<DateWrapper>
												<DatePicker
													label={t('wms:DispatchExpectedDate')}
													placeholder={`${t('wms:SelectDate')}`}
													margin='8px 0 0 10px'
													timePicker={true}
													value={newData.expected_date}
													onChange={e => setNewData({ ...newData, expected_date: e })}
													fontSize='small'
													noPast={true}
												/>
											</DateWrapper>
											{isRigReturn && (
												<FlexView width='30%' margin='0'>
													<Input
														label={`${t('wms:NumberParts')}`}
														margin='8px 0 0 10px'
														min='1'
														type='number'
														value={newData.number_of_parts}
														onChange={e => {
															setNewData({ ...newData, number_of_parts: e.target.value })
														}}
														fontSize='small'
														width='100%'
														data-cy='input-partsqty'
													/>
												</FlexView>
											)}
										</FlexView>
									)}
									{summaryKeys && summaryKeys.length === 0 && (
										<FlexView
											width='100%'
											fontWeight='bold'
											fontSize='24px'
											color='lightGray'
											margin='auto 0'
											alignItems='center'
											justifyContent='center'
											alignSelf='center'>
											{t('wms:InspectionSummaryNotFound')}
										</FlexView>
									)}
								</FlexView>
							)}
							{newData && newData.contents && newData.contents.length > 0 && (
								<FlexView
									width='100%'
									height='90%'
									maxHeight='90%'
									fontWeight='bold'
									fontSize='medium'
									margin='0 0 8px 0'
									style={{ borderTop: borderTopDefinitionStr, overflowY: 'auto', overflowX: 'hidden' }}>
									<FlexView
										width='100%'
										fontSize='medium'
										fontWeight='bold'
										margin='0 0 8px 0'
										padding='0 0 0 4px'>
										{t('wms:Content')}
									</FlexView>
									{isArrival &&
										inspectionType &&
										inspectionType.is_filter_on_content &&
										newData.contents.map((content, index) => (
											<FlexView
												key={index}
												width='100%'
												flexDirection='row'
												flexWrap='wrap'
												justifyContent='flex-start'>
												{content.filters && content.filters.enduser && (
													<FlexView width='30%' margin='8px 0 8px 0' padding='0' flexDirection='column'>
														<FlexView
															width='100%'
															fontWeight='bold'
															fontSize='tiny'
															color='metalic'
															margin='0'>
															{t('wms:EndUser')}
														</FlexView>
														<FlexView
															padding='0 0 0 8px'
															width='100%'
															fontSize='medium'
															color='black'
															style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
															{endUsers.find(eu => eu.id === content.filters.enduser).name}
														</FlexView>
													</FlexView>
												)}
												{content.filters.customer_order && (
													<FlexView width='30%' margin='0 0 8px 0' padding='0' flexDirection='column'>
														<FlexView
															width='100%'
															fontWeight='bold'
															fontSize='tiny'
															color='metalic'
															margin='0'>
															{t('wms:CustomerOrder')}
														</FlexView>
														<FlexView
															padding='0 0 0 8px'
															width='100%'
															fontSize='medium'
															color='black'
															style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
															{content.filters.customer_order}
														</FlexView>
													</FlexView>
												)}
												{content.filters.customer_item && (
													<FlexView width='30%' margin='0 0 8px 8px' padding='0' flexDirection='column'>
														<FlexView
															width='100%'
															fontWeight='bold'
															fontSize='tiny'
															color='metalic'
															margin='0'>
															{t('wms:CustomerItem')}
														</FlexView>
														<FlexView
															padding='0 0 0 8px'
															width='100%'
															fontSize='medium'
															color='black'
															style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
															{content.filters.customer_item}
														</FlexView>
													</FlexView>
												)}
												{content.filters.sales_order && (
													<FlexView width='30%' margin='0 0 8px 0' padding='0' flexDirection='column'>
														<FlexView
															width='100%'
															fontWeight='bold'
															fontSize='tiny'
															color='metalic'
															margin='0'>
															{t('wms:SalesOrder')}
														</FlexView>
														<FlexView
															width='100%'
															padding='0 0 0 8px'
															fontSize='medium'
															color='black'
															style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
															{content.filters.sales_order}
														</FlexView>
													</FlexView>
												)}
												{content.filters.sales_item && (
													<FlexView width='30%' margin='0 0 8px 0' padding='0' flexDirection='column'>
														<FlexView
															width='100%'
															fontWeight='bold'
															fontSize='tiny'
															color='metalic'
															margin='0'>
															{t('wms:SalesItem')}
														</FlexView>
														<FlexView
															width='100%'
															padding='0 0 0 8px'
															fontSize='medium'
															color='black'
															style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
															{content.filters.sales_item}
														</FlexView>
													</FlexView>
												)}
												{content.filters.material && (
													<FlexView width='100%' margin='0 0 8px 0' padding='0' flexDirection='column'>
														<FlexView
															width='100%'
															fontWeight='bold'
															fontSize='tiny'
															color='metalic'
															margin='0'>
															{t('wms:Material')}
														</FlexView>
														<FlexView
															width='100%'
															padding='0 0 0 8px'
															fontSize='medium'
															color='black'
															style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
															{matFormatted(content.filters.material)}
														</FlexView>
													</FlexView>
												)}
												<FlexView
													key={index}
													width='100%'
													margin='0'
													flexDirection='row'
													padding='8px 0'
													style={{
														borderTop: !index ? 'none' : borderTopDefinitionStr,
														overflowY: 'scroll'
													}}>
													<FlexView flexDirection='row' width='50%'>
														<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
															<strong>{`${t('wms:Part')} ${index + 1}`}</strong>
														</FlexView>
														<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
															<strong>{`${quantityStr}: ${content.quantity}`}</strong>
														</FlexView>
													</FlexView>
													<FlexView
														width='50%'
														height='100%'
														margin='0'
														padding='0'
														flexDirection='row'
														justifyContent='center'>
														<FlexView
															key={index}
															fontSize='tiny'
															color={STATUS.color[content.execution_status]}
															style={{
																borderLeft: `2px solid ${
																	theme.colors[STATUS.color[content.execution_status]]
																}`,
																lineHeight: '24px'
															}}
															margin='auto 0 auto auto'
															padding='8px 16px'>
															{t(`wms:${STATUS.literal[content.execution_status]}`)}
														</FlexView>
														<Button
															fontSize='small'
															margin='auto 0 auto auto'
															padding='8px 16px'
															color='white'
															backgroundColor='error'
															disabled={false}
															onClick={() => removeContent(index)}>
															<Icon
																name='trash'
																color='white'
																height='16px'
																width='16px'
																margin='0'
																tootilp={removePartStr}
															/>
														</Button>
													</FlexView>
												</FlexView>
											</FlexView>
										))}
									{isArrival && inspectionType && !inspectionType.is_filter_on_content && (
										<FlexView width='100%' flexDirection='row' flexWrap='wrap' justifyContent='flex-start'>
											{newData && newData.filters && newData.filters.enduser && (
												<FlexView width='30%' margin='8px 0 8px 0' padding='0' flexDirection='column'>
													<FlexView
														width='100%'
														fontWeight='bold'
														fontSize='tiny'
														color='metalic'
														margin='0'>
														{t('wms:EndUser')}
													</FlexView>
													<FlexView
														padding='0 0 0 8px'
														width='100%'
														fontSize='medium'
														color='black'
														style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
														{endUsers.find(eu => eu.id === newData.filters.enduser).name}
													</FlexView>
												</FlexView>
											)}
											{newData && newData.filters.customer_order && (
												<FlexView width='30%' margin='0 0 8px 0' padding='0' flexDirection='column'>
													<FlexView
														width='100%'
														fontWeight='bold'
														fontSize='tiny'
														color='metalic'
														margin='0'>
														{t('wms:CustomerOrder')}
													</FlexView>
													<FlexView
														padding='0 0 0 8px'
														width='100%'
														fontSize='medium'
														color='black'
														style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
														{newData.filters.customer_order}
													</FlexView>
												</FlexView>
											)}
											{newData && newData.filters.customer_item && (
												<FlexView width='30%' margin='0 0 8px 8px' padding='0' flexDirection='column'>
													<FlexView
														width='100%'
														fontWeight='bold'
														fontSize='tiny'
														color='metalic'
														margin='0'>
														{t('wms:CustomerItem')}
													</FlexView>
													<FlexView
														padding='0 0 0 8px'
														width='100%'
														fontSize='medium'
														color='black'
														style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
														{newData.filters.customer_item}
													</FlexView>
												</FlexView>
											)}
											{newData && newData.filters && newData.filters.sales_order && (
												<FlexView width='30%' margin='0 0 8px 0' padding='0' flexDirection='column'>
													<FlexView
														width='100%'
														fontWeight='bold'
														fontSize='tiny'
														color='metalic'
														margin='0'>
														{t('wms:SalesOrder')}
													</FlexView>
													<FlexView
														width='100%'
														padding='0 0 0 8px'
														fontSize='medium'
														color='black'
														style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
														{newData.filters.sales_order}
													</FlexView>
												</FlexView>
											)}
											{newData && newData.filters && newData.filters.sales_item && (
												<FlexView width='30%' margin='0 0 8px 0' padding='0' flexDirection='column'>
													<FlexView
														width='100%'
														fontWeight='bold'
														fontSize='tiny'
														color='metalic'
														margin='0'>
														{t('wms:SalesItem')}
													</FlexView>
													<FlexView
														width='100%'
														padding='0 0 0 8px'
														fontSize='medium'
														color='black'
														style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
														{newData.filters.sales_item}
													</FlexView>
												</FlexView>
											)}
											{newData && newData.filters && newData.filters.material && (
												<FlexView width='100%' margin='0 0 8px 0' padding='0' flexDirection='column'>
													<FlexView
														width='100%'
														fontWeight='bold'
														fontSize='tiny'
														color='metalic'
														margin='0'>
														{t('wms:Material')}
													</FlexView>
													<FlexView
														width='100%'
														padding='0 0 0 8px'
														fontSize='medium'
														color='black'
														style={{ borderLeft: borderLeftDefinitionStr, lineHeight: '24px' }}>
														{materialList.find(mt => mt.id === newData.filters.material).formatted}
													</FlexView>
												</FlexView>
											)}
											{newData &&
												newData.contents.length > 0 &&
												newData.contents.map((content, index) => (
													<FlexView
														key={index}
														width='100%'
														margin='0'
														flexDirection='row'
														padding='8px 0'
														style={{
															borderTop: !index ? 'none' : borderTopDefinitionStr,
															overflowY: 'scroll'
														}}>
														<FlexView flexDirection='row' width='50%'>
															<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
																<strong>{`${t('wms:Part')} ${index + 1}`}</strong>
															</FlexView>
															<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
																<strong>{`${quantityStr}: ${content.quantity}`}</strong>
															</FlexView>
														</FlexView>
														<FlexView
															width='50%'
															height='100%'
															margin='0'
															padding='0'
															flexDirection='row'
															justifyContent='center'>
															<FlexView
																key={index}
																fontSize='tiny'
																color={STATUS.color[content.execution_status]}
																style={{
																	borderLeft: `2px solid ${
																		theme.colors[STATUS.color[content.execution_status]]
																	}`,
																	lineHeight: '24px'
																}}
																margin='auto 0 auto auto'
																padding='8px 16px'>
																{t(`wms:${STATUS.literal[content.execution_status]}`)}
															</FlexView>
															<Button
																fontSize='small'
																margin='auto 0 auto auto'
																padding='8px 16px'
																color='white'
																backgroundColor='error'
																disabled={false}
																onClick={() => removeContent(index)}>
																<Icon
																	name='trash'
																	color='white'
																	height='16px'
																	width='16px'
																	margin='0'
																	tootilp={removePartStr}
																/>
															</Button>
														</FlexView>
													</FlexView>
												))}
										</FlexView>
									)}
									{isModifiedProduct &&
										inspectionType &&
										inspectionType.is_filter_on_content &&
										newData &&
										newData.contents &&
										newData.contents.length &&
										newData.contents.map((content, index) => (
											<FlexView
												key={index}
												flexDirection='row'
												width='100%'
												height='90%'
												margin='8px 0 0 0'
												style={{
													borderTop: !index ? 'none' : borderTopDefinitionStr,
													overflowY: 'scroll'
												}}>
												<FlexView flex='1' fontSize='small' margin='4px 0'>
													{matFormatted(content && content.filters && content.filters.material)}
													<br />
													<strong>{`${quantityStr}: ${content.quantity}`}</strong>
												</FlexView>
												<FlexView
													width='35%'
													height='100%'
													margin='0'
													padding='0'
													flexDirection='row'
													justifyContent='center'>
													<FlexView
														key={index}
														fontSize='tiny'
														color={STATUS.color[content.execution_status]}
														style={{
															borderLeft: `2px solid ${
																theme.colors[STATUS.color[content.execution_status]]
															}`,
															lineHeight: '24px'
														}}
														margin='auto 0 auto auto'
														padding='8px 16px'>
														{t(`wms:${STATUS.literal[content.execution_status]}`)}
													</FlexView>
													<Button
														fontSize='small'
														margin='auto 0 auto auto'
														padding='8px 16px'
														color='white'
														backgroundColor='error'
														disabled={false}
														onClick={() => removeContent(index)}>
														<Icon
															name='trash'
															color='white'
															height='16px'
															width='16px'
															margin='0'
															tootilp={removePartStr}
														/>
													</Button>
												</FlexView>
											</FlexView>
										))}
									{isMaterialRequisition &&
										inspectionType &&
										inspectionType.is_filter_on_content &&
										newData &&
										newData.contents &&
										newData.contents.length &&
										newData.contents.map((content, index) => (
											<FlexView
												key={index}
												flexDirection='row'
												width='100%'
												margin='0'
												style={{
													borderTop: !index ? 'none' : borderTopDefinitionStr
												}}>
												<FlexView width='100%' flexDirection='column'>
													<FlexView key={index} flex='1' fontSize='small' margin='0' padding='0 0 0 4px'>
														{matFormatted(content && content.filters && content.filters.material)}
													</FlexView>
													<FlexView
														flex='1'
														width='100%'
														fontWeight='bold'
														fontSize='small'
														padding='0 0 0 4px'>
														{`${
															content.filters &&
															content.filters.source_ownership &&
															formatOwnership(content.filters.source_ownership)
														} | ${content.filters && content.filters.levelname} | ${t('wms:Quantity')}: ${
															content.quantity
														}`}
													</FlexView>
												</FlexView>

												<FlexView
													width='35%'
													height='100%'
													margin='0'
													padding='0'
													flexDirection='row'
													justifyContent='center'>
													<FlexView
														key={index}
														fontSize='tiny'
														color={STATUS.color[content.execution_status]}
														style={{
															borderLeft: `2px solid ${
																theme.colors[STATUS.color[content.execution_status]]
															}`,
															lineHeight: '24px'
														}}
														margin='auto 0 auto auto'
														padding='8px 16px'>
														{t(`wms:${STATUS.literal[content.execution_status]}`)}
													</FlexView>
													<Button
														fontSize='small'
														margin='auto 0 auto auto'
														padding='8px 16px'
														color='white'
														backgroundColor='error'
														disabled={false}
														onClick={() => removeContent(index)}>
														<Icon
															name='trash'
															color='white'
															height='16px'
															width='16px'
															margin='0'
															tootilp={removePartStr}
														/>
													</Button>
												</FlexView>
											</FlexView>
										))}
								</FlexView>
							)}
						</Card>
					</FlexView>
				</FlexView>
				<FlexView width='100%' flexDirection='row' margin='16px 0 0 0' alignItems='center'>
					{inspectionType && mandatoryUploadFile() ? (
						<>
							<Button
								fontSize='medium'
								margin='0 8px 0 0'
								style={{ marginLeft: 'calc(35% + 16px)' }}
								color='white'
								backgroundColor='secondary'
								disabled={inspectionType === null}
								onClick={() => fileUploader.current.click()}>
								{t('wms:UploadFile')}
								<Icon name='upload' color='white' height='16px' width='16px' margin='0 0 0 8px' />
							</Button>
							<FlexView
								style={{ texOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
								flex='1'
								margin='0 8px 0 0'
								flexDirection='row'>
								{file && file.name}
							</FlexView>
							<input
								style={{ display: 'none' }}
								id='fileInput'
								type='file'
								accept='.pdf, .png, .jpg, .ods, .doc, .docx, .odt, .ppt, .pptx, .xls, .xlsx'
								ref={fileUploader}
								onChange={e =>
									checkTypeFiles(e.target.files[0], e.target.files && e.target.files[0].name.split('.').pop())
								}
							/>{' '}
						</>
					) : null}
					<Button
						fontSize='medium'
						margin='0 0 0 auto'
						color='white'
						backgroundColor='success'
						disabled={!validateData()}
						onClick={() => saveInspection()}>
						{t('wms:Save')}
						<Icon name='save' color='white' height='16px' width='16px' margin='0 0 0 8px' />
						<LoadingOverlay visible={savingInsp || fileUpload} borderRadius='card' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default NewInspModal
