/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getReports: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/modifprod/${wid}/reports`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.modifprodlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getModifiedProductLists: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`modifprod/${wid}/list`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.modifprodlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getTargetMaterials: (wid, enduserid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`modifprod/materiallist/${wid}/${enduserid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response.data.materiallist)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	saveModifiedProductList: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`modifprod/${wid}/list`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	deleteModifiedProductList: (wid, mplid, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`modifprod/${wid}/list/${mplid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	uploadFile: (wid, file, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(
					`modifprod/${wid}/savepdf`,
					{ erfile: file },
					{
						headers: {
							Authorization: `${token}`
						}
					}
				)
				.then(response => {
					if (response.status === 200) resolve(response.data.filename)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateModifiedProductList: (wid, params = {}, newmpid, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`modifprod/${wid}/list/${newmpid}`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					resolve(response)
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	reopenModif: (wid, payload = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`modifprod/${wid}/release`, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		})
}
