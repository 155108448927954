import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import {
  ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend,
} from 'recharts'
import _ from 'lodash'
import moment from 'moment'
import { FlexView } from 'components/common'

const CpuChart = ({ time, disk, diskTotal, isPercentage }) => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const data = _.map(time, (item, index) => {
    return {
      x: moment(item, 'YYYY-MM-DDTHH:mm:ssZ').format('DD/MM/YYYY HH:mm'),
      y: isPercentage ? ((diskTotal[index]-disk[index])/diskTotal[index])*100 : disk[index]/1048576
    }
  })

  const formatDisk = value => isPercentage ? value.toFixed(2) + '%' : value.toFixed(1) + ' MB'

  const yAxisProps = isPercentage ? {
    domain: [0, 100],
    label: { value: '%', angle: -90, position: 'insideLeft' }
  } : {
    label: { value: 'MB', angle: -90, position: 'insideLeft' }
  }

  return (
    <FlexView  flex="1" style={{ overflow: 'hidden' }} fontSize="small">
      <FlexView margin="0px 0px 8px" fontSize="big" fontWeight="bold">{t('admin:status.Disk')}{isPercentage && ` - ${Math.round(diskTotal[0]/1073741824)} GB`}</FlexView>
      <ResponsiveContainer Container width="100%" height={200}>
        <LineChart data={data} margin={{ top: 5, right: 10, left: 0, bottom: 5 }}>
          <XAxis dataKey="x" dy={5}/>
          <YAxis dx={-5} {...yAxisProps} />
          <Tooltip formatter={formatDisk} />
          <Legend />
          <Line type="monotone" dataKey="y" name={t('admin:status.Disk Used')} stroke={theme.colors.tertiary} activeDot={{ r: 6 }} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </FlexView>
  )
}

export default CpuChart
