import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { CSSTransition } from 'react-transition-group'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { FlexView, Icon } from '../../common'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 300px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadows.high};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  z-index: 999999;
  overflow-y: auto;
  transform-origin: 10% 10%;

  &.side-menu-enter {
    opacity: 0;
    transform: scale(0.8);
  }
  &.side-menu-enter-active {
    opacity: 1;
    transition: opacity 0.1s ease, transform 0.1s ease;
    transform: translateX(0);
  }
  &.side-menu-exit {
    opacity: 1;
    transform: translateX(0);
  }
  &.side-menu-exit-active {
    opacity: 0;
    transition: opacity 0.1s ease, transform 0.1s ease;
    transform: scale(0.8);
  }

  /* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

  /* total width */
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {display:none}

  img {
    max-height: 120px;
  }
`

const Item = styled.div`
  margin: 8px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Noto Sans';
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 8px 16px;
  margin: -4px 0px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.offWhite};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.offWhite};
  }

  ${({ header, theme }) => header && css`
    margin: 8px 0px 8px;
    font-weight: bold;
    padding-left: 0px;
    color: ${theme.colors.metalic};
    font-size: ${theme.fontSizes.small};
    text-transform: uppercase;
    cursor: default;

    &:hover {
      background-color: white;
    }
  `};
`

const SidebarContent = ({ onOutsideClick, children }) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = e => {
      onOutsideClick && wrapperRef && (wrapperRef.current !== e.target) && !wrapperRef.current.contains(e.target) && onOutsideClick()
    }
    document.addEventListener("click", handleOutsideClick)

    return () => {
      document.removeEventListener("click", handleOutsideClick)
    }
  }, [onOutsideClick])

  const renderComponent = <Wrapper ref={wrapperRef}>{ children }</Wrapper>
  return ReactDOM.createPortal(renderComponent, document.body)
}

const SideMenu = ({ isOpen, onOutsideClick, appLogoSrc, itemGroups }) => {
  const closeMenuAfter = onClick => () => {
    onClick()
    onOutsideClick()
  }

  const renderItems = () => {
    return _.map(itemGroups, ({ key, label, items }) => (
      <div key={key}>
        <Item header>{label}</Item>
        {_.map((items), ({ key, label, icon, onClick }) => (
           <Item key={key} onClick={closeMenuAfter(onClick)}>
            {icon && <Icon name={icon} width="16px" height="16px" margin="0px 6px 0px 0px" />}
            {label}
          </Item>
        ))}
      </div>
    ))
  }

  return (
    <CSSTransition
      in={isOpen}
      timeout={300}
      classNames="side-menu"
      unmountOnExit
    >
      <SidebarContent onOutsideClick={onOutsideClick}>
        <div><Icon name="bars" onClick={onOutsideClick} width="18px" height="18px" margin="5px 0px 0px" /></div>
        <FlexView width="100%" alignItems="center" justifyContent="center">
          <img src={appLogoSrc} style={{ maxWidth: '240px', margin: '24px 0px' }} alt="Application Logo" />
        </FlexView>
        {renderItems()}
      </SidebarContent>
    </CSSTransition>
  )
}

const SideMenuControl = props => {
  const [isOpen, setOpen] = useState(false)

  const openSideMenu = () => setOpen(true)
  const closeSideMenu = () => setOpen(false)

  return <>
    <SideMenu isOpen={isOpen} onOutsideClick={closeSideMenu} {...props} />
    <Icon name="bars" onClick={openSideMenu} width="18px" height="18px" margin="0px 12px 0px 0px" />
  </>
}

export default SideMenuControl