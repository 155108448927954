/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getVehicles: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`vehicle/${wid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response.data.vehicles)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	saveVehicle: (wid, isNew, vid, params = {}, token) =>
		new Promise((resolve, reject) => {
			if (isNew === true) {
				instance
					.post(`vehicle/${wid}`, params, {
						headers: {
							Authorization: `${token}`
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) resolve(response)
						else reject(new Error('CouldNotConnect'))
					})
					.catch(err => {
						reject(err.response || err)
					})
			} else {
				instance
					.put(`vehicle/${wid}/${vid}`, params, {
						headers: {
							Authorization: `${token}`
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) resolve(response)
						else reject(new Error('CouldNotConnect'))
					})
					.catch(err => {
						reject(err.response || err)
					})
			}
		}),
	deleteVehicle: (wid, vid, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`vehicle/${wid}/${vid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
