/** @format */

import React, { useContext } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import assetsAttr from '../api/assetsAttributes'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import _ from 'lodash'

export const AssetsAttributesContext = React.createContext()

export const AssetsAttributesProvider = ({ children }) => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const { token } = useContext(UserContext)
	const [loadingAttributes, setLoadingAttributes] = useState(false)
	const [openAddModal, setOpenAddModal] = useState(false)
	const [loadingValues, setLoadingValues] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [attributes, setAttributes] = useState(null)
	const [mandatoryAttr, setMandatoryAttr] = useState(null)
	const [optionalAttr, setOptionalAttr] = useState(null)
	const [attrSelected, setAttrSelected] = useState(null)
	const [values, setValues] = useState([])
	const [optAttrSelected, setOptAttrSelected] = useState({
		name: '',
		description: '',
		symbol: ''
	})
	const [mandatorySelected, setMandatorySelected] = useState(null)
	const [newAttribute, setNewAttribute] = useState('')
	const [attrTypeSelected, setAttrTypeSelected] = useState(null)
	const getAttributes = () =>
		new Promise((resolve, reject) => {
			assetsAttr
				.getAttributes(warehouse.id, token)
				.then(response => {
					resolve()
					setAttributes(response)
					setMandatoryAttr(_.filter(response, res => res.mandatory === true))
					setOptionalAttr(_.filter(response, res => res.mandatory === false))
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingAttributes')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getAttributeValues = () =>
		new Promise((resolve, reject) => {
			assetsAttr
				.getAttrValues(warehouse.id, attrSelected, token)
				.then(response => {
					resolve()
					setValues(response)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingAttrValues')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const addAttributeValue = (newAttr, selected) =>
		new Promise((resolve, reject) => {
			let payload = {
				aid: selected.id ? selected.id : selected,
				value: newAttr
			}
			assetsAttr
				.addAttrValue(warehouse.id, payload, token)
				.then(response => {
					resolve(response)
					getAttributeValues()
					setNewAttribute('')
					toast.success(t('wms:SuccessfullyCreatedValue'))
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorCreatingValue')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const saveAttrValue = item =>
		new Promise((resolve, reject) => {
			let payload = item
			assetsAttr
				.saveAttrValue(warehouse.id, item.id, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullySavedValue'))
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorUpdatingValue')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const deleteAttrValue = item =>
		new Promise((resolve, reject) => {
			assetsAttr
				.deleteAttrValue(warehouse.id, item.id, token)
				.then(response => {
					for (var i = 0; i < values.length; i++) {
						let ov = values[i]
						ov.id === item.id && delete values[i]
					}
					setValues({ ...values })
					resolve(response)
					toast.success(t('wms:SuccessfullyDeletedValue'))
					getAttributeValues()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorDeletingValue')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const saveAttr = attribute =>
		new Promise((resolve, reject) => {
			if (attribute.isnew) {
				_.find(attributes, attr => attr.name === attribute.name) && toast.error(t('wms:AttributeSameName'))
				assetsAttr
					.saveAttribute(warehouse.id, attribute.isnew, attribute, null, token)
					.then(response => {
						resolve()
						toast.success(t('wms:AttributeCreatedSuccessful'))
						setAttributes({ ...attributes, response })
						setOpenAddModal(false)
						getAttributes()
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorCreatingAttribute')} [ ${e.status} ]: ${e.data}`)
					})
			} else {
				assetsAttr
					.saveAttribute(warehouse.id, null, attribute, attribute.id, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:AttributeUpdatedSuccessful'))
						setAttributes({ ...attributes, response })
						getAttributes()
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorUpdatingAttribute')} [ ${e.status} ]: ${e.data}`)
					})
			}
		})

	const deleteAttr = () =>
		new Promise((resolve, reject) => {
			optAttrSelected &&
				assetsAttr
					.deleteAttribute(warehouse.id, optAttrSelected.id, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:AttributeDeletedSuccessful'))
						getAttributes()
						setOptAttrSelected({
							name: '',
							description: '',
							symbol: ''
						})
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorDeletingAttribute')} [ ${e.status} ]: ${e.data}`)
					})
					.finally(() => {
						setOpenConfirmModal(false)
					})
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingAttributes(true)
				setAttrTypeSelected(null)
				setOptionalAttr(null)
				setMandatoryAttr(null)
				setMandatorySelected(null)
				setOptAttrSelected({ name: '', description: '', symbol: '' })
				setAttrSelected(null)
				await getAttributes()
			} catch (e) {
				console.error(e)
				setLoadingAttributes(false)
			} finally {
				setLoadingAttributes(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingValues(true)
				attrSelected && (await getAttributeValues())
			} catch (e) {
				setLoadingValues(false)
				console.error(e)
			} finally {
				setLoadingValues(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, attrSelected])

	return (
		<AssetsAttributesContext.Provider
			value={{
				attributes,
				setAttributes,
				attrSelected,
				setAttrSelected,
				values,
				setValues,
				loadingAttributes,
				setLoadingAttributes,
				mandatoryAttr,
				setMandatoryAttr,
				optionalAttr,
				setOptionalAttr,
				newAttribute,
				setNewAttribute,
				openAddModal,
				setOpenAddModal,
				loadingValues,
				setLoadingValues,
				addAttributeValue,
				saveAttrValue,
				deleteAttrValue,
				saveAttr,
				openConfirmModal,
				setOpenConfirmModal,
				deleteAttr,
				optAttrSelected,
				setOptAttrSelected,
				mandatorySelected,
				setMandatorySelected,
				attrTypeSelected,
				setAttrTypeSelected
			}}>
			{children}
		</AssetsAttributesContext.Provider>
	)
}
