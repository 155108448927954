/** @format */

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay, Icon } from 'components/common'
import { Select } from 'components/form'
import DialogModal from 'apps/wms/components/forms/DialogModal'

import { MovementContext } from '../stores/MovementStore'
import PageTemplate from '../containers/common/PageTemplate'
import DualListSelect from '../components/forms/DualListSelect/DualListSelect'
import { useWindowSize } from '../utils/hooks'
import { WMSContext } from '../stores/WmsStore'
import { MOVEMENT_SOURCE_TYPES } from '../utils/enum'

const MovementPage = () => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const {
		sourceRack,
		setSourceRack,
		targetRack,
		setTargetRack,
		sourceRackList,
		availablePipes,
		selectedPipes,
		setSelectedPipes,
		loadingPipes,
		moveItems,
		movingItems,
		openConfirmModal,
		setConfirmModal,
		getRackName,
		rackOccupation,
		qntyTargetRack,
		loadingRackOccupation,
		selectedType,
		setSelectedType,
		reports,
		selectedReport,
		setSelectedReport,
		loadingReports,
		setAvailablePipes,
		materialList,
		selectedMaterial,
		setSelectedMaterial,
		filteredPipes,
		setFilteredPipes,
		targetRackList,
		filteredRacks,
		setFilteredRacks,
		filteredMaterials,
		setFilteredMaterials,
		sourceFilter,
		setSourceFilter,
		customerItemList,
		customerOrder,
		setCustomerOrder,
		customerItem,
		setCustomerItem,
		loadingCustomerList,
		fullCustomerList,
		inspections
	} = useContext(MovementContext)

	const listDeadSpace = 160
	const colDeadSpace = 625
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const [cardHeight, setCardHeight] = useState(0)
	const [totalPipes, setTotalPipes] = useState(0)
	const listRef = useRef(null)
	const columnRef = useRef(null)

	const sourceString = t('wms:Source')
	const targetString = t('wms:Target')
	const materialString = t('wms:Material')

	useEffect(() => {
		setCardHeight(columnRef.current.offsetHeight - colDeadSpace)
	}, [columnRef, window])

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		setAvailablePipes([])
		setFilteredPipes([])
		setSelectedMaterial(null)
		setSelectedReport(null)
		setSelectedType(null)
		setSourceRack(null)
		setSelectedPipes([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceFilter])

	useEffect(() => {
		setSourceFilter(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Movement'),
				path: '/wms/manage/move'
			}
		],
		[t]
	)

	const clearFilter = () => {
		setSourceRack(null)
		setSelectedMaterial(null)
		setFilteredRacks([])
		setFilteredMaterials([])
		setFilteredPipes([])
		setCustomerOrder(null)
		setCustomerItem(null)
	}

	const handleTypeOfFilterFieldChange = value => {
		setTotalPipes(0)
		setSourceFilter(value)
	}

	const handleMaterialFieldChange = value => {
		setTotalPipes(0)
		setSelectedMaterial(value)
	}

	const handleReportFieldChange = value => {
		setTotalPipes(0)
		setSelectedReport(value)
	}

	const handleReportTypeFieldChange = value => {
		setTotalPipes(0)
		setSelectedType(value)
	}

	const countSelectedPipes = value => {
		setSelectedPipes(value)
		setTotalPipes(value.length)
	}

	const movePipes = () => {
		if (sourceFilter === 'RACK') moveItems(sourceFilter, sourceRack, targetRack, selectedPipes, null)
		else moveItems(sourceFilter, null, targetRack, selectedPipes, selectedReport)
	}

	const checkSource = () => {
		let isDisabled = true
		if (sourceFilter === 'RACK' && !sourceRack && !targetRack && selectedPipes.length === 0) isDisabled = true
		else if (!targetRack && selectedPipes.length === 0 && !selectedReport) isDisabled = true
		else isDisabled = false

		return isDisabled
	}

	return (
		<PageTemplate crumbs={crumbs} loadingOverlay={movingItems}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView flexDirection='row' minWidth='100%' fontSize='40px' fontWeight='bold'>
					{t('wms:Movement')}
					<Select
						width='max-content'
						inline
						placeholder={t('wms:TypeOfFilter')}
						label={t('wms:TypeOfFilter')}
						value={sourceFilter}
						options={MOVEMENT_SOURCE_TYPES.map(source => ({
							value: source.value,
							label: t(source.label)
						}))}
						onChange={value => handleTypeOfFilterFieldChange(value)}
						margin='0 0 0 auto'
					/>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView flex='1' height='100%' maxWidth='450px' margin='0 8px 0 0' ref={columnRef}>
						<Card margin='0 0 16px 0' width='calc(100% - 48px)' data-cy='card-source-rack'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{sourceString}
							</FlexView>
							{((filteredRacks && filteredRacks.length) || sourceRackList) && (
								<Select
									searchable
									label={`${t('wms:Rack')}:`}
									placeholder={t('wms:SelectRack')}
									value={sourceRack}
									options={
										filteredRacks.map(rack => ({
											label: rack.fullname,
											value: rack.id
										})) ||
										sourceRackList.map(rack => ({
											label: rack.fullname,
											value: rack.id
										}))
									}
									onChange={value => {
										setSourceRack(value)
									}}
									width='100%'
									margin='0'
									disabled={sourceFilter !== 'RACK'}
									data-cy='select-source-rack'
								/>
							)}
							{sourceFilter === 'RACK' && (
								<FlexView width='100%' flexDirection='column'>
									<Select
										width='100%'
										searchable
										label={`${materialString}:`}
										placeholder={materialString}
										value={selectedMaterial}
										options={
											filteredMaterials && filteredMaterials.length
												? filteredMaterials.map(material => ({
														label: material.formatted,
														value: material.id
												  }))
												: materialList &&
												  materialList.map(material => ({
														label: material.formatted,
														value: material.id
												  }))
										}
										onChange={value => handleMaterialFieldChange(value)}
										margin='8px 0 0 0'
									/>
									<LoadingOverlay visible={loadingCustomerList} borderRadius='card' />
									<Select
										width='100%'
										searchable
										label={t('wms:CustomerOrder')}
										placeholder={t('wms:CustomerOrder')}
										value={customerOrder}
										options={
											fullCustomerList &&
											fullCustomerList.length &&
											fullCustomerList.map(list => ({
												label: list.customerorder,
												value: list.customerorder
											}))
										}
										onChange={value => setCustomerOrder(value)}
										margin='8px 0 0 0'
									/>
									<Select
										width='100%'
										searchable
										label={t('wms:CustomerItem')}
										placeholder={t('wms:CustomerItem')}
										value={customerItem}
										options={
											customerItemList &&
											customerItemList.length &&
											customerItemList.map(co => ({
												label: co.label,
												value: co.value
											}))
										}
										onChange={value => setCustomerItem(value)}
										margin='8px 0 0 0'
										disabled={!customerOrder}
									/>
								</FlexView>
							)}
							<Select
								width='100%'
								searchable
								label={`${t('wms:ReportType')}:`}
								placeholder={t('wms:ReportType')}
								value={selectedType}
								options={
									inspections
										? inspections.map(inspection => ({
												value: inspection.name,
												label: inspection.name
										  }))
										: ''
								}
								onChange={value => handleReportTypeFieldChange(value)}
								margin='8px 0 0 0'
								disabled={sourceFilter !== 'REPORTS'}
							/>
							<>
								<LoadingOverlay visible={loadingReports} borderRadius='card' />
								{reports && (
									<Select
										width='100%'
										searchable
										label={`${t('wms:Reports')}: `}
										placeholder={t('wms:Reports')}
										value={selectedReport}
										options={reports.map(report => ({
											value: report.id,
											label: report.name
										}))}
										onChange={value => handleReportFieldChange(value)}
										margin='0'
										disabled={selectedType == null || sourceFilter === 'RACK'}
									/>
								)}
							</>
							{sourceFilter === 'RACK' && (
								<FlexView width='100%' justifyContent='center' alignItems='center' margin='auto 0 0 0'>
									<Button
										margin='0'
										disabled={
											(!sourceRack && !selectedMaterial) ||
											(!sourceRack && !selectedMaterial && !customerOrder && !customerItem) ||
											(!sourceRack && !customerOrder && !customerItem)
										}
										onClick={() => clearFilter()}>
										{t('wms:ClearFilter')}
										<Icon name='cross-simple' width='12px' height='12px' margin='0 0 0 8px' />
									</Button>
								</FlexView>
							)}
						</Card>
						<Card width='calc(100% - 48px)' margin='0 0 16px 0' data-cy='card-target-rack'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{targetString}
							</FlexView>
							<Select
								searchable
								placeholder={t('wms:SelectRack')}
								value={targetRack}
								options={
									targetRackList &&
									targetRackList
										.filter(rack => rack.id !== sourceRack)
										.map(rack => ({
											label: rack.fullname,
											value: rack.id
										}))
								}
								onChange={value => {
									setTargetRack(value)
								}}
								width='100%'
								inline={true}
								margin='0'
								data-cy='select-target-rack'
							/>
						</Card>
						{targetRack !== null && (
							<Card
								width='calc(100% - 48px)'
								margin='0'
								data-cy='card-occupation-rack'
								style={{ position: 'relative' }}>
								<LoadingOverlay visible={loadingRackOccupation} borderRadius='card' />
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
									{getRackName(targetRack)} - {t('wms:RackOccupation')}: {qntyTargetRack} {t('wms:Pipes')}
								</FlexView>
								<FlexView
									width='100%'
									fontSize='small'
									color='metalic'
									flexDirection='row'
									justifyContent='space-between'
									margin='0 0 16px 0'>
									<span>{materialString}</span>
									<span>{t('wms:Quantity')}</span>
								</FlexView>
								<FlexView width='100%' height={`${cardHeight}px`} margin='0' style={{ overflowY: 'scroll' }}>
									{rackOccupation &&
										rackOccupation.length &&
										rackOccupation.map(item => (
											<FlexView key={item.mid} width='100%' flexDirection='row' margin='0 0 16px 0'>
												<FlexView fontSize='small' width='80%'>
													{item.formatted}
												</FlexView>
												<FlexView fontSize='small' width='20%' alignItems='flex-end'>
													{item.quantity}
												</FlexView>
											</FlexView>
										))}
								</FlexView>
							</Card>
						)}
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						<Card
							width='calc(100% - 48px)'
							flex='1'
							margin='0'
							style={{ position: 'relative' }}
							data-cy='card-pipes-list'
							ref={listRef}>
							<FlexView style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
								<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
									{t('wms:AvailablePipes')}
								</FlexView>
								<FlexView
									width='500px'
									fontSize='medium'
									fontWeight='bold'
									margin='0 0 16px 0'
									alignItems='flex-end'>
									{t('wms:TotalSelectedPipes') + `: ${totalPipes}`}
								</FlexView>
							</FlexView>
							<LoadingOverlay visible={loadingPipes} borderRadius='card' />
							{sourceFilter === 'RACK' && filteredPipes && filteredPipes.length && (
								<FlexView flex='1' margin='0' width='100%'>
									<DualListSelect
										searchable={true}
										width='100%'
										height={`${listHeight}px`}
										margin='0'
										selectedValues={selectedPipes}
										options={filteredPipes.map(pipe => ({
											label: pipe.id,
											value: pipe.id
										}))}
										onChange={value => {
											countSelectedPipes(value)
										}}
										data-cy='dualselect-pipes-list'
										leftLabel={sourceString}
										rightLabel={targetString}
									/>
								</FlexView>
							)}
							{sourceFilter === 'REPORTS' && availablePipes && availablePipes.length && (
								<FlexView flex='1' margin='0' width='100%'>
									<DualListSelect
										searchable={true}
										width='100%'
										height={`${listHeight}px`}
										margin='0'
										selectedValues={selectedPipes}
										options={availablePipes.map(pipe => ({
											label: pipe.id || pipe.valid,
											value: pipe.id || pipe.valid
										}))}
										onChange={value => {
											countSelectedPipes(value)
										}}
										data-cy='dualselect-pipes-list'
										leftLabel={sourceString}
										rightLabel={targetString}
									/>
								</FlexView>
							)}
							{((filteredPipes && filteredPipes.length === 0) ||
								(availablePipes && availablePipes.length === 0)) && (
								<FlexView
									width='100%'
									fontWeight='bold'
									fontSize='24px'
									color='lightGray'
									margin='auto 0'
									alignItems='center'
									justifyContent='center'
									data-cy='placeholder-pipes-list'>
									{t('wms:NoAvailablePipes')}
								</FlexView>
							)}
							<Button
								fontSize='medium'
								margin='0 0 0 auto'
								color='white'
								backgroundColor='secondary'
								disabled={checkSource()}
								onClick={() => {
									setConfirmModal(true)
								}}
								data-cy='button-move-pipes'>
								{t('wms:MoveItems')}
							</Button>
						</Card>
					</FlexView>
				</FlexView>
			</FlexView>
			<DialogModal
				isOpen={openConfirmModal}
				text={t(`wms:ConfirmMove`, { source: getRackName(sourceRack), target: getRackName(targetRack) })}
				onConfirm={movePipes}
				onCancel={() => setConfirmModal(false)}
				data-cy='dialog-confirm-move'
			/>
		</PageTemplate>
	)
}

export default MovementPage
