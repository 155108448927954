/** @format */

/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay, Icon } from 'components/common'
import Table from '../../components/reports/ExpandedTable'
import { INSPECTION_STAGES, REPORT_STATUS, REPORT_TYPE } from '../../utils/enum'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import Pagination from 'apps/wms/components/reports/Pagination'
import { ReportsContext } from 'apps/wms/stores/ReportStore'
import { useState } from 'react'

const ExportReportList = ({
	page,
	loadingList,
	list = [],
	reportType,
	onDownloadReport,
	onDownloadReportPdf,
	height,
	downloadingReport,
	selectedInspection,
	getMoreReports,
	filteredReports = []
}) => {
	const {
		totalItems,
		currentPage,
		setCurrentPage,
		totalPages,
		setFetchingFirstPage,
		setFetchingLastPage,
		canPreviousPage,
		setCanPreviousPage,
		canNextPage,
		setCanNextPage
	} = useContext(ReportsContext)

	const { t, i18n } = useTranslation()
	const [selectedSubRow, setSelectedSubRow] = useState(null)
	const [selectedRow, setSelectedRow] = useState(null)

	const workOrderString = t('wms:WorkOrderDescription')
	const actionString = t('wms:Action')

	const handleDownloadReport = useCallback(
		row => {
			const subRowSelected = row.depth > 0
			row != null && (page === 'dispatch' || page === 'title_transfer' || page === 'modif_products')
				? onDownloadReport(row.original.listid || row.original.id)
				: row != null &&
				  onDownloadReport(
						subRowSelected && selectedSubRow ? selectedSubRow.listid : selectedRow && selectedRow.listid,
						subRowSelected ? row.original.part : null
				  )
		},
		[onDownloadReport, page, selectedRow, selectedSubRow]
	)

	const handleDownloadReportPdf = useCallback(
		row => {
			const subRowSelected = row.depth > 0
			!!row && page === 'title_transfer'
				? onDownloadReportPdf(row.original.id)
				: row != null &&
				  onDownloadReportPdf(
						subRowSelected && selectedSubRow ? selectedSubRow.listid : selectedRow && selectedRow.listid,
						subRowSelected ? row.original.part : null
				  )
		},
		[onDownloadReportPdf, page, selectedRow, selectedSubRow]
	)

	const selectRow = row => {
		if (row.depth > 0) {
			setSelectedSubRow({
				listid: row.original.id,
				id: row.id,
				depth: row.depth,
				partid: row.original.partid,
				part: row.original.part,
				closuredate: row.original.closuredate
			})
			setSelectedRow(null)
		} else {
			setSelectedRow({
				listid: row.original.listid,
				id: row.id,
				depth: row.depth
			})
			setSelectedSubRow(null)
		}
	}

	const checkOldReports = row => {
		return row.original.validation_data
	}

	const showOldReportsMessage = row => {
		if (checkOldReports(row)) return t('wms:OldReportsNotDownloadable')
	}

	const renderRowExpand = row => {
		return (
			<FlexView
				flexDirection='row'
				alignItems='center'
				justifyContent='center'
				width='10px'
				fontSize='14px'
				margin='0'
				padding='0'
				style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
				{...row.getToggleRowExpandedProps({
					style: {
						paddingLeft: `${row.depth * 32}px`,
						cursor: row.canExpand ? 'pointer' : 'inherit'
					},
					title: ''
				})}>
				{row.canExpand && (
					<Icon
						name={row.isExpanded ? 'chevron-up' : 'chevron-down'}
						width='12px'
						height='12px'
						color={
							selectedRow && selectedRow.id === row.id
								? 'white'
								: selectedRow && selectedRow.id === row.id && row.isExpanded
								? 'white'
								: 'gray'
						}
					/>
				)}
			</FlexView>
		)
	}

	const mrColumns = useMemo(
		() => [
			{
				id: 'expander',
				Header: ' ',
				width: '5px',
				Cell: ({ cell: { row } }) => renderRowExpand(row)
			},
			{
				Header: 'ID',
				accessor: 'listid',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Parts'),
				accessor: 'part',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: workOrderString,
				accessor: 'workorderdescription',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:CustomerRequestReference'),
				accessor: 'customerrequestreference',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Ownership'),
				accessor: 'ownershipname',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: selectedInspection ? `${selectedInspection.name + ' ' + t('wms:Date')}` : null,
				accessor: 'closuredate',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: actionString,
				width: 'auto',
				Cell: ({ cell: { row } }) => (
					<FlexView flexDirection='row' width='30%'>
						<Button
							fontSize='tiny'
							margin='4px'
							backgroundColor='primary'
							elevation='none'
							onClick={() => {
								handleDownloadReport(row)
							}}
							disabled={checkOldReports(row)}
							title={showOldReportsMessage(row)}
							data-cy='button-download-report'>
							<Icon name='csv' width='16px' height='16px' color='white' />
						</Button>
						<Button
							fontSize='tiny'
							margin='4px'
							color='white'
							backgroundColor='tertiary'
							elevation='none'
							onClick={() => {
								handleDownloadReportPdf(row)
							}}
							disabled={checkOldReports(row)}
							title={showOldReportsMessage(row)}
							data-cy='button-download-report-pdf'>
							<Icon name='pdf' width='16px' height='16px' color='white' />
						</Button>
						{row.original.status === REPORT_STATUS.code.PENDING ||
						row.original.status === REPORT_STATUS.code.NOTCONCLUDED ? (
							<FlexView width='10%' margin='0 0 0 8px' alignSelf='center'>
								*DRAFT*
							</FlexView>
						) : (
							''
						)}
						<LoadingOverlay visible={downloadingReport} />
					</FlexView>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, reportType, t]
	)

	const backloadColumns = useMemo(
		() => [
			{
				id: 'expander',
				Header: ' ',
				width: '5px',
				Cell: ({ cell: { row } }) => renderRowExpand(row)
			},
			{
				Header: t('wms:Parts'),
				accessor: 'part',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:CustomerRequestReference'),
				accessor: 'customerrequestreference',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value ? value : '*****'}
						</FlexView>
					)
				}
			},
			{
				Header: workOrderString,
				accessor: 'workorderdescription',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{value ? value : '*****'}
					</FlexView>
				)
			},
			{
				Header:
					selectedInspection && selectedInspection.is_modified_product
						? t('wms:ModifiedProductDate')
						: t('wms:RigReturnDate'),
				accessor: 'closuredate',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: actionString,
				width: 'auto',
				Cell: ({ cell: { row } }) => (
					<>
						<Button
							width='100%'
							fontSize='tiny'
							margin='4px 0'
							color='white'
							backgroundColor='primary'
							disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
							elevation='none'
							onClick={() => {
								selectRow(row)
								handleDownloadReport(row)
							}}
							data-cy='button-download-report'>
							{t('wms:DownloadReport')}
						</Button>
						<LoadingOverlay visible={downloadingReport} />
					</>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, reportType, t]
	)

	const dispatchColumns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'listid',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: workOrderString,
				accessor: 'workorderdescription',
				width: '250px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:DispatchDate'),
				accessor: 'created',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: actionString,
				width: 'auto',
				Cell: ({ cell: { row } }) => (
					<>
						<Button
							width='100%'
							fontSize='tiny'
							margin='4px 0'
							color='white'
							backgroundColor='primary'
							disabled={false}
							elevation='none'
							onClick={() => {
								handleDownloadReport(row)
							}}
							data-cy='button-download-report'>
							{t('wms:DownloadReport')}
						</Button>
						<LoadingOverlay visible={downloadingReport} />
					</>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, reportType, t]
	)

	const titleTransferColumns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Name'),
				accessor: 'name',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:ExtactionDate'),
				accessor: 'extractiondate',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{DATE_FORMAT(value, i18n.language)}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Warehouse'),
				accessor: 'warehouse',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:NumberLTPA'),
				accessor: 'ltpanumber',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: actionString,
				width: 'auto',
				Cell: ({ cell: { row } }) => (
					<FlexView flexDirection='row' justifyContent='center'>
						<Button
							fontSize='tiny'
							margin='4px'
							backgroundColor='primary'
							elevation='none'
							onClick={() => {
								handleDownloadReport(row)
							}}
							data-cy='button-download-report'>
							<Icon name='csv' width='16px' height='16px' color='white' />
						</Button>
						<Button
							fontSize='tiny'
							margin='4px'
							color='white'
							backgroundColor='tertiary'
							elevation='none'
							onClick={() => {
								handleDownloadReportPdf(row)
							}}
							data-cy='button-download-report-pdf'>
							<Icon name='pdf' width='16px' height='16px' color='white' />
						</Button>
					</FlexView>
				)
			}
		],
		[t, actionString, i18n.language, handleDownloadReport, handleDownloadReportPdf]
	)

	const lists = () => {
		if (selectedInspection && selectedInspection.stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION) {
			return mrColumns
		} else if (selectedInspection && selectedInspection.stage === INSPECTION_STAGES.code.RETURN) {
			return backloadColumns
		} else if (reportType === REPORT_TYPE.code.DISPATCH) {
			return dispatchColumns
		} else if (reportType === REPORT_TYPE.code.TITLE_TRANSFER) {
			return titleTransferColumns
		}
	}

	const getCy = () => {
		if (selectedInspection && selectedInspection.stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION)
			return 'list-reports-delivery-notes'
		else if (reportType === REPORT_TYPE.code.BACKLOAD_MANIFEST || reportType === REPORT_TYPE.code.MODIF_PRODUCTS)
			return 'list-reports-backload-manifest'
		else if (reportType === REPORT_TYPE.code.DISPATCH) return 'list-reports-dispatch'
		else return 'list-reports-title-transfer'
	}

	return (
		<Card data-cy='card-list-wrapper' width='calc(100% - 48px)' flex='1' margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingList} borderRadius='card' />
			{list && list.length && lists() ? (
				<Table
					margin='0'
					width='calc(100%)'
					textAlign='center'
					elevation='none'
					height={height}
					fontSize='14px'
					columns={lists()}
					data={filteredReports && filteredReports.length ? filteredReports : list}
					sortable
					gridless
					data-cy={getCy()}
					selectedRow={selectedRow}
					selectedSubRow={selectedSubRow}
					onRowClick={value => selectRow(value)}
				/>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='placeholder-reports-list'>
					{t('wms:NoReport')}
				</FlexView>
			)}
			<FlexView width='100%' margin='16px 0 0 0'>
				{totalItems > 50 && (
					<Pagination
						canPreviousPage={canPreviousPage}
						setCanPreviousPage={setCanPreviousPage}
						canNextPage={canNextPage}
						setCanNextPage={setCanNextPage}
						totalPages={totalPages}
						totalItems={totalItems}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						setFetchingFirstPage={setFetchingFirstPage}
						setFetchingLastPage={setFetchingLastPage}
						getMoreReports={getMoreReports}
					/>
				)}
			</FlexView>
		</Card>
	)
}

export default ExportReportList
