/** @format */

import { Button, FlexView, Icon } from 'components/common'
import { Select } from 'components/form'
import React, { useContext, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PageTemplate from '../containers/common/PageTemplate'
import { PrepareInspectionContext } from '../stores/PrepareInspectionStore'
import _ from 'lodash'
import InspectionReportsList from '../containers/prepareInsp/List'
import PrepareInspectionInfo from '../containers/prepareInsp/Info'
import { UserContext } from 'stores/UserStore'
import NewInspModal from '../containers/prepareInsp/NewInspModal'
import UpdateInspModal from '../containers/prepareInsp/UpdateInspModal'
import { INSPECTION_STAGES } from '../utils/enum'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { entrance, genParams, materialReq, Return } from '../utils/hubs'

const PrepareInspectionPage = () => {
	const { t } = useTranslation()
	const { user } = useContext(UserContext)
	const {
		inspections,
		selectedInsp,
		setSelectedInsp,
		selectedReport,
		setSelectedReport,
		formattedReport,
		racks,
		openFile,
		reopen,
		openModal,
		setOpenModal,
		openUpdateModal,
		setOpenUpdateModal,
		setInspName,
		setIsMatRequisition,
		setIsArrival,
		setIsReturn,
		loadingReports
	} = useContext(PrepareInspectionContext)
	const [filteredInspections, setFilteredInspections] = useState([])
	const [stage, setStage] = useState(null)

	let { inspection } = useParams()

	const selectInsp = value => {
		let inspection = _.find(inspections, insp => insp.id === value)
		if (inspection) {
			setInspName(inspection.name)
			setSelectedInsp(inspection)
		}
	}

	const getTitle = useCallback(() => {
		return inspection === 'entrance'
			? t('wms:PrepareEntranceInspections')
			: inspection === 'return'
			? t('wms:PrepareReturnInspections')
			: inspection === 'matreq'
			? t('wms:PrepareMatReqInspections')
			: t('wms:PrepareInspections')
	}, [inspection, t])

	const getName = useCallback(() => {
		return inspection === 'entrance'
			? t('wms:EntranceInspections')
			: inspection === 'return'
			? t('wms:ReturnInspections')
			: inspection === 'matreq'
			? t('wms:MatReqInspections')
			: t('wms:Inspections')
	}, [inspection, t])

	const addInspection = () => {
		setOpenModal(true)
		setSelectedReport(null)
	}

	const handleReopen = useCallback(content => {
		content && reopen(content).catch(error => console.error(error))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: `/wms/`
			},
			{
				name: `${inspection}`,
				path: `/wms/${inspection}${genParams(
					inspection === 'entrance' ? entrance : inspection === 'return' ? Return : materialReq
				)}`
			},
			{
				name: getName(),
				path: null
			}
		],
		[getName, inspection]
	)

	useEffect(() => {
		if (inspection === 'entrance') {
			setStage(INSPECTION_STAGES.code.ARRIVAL)
			let filtered = _.filter(inspections, insp => insp.stage === INSPECTION_STAGES.code.ARRIVAL)
			filtered && filtered.length && setFilteredInspections(filtered)
			setIsArrival(true)
			setIsMatRequisition(false)
			setIsReturn(false)
		} else if (inspection === 'return') {
			setStage(INSPECTION_STAGES.code.RETURN)
			let filtered = _.filter(inspections, insp => insp.stage === INSPECTION_STAGES.code.RETURN)
			filtered && filtered.length && setFilteredInspections(filtered)
			setIsReturn(true)
			setIsArrival(false)
			setIsMatRequisition(false)
		} else if (inspection === 'matreq') {
			setStage(INSPECTION_STAGES.code.MATERIAL_REQUISITION)
			let filtered = _.filter(inspections, insp => insp.stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION)
			filtered && filtered.length && setFilteredInspections(filtered)
			setIsMatRequisition(true)
			setIsReturn(false)
			setIsArrival(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspection, inspections])

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='calc(100% - 320px)' height='100%' flex='1' margin='0 0 40px 8px'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 16px 0'>
					{getTitle()}
				</FlexView>
				<FlexView flexDirection='row' minWidth='45%' width='45%' alignItems='center'>
					{filteredInspections && (
						<Select
							inline
							margin='0'
							width='220px'
							label={`${t('wms:InspectionType')}: `}
							placeholder={t('wms:InspectionType')}
							value={selectedInsp && selectedInsp.id}
							options={_.map(filteredInspections, insp => ({
								value: insp.id,
								label: insp.name
							}))}
							onChange={value => selectInsp(value)}
						/>
					)}
					<Button
						color='white'
						backgroundColor='secondary'
						onClick={() => addInspection()}
						margin='auto 8px 8px auto'
						justifyContent='center'
						alignItems='center'
						alignSelf='center'>
						{t('wms:AddInspection')}
						<Icon name='add' color='white' width='12px' height='12px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='45%' margin='0 8px 0 0'>
						<InspectionReportsList />
					</FlexView>
					<FlexView
						width={selectedInsp && selectedInsp.stage === INSPECTION_STAGES.code.PERIODICAL ? '25%' : '45%'}
						height='100%'
						margin='0 8px 0 8px'>
						{formattedReport && (
							<PrepareInspectionInfo
								report={formattedReport}
								loadingReports={loadingReports}
								rackList={racks}
								selectedInspection={selectedInsp}
								user={user}
								openFile={() => openFile()}
								reopenContent={value => handleReopen(value)}
								openModal={openModal}
							/>
						)}
					</FlexView>
				</FlexView>
			</FlexView>
			<NewInspModal
				isOpen={openModal}
				onOutsideClick={() => setOpenModal(false)}
				stage={stage}
				inspections={filteredInspections}
			/>
			<UpdateInspModal
				isOpen={openUpdateModal}
				onOutsideClick={() => setOpenUpdateModal(false)}
				selectedReport={selectedReport}
				selectedInspection={selectedInsp}
				stage={stage}
			/>
		</PageTemplate>
	)
}

export default PrepareInspectionPage
