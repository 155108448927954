/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import ageMgmt from 'apps/wms/api/assetsAgeMgmt'
import { toast } from 'react-toastify'
import _ from 'lodash'

export const AssetsAgeMgmtContext = React.createContext()

export const AssetsAgeMgmtProvider = ({ children }) => {
	const { t } = useTranslation()
	const { warehouse, getLevelsData } = useContext(WMSContext)
	const { token } = useContext(UserContext)
	const [extracts, setExtracts] = useState(null)
	const [selectedExtract, setSelectedExtract] = useState(null)
	const [productList, setProductList] = useState(null)
	const [ownership, setOwnership] = useState([])
	const [erpList, setErpList] = useState(null)
	const [erpReference, setErpReference] = useState([])
	const [newExtract, setNewExtract] = useState({
		extractdesc: '',
		periodthreshold: '',
		consignmenttypefilters: [],
		ownershipfilters: [],
		producttypefilters: [],
		erpreferencefilters: []
	})
	const [updateExtract, setUpdateExtract] = useState(null)
	const [materials, setMaterials] = useState(null)
	const [loadingExtracts, setLoadingExtracts] = useState(false)
	const [openAddModal, setOpenAddModal] = useState(false)
	const [openUpdateModal, setOpenUpdateModal] = useState(false)
	const [save, setSave] = useState(false)
	const [isNew, setIsNew] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [levels, setLevels] = useState(null)

	const getExtracts = () =>
		new Promise((resolve, reject) => {
			ageMgmt
				.getExtracts(warehouse.id, token)
				.then(response => {
					_.forEach(response, res => {
						res.periodthreshold = res.periodthreshold / 30
					})
					let ext = _.sortBy(response, res => {
						return res.extractid
					})
					setExtracts(ext)
					resolve()
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingExtracts')} [ ${e.status} ]: ${e.data}`)
					console.error(e)
					reject(e)
				})
		})

	const getErpReference = () =>
		new Promise((resolve, reject) => {
			ageMgmt
				.getErpReference(warehouse.id, token)
				.then(response => {
					let erp = _.map(response.materialdescriptions, mat => {
						return mat.erpreference
					})
					erp = _.uniq(erp)
					erp = _.filter(erp, opt => {
						return opt.value !== null && opt.value !== '-'
					})
					setErpList(erp)
					resolve()
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingErpReference')} [ ${e.status}]: ${e.data}`)
					console.error(e)
					reject(e)
				})
		})

	const getMaterials = () =>
		new Promise((resolve, reject) => {
			ageMgmt
				.getMaterials(warehouse.id, token)
				.then(response => {
					setMaterials(response)
					resolve()
					gettAttributes(response.producttypeattributeid)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingMaterialDsc')} [ ${e.status} ]: ${e.data}`)
					console.error(e)
					reject(e)
				})
		})

	const gettAttributes = id =>
		new Promise((resolve, reject) => {
			ageMgmt
				.getAttributes(warehouse.id, id, token)
				.then(response => {
					setProductList(response.attributevalues)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingProductTypeList')} [ ${e.status}]: ${e.data}`)
				})
		})

	const saveExtract = () =>
		new Promise((resolve, reject) => {
			let payload = {}
			if (isNew) {
				payload = {
					extractdesc: newExtract.extractdesc,
					periodthreshold: newExtract.periodthreshold * 30,
					consignmenttypefilters: newExtract.consignmenttypefilters,
					ownershipfilters: newExtract.ownershipfilters,
					producttypefilters: newExtract.producttypefilters,
					erpreferencefilters: newExtract.erpreferencefilters
				}
			} else {
				payload = {
					extractid: updateExtract.extractid,
					extractdesc: updateExtract.extractdesc,
					periodthreshold: updateExtract.periodthreshold * 30,
					consignmenttypefilters: updateExtract.consignmenttypefilters,
					ownershipfilters: updateExtract.ownershipfilters,
					producttypefilters: updateExtract.producttypefilters,
					erpreferencefilters: updateExtract.erpreferencefilters
				}
			}
			ageMgmt
				.saveExtract(warehouse.id, isNew, payload, token)
				.then(response => {
					resolve(response)
					toast.success(isNew ? t('wms:SuccessfullyCreatedExtract') : t('wms:SuccessfullyUpdatedExtract'))
					setSave(false)
					setIsNew(false)
					setNewExtract({
						extractdesc: '',
						periodthreshold: '',
						consignmenttypefilters: [],
						ownershipfilters: [],
						producttypefilters: [],
						erpreferencefilters: []
					})
					setSelectedExtract(null)
					setOpenAddModal(false)
					setOpenUpdateModal(false)
					getExtracts()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(
						isNew
							? `${t('wms:ErrorCreatingExtract')} [ ${e.status}]: ${e.data}`
							: `${t('wms:ErrorUpdatingExtract')} [ ${e.status}]: ${e.data}`
					)
				})
		})

	const deleteExtract = () =>
		new Promise((resolve, reject) => {
			let payload = {
				extractid: selectedExtract.extractid
			}
			ageMgmt
				.deleteExtract(warehouse.id, payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:SuccessfullyDeletedExtract'))
					setOpenConfirmModal(false)
					getExtracts()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorDeletingExtract')} [ ${e.status}]: ${e.data}`)
					setOpenConfirmModal(false)
				})
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingExtracts(true)
				await getExtracts()
			} catch (e) {
				setLoadingExtracts(false)
				console.error(e)
			} finally {
				setLoadingExtracts(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		getLevelsData()
			.then(levels => setLevels(levels))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getErpReference()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getMaterials()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (save) {
			const hydrate = async () => {
				try {
					await saveExtract()
				} catch (e) {
					console.error(e)
					setSave(false)
				} finally {
					setSave(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [save])

	useEffect(() => {
		if (openUpdateModal) {
			let extract = _.find(extracts, e => {
				return e.extractid === selectedExtract.extractid
			})
			openUpdateModal && setUpdateExtract({ ...extract })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openUpdateModal])

	useEffect(() => {
		setSelectedExtract(null)
	}, [warehouse])

	return (
		<AssetsAgeMgmtContext.Provider
			value={{
				extracts,
				setExtracts,
				selectedExtract,
				setSelectedExtract,
				levels,
				setLevels,
				ownership,
				setOwnership,
				erpList,
				setErpList,
				erpReference,
				setErpReference,
				loadingExtracts,
				setLoadingExtracts,
				openAddModal,
				setOpenAddModal,
				openUpdateModal,
				setOpenUpdateModal,
				newExtract,
				setNewExtract,
				productList,
				setProductList,
				save,
				setSave,
				isNew,
				setIsNew,
				updateExtract,
				setUpdateExtract,
				materials,
				setMaterials,
				openConfirmModal,
				setOpenConfirmModal,
				deleteExtract
			}}>
			{children}
		</AssetsAgeMgmtContext.Provider>
	)
}
