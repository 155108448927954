/** @format */

import { FlexView } from 'components/common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PageTemplate from '../containers/common/PageTemplate'
import { assets, genParams } from '../utils/hubs'
import theme from 'utils/theme'
import PerInspCard from '../containers/assets/settings/PerInspCard'
import InspectionExpirationTimeCard from '../containers/assets/settings/InspectionExpirationTimeCard'
import UnitsSettingsCard from '../containers/assets/settings/UnitsSettingsCard'

const AssetsSettingsPage = () => {
	const { t } = useTranslation()

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:Settings'),
				path: '/wms/assets/settings'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='calc(100% - 320px)' height='100%' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:Settings')}
				</FlexView>
				<FlexView width='100%' flexDirection='row' alignItems='stretch' justifyContent='space-between'>
					<FlexView flexDirection='column' width='40%'>
						<FlexView
							width='100%'
							height='30px'
							borderRadius='4px'
							backgroundColor={theme.colors.secondary}
							color={theme.colors.white}
							margin='0'
							fontWeight='bold'
							justifyContent='center'
							alignItems='center'>
							{t('wms:Inspection')}
						</FlexView>
						<FlexView
							width='100%'
							flexDirection='row'
							height='100%'
							alignItems='stretch'
							justifyContent='space-between'>
							<PerInspCard />
							<InspectionExpirationTimeCard />
						</FlexView>
					</FlexView>
					<FlexView flexDirection='column' width='40%' margin='0 0 0 16px'>
						<FlexView
							width='100%'
							height='30px'
							borderRadius='4px'
							backgroundColor={theme.colors.secondary}
							color={theme.colors.white}
							margin='0'
							fontWeight='bold'
							justifyContent='center'
							alignItems='center'>
							{t('wms:WMSUnitManagement')}
						</FlexView>
						<FlexView
							width='100%'
							flexDirection='row'
							height='100%'
							alignItems='stretch'
							justifyContent='space-between'>
							<UnitsSettingsCard />
						</FlexView>
					</FlexView>
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default AssetsSettingsPage
