import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Manager, Reference, Popper } from 'react-popper'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import styled from 'styled-components'
import moment from 'moment'
import MonthView from './MonthView'
import YearView from './YearView'
import TimeInput from './TimeInput'
import DateDisplay from './DateDisplay'
import { FlexView, Icon } from '../../common'

const Wrapper = styled.div`
  width: fit-content;
  font-family: 'Noto Sans';
  display: flex;
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  opacity: ${({ open }) => open ? '1' : '0'};
  transform: ${({ popperTransform, open }) => `${popperTransform} ${open ? 'translateX(0)' : 'scale(0.9)'} !important`};
  flex-direction: column;
  padding: 8px;
  background: #FFFFFF;
  box-shadow: ${({ theme }) => theme.boxShadows.high };
  overflow-y: auto;
  border-radius: 8px;
  z-index: 999;
  transition: all 0.2s ease;
  /*position: fixed !important;
  top: auto !important;
  left: auto !important;*/
`

const DateInput = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px 8px 8px;
  margin: ${({ label }) => label ? '8px 0px' : '0px'};
  border: 2px solid ${({ error, theme }) => error ? theme.colors.error : theme.colors.lightGray};
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  outline: none;

  span {
    color: ${({ theme }) => theme.colors.metalic};
  }
`

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  white-space: nowrap;
  font-weight: bold;
  margin-right: ${({ inline }) => inline ? '8px' : '0px'};
`

const DatePickerPopper = React.forwardRef(({ style, placement, isOpen, viewMode, renderView, value, timePicker, scheduleUpdate, time, onTimeChange }, ref) => {
  useEffect(() => {
    scheduleUpdate()
  }, [isOpen, scheduleUpdate])

  return <Wrapper ref={ref} style={style} data-placement={placement} open={isOpen} popperTransform={style.transform}>
    <SwitchTransition>
      <CSSTransition
        key={viewMode}
        timeout={300}
        classNames="fade"
      >
        {renderView()}
      </CSSTransition>
    </SwitchTransition>
    <FlexView width="calc(100% - 16px)" flexDirection="row" alignItems="flex-end" justifyContent="space-between" padding="8px">
      <DateDisplay date={value} />
      {timePicker && <TimeInput value={time} onChange={onTimeChange} />}
    </FlexView>
  </Wrapper>
})

const DatePicker = ({ value, onChange, timePicker, format, placeholder, label, inline, margin, error, dataCy }) => {
  const node = useRef(null)
  const [time, setTime] = useState(value ? value.format('HH:mm') : '00:00')
  const [currentView, setCurrentView] = useState(moment())
  const [viewMode, setViewMode] = useState('MONTH')
  const [isOpen, setOpen] = useState(false)
  const displayFormat = format || (timePicker ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')

  const toggleDatePicker = () => {
    value && timePicker && setTime(value.format('HH:mm'))
    value === null && onChange(moment(`${moment().format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm'))
    value && setCurrentView(moment(value))
    setOpen(isOpen => !isOpen)
  }

  const onDayClick = day => {
    onChange(moment(`${day.format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm'))
  }

  const onTimeChange = newTime => {
    setTime(newTime)
    onChange(moment(`${value.format('DD/MM/YYYY')} ${newTime}`, 'DD/MM/YYYY HH:mm'))
  }

  const toggleView = () => {
    setViewMode(currentViewMode => currentViewMode === 'MONTH' ? 'YEAR' : 'MONTH')
  }

  const onMonthClick = month => {
    setCurrentView(moment(month))
    setViewMode('MONTH')
  }

  const onPrevMonth = () => {
    setCurrentView(moment(currentView).subtract(1, 'month'))
  }

  const onNextMonth = () => {
    setCurrentView(moment(currentView).add(1, 'month'))
  }

  const onPrevYear = () => {
    setCurrentView(moment(currentView).subtract(1, 'year'))
  }

  const onNextYear = () => {
    setCurrentView(moment(currentView).add(1, 'year'))
  }

  const renderView = () => (
    viewMode === 'MONTH' ? <MonthView value={value} onDayClick={onDayClick} onMonthClick={toggleView} currentMonth={currentView} onPrevMonth={onPrevMonth} onNextMonth={onNextMonth} />
    : <YearView value={value} onMonthClick={onMonthClick} onYearClick={toggleView} currentYear={currentView} onPrevYear={onPrevYear} onNextYear={onNextYear} />
  )

  const getPlaceholder = () => value ? value.format(displayFormat) : <span>{placeholder || displayFormat}</span>

  const handleOutsideClick = e => {
    node && node.current && !node.current.contains(e.target) && setOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    setViewMode('MONTH')
  }, [isOpen])

  return (
    <Manager>
      <FlexView
        flexDirection={inline ? 'row' : 'column'}
        alignItems={inline ? 'center' : 'flex-start'}
        justifyContent="flex-start"
        position="relative"
        margin={margin}
        width="fit-content"
        ref={node}
      >
        {label && <Label inline={inline}>{label}</Label>}
        <Reference>
          {({ ref }) => (
            <DateInput ref={ref} onClick={toggleDatePicker} label={label} error={error} data-cy={dataCy}>
              <Icon name="calendar" width="24px" height="24px" margin="0px 16px 0px 0px" />
              {getPlaceholder()}
            </DateInput>
          )}
        </Reference>
        <Popper
          placement="right"
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          {({ ref, style, scheduleUpdate, placement }) => <DatePickerPopper {...{ style, placement, isOpen, viewMode, renderView, value, timePicker, scheduleUpdate, time, onTimeChange, ref }} />}
        </Popper>
      </FlexView>
    </Manager>
  )
}

DatePicker.propTypes = {
  /**
   * Selected date value, a moment object
   */
  value: PropTypes.any,
  /**
   * Function that is called when the value is changed, being passed as parameter the selected date as a moment object
   */
  onChange: PropTypes.func,
  /**
   * Defines if the component should allow the time to be chosen. If not, defaults the moment object time to 00:00
   */
  timePicker: PropTypes.bool,
  /**
   * Format that the moment object should be displayed, defaults to 'DD/MM/YYYY' (or 'DD/MM/YYYY HH:mm' with timePicker) when null
   */
  format: PropTypes.string,
  /**
   * Label that accompanies the input
   */
  label: PropTypes.string,
  /**
   * Placeholder that appears inside the input before a date is selected
   */
  placeholder: PropTypes.string,
  /**
   * Defines if the label should be rendered in the same line as the input
   */
  inline: PropTypes.bool,
  /**
   * Override CSS margin property. Must be a valid CSS margin value as a string
   */
  margin: PropTypes.string,
  /**
   * Color the border to indicate an error state
   */
  error: PropTypes.bool,

  dataCy: PropTypes.string,
}

DatePicker.defaultProps = {
  width: 'fit-content'
}

export default DatePicker
