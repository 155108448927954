/** @format */

import axios from "axios"
import _ from "lodash"
import moment from "moment"

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/admin/logs`,
  headers: {
    "Content-Type": "application/json",
    "vlr-authorization": `${btoa(
      JSON.stringify({ id: process.env.REACT_APP_ID })
    )}`,
  },
})

const objectIdFromDate = (date) =>
  Math.floor(date.format("X")).toString(16) + "0000000000000000"

const dateFromObjectId = (objectId) =>
  moment(new Date(parseInt(objectId.substring(0, 8), 16) * 1000))

export default {
  getServices: (token) =>
    new Promise((resolve, reject) => {
      instance
        .get("/collections", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(new Error("CouldNotConnect"))
          }
        })
        .catch((err) => {
          reject(err.response || err)
        })
    }),
  getLogs: (service, startDate, endDate, filterType, filter, token) =>
    new Promise((resolve, reject) => {
      instance
        .get(`/${service}/20/filter`, {
          params: {
            f: filter.length ? filter : ".",
            tf: filterType === "all" ? "." : filterType,
            from: objectIdFromDate(startDate),
            to: objectIdFromDate(endDate),
          },
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              logs: _.map(response.data, (log) => ({
                ...log,
                datetime: dateFromObjectId(log.Id),
              })),
              allowNext: !response.headers["content-length"],
            })
          } else {
            reject(new Error("CouldNotConnect"))
          }
        })
        .catch((err) => {
          reject(err.response || err)
        })
    }),
  next: (service, startDate, filterType, filter, referenceId, token) =>
    new Promise((resolve, reject) => {
      instance
        .get(`/${service}/20/next/${referenceId}/filter`, {
          params: {
            f: filter.length ? filter : ".",
            tf: filterType === "all" ? "." : filterType,
            from: objectIdFromDate(startDate),
          },
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              logs: _.map(response.data, (log) => ({
                ...log,
                datetime: dateFromObjectId(log.Id),
              })),
              allowNext: !response.headers["content-length"],
            })
          } else {
            reject(new Error("CouldNotConnect"))
          }
        })
        .catch((err) => {
          reject(err.response || err)
        })
    }),
  prev: (service, endDate, filterType, filter, referenceId, token) =>
    new Promise((resolve, reject) => {
      instance
        .get(`/${service}/20/prev/${referenceId}/filter`, {
          params: {
            f: filter.length ? filter : ".",
            tf: filterType === "all" ? "." : filterType,
            to: objectIdFromDate(endDate),
          },
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              logs: _.map(response.data, (log) => ({
                ...log,
                datetime: dateFromObjectId(log.Id),
              })),
              allowNext: !response.headers["content-length"],
            })
          } else {
            reject(new Error("CouldNotConnect"))
          }
        })
        .catch((err) => {
          reject(err.response || err)
        })
    }),
}
