/** @format */

const formatCurrentQuantity = (data, periodicInfo) => {
	const selectedQuantity = periodicInfo.find(info => info.id === data.id)
	return selectedQuantity && selectedQuantity.to_date_qty
}

const formatGeneralPeriodicInfo = (list, periodicinfo) => {
	return list
		.map(info => {
			if (!(info.periodic || info.filters)) return null
			else
				return {
					id: info.id,
					erpRef: info.filters.erp_reference,
					material: info.filters.material_description,
					entranceid: info.filters.entrance,
					entrancedate: info.periodic.entrance_date,
					lastdate: info.periodic.last_date,
					nextdate: info.periodic.next_date,
					customerpo: info.filters.customer_order,
					customeritem: info.filters.customer_item,
					salesorder: info.filters.sales_order,
					salesitem: info.filters.sales_item,
					nextinspdate: info.periodic.next_date,
					pipeqty: formatCurrentQuantity(info, periodicinfo),
					contents: info.contents,
					type: info.type
				}
		})
		.sort((a, b) => b.id - a.id)
}

export default function generalPerInspFormatter(list, periodicInfo) {
	return formatGeneralPeriodicInfo(list, periodicInfo)
}
