/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react'
import Select from 'components/form/Select'
import FlexView from 'components/common/FlexView'
import { ContentContext } from 'apps/wms/stores/ContentStore'
import { useTranslation } from 'react-i18next'
import { Button, Card, Icon, LoadingOverlay } from 'components/common'
import _ from 'lodash'
import { useWindowSize } from 'apps/wms/utils/hooks'

const OwnershipFilter = () => {
	const {
		setFoundMaterial,
		setShowTable,
		subLevels,
		setSubLevels,
		firstLevel,
		setFirstLevel,
		getOwnershipBySearch,
		searchingByOwnership,
		setLastOwnership,
		lastOwnership,
		filteredLevels,
		setFilteredLevels
	} = useContext(ContentContext)
	const { t } = useTranslation()

	const listDeadSpace = 50
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const handleClearFilter = () => {
		setFoundMaterial(undefined)
		setShowTable(false)
		setLastOwnership(null)
		_.forEach(subLevels, lv => {
			lv.selectedOption = -1
			lv.selectedLevel = 0
		})
		setSubLevels([...subLevels])
		firstLevel.selectedOption = -1
		firstLevel.selectedLevel = 0
		setFilteredLevels([])
	}

	const handleSearchClick = () => {
		lastOwnership !== null && getOwnershipBySearch(lastOwnership)
	}

	const selectFirstLevel = value => {
		setFirstLevel({ ...firstLevel, selectedOption: value })
		setLastOwnership(value)
		let filteredOptions = []
		const level = subLevels.filter(lv => lv.parentLevel === firstLevel.level)
		_.forEach(level, lv => {
			filteredOptions.push({
				id: lv.id,
				description: lv.description,
				level: lv.level,
				options: _.filter(lv.options, opt => {
					let idx = opt.parentIds.indexOf(value)
					return (
						idx !== -1 && {
							id: opt.id,
							parentIds: opt.parentIds,
							label: opt.label
						}
					)
				}).concat({ id: -1, parentIds: [], label: 'All' }),
				parentLevel: lv.parentLevel,
				selectedLevel: lv.selectedLevel,
				selectedOption: lv.selectedOption
			})
		})
		setFilteredLevels(filteredOptions)
	}

	const selectLevel = (value, idx, levelId) => {
		let levelSelected = filteredLevels.find(lv => lv.id === levelId)
		const owner = subLevels.filter(lv => lv.parentLevel === levelSelected.level)
		let isDuplicatedLevel = owner && owner.length && _.some(filteredLevels, lv => lv.id === owner[0].id)
		filteredLevels[idx].selectedOption = value
		let filteredOwner = []
		owner &&
			owner.length &&
			_.forEach(owner, ow => {
				filteredOwner.push({
					id: ow.id,
					level: ow.level,
					description: ow.description,
					parentLevel: ow.parentLevel,
					selectedOption: ow.selectedOption,
					selectedLevel: ow.selectedLevel,
					options: _.filter(ow.options, opt => {
						let idx = opt.parentIds.indexOf(value)
						return (
							idx !== -1 && {
								id: opt.id,
								parentIds: opt.parentIds,
								label: opt.label
							}
						)
					}).concat({ id: -1, parentIds: [], label: 'All' })
				})
			})
		value === -1
			? (filteredLevels[idx].selectedLevel = filteredLevels[idx].id)
			: (filteredLevels[idx].selectedLevel = 0)
		value !== -1 && !isDuplicatedLevel && filteredLevels.length <= subLevels.length && owner.length > 0
			? setFilteredLevels([...filteredLevels, ...filteredOwner])
			: setFilteredLevels([...filteredLevels])
		value !== -1 && !isDuplicatedLevel && setLastOwnership(value)
	}

	const checkFields = () => {
		let isDisabled = true
		isDisabled = isDisabled && lastOwnership === null
		return isDisabled
	}

	return (
		<Card
			ref={listRef}
			width='calc(100% - 132px)'
			flex='1'
			margin='0 0 0 16px'
			padding='0'
			style={{ position: 'relative' }}
			height='100%'
			maxHeight='auto'
			data-cy='card-ownership-filter'>
			<FlexView
				width='100%'
				height={`${listHeight}px`}
				maxHeight='100%'
				flexDirection='column'
				style={{ overflowY: 'auto' }}>
				{firstLevel && Object.keys(firstLevel).length > 0 && (
					<Select
						width='90%'
						margin='8px'
						label={firstLevel.description}
						options={firstLevel.options.map(opt => ({
							value: opt.id,
							label: t(`wms:${opt.label}`)
						}))}
						placeholder={firstLevel.description}
						onChange={value => selectFirstLevel(value)}
						value={firstLevel.selectedOption}
						searchable
						data-cy='select-sublevels-ownership'
					/>
				)}
				{filteredLevels &&
					filteredLevels.length > 0 &&
					filteredLevels.map((level, index) => (
						<Select
							key={index}
							width='90%'
							margin='8px'
							label={level.description}
							options={level.options.map(opt => ({
								value: opt.id,
								label: t(`wms:${opt.label}`)
							}))}
							placeholder={level.description}
							onChange={value => selectLevel(value, index, level.id)}
							value={level.selectedOption}
							searchable
							data-cy='select-sublevels-ownership'
						/>
					))}
			</FlexView>
			<FlexView flexDirection='row' width='100%' margin='auto 0 8px 0' justifyContent='center'>
				<Button
					fontSize='small'
					margin='0px 6px 0px 0px'
					backgroundColor='error'
					color='white'
					width='115px'
					onClick={handleClearFilter}
					data-cy='button-clear-filter'>
					{t('wms:ClearFilter')}
					<Icon name='cross-simple' color='white' margin='3px 0px 0px 8px' width='12px' height='12px' />
				</Button>
				<Button
					fontSize='small'
					margin='0'
					backgroundColor='secondary'
					color='white'
					width='115px'
					onClick={() => handleSearchClick()}
					disabled={checkFields()}
					data-cy='button-search'>
					{t('wms:Search')}
					<Icon name='search' color='white' margin='3px 0px 0px 8px' width='12px' height='12px' />
				</Button>
				<LoadingOverlay visible={searchingByOwnership} borderRadius='card' />
			</FlexView>
		</Card>
	)
}

export default OwnershipFilter
