/** @format */

import { Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import Button from 'apps/wms/components/commom/Button'

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UpdateBatchListModal from './UpdateBatchListModal'
import { BatchEntranceContext } from 'apps/wms/stores/BatchEntranceStore'
import AddBatchListModal from './AddBatchListModal'
import DialogModal from '../../components/forms/DialogModal'
import _ from 'lodash'
import { STATUS } from '../../utils/enum'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'

const MaterialList = () => {
	const { t, i18n } = useTranslation()
	const {
		setExpectedQuantity,
		setUpdateMaterial,
		openAddModal,
		setOpenAddModal,
		deleteMatList,
		setDeleteMaterialList,
		openConfirmModal,
		setOpenConfirmModal,
		pageLoading,
		batchList,
		openUpdateModal,
		setOpenUpdateModal,
		selectedBatch,
		setSelectedBatch
	} = useContext(BatchEntranceContext)

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		selectedBatch && selectedBatch.id && handleSelect(selectedBatch.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [batchList])

	const handleSelect = id => {
		id && setSelectedBatch(_.find(batchList, batch => batch.id === id))
	}

	const closeAddModal = () => {
		setOpenAddModal(false)
	}

	const onOutsideClick = () => {
		setOpenUpdateModal(false)
	}

	const handleUpdate = id => {
		if (id !== null) {
			handleSelect(id)
			setOpenUpdateModal(true)
			setUpdateMaterial(true)
			setExpectedQuantity(0)
		}
	}

	const handleDelete = id => {
		if (id !== null) {
			handleSelect(id)
			setDeleteMaterialList(true)
			setOpenConfirmModal(true)
		}
	}

	const checkContentStatus = useCallback(batch => {
		let isDisabled = false
		isDisabled = !!_.find(batch.content, c => c.status !== STATUS.code.NOTSTARTED)
		return isDisabled
	}, [])

	return (
		<Card
			width='calc(100% - 48px)'
			flex='1'
			margin='0'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='card-material-list'>
			<LoadingOverlay visible={pageLoading} />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:EntranceList')}
			</FlexView>
			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				{batchList && batchList.length > 0 ? (
					_.map(batchList, (batch, index) => (
						<FlexView
							key={batch.id}
							backgroundColor={selectedBatch && selectedBatch.id === batch.id ? 'offWhite' : 'default'}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							onClick={() => handleSelect(batch.id)}
							data-cy='list-item'>
							<FlexView flex='1' fontSize='small' margin='auto 0'>
								{`${batch.id} (${DATE_FORMAT(batch.expected, i18n.language)}) ${batch.name}`}
							</FlexView>
							<Button
								color='secondary'
								backgroundColor='white'
								hoverBackgroundColor='secondary'
								hoverColor='white'
								fontSize='tiny'
								margin='auto 8px auto 0'
								onClick={() => handleUpdate(batch.id)}>
								<Icon
									name='refresh'
									height='12px'
									width='12px'
									color='secondary'
									margin='0px 8px 0px 0'
									fontWeight='bold'
								/>
								{t('wms:Update')}
							</Button>
							<Button
								backgroundColor='error'
								color='white'
								onClick={() => handleDelete(batch.id)}
								disabled={checkContentStatus(batch)}
								fontSize='tiny'
								margin='auto 0'>
								<Icon
									name='trash'
									width='12px'
									height='12px'
									color='white'
									margin='0px 8px 0px 0px'
									fontWeight='bold'
								/>
								{t('wms:Delete')}
							</Button>
						</FlexView>
					))
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						data-cy='no-batch-list'>
						{t('wms:NoEntranceList')}
					</FlexView>
				)}
			</FlexView>
			<Button
				backgroundColor='secondary'
				color='white'
				margin='auto 0 0 auto'
				style={{ alignSelf: 'flex-end' }}
				fontSize='small'
				onClick={() => setOpenAddModal(true)}
				data-cy='button-add-batch-list'>
				{t('wms:Add')}
				<Icon name='add' width='16px' height='16px' color='white' margin='0px 0px 0px 8px' fontWeight='bold' />
			</Button>
			<DialogModal
				isOpen={openConfirmModal}
				text={t('wms:ConfirmEntranceListDeletion')}
				onConfirm={deleteMatList}
				onCancel={() => setOpenConfirmModal(false)}
			/>
			<UpdateBatchListModal
				isOpen={openUpdateModal}
				setOpenUpdateModal={setOpenUpdateModal}
				onOutsideClick={onOutsideClick}
			/>
			<AddBatchListModal isOpen={openAddModal} onOutsideClick={closeAddModal} />
		</Card>
	)
}

export default MaterialList
