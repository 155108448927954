/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getInventories: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inventory/${wid}/report`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response.data.inventories)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	loadInventory: (wid, irid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inventory/${wid}/report/${irid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	loadGhosts: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`bundles/${wid}/ghosts`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	exportInventory: (wid, invId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inventory/${wid}/export/${invId}`, {
					headers: {
						Authorization: token
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	exportGhost: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`inventory/${wid}/export/notread/`, {
					headers: {
						Authorization: token
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		})
}
