import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StatusContext } from 'apps/admin/stores/StatusStore'
import { Modal, Card, FlexView, LoadingOverlay } from 'components/common'
import CpuChart from './CpuChart'
import MemoryChart from './MemoryChart'
import DiskChart from './DiskChart'
import { CSSTransition } from 'react-transition-group'
import DowntimeChart from './DowntimeChart'

const ChartsModal = ({ isOpen, onOutsideClick }) => {
  const { t } = useTranslation()
  const { app } = useContext(StatusContext)
  const [show, setShow] = useState(false)

  useEffect(() => {
    (app && app.data) ? setShow(true) : setShow(false)
  }, [app])

  const renderData = () => {
    return app && app.data ? <CSSTransition
      in={show}
      timeout={800}
      classNames="slow-fade"
      unmountOnExit
    >
      <FlexView flexDirection="row" flexWrap="wrap">
        <FlexView flex="1 320px" flexDirection="row" margin="8px" style={{ overflow: 'hidden' }}>
          <CpuChart time={app.data.time} cpu={app.data.cpu} />
        </FlexView>
        <FlexView flex="1 320px" flexDirection="row" margin="8px" style={{ overflow: 'hidden' }}>
          <MemoryChart time={app.data.time} mem={app.data.mem} />
        </FlexView>
        <FlexView flex="1 320px" flexDirection="row" margin="8px" style={{ overflow: 'hidden' }}>
          <DiskChart time={app.data.time} disk={app.data.disk} />
        </FlexView>
        <FlexView flex="1 320px" flexDirection="row" margin="8px" style={{ overflow: 'hidden' }}>
          <DowntimeChart time={app.data.time} up={app.data.up} />
        </FlexView>
      </FlexView>
    </CSSTransition> : <FlexView alignSelf="center" justifyContent="center" alignItems="center" margin="40px" fontSize="big" color="gray">{t('admin:status.NoDataFound')}</FlexView>
  }

  return <Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
    <Card width="800px" position="relative">
      <LoadingOverlay visible={app.loading} borderRadius="card" />
      <FlexView fontSize="subtitle" fontWeight="bold" margin="0px 0px 16px">{app.name}</FlexView>
      {renderData()}
    </Card>
  </Modal>
}

export default ChartsModal