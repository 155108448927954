/** @format */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Card from 'components/common/Card'
import FlexView from 'components/common/FlexView'
import Button from 'components/common/Button'
import { useTranslation } from 'react-i18next'
import { ThreadCompoundContext } from 'apps/wms/stores/AssetsThreadCompoundStore'
import { toast } from 'react-toastify'
import DraggableItem from './DraggableItem'
import { useDrop } from 'react-dnd'
import { useWindowSize } from 'apps/wms/utils/hooks'

const ThreadCompoundTypesList = () => {
	const { t } = useTranslation()
	const {
		typesList,
		setTypesList,
		deleteType,
		updateTypesList,
		reorderList,
		removeItemFromList,
		setIsTypeModalOpen,
		moveCardHandler
	} = useContext(ThreadCompoundContext)

	const listDeadSpace = 100
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const [, drop] = useDrop({
		accept: 'thread compound type',
		drop: () => ({ name: 'Some name' })
	})

	const moveHandler = (dragIndex, hoverIndex) => moveCardHandler(dragIndex, hoverIndex, typesList, setTypesList)

	const _onRemoveType = async (type, tList, dType, rList, removeIList, uTList, t) => {
		try {
			await dType(type.typeid)
			const list = rList(removeIList(tList, type.typeid))
			await uTList(list)
		} catch (error) {
			console.error(error)
			toast.error(t(`${t('wms:RemoveThreadCompoundTypeError')}. ${error.data}`))
		}
	}

	const onRemoveType = useCallback(
		async typeData =>
			_onRemoveType(typeData, typesList, deleteType, reorderList, removeItemFromList, updateTypesList, t),
		[typesList, deleteType, reorderList, removeItemFromList, updateTypesList, t]
	)

	const _onUpdateListOrder = async (list, rList, uTList, t) => {
		try {
			const newList = rList(list)
			await uTList(newList)
		} catch (error) {
			console.error(error)
			toast.error(t(`${t('wms:ErrorUpdatingThreadCompoundTypeOrder')}. ${error.data}`))
		}
	}

	const onUpdateListOrder = useCallback(
		async () => _onUpdateListOrder(typesList, reorderList, updateTypesList, t),
		[typesList, reorderList, updateTypesList, t]
	)

	return (
		<FlexView
			ref={listRef}
			flex='1'
			height='100%'
			maxWidth='450px'
			margin='0 16px 0 0'
			style={{ alignItems: 'stretch' }}>
			<Card
				ref={drop}
				margin='0 0 16px 0'
				width='calc(100% - 48px)'
				data-cy='types-list-card'
				height={`${listHeight}px`}
				style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				<FlexView width='100%' flexDirection='row' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
					{t('wms:ThreadCompoundTypes')}
					<Button
						fontSize='tiny'
						margin='auto 0 auto auto'
						padding='8px 16px'
						color='white'
						backgroundColor='secondary'
						disabled={false}
						onClick={() => setIsTypeModalOpen(true)}>
						{`${t('wms:New')} ${t('wms:Type')}`}
					</Button>
				</FlexView>
				{typesList.map((type, index) => (
					<DraggableItem
						key={type.typeid}
						index={index}
						id={type.typeid}
						desc={type.typedesc}
						state={type.state}
						data={type}
						onRemove={onRemoveType}
						moveHandler={moveHandler}
					/>
				))}
			</Card>
			<Button
				margin='0 0 0 auto'
				padding='8px 16px'
				color='white'
				backgroundColor='secondary'
				onClick={onUpdateListOrder}>
				{`${t('wms:Save')} ${t('wms:Order')}`}
			</Button>
		</FlexView>
	)
}

export default ThreadCompoundTypesList
