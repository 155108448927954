/** @format */

import { BatchEntranceContext } from 'apps/wms/stores/BatchEntranceStore'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import { DatePicker, Input, Select } from 'components/form'
import _ from 'lodash'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import BatchEntranceFilter from './BatchEntranceFilter'

// eslint-disable-next-line react/prop-types
const AddBatchListModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const {
		sourceType,
		setSourceType,
		pageLoading,
		expectedQuantity,
		newContent,
		setNewContent,
		batchListName,
		setBatchListName,
		expectedDate,
		setExpectedDate,
		setSaveBatchList,
		endUser,
		materialDesc,
		customerOrder,
		customerItem,
		salesOrder,
		salesItem,
		setEndUser,
		setExpectedQuantity,
		levels,
		ownership,
		setOwnership
	} = useContext(BatchEntranceContext)

	const sources = [
		{
			value: 'CUSTOMER_ITEM',
			label: t('wms:CustomerOrderAndItem')
		},
		{
			value: 'SALES_ITEM',
			label: t('wms:SalesOrderAndItem')
		}
	]

	const handleAddToBatchList = () => {
		let content = { quantity: expectedQuantity }
		setNewContent(oldContent => [...oldContent, content])
	}

	const handleReset = () => {
		setEndUser(null)
		setExpectedQuantity(0)
		setNewContent([])
	}

	const handleSave = () => {
		setSaveBatchList(true)
	}

	const handleContentRemoval = index => {
		newContent.splice(index, 1)
		setNewContent([...newContent])
	}

	const inputMessage = () => {
		if (batchListName.length <= 30) return t('wms:MaxCharacter')
		else return t('wms:MaxCharacterNotAllowed')
	}

	const inputMessageType = () => {
		if (batchListName.length <= 30) return 'info'
		else return 'error'
	}

	const handleDateChange = v => {
		if (v._d <= new Date()) return toast.error(t('wms:FutureExpectDate'))
		else return setExpectedDate(v)
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='90%' height='90%' position='relative' data-cy='card-add-batch-list'>
				<LoadingOverlay visible={pageLoading} borderRadius='16px' />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 8px 0'
					justifyContent='space-between'>
					{t('wms:NewEntrance')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' disabled={false} onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView maxWidth='40%' minWidth='40%' minHeight='100%' maxHeight='100%' margin='0 16px 0 0'>
						<Card
							margin='0 0 10px 0'
							minWidth='calc(100% - 48px)'
							maxWidth='calc(100% - 48px)'
							data-cy='card-type-source'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 8px 0'>
								{t('wms:TypeOfSource')}
							</FlexView>
							<Select
								placeholder={t('wms:SelectOption')}
								value={sourceType}
								options={sources}
								onChange={value => setSourceType(value)}
								width='100%'
								margin='0'
								dataCy='select-source-type'></Select>
						</Card>
						<Card
							margin='0 0 4px 0'
							flex='1'
							minWidth='calc(100% - 48px)'
							maxWidth='calc(100% - 48px)'
							data-cy='card-batch-entrance-filter'>
							<BatchEntranceFilter />
							<FlexView flexDirection='row' width='100%' justifyContent='space-between' margin='auto 0 0 0'>
								<Button
									fontSize='medium'
									margin='0'
									color='white'
									backgroundColor='warning'
									onClick={() => {
										handleReset()
									}}
									disabled={!(newContent.length !== 0)}
									data-cy='button-reset-batch'>
									<Icon name='refresh' color='white' height='16px' width='16px' margin='0 8px 0 0' />
									{t('wms:Restart')}
								</Button>
								<Button
									fontSize='medium'
									margin='auto 0 0 auto'
									color='white'
									backgroundColor='secondary'
									disabled={expectedQuantity <= 0 || expectedQuantity.length >= 6}
									onClick={() => {
										handleAddToBatchList()
									}}
									data-cy='button-add-part'>
									{t('wms:AddPart')}
								</Button>
							</FlexView>
						</Card>
					</FlexView>
					<FlexView
						maxWidth='calc(60% - 16px)'
						minWidth='calc(60% - 16px)'
						minHeight='100%'
						maxHeight='100%'
						margin='0'>
						<Card
							margin='0 0 4px 0'
							width='calc(100% - 48px)'
							minHeight='inherit'
							flex='1'
							data-cy='card-batch-info'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 8px'>
								{t('wms:EntranceInfo')}
							</FlexView>
							<FlexView
								flexDirection='row'
								width='100%'
								flexWrap='wrap'
								justifyContent='flex-start'
								alignItems='center'>
								<Input
									message={inputMessage()}
									messageType={inputMessageType()}
									info
									type='text'
									label={t('wms:Name')}
									placeholder={t('wms:Name')}
									value={batchListName}
									onChange={e => setBatchListName(e.target.value)}
									margin='8px 0 0 8px'
									minWidth='50%'
									max='30'
									data-cy='input-batch-list-name'
								/>
								<DatePicker
									timePicker
									label={t('wms:ExpectedDate')}
									value={expectedDate}
									onChange={value => handleDateChange(value)}
									margin='8px 0 0 8px'
									dataCy='date-picker-expected-date'
								/>
								{levels && levels.length > 0 && (
									<Select
										width='30%'
										label={`${t('wms:Ownership')}`}
										options={_.map(levels, owner => ({
											...owner,
											value: owner.id,
											label: owner.label
										}))}
										placeholder={t('wms:Ownership')}
										value={ownership}
										onChange={v => setOwnership(v)}
										margin='8px 0 0 8px'
										searchable
										dataCy='select-ownership'
									/>
								)}
							</FlexView>
							{newContent.length !== 0 ? (
								<FlexView flexDirection='row' margin='8px 0' flexWrap='wrap' justifyContent='space-between'>
									<FlexView width='30%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:Enduser')}
										</FlexView>
										{endUser && endUser.name}
									</FlexView>
									{sourceType === 'CUSTOMER_ITEM' ? (
										<>
											<FlexView width='40%' margin='0 0 8px 0' padding='0' flexDirection='column'>
												<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
													{t('wms:CustomerOrder')}
												</FlexView>
												{customerOrder}
											</FlexView>
											<FlexView width='20%' margin='0 0 8px 0' padding='0' flexDirection='column'>
												<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
													{t('wms:CustomerItem')}
												</FlexView>
												{customerItem}
											</FlexView>
										</>
									) : null}
									{sourceType === 'SALES_ITEM' ? (
										<>
											<FlexView width='40%' margin='0 0 8px 0' padding='0' flexDirection='column'>
												<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
													{t('wms:SalesOrder')}
												</FlexView>
												{salesOrder}
											</FlexView>
											<FlexView width='20%' margin='0 0 8px 0' padding='0' flexDirection='column'>
												<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
													{t('wms:SalesItem')}
												</FlexView>
												{salesItem}
											</FlexView>
										</>
									) : null}
									<FlexView width='100%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:Material')}
										</FlexView>
										{materialDesc}
									</FlexView>
								</FlexView>
							) : null}

							{sourceType !== null ? (
								<FlexView width='100%' height='250px' style={{ overflowY: 'scroll', overflowX: 'auto' }}>
									{newContent && newContent.length ? (
										newContent.map((content, index) => (
											<FlexView
												key={index}
												width='100%'
												margin='0'
												flexDirection='row'
												padding='8px 0'
												style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
												<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
													<strong>{`${t('wms:Part')} ${index + 1}`}</strong>
												</FlexView>
												<FlexView flex='1' fontSize='small' margin='auto 0' flexDirection='row'>
													{`${t('wms:ExpectedQuantity')}: ${content.quantity}`}
												</FlexView>
												<Button
													fontSize='small'
													margin='auto 0 auto auto'
													padding='8px 16px'
													color='white'
													backgroundColor='error'
													disabled={false}
													onClick={() => {
														handleContentRemoval(index)
													}}
													data-cy='button-remove-content'>
													<Icon
														name='trash'
														color='white'
														height='16px'
														width='16px'
														margin='0'
														tooltip={`${t('wms:RemovePart')}`}
													/>
												</Button>
											</FlexView>
										))
									) : (
										<></>
									)}
								</FlexView>
							) : null}
						</Card>
					</FlexView>
				</FlexView>
				<FlexView width='100%' flexDirection='row' margin='4px 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0 0 0 auto'
						color='white'
						backgroundColor='success'
						disabled={
							!(
								newContent.length > 0 &&
								batchListName &&
								batchListName.length <= 30 &&
								expectedDate &&
								expectedDate._d >= new Date() &&
								ownership
							)
						}
						onClick={() => handleSave()}
						data-cy='button-save-batch-list'>
						{t('wms:Save')}
						<Icon name='save' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default AddBatchListModal
