/** @format */

import axios from "axios"

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/admin/users`,
  headers: {
    "Content-Type": "application/json",
    "vlr-authorization": `${btoa(
      JSON.stringify({ id: process.env.REACT_APP_ID })
    )}`,
  },
})

export default {
  getGroups: (token) =>
    new Promise((resolve, reject) => {
      instance
        .get("/groups", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(new Error("CouldNotConnect"))
          }
        })
        .catch((err) => {
          reject(err.response || err)
        })
    }),
  getUsers: (groupID, token) =>
    new Promise((resolve, reject) => {
      var url = groupID === null ? "/users" : "/users?groupid=" + groupID
      instance
        .get(url, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(new Error("CouldNotConnect"))
          }
        })
        .catch((err) => {
          reject(err.response || err)
        })
    }),
  createNewUser: (user, token) =>
    new Promise((resolve, reject) => {
      instance
        .post(`/users`, user, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(new Error("CouldNotConnect"))
          }
        })
        .catch((err) => {
          reject(err.response || err)
        })
    }),
  editUser: (user, token) =>
    new Promise((resolve, reject) => {
      instance
        .put(`/users`, user, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve()
          } else {
            reject(new Error("CouldNotConnect"))
          }
        })
        .catch((err) => {
          reject(err.response || err)
        })
    }),
  addUserToGroup: (idUser, idGroup, token) =>
    new Promise((resolve, reject) => {
      instance
        .post(
          `/usergroup`,
          { Idgroup: idGroup, Iduser: idUser },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(new Error("CouldNotConnect"))
          }
        })
        .catch((err) => {
          reject(err.response || err)
        })
    }),
  removeUserFromGroup: (idUser, idGroup, token) =>
    new Promise((resolve, reject) => {
      instance
        .delete(`/usergroup`, {
          data: { Idgroup: idGroup, Iduser: idUser },
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(new Error("CouldNotConnect"))
          }
        })
        .catch((err) => {
          reject(err.response || err)
        })
    }),
}
