/** @format */

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import dispatch from '../api/dispatch'
import common from '../api/common'

import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'
import usageRights from '../api/usageRights'
import _ from 'lodash'

export const DispatchContext = React.createContext()

export const DispatchProvider = ({ children }) => {
	const { warehouse, lastLevelsList, getContentByOwnership, getEnduserList, getMaterialsDescpritions, getLevelsData } =
		useContext(WMSContext)
	const { token, user } = useContext(UserContext)
	const { t } = useTranslation()
	const [dispatchList, setDispatchList] = useState([])
	const [materialList, setMaterialList] = useState([])
	const [materialsByEU, setMaterialsByEU] = useState([])
	const [vehiclesList, setVehiclesList] = useState([])
	const [endUserList, setEndUserList] = useState([])
	const [ownershipList, setOwnershipList] = useState([])
	const [loadingDispatchList, setLoadingDispatchList] = useState(false)
	const [selectedDispatch, setSelectedDispatch] = useState(null)
	const [newDispatchModal, setNewDispatchModal] = useState(false)
	const [dispatchModal, setDispatchModal] = useState(false)
	const [updateDispatchList, setUpdateDispatchList] = useState(false)
	const [loadingDispatchInfo, setLoadingDispatchInfo] = useState(false)
	const [loadingLevels, setLoadingLevels] = useState(false)
	const [pipeData, setPipeData] = useState(null)
	const [openConfirmModal, setConfirmModal] = useState(false)
	const [selectAll, setSelectAll] = useState(false)
	const [disabledButton, setDisabledButton] = useState(false)
	const warehouseNotDefined = t('wms:WarehouseNotDefined')

	const getDispatchList = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			setLoadingDispatchList(true)
			dispatch
				.getDispatchList(warehouse.id, token)
				.then(dispatchList => resolve(dispatchList))
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingDispatchingLists')} [${e.status}]: ${e.data}`)
					reject(e)
				})
				.finally(() => setLoadingDispatchList(false))
		})

	const getDispatchInfo = dispatchId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error(warehouseNotDefined))
			}
			setLoadingDispatchInfo(true)
			dispatch
				.getDispatchInfo(warehouse.id, dispatchId, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingDispatchListContent')} [${e.status}]: ${e.data}`)
					reject(e)
				})
				.finally(() => setLoadingDispatchInfo(false))
		})

	const deleteDispatch = dispatchId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error(warehouseNotDefined))
			}
			dispatchId !== null &&
				dispatch
					.deleteDispatch(warehouse.id, dispatchId, token)
					.then(() => {
						toast.success(t(`wms:DispatchRemoved`))
						resolve(true)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorDeletingDispatchList')} [${e.status}]: ${e.data}`)
						reject(e)
					})
		})

	const getVehicles = () =>
		new Promise((resolve, reject) => {
			dispatch
				.getVehiclesList(warehouse.id, token)
				.then(vehicles => vehicles.length && resolve(vehicles))
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingDispatchingLists')} [${e.status}]: ${e.data}`)
					reject(e)
				})
				.finally(() => setLoadingDispatchList(false))
		})

	const getContent = sourceOwnership =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error(warehouseNotDefined))
			} else
				sourceOwnership &&
					getContentByOwnership(sourceOwnership, false)
						.then(contentList => resolve(contentList))
						.catch(e => {
							toast.error(`${t('wms:ErrorGettingContent')} [${e.status}]: ${e.data}`)
							reject(e)
						})
		})

	const getPipeData = valid =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				//toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error(warehouseNotDefined))
			} else
				valid &&
					common
						.getPipeData(warehouse.id, valid, undefined, token)
						.then(pipeData => resolve(pipeData))
						.catch(e => {
							toast.error(`${t('wms:ErrorGettingPipeData')} [${e.status}]: ${e.data}`)
							reject(e)
						})
		})

	const _getOwnershipByEnduser = (wid, enduser, token, t) =>
		new Promise((resolve, reject) => {
			setLoadingLevels(true)
			usageRights
				.getOwnershipsListByEndUser(wid, enduser, token)
				.then(response => {
					const ownerDesc = response.ownershipdescriptions
					const levelList = ownerDesc.map(res => ({
						id: res.id,
						label: res.namepath.join('..')
					}))
					const ownerships = _.uniqBy(levelList, 'id')
					resolve(ownerships)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingOwnerships')} [ ${e.status} ]: ${e.data}`)
					reject(e)
					setLoadingLevels(false)
				})
				.finally(() => setLoadingLevels(false))
		})

	const getOwnershipByEnduser = useCallback(
		enduser => _getOwnershipByEnduser(warehouse.id, enduser, token, t),
		[warehouse, token, t]
	)

	const _getMaterialByEndUser = (wid, enduser, token, t) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
				console.error(warehouseNotDefined)
			} else {
				dispatch
					.getMaterialsByEU(wid, enduser, token)
					.then(endUserList => {
						resolve(endUserList)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingMatListEndUser')} [ ${e.status} ]: ${e.data}`)
					})
			}
		})

	const getMaterialByEndUser = useCallback(
		enduser => _getMaterialByEndUser(warehouse.id, enduser, token, t),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, t, token]
	)

	const newDispatch = dispatchData =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error(warehouseNotDefined))
			}
			let payload = {
				expecteddate: dispatchData.expecteddate,
				workorderdescription: dispatchData.work_order_description,
				vehicledata: { ...dispatchData.vehicle, properties: undefined },
				vehicleid: dispatchData.vehicleid,
				occupationfactor: dispatchData.occupationfactor ? dispatchData.occupationfactor : 0,
				contents: dispatchData.content
			}

			dispatch
				.newDispatch(warehouse.id, payload, token)
				.then(() => {
					toast.success(t(`wms:DispatchCreated`))
					resolve(true)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorCreatingDispatchingLists')} [${e.status}]: ${e.data}`)
					reject(e)
				})
		})

	const executeDispatch = dispatchData =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error(warehouseNotDefined))
			}

			let items = dispatchData.bundles.filter(b => b.checked)
			const contents = dispatchData.contents.filter(c => c.checked >= 1)

			let payload = {
				responsible: `${user.firstName} ${user.lastName}`,
				dispatchlistid: dispatchData.id,
				contents: contents.map(c => ({
					dispatchlistid: dispatchData.id,
					materialid: c.material.id,
					levelid: c.level.id,
					valids: items.filter(i => i.mid === c.material.id && i.levelid === c.level.id).map(i => i.valid)
				}))
			}

			dispatch
				.executeDispatch(warehouse.id, payload, token)
				.then(() => {
					toast.success(t(`wms:DispatchSuccessful`))
					resolve(true)
					setConfirmModal(false)
				})
				.catch(e => {
					let errMsg =
						e.data && e.data.validlist && e.data.validlist.length > 0 ? e.data.validlist[0].errors[0] : e.data
					toast.error(`${t('wms:ErrorDispatching')} [${e.status}]: ${errMsg}`)
					reject(e)
				})
				.finally(() => {
					setConfirmModal(false)
				})
		})

	useEffect(() => {
		getDispatchList()
			.then(DispatchList => setDispatchList(DispatchList))
			.catch(e => console.error(e))
		getVehicles()
			.then(vehicles => setVehiclesList(vehicles))
			.catch(e => console.error(e))
		getMaterialsDescpritions()
			.then(materialsList => setMaterialList(materialsList))
			.catch(e => console.error(e))
		getEnduserList()
			.then(endUsers => setEndUserList(endUsers))
			.catch(e => console.error(e))
		getLevelsData()
			.then(ownerships => setOwnershipList(ownerships))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		getDispatchList()
			.then(DispatchList => DispatchList.length && setDispatchList(DispatchList.sort((a, b) => a.id - b.id)))
			.catch(e => console.error(e))
		setUpdateDispatchList(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateDispatchList])

	useEffect(() => {
		selectedDispatch &&
			selectedDispatch.contents === undefined &&
			getDispatchInfo(selectedDispatch.id)
				.then(dispatchData => {
					if (dispatchData.contents) {
						dispatchData.displayitems = dispatchData.contents
							.filter(c => !!c.level)
							.map(c =>
								c.bundles
									.map(b =>
										b.items
											.map(i => ({
												...i,
												checked: false,
												levelid: c.level.id,
												levelfullname: c.level.fullname,
												mid: c.material.id,
												ownershipid: c.ownership.id,
												enduserid: c.enduser.id
											}))
											.flat()
									)
									.flat()
							)
							.flat()
					}
					setSelectedDispatch({
						...selectedDispatch,
						contents: dispatchData.contents,
						bundles: dispatchData.displayitems
					})
				})
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDispatch])

	return (
		<DispatchContext.Provider
			value={{
				dispatchList,
				loadingDispatchList,
				selectedDispatch,
				setSelectedDispatch,
				deleteDispatch,
				setUpdateDispatchList,
				materialList,
				lastLevelsList,
				loadingDispatchInfo,
				setLoadingDispatchInfo,
				vehiclesList,
				newDispatchModal,
				setNewDispatchModal,
				dispatchModal,
				setDispatchModal,
				getContent,
				newDispatch,
				executeDispatch,
				pipeData,
				setPipeData,
				getPipeData,
				endUserList,
				setEndUserList,
				getOwnershipByEnduser,
				loadingLevels,
				setLoadingLevels,
				setMaterialList,
				getMaterialByEndUser,
				materialsByEU,
				setMaterialsByEU,
				openConfirmModal,
				setConfirmModal,
				ownershipList,
				selectAll,
				setSelectAll,
				disabledButton,
				setDisabledButton
			}}>
			{children}
		</DispatchContext.Provider>
	)
}
