import React from 'react'
import FlexView from './FlexView'
import PropTypes from 'prop-types'

/**
 * Card component that extends FlexView
 */
const Card = React.forwardRef((props, ref) => <FlexView ref={ref} {...props} />)

Card.propTypes = {
  /**
   * A color key defined in the theme
   */
  backgroundColor: PropTypes.string,
  /**
   * A gradient key defined in the theme
   */
  backgroundGradient: PropTypes.string,
  /**
   * A color key defined in the theme
   */
  color: PropTypes.string,
  /**
   * Override default border radius with CSS compliant value or a border key in the theme. Examples: card, button, 8px, 1rem, 40%, 6vw
   */
  borderRadius: PropTypes.string,
 /**
   * Override CSS width property. Must be a valid CSS width value as a string
   */
  width: PropTypes.string,
  /**
  * Override CSS min-width property. Must be a valid CSS min-width value as a string
  */
  minWidth: PropTypes.string,
  /**
   * Override CSS max-width property. Must be a valid CSS max-width value as a string
   */
  maxWidth: PropTypes.string,
  /**
   * Override CSS height property. Must be a valid CSS height value as a string
   */
  height: PropTypes.string,
  /**
  * Override CSS min-height property. Must be a valid CSS min-height value as a string
  */
  minHeight: PropTypes.string,
  /**
   * Override CSS max-height property. Must be a valid CSS max-height value as a string
   */
  maxHeight: PropTypes.string,
  /**
   * Override CSS margin property. Must be a valid CSS margin value as a string
   */
  margin: PropTypes.string,
  /**
   * Override CSS padding property. Must be a valid CSS padding value as a string
   */
  padding: PropTypes.string,
  /**
   * Override CSS flex property. Must be a valid CSS flex value as a string
   */
  flex: PropTypes.string,
  /**
   * Override CSS flex-wrap property. Must be a valid CSS flex-wrap value as a string
   */
  flexWrap: PropTypes.string,
  /**
   * Override CSS flex-direction property. Must be a valid CSS flex-direction value as a string
   */
  flexDirection: PropTypes.string,
  /**
   * Override CSS align-items property. Must be a valid CSS align-items value as a string
   */
  alignItems: PropTypes.string,
  /**
   * Override CSS align-self property. Must be a valid CSS align-items value as a string
   */
  alignSelf: PropTypes.string,
  /**
   * Override CSS justify-content property. Must be a valid CSS justify-content value as a string
   */
  justifyContent: PropTypes.string,
  /**
   * A font size key defined in the theme
   */
  fontSize: PropTypes.string,
  /**
   * Override CSS font-weight property. Must be a valid CSS font-weight value as a string
   */
  fontWeight: PropTypes.string,
  /**
   * An elevation key defined in the theme
   */
  elevation: PropTypes.string
}

Card.defaultProps = {
  backgroundColor: 'white',
  borderRadius: 'card',
  padding: '16px 24px',
  margin: '8px',
  elevation: 'low'
}

export default Card