/** @format */

import React from "react"
import Status from "./pages/Status"
import Logs from "./pages/Logs"
import Users from "./pages/Users"
import PrivateRoute from "containers/router/PrivateRoute"
import { StatusProvider } from "./stores/StatusStore"
import { LogsProvider } from "./stores/LogsStore"
import { UsersProvider } from "./stores/UsersStore"
import "./i18n"

export const getSideMenu = (user, redirectTo, translate) => [
  {
    key: "admin",
    label: translate("admin:Admin"),
    roles: ["ADMINISTRATORS"],
    items: [
      {
        key: "admin-status",
        icon: "info",
        label: "Status",
        onClick: redirectTo("/admin/status"),
      },
      {
        key: "admin-logs",
        icon: "calendar",
        label: "Logs",
        onClick: redirectTo("/admin/logs"),
      },
      {
        key: "admin-user",
        icon: "user",
        label: "Users",
        onClick: redirectTo("/admin/users"),
      },
    ],
  },
]

const Router = () => {
  return (
    <>
      <PrivateRoute roles={["ADMINISTRATORS"]} path="/admin">
        <PrivateRoute path="/admin/status">
          <StatusProvider>
            <Status />
          </StatusProvider>
        </PrivateRoute>
        <PrivateRoute path="/admin/logs">
          <LogsProvider>
            <Logs />
          </LogsProvider>
        </PrivateRoute>
        <PrivateRoute path="/admin/users">
          <UsersProvider>
            <Users />
          </UsersProvider>
        </PrivateRoute>
      </PrivateRoute>
    </>
  )
}

export default Router
