/** @format */

import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { AssetsTransportContext } from 'apps/wms/stores/AssetsTransportStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import AddVehicleModal from './AddVehicleModal'
import UpdateVehicleModal from './UpdateVehicleModal'
import DialogModal from 'apps/wms/components/forms/DialogModal'

const VehicleList = () => {
	const { t } = useTranslation()
	const {
		vehicles,
		selectedVehicle,
		setSelectedVehicle,
		loadingVehicles,
		setOpenUpdateModal,
		openConfirmModal,
		setOpenConfirmModal,
		openAddModal,
		setOpenAddModal,
		openUpdateModal,
		deleteVehicle
	} = useContext(AssetsTransportContext)

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		selectedVehicle && selectedVehicle.id && handleSelect(selectedVehicle.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vehicles])

	const handleSelect = id => {
		id && setSelectedVehicle(_.find(vehicles, v => v.id === id))
	}

	const handleUpdate = id => {
		if (id !== null) {
			handleSelect(id)
			setOpenUpdateModal(true)
		}
	}

	const handleDelete = id => {
		if (id !== null) {
			handleSelect(id)
			setOpenConfirmModal(true)
		}
	}

	const closeModal = () => {
		setOpenAddModal(false)
	}

	const closeUpdateModal = () => {
		setOpenUpdateModal(false)
	}

	return (
		<Card
			width='calc(100% - 48px)'
			flex='1'
			margin='0'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='card-vehicle-list'>
			<LoadingOverlay visible={loadingVehicles} borderRadius='card' />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:Transport')}
			</FlexView>
			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				{vehicles && vehicles.length > 0 ? (
					_.map(vehicles, (v, index) => (
						<FlexView
							key={v.id}
							backgroundColor={selectedVehicle && selectedVehicle.id === v.id ? 'offWhite' : 'default'}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							onClick={() => handleSelect(v.id)}
							data-cy='list-vehicle'>
							<FlexView flex='1' fontSize='small' margin='auto 0'>
								{`${v.name}`}
							</FlexView>
							<Button
								backgroundColor='secondary'
								color='white'
								fontSize='tiny'
								margin='auto 8px auto 0'
								onClick={() => handleUpdate(v.id)}
								data-cy='button-update-vehicle'>
								{t('wms:Update')}
								<Icon
									name='refresh'
									width='16px'
									height='16px'
									color='white'
									margin='0px 0px 0px 8px'
									fontWeight='bold'
								/>
							</Button>
							<Button
								backgroundColor='error'
								color='white'
								onClick={() => handleDelete(v.id)}
								fontSize='tiny'
								margin='auto 0'
								data-cy='button-delete-vehicle'>
								{t('wms:Delete')}
								<Icon
									name='trash'
									width='16px'
									height='16px'
									color='white'
									margin='0px 0px 0px 8px'
									fontWeight='bold'
								/>
							</Button>
						</FlexView>
					))
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						data-cy='no-vehicle-list'>
						{t('wms:NoTransport')}
					</FlexView>
				)}
			</FlexView>
			<Button
				backgroundColor='secondary'
				color='white'
				margin='auto 0 0 auto'
				style={{ alignSelf: 'flex-end' }}
				fontSize='small'
				onClick={() => setOpenAddModal(true)}
				data-cy='button-add-vehicle'>
				{`${t('wms:Add')} ${t('wms:Transport')}`}
				<Icon name='add' width='16px' height='16px' color='white' margin='0px 0px 0px 8px' fontWeight='bold' />
			</Button>
			<DialogModal
				isOpen={openConfirmModal}
				text={t('wms:ConfirmTransportDeletion')}
				onConfirm={deleteVehicle}
				onCancel={() => setOpenConfirmModal(false)}
			/>
			<AddVehicleModal isOpen={openAddModal} onOutsideClick={closeModal} />
			<UpdateVehicleModal isOpen={openUpdateModal} onOutsideClick={closeUpdateModal} vehicle={selectedVehicle} />
		</Card>
	)
}

export default VehicleList
