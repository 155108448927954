import React, { useState, useContext } from 'react'
import { UserContext } from 'stores/UserStore'
import status from '../api/status'

const initialState = {
  host: {
    time: [],
    memfree: [],
    memtotal: []
  },
  apps: null,
  formData: null,
  app: {
    name: '',
    data: null,
    loading: false
  }
}

export const StatusContext = React.createContext(initialState)

export const StatusProvider = ({ children }) => {
  const [host, setHost] = useState(initialState.host)
  const [apps, setApps] = useState(initialState.apps)
  const [formData, setFormData] = useState(initialState.formData)
  const [app, setApp] = useState(initialState.app)
  const { token } = useContext(UserContext)

  const getReports = (startDate, endDate, interval) => new Promise((resolve, reject) => {
    setFormData({
      startDate,
      endDate,
      interval
    })
    status.getReports(startDate, endDate, interval, token).then(data => {
      setHost(data.host)
      setApps(data.apps)
      resolve()
    }).catch(error => {
      reject(error)
    })
  })

  const getAppStatus = (appName) => new Promise((resolve, reject) => {
    setApp({
      name: appName,
      data: null,
      loading: true
    })
    const { startDate, endDate, interval } = formData
    status.getAppStatus(startDate, endDate, interval, appName, token).then(data => {
      setApp({
        name: appName,
        data,
        loading: false,
      })
      resolve()
    }).catch(error => {
      reject(error)
    })
  })

  const executeCommand = url => new Promise((resolve, reject) => {
    status.executeCommand(url, token).then(() => {
      const { startDate, endDate, interval } = formData
      status.getReports(startDate, endDate, interval, token).then(data => {
        setHost(data.host)
        setApps(data.apps)
        resolve()
      }).catch(error => {
        reject(error)
      })
    }).catch(error => {
      reject(error)
    })
  })

  return <StatusContext.Provider value={{
    host,
    apps,
    formData,
    app,
    getReports,
    getAppStatus,
    executeCommand
  }}>
    {children}
  </StatusContext.Provider>
}