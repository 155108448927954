/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { UserContext } from 'stores/UserStore'
import { WMSContext } from './WmsStore'
import assetsRefReports from '../api/assetsReportRef'

export const AssetsReportRefContext = React.createContext()

export const AssetsReportRefProvider = ({ children }) => {
	const { t } = useTranslation()

	const { warehouse } = useContext(WMSContext)
	const { token, user } = useContext(UserContext)
	const [loadingReports, setLoadingReports] = useState(false)
	const [uploadedFile, setUploadedFile] = useState([])
	const [imgBase64, setImgBase64] = useState('')
	const [pageLoading, setPageLoading] = useState(false)
	const [currentLogo, setCurrentLogo] = useState('')

	// WMS Report Refs
	const [reports, setReports] = useState(null)
	const [selectedReport, setSelectedReport] = useState(null)
	const [updateReport, setUpdateReport] = useState(null)
	const [reference, setReference] = useState('')
	const [update, setUpdate] = useState(false)
	const [openUpdateModal, setOpenUpdateModal] = useState(false)
	const [openUnitSystem, setOpenUnitSystem] = useState(false)

	// Inspection Report Refs
	const [inspectionReports, setInspectionReports] = useState([])
	const [inspectionReportTypes, setInspectionReportTypes] = useState([])
	const [warehouseUnits, setWarehouseUnits] = useState({})
	const [reportLengthUnit, setReportLengthUnit] = useState(null)
	const [reportWeightUnit, setReportWeightUnit] = useState(null)
	const [reportDiameterUnit, setReportDiameterUnit] = useState(null)
	const [selectedInspectionReport, setSelectedInspectionReport] = useState(null)
	const [inspectionReportFormData, setInspectionReportFormData] = useState(null)
	const [openUpdateInspectionModal, setOpenUpdateInspectionModal] = useState(false)
	const [isInspectionReportUpdate, setIsInspectionReportUpdate] = useState(false)
	const [isUpdatingInspectionReportData, setIsUpdatingInspectionReportData] = useState(false)
	const [isUpdatingInspectionReportList, setIsUpdatingInspectionReportList] = useState(false)
	const [savingUnits, setSavingUnits] = useState(false)
	const referenceReportUpdated = t('wms:ReferenceReportUpdated')
	const errorUpdatingReference = t('wms:ErrorUpdatingReference')

	const getReports = () =>
		new Promise((resolve, reject) => {
			assetsRefReports
				.getReports(warehouse.id, token)
				.then(response => {
					setReports(response.list)
					resolve()
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorGettingReports')} [ ${e.status} ]: ${e.data}`)
					console.error(e)
				})
		})

	const updateRefReport = () =>
		new Promise((resolve, reject) => {
			let payload = {
				reporttype: updateReport.reporttype,
				reportreference: reference,
				responsible: `${user.firstName} ${user.lastName}`
			}
			assetsRefReports
				.updateReport(warehouse.id, payload, token)
				.then(response => {
					resolve(response)
					toast.success(referenceReportUpdated)
					setUpdateReport(null)
					setOpenUpdateModal(false)
					setReference('')
					setSelectedReport(null)
					getReports()
				})
				.catch(e => {
					console.error(e)
					toast.error(`${errorUpdatingReference} [ ${e.status} ]: ${e.data}`)
					reject(e)
					setUpdateReport(null)
					setOpenUpdateModal(false)
					setReference('')
					setSelectedReport(null)
				})
		})

	const getInspectionReports = () =>
		new Promise((resolve, reject) => {
			setIsUpdatingInspectionReportList(true)
			assetsRefReports
				.getInspectionReports(warehouse.id, token)
				.then(response => {
					setInspectionReports(response)
					resolve()
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorGettingInspReports')} [ ${e.status} ]: ${e.data}`)
					console.error(e)
				})
				.finally(() => setIsUpdatingInspectionReportList(false))
		})

	const getInspectionReportTypes = () =>
		new Promise((resolve, reject) => {
			assetsRefReports
				.getInspectionReportTypes(token)
				.then(response => {
					setInspectionReportTypes(response)
					resolve()
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorGettingInspReportTypes')} [ ${e.status} ]: ${e.data}`)
					console.error(e)
				})
		})

	const createInspectionReportReference = () =>
		new Promise((resolve, reject) => {
			setIsUpdatingInspectionReportData(true)
			const payload = getInspectionReportReferencePayload()
			assetsRefReports
				.createInspectionReport(warehouse.id, payload, token)
				.then(response => {
					getInspectionReports()
					toast.success(t('wms:ReferenceReportCreated'))
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					toast.error(`${t('wms:ErrorCreatingReference')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => {
					setIsUpdatingInspectionReportData(false)
					setOpenUpdateInspectionModal(false)
				})
		})

	const updateInspectionReportReference = () =>
		new Promise((resolve, reject) => {
			setIsUpdatingInspectionReportData(true)
			const payload = getInspectionReportReferencePayload()
			assetsRefReports
				.updateInspectionReport(warehouse.id, payload, token)
				.then(response => {
					getInspectionReports()
					toast.success(referenceReportUpdated)
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					toast.error(`${errorUpdatingReference} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => {
					setIsUpdatingInspectionReportData(false)
					setOpenUpdateInspectionModal(false)
				})
		})

	const getInspectionReportReferencePayload = () => {
		const { report_reference_type, config_type_id, reference } = inspectionReportFormData
		return {
			report_type: report_reference_type,
			config_type_id,
			reference,
			responsible: `${user.firstName} ${user.lastName}`
		}
	}

	const disableInspectionReportReference = reportReference =>
		new Promise((resolve, reject) => {
			const payload = getInspectionReportTogglePayload(reportReference)
			assetsRefReports
				.disableInspectionReport(warehouse.id, payload, token)
				.then(response => {
					toast.success(referenceReportUpdated)
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					toast.error(`${errorUpdatingReference} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => setInspectionReportFormData(null))
		})

	const enableInspectionReportReference = reportReference =>
		new Promise((resolve, reject) => {
			const payload = getInspectionReportTogglePayload(reportReference)
			assetsRefReports
				.enableInspectionReport(warehouse.id, payload, token)
				.then(response => {
					toast.success(referenceReportUpdated)
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					toast.error(`${errorUpdatingReference} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => setInspectionReportFormData(null))
		})

	const getInspectionReportTogglePayload = reportReference => ({
		report_type: reportReference.report_reference_type,
		config_type_id: reportReference.config_type_id,
		reference: reportReference.reference,
		responsible: `${user.firstName} ${user.lastName}`
	})

	const getAvailableUnits = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(t('wms:WarehouseNotDefined')))
			} else {
				assetsRefReports
					.getUnits(warehouse.id, token)
					.then(response => {
						setReportLengthUnit(response.length_unit || 'Ft')
						setReportWeightUnit(response.weight_unit || 'Pd')
						setReportDiameterUnit(response.diameter_unit || 'In')
						setWarehouseUnits(response || {})
						resolve()
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorGettingUnits')} [ ${e.status} ]: ${e.data}`)
					})
			}
		})

	const saveReportUnits = () =>
		new Promise((resolve, reject) => {
			setSavingUnits(true)
			const payload =
				warehouseUnits && warehouseUnits.id
					? {
							id: warehouseUnits.id,
							length_unit: reportLengthUnit,
							weight_unit: reportWeightUnit,
							diameter_unit: reportDiameterUnit,
							warehouse: warehouse.id
					  }
					: {
							length_unit: reportLengthUnit,
							weight_unit: reportWeightUnit,
							diameter_unit: reportDiameterUnit,
							warehouse: warehouse.id
					  }
			warehouseUnits && warehouseUnits.id
				? assetsRefReports
						.updateWarehouseUnits(warehouse.id, payload, token)
						.then(response => {
							resolve(response)
							toast.success(t('wms:WarehouseUnitsUpdated'))
							setOpenUnitSystem(false)
						})
						.catch(e => {
							console.error(e)
							toast.error(`${t('wms:ErrorUpdatingWarehouseUnits')} [ ${e.status} ]: ${e.data}`)
							reject(e)
							setSavingUnits(false)
						})
						.finally(() => setSavingUnits(false))
				: assetsRefReports
						.createWarehouseUnits(warehouse.id, payload, token)
						.then(response => {
							resolve(response)
							toast.success(t('wms:WarehouseUnitsCreated'))
							setOpenUnitSystem(false)
						})
						.catch(e => {
							console.error(e)
							toast.error(`${t('wms:ErrorCreatingWarehouseUnits')} [ ${e.status} ]: ${e.data}`)
							reject(e)
							setSavingUnits(false)
							setOpenUnitSystem(false)
						})
						.finally(() => setSavingUnits(false))
		})

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getAvailableUnits()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingReports(true)
				await Promise.allSettled([getReports(), getInspectionReportTypes(), getInspectionReports()])
			} catch (e) {
				setLoadingReports(false)
				console.error(e)
			} finally {
				setLoadingReports(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (update) {
			const hydrate = async () => {
				try {
					await updateRefReport()
				} catch (e) {
					console.error(e)
					setUpdate(false)
				} finally {
					setUpdate(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [update])

	useEffect(() => {
		const rep = _.find(reports, r => {
			return r.reporttype === selectedReport.reporttype
		})
		openUpdateModal && setUpdateReport(rep)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openUpdateModal])

	useEffect(() => {
		if (!openUpdateInspectionModal) setInspectionReportFormData(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openUpdateInspectionModal])

	useEffect(() => {
		if (selectedInspectionReport)
			setSelectedInspectionReport(_.find(inspectionReports, ir => ir.id === selectedInspectionReport.id))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspectionReports])

	const onLogoUpload = async () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				toast.error(t(`wms:WarehouseNotDefined`))
				reject(new Error('wms:WarehouseNotDefined'))
			} else
				assetsRefReports
					.uploadWarehouseLogo(
						warehouse.id,
						{
							data: imgBase64
						},
						token
					)
					.then(list => {
						getCurrentWarehouseLogo()
						toast.success(`${t('wms:LogoUploadSuccessful') + '!'}`)
						resolve(list)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorUploadingLogo') + '!'}`)
						reject(e)
					})
					.finally(() => {
						setPageLoading(false)
					})
		})

	const getCurrentWarehouseLogo = async () =>
		new Promise((resolve, reject) => {
			assetsRefReports
				.getCurrentWarehouseLogo(warehouse.id, token)
				.then(response => {
					setCurrentLogo(response)
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorGettingLogo')} [ ${e.status}]: ${e.data}`)
					console.error(e)
				})
		})

	useEffect(() => {
		getCurrentWarehouseLogo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setSelectedReport(null)
		setSelectedInspectionReport(null)
	}, [warehouse])

	return (
		<AssetsReportRefContext.Provider
			value={{
				reports,
				setReports,
				reference,
				setReference,
				loadingReports,
				setLoadingReports,
				selectedReport,
				setSelectedReport,
				openUpdateModal,
				setOpenUpdateModal,
				update,
				setUpdate,
				updateRefReport,
				uploadedFile,
				setUploadedFile,
				onLogoUpload,
				imgBase64,
				setImgBase64,
				pageLoading,
				setPageLoading,
				currentLogo,
				inspectionReports,
				inspectionReportTypes,
				selectedInspectionReport,
				setSelectedInspectionReport,
				openUpdateInspectionModal,
				setOpenUpdateInspectionModal,
				inspectionReportFormData,
				setInspectionReportFormData,
				createInspectionReportReference,
				updateInspectionReportReference,
				isInspectionReportUpdate,
				setIsInspectionReportUpdate,
				isUpdatingInspectionReportData,
				isUpdatingInspectionReportList,
				disableInspectionReportReference,
				enableInspectionReportReference,
				getInspectionReports,
				warehouseUnits,
				setWarehouseUnits,
				reportLengthUnit,
				setReportLengthUnit,
				reportWeightUnit,
				setReportWeightUnit,
				reportDiameterUnit,
				setReportDiameterUnit,
				saveReportUnits,
				savingUnits,
				setSavingUnits,
				openUnitSystem,
				setOpenUnitSystem
			}}>
			{children}
		</AssetsReportRefContext.Provider>
	)
}
