import Mminch from 'mminch-64'

const decimalHouses = 3
const en_US = 'en-US'
const separator = ' - '

const mmToMeter = (value) => {
  const factor = 1000
  return (Number(value) / factor).toLocaleString(en_US, {minimumFractionDigits: decimalHouses})
}

const mmToInch = (value) => {
  const inch = Mminch(value.toString()).toInch()
  return inch
}

const mmToInchDecimalHouse = (value) => {
  const factor = 25.4
  const result = (Number(value) / factor).toLocaleString(en_US, {minimumFractionDigits: decimalHouses})
  return result
}

export const convertLength = (value, showUnit, formats) => {
  let result = ''
  if (!formats) {
    formats = ['mm']
  }
  if (value && value.toString().trim() !== '') {

    for (let i = 0; i < formats.length; i++) {
      const format = formats[i]
      value = value + ''
      const values = value.split('-')

      for (let j = 0; j < values.length; j++){
        const converted = choose(format, showUnit, values[j])

        if (result === '') {
          result = converted
        } else {
          result += separator + converted 
        }
      }

      if (i + 1 < formats.length) {
        result = result + ' | ' 
      }
    }
  }
  if (result === '') {
    result = value
  }
  return result
}

const choose = (format, showUnit, value) => {
  let converted
  let unit = (showUnit) ?  ' ' + format : ''

  switch (format) {
  case 'm':
    converted = mmToMeter(value)
    break

  case 'id':
    unit = (showUnit) ?  ' \'\'' : ''
    converted = mmToInchDecimalHouse(value)
    break

  case 'in':
    unit = (showUnit) ?  ' \'\'' : ''
    converted = mmToInch(value)
    break

  case 'mm':
    converted = value
    break

  default:
    converted = value
    unit = (showUnit) ?  ' mm' : ''
    break
  }
  return converted + unit
}
