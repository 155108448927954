/** @format */

import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { FlexView, Button, Icon, Spinner } from 'components/common'
import theme from 'utils/theme'
import Toggle from '../../../components/commom/Toggle'
import { DATE_FORMAT } from '../../../utils/dateformat'
import { AssetsReportRefContext } from '../../../stores/AssetsReportRefStore'

const getAccentBorder = () => ({ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' })

const InspectionReportRefDetails = ({ reportRefType }) => {
	const { t, i18n } = useTranslation()
	const {
		selectedInspectionReport,
		inspectionReportTypes,
		setIsInspectionReportUpdate,
		setOpenUpdateInspectionModal,
		setInspectionReportFormData,
		disableInspectionReportReference,
		enableInspectionReportReference,
		getInspectionReports
	} = useContext(AssetsReportRefContext)
	const [isTogglingReportRef, setIsTogglingReportRef] = useState(false)

	const getReportReferenceByType = typeId =>
		_.find(selectedInspectionReport.report_references, rr => rr.report_reference_type === typeId)

	const getReportTypeName = typeId => {
		const reportType = _.find(inspectionReportTypes, t => t.id === typeId)
		if (reportType) return reportType.name
		return 'N/A'
	}

	const openFormModal = (isUpdate = false, reportData = null) => {
		setIsInspectionReportUpdate(isUpdate)
		setInspectionReportFormData(reportData)
		setOpenUpdateInspectionModal(true)
	}

	const handleToggleReportRef = async reportRef => {
		setIsTogglingReportRef(true)
		try {
			if (reportRef.enabled) await disableInspectionReportReference(reportRef)
			else await enableInspectionReportReference(reportRef)
			getInspectionReports()
		} finally {
			setIsTogglingReportRef(false)
		}
	}

	const reportRef = getReportReferenceByType(reportRefType)

	if (!reportRef) {
		return (
			<FlexView width='100%' flexDirection='col' flexWrap='wrap' position='relative'>
				<FlexView width='100%' fontWeight='bold' fontSize='small' color='secondary' margin='0 0 12px 0'>
					{getReportTypeName(reportRefType)}
				</FlexView>
				<Button
					backgroundColor='secondary'
					color='white'
					fontSize='small'
					margin='0'
					title={t('wms:InspectionReportRefsCreateNew')}
					data-cy='button-create-report-ref'
					onClick={() =>
						openFormModal(false, {
							report_reference_type: reportRefType,
							config_type_id: selectedInspectionReport.id
						})
					}>
					{t('wms:Create')}
					<Icon name='add' width='12px' height='12px' color='white' margin='0 0 0 8px' fontWeight='bold' />
				</Button>
			</FlexView>
		)
	}

	return (
		<FlexView width='100%' position='relative'>
			<FlexView flexDirection='col' flexWrap='wrap' width='100%' margin='0 0 16px 0'>
				<FlexView width='70%' flexDirection='col' flexWrap='wrap'>
					<FlexView width='100%' fontWeight='bold' fontSize='small' color='secondary' margin='0 0 12px 0'>
						{getReportTypeName(reportRefType)}
					</FlexView>
					<FlexView width='100%' flexDirection='col' flexWrap='wrap'>
						<FlexView width='50%' padding='0' flexDirection='column'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:Reference')}
							</FlexView>
							<FlexView width='100%' style={getAccentBorder()} padding='0 0 0 8px'>
								{reportRef.reference ? reportRef.reference : '****'}
							</FlexView>
						</FlexView>
						<FlexView width='50%' padding='0' flexDirection='column'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:Responsible')}
							</FlexView>
							<FlexView width='100%' style={getAccentBorder()} padding='0 0 0 8px'>
								{reportRef.responsible ? reportRef.responsible : '****'}
							</FlexView>
						</FlexView>
					</FlexView>
				</FlexView>
				<FlexView width='30%' flexDirection='row' alignItems='center' justifyContent='flex-end'>
					{isTogglingReportRef && (
						<FlexView margin='0 12px 0 0'>
							<Spinner size='small' color='primary' />
						</FlexView>
					)}
					<Toggle
						margin='0'
						alternate
						state={reportRef.enabled}
						textOff={t('wms:Disabled')}
						textOn={t('wms:Enabled')}
						onChange={() => handleToggleReportRef(reportRef)}
						disabled={isTogglingReportRef}
						data-cy='report-ref-enabled-toggle'
					/>
				</FlexView>
			</FlexView>
			<FlexView flexDirection='col' flexWrap='wrap' width='100%' alignItems='flex-end' margin='0 0 16px 0'>
				<FlexView width='70%' flexDirection='col' flexWrap='wrap'>
					<FlexView width='100%' flexDirection='col' flexWrap='wrap'>
						<FlexView width='50%' padding='0' flexDirection='column'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:CreationDate')}
							</FlexView>
							<FlexView width='100%' style={getAccentBorder()} padding='0 0 0 8px'>
								{reportRef.created_at ? DATE_FORMAT(reportRef.created_at, i18n.language) : '****'}
							</FlexView>
						</FlexView>
						<FlexView width='50%' padding='0' flexDirection='column'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:UpdatedOn')}
							</FlexView>
							<FlexView width='100%' style={getAccentBorder()} padding='0 0 0 8px'>
								{reportRef.modified_at ? DATE_FORMAT(reportRef.modified_at, i18n.language) : '****'}
							</FlexView>
						</FlexView>
					</FlexView>
				</FlexView>
				<FlexView width='30%' alignItems='flex-end'>
					<Button
						backgroundColor='secondary'
						color='white'
						fontSize='small'
						margin='0'
						title={t('wms:UpdateReferenceReport')}
						data-cy='button-update-report-ref'
						onClick={() => openFormModal(true, reportRef)}
						disabled={isTogglingReportRef}>
						{t('wms:Edit')}
						<Icon name='edit' width='12px' height='12px' color='white' margin='0 0 0 8px' fontWeight='bold' />
					</Button>
				</FlexView>
			</FlexView>
		</FlexView>
	)
}

InspectionReportRefDetails.propTypes = {
	/**
	 * The ID of the report reference type
	 */
	reportRefType: PropTypes.string
}

export default InspectionReportRefDetails
