/** @format */

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay, Icon } from 'components/common'
import Table from '../../components/reports/ExpandedTable'
import { REPORT_STATUS } from '../../utils/enum'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { isReceiptNoteReport } from '../reportEditor/businessRules'
import { formatReceiptNotes } from 'apps/wms/utils/reports/receiptNotesFormatter'
import { ProgressColumn } from 'apps/wms/utils/reports/tableColumnsFormatter'

const ReviewReportList = ({
	loadingList,
	list = [],
	reviewType,
	onSelectReport,
	onCheckReport,
	onDownloadReport,
	height,
	downloadingReport
}) => {
	const { t, i18n } = useTranslation()
	const [selectedRow, setSelectedRow] = useState({})
	const [selectedSubRow, setSelectedSubRow] = useState({})
	const [dataList, setDataList] = useState([])

	useEffect(() => {
		setDataList(isReceiptNoteReport(reviewType, false) ? formatReceiptNotes(list) : list)
	}, [list, reviewType])

	const handleDownloadReport = (reportId, partNumber) => {
		reportId != null && onDownloadReport(reportId, partNumber)
	}

	const handleCheckReport = (reportId, partNumber) => {
		reportId != null && onCheckReport(reportId, partNumber)
	}

	const lists = () => (isReceiptNoteReport(reviewType, false) ? millReceiptColumns : ageColumns)
	const dataCy = () => (isReceiptNoteReport(reviewType, false) ? 'list-reports-mill' : 'list-reports-age')

	const selectRow = row => {
		if (row.depth > 0) {
			setSelectedSubRow({
				listid: row.original.id,
				id: row.id,
				depth: row.depth,
				partid: row.original.partid,
				part: row.original.part,
				closuredate: row.original.closuredate
			})
			setSelectedRow({})
			onSelectReport(Number(row.original.id || row.original.listid), row.original.part)
		} else {
			setSelectedRow({
				listid: row.original.listid,
				id: row.id,
				depth: row.depth
			})
			setSelectedSubRow({})
			onSelectReport(Number(row.original.id || row.original.listid))
		}
	}

	const renderRowExpand = row => {
		return (
			<FlexView
				flexDirection='row'
				alignItems='center'
				justifyContent='center'
				width='10px'
				fontSize='14px'
				margin='0'
				padding='0'
				style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
				{...row.getToggleRowExpandedProps({
					style: {
						paddingLeft: `${row.depth * 32}px`,
						cursor: row.canExpand ? 'pointer' : 'inherit'
					},
					title: ''
				})}>
				{row.canExpand && (
					<Icon
						name={row.isExpanded ? 'chevron-up' : 'chevron-down'}
						width='12px'
						height='12px'
						color={
							selectedRow && selectedRow.id === row.id
								? 'white'
								: selectedRow && selectedRow.id === row.id && row.isExpanded
								? 'white'
								: 'gray'
						}
					/>
				)}
			</FlexView>
		)
	}

	const ageColumns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:ThresholdDate'),
				accessor: 'extractiondate',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Status'),
				accessor: 'status',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0'
						padding='0'
						fontSize='16px'
						fontWeight='bold'
						color={REPORT_STATUS.color[value]}>
						{t(`${REPORT_STATUS.translateKey[value]}`)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Action'),
				width: 'auto',
				Cell: ({ cell: { row } }) => (
					<>
						{row.original.status === REPORT_STATUS.code.VALIDATED ? (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0'
								color='white'
								backgroundColor='primary'
								disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
								elevation='none'
								onClick={() => {
									handleDownloadReport(row.original.id)
								}}
								data-cy='button-download-report'>
								{t('wms:DownloadReport')}
							</Button>
						) : null}
						{row.original.status === REPORT_STATUS.code.PENDING ||
						row.original.status === REPORT_STATUS.code.REJECTED ? (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0'
								color='white'
								backgroundColor='secondary'
								onClick={() => {
									handleCheckReport(row.original.id)
								}}
								data-cy='button-check-report'>
								{t('wms:CheckReport')}
							</Button>
						) : null}
						<LoadingOverlay visible={downloadingReport} />
					</>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, handleDownloadReport, reviewType, t]
	)

	const millReceiptColumns = useMemo(
		() => [
			{
				id: 'expander',
				Header: ' ',
				width: '10px',
				Cell: ({ cell: { row } }) => renderRowExpand(row)
			},
			{
				Header: 'ID',
				accessor: 'listid',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Parts'),
				accessor: 'part',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Name'),
				accessor: 'name',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:EntranceDate'),
				accessor: 'closuredate',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{DATE_FORMAT(value, i18n.language)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Status'),
				accessor: 'status',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0'
						padding='0'
						fontSize='16px'
						fontWeight='bold'
						color={REPORT_STATUS.color[value]}>
						{t(`${REPORT_STATUS.translateKey[value]}`)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Qty'),
				accessor: 'quantitylabel',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:Progress'),
				width: '130px',
				Cell: ({ cell: { row } }) => (
					<ProgressColumn
						row={row}
						handleDownloadReport={handleDownloadReport}
						handleCheckReport={handleCheckReport}
					/>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dataList, handleDownloadReport, reviewType, t]
	)

	return (
		<Card
			data-cy='card-list-wrapper'
			width='calc(100% - 48px)'
			flex='1'
			margin='0'
			style={{ position: 'relative', overflowY: 'auto' }}>
			<LoadingOverlay visible={loadingList} borderRadius='card' />
			{list && list.length && lists() ? (
				<Table
					margin='0'
					width='100%'
					textAlign='center'
					elevation='none'
					height={height}
					fontSize='14px'
					columns={lists()}
					data={dataList}
					sortable
					gridless
					data-cy={dataCy()}
					selectedRow={selectedRow}
					selectedSubRow={selectedSubRow}
					onRowClick={selectRow}
				/>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='placeholder-reports-list'>
					{t('wms:NoReport')}
				</FlexView>
			)}
		</Card>
	)
}

export default ReviewReportList
