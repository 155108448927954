import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  withFlex,
  withWidth,
  withMinWidth,
  withMaxWidth,
  withHeight,
  withMinHeight,
  withMaxHeight,
  withMargin,
  withPadding,
  withBorderRadius,
  withPosition,
  withFontSize,
  withFontWeight,
  withBackgroundColor,
  withBackgroundGradient,
  withColor,
  withElevation
} from 'utils/styled-decorators'


/**
 * General use View component that implements the flex layout system
 */
const FlexView = styled.div`
  ${withFlex()}
  ${withWidth()}
  ${withMinWidth()}
  ${withMaxWidth()}
  ${withHeight()}
  ${withMinHeight()}
  ${withMaxHeight()}
  ${withMargin()}
  ${withPadding()}
  ${withFontSize()}
  ${withFontWeight()}
  ${withColor()}
  ${withPosition()}
  ${withBackgroundColor()}
  ${withBackgroundGradient()}
  ${withBorderRadius()}
  ${withElevation()}
  cursor: default;
   /* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

  /* total width */
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {display:none}
`

FlexView.propTypes = {
  /**
   * A color key defined in the theme
   */
  backgroundColor: PropTypes.string,
  /**
   * A gradient key defined in the theme
   */
  backgroundGradient: PropTypes.string,
  /**
   * A color key defined in the theme
   */
  color: PropTypes.string,
  /**
   * Override default border radius with CSS compliant value or a border key in the theme. Examples: card, button, 8px, 1rem, 40%, 6vw
   */
  borderRadius: PropTypes.string,
  /**
   * Override CSS width property. Must be a valid CSS width value as a string
   */
  width: PropTypes.string,
  /**
  * Override CSS min-width property. Must be a valid CSS min-width value as a string
  */
  minWidth: PropTypes.string,
  /**
   * Override CSS max-width property. Must be a valid CSS max-width value as a string
   */
  maxWidth: PropTypes.string,
  /**
   * Override CSS height property. Must be a valid CSS height value as a string
   */
  height: PropTypes.string,
  /**
  * Override CSS min-height property. Must be a valid CSS min-height value as a string
  */
  minHeight: PropTypes.string,
  /**
   * Override CSS max-height property. Must be a valid CSS max-height value as a string
   */
  maxHeight: PropTypes.string,
  /**
   * Override CSS margin property. Must be a valid CSS margin value as a string
   */
  margin: PropTypes.string,
  /**
   * Override CSS padding property. Must be a valid CSS padding value as a string
   */
  padding: PropTypes.string,
  /**
   * Override CSS flex property. Must be a valid CSS flex value as a string
   */
  flex: PropTypes.string,
  /**
   * Override CSS flex-wrap property. Must be a valid CSS flex-wrap value as a string
   */
  flexWrap: PropTypes.string,
  /**
   * Override CSS flex-direction property. Must be a valid CSS flex-direction value as a string
   */
  flexDirection: PropTypes.string,
  /**
   * Override CSS align-items property. Must be a valid CSS align-items value as a string
   */
  alignItems: PropTypes.string,
  /**
   * Override CSS justify-content property. Must be a valid CSS justify-content value as a string
   */
  justifyContent: PropTypes.string,
  /**
   * A font size key defined in the theme
   */
  fontSize: PropTypes.string,
  /**
   * Override CSS font-weight property. Must be a valid CSS font-weight value as a string
   */
  fontWeight: PropTypes.string,
  /**
   * An elevation key defined in the theme
   */
  elevation: PropTypes.string
}

export default FlexView
