/** @format */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import Checkbox from 'apps/wms/components/forms/Checkbox'
import { useTranslation } from 'react-i18next'
import { AssetsInspSettingsContext } from 'apps/wms/stores/AssetsInspSettingsStore'
import { toast } from 'react-toastify'
import theme from 'utils/theme'
import { INSPECTION_STAGES } from '../../../utils/enum'

const InspTypeCardConfig = () => {
	const { t } = useTranslation()

	const {
		setSelectedInspection,
		selectedInspection,
		updateInspectionType,
		measureConfig,
		newMeasurementConfig,
		triggerReload
	} = useContext(AssetsInspSettingsContext)

	const [isLoadingResponse, setIsLoadingResponse] = useState(false)
	const [inEditionData, setInEdtitionData] = useState(null)

	const availableProps = useMemo(
		() => [
			{ name: 'WorkOrderDescription', enabled: true },
			{ name: 'FieldName', enabled: false },
			{ name: 'RigName', enabled: false },
			{ name: 'WellName', enabled: false },
			{ name: 'TpartyName', enabled: false },
			{ name: 'TpartyLocation', enabled: false },
			{ name: 'LtpaNumber', enabled: false },
			{ name: 'DeliveryLocation', enabled: false },
			{ name: 'AttachedCustomerRequest', enable: false }
		],
		[]
	)

	useEffect(() => {
		selectedInspection
			? setInEdtitionData({
					...selectedInspection,
					isMeasureEnabled: selectedInspection.config_measurement !== 0,
					config_measurement: measureConfig.find(m => m.id === selectedInspection.config_measurement),
					config_summary:
						selectedInspection.config_summary && selectedInspection.config_summary.length > 1
							? selectedInspection.config_summary
							: availableProps.map(p => ({
									name: p.name,
									enabled:
										selectedInspection && selectedInspection.config_summary
											? !!selectedInspection.config_summary.find(c => c.name === p.name)
											: false
							  }))
			  })
			: setInEdtitionData(null)
	}, [selectedInspection, availableProps, measureConfig])

	const toggleSummary = name => {
		const summIndex = inEditionData.config_summary.findIndex(summ => summ.name === name)
		if (summIndex === -1) return

		let config = [...inEditionData.config_summary]
		config[summIndex].enabled = !config[summIndex].enabled

		const newData = {
			...inEditionData,
			config_summary: [...config]
		}

		setInEdtitionData(newData)
	}

	const onUpdateInspectionType = async () => {
		// Check if thread compound and config measurement have boxes checked
		if (
			(inEditionData.thread_compound_method_required === false &&
				inEditionData.thread_compound_type_required === false &&
				inEditionData.has_thread_compound === true) ||
			(inEditionData.config_measurement !== undefined &&
				inEditionData.config_measurement.length === false &&
				inEditionData.config_measurement.int_diameter === false &&
				inEditionData.config_measurement.out_diameter === false &&
				inEditionData.config_measurement.wall_thickness === false &&
				inEditionData.config_measurement.weight === false)
		) {
			toast.error(t('wms:InspectionSaveCanceled'))
			return
		}
		try {
			setIsLoadingResponse(true)
			// Check if config measurement is defined
			if (inEditionData.config_measurement !== undefined) {
				const aux = measureConfig.find(
					m =>
						m.length === inEditionData.config_measurement.length &&
						m.out_diameter === inEditionData.config_measurement.out_diameter &&
						m.int_diameter === inEditionData.config_measurement.int_diameter &&
						m.wall_thickness === inEditionData.config_measurement.wall_thickness &&
						m.weight === inEditionData.config_measurement.weight
				)
				const newMeasurementData = aux ? aux : await newMeasurementConfig(inEditionData.config_measurement)
				await updateInspectionType({
					...inEditionData,
					config_summary: inEditionData.config_summary,
					config_measurement: newMeasurementData.id
				})
			} else {
				await updateInspectionType({
					...inEditionData,
					config_summary: inEditionData.config_summary
				})
			}
			setIsLoadingResponse(false)
			triggerReload()
			setSelectedInspection(null)
			inEditionData.stage === INSPECTION_STAGES.code.PERIODICAL
				? toast.success(t('wms:SuccessfullyInspectionTypeUpdated'))
				: toast.success(t('wms:SuccessfullyUpdatedInspectionSettings'))
		} catch (error) {
			setIsLoadingResponse(false)
			console.error(error)
		}
	}

	return (
		<Card
			margin='0'
			height='100%'
			width='calc(100% - 48px)'
			data-cy='types-list-card'
			style={{ overflowY: 'scroll', overflowX: 'hidden', position: 'relative' }}>
			<LoadingOverlay visible={isLoadingResponse} borderRadius='card' />

			<FlexView width='100%' flexDirection='row' fontSize='medium' fontWeight='bold' margin='0 0 8px 0'>
				{t('wms:InspectionOptions')}
			</FlexView>

			{inEditionData ? (
				<FlexView flexDirection='row' flexWrap='wrap' justifyContent='space-between'>
					{inEditionData.stage === INSPECTION_STAGES.code.RETURN && (
						<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:ReturnType')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{inEditionData.is_modified_product ? t('wms:ModifiedProduct') : t('wms:RigReturn')}
							</FlexView>
						</FlexView>
					)}
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 4px 0'>
						{t('wms:InspectionSummary')}
					</FlexView>
					{inEditionData.config_summary.map((a, index) =>
						inEditionData.stage === INSPECTION_STAGES.code.PERIODICAL ? (
							<Checkbox
								width='50%'
								fontSize='small'
								key={index}
								checked={a.enabled}
								onChange={() => toggleSummary(a.name)}
								label={a.name === 'CustomerRequestReference' ? 'AttachedCustomerRequest' : a.name}
								disabled={true}
							/>
						) : (
							<Checkbox
								width='50%'
								fontSize='small'
								key={index}
								checked={a.enabled}
								onChange={() => toggleSummary(a.name)}
								label={a.name === 'CustomerRequestReference' ? 'AttachedCustomerRequest' : a.name}
								disabled={a.name === 'WorkOrderDescription'}
							/>
						)
					)}
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 4px 0'>
						{t('wms:InspectionMeasureConfig')}
					</FlexView>

					{inEditionData.config_measurement ? (
						<>
							<Checkbox
								width='50%'
								fontSize='small'
								checked={inEditionData.config_measurement.length}
								onChange={v =>
									setInEdtitionData({
										...inEditionData,
										config_measurement: { ...inEditionData.config_measurement, length: v }
									})
								}
								label='Length'
							/>
							<Checkbox
								width='50%'
								fontSize='small'
								checked={inEditionData.config_measurement.int_diameter}
								onChange={v =>
									setInEdtitionData({
										...inEditionData,
										config_measurement: { ...inEditionData.config_measurement, int_diameter: v }
									})
								}
								label='Internal Diameter'
							/>

							<Checkbox
								width='50%'
								fontSize='small'
								checked={inEditionData.config_measurement.out_diameter}
								onChange={v =>
									setInEdtitionData({
										...inEditionData,
										config_measurement: { ...inEditionData.config_measurement, out_diameter: v }
									})
								}
								label='Outer Diameter'
							/>
							<Checkbox
								width='50%'
								fontSize='small'
								checked={inEditionData.config_measurement.wall_thickness}
								onChange={v =>
									setInEdtitionData({
										...inEditionData,
										config_measurement: { ...inEditionData.config_measurement, wall_thickness: v }
									})
								}
								label='Wall Thickness'
							/>
							<Checkbox
								width='50%'
								fontSize='small'
								checked={inEditionData.config_measurement.weight}
								onChange={v =>
									setInEdtitionData({
										...inEditionData,
										config_measurement: { ...inEditionData.config_measurement, weight: v }
									})
								}
								label='Weight'
							/>
						</>
					) : (
						<FlexView
							width=' 100%'
							fontWeight='bold'
							color='lightGray'
							margin='32px 0'
							alignItems='center'
							style={{ alignContent: 'center', textAlign: 'center' }}
							justifyContent='center'
							data-cy='no-reports-list'>
							{t('wms:MeasurementDisabled')}
						</FlexView>
					)}
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='16px 0 4px 0'>
						{t('wms:InspectionThreadCompoundConfig')}
					</FlexView>
					{inEditionData.has_thread_compound ? (
						<>
							<Checkbox
								width='50%'
								fontSize='small'
								checked={inEditionData.thread_compound_method_required}
								onChange={v =>
									setInEdtitionData({
										...inEditionData,
										thread_compound_method_required: v
									})
								}
								label={t('wms:ApplicationMethod')}
							/>
							<Checkbox
								width='50%'
								fontSize='small'
								checked={inEditionData.thread_compound_type_required}
								onChange={v =>
									setInEdtitionData({
										...inEditionData,
										thread_compound_type_required: v
									})
								}
								label={t('wms:ThreadCompoundName')}
							/>
						</>
					) : (
						<FlexView
							width=' 100%'
							fontWeight='bold'
							color='lightGray'
							margin='32px 0'
							alignItems='center'
							style={{ alignContent: 'center', textAlign: 'center' }}
							justifyContent='center'
							data-cy='no-reports-list'>
							{t('wms:ThreadCompoundDisabled')}
						</FlexView>
					)}

					<Button
						fontSize='small'
						margin='auto 0 0 auto'
						backgroundColor='success'
						color='white'
						onClick={() => onUpdateInspectionType()}
						data-cy='button-save-config'>
						{t('wms:Save')}
						<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			) : (
				<FlexView
					width=' 100%'
					fontWeight='bold'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					style={{ alignContent: 'center' }}
					justifyContent='center'
					data-cy='no-reports-list'>
					{t('wms:NoInspectionTypeSelected')}
				</FlexView>
			)}
		</Card>
	)
}

export default InspTypeCardConfig
