/** @format */

import { Button, FlexView, Icon } from 'components/common'
import { Input } from 'components/form'
import React from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line react/prop-types
const SearchBar = ({ keyWord, setKeyWord, inspSelected, inspType, onSearchInfo, onSearchParams, loadingReports }) => {
	const { t } = useTranslation()

	const handleSearchInfo = value => {
		onSearchInfo(value)
	}

	const selectKeyWord = value => {
		setKeyWord(value)
	}

	const searchParamReports = () => {
		onSearchParams()
	}

	return (
		<FlexView width='50%' margin='0 0 0 auto' flexDirection='row'>
			<Input
				message={t('wms:CaseSensitve')}
				messageType='warning'
				minWidth='100%'
				width='100%'
				inline={true}
				disabled={!inspSelected || inspType === 0 || loadingReports === true}
				placeholder={t('wms:Keyword')}
				value={keyWord}
				onKeyPress={e => handleSearchInfo(e)}
				onChange={e => selectKeyWord(e.target.value)}
			/>
			<Button
				backgroundColor='#667EEA'
				color='white'
				margin='0 0 22px 8px'
				alignItems='center'
				alignSelf='center'
				disabled={!keyWord}
				onClick={() => searchParamReports()}>
				<Icon name='search' color='white' height='16px' width='16px' margin='0 8px 0 0' />
				{t('wms:Search')}
			</Button>
		</FlexView>
	)
}

export default SearchBar
