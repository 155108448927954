/** @format */
/* eslint-disable react/prop-types */

import { AssetsAttributesContext } from 'apps/wms/stores/AssetsAttributesStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Input } from 'components/form'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { toast } from 'react-toastify'

const AttributeValues = ({ mandatorySelected, optAttrSelected }) => {
	const { t } = useTranslation()
	const {
		attrSelected,
		mandatoryAttr,
		optionalAttr,
		loadingValues,
		newAttribute,
		values,
		setValues,
		addAttributeValue,
		saveAttrValue,
		deleteAttrValue,
		setNewAttribute
	} = useContext(AssetsAttributesContext)

	const listDeadSpace = 145
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const checkFieldType = () => {
		if (
			(mandatorySelected && mandatorySelected.validationexample) === '1.23' ||
			(optAttrSelected && optAttrSelected.validationexample) === '1.23' ||
			(mandatorySelected && mandatorySelected.validationexample) === '123' ||
			(optAttrSelected && optAttrSelected.validationexample) === '123'
		)
			return 'number'
		else if (
			(mandatorySelected && mandatorySelected.validationexample) === 'VS-01' ||
			(optAttrSelected && optAttrSelected.validationexample) === 'VS-01'
		)
			return 'text'
		else return 'text'
	}

	const handleNewAttribute = value => {
		;(mandatorySelected && mandatorySelected.validationexample) === '1.23'
			? setNewAttribute(parseFloat(value))
			: (optAttrSelected && optAttrSelected.validationexample) === '1.23'
			? setNewAttribute(parseFloat(value.replace(/,/g, '.')))
			: (mandatorySelected && mandatorySelected.validationexample) === '123'
			? setNewAttribute(parseInt(value))
			: (optAttrSelected && optAttrSelected.validationexample) === '123'
			? setNewAttribute(parseInt(value))
			: setNewAttribute(value)
	}

	const addAttributes = () => {
		let regexValue = /^([0-9]*[.])?[0-9]+$/
		let result
		if (mandatorySelected && mandatorySelected.validationexample === '1.23') {
			result = regexValue.test(newAttribute)
		}
		if (optAttrSelected && optAttrSelected.validationexample === '1.23') {
			result = regexValue.test(newAttribute)
		}
		result === false
			? toast.error('You must enter a value with a dot separator')
			: addAttributeValue(newAttribute, attrSelected)
	}

	const handleChangeValue = (value, index) => {
		values[index].value = value
		setValues({ ...values })
	}

	const saveAttributeValue = makeup => {
		let regexValue = /^([0-9]*[.])?[0-9]+$/
		let result
		if (mandatorySelected && mandatorySelected.validationexample === '1.23') result = regexValue.test(makeup.value)
		if (optAttrSelected && optAttrSelected.validationexample === '1.23') result = regexValue.test(makeup.value)

		result === false ? toast.error('You must enter a value with a dot separator') : saveAttrValue(makeup)
	}

	const deleteAttributeValue = value => {
		deleteAttrValue(value)
	}

	return (
		<Card
			width='100%'
			margin='0 0 0 16px'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='card-mandatory-attr-value'>
			{attrSelected ? (
				<>
					<FlexView
						flexDirection='row'
						width='100%'
						justifyContent='space-between'
						alignItems='center'
						alignSelf='center'
						margin='0'>
						<FlexView fontSize='subtitle' fontWeight='bold' width='50%' margin='0'>
							{mandatorySelected && mandatoryAttr
								? _.find(mandatoryAttr, opt => opt.id === attrSelected).description
								: optAttrSelected &&
								  optionalAttr &&
								  (optAttrSelected.name !== '' ||
										optAttrSelected.description !== '' ||
										optAttrSelected.symbol !== '')
								? _.find(optionalAttr, opt => opt.id === attrSelected).description
								: null}
						</FlexView>
						<FlexView width='50%' flexDirection='row' justifyContent='flex-end' margin='0'>
							<Input
								type={checkFieldType()}
								size='18'
								placeholder='item'
								value={newAttribute}
								onChange={e => handleNewAttribute(e.target.value)}
								data-cy='input-mandatory-attr-name'
							/>
							<Button
								backgroundColor='secondary'
								color='white'
								onClick={() => addAttributes()}
								margin='18px 0 0 16px'
								disabled={!newAttribute}
								data-cy='button-add-mandatory-attr-value'>
								{t('wms:Add')}
								<Icon name='add' color='white' width='14px' height='14px' margin='0 0 0 8px' />
							</Button>
						</FlexView>
					</FlexView>
					<FlexView
						flexDirection='row'
						flexWrap='wrap'
						width='100%'
						margin='16px 0 16px 0'
						height='auto'
						maxHeight={`${listHeight}px`}
						style={{ overflowX: 'hidden', overflowY: 'auto' }}>
						<LoadingOverlay visible={loadingValues} borderRadius='card' />
						{values &&
							_.map(values, (item, index) => (
								<Card key={index} width='fit-content' margin='8px 0 8px 16px' data-cy='card-mandatory-values'>
									<FlexView flexDirection='column' width='100%' aligItems='center'>
										<Input
											width='100%'
											type='text'
											size='18'
											placeholder='item'
											value={item.value}
											onChange={e => handleChangeValue(e.target.value, index)}
											data-cy='input-mandatory-value-name'
										/>
										<FlexView flexDirection='row' width='100%'>
											<Button
												backgroundColor='success'
												color='white'
												margin='0'
												onClick={() => saveAttributeValue(item)}
												data-cy='button-save-mandatory-value'>
												{t('wms:Save')}
												<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
											</Button>
											<Button
												backgroundColor='error'
												color='white'
												margin='0 0 0 16px'
												onClick={() => deleteAttributeValue(item)}
												data-cy='button-delete-mandatory-value'>
												{t('wms:Delete')}
												<Icon name='trash' color='white' width='14px' height='14px' margin='0 0 0 8px' />
											</Button>
										</FlexView>
									</FlexView>
								</Card>
							))}
					</FlexView>
				</>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='no-attribute-list'>
					{t('wms:NoAttribute')}
				</FlexView>
			)}
		</Card>
	)
}

export default AttributeValues
