import React from 'react'
import { FlexView, Card } from 'components/common'
import Groups from '../containers/users/GroupCard'
import Users from '../containers/users/UserCard'

const UsersPage = () => {

  return (
    <FlexView height="100%" flexDirection="row" alignItems="flex-start" justifyContent="space-between" fontSize="medium" margin="8px 0px" width="100%">
      <Card width="30%" padding="0px 0px" margin="8px 1px 8px 8px" >
        <Groups />
      </Card>
      <Card width="70%" padding="0px 0px" margin="8px 8px 8px 1px" >
        <Users />
      </Card>
    </FlexView>
  )
}

export default UsersPage
