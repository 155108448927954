/** @format */

import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexView } from 'components/common'
import { ReportsContext } from '../stores/ReportStore'
import InspReportList from '../containers/reports/InspList'
import InspReportInfo from '../containers/reports/InspInfo'
import { toast } from 'react-toastify'
import TabsRow from '../components/commom/TabsRow'
import PageTemplate from '../containers/common/PageTemplate'
import ReportEditorModal from '../containers/reportEditor/wrapper'
import DailyReportFilter from '../containers/reports/DailyReportFilter'
import _ from 'lodash'
import SearchBar from '../containers/common/SearchBar'

const InspectionReportsPage = () => {
	const { t } = useTranslation()
	const {
		inspType,
		setInspType,
		loadingReports,
		inspReportList,
		setSelectedReport,
		loadingDetails,
		reportDetails,
		downloadInspectionReport,
		isEditorOpen,
		setIsEditorOpen,
		downloadingReport,
		inspections,
		inspSelected,
		setInspSelected,
		getReports,
		elementsByInsp,
		inspInfo,
		items,
		reportHeader,
		inspName,
		setInspName,
		type,
		setReportDetails,
		filteredReports,
		searchReports,
		keyWord,
		setKeyWord,
		partDetails,
		reopenReport,
		selectedReport,
		selectedSubRow
	} = useContext(ReportsContext)
	const isInspection = true

	const handleSelectReport = useCallback(
		reportId => {
			let inspList = inspReportList.find(item => item.listid === reportId || item.id === reportId)
			let filteredInspList = filteredReports.find(item => item.listid === reportId || item.id === reportId)
			reportId != null && setSelectedReport(filteredInspList ? filteredInspList : inspList)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[inspReportList, filteredReports]
	)

	const handleDownloadReport = useCallback(
		(reportId, partNumber) => {
			reportId &&
				inspSelected &&
				downloadInspectionReport(reportId, partNumber, inspSelected).catch(error => {
					toast.error(`${t(error)}`)
				})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[downloadInspectionReport]
	)

	const handleOpenReportEditor = useCallback(() => {
		setIsEditorOpen(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleReopenPartInspection = useCallback(
		(contentID, revision) => {
			contentID && reopenReport(contentID, revision).catch(error => console.error(error))
		},
		[reopenReport]
	)

	const closeReportEditorModal = reload => {
		setIsEditorOpen(false)
		setReportDetails(null)
		const hydrate = async () => {
			try {
				await getReports(inspName)
				handleSelectReport(reportDetails.id)
			} catch (e) {
				console.error(e)
			}
		}
		return reload ? hydrate() : null
	}

	const selectInsp = value => {
		setInspType(value)
		setInspSelected(_.find(inspections, insp => insp.id === value))
		setInspName(_.find(inspections, insp => insp.id === value).name)
	}

	const searchParamReports = () => {
		searchReports(inspName, keyWord).catch(e => console.error(e))
	}

	const handleSearchInfo = event => {
		event.key === 'Enter' && event.target.value !== '' && searchReports(inspName, event.target.value)
	}
	const checkPartInfo = () => {
		const hasOnePart =
			(inspInfo && Object.keys(inspInfo).length && inspInfo.parts === null) || inspInfo.parts === undefined
		const isPartSelected = Object.keys(inspInfo) && inspInfo.partid === null && selectedSubRow != null

		return hasOnePart ? true : isPartSelected ? true : false
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:InspectionReports'),
				path: '/wms/manage/reports/edit/inspection'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs} overflow={isEditorOpen ? 'hidden' : 'inherit'} height='90%'>
			<FlexView width='100%' height='90%' maxWidth='calc(100% - 32px)' margin='0 0 16px 0'>
				<FlexView width='calc(100% - 12px)' flexDirection='row'>
					<FlexView
						padding='0 0 0 16px'
						width='82%'
						data-cy='flexview-page-title'
						minWidth='50%'
						fontSize='40px'
						fontWeight='bold'
						margin='0 auto 24px 0'>
						{t('wms:InspectionReports')}
					</FlexView>
					<SearchBar
						keyWord={keyWord}
						inspSelected={inspSelected}
						inspType={inspType}
						onSearchInfo={value => handleSearchInfo(value)}
						setKeyWord={setKeyWord}
						onSearchParams={() => searchParamReports()}
					/>
				</FlexView>
				<TabsRow
					tabsList={inspections && _.map(inspections, insp => ({ value: insp.id, label: insp.name }))}
					selectedTab={inspType}
					setSelectedTab={v => selectInsp(v)}
					tabWidth={360}
					width={window.innerWidth - 200}
					onChange={value => setInspType(value)}
					data-cy='tabs-row'
				/>
				<FlexView width='100%' height='70%' flexDirection='row' padding='0'>
					{inspType !== 0 ? (
						<InspReportList
							inspName={inspName}
							inspection={inspSelected}
							loadingList={loadingReports}
							list={inspReportList}
							filteredReports={filteredReports}
							listType={inspType}
							onSelectReport={(value, depth) => handleSelectReport(value, depth)}
							onDownloadReport={(reportId, partId) => handleDownloadReport(reportId, partId)}
							onCheckReport={() => handleOpenReportEditor()}
							downloadingReport={downloadingReport}
							data-cy='inspection-report-list'
						/>
					) : (
						<DailyReportFilter />
					)}

					{selectedReport && (
						<InspReportInfo
							subRow={selectedSubRow}
							inspSelected={inspSelected}
							selectedReport={selectedReport}
							details={checkPartInfo() ? partDetails : inspInfo}
							inspectionType={inspType}
							loadingInfo={loadingDetails}
							onDownloadReport={(reportId, partNumber) => handleDownloadReport(reportId, partNumber)}
							onCheckReport={() => handleOpenReportEditor()}
							onReopenReport={(contentID, revision) => handleReopenPartInspection(contentID, revision)}
							data-cy='inspection-report-info'
						/>
					)}
				</FlexView>
			</FlexView>
			{reportDetails && isEditorOpen && (
				<ReportEditorModal
					isOpen={isEditorOpen}
					onOutsideClick={closeReportEditorModal}
					report={checkPartInfo() ? partDetails : inspInfo}
					reportType={type}
					inspSelected={inspSelected}
					items={items}
					elements={elementsByInsp}
					loading={loadingDetails}
					reportHeader={reportHeader}
					stage={inspSelected.stage}
					isInspection={isInspection}
				/>
			)}
		</PageTemplate>
	)
}

export default InspectionReportsPage
