/** @format */

import { FlexView } from 'components/common'
import React from 'react'
import { useContext } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import EquipmentInfo from '../containers/assets/equipment/Info'
import InstrumentList from '../containers/assets/equipment/List'
import EquipmentModal from '../containers/assets/equipment/Modal'
import PageTemplate from '../containers/common/PageTemplate'
import { AssetsEquipmentsContext } from '../stores/AssetsEquipmentsStore'
import { assets, genParams } from '../utils/hubs'

const AssetsEquipmentPage = () => {
	const { t } = useTranslation()
	const { selectedEquipment, isModalOpen, setIsModalOpen, isUpdate, setIsUpdate } = useContext(AssetsEquipmentsContext)

	const closeModal = () => {
		setIsModalOpen(false)
		setIsUpdate(false)
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:EquipmentManagement'),
				path: '/wms/assets/equipments'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:Equipments')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='50%' margin='0 24px 0 0'>
						<InstrumentList />
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 24px'>
						{selectedEquipment && <EquipmentInfo selectedEquipment={selectedEquipment} />}
					</FlexView>
				</FlexView>
			</FlexView>
			<EquipmentModal
				isOpen={isModalOpen}
				onOutsideClick={closeModal}
				isUpdate={isUpdate}
				equipmentInfo={selectedEquipment}
			/>
		</PageTemplate>
	)
}

export default AssetsEquipmentPage
