/** @format */

export const responseHandle = (response) => {
  const responseData = response.data
    ? response.data.body !== undefined
      ? response.data.body
      : response.data
    : response
  return responseData
}

export const errorHandle = (error) => {
  let message = JSON.stringify(error)
  if (error.response) {
    message = error.response.data.message
      ? error.response.data.message
      : error.response.data
  } else {
    message = error.message ? error.message : message
  }
  return message
}
