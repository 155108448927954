/** @format */

import { FlexView } from 'components/common'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DefectsByItems from '../containers/assets/inspectionSettings/DefectsByItems'
import InspDefects from '../containers/assets/inspectionSettings/InspDefects'
import InspItems from '../containers/assets/inspectionSettings/InspItems'
import InspTabs from '../containers/assets/inspectionSettings/InspTabs'
import InspTypeCardConfig from '../containers/assets/inspectionSettings/inspTypeCardConfig'
import InspTypesList from '../containers/assets/inspectionSettings/InspTypesList'
import ItemsByInsp from '../containers/assets/inspectionSettings/ItemsByInsp'
import PageTemplate from '../containers/common/PageTemplate'
import { AssetsInspSettingsContext } from '../stores/AssetsInspSettingsStore'
import { assets, genParams } from '../utils/hubs'

const AssetsInspSettingsPage = () => {
	const { t } = useTranslation()
	const { chosenDefects, chosenItems, chosenInspections } = useContext(AssetsInspSettingsContext)

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:InspectionSettings'),
				path: '/wms/assets/inspsettings'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' height='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:InspectionSettings')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row' alignItems='stretch'>
					<FlexView height='100%' width='0%' margin='0 8px 0 0'>
						<InspTabs />
					</FlexView>
					<FlexView height='100%' width='50%' margin='0 0 0 8px'>
						{chosenItems && <InspItems />}
						{chosenDefects && <InspDefects />}
						{chosenInspections && <InspTypesList />}
					</FlexView>
					<FlexView height='100%' width='40%' margin='0 0 0 24px'>
						{chosenItems && <ItemsByInsp />}
						{chosenDefects && <DefectsByItems />}
						{chosenInspections && <InspTypeCardConfig />}
					</FlexView>
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default AssetsInspSettingsPage
