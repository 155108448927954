/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getOwnershipsList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`ownership/${wid}/description`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getOwnershipLevels: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`ownership/${wid}/levels`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),

	getOwnershipsListByEndUser: (wid, enduser, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/ownershiplist/${wid}/${enduser}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getOwnershipsByPipedata: (wid, enduser, order, item, type, token) =>
		new Promise((resolve, reject) => {
			let url = type
				? `content/ownershiplist/${wid}/${enduser}?salesnumber=${encodeURIComponent(
						order
				  )}&salesitem=${encodeURIComponent(item)}`
				: `content/ownershiplist/${wid}/${enduser}?ponumber=${encodeURIComponent(
						order
				  )}&poitem=${encodeURIComponent(item)}`

			instance
				.get(url, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.ownershipdescriptions)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getEndUsersByPipedata: (wid, ownership, order, item, type, token) =>
		new Promise((resolve, reject) => {
			let url = type
				? `content/enduserlist/${wid}/${ownership}?salesnumber=${encodeURIComponent(
						order
				  )}&salesitem=${encodeURIComponent(item)}`
				: `content/enduserlist/${wid}/${ownership}?ponumber=${encodeURIComponent(
						order
				  )}&poitem=${encodeURIComponent(item)}`

			instance
				.get(url, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.enduserlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getContentByOwnership: (wid, ownershipId, physTrans, token) =>
		new Promise((resolve, reject) => {
			let url = physTrans
				? `content/${wid}/byownership/${ownershipId}?phystransissued=true`
				: `content/${wid}/byownership/${ownershipId}`
			instance
				.get(url, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.content)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getContentByEndUser: (wid, endUserId, physTrans, token) =>
		new Promise((resolve, reject) => {
			let url = physTrans
				? `content/${wid}/byenduser/${endUserId}?phystransissued=true`
				: `content/${wid}/byenduser/${endUserId}`
			instance
				.get(url, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.content)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getContentByMaterial: (mid, wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/${wid}/filtered?ownership=&material=${mid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.content)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getEnduserList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`enduser/${wid}/local`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.locallist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getAllEndusersList: token =>
		new Promise((resolve, reject) => {
			instance
				.get(`enduser/global`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.globallist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getCustomerOrdersListByEndUser: (wid, enduser, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/polist/${wid}/${enduser}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.polist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getCustomerOrdersListByOwnership: (wid, onwershipId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/polist/${wid}/byownership/${onwershipId}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.polist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getSalesOrdersListByEndUser: (wid, enduser, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/saleslist/${wid}/${enduser}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.saleslist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getSalesOrdersListByOwnership: (wid, onwershipId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/saleslist/${wid}/byownership/${onwershipId}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.saleslist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getPipesByOrderItem: (wid, enduser, order, item, ownership, type, token) =>
		new Promise((resolve, reject) => {
			let url = type
				? `content/itemlist/${wid}/${enduser}?salesnumber=${encodeURIComponent(
						order
				  )}&salesitem=${encodeURIComponent(item)}`
				: `content/itemlist/${wid}/${enduser}?ponumber=${encodeURIComponent(order)}&poitem=${encodeURIComponent(
						item
				  )}`
			url += `&ownershipid=${encodeURIComponent(ownership)}`
			instance
				.get(url, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.bundles)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getPipesByLevel: (wid, rackId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/bundles/${wid}/at/${rackId}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.bundles)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getOwnershipByMaterial: (wid, mid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/ownershiplist/${wid}/material/${mid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	checkValid: (wid, VALID, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/${wid}/byitem?valid=${encodeURIComponent(VALID)}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.content)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	changeOwnership: (wid, targetOwnership, payload, token) =>
		new Promise((resolve, reject) => {
			instance
				.put(`/ownership/change/${wid}/${targetOwnership}`, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	changeEnduser: (targetEnduser, targetMaterial, payload, token) =>
		new Promise((resolve, reject) => {
			instance
				.put(`/content/change/enduser/${targetEnduser}/${targetMaterial}`, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
