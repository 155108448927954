/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

const newInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/v2/wms/inspection`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getReports: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`reportref/${wid}/config`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateReport: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`reportref/${wid}/config/update`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	uploadWarehouseLogo: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`reportref/${wid}/storelogo`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getCurrentWarehouseLogo: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`reportref/${wid}/logo`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getInspectionReportTypes: token =>
		new Promise((resolve, reject) => {
			newInstance
				.get('reportreference/types', {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getInspectionReports: (wid, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`reportreference/${wid}/config`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	createInspectionReport: (wid, params, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.post(`reportreference/${wid}/create`, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if ([200, 201, 204].includes(response.status)) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateInspectionReport: (wid, params, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.put(`reportreference/${wid}/update`, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	disableInspectionReport: (wid, params, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.put(`reportreference/${wid}/disable`, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	enableInspectionReport: (wid, params, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.put(`reportreference/${wid}/enable`, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getUnits: (wid, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`config/${wid}/report-unit`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					response.status === 200 || response.status === 204
						? resolve(response.data)
						: reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	createWarehouseUnits: (wid, params, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.post(`config/${wid}/report-unit`, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					response.status === 200 || response.status === 201 || response.status === 204
						? resolve(response.data)
						: reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateWarehouseUnits: (wid, params, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.put(`config/${wid}/report-unit`, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					response.status === 200 || response.status === 204
						? resolve(response)
						: reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
