/** @format */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import { Button, FlexView, LoadingOverlay } from 'components/common'
import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AssetsPrinterContext } from 'apps/wms/stores/AssetsPrinterStore'
import _ from 'lodash'
import { Select } from 'components/form'
import Table from 'apps/wms/components/assets/Table'
import { isRFID } from 'apps/wms/utils/enum'

const PrintersTable = () => {
	const { t } = useTranslation()
	const {
		data,
		setData,
		loadingPrinters,
		setSize,
		setSavePrinter,
		setTempSelected,
		setNewConfig,
		testConfig,
		setTestConfig,
		setPrinterTest
	} = useContext(AssetsPrinterContext)

	const selectOptionStr = t('wms:SelectOption')

	const selectRFID = useCallback(
		(value, row) => {
			data[row.index].rfid = value
			setData([...data])
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data]
	)

	const selectTemplate = useCallback(
		(value, row) => {
			row.original.tagname = value
			let type = _.find(row.original.printertemplate, p => p.name === value)
			row.original.tagtype = type.type
			setTempSelected(value)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data]
	)

	const getOptions = row => {
		let values = _.find(row.original.printertemplate, tags => tags.name === row.original.tagname)
		return _.map(values ? values.sizes : [], t => {
			return { value: t, label: t }
		})
	}

	const selectSize = useCallback((value, row) => {
		row.original.printersize = value
		setSize(value)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const savePrinterConfig = useCallback(
		row => e => {
			e.stopPropagation()
			setNewConfig(row.original)
			setSavePrinter(true)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const testPrinter = useCallback(
		row => e => {
			e.stopPropagation()
			setPrinterTest(row.original)
			setTestConfig(true)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const tableData = useMemo(() => {
		return (
			data &&
			data.map(printerConfig => ({
				printer: printerConfig.printer,
				tagname: printerConfig.tagname,
				printertemplate: printerConfig.templates,
				printersize: printerConfig.tagsize,
				rfid: printerConfig.rfid,
				tagtype: printerConfig.tagtype
			}))
		)
	}, [data])

	const columns = useMemo(
		() => [
			{
				Header: (
					<FlexView
						fontSize='big'
						fontWeight='bold'
						color='metalic'
						style={{ alignItems: 'center', texAlign: 'center' }}>
						{t('wms:Printer')}
					</FlexView>
				),
				accessor: 'printer',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' width='100%' margin='0 auto'>
						{value}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='big' fontWeight='bold' color='metalic' style={{ alignItems: 'center' }}>
						{t('wms:PrinterTemplate')}
					</FlexView>
				),
				accessor: 'printertemplate',
				Cell: ({ cell: { value, row } }) => (
					<Select
						width='100%'
						placeholder={selectOptionStr}
						value={row.original.tagname}
						options={
							value &&
							_.map(value, v => {
								return { value: v.name, label: v.name }
							})
						}
						onChange={v => selectTemplate(v, row)}></Select>
				)
			},
			{
				Header: (
					<FlexView fontSize='big' fontWeight='bold' color='metalic' style={{ alignItems: 'center' }}>
						{t('wms:PrinterSize')}
					</FlexView>
				),
				accessor: 'printersize',
				Cell: ({ cell: { row } }) => (
					<Select
						width='100%'
						placeholder={selectOptionStr}
						value={row.original.printersize}
						options={getOptions(row)}
						onChange={v => selectSize(v, row)}></Select>
				)
			},
			{
				Header: (
					<FlexView fontSize='big' fontWeight='bold' color='metalic' style={{ alignItems: 'center' }}>
						{t('wms:PrinterRFID')}
					</FlexView>
				),
				accessor: 'rfid',
				Cell: ({ cell: { row } }) => (
					<FlexView width='100%' style={{ alignSelf: 'center' }} alignItems='center'>
						<Select
							width='100%'
							placeholder={selectOptionStr}
							options={isRFID.map(opt => ({
								value: opt.value,
								label: t(opt.label)
							}))}
							value={row.original.rfid}
							onChange={v => selectRFID(v, row)}></Select>
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='big' fontWeight='bold' color='metalic' style={{ alignItems: 'center' }}>
						{t('wms:PrinterSaveConfig')}
					</FlexView>
				),
				accessor: 'saveprinterconfig',
				Cell: ({ cell: { row } }) => (
					<FlexView width='100%' alignItems='center'>
						<Button
							margin='8px 0 16px 0'
							backgroundColor='secondary'
							color='white'
							onClick={savePrinterConfig(row)}>
							{t('wms:PrinterSaveConfig')}
						</Button>
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='big' fontWeight='bold' color='metalic' style={{ alignItems: 'center' }}>
						{t('wms:PrinterTest')}
					</FlexView>
				),
				accessor: 'printertest',
				Cell: ({ cell: { row } }) => (
					<FlexView width='100%' alignItems='center'>
						<Button margin='8px 0 16px 0' backgroundColor='secondary' color='white' onClick={testPrinter(row)}>
							{t('wms:PrinterTest')}
							<LoadingOverlay visible={testConfig} />
						</Button>
					</FlexView>
				)
			}
		],
		[t, selectOptionStr, selectTemplate, selectSize, selectRFID, savePrinterConfig, testPrinter, testConfig]
	)

	return (
		<>
			<LoadingOverlay visible={loadingPrinters} borderRadius='card' />
			<Table columns={columns} data={tableData} width='100%' height='100%' data-cy='table-printers'></Table>
		</>
	)
}

export default PrintersTable
