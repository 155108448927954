import React, { useContext, useEffect, useState } from 'react'
import { Modal, Card, FlexView, Button } from 'components/common'
import { Input, DualListSelect, Checkbox } from 'components/form'
import { useTranslation } from 'react-i18next'
import { UsersContext } from 'apps/admin/stores/UsersStore'
import { toast } from 'react-toastify'
import _ from 'lodash'


const UsersModal = ({ isOpen, onOutsideClick, mode, data, action }) => {
  const { optionsGroup, addUser, editUser } = useContext(UsersContext)
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(true)
  const [isVisibleRandomPass, setIsVisibleRandomPass] = useState(false)
  const [newUser, setNewUser] = useState({ name: undefined, displayName: undefined, domain: undefined, password: undefined, email: undefined, groups: undefined, enabled: true })
  const [retypedpassword, setRetypedpassword] = useState([])
  const [randomPass, setRandompass] = useState([])
  const [selectedValues, setSelectedValues] = useState([])
  const [initialUserGroup, setInitialUserGroup] = useState([])

  useEffect(() => {
    if (isOpen && mode === "edit") {
      setNewUser({ ...data })
      prepareDuaListData(data)
    } else {
      setNewUser({ name: undefined, displayName: undefined, domain: undefined, password: undefined, email: undefined, groups: undefined, enabled: true })
      prepareDuaListData([])
    }
    setRetypedpassword([])
    setIsVisibleRandomPass(false)

  }, [isOpen, data, mode])

  const prepareDuaListData = (data) => {
    const optionsUserGroup = []
    _.map(data.groups, (g) => (
      optionsUserGroup.push(g.id)
    ))
    setSelectedValues(optionsUserGroup)
    setInitialUserGroup(optionsUserGroup)
  }

  const onInputChange = (event) => {
    const inputUser = { ...newUser }

    switch (event.target.name) {
      case "name":
        inputUser.name = event.target.value
        break
      case "displayName":
        inputUser.displayName = event.target.value
        break
      case "email":
        inputUser.email = event.target.value
        break
      case "password":
        inputUser.password = event.target.value
        break
      case "domain":
        inputUser.domain = event.target.value
        if (isVisible) {
          setIsVisible(false)
        }
        if (inputUser.domain === "") {
          setIsVisible(true)
        }
        break
      default:
        break
    }

    setNewUser(inputUser)
  }


  const onCheckChange = (value) => {
    const checkUser = { ...newUser }
    checkUser.enabled = value
    setNewUser(checkUser)
  }

  const onRetypePassChange = (event) => {
    setRetypedpassword(event.target.value)
  }


  const GenerateRandomPwd = () => {
    const userGenPass = { ...newUser }
    // --- Class for generating random password string
    let pattern = /[a-zA-Z0-9]/
    let length = 8

    let pass = Array.apply(null, { 'length': length })
      .map(function () {
        let result
        while (true) {
          result = String.fromCharCode(Math.floor(Math.random() * 256))
          if (pattern.test(result)) {
            return result
          }
        }
      }, this)
      .join('')
    userGenPass.password = pass
    setRandompass(pass)
    setNewUser(userGenPass)
    setIsVisibleRandomPass(true)
  }

  const onChangeList = (item) => {
    setSelectedValues(item)
  }

  const onSaveUser = async () => {
    let removeRoles = [], addRoles = []

    if (selectedValues) {
      removeRoles = _.difference(initialUserGroup, selectedValues)
      addRoles = _.difference(selectedValues, initialUserGroup)
    }

    if (mode === "edit") {
      editUser(newUser, removeRoles, addRoles).then(erro => {
        onOutsideClick()
        toast.success(t('admin:users.useredited'))
        action()
      }).catch(error => {
        if (error.message === "removeError") {
          toast.error(t('admin:users.errorRemovingUserGroup'))
        } else if (error.message === "addError") {
          toast.error(t('admin:users.errorAddingUserGroup'))
        } else {
          toast.error(t('admin:users.errorEditingUser', { status: error.data }))
        }
      })
    }
    else {
      if (!(newUser.name) || !(newUser.displayName)) {
        toast.error(t('admin:users.missingFielsIdName'))
      }
      if (newUser.domain ||
        (newUser.password === retypedpassword) || (randomPass === newUser.password)) {
        addUser(newUser, addRoles).then(error => {
          onOutsideClick()
          toast.success(t('admin:users.useradded'))
        }).catch(error => {
          toast.error(t('admin:users.errorAddingUser', { status: error === "notFound" ? t('admin:users.notFound') : error.data }))
        })
      }
      else {
        toast.error(t('admin:users.invalidPassword'))
      }
    }
  }

  return <Modal isOpen={isOpen} onOutsideClick={onOutsideClick} mode={mode} data={newUser}>

    <Card minWidth="400px" maxWidth="800px" position="relative" alignItems="stretch" style={{maxHeight: "550px" , overflow: "auto"}}>
      <FlexView fontSize="big" fontWeight="bold" margin="0px 0px 16px" flexDirection="row" alignItems="center" justifyContent="center">
        {mode === "new" ? t('admin:users.addUser') : t('admin:users.editUser')}
      </FlexView>
        <FlexView flexDirection="row" alignItems="flex-end" justifyContent="center">
          <Input label={t('admin:users.domain')} margin="0px 10px" type="text" name="domain" value={(newUser.domain === undefined || newUser.domain === null) ? '' : newUser.domain} onChange={onInputChange} />
          <Input label={t('admin:users.Id')} margin="0px 10px" type="text" name="name" value={newUser.name || ''} onChange={onInputChange} disabled={mode === "edit" ? true : false} />
        </FlexView>
        <FlexView flexDirection="row" alignItems="flex-end" justifyContent="center" >
          <Input label={t('admin:users.name')} margin="0px 10px" type="text" name="displayName" value={newUser.displayName || ''} onChange={onInputChange} />
          <Input label={t('admin:users.email')} margin="0px 10px" type="text" name="email" value={newUser.email || ''} onChange={onInputChange} />
        </FlexView>
        {isVisible ? <div>
          <FlexView flexDirection="row" alignItems="flex-end" justifyContent="center" >
            <Button margin="16px 16px" backgroundColor="primary" color="white" onClick={(e) => { GenerateRandomPwd(e) }}>{t('admin:users.GenerateRandomPwd')}</Button>
          </FlexView>
          {isVisibleRandomPass ? null : <div>
            <FlexView flexDirection="row" alignItems="flex-end" justifyContent="center" >
              <Input label={t('admin:users.password')} margin="0px 10px" type="password" name="password" width="90%" value={newUser.password || ''} onChange={onInputChange} />
              <Input label={t('admin:users.TypePwdAgain')} margin="0px 10px" type="password" width="90%" value={retypedpassword || ''} onChange={onRetypePassChange} />
            </FlexView></div>}
        </div>
          : null
        }
        {(isVisibleRandomPass && isVisible) ? <div>
          <Card backgroundColor="warning" fontWeight="bold" color="white" elevation="high"> {t('admin:users.password')}  : {newUser.password} </Card>
        </div> : null
        }
        <FlexView flexDirection="row" alignItems="stretch" justifyContent="flex-start" width="100%">
          <DualListSelect label={t('admin:users.roles')} height="300px" width="100%" margin="8px" selectedValues={selectedValues} onChange={onChangeList} options={optionsGroup} searchable />
        </FlexView>

        <FlexView flexDirection="row" alignItems="stretch" justifyContent="flex-start" width="100%">
          <Checkbox label={t('admin:users.enabled')} checked={newUser.enabled} onChange={onCheckChange} />
        </FlexView>
        <FlexView flexDirection="row" alignItems="flex-end" justifyContent="center" >
          <Button margin="0px 16px" backgroundColor="success" color="white" onClick={onSaveUser}>{t('admin:users.save')}</Button>
        </FlexView>

    </Card>

  </Modal>
}

export default UsersModal
