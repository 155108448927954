/** @format */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import { InventoryContext } from 'apps/wms/stores/InventoryStore'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { listStatusTranslateKey } from '../../utils/enum'
import _ from 'lodash'
import { useWindowSize } from 'apps/wms/utils/hooks'
import Table from '../../components/inventory/Table'
import { Input, RadioGroup, Select } from 'components/form'
import moment from 'moment'
import Checkbox from 'apps/wms/components/forms/Checkbox'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import theme from 'utils/theme'

const GhostModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const {
		items,
		loadingGhosts,
		setOpenPrint,
		openPrint,
		loadingPrinters,
		printers,
		printerSelected,
		setPrinterSelected,
		putPrinters,
		setItemSelected,
		exportItemsNotFound,
		setItems,
		confirmModal,
		setConfirmModal,
		executeDispatch,
		materialList,
		vehicleList,
		newDispatch
	} = useContext(InventoryContext)

	const [printing, setPrinting] = useState(false)
	const [extracting, setExtracting] = useState(false)
	const [dispatching, setDispatching] = useState(false)
	const [openDispatch, setOpenDispatch] = useState(false)
	const [dispatchData, setDispatchData] = useState([])
	const [vehicle, setVehicle] = useState(null)

	const listDeadSpace = 115
	const windowSize = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	const breakSpaceStr = 'break-spaces'

	useEffect(() => {
		if (listRef.current !== null) setListHeight(listRef.current.offsetHeight - listDeadSpace)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listRef.current, windowSize])

	const handlePrint = useCallback(
		original => e => {
			e.stopPropagation()
			setItemSelected(original)
			setOpenPrint(true)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const exportToExcel = () => {
		setExtracting(true)
		exportItemsNotFound()
			.then(response => {
				let fileName = `Extract - Not Read (${moment(new Date()).format('M-DD-YYYY')}).xlsx`
				let a = document.createElement('a')
				let file = new Blob([response], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				})
				let fileURL = window.URL.createObjectURL(file)
				a.href = fileURL
				a.target = '_blank'
				a.download = fileName
				a.click()
			})
			.catch(e => console.error(e))
			.finally(() => {
				setExtracting(false)
			})
	}

	const handleChange = e => {
		setPrinterSelected(e)
	}

	const closeModal = async () => {
		try {
			setPrinting(true)
			await putPrinters()
		} catch (e) {
			console.error(e)
			setOpenPrint(false)
			setPrinting(false)
		} finally {
			setOpenPrint(false)
			setPrinting(false)
		}
	}

	const closePrinters = () => {
		setOpenPrint(false)
	}

	const handleSelect = useCallback(
		(row, v) => {
			let idx = _.findIndex(items, item => item.valid === row.valid)
			items[idx].checked = v
			setItems([...items])
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[items]
	)

	const handleSelectAll = useCallback(
		(v, row) => {
			_.forEach(items, item => {
				if (item.material === row.material) {
					item.checked = v
					item.checked_all = v
				}
			})
			setItems([...items])
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[items]
	)

	const checkDispatchCompletion = () => {
		let isDisabled = true
		let isChecked = _.some(items, item => item.checked === true)
		isDisabled = isDisabled && !isChecked

		return isDisabled
	}

	const handleDispatch = () => {
		let quantity = 0
		_.forEach(dispatchData.content, content => {
			return (quantity += Number(content.valid.length))
		})
		setDispatching(true)
		let payload = {
			expecteddate: dispatchData.expecteddate,
			destination: dispatchData.destination,
			vehicleData: { ...dispatchData.vehicle, properties: undefined },
			occupationfactor: dispatchData.occupationfactor ? dispatchData.occupationfactor : 0,
			contents: dispatchData.content.map(content => ({
				levelfullname: content.fullname,
				mweightkg: content.mweightkg,
				mid: content.mid,
				quantity: quantity,
				enduserid: content.enduserid
			}))
		}
		newDispatch(payload).then(response => {
			executeDispatch(response)
				.then(() => {
					onOutsideClick()
					setDispatching(false)
					setOpenDispatch(false)
				})
				.catch(e => console.error(e))
				.finally(() => setDispatching(false))
		})
	}

	const openDispatchModal = () => {
		let filteredItems = _.filter(items, it => {
			return it.checked === true
		})
		let content = []
		_.forEach(materialList, material => {
			_.forEach(filteredItems, item => {
				item.material === material.mvid &&
					content.push({
						mid: material.mvid,
						formatted: material.formatted,
						mweightkg: material.weightkg,
						enduserid: material.enduserid,
						levelid: item.levelid,
						fullname: item.fullname
					})
			})
		})
		let uniqContentList = _.uniqWith(content, _.isEqual)
		let disptachInfo =
			uniqContentList &&
			uniqContentList.length &&
			uniqContentList.map(ct => ({
				enduserid: ct.enduserid,
				mid: ct.mid,
				formatted: ct.formatted,
				mweightkg: ct.mweightkg,
				valid:
					_.filter(items, { levelid: ct.levelid, checked: true }) &&
					_.filter(items, { levelid: ct.levelid, checked: true }).map(item => item.valid),
				levelid: ct.levelid,
				fullname: ct.fullname
			}))
		setDispatchData({ content: disptachInfo, destination: '', occupationfactor: '', expecteddate: new Date() })
		setOpenDispatch(true)
	}

	const selectVehicle = value => {
		let selectedVehicle = _.find(vehicleList, vehicle => vehicle.id === value)
		selectedVehicle &&
			setDispatchData({
				...dispatchData,
				vehicle: selectedVehicle,
				occupationfactor: getUpdateOccupation(dispatchData, selectedVehicle)
			})

		selectedVehicle && setVehicle(selectedVehicle)
	}

	const getUpdateOccupation = (data, vehicle) => {
		let occupation = dispatchData.occupationfactor
		let quantity = 0
		_.forEach(data.content, content => {
			return (quantity += Number(content.valid.length))
		})
		const sum = dispatchData.content.reduce((prev, curr) => {
			return (prev + curr.mweightkg) * quantity
		}, 0)
		occupation = (sum * 100, 0) / vehicle.maxweightkg

		return occupation
	}

	const handleOccupationChange = event => {
		let value = parseFloat(event)
		!isNaN(value) && value >= 0 && value <= 100 && setDispatchData({ ...dispatchData, occupationfactor: event })
	}

	const checkDispatchInfo = () => {
		let isValid = true
		isValid =
			isValid &&
			dispatchData.expecteddate != null &&
			dispatchData.destination !== '' &&
			dispatchData.content.length > 0
		return isValid
	}

	const data = useMemo(
		() =>
			_.chain(items)
				.groupBy('fullname')
				.map((value, key) => ({
					fullname: key,
					valid: value[0].valid,
					puuid: value[0].puuid,
					formatted: value.length > 1 ? `${value.length} ${t('wms:Materials')}` : value[0].formatted,
					status: value[0].status,
					checked_all: value[0].checked_all,
					children: value || [],
					subRows: value.length > 1 ? value : []
				}))
				.value(),
		[items, t]
	)

	const columns = useMemo(
		() => [
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Print')}
					</FlexView>
				),
				accessor: 'print',
				width: 'auto',
				Cell: ({ cell: { row } }) => {
					return (
						<FlexView alignItems='center' width='30%' justifyContent='center' margin='0 auto'>
							{row.subRows.length > 1 ? null : (
								<Icon
									name='print'
									color='secondary'
									fontWeight='bold'
									width='16px'
									height='16px'
									onClick={handlePrint(row.original)}
								/>
							)}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Rack')}
					</FlexView>
				),
				accessor: 'fullname',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							alignItems='center'
							flexDirection='row'
							fontWeight='bold'
							color='metalic'
							justifyContent='center'
							{...row.getToggleRowExpandedProps({
								style: {
									paddingLeft: `${row.depth * 32}px`,
									cursor: row.canExpand ? 'pointer' : 'inherit'
								},
								title: ''
							})}>
							{value}
							{row.subRows.length > 1 && row.canExpand && (
								<Icon
									name={row.isExpanded ? 'chevron-up' : 'chevron-down'}
									width='12px'
									height='12px'
									margin='0 4px 0 8px'
								/>
							)}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						Valid
					</FlexView>
				),
				accessor: 'valid',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							alignItems='center'
							fontWeight='bold'
							color='metalic'
							margin='0'
							justifyContent='center'
							width='100%'
							style={{ alignSelf: 'center', textAlign: 'center' }}>
							{row.subRows.length > 1 ? null : value}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Puuid')}
					</FlexView>
				),
				accessor: 'puuid',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							alignItems='center'
							fontWeight='bold'
							color='metalic'
							margin='0px'
							justifyContent='center'
							style={{ alignSelf: 'center' }}
							width='100%'>
							{row.subRows.length > 1 ? null : value}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Material')}
					</FlexView>
				),
				accessor: 'formatted',
				width: '350px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							alignItems='center'
							fontWeight='bold'
							color='metalic'
							margin='0px'
							minWidth='100%'
							justifyContent='center'
							style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						Status
					</FlexView>
				),
				accessor: 'status',
				width: '80px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							alignItems='center'
							fontWeight='bold'
							color='metalic'
							margin='0px'
							style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}>
							{row.subRows.length > 1 ? null : t(`${listStatusTranslateKey(value)}`)}
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Selection')}
					</FlexView>
				),
				accessor: 'checked',
				width: '80px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							alignItems='center'
							fontWeight='bold'
							color='metalic'
							margin='0px'
							style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}>
							{row.subRows.length > 1 ? (
								<Checkbox
									label={t('wms:SelectAll')}
									checked={row.original.checked_all}
									onChange={v => handleSelectAll(v, row.original)}
								/>
							) : (
								<Checkbox checked={value} onChange={v => handleSelect(row.original, v)} />
							)}
						</FlexView>
					)
				}
			}
		],

		[t, handlePrint, handleSelect, handleSelectAll]
	)
	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			{openPrint ? (
				<Card
					height='fit-content'
					style={{ alignSelf: 'center', justifyContent: 'space-between' }}
					data-cy='card-print-modal'>
					<LoadingOverlay visible={loadingPrinters} borderRadius='card' />
					<FlexView
						width='-webkit-fill-available'
						flexDirection='row'
						justifyContent='space-between'
						backgroundColor='info'
						fontSize='title'
						fontWeight='bold'
						color='white'
						margin='-16px -24px 8px'
						padding='8px'
						borderRadius='8px 8px 0 0'>
						{t('wms:SelectPrinter')}
					</FlexView>
					<RadioGroup
						name='printers'
						options={_.map(printers, p => ({ ...p, value: p.value, label: p.label }))}
						value={printerSelected}
						onChange={evt => handleChange(evt)}
						data-cy='radiogroup-printers'
					/>
					<FlexView width='100%' flexDirection='row' alignItems='center' justifyContent='center'>
						<Button
							backgroundColor='error'
							color='white'
							margin='0 8px 0 0'
							onClick={closePrinters}
							data-cy='button-cancel-print'>
							{t('wms:Cancel')}
						</Button>
						<Button
							disabled={printerSelected === null}
							backgroundColor='success'
							color='white'
							margin='0'
							onClick={closeModal}
							isLoading={printing}
							data-cy='button-print'>
							{t('wms:OK')}
						</Button>
					</FlexView>
				</Card>
			) : openDispatch ? (
				<Card width='70%' height='80vh' position='relative'>
					<FlexView width='100%' flexDirection='row' height='100%'>
						<FlexView width='40%' minWidth='35%' minHeight='100%' maxHeight='100%' margin='0 16px 0 0'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='8px 0'>
								{t('wms:Content')}
							</FlexView>
							{dispatchData &&
								dispatchData.content.length &&
								dispatchData.content.map((content, index) => (
									<FlexView
										key={index}
										width='100%'
										margin='0'
										flexDirection='column'
										FlexWrap='wrap'
										padding='8px 0'
										style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
										<FlexView
											width='100%'
											fontSize='small'
											margin='0 0 16px 0'
											padding='0'
											flexDirection='column'>
											<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
												{t('wms:Material')}
											</FlexView>
											<FlexView
												width='100%'
												style={{
													borderLeft: `2px solid ${theme.colors.primary}`,
													lineHeight: '24px'
												}}
												padding='0 0 0 8px'>
												{content.formatted}
											</FlexView>
										</FlexView>
										<FlexView width='100%' flexDirection='row'>
											<FlexView width='30%' margin='0' padding='0' flexDirection='column'>
												<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
													{t('wms:Rack')}
												</FlexView>
												<FlexView
													width='100%'
													style={{
														borderLeft: `2px solid ${theme.colors.primary}`,
														lineHeight: '24px'
													}}
													padding='0 0 0 8px'>
													{content.fullname}
												</FlexView>
											</FlexView>
											<FlexView width='30%' margin='0' padding='0' flexDirection='column'>
												<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
													{t('wms:Quantity')}
												</FlexView>
												<FlexView
													width='100%'
													style={{
														borderLeft: `2px solid ${theme.colors.primary}`,
														lineHeight: '24px'
													}}
													padding='0 0 0 8px'>
													{content.valid && content.valid.length}
												</FlexView>
											</FlexView>
										</FlexView>
									</FlexView>
								))}
						</FlexView>
						<FlexView height='100%' style={{ borderLeft: '2px solid whitesmoke' }}></FlexView>
						<FlexView
							width='60%'
							flexDirection='row'
							flexWrap='wrap'
							margin='0 0 0 16px'
							justifyContent='space-around'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='8px 0'>
								{t('wms:DispatchInfo')}
							</FlexView>
							<Input
								label={`${t('wms:DispatchDestination')}`}
								placeholder={`${t('wms:DispatchDestination')}`}
								margin='8px 0 0 0'
								value={dispatchData.destination}
								onChange={e => {
									setDispatchData({ ...dispatchData, destination: e.target.value })
								}}
								fontSize='small'
								width='40%'
							/>
							<Select
								searchable
								label={t('wms:Transport')}
								value={vehicle && vehicle.id}
								options={
									vehicleList &&
									vehicleList.map(vehicle => ({
										label: vehicle.name,
										value: vehicle.id
									}))
								}
								onChange={value => {
									selectVehicle(value)
								}}
								margin='8px 0 0 0'
								width='40%'
							/>
							{dispatchData &&
								dispatchData.vehicle &&
								dispatchData.vehicle.properties.map(prop => (
									<Input
										width='40%'
										key={prop.name}
										label={`${prop.name}`}
										placeholder={`${prop.defaultvalue}`}
										margin='8px 8px 0 16px'
										value={vehicle[prop.name]}
										onChange={e => {
											setDispatchData({
												...dispatchData,
												vehicle: { ...dispatchData.vehicle, [prop.name]: e.target.value }
											})
										}}
										fontSize='small'
										disabled={!prop.editable}
									/>
								))}
							<Input
								label={`${t('wms:DispatchOccupationFactor')}`}
								placeholder={`${t('wms:DispatchOccupationFactor')}`}
								margin='8px 0 0 8px'
								value={dispatchData && dispatchData.occupationfactor}
								onChange={e => {
									handleOccupationChange(e.target.value)
								}}
								fontSize='small'
								type='number'
								min='0'
								max='100'
								width='40%'
							/>
						</FlexView>
					</FlexView>
					<FlexView flexDirection='row' alignItems='center' justifyContent='center' margin='auto 0 0 auto'>
						<Button
							backgroundColor='error'
							color='white'
							margin='0 8px 0 0'
							onClick={() => setOpenDispatch(false)}
							data-cy='button-cancel-print'>
							{t('wms:Cancel')}
						</Button>
						<Button
							disabled={!checkDispatchInfo()}
							backgroundColor='success'
							color='white'
							margin='0'
							onClick={() => setConfirmModal(true)}
							data-cy='button-print'>
							{t('wms:DispatchMaterial')}
							<Icon name='play' color='white' height='16px' width='16px' margin='0 0 0 8px' />
						</Button>
					</FlexView>
					<DialogModal
						isOpen={confirmModal}
						text={t(`wms:ConfirmItemsDispatch`)}
						onConfirm={handleDispatch}
						onCancel={() => setConfirmModal(false)}
						data-cy='dialog-confirm-change'
					/>
					<LoadingOverlay visible={dispatching} borderRadius='card' />
				</Card>
			) : (
				<Card
					width='70%'
					height='80vh'
					ref={listRef}
					heightstyle={{ alignSelf: 'normal' }}
					data-cy='card-ghost-modal'>
					<LoadingOverlay visible={loadingGhosts} borderRadius='card' />
					<FlexView
						width='-webkit-fill-available'
						flexDirection='row'
						justifyContent='space-between'
						backgroundColor='info'
						fontSize='title'
						fontWeight='bold'
						color='white'
						margin='-16px -24px 8px'
						padding='8px 16px'
						borderRadius='8px 8px 0 0'>
						{t('wms:InventoryGhosts')}
						<Icon
							name='download'
							width='24px'
							height='24px'
							margin='8px'
							color='white'
							fontWeight='bold'
							onClick={() => exportToExcel()}
							tooltip={t('wms:ExportExcel')}
							tooltipPosition='top'
						/>
						<LoadingOverlay visible={extracting} borderRadius='card' />
					</FlexView>
					{data && (
						<Table
							margin='8px'
							width='calc(100% - 8px)'
							maxHeight={`${listHeight}px`}
							style={{ backgroundColor: 'white', elevation: 'none', alignSelf: 'center' }}
							columns={columns}
							data={data}
							paginated
							sortable
							data-cy='table-ghosts'></Table>
					)}
					<FlexView margin='auto 0 0 auto'>
						<Button
							fontSize='medium'
							margin='0 0 0 auto'
							color='white'
							backgroundColor='success'
							disabled={checkDispatchCompletion()}
							onClick={() => openDispatchModal()}>
							{t('wms:DispatchMaterial')}
							<Icon name='play' color='white' height='16px' width='16px' margin='0 0 0 8px' />
						</Button>
					</FlexView>
				</Card>
			)}
		</Modal>
	)
}

export default GhostModal
