import React, { useState, useEffect } from 'react'
import theme from 'utils/theme'
import { ThemeProvider } from 'styled-components'
import { convertLength } from '../utils/length-unit'

const initialState = {
  name: process.env.REACT_APP_NAME,
  version: process.env.REACT_APP_VERSION
}

export const AppContext = React.createContext(initialState)

export const AppProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState(initialState.name)
  const [isHeaderVisible, setHeaderVisibility] = useState(true)

    // Force measurementSystem
    const [unitSystem, setUnitSystem] = useState(null)

    // const [lengthUnit, setLengthUnit] = useState(null)
    // const [weightUnit, setWeightUnit] = useState(null)

    useEffect(() => {
      let localUnitSystem = localStorage.getItem('unitSystem')
      if (!localUnitSystem) {
        localUnitSystem = 'International'
        localStorage.setItem('unitSystem', localUnitSystem)
      }
      setUnitSystem(localUnitSystem)
    }, [])

    const round = (number, decimalPlaces) => {
      const factorOfTen = Math.pow(10, decimalPlaces)
      return Math.round(number * factorOfTen) / factorOfTen
    }

    const convertLengthToImperialUnit = (value) => {
      if (!value) {
        return value
      }
      return round(value * 0.0393701, 2)
    }

    const convertOuterDiameterToImperialUnit = (value) => {
      if (!value) {
        return value
      }
      return convertLength(value, true, ['in'])
    }

    const convertTorqueToImperialUnit = (value) => {
      if (!value) {
        return value
      }
      return round(value * 0.737562, 2)
    }

    const convertLengthToInternacionalUnit = (value) => {
      if (!value) {
        return value
      }
      if (localStorage.getItem('unitSystem') === 'International') {
        return value
      }
      return round(value / 0.0393701, 2)
    }

    const convertOuterDiameterToInternacionalUnit = (value) => {
      if (!value) {
        return value
      }
      if (localStorage.getItem('unitSystem') === 'International') {
        return value
      }
      return convertLength(value, true, ['mm'])
    }

    const convertTorqueToInternacionalUnit = (value) => {
      if (!value) {
        return value
      }
      if (localStorage.getItem('unitSystem') === 'International') {
        return value
      }
      return round(value / 0.737562, 2)
    }

    const convertToCurrentLengthUnitSystem = (value) => {
      return localStorage.getItem('unitSystem') === 'Imperial' ? convertLengthToImperialUnit(value) : value
    }

    const convertToCurrentTorqueUnitSystem = (value) => {
      return localStorage.getItem('unitSystem') === 'Imperial' ? convertTorqueToImperialUnit(value) : value
    }

    const convertToCurrentOuterDiameterUnitSystem = (value) => {
      return localStorage.getItem('unitSystem') === 'Imperial' ? convertOuterDiameterToImperialUnit(value) : value.toFixed(2).toString()
    }


    const changeUnitSystem = () => {
      if (unitSystem === 'International') {
        localStorage.setItem('unitSystem', 'Imperial')
        setUnitSystem('Imperial')
      } else {
        localStorage.setItem('unitSystem', 'International')
        setUnitSystem('International')
      }
    }

  return <ThemeProvider theme={theme}>
    <AppContext.Provider value={{
      ...initialState,
      pageTitle,
      isHeaderVisible,
      setPageTitle,
      setHeaderVisibility,
      unitSystem,
      convertToCurrentTorqueUnitSystem,
      convertToCurrentLengthUnitSystem,
      convertToCurrentOuterDiameterUnitSystem,
      convertLengthToInternacionalUnit,
      convertTorqueToInternacionalUnit,
      convertOuterDiameterToInternacionalUnit,
      changeUnitSystem,
      round
    }}>
      {children}
    </AppContext.Provider>
  </ThemeProvider>
}
