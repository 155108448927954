/** @format */

import { FlexView } from 'components/common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import LevelList from '../containers/assets/levels/LevelList'
import PageTemplate from '../containers/common/PageTemplate'
import { assets, genParams } from '../utils/hubs'

const AssetsLevelsPage = () => {
	const { t } = useTranslation()

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:Levels'),
				path: '/wms/assets/levels'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' height='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:Levels')}
				</FlexView>
				<LevelList />
			</FlexView>
		</PageTemplate>
	)
}

export default AssetsLevelsPage
