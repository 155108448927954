/** @format */

import _ from 'lodash'
import { REPORT_STATUS } from '../enum'

const formatReceiptNotes = receiptNotes => {
	return _.map(receiptNotes, receiptNote => ({
		id: receiptNote.id,
		listid: receiptNote.id,
		name: receiptNote.name,
		closuredate: receiptNote.closuredate,
		status: receiptNote.status,
		quantity: validatedQuantity(receiptNote),
		maxqty: _.sumBy(receiptNote.content, 'quantity'),
		quantitylabel: `${validatedQuantity(receiptNote)}/${_.sumBy(receiptNote.content, 'quantity')}`,
		subRows: formatSubRows(receiptNote),
		children: formatSubRows(receiptNote)
	}))
}

const validatedQuantity = receiptNote =>
	_.chain(receiptNote.content)
		.filter(f => f.validationdata.status === REPORT_STATUS.code.VALIDATED)
		.sumBy('quantity')

const formatSubRows = receiptNote => {
	if (receiptNote.content.length === 0) return []

	return _.map(receiptNote.content, content => ({
		id: receiptNote.id,
		partid: content.belcid,
		status: content.validationdata.status,
		part: content.partnumber,
		closuredate: content.validationdata.laststatuschange,
		quantitylabel: content.quantity.toString(),
		validationdata: content.validationdata
	}))
}

export { formatReceiptNotes }
