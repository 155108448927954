import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Card, FlexView, Button } from 'components/common'
import { useTranslation } from 'react-i18next'
import Input from './Input'

const DialogModal = ({ isOpen, onConfirm, onCancel, title, text, confirmationText, confirmText, cancelText, danger }) => {
  const [confirmation, setConfirmation] = useState('')
  const { t } = useTranslation()

  const onConfirmationChange = e => {
    setConfirmation(e.target.value)
  }

  const onInputKeyPress = e => {
    e.key === 'Enter' && confirmation === confirmationText && onConfirm()
  }

  useEffect(() => {
    !isOpen && setConfirmation('')
  }, [isOpen])

  return <Modal isOpen={isOpen} onOutsideClick={onCancel}>
    <Card minWidth="300px" maxWidth="800px">
      <FlexView fontSize="big" fontWeight="bold" margin="0px 0px 8px">{title || t('Confirm')}</FlexView>
      <FlexView flexDirection="column">
        {(text.split("\n")).map((t, index) => (
          <FlexView key={index}>{t}<br/></FlexView>
        ))}
      </FlexView>
      {confirmationText && <Input value={confirmation} onChange={onConfirmationChange} placeholder={confirmationText} onKeyPress={onInputKeyPress} width="100%" margin="8px 0px 0px" />}
      <FlexView flexDirection="row" alignSelf="stretch" justifyContent="flex-end" margin="16px 0px 0px">
        <Button margin="0px" onClick={onCancel}>{cancelText || t('No')}</Button>
        <Button margin="0px 0px 0px 16px" backgroundColor={danger ? 'error' : 'success'} color="white" disabled={confirmationText && confirmationText !== confirmation} onClick={onConfirm}>{confirmText || t('Yes')}</Button>
      </FlexView>
    </Card>
  </Modal>
}

DialogModal.propTypes = {
  /**
   * Boolean to control if the dialog is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * Function called when the user confirms the dialog
   */
  onConfirm: PropTypes.func,
  /**
   * Function called when the user cancel the dialog or clicks outside of it
   */
  onCancel: PropTypes.func,
  /**
   * Dialog modal title
   */
  title: PropTypes.string,
  /**
   * Dialog modal text
   */
  text: PropTypes.string,
  /**
   * Confirmation text that the user must input before confirming the dialog
   */
  confirmationText: PropTypes.string,
  /**
   * Text that will appear in the confirm button
   */
  confirmText: PropTypes.string,
  /**
   * Text that will appear in the cancel button
   */
  cancelText: PropTypes.string,
  /**
   * Change confirmation button color to indicate danger
   */
  danger: PropTypes.bool
}

DialogModal.defaultProps = {
  isOpen: false
}

export default DialogModal
