/** @format */
/* eslint-disable react/prop-types */

import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { Card, FlexView } from 'components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'utils/theme'

const EquipmentInfo = ({ selectedEquipment }) => {
	const { t, i18n } = useTranslation()

	return (
		<Card width='calc(100% - 48px)' flex='1' margin='0' data-cy='card-info-wrapper'>
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:EquipmentInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:EquipmentType')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedEquipment.type}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:EquipmentReference')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedEquipment.reference}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:EquipmentStatus')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{t(`wms:${selectedEquipment.status}`)}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:CalibrationDate')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{`${DATE_FORMAT(selectedEquipment.calibdate, i18n.language)}`}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column' data-cy='card-info'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:NextCalibrationDate')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{`${DATE_FORMAT(selectedEquipment.nextcalib, i18n.language)}`}
					</FlexView>
				</FlexView>
			</FlexView>
		</Card>
	)
}

export default EquipmentInfo
