/** @format */

import { AssetsAttributesContext } from 'apps/wms/stores/AssetsAttributesStore'
import { Button, Card, FlexView, Icon, Modal } from 'components/common'
import { Input } from 'components/form'
import _ from 'lodash'
import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from '../../../components/prepareEntrance/Select'

// eslint-disable-next-line react/prop-types
const AddAttributeModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { saveAttr } = useContext(AssetsAttributesContext)

	const validations = [
		{
			type: t('wms:ValidationString'),
			validationregex: '^.+$',
			validationexample: 'VS-01'
		},
		{
			type: t('wms:ValidationFloatingPoint'),
			validationregex: '^([0-9]*[.,])?[0-9]+$',
			validationexample: (1.23).toLocaleString()
		},
		{
			type: t('wms:ValidationInteger'),
			validationregex: '^[0-9]+$',
			validationexample: '123'
		},
		{
			type: t('wms:ValidationFraction'),
			validationregex: '^[0-9]*([ ]*[0-9]+/+[0-9]+)*$',
			validationexample: '9 5/8'
		}
	]

	const [newAttr, setNewAttr] = useState({
		name: '',
		description: '',
		symbol: '',
		validationregex: '^.+$',
		validationexample: 'VS-01',
		isnew: true
	})

	const selectValidation = value => {
		setNewAttr({
			...newAttr,
			validationregex: value,
			validationexample: _.find(validations, v => v.validationregex === value).validationexample
		})
	}

	const saveAttribute = value => {
		saveAttr(value)
			.then(
				setNewAttr({
					name: '',
					description: '',
					symbol: '',
					validationregex: null,
					validationexample: null,
					isnew: true
				})
			)
			.catch(e => console.error(e))
	}

	const checkProperties = () => {
		let isDisabled = true
		isDisabled = isDisabled && (!newAttr.name || !newAttr.validationregex)
		return isDisabled
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='80%' data-cy='card-add-optional-attributes'>
				<FlexView width='100%' fontSize='subtitle' fontWeight='bold'>
					{t('wms:Add')} {t('wms:Attribute')}
				</FlexView>
				<FlexView flexDirection='row' width='100%' justifyContent='space-around' margin='16px 0 0 0'>
					<Input
						type='text'
						label={t('wms:Name')}
						placeholder={t('wms:Name')}
						value={newAttr.name}
						onChange={e => setNewAttr({ ...newAttr, name: e.target.value })}
						data-cy='input-optional-attr-name'
						success={newAttr.name !== ''}
						error={newAttr.name === ''}
					/>
					<Input
						type='text'
						label={t('wms:Description')}
						placeholder={t('wms:Description')}
						value={newAttr.description}
						onChange={e => setNewAttr({ ...newAttr, description: e.target.value })}
						data-cy='input-optional-attr-description'
					/>
					<Input
						type='text'
						label={t('wms:Symbol')}
						placeholder={t('wms:Symbol')}
						value={newAttr.symbol}
						onChange={e => setNewAttr({ ...newAttr, symbol: e.target.value })}
						data-cy='input-optional-attr-symbol'
					/>
					<Select
						success={newAttr.validationregex !== null}
						error={newAttr.validationregex === null}
						width='25%'
						label={t('wms:ValidationType')}
						placeholder={t('wms:ValidationType')}
						options={
							validations &&
							_.map(validations, val => {
								return { value: val.validationregex, label: val.type }
							})
						}
						value={newAttr.validationregex}
						onChange={v => selectValidation(v)}
						data-cy='select-validation-type'
					/>
				</FlexView>
				<FlexView width='100%' flexDirection='row' margin='auto 0 0 0' justifyContent='center' alignItems='center'>
					<Button backgroundColor='error' color='white' onClick={onOutsideClick} data-cy='button-cancel-add-attr'>
						{t('wms:Cancel')}
						<Icon name='cross-simple' color='white' width='14px' height='14px' margin='0 0 0 8px' />
					</Button>
					<Button
						backgroundColor='success'
						color='white'
						onClick={() => saveAttribute(newAttr)}
						margin='0 0 0 16px'
						disabled={checkProperties()}
						data-cy='button-save-new-attribute'>
						{t('wms:Save')}
						<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default AddAttributeModal
