/** @format */

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexView, Card, Button, LoadingOverlay, Icon } from 'components/common'
import Table from '../../components/reports/ExpandedTable'
import { INSPECTION_STAGES, REPORT_STATUS } from '../../utils/enum'
import moment from 'moment'
import { ReportsContext } from 'apps/wms/stores/ReportStore'
import Pagination from 'apps/wms/components/reports/Pagination'
import ProgressBar from 'apps/wms/components/reports/ProgressBar'
import { ProgressColumn } from 'apps/wms/utils/reports/tableColumnsFormatter'

const InspReportList = ({
	loadingList,
	list = [],
	onSelectReport,
	onDownloadReport,
	onCheckReport,
	downloadingReport,
	inspName,
	filteredReports = []
}) => {
	const {
		canPreviousPage,
		setCanPreviousPage,
		canNextPage,
		setCanNextPage,
		totalPages,
		totalItems,
		currentPage,
		setCurrentPage,
		setFetchingFirstPage,
		setFetchingLastPage,
		getMoreReports,
		selectedRow,
		setSelectedRow,
		selectedSubRow,
		setSelectedSubRow,
		setPartDetails,
		inspSelected
	} = useContext(ReportsContext)
	const { t, i18n } = useTranslation()

	const breakSpaceString = 'break-spaces'

	const selectRow = row => {
		if (row.depth > 0) {
			setSelectedSubRow({
				listid: row.original.id,
				id: row.id,
				depth: row.depth,
				partid: row.original.partid,
				part: row.original.part,
				closuredate: row.original.closuredate
			})
			setSelectedRow(null)
		} else {
			setSelectedRow({
				listid: row.original.listid,
				id: row.id,
				depth: row.depth
			})
			setPartDetails({})
			setSelectedSubRow(null)
		}
		onSelectReport(Number(row.original.id || row.original.listid), row.depth)
	}

	const handleDownloadReport = (reportId, partNumber) => {
		reportId != null && partNumber && onDownloadReport(reportId, partNumber)
	}

	const handleCheckReport = () => {
		onCheckReport()
	}

	const periodicColumns = useMemo(
		() => [
			{
				id: 'expander',
				Header: ' ',
				width: '10px',
				Cell: ({ cell: { row } }) => (
					<FlexView
						flexDirection='row'
						alignItems='center'
						justifyContent='center'
						width='10px'
						fontSize='14px'
						margin='0'
						padding='0'
						style={{ whiteSpace: breakSpaceString, textAlign: 'center' }}
						{...row.getToggleRowExpandedProps({
							style: {
								paddingLeft: `${row.depth * 32}px`,
								cursor: row.canExpand ? 'pointer' : 'inherit'
							},
							title: ''
						})}>
						{row.canExpand && (
							<Icon
								name={row.isExpanded ? 'chevron-up' : 'chevron-down'}
								width='12px'
								height='12px'
								color={
									(selectedRow && selectedRow.id === row.id) ||
									(selectedRow && selectedRow.id === row.id && row.isExpanded)
										? 'white'
										: 'gray'
								}
							/>
						)}
					</FlexView>
				)
			},
			{
				Header: 'ID',
				accessor: 'listid',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:ErpReference'),
				accessor: 'erpreference',
				width: '80px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'
							style={{ whiteSpace: breakSpaceString }}>
							{value || '****'}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:CustomerOrder'),
				accessor: 'customerorder',
				width: '150px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'
							style={{ whiteSpace: breakSpaceString }}>
							{value || '****'}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:CustomerItem'),
				accessor: 'customeritem',
				width: '100px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'
							style={{ whiteSpace: breakSpaceString }}>
							{value || '****'}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:SalesOrder'),
				accessor: 'salesorder',
				width: '70px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'
							style={{ whiteSpace: breakSpaceString }}>
							{value || '****'}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:SalesItem'),
				accessor: 'salesitem',
				width: '70px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'
							style={{ whiteSpace: breakSpaceString }}>
							{value || '****'}
						</FlexView>
					)
				}
			},
			{
				Header: `${t('wms:DateOf')} ${inspName}`,
				accessor: 'closuredate',
				width: '150px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'
							style={{ whiteSpace: breakSpaceString }}>
							{(moment.locale(`${i18n.language}`) && moment(value).format('L')) || '****'}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Status'),
				accessor: 'status',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0'
						padding='0'
						fontSize='16px'
						fontWeight='bold'
						color={REPORT_STATUS.color[value]}>
						{t(`${REPORT_STATUS.translateKey[value]}`)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Qty'),
				accessor: 'quantitylabel',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:Progress'),
				width: '130px',
				Cell: ({ cell: { row } }) => (
					<>
						{row.depth === 0 && (
							<ProgressBar
								color={
									row.original.quantity >= row.original.maxqty
										? 'linear-gradient(90deg, #1F8600 0%, #34A06E 100%)'
										: 'linear-gradient(90deg, #F47408 0%, #FFB134 100%)'
								}
								quantity={(row.original.quantity / row.original.maxqty) * 100}></ProgressBar>
						)}
						{row.original.status === REPORT_STATUS.code.VALIDATED && (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0 4px 0'
								color='white'
								backgroundColor='primary'
								disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
								elevation='none'
								onClick={() => {
									handleDownloadReport(row.original.id, row.original.part)
								}}
								data-cy='button-download-report'>
								{t('wms:DownloadReport')}
							</Button>
						)}
						{(row.original.status === REPORT_STATUS.code.PENDING ||
							row.original.status === REPORT_STATUS.code.REJECTED) && (
							<Button
								width='100%'
								fontSize='tiny'
								margin='4px 0 4px 0'
								color='white'
								backgroundColor='secondary'
								onClick={() => {
									handleCheckReport()
								}}
								data-cy='button-check-report'>
								{t('wms:CheckReport')}
							</Button>
						)}
					</>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[downloadingReport, i18n.language, inspName, t]
	)

	const inspColumns = useMemo(
		() => [
			{
				id: 'expander',
				Header: ' ',
				width: '10px',
				Cell: ({ cell: { row } }) => (
					<FlexView
						flexDirection='row'
						alignItems='center'
						justifyContent='center'
						width='10px'
						fontSize='14px'
						margin='0'
						padding='0'
						style={{ whiteSpace: breakSpaceString, textAlign: 'center' }}
						{...row.getToggleRowExpandedProps({
							style: {
								paddingLeft: `${row.depth * 32}px`,
								cursor: row.canExpand ? 'pointer' : 'inherit'
							},
							title: ''
						})}>
						{row.canExpand && (
							<Icon
								name={row.isExpanded ? 'chevron-up' : 'chevron-down'}
								width='12px'
								height='12px'
								color={
									(selectedRow && selectedRow.id === row.id) ||
									(selectedRow && selectedRow.id === row.id && row.isExpanded)
										? 'white'
										: 'gray'
								}
							/>
						)}
					</FlexView>
				)
			},
			{
				Header: 'ID',
				accessor: 'listid',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						flexDirection='row'
						alignItems='center'
						justifyContent='center'
						fontSize='14px'
						margin='0'
						padding='0'
						style={{
							whiteSpace: breakSpaceString,
							textAlign: 'center'
						}}>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:Parts'),
				accessor: 'part',
				width: 'auto',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:WorkOrderDescription'),
				accessor: 'workorderdescription',
				width: '200px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'
							style={{ whiteSpace: breakSpaceString }}>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:CustomerRequestReference'),
				accessor: 'customerrequestreference',
				width: '200px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'
							style={{ whiteSpace: breakSpaceString }}>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: `${t('wms:DateOf')} ${inspName}`,
				accessor: 'closuredate',
				width: '150px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='14px'
							margin='0'
							padding='0'
							style={{ whiteSpace: breakSpaceString }}>
							{(moment.locale(`${i18n.language}`) && moment(value).format('L')) || '*****'}
						</FlexView>
					)
				}
			},
			{
				id: 'workorderstatus',
				Header: t('wms:Status'),
				accessor: 'status',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0'
						padding='0'
						fontSize='16px'
						fontWeight='bold'
						color={REPORT_STATUS.color[value]}>
						{t(`${REPORT_STATUS.translateKey[value]}`)}
					</FlexView>
				)
			},
			{
				Header: t('wms:Qty'),
				accessor: 'quantitylabel',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='14px'>
						{value}
					</FlexView>
				)
			},
			{
				Header: t('wms:Progress'),
				width: '130px',
				Cell: ({ cell: { row } }) => (
					<ProgressColumn
						row={row}
						handleDownloadReport={handleDownloadReport}
						handleCheckReport={handleCheckReport}
					/>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleDownloadReport, i18n.language, t]
	)

	const getDataCY = () => {
		return 'list-reports'
	}

	return (
		<Card
			data-cy='card-list-wrapper'
			height='90%'
			width='65%'
			margin='0'
			style={{ position: 'relative', overflowY: 'auto' }}>
			<LoadingOverlay visible={loadingList} borderRadius='card' />
			{list && list.length ? (
				<Table
					margin='0'
					width='100%'
					maxWidth='100%'
					textAlign='center'
					elevation='none'
					fontSize='14px'
					columns={
						inspSelected && inspSelected.stage === INSPECTION_STAGES.code.PERIODICAL
							? periodicColumns
							: inspColumns
					}
					data={filteredReports && filteredReports.length ? filteredReports : list}
					sortable
					gridless
					data-cy={getDataCY()}
					selectedRow={selectedRow}
					selectedSubRow={selectedSubRow}
					onRowClick={value => selectRow(value)}
				/>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='placeholder-reports-list'>
					{t('wms:NoReport')}
				</FlexView>
			)}
			<FlexView width='100%' margin='16px 0 0 0'>
				{totalItems > 50 && (
					<Pagination
						canPreviousPage={canPreviousPage}
						setCanPreviousPage={setCanPreviousPage}
						canNextPage={canNextPage}
						setCanNextPage={setCanNextPage}
						totalPages={totalPages}
						totalItems={totalItems}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						setFetchingFirstPage={setFetchingFirstPage}
						setFetchingLastPage={setFetchingLastPage}
						getMoreReports={getMoreReports}
					/>
				)}
			</FlexView>
		</Card>
	)
}

export default InspReportList
