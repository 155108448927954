/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

const newInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/v2/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getAttributes: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/attributes/${wid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.attributes)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getAllAttValues: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/attributes/${wid}/value`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.attributevalues)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	perInspConfig: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/perinsp/${wid}/config`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.config)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	pipeAgeConfig: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/agemgmt/${wid}/config`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.config)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	modifProdConfig: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/modifprod/${wid}/config`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.config)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	fetchOwnerships: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/ownership/${wid}/description`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.ownershipdescriptions)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getAttributeValues: (wid, attrid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/attributes/${wid}/value?attributeid=${encodeURIComponent(attrid)}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.attributevalues)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateAgeMgmtConfig: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`agemgmt/${wid}/config`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve()
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateModifProdConfig: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`modifprod/${wid}/config`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve()
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	savePerInspConfig: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`perinsp/${wid}/config`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve()
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getOwnerships: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`ownership/${wid}/description`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	expirationTimeConfig: (wid, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`inspection/config/${wid}/expiration-time`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	saveExpirationTimeConfig: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.post(`inspection/config/${wid}/expiration-time`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if ([200, 201, 204].includes(response.status)) {
						resolve()
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateExpirationTimeConfig: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.put(`inspection/config/${wid}/expiration-time`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve()
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
