/** @format */
/* eslint-disable react/prop-types */

import { AssetsTransportContext } from 'apps/wms/stores/AssetsTransportStore'
import { Button, Card, FlexView, Icon, Modal } from 'components/common'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Input, Toggle } from 'components/form'
import { WMSContext } from 'apps/wms/stores/WmsStore'

const UpdateVehicleModal = ({ isOpen, onOutsideClick, vehicle }) => {
	const { t } = useTranslation()
	const { weightUnit, numConvertToCurrentWeightUnit } = useContext(WMSContext)
	const { toUpdateVehicle, updateVehicle, setUpdateVehicle } = useContext(AssetsTransportContext)

	useEffect(() => {
		vehicle &&
			setUpdateVehicle({
				...vehicle,
				maxweightkg: numConvertToCurrentWeightUnit(vehicle.maxweightkg, weightUnit),
				properties: [...vehicle.properties]
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vehicle, numConvertToCurrentWeightUnit, weightUnit])

	const addProperty = () => {
		updateVehicle.properties.push({
			name: '',
			description: '',
			defaultvalue: '',
			editable: false
		})
		setUpdateVehicle({ ...updateVehicle })
	}

	const addAttrName = (value, i) => {
		updateVehicle.properties[i].name = value
		setUpdateVehicle({ ...updateVehicle, properties: [...updateVehicle.properties] })
	}

	const addAttrDesc = (value, i) => {
		updateVehicle.properties[i].description = value
		setUpdateVehicle({ ...updateVehicle, properties: [...updateVehicle.properties] })
	}

	const addAttrValue = (value, i) => {
		updateVehicle.properties[i].defaultvalue = value
		setUpdateVehicle({ ...updateVehicle, properties: [...updateVehicle.properties] })
	}

	const addEditableProp = (value, i) => {
		updateVehicle.properties[i].editable = value
		setUpdateVehicle({ ...updateVehicle, properties: [...updateVehicle.properties] })
	}

	const checkDeleteRules = index => {
		let isDisabled = true
		index === 0 ? (isDisabled = true) : (isDisabled = false)
		return isDisabled
	}

	const deleteProperty = index => {
		if (
			updateVehicle &&
			updateVehicle.properties &&
			updateVehicle.properties.length &&
			index < updateVehicle.properties.length
		) {
			updateVehicle.properties.splice(index, 1)
			setUpdateVehicle({ ...updateVehicle, properties: [...updateVehicle.properties] })
		}
	}

	const saveVehicle = async () => {
		try {
			await toUpdateVehicle(updateVehicle)
			setUpdateVehicle({
				name: '',
				maxweightkg: 0,
				properties: []
			})
			onOutsideClick()
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		if (updateVehicle) {
			checkProperties()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateVehicle])

	const checkProperties = () => {
		let isDisabled = true
		if (
			updateVehicle.properties.length === 0 ||
			updateVehicle.properties.some(prop => prop.name === '') ||
			updateVehicle.properties.some(prop => prop.description === '') ||
			updateVehicle.properties.find(prop => checkWhiteSpaces(prop.name))
		) {
			isDisabled = true
		} else if (checkWhiteSpaces(updateVehicle.name)) {
			isDisabled = true
		} else {
			isDisabled = false
		}

		return isDisabled
	}

	const checkWhiteSpaces = name => {
		return name.indexOf(' ') >= 0
	}

	const inputAttrMessage = index => {
		if (checkWhiteSpaces(updateVehicle.properties[index].name)) {
			return t('wms:TransportPropNameNotAllowed')
		} else {
			return t('wms:TransportPropertyNameHelp')
		}
	}

	const inputAttrMessageType = index => {
		if (checkWhiteSpaces(updateVehicle.properties[index].name)) {
			return 'error'
		} else {
			return 'info'
		}
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='60%' height='85%' maxHeight='85%' data-cy='card-update-vehicle'>
				<FlexView width='100%' height='100%'>
					<FlexView
						width='100%'
						fontSize='big'
						flexDirection='row'
						fontWeight='bold'
						margin='0 0 8px 0'
						justifyContent='space-between'>
						{t('wms:UpdateVehicle')}
						<Button fontSize='tiny' margin='0' backgroundColor='error' disabled={false} onClick={onOutsideClick}>
							<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
						</Button>
					</FlexView>
					<FlexView flexDirection='column' width='100%' height='85%' maxHeight='85%' margin='16px 0'>
						<Card width='95%' margin='0' data-cy='card-vehicle-name'>
							<FlexView flexDirection='row' width='100%'>
								<Input
									width='50%'
									margin='0'
									type='text'
									label={t('wms:TransportType')}
									value={updateVehicle && updateVehicle.name}
									disabled={true}
									data-cy='input-vehicle-name'
								/>
								<Input
									margin='0'
									width='50%'
									type='number'
									min='1'
									max='999999'
									placeholder={t('wms:MaximumPayload')}
									label={`${t('wms:MaximumPayload')} [${weightUnit}]`}
									value={updateVehicle && updateVehicle.maxweightkg}
									onChange={e => setUpdateVehicle({ ...updateVehicle, maxweightkg: Math.abs(e.target.value) })}
									data-cy='input-vehicle-weight'
								/>
								<Button
									backgroundColor='secondary'
									color='white'
									margin='16px 0 0 0'
									style={{ alignSelf: 'center' }}
									onClick={() => addProperty()}
									data-cy='button-add-property'>
									{t('wms:AddProperty')}
									<Icon name='add' color='white' width='14px' height='14px' margin='0 0 0 8px' />
								</Button>
							</FlexView>
						</Card>
						<FlexView
							flexDirection='column'
							width='100%'
							height='100%'
							margin='16px 0'
							style={{ overflowY: 'scroll', position: 'relative' }}>
							<FlexView width='100%' fontSize='big' fontWeight='bold' margin='0 0 8px 0'>
								{t('wms:Properties')}:
							</FlexView>
							{updateVehicle &&
								updateVehicle.properties.length > 0 &&
								_.map(updateVehicle.properties, (property, index) => (
									<Card key={index} width='95%' margin='16px 0 0 0' data-cy='card-update-properties'>
										<FlexView width='100%' flexDirection='row'>
											<Input
												margin='0 8px 0 0'
												width='50%'
												message={inputAttrMessage(index)}
												messageType={inputAttrMessageType(index)}
												type='text'
												label={t('wms:AttributeName')}
												placeholder={t('wms:AttributeName')}
												value={property.name}
												onChange={e => addAttrName(e.target.value, index)}
												data-cy='input-attribute-name'
											/>
											<Input
												margin='0 8px 0 0'
												type='text'
												label={t('wms:Description')}
												placeholder={t('wms:Description')}
												value={property.description}
												onChange={e => addAttrDesc(e.target.value, index)}
												data-cy='input-attribute-description'
											/>
										</FlexView>
										<FlexView width='100%' flexDirection='row' margin='16px 0 0 0'>
											<Input
												margin='0 8px 0 0'
												type='text'
												label={t('wms:DefaultValue')}
												placeholder={t('wms:DefaultValue')}
												value={property.defaultvalue}
												onChange={e => addAttrValue(e.target.value, index)}
												data-cy='input-default-value'
											/>
											<FlexView flexDirection='column' margin='8px auto'>
												<FlexView fontSize='medium' fontWeight='bold'>{`${t(
													'wms:CanBeEdited'
												)}?`}</FlexView>
												<Toggle
													textOff={t('wms:No')}
													textOn={t('wms:Yes')}
													alternate
													state={property.editable}
													onChange={v => addEditableProp(v, index)}
													data-cy='toggle-can-be-edited'></Toggle>
											</FlexView>
										</FlexView>
										<Button
											backgroundColor='error'
											color='white'
											margin='auto 0 auto auto'
											onClick={() => deleteProperty(index)}
											disabled={checkDeleteRules(index)}
											data-cy='button-delete-property'>
											{`${t('wms:Delete')} ${t('wms:Property')}`}
											<Icon
												name='trash'
												color='white'
												fontWeight='bold'
												width='14px'
												height='14px'
												margin='0 0 0 8px'
											/>
										</Button>
									</Card>
								))}
						</FlexView>
					</FlexView>
					<FlexView
						flexDirection='row'
						width='100%'
						margin='auto 0 0 0'
						alignItems='flex-end'
						justifyContent='flex-end'>
						{updateVehicle && (
							<Button
								disabled={checkProperties()}
								backgroundColor='success'
								color='white'
								margin='0'
								onClick={() => saveVehicle()}
								data-cy='button-save-vehicle'>
								{t('wms:Save')}
								<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
							</Button>
						)}
					</FlexView>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default UpdateVehicleModal
