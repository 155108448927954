/** @format */

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexView, Card } from 'components/common'
import PageTemplate from '../containers/common/PageTemplate'
import CloudLogsForm from '../containers/cloudLogs/CloudLogsForm'
import CloudLogsTable from '../containers/cloudLogs/CloudLogsTable'
import CloudLogsModal from '../containers/cloudLogs/CloudLogsModal'

const CloudLogsPage = () => {
	const { t } = useTranslation()

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:CloudLogs'),
				path: '/wms/cloud-logs'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' alignItems='stretch' position='relative'>
				<Card margin='16px 16px 0'>
					<FlexView fontSize='title' fontWeight='bold'>
						{t('wms:CloudLogs')}
					</FlexView>
					<FlexView width='100%' position='relative' minHeight='100px' margin='24px 0 0 0'>
						<CloudLogsForm />
					</FlexView>
				</Card>
				<CloudLogsTable />
				<CloudLogsModal />
			</FlexView>
		</PageTemplate>
	)
}

export default CloudLogsPage
