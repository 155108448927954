import React from 'react'
import { ThemeProvider } from 'styled-components'
import { AppProvider } from './stores/AppStore'
import { UserProvider } from './stores/UserStore'
import theme from './utils/theme'
import Router from './Router'
import { toast, Slide } from 'react-toastify'

toast.configure({
  autoClose: 5000,
  draggable: false,
  transition: Slide,
  hideProgressBar: false,
  closeButton: false
})

const App = () => (
  <ThemeProvider theme={theme}>
    <UserProvider>
      <AppProvider>
        <Router />
      </AppProvider>
    </UserProvider>
  </ThemeProvider>
)

export default App
