/** @format */

import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { UserContext } from 'stores/UserStore'
import cloudLogs from '../api/cloudLogs'

export const CloudLogsContext = React.createContext(null)

export const CloudLogsProvider = ({ children }) => {
	const { t, i18n } = useTranslation()
	const { token } = useContext(UserContext)
	const [logsObject, setLogsObject] = useState(null)
	const [selectedLog, setSelectedLog] = useState(null)
	const [fetchingLogs, setFetchingLogs] = useState(false)
	const [allType, setAllType] = useState(i18n.language === 'en' ? 'All' : 'Todos')
	const [currentPage, setCurrentPage] = useState(1)
	const [formData, setFormData] = useState({
		group: '',
		date: moment(),
		type: allType,
		filter: ''
	})

	const changeFormData = (prop, value) => {
		if (prop !== 'filter') {
			setFormData(data => ({ ...data, [prop]: value }))
			return
		}

		setFormData(data => ({ ...data, filter: value.target.value }))
		value.persist()
	}

	const isFormValid = () => {
		return formData.group && formData.date && formData.type
	}

	const fetchLogs = (nextToken = null) => {
		setFetchingLogs(true)

		const sendData = {
			token,
			group: formData.group,
			date: formData.date.format('YYYY-MM-DD'),
			type: formData.type !== allType ? formData.type : null,
			filter: formData.filter,
			nextToken: nextToken,
			limit: 20
		}

		cloudLogs
			.getLogs(...Object.values(sendData))
			.then(data => {
				setLogsObject(data)
			})
			.catch(() => {
				setLogsObject(null)
				toast.error(t(`wms:ErrorGettingCloudLogs`))
			})
			.finally(() => setFetchingLogs(false))
	}

	return (
		<CloudLogsContext.Provider
			value={{
				logsObject,
				setLogsObject,
				selectedLog,
				setSelectedLog,
				fetchingLogs,
				setFetchingLogs,
				allType,
				setAllType,
				currentPage,
				setCurrentPage,
				formData,
				changeFormData,
				isFormValid,
				fetchLogs
			}}>
			{children}
		</CloudLogsContext.Provider>
	)
}
