/** @format */

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexView } from 'components/common'

import { ReportsContext } from '../stores/ReportStore'
import TabsRow from '../components/commom/TabsRow'
import { INSPECTION_STAGES, REPORT_TYPE } from '../utils/enum'
import ExportReportList from '../containers/reports/ExportList'
import { useParams } from 'react-router-dom'
import PageTemplate from '../containers/common/PageTemplate'
import { useWindowSize } from '../utils/hooks'
import _ from 'lodash'
import SearchBar from '../containers/common/SearchBar'
import { WMSContext } from '../stores/WmsStore'

const ExportReportsPage = () => {
	const { t } = useTranslation()
	const {
		reportType,
		setReportType,
		loadingReports,
		exportReportsList,
		getDispatchReports,
		getOtherReports,
		downloadBackloadManifest,
		downloadDeliveryNote,
		downloadDeliveryNotePdf,
		downloadDispatchReport,
		downloadModifProductsReport,
		inspections,
		inspSelected,
		setInspSelected,
		inspName,
		setInspName,
		getMoreDeliveryNotes,
		getMoreBackloadManifests,
		getMoreModifProductReports,
		getDeliveryNotesTitleTransfer,
		downloadTitleTransferReport,
		downloadingReport,
		downloadTitleTransferReportPdf,
		keyWord,
		setKeyWord,
		searchReports,
		filteredExportList,
		getReports
	} = useContext(ReportsContext)
	const { warehouse } = useContext(WMSContext)

	const [matReqInspections, setMatReqInspections] = useState([])
	const [rigReturnInspections, setRigReturnInspections] = useState([])
	const [modifProdInspections, setModifProdInspections] = useState([])

	const listDeadSpace = 100
	const windowSize = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setMatReqInspections(_.filter(inspections, insp => insp.stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION))
	}, [inspections])

	useEffect(() => {
		setRigReturnInspections(
			_.filter(inspections, insp => insp.stage === INSPECTION_STAGES.code.RETURN && !insp.is_modified_product)
		)
	}, [inspections])

	useEffect(() => {
		setModifProdInspections(
			_.filter(inspections, insp => insp.stage === INSPECTION_STAGES.code.RETURN && insp.is_modified_product)
		)
	}, [inspections])

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, windowSize])

	const [page, setPage] = useState(null)
	let { report } = useParams()

	useEffect(() => {
		setPage(report)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report])

	useEffect(() => {
		if (page === 'delivery_notes' && inspName !== null) {
			getReports(inspName, page).catch(e => console.error(e))
		} else if (page === 'dispatch') {
			setReportType(REPORT_TYPE.code.DISPATCH)
			getDispatchReports().catch(e => console.error(e))
		} else if ((page === 'backload_manifest' || page === 'modif_products') && inspName !== null) {
			getOtherReports(inspName).catch(e => console.error(e))
		} else if (page === 'title_transfer') {
			setReportType(REPORT_TYPE.code.TITLE_TRANSFER)
			getDeliveryNotesTitleTransfer().catch(e => console.error(e))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, inspName, warehouse])

	const handleDownloadReport = useCallback(
		(value, partNumber) => {
			if (page === 'delivery_notes') {
				value && downloadDeliveryNote(value, partNumber).catch(e => console.error(e))
			} else if (page === 'dispatch') {
				value && downloadDispatchReport(value).catch(e => console.error(e))
			} else if (page === 'backload_manifest') {
				value && downloadBackloadManifest(value, partNumber).catch(e => console.error(e))
			} else if (page === 'modif_products') {
				value && downloadModifProductsReport(value).catch(e => console.error(e))
			} else if (page === 'title_transfer') {
				downloadTitleTransferReport(value).catch(e => console.error(e))
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[
			downloadDeliveryNote,
			downloadDispatchReport,
			downloadBackloadManifest,
			downloadModifProductsReport,
			downloadTitleTransferReport,
			page
		]
	)

	const handleDownloadReportPdf = useCallback(
		(value, ownership, partid) => {
			if (page === 'delivery_notes') {
				downloadDeliveryNotePdf(value, ownership, partid).catch(e => console.error(e))
			} else if (page === 'title_transfer') {
				downloadTitleTransferReportPdf(value).catch(e => console.error(e))
			}
		},
		[downloadDeliveryNotePdf, page, downloadTitleTransferReportPdf]
	)

	const getPageName = useCallback(() => {
		return page === 'delivery_notes'
			? t('wms:DeliveryNotes')
			: page === 'dispatch'
			? t('wms:DispatchReports')
			: page === 'backload_manifest'
			? t('wms:BackloadManifest')
			: page === 'modif_products'
			? t('wms:ModifiedProductReports')
			: t('wms:TitleTransferDN')
	}, [page, t])

	const selectInsp = value => {
		setReportType(value)
		page === 'delivery_notes'
			? setInspSelected(_.find(matReqInspections, insp => insp.id === value))
			: page === 'backload_manifest'
			? setInspSelected(_.find(rigReturnInspections, insp => insp.id === value))
			: page === 'modif_products'
			? setInspSelected(_.find(modifProdInspections, insp => insp.id === value))
			: setInspSelected(null)
		page === 'delivery_notes'
			? setInspName(_.find(matReqInspections, insp => insp.id === value).name)
			: page === 'backload_manifest'
			? setInspName(_.find(rigReturnInspections, insp => insp.id === value).name)
			: page === 'modif_products'
			? setInspName(_.find(modifProdInspections, insp => insp.id === value).name)
			: setInspName(null)
	}

	const searchParamReports = () => {
		searchReports(inspName, keyWord, page).catch(e => console.error(e))
	}

	const handleSearchInfo = event => {
		event.key === 'Enter' && event.target.value !== '' && searchReports(inspName, event.target.value, page)
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: getPageName(),
				path: null
			}
		],
		[getPageName]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' maxWidth='calc(100% - 32px)' flex='1' padding='0 0 40px 0'>
				<FlexView width='calc(100% - 12px)' flexDirection='row'>
					<FlexView
						data-cy='flexview-page-title'
						width='82%'
						minWidth='50%'
						fontSize='40px'
						fontWeight='bold'
						margin='0 0 24px 0'>
						{getPageName()}
					</FlexView>
					{page === 'delivery_notes' && (
						<SearchBar
							keyWord={keyWord}
							setKeyWord={setKeyWord}
							inspSelected={inspSelected}
							inspType={reportType}
							onSearchInfo={value => handleSearchInfo(value)}
							onSearchParams={() => searchParamReports()}
						/>
					)}
				</FlexView>
				<FlexView width='calc(100% - 12px)'>
					{page === 'delivery_notes' ? (
						<TabsRow
							tabsList={
								matReqInspections && _.map(matReqInspections, insp => ({ value: insp.id, label: insp.name }))
							}
							selectedTab={reportType}
							setSelectedTab={value => selectInsp(value)}
							tabWidth={500}
							width={window.innerWidth - 320}
							onChange={value => setReportType(value)}
							data-cy='tabs-row'
						/>
					) : page === 'backload_manifest' ? (
						<TabsRow
							tabsList={
								rigReturnInspections &&
								_.map(rigReturnInspections, insp => ({ value: insp.id, label: insp.name }))
							}
							selectedTab={reportType}
							setSelectedTab={value => selectInsp(value)}
							tabWidth={500}
							width={window.innerWidth - 320}
							onChange={value => setReportType(value)}
							data-cy='tabs-row'
						/>
					) : page === 'modif_products' ? (
						<TabsRow
							tabsList={
								rigReturnInspections &&
								_.map(modifProdInspections, insp => ({ value: insp.id, label: insp.name }))
							}
							selectedTab={reportType}
							setSelectedTab={value => selectInsp(value)}
							tabWidth={500}
							width={window.innerWidth - 320}
							onChange={value => setReportType(value)}
							data-cy='tabs-row'
						/>
					) : null}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='calc(100% - 12px)' ref={listRef} margin='0 8px 0 0'>
						<ExportReportList
							page={page}
							filteredReports={filteredExportList}
							loadingList={loadingReports}
							list={exportReportsList}
							reportType={reportType}
							selectedInspection={inspSelected}
							onDownloadReport={(value, partNumber) => handleDownloadReport(value, partNumber)}
							onDownloadReportPdf={
								(page === 'delivery_notes' || page === 'title_transfer') &&
								((value, partNumber) => handleDownloadReportPdf(value, partNumber))
							}
							height={`${listHeight}px`}
							getMoreReports={
								page === 'delivery_notes'
									? getMoreDeliveryNotes
									: page === 'backload_manifest'
									? getMoreBackloadManifests
									: page === 'modif_products'
									? getMoreModifProductReports
									: null
							}
							downloadingReport={downloadingReport}
							data-cy='export-report-list'
						/>
					</FlexView>
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default ExportReportsPage
