/** @format */

import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView } from 'components/common'

import { DispatchContext } from '../stores/DispatchStore'
import PageTemplate from '../containers/common/PageTemplate'
import DispatchInfo from '../containers/dispatch/Info'
import DispatchList from '../containers/dispatch/List'
import NewDispatchModal from '../containers/dispatch/NewModal'
import ExecuteDispatchModal from '../containers/dispatch/DispatchModal'
import { dispatch, genParams } from '../utils/hubs'

const DispatchPage = () => {
	const { t } = useTranslation()
	const {
		selectedDispatch,
		newDispatchModal,
		setNewDispatchModal,
		dispatchModal,
		setDispatchModal,
		setUpdateDispatchList,
		selectAll,
		setSelectAll,
		disabledButton,
		setDisabledButton
	} = useContext(DispatchContext)

	const closeOptionsModal = () => {
		setNewDispatchModal(false)
		setUpdateDispatchList(true)
	}

	const closeDispatchModal = () => {
		setDispatchModal(false)
		setUpdateDispatchList(true)
		setSelectAll(false)
		setDisabledButton(false)
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Dispatch'),
				path: `/wms/dispatch${genParams(dispatch)}`
			},
			{
				name: t('wms:Manage'),
				path: '/wms/dispatch/matreq'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:Dispatch')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='55%' margin='0 8px 0 0'>
						<DispatchList />
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						{selectedDispatch && <DispatchInfo />}
					</FlexView>
				</FlexView>
			</FlexView>
			{<NewDispatchModal isOpen={newDispatchModal} onOutsideClick={closeOptionsModal} />}
			{
				<ExecuteDispatchModal
					isOpen={dispatchModal}
					onOutsideClick={closeDispatchModal}
					dispatchInfo={selectedDispatch}
					selectAll={selectAll}
					setSelectAll={setSelectAll}
					disabledButton={disabledButton}
					setDisabledButton={setDisabledButton}
				/>
			}
		</PageTemplate>
	)
}

export default DispatchPage
