/** @format */

import { Card, FlexView } from 'components/common'
import { Select } from 'components/form'
import React, { useMemo } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import AttributeValues from '../containers/assets/attributes/AttributeValues'
import MandatoryAttributes from '../containers/assets/attributes/MandatoryAttributes'
import OptionalAttributes from '../containers/assets/attributes/OptionalAttributes'
import PageTemplate from '../containers/common/PageTemplate'
import { AssetsAttributesContext } from '../stores/AssetsAttributesStore'
import { assets, genParams } from '../utils/hubs'

const AssetsAttributesPage = () => {
	const { t } = useTranslation()
	const {
		setAttrSelected,
		mandatorySelected,
		setMandatorySelected,
		optAttrSelected,
		setOptAttrSelected,
		attrTypeSelected,
		setAttrTypeSelected
	} = useContext(AssetsAttributesContext)

	const attrType = [
		{
			value: 'MANDATORY',
			label: `${t('wms:MandatoryAttributes')}`
		},
		{
			value: 'OPTIONAL',
			label: `${t('wms:OptionalAttributes')}`
		}
	]

	const handleAttrType = value => {
		setAttrSelected(null)
		setAttrTypeSelected(value)
		setMandatorySelected(null)
		setOptAttrSelected({
			name: '',
			description: '',
			symbol: ''
		})
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:AttributesManagement'),
				path: '/wms/assets/attributes'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='95%' height='100%' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:AttributesManagement')}
				</FlexView>
				<FlexView flexDirection='row' width='100%' alignItems='stretch' height='100%'>
					<Card width='35%' padding='16px' alignItems='center' data-cy='card-attribute-type'>
						<Select
							style={{ alignSelf: 'center' }}
							margin='0'
							width='90%'
							label={t('wms:AttributeType')}
							placeholder={t('wms:AttributeType')}
							options={attrType}
							value={attrTypeSelected}
							onChange={v => handleAttrType(v)}
							data-cy='select-attr-type'
						/>
						{attrTypeSelected === 'MANDATORY' ? (
							<MandatoryAttributes />
						) : attrTypeSelected === 'OPTIONAL' ? (
							<OptionalAttributes />
						) : (
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='24px'
								color='lightGray'
								margin='auto 0'
								alignItems='center'
								justifyContent='center'
								style={{ textAlign: 'center' }}
								data-cy='no-attr-type'>
								{t('wms:SelectAttrType')}
							</FlexView>
						)}
					</Card>
					{mandatorySelected ? (
						<AttributeValues mandatorySelected={mandatorySelected} />
					) : (
						<AttributeValues optAttrSelected={optAttrSelected} />
					)}
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default AssetsAttributesPage
