import React, { useContext, useState, useEffect } from 'react'
import { FlexView, Icon, Card } from 'components/common'
import { Input } from 'components/form'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { UsersContext } from 'apps/admin/stores/UsersStore'


const GroupCard = () => {
  const { groups, getUsers, setSelectedGroup } = useContext(UsersContext)
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [pagination, setPagination] = useState({
    groups: [],
    currentpage: 1,
    numperpage: 4,
    length: 0,
    max: true,
    min: true,
    begin: 0,
    end: 0
  })

  const handleChange = e => {
    setSearchTerm(e.target.value)
  }

  const selectGroup = (group) => e => {
    getUsers(group.id)
    setSelectedGroup(group)
  }

  useEffect(() => {
    const results = _.filter(groups, function (obj) {
      return (obj.name).toLowerCase().indexOf(searchTerm) !== -1
    })

    setSearchResults(results)
    updatePagination()
  }, [searchTerm]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    updatePagination()
    renderItems()

  }, [searchResults]) // eslint-disable-line react-hooks/exhaustive-deps


  const updatePagination = () => {
    pagination.length = searchResults.length
    pagination.currentpage = 1
    setPagination({ ...pagination })

    updatePaginationItems()
  }

  const updatePaginationItems = () => {
    const pages = { ...pagination }
    if (pages.currentpage < 1) {
      pages.currentpage = 1
      pages.min = true
    }
    else pages.min = false

    let begin = ((pages.currentpage - 1) * pages.numperpage)
    let end = begin + pages.numperpage

    if (end >= pages.length) {
      end = pages.length
      pages.max = true
    }
    else pages.max = false

    pages.begin = begin
    pages.end = end

    pages.groups = searchResults.slice(begin, end)

    setPagination(pages)
  }

  const onNextPage = () => {

    pagination.currentpage += 1
    setPagination({ ...pagination })
    updatePaginationItems()
  }

  const onPrevPage = () => {
    pagination.currentpage -= 1
    setPagination({ ...pagination })
    updatePaginationItems()
  }

  const renderItems = () => {
    return _.map(pagination.groups, (result) => (
      <Card height="80px" key={result.id} alignItems="stretch" onClick={selectGroup(result)}  margin="3px" > {result.name}
        <FlexView flexDirection="row" alignItems="stretch" justifyContent="flex-start" width="100%" margin="15px 0px 0px 0px">
          <Icon name="user-group" width="32px" height="32px" margin="0px 15px 0px 0px" />{result.numUsers} {t('admin:users.users')}
        </FlexView>
      </Card>
    ))
  }
  return <>
    <FlexView flexDirection="row" alignItems="stretch" justifyContent="space-between" width="100%" backgroundColor="primary" color="white" >
      <FlexView flexDirection="column" alignItems="stretch" justifyContent="space-between" margin="40px 0px 0px 10px" width="20%" fontWeight="bold">
        {t('admin:users.groups')}
      </FlexView>
      <FlexView flexDirection="column" alignItems="stretch" justifyContent="flex-end" margin="15px 0px 0px 0px" width="80%">
        <FlexView flexDirection="row" alignItems="stretch" justifyContent="flex-start" width="100%">
          <Icon name="search" width="24px" height="24px" margin="25px 15px" color="white" tooltip={t('admin:users.search')} />
          <Input type="text" width="100%" laceholder="Search" value={searchTerm} onChange={handleChange} />
          <Icon name="arrow-left" width="24px" height="24px" margin="25px 5px" color="white" tooltip={t('admin:users.previous')} onClick={onPrevPage} />
          <FlexView flexDirection="row" alignItems="stretch" justifyContent="center" width="60%" margin="25px 0px 0px 0px" fontWeight="bold"> {pagination.begin + 1}-{pagination.end}/{pagination.length}</FlexView>
          {pagination.max ? '' : <Icon name="arrow-right" width="24px" height="24px" margin="25px 5px" color="white" tooltip={t('admin:users.next')} onClick={onNextPage} />}
        </FlexView>
      </FlexView>
    </FlexView>
    <FlexView flexDirection="column" alignItems="stretch" justifyContent="flex-start" width="100%">
      {renderItems()}
    </FlexView>
  </>
}

export default GroupCard
