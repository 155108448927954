/** @format */
/* eslint-disable react/prop-types */

import { REPORT_ELEMENTS_STATUS, REPORT_ITEM } from 'apps/wms/utils/enum'
import { Button, Card, FlexView, Icon, Modal, LoadingOverlay } from 'components/common'
import React, { useCallback, useMemo, useRef, useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import { WMSContext } from 'apps/wms/stores/WmsStore'
import { UserContext } from 'stores/UserStore'
import inspection from '../../api/inspection'
import _, { forEach } from 'lodash'

const ActionButton = styled(Button)`
	border: none;

	&:hover {
		box-shadow: none;
	}
`

const ImageWrapper = styled.div`
	position: relative;
	padding-top: 100px;
	min-width: 100px;

	img {
		position: absolute;
		max-width: 95%;
		max-height: 95%;
		top: 50%;
		margin-top: -47.5%;
		left: 50%;
		margin-left: -78.5%;
	}

	button {
		position: absolute;
		margin: 0;
		top: 0;
		right: 0;
	}
`

const ReportImageGallery = ({
	isOpen,
	onOutsideClick,
	onImageChange,
	report,
	elements,
	reportType,
	allowEdit,
	rejectReasons
}) => {
	const { token } = useContext(UserContext)
	const { warehouse } = useContext(WMSContext)
	const { t } = useTranslation()
	const [confirmImageUpdate, setConfirmImageUpdate] = useState(false)
	const [dialogData, setDialogData] = useState(null)
	const [resizeImg, setResizeImg] = useState(false)
	const [currentPhoto, setCurrentPhoto] = useState(null)
	const [currentInfo, setCurrentInfo] = useState({
		reportItem: null,
		defect: null
	})
	const [defectPhotoList, setDefectPhotoList] = useState([])
	const [reportKeys, setReportKeys] = useState([])
	const [isLoadingPhotoList, setIsLoadingPhotoList] = useState(false)

	const parentRef = useRef(null)

	const reportItemAccessor = useMemo(() => report && Object.keys(report), [report])

	//const hasDefectSet = (reportItem) => (reportItem.defect > 9)
	const isReportNOK = reportItem => {
		if (reportItem) {
			let isNotOk = true
			isNotOk = isNotOk && reportItem === REPORT_ELEMENTS_STATUS.codeString.NOTOK
			return isNotOk
		}
	}

	const getReportItem = (report, accessor) => report[accessor]
	//const getReportItemReport = (report, itemCode) => (getReportItem(report, itemCode).report)
	const getReportItemDefect = (report, itemCode) => {
		let filteredKeys = reportKeys && _.filter(reportKeys, keys => keys.includes('defect'))
		let defectAccessor = _.find(filteredKeys, key => _.startsWith(key, itemCode))
		return defectAccessor && getReportItem(report, defectAccessor)
	}
	const getReportItemPhoto = (report, itemCode) => {
		let filteredKeys = reportKeys && _.filter(reportKeys, keys => keys.includes('photo'))
		let photoAccessor = _.find(filteredKeys, key => _.startsWith(key, itemCode))
		return photoAccessor && getReportItem(report, photoAccessor)
	}
	const getReportPhotoURL = (report, itemCode) => {
		let filteredKeys = reportKeys && _.filter(reportKeys, keys => keys.includes('photo'))
		let photoAccessor = _.find(filteredKeys, key => _.startsWith(key, itemCode))
		const photo =
			defectPhotoList && defectPhotoList.length ? defectPhotoList.find(p => p.accessor === photoAccessor) : null

		return photo && photo.base64 ? photo.base64 : null
	}

	const isDefectSet = (report, accessor) => getReportItemDefect(report, accessor)
	const getRejectReason = rejectReasonCode => {
		let reasons = rejectReasons.find(rr => rr.id === rejectReasonCode)
		return reasons ? reasons.description : null
	}
	const getElement = (report, accessor) => {
		let filteredKeys = reportKeys && _.filter(reportKeys, keys => keys.includes('elementid'))
		let element = _.find(filteredKeys, key => _.startsWith(key, accessor))
		let elementId = element && report[element]
		let elementSelected = elementId && _.find(elements, el => el.id === elementId)
		return elementSelected ? elementSelected.name : ''
	}

	const storeDialogData = useCallback((item, value, report) => {
		setDialogData({
			item: item,
			value: value,
			report: report
		})
	}, [])

	const handleAddImage = (itemCode, imageEvent, report) => {
		let filteredKeys = reportKeys && _.filter(reportKeys, keys => keys.includes('photo'))
		let photoAccessor = _.find(filteredKeys, key => _.startsWith(key, itemCode))
		const reader = new FileReader()
		reader.onload = event => {
			const encoded = event.target.result
			storeDialogData(photoAccessor, encoded, report)
			setConfirmImageUpdate(true)
		}
		imageEvent.target.files.length > 0 && reader.readAsDataURL(imageEvent.target.files[0])
	}

	const handleRemoveImage = (itemCode, report) => {
		storeDialogData(itemCode, 'N', report)
		setConfirmImageUpdate(true)
	}

	const changeImage = (itemCode, imageEvent, report) => onImageChange(itemCode, imageEvent, report)

	const enlargeImg = (report, itemCode) => {
		let keys = reportKeys && _.filter(reportKeys, keys => keys.includes('elementid'))
		let element = _.find(keys, key => _.startsWith(key, itemCode))
		let elementId = element && report[element]
		let elementSelected = elementId && _.find(elements, el => el.id === elementId)

		setCurrentInfo({
			reportItem: elementSelected && elementSelected.name,
			defect: isDefectSet(report, itemCode)
				? getRejectReason(getReportItemDefect(report, itemCode))
				: t('wms:NoDefectSet')
		})
		let filteredKeys = reportKeys && _.filter(reportKeys, keys => keys.includes('photo'))
		let photoAccessor = _.find(filteredKeys, key => _.startsWith(key, itemCode))
		const photo = defectPhotoList.find(p => p.accessor === photoAccessor)
		if (!photo) return
		setCurrentPhoto(photo.base64)
		setResizeImg(true)
	}

	useEffect(() => {
		setReportKeys(Object.keys(report))
	}, [report])

	useEffect(() => {
		const fetchPhotos = async () => {
			const accessors = Object.keys(report)
			let photoList = []

			forEach(accessors, async accessor => {
				const item = {
					accessor,
					base64: null
				}

				const reportItem = getReportItem(report, accessor)
				const photoURL = reportItem

				if (!reportItem || !accessor.includes('photo') || !photoURL || photoURL === 'N') return

				try {
					if (photoURL.startsWith('data')) {
						item.base64 = photoURL
					} else if (/^\d+\.jpg$/.test(photoURL)) {
						item.base64 = await inspection.getInspectorDefectPhoto(warehouse.id, photoURL, reportType, token)
					}
				} catch (e) {
					console.error(e)
					toast.error(t('wms:DefectPhotoNotFound'))
				}

				photoList = [...photoList, item]
			})

			return photoList
		}

		setIsLoadingPhotoList(true)

		fetchPhotos()
			.then(photoList => setDefectPhotoList(photoList))
			.finally(() => setIsLoadingPhotoList(false))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report])

	const renderDefectPhoto = useCallback(
		accessor => {
			const photo = getReportPhotoURL(report, accessor)

			if (!photo && isLoadingPhotoList)
				return (
					<ImageWrapper>
						<LoadingOverlay visible={true} style={{ backgroundColor: 'transparent' }} />
					</ImageWrapper>
				)

			if (!photo && !isLoadingPhotoList)
				return (
					<FlexView width='48px' height='48px' alignItems='center' justifyContent='center'>
						<Icon name='cross-simple' width='24px' height='24px' color='error' />
					</FlexView>
				)

			return (
				<ImageWrapper>
					<img
						src={getReportPhotoURL(report, accessor)}
						alt=''
						id='img'
						onClick={() => enlargeImg(report, accessor)}
						style={{ cursor: 'pointer' }}
					/>
					<ActionButton
						fontSize='tiny'
						backgroundColor='error'
						padding='4px'
						color='white'
						margin='0 0 0 8px'
						onClick={() => handleRemoveImage(accessor, report)}>
						<Icon name='trash' color='white' height='12px' width='12px' margin='0' />
					</ActionButton>
				</ImageWrapper>
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[report, isLoadingPhotoList]
	)

	const itemInfo = useCallback(
		accessor =>
			isReportNOK(getReportItem(report, accessor)) && (
				<FlexView
					width='calc(100% - 32px)'
					padding='8px 0px'
					margin='16px 0 0 0'
					flexDirection='row'
					justifyContent='space-between'
					key={accessor}>
					<FlexView
						width='calc(100% - 32px)'
						flexDirection='column'
						fontSize='small'
						fontWeight='bold'
						padding='0px'>
						{getElement(report, accessor)}
						<FlexView width='calc(100% - 32px)' fontSize='small' margin='4px 0 0 0'>
							{isDefectSet(report, accessor)
								? getRejectReason(getReportItemDefect(report, accessor))
								: t('wms:NoDefectSet')}
						</FlexView>
					</FlexView>
					{getReportItemPhoto(report, accessor) === 'N' ? (
						<Button
							title={t('wms:AddPicture')}
							fontSize='tiny'
							padding='0'
							outline='true'
							color='secondary'
							onClick={e => e.stopPropagation()}
							margin='0 0 0 8px'
							disabled={!allowEdit || !isDefectSet(report, accessor)}>
							<label htmlFor={`add-${REPORT_ITEM.accessor}-photo`} style={{ cursor: 'pointer' }}>
								<Icon name='add' color='secondary' height='24px' width='24px' margin='16px' />
							</label>
							<input
								type='file'
								id={`add-${REPORT_ITEM.accessor}-photo`}
								accept='image/png, image/jpeg'
								style={{ display: 'none' }}
								onChange={e => {
									handleAddImage(accessor, e, report)
								}}
							/>
						</Button>
					) : (
						renderDefectPhoto(accessor)
					)}
				</FlexView>
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[report, defectPhotoList, renderDefectPhoto]
	)

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			{resizeImg ? (
				<FlexView
					flexDirection='column'
					width='calc(100% - 48px)'
					height='calc(100% - 48px)'
					padding='24px'
					margin='0px'>
					<Card
						flexDirection='row'
						width='calc(100% - 48px)'
						margin='0px'
						padding='8px 24px'
						backgroundColor='background'
						justifyContent='space-between'
						borderRadius='4px'
						style={{ alignItems: 'center' }}>
						<FlexView
							width='calc(100% - 32px)'
							flexDirection='column'
							fontSize='small'
							fontWeight='bold'
							padding='0px'>
							{currentInfo && currentInfo.reportItem}
							<FlexView width='calc(100% - 32px)' fontSize='small' margin='4px 0 0 0'>
								{currentInfo && currentInfo.defect}
							</FlexView>
						</FlexView>
						<Icon
							name='cross-simple'
							width='14px'
							height='14px'
							style={{ alignSelf: 'center' }}
							onClick={() => setResizeImg(false)}
						/>
					</Card>
					<img
						src={currentPhoto}
						alt=''
						style={{
							cursor: 'pointer',
							width: '80%',
							margin: 'auto',
							objectFit: 'contain',
							objectPosition: '50% 50%',
							height: 'auto',
							maxHeight: '80%'
						}}
					/>
				</FlexView>
			) : (
				<Card
					ref={parentRef}
					borderRadius='0'
					backgroundColor='background'
					maxWidth='calc(30%)'
					height='calc(100%)'
					maxHeight='100vh'
					flex='1'
					margin='0 0 0 auto'
					padding='0'
					alignItems='center'
					alignSelf='flex-end'
					style={{ position: 'relative', overflowY: 'scroll' }}>
					<FlexView
						width='calc(100% - 32px)'
						fontWeight='bold'
						fontSize='24px'
						padding='16px 16px 8px'
						flexDirection='row'
						justifyContent='space-between'>
						{`${t('wms:PhotosOfDefects')}`}
						<ActionButton
							fontSize='tiny'
							padding='4px 0'
							outline='true'
							color='secondary'
							onClick={() => onOutsideClick()}
							margin='0 0 0 8px'>
							<Icon name='cross' color='secondary' height='24px' width='24px' margin='0' />
						</ActionButton>
					</FlexView>
					<FlexView width='calc(100% - 32px)' fontSize='16px' padding='0 16px 16px'>
						{t('wms:Item')}: {report.valid}
					</FlexView>
					{reportItemAccessor.map(ri => itemInfo(ri))}
				</Card>
			)}
			{dialogData !== null && (
				<DialogModal
					isOpen={confirmImageUpdate}
					title={dialogData.value === 'N' ? t(`wms:ConfirmPhotoRemoval`) : t('wms:ConfirmPhotosUpdate')}
					text={t(`wms:ProceedModifyingPhoto`)}
					onConfirm={() =>
						changeImage(dialogData.item, dialogData.value, dialogData.report) && setConfirmImageUpdate(false)
					}
					onCancel={() => setConfirmImageUpdate(false)}
				/>
			)}
		</Modal>
	)
}

export default ReportImageGallery
