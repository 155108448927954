/** @format */

import { ActualDataContext } from 'apps/wms/stores/ActualDataModifStore'
import { Button, Card, FlexView, LoadingOverlay, Modal } from 'components/common'
import React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line react/prop-types
const ActualDataChangeModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { modifPipes, savingChanges } = useContext(ActualDataContext)

	const confirmChanges = () => {
		modifPipes()
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='20%' data-cy='card-confirm-data-changes'>
				<FlexView FlexView='column' width='100%' height='100%'>
					<FlexView width='100%' fontSize='big' fontWeight='bold'>
						{t('wms:ConfirmPipeDataModification')}
					</FlexView>
					<FlexView fontSize='medium' width='100%' margin='16px 0 0 0' style={{ textAlign: 'justify' }}>
						{t('wms:ModifPipes')}
					</FlexView>
					<FlexView flexDirection='row' margin='auto 0 0 auto'>
						<Button
							backgroundColor='error'
							color='white'
							onClick={onOutsideClick}
							data-cy='button-cancel-changes'>
							{t('wms:Cancel')}
						</Button>
						<Button
							backgroundColor='success'
							color='white'
							margin='8px 0 0 8px'
							onClick={() => confirmChanges()}
							data-cy='button-confirm-changes'>
							{t('wms:Ok')}
							<LoadingOverlay visible={savingChanges} borderRadius='card' />
						</Button>
					</FlexView>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default ActualDataChangeModal
