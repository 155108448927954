/** @format */

import { PrepareEntranceContext } from 'apps/wms/stores/PrepareEntranceStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, Card, FlexView, Icon } from 'components/common'
import _ from 'lodash'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmVALIDGenModal from './ConfirmValidGenModal'

const MaterialData = () => {
	const { t } = useTranslation()
	const { newMatList, setStep, setOpenConfirmModal, openConfirmModal } = useContext(PrepareEntranceContext)

	const [list, setList] = useState(null)

	const listDeadSpace = 160
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		setList(newMatList)
	}, [newMatList])

	const confirmVALIDGen = () => {
		setOpenConfirmModal(true)
	}

	const checkFields = () => {
		let isDisabled = true
		let emptyFields = list === null || list === undefined
		isDisabled = isDisabled && emptyFields

		return isDisabled
	}

	const goBack = () => {
		setStep(2)
	}

	return (
		<FlexView width='calc(100% - 48px)' height='100%' margin='16px 0 0 0'>
			<Card width='100%' height='100%' ref={listRef} style={{ position: 'relative' }} data-cy='card-material-data'>
				<FlexView width='100%' fontSize='title' fontWeight='bold'>
					{t('wms:VALIDGenerationData')}
				</FlexView>
				<FlexView width='100%' flexDirection='row'>
					<FlexView flexDirection='column' width='100%' height={`${listHeight}px`} style={{ overflowY: 'auto' }}>
						{list && list.length > 0
							? _.map(list, (material, index) => (
									<FlexView key={index} flexDirection='column' width='100%'>
										<Card
											width='calc(100% - 70px)'
											margin='16px 0 16px 8px'
											data-cy={`card-material-${index + 1}`}>
											<FlexView flexDirection='row' width='100%'>
												<FlexView
													width='100%'
													fontSize='medium'
													fontWeight='bold'
													color='metalic'
													margin='0 0 16px 0'>
													{t('wms:Material')} {index + 1}: {material.desc}
												</FlexView>
												<FlexView
													width='10%'
													fontSize='medium'
													fontWeight='bold'
													color='metalic'
													margin='0 0 16px 0'>
													{material.quantity} {t('wms:Pipe')}(s)
												</FlexView>
											</FlexView>
											<FlexView width='100%' fontSize='small' color='metalic' margin='0 0 16px 0'>
												{t('wms:CustomerOrderAndItem')}: {material.customerorder} #{material.customeritem} /{' '}
												{t('wms:SalesOrderAndItem')}: {material.salesorder} #{material.salesitem} /{' '}
												{material.consignmenttype}
											</FlexView>
										</Card>
									</FlexView>
							  ))
							: null}
					</FlexView>
				</FlexView>
				<FlexView flexDirection='row' width='100%' justifyContent='space-between' margin='auto 0 0 auto'>
					<Button
						backgroundColor='primary'
						color='white'
						margin='auto 0 auto 0'
						onClick={() => goBack()}
						data-cy='button-back-step'>
						<Icon name='arrow-left' color='white' width='14px' height='14px' margin='0 8px 0 0' />
						{t('wms:Back')}
					</Button>
					<Button
						margin='auto 0 0 auto'
						backgroundColor='success'
						color='white'
						onClick={() => confirmVALIDGen()}
						disabled={checkFields()}
						data-cy='button-confirm-generation'>
						{t('wms:ConfirmVALIDGen')}
					</Button>
				</FlexView>
			</Card>
			<ConfirmVALIDGenModal isOpen={openConfirmModal} onOutsideClick={() => setOpenConfirmModal(false)} />
		</FlexView>
	)
}

export default MaterialData
