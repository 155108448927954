/** @format */
/* eslint-disable react/prop-types */

import { AssetsEquipmentsContext } from 'apps/wms/stores/AssetsEquipmentsStore'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import { DatePicker, Select, Input } from 'components/form'
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const EquipmentModal = ({ isOpen, onOutsideClick, isUpdate, equipmentInfo }) => {
	const { t } = useTranslation()
	const { createEquipment, updateEquipment, creatingEquipment, updatingEquipment, equipmentData, setEquipmentData } =
		useContext(AssetsEquipmentsContext)

	const baseEquipment = {
		type: '',
		reference: '',
		status: null,
		calibdate: null,
		nextcalib: null,
		remarks: null
	}

	useEffect(() => {
		isOpen && setEquipmentData(baseEquipment)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	const statusOptions = [
		{
			value: 'Active',
			label: t('wms:Active')
		},
		{
			value: 'Inactive',
			label: t('wms:Inactive')
		}
	]

	const saveEquipment = async () => {
		try {
			await createEquipment(equipmentData).then(() => {
				setEquipmentData(baseEquipment)
			})
		} catch (e) {
			console.error(e)
		}
	}

	const updateInstrument = async () => {
		try {
			await updateEquipment(equipmentData, equipmentInfo.id).then(() => {
				setEquipmentData(baseEquipment)
			})
		} catch (e) {
			console.error(e)
		}
	}

	const validateData = () => {
		let isDisabled = true
		isDisabled =
			(isDisabled && !equipmentData) ||
			(equipmentData && !equipmentData.type) ||
			(equipmentData && !equipmentData.reference) ||
			(equipmentData && !equipmentData.status) ||
			(equipmentData && !equipmentData.calibdate) ||
			(equipmentData && !equipmentData.nextcalib) ||
			(equipmentData && equipmentData.calibdate > equipmentData.nextcalib)

		return isDisabled
	}

	useEffect(() => {
		if (isOpen && isUpdate) {
			setEquipmentData({
				type: equipmentInfo.type,
				reference: equipmentInfo.reference,
				status: equipmentInfo.status,
				calibdate: moment(equipmentInfo.calibdate),
				nextcalib: moment(equipmentInfo.nextcalib),
				remarks: equipmentInfo.remarks
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	const handleDateChange = value => {
		return value < equipmentData.calibdate
			? toast.error(t('wms:ErrorNextCalibDate'))
			: setEquipmentData({ ...equipmentData, nextcalib: value })
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='30%' height='70%' position='relative'>
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{isUpdate ? t('wms:UpdateEquipment') : t('wms:NewEquipment')}
					<Button fontSize='tiny' margin='0' onClick={onOutsideClick} backgroundColor='white'>
						<Icon name='cross-simple' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='column'>
					<Input
						type='text'
						width='100%'
						label={t('wms:EquipmentType')}
						placeholder={t('wms:EquipmentType')}
						value={equipmentData && equipmentData.type}
						onChange={e => setEquipmentData({ ...equipmentData, type: e.target.value })}
						fontSize='small'
					/>
					<Input
						type='text'
						width='100%'
						label={t('wms:EquipmentReference')}
						placeholder={t('wms:EquipmentReference')}
						value={equipmentData && equipmentData.reference}
						onChange={e => setEquipmentData({ ...equipmentData, reference: e.target.value })}
						fontSize='small'
					/>
					<Select
						placeholder={t('wms:Status')}
						label={t('wms:Status')}
						width='100%'
						value={equipmentData && equipmentData.status}
						options={statusOptions}
						onChange={value => setEquipmentData({ ...equipmentData, status: value })}
					/>
					<FlexView width='100%' flexDirection='row' justifyContent='space-around'>
						<DatePicker
							label={t('wms:CalibrationDate')}
							timePicker={false}
							value={equipmentData && equipmentData.calibdate}
							onChange={e => setEquipmentData({ ...equipmentData, calibdate: e })}
							fontSize='small'
						/>
						<DatePicker
							label={t('wms:NextCalibrationDate')}
							timePicker={false}
							value={equipmentData && equipmentData.nextcalib}
							onChange={e => handleDateChange(e)}
							fontSize='small'
						/>
					</FlexView>
				</FlexView>
				<FlexView width='100%' fontSize='medium' justifyContent='center' alignItems='center'>
					{!isUpdate ? (
						<Button
							backgroundColor='success'
							color='white'
							disabled={validateData()}
							onClick={() => saveEquipment()}>
							{t('wms:Save')}
							<Icon name='save' width='12px' height='12px' color='white' margin='0 0 0 8px' />
							<LoadingOverlay visible={creatingEquipment} borderRadius='card' />
						</Button>
					) : (
						<Button
							fontSize='medium'
							color='white'
							backgroundColor='secondary'
							disabled={validateData()}
							onClick={() => updateInstrument()}>
							{t('wms:Update')}
							<Icon name='refresh' color='white' height='12px' width='12px' margin='0 0 0 8px' />
							<LoadingOverlay visible={updatingEquipment} borderRadius='card' />
						</Button>
					)}
				</FlexView>
			</Card>
		</Modal>
	)
}

export default EquipmentModal
