/** @format */

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexView } from 'components/common'

import PageTemplate from '../containers/common/PageTemplate'
import { useWindowSize } from 'apps/wms/utils/hooks'

const FeaturesMapPage = () => {
	const { t } = useTranslation()

	const listDeadSpace = 180
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:FeaturesMap'),
				path: '/wms/features'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0' ref={listRef}>
				<FlexView
					data-cy='flexview-page-title'
					minWidth='100%'
					fontSize='40px'
					fontWeight='bold'
					margin='0 0 24px 0'>
					{t('wms:FeaturesMap')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView
						width='100%'
						margin='32px 0 0 0'
						flexDirection='row'
						flexWrap='wrap'
						justifyContent='space-between'
						alignItems='strech'>
						<iframe
							title='KUMU_MAP'
							src='https://embed.kumu.io/caec545e053fbd2a54234274a2df7949'
							width='100%'
							height={listHeight}
							frameBorder='0'></iframe>
					</FlexView>
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default FeaturesMapPage
