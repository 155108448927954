/** @format */

import { INSPECTION_STAGES, REPORT_ELEMENTS_STATUS, REPORT_ITEM_STATUS, REPORT_TYPE } from 'apps/wms/utils/enum'
import _ from 'lodash'

export const shouldConfirmInspectionItemModification = currentReport => currentReport === REPORT_ITEM_STATUS.code.NOTOK
export const shouldConfirmRejectReasonModification = currentReport => currentReport >= 9

const changeReportInspectionResponsible = (report, supervisor) => ({ ...report, responsible: supervisor })
const changeReportModifyStatus = report => ({ ...report, modified: true })
const changeReportInstrumentCase = (report, caseid) => ({ ...report, instlistid: caseid })
const changeReportInstrumentList = (report, list) => ({ ...report, instrumentlist: list })

const resetReportItemRejectReason = (report, accessor, itemDefect, itemPhoto, reportValue) => ({
	...report,
	[accessor]: reportValue,
	[itemDefect]: 0,
	[itemPhoto]: 'N'
})
const changeReportInspectionStatus = (report, inspectionStatus) => ({ ...report, finalreport: inspectionStatus })
const setReportInspectionAccepted = report => changeReportInspectionStatus(report, REPORT_ITEM_STATUS.code.ACCEPT)
const setReportInspectionRepair = report => changeReportInspectionStatus(report, REPORT_ITEM_STATUS.code.REPAIR)
//const setReportInspectionRejected = (report) => changeReportInspectionStatus(report, REPORT_ITEM_STATUS.code.REJECT)
export const isReportItemOk = reportItemValue =>
	reportItemValue === REPORT_ELEMENTS_STATUS.codeString.OK || reportItemValue === 2
export const isReportItemNA = reportItemValue => reportItemValue === REPORT_ELEMENTS_STATUS.codeString.NA
export const isReportItemAcceptable = reportItemValue =>
	isReportItemOk(reportItemValue) || isReportItemNA(reportItemValue)
export const isReportItemRejectionNotConsistent = reportItem =>
	reportItem.report === REPORT_ELEMENTS_STATUS.codeString.NOTOK && reportItem.defect < 9

export const isReportInspectionStatusAcceptable = (report, accessor) => {
	return isReportItemAcceptable(report[accessor]) ? isReportItemAcceptable(report[accessor]) : true
}

export const isReportFinalStatusAcceptable = (report, elements) => {
	var isAcceptable = true
	_.forEach(elements, el => {
		isAcceptable = isAcceptable && isReportItemAcceptable(report[el.name])
	})
	return isAcceptable
}

export const isReportInspectionNotConsistent = (report, accessor) => {
	return report[accessor] && isReportItemRejectionNotConsistent(report[accessor])
		? isReportItemRejectionNotConsistent(report[accessor])
		: false
}

export const changeReportInspectionData = (currentReport, accessor, itemDefect, itemPhoto, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportInspectionResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = resetReportItemRejectReason(newReport, accessor, itemDefect, itemPhoto, newReport[accessor])
	newReport = isReportInspectionStatusAcceptable(newReport, accessor)
		? setReportInspectionAccepted(newReport)
		: setReportInspectionRepair(newReport)
	return newReport
}

export const changeReportRejectReason = (currentReport, itemPhoto, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportInspectionResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = {
		...newReport,
		[itemPhoto]: 'N'
	}
	return newReport
}

export const changeReportPhoto = (currentReport, accessor, photoData, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportInspectionResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = {
		...newReport,
		[accessor]: photoData
	}
	return newReport
}

const changeReportMeasureResponsible = (report, supervisor) => ({ ...report, measureresponsible: supervisor })
const changeReportEffectiveLength = report => ({ ...report, effective: report.measured - report.makeuploss })

export const changeWeightData = (currentReport, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportMeasureResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)

	return newReport
}

export const changeReportMeasureData = (currentReport, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportMeasureResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = changeReportEffectiveLength(newReport)
	return newReport
}

const changeManufacturer = (report, manufacturer) => ({
	...report,
	manufacturer: manufacturer
})
const changeBatchNumber = (report, number) => ({ ...report, batchnumber: number })
const changeApplicationMethod = (report, method) => ({
	...report,
	applicationmethod: method
})
const changeThreadResponsible = (report, supervisor) => ({ ...report, threadresponsible: supervisor })

export const changeManufacturerData = (currentReport, supervisor, manufacturer) => {
	var newReport = { ...currentReport }
	newReport = changeThreadResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = changeManufacturer(newReport, manufacturer)
	return newReport
}

export const changeBatchNumberData = (currentReport, supervisor, batchNumber) => {
	var newReport = { ...currentReport }
	newReport = changeThreadResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = changeBatchNumber(newReport, batchNumber)
	return newReport
}

export const changeApplicationMethodData = (currentReport, supervisor, method) => {
	var newReport = { ...currentReport }
	newReport = changeThreadResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = changeApplicationMethod(newReport, method)
	return newReport
}

const isReportFinalStatusAccepted = report => report.finalreport === REPORT_ITEM_STATUS.code.ACCEPT

export const isReportFinalStatusConsistent = (report, accessor, elements) => {
	return isReportFinalStatusAccepted(report)
		? isReportFinalStatusAcceptable(report, elements)
		: !isReportFinalStatusAcceptable(report, elements)
}

export const changeReportData = (currentReport, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportInspectionResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	return newReport
}

export const changeInstrumentsData = (currentReport, caseId, instrumentList, supervisor) => {
	var newReport = { ...currentReport }
	newReport = changeReportMeasureResponsible(newReport, supervisor)
	newReport = changeReportInspectionResponsible(newReport, supervisor)
	newReport = changeReportModifyStatus(newReport)
	newReport = changeReportInstrumentCase(newReport, caseId)
	newReport = changeReportInstrumentList(newReport, instrumentList)
	return newReport
}

export const isMR = stage => stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION

export const isReturn = stage => stage === INSPECTION_STAGES.code.RETURN

export const hasFewerItems = (plannedQty, actualQty) => actualQty < plannedQty

export const shouldAllowReportReopen = (reportType, plannedQty, actualQty, isInspection) =>
	hasFewerItems(plannedQty, actualQty) || isReceiptNoteReport(reportType, isInspection)

export const isAgeManagementReport = (reportType, isInspection) =>
	!isInspection && reportType === REPORT_TYPE.code.AGE_EXTRACTS

export const isReceiptNoteReport = (reportType, isInspection) =>
	!isInspection && reportType === REPORT_TYPE.code.MILL_RECEIPT
