/** @format */

import { PrepareEntranceContext } from 'apps/wms/stores/PrepareEntranceStore'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import { RadioGroup } from 'components/form'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
const ConfirmVALIDGenModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const {
		savingChanges,
		genValids,
		openRelocateModal,
		resetProcess,
		setOpenConfirmModal,
		setOpenRelocateModal,
		printersList,
		printerSelected,
		setPrinterSelected,
		printItems,
		generatedVALIDs,
		isPrinting
	} = useContext(PrepareEntranceContext)
	const history = useHistory()
	const [printingMode, setPrintingMode] = useState(false)

	const confirmGeneration = () => {
		genValids()
	}

	const restartProcess = () => {
		resetProcess()
	}

	const goToLandingPage = () => {
		setOpenConfirmModal(false)
		setOpenRelocateModal(false)
		history.push('/wms/')
	}

	const printValids = () => {
		setPrintingMode(true)
	}

	const confirmPrint = () => {
		printItems(printerSelected, generatedVALIDs)
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width={printingMode ? '15%' : '30%'} data-cy='card-confirm-generation'>
				{printingMode ? (
					<FlexView width='100%' flexDirection='column'>
						<FlexView
							flexDirection='row'
							width='100%'
							justifyContent='space-between'
							alignItems='center'
							margin='0 0 16px 0'>
							<FlexView fontSize='big'>{t('wms:SelectPrinter')}</FlexView>
							<Button
								alignItems='center'
								backgroundColor='white'
								fontSize='tiny'
								margin='0px 0px 8px 0px'
								padding='0'
								disabled={false}
								onClick={() => setPrintingMode(false)}
								data-cy='button-close-print-modal'>
								<Icon name='cross-simple' color='black' height='12px' width='12px' margin='0' />
							</Button>
						</FlexView>
						<RadioGroup
							fontSize='14px'
							name='printers'
							options={printersList}
							value={printerSelected}
							onChange={evt => setPrinterSelected(evt)}
						/>
						<FlexView flexDirection='row' width='100%' justifyContent='flex-end' margin='16px 0 0 0'>
							<Button backgroundColor='error' color='white' onClick={() => setPrintingMode(false)}>
								{t('wms:Cancel')}
							</Button>
							<Button
								disabled={!printerSelected}
								margin='8px 0 0 16px'
								backgroundColor='success'
								color='white'
								onClick={() => confirmPrint()}>
								{t('wms:Confirm')}
							</Button>
						</FlexView>
						<LoadingOverlay visible={isPrinting} />
					</FlexView>
				) : openRelocateModal ? (
					<FlexView flexDirection='column' width='100%'>
						<FlexView
							width='100%'
							fontSize='big'
							fontWeight='bold'
							margin='0 0 16px 0'
							alignItems='center'
							data-cy='flex-view-valids-generated'
							style={{ textAlign: 'center' }}>
							{t('wms:VALIDsGenerated')}
						</FlexView>
						<FlexView
							width='100%'
							fontSize='medium'
							fontWeight='bold'
							alignItems='center'
							margin='0 0 16px 0'
							data-cy='flex-view-valids-generated-message'>
							{t('wms:VALIDsGeneratedMessage')}
						</FlexView>
						<FlexView flexDirection='row' margin='auto 0 0 auto' width='100%' justifyContent='space-between'>
							<Button
								backgroundColor='secondary'
								color='white'
								onClick={() => restartProcess()}
								data-cy='button-restart-process'>
								{t('wms:Restart')} {t('wms:Process')}
								<Icon name='refresh' color='white' width='14px' height='14px' margin='0 0 0 8px' />
							</Button>
							<Button
								backgroundColor='primary'
								color='white'
								onClick={() => printValids()}
								data-cy='button-print-valids'>
								{t('wms:PrintGeneratedIds')}
								<Icon name='print' color='white' width='14px' height='14px' margin='0 0 0 8px' />
							</Button>
							<Button
								backgroundColor='success'
								color='white'
								onClick={() => goToLandingPage()}
								margin='8px 0 0 8px'
								data-cy='button-go-to-pipe-data'>
								{t('wms:Ok')}
								<Icon name='check' color='white' width='14px' height='14px' margin='0 0 0 8px' />
							</Button>
						</FlexView>
					</FlexView>
				) : (
					<FlexView flexDirection='column' width='100%'>
						<FlexView
							width='100%'
							fontSize='big'
							fontWeight='bold'
							margin='0 0 16px 0'
							style={{ textAlign: 'center' }}>
							{t('wms:ConfirmVALIDGenMessage')}
						</FlexView>
						<FlexView flexDirection='row' margin='auto 0 0 auto'>
							<Button
								backgroundColor='error'
								color='white'
								onClick={onOutsideClick}
								data-cy='button-cancel-generation'>
								{t('wms:Cancel')}
								<Icon name='cross-simple' color='white' width='14px' height='14px' margin='2px 0 0 8px' />
							</Button>
							<Button
								backgroundColor='success'
								color='white'
								margin='8px 0 0 8px'
								onClick={() => confirmGeneration()}
								data-cy='button-confirm-valid-generation'>
								{t('wms:Confirm')}
								<Icon name='check' color='white' width='14px' height='14px' margin='2px 0 0 8px' />
							</Button>
							<LoadingOverlay visible={savingChanges} borderRadius='card' />
						</FlexView>
					</FlexView>
				)}
			</Card>
		</Modal>
	)
}

export default ConfirmVALIDGenModal
