/** @format */

export const entrance = {
	title: 'Entrance',
	routes: [
		{
			n: 'Entrance',
			r: '/wms/entrance/batch'
		},
		{
			n: 'PrepareEntranceInspections',
			r: '/wms/prepare/inspection/entrance'
		},
		{
			n: 'ReceiptNote',
			r: '/wms/manage/reports/review/mill_receipts'
		},
		{
			n: 'PrepareEntrance',
			r: '/wms/entrance/prepare'
		},
		{
			n: 'TagPrinting',
			r: '/wms/entrance/printing/'
		}
	]
}

export const ageManagement = {
	title: 'AgeManagement',
	routes: [
		{
			n: 'AgeManagement',
			r: '/wms/manage/pipeagemanagement'
		},
		{
			n: 'AgeManagementReports',
			r: '/wms/manage/reports/review/age_management'
		}
	]
}

export const inspection = {
	title: 'Inspection',
	routes: [
		{
			n: 'InspectionReports',
			r: '/wms/manage/reports/edit/inspection'
		}
	]
}

export const materialReq = {
	title: 'MaterialRequisition',
	routes: [
		{
			n: 'PrepareMatReqInspections',
			r: '/wms/prepare/inspection/matreq'
		},
		{
			n: 'DeliveryNotes',
			r: '/wms/manage/reports/export/delivery_notes'
		}
	]
}

export const Return = {
	title: 'Return',
	routes: [
		{
			n: 'PrepareReturnInspections',
			r: '/wms/prepare/inspection/return'
		},
		{
			n: 'BackloadManifest',
			r: '/wms/manage/reports/export/backload_manifest'
		},
		{
			n: 'ModifiedProductReports',
			r: '/wms/manage/reports/export/modif_products'
		}
	]
}

export const dispatch = {
	title: 'Dispatch',
	routes: [
		{
			n: 'Dispatch',
			r: '/wms/dispatch/manage'
		},
		{
			n: 'Reports',
			r: '/wms/manage/reports/export/dispatch'
		}
	]
}

export const inventory = {
	title: 'Inventory',
	routes: [
		{
			n: 'ViewInventory',
			r: '/wms/manage/inventory/check'
		}
	]
}

export const assets = {
	title: 'Assets',
	routes: [
		{
			n: 'Warehouse',
			r: '/wms/assets/warehouse'
		},
		{
			n: 'AttributesManagement',
			r: '/wms/assets/attributes'
		},
		{
			n: 'Material',
			r: '/wms/assets/material'
		},
		{
			n: 'UsageRights',
			r: '/wms/assets/usagerights'
		},
		{
			n: 'Levels',
			r: '/wms/assets/levels'
		},
		{
			n: 'Transport',
			r: '/wms/assets/transport'
		},
		{
			n: 'Printers',
			r: '/wms/assets/printers'
		},
		{
			n: 'Settings',
			r: '/wms/assets/settings'
		},
		{
			n: 'AgeManagement',
			r: '/wms/assets/agemanagement'
		},
		{
			n: 'ReportsManagement',
			r: '/wms/assets/reportsmanagement'
		},
		{
			n: 'ThreadCompound',
			r: '/wms/assets/threadcompound'
		},
		{
			n: 'InspectionSettings',
			r: '/wms/assets/inspsettings'
		},
		{
			n: 'EquipmentManagement',
			r: '/wms/assets/equipments'
		}
	]
}

export const content = {
	title: 'Content',
	routes: [
		{
			n: 'Content',
			r: '/wms/content/main'
		},
		{
			n: 'NominalDataModification',
			r: '/wms/pipedata/nominaldatamodif'
		},
		{
			n: 'ActualDataModif',
			r: '/wms/pipedata/modify'
		}
	]
}

export const genParams = function (hub) {
	return `?t=${hub.title}${hub.routes
		.map(route => {
			const routeStr = JSON.stringify(route)
			return `&r=${routeStr}`
		})
		.join('')}`
}
