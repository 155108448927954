/** @format */

import axios from 'axios'

import { REPORT_TYPE } from '../utils/enum'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

const newInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/v2/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	generateDailyReport: (wid, language, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`inspection/${wid}/activityreport/${language}`, params, {
					headers: {
						Authorization: token
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getReports: (wid, name, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getOpenReports: (wid, name, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(
					`inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}&status=open&limit=200`,
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMoreOpenReports: (wid, name, numberPerPage, page, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(
					`inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}&status=open&limit=${
						numberPerPage ? numberPerPage : 10
					}&page=${page}`,
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	getClosedReports: (wid, name, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(
					`inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}&status=closed&limit=50`,
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	getMoreReports: (wid, name, numberPerPage, page, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(
					`inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}&limit=${
						numberPerPage ? numberPerPage : 10
					}&page=${page}`,
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	getMoreClosedReports: (wid, name, numberPerPage, page, token, keyWord) =>
		new Promise((resolve, reject) => {
			let url =
				keyWord != null
					? `inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}&status=closed&limit=${
							numberPerPage ? numberPerPage : 50
					  }&page=${page}&search=${keyWord}`
					: `inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}&status=closed&limit=${
							numberPerPage ? numberPerPage : 50
					  }&page=${page}`
			newInstance
				.get(url, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	getInspectionReportDetails: (wid, reportId, inspType, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`/inspection/search/${wid}/type/${inspType}/list/${reportId}`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getReceiptNoteReportData: (wid, reportType, reportId, partId, token) =>
		new Promise((resolve, reject) => {
			let url = `/${REPORT_TYPE.path[reportType]}${wid}/`

			url += `millreceipt/${reportId}/details?partId=${partId}`

			instance
				.get(url, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.millreceiptdetails)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getRejectReasons: (wid, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`/inspection/config/${wid}/defects`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	rejectInspection: (wid, contentID, params, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.put(`/inspection/review/${wid}/content/${contentID}/rejectinspection`, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	reopenInspection: (wid, contentID, params, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.post(`/inspection/review/${wid}/content/${contentID}/create-derived`, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	rejectReport: (wid, reportType, reportId, payload, token) =>
		new Promise((resolve, reject) => {
			const url = `/${REPORT_TYPE.path[reportType]}${wid}/reject/${reportId}`
			instance
				.post(url, payload, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.defectlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	reopenReport: (wid, reportType, reportId, payload, token) =>
		new Promise((resolve, reject) => {
			const url = `/${REPORT_TYPE.path[reportType]}${wid}/reopen/${reportId}`
			instance
				.post(url, payload, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.defectlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),

	reopenReceiptNote: (wid, listID, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`/batchentrance/${wid}/reopen/${listID}`, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),

	validateInspection: (wid, contentID, params, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.put(`/inspection/review/${wid}/content/${contentID}/validateinspection`, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	validateReport: (wid, type, listID, params, token) =>
		new Promise((resolve, reject) => {
			const url = `/${REPORT_TYPE.path[type]}${wid}/validate/${listID}`
			instance
				.post(url, params, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.defectlist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateReport: (wid, reportType, reportId, payload, isInspection, token) =>
		new Promise((resolve, reject) => {
			if (
				!isInspection &&
				(reportType === REPORT_TYPE.code.AGE_EXTRACTS || reportType === REPORT_TYPE.code.MILL_RECEIPT)
			) {
				const url = `/${REPORT_TYPE.path[reportType]}${wid}/update/${reportId}`
				instance
					.post(url, payload, {
						headers: {
							Authorization: token
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) {
							resolve(response.data.defectlist)
						} else {
							reject(new Error('CouldNotConnect'))
						}
					})
					.catch(err => {
						reject(err.response || err)
					})
			} else {
				newInstance
					.put(`/inspection/review/${wid}/content/${reportId}/updateitems`, payload, {
						headers: {
							Authorization: token
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) resolve(response.data)
						else reject(new Error('CouldNotConnect'))
					})
					.catch(err => {
						reject(err.response || err)
					})
			}
		}),
	getEndUsers: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/${wid}/enduserlist`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.enduserlist)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	downloadDispatchReport: (wid, dispatchedId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`dispatch/${wid}/report/${dispatchedId}/excel`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.url)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	downloadInspReports: (wid, reportId, query, part, token) =>
		new Promise((resolve, reject) => {
			const url = part
				? `inspection/search/${wid}/report/${reportId}/excel?type=${query}&part=${part}`
				: `inspection/search/${wid}/report/${reportId}/excel?type=${query}`
			newInstance
				.get(url, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.url)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadReceiptNote: (wid, beid, part, token) =>
		new Promise((resolve, reject) => {
			const url = part
				? `batchentrance/${wid}/receipt-note/${beid}/report/excel?partNumber=${part}`
				: `batchentrance/${wid}/receipt-note/${beid}/report/excel`
			instance
				.get(url, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.url)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	downloadDeliveryNotesPdf: (wid, reportId, part, token) =>
		new Promise((resolve, reject) => {
			const url = part
				? `inspection/search/${wid}/report/${reportId}/pdf?type=delivery-notes&part=${part}`
				: `inspection/search/${wid}/report/${reportId}/pdf?type=delivery-notes`
			newInstance
				.get(url, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.url)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadAgeMgmtReport: (wid, id, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`agemgmt/${wid}/extract/${id}/report/excel`, {
					headers: {
						Authorization: token
					},
					params: {
						customerReport: true
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.url)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	openFile: (wid, type, reportId, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`inspection/search/${wid}/type/${type}/downloadfile/${reportId}`, {
					headers: {
						Authorization: token
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadDeliveryNotes: (wid, reportId, part, token) =>
		new Promise((resolve, reject) => {
			const url = part
				? `inspection/search/${wid}/report/${reportId}/excel?type=delivery-notes&part=${part}`
				: `inspection/search/${wid}/report/${reportId}/excel?type=delivery-notes`
			newInstance
				.get(url, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.url)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadBackload: (wid, reportId, part, token) =>
		new Promise((resolve, reject) => {
			const url = part
				? `inspection/search/${wid}/report/${reportId}/excel?type=backload-manifest&part=${part}`
				: `inspection/search/${wid}/report/${reportId}/excel?type=backload-manifest`
			newInstance
				.get(url, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.url)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadModifProduct: (wid, reportId, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`inspection/search/${wid}/report/${reportId}/excel?type=modified-product`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.url)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	downloadTitleTransfer: (wid, reportId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`agemgmt/${wid}/extract/${reportId}/report/excel?customerReport=true`, {
					headers: {
						Authorization: token
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.url)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	downloadTitleTransferPdfData: (wid, reportId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`agemgmt/${wid}/extract/${reportId}/report/pdf`, {
					headers: {
						Authorization: token
					},
					params: {
						customerReport: true
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data.url)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	searchInfo: (wid, name, token, searchParam) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(
					`inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(
						name
					)}&status=closed&limit=50&search=${encodeURIComponent(searchParam)}`,
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	searchMoreInfo: (wid, name, numberPerPage, page, token, searchParam) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(
					`inspection/search/${wid}/listsbytypename?typeName=${encodeURIComponent(name)}&status=closed&limit=${
						numberPerPage ? numberPerPage : 50
					}&page=${page}&search=${encodeURIComponent(searchParam)}`,
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		})
}
