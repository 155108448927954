/** @format */

import _ from 'lodash'
import { REPORT_ELEMENTS_STATUS, REPORT_ITEM_STATUS, STOCK_STATUS } from '../enum'

const formatPartDataColumns = (column, reports) => ({
	[`${column.name.replace(/ +/g, '').toLowerCase()}`]:
		reports === 0
			? REPORT_ELEMENTS_STATUS.codeString.OK
			: reports === 1
			? REPORT_ELEMENTS_STATUS.codeString.NOTOK
			: reports === -1 && REPORT_ELEMENTS_STATUS.codeString.NA
})

const formatPartColumns = (reports, elements) => {
	const columns = {}
	elements.forEach((el, index) => {
		return Object.assign(columns, formatPartDataColumns(el, reports[index]))
	})
	return columns
}

const formatPartIdColumns = column => ({
	[`${column.name.replace(/ +/g, '').toLowerCase()}elementid`]: column.id
})

const formatPartColumnsID = elements => {
	const columns = {}
	elements.forEach(el => {
		return Object.assign(columns, formatPartIdColumns(el))
	})
	return columns
}

const partDefectColumns = (column, defects) => ({
	[`${column.name.replace(/ +/g, '').toLowerCase()}defect`]:
		defects === 0 ? REPORT_ITEM_STATUS.code.OK : defects < 0 ? REPORT_ITEM_STATUS.code.NA : defects
})

const formatPartDefectColumns = (defects, elements) => {
	const columns = {}
	elements.forEach((el, index) => {
		return Object.assign(columns, partDefectColumns(el, defects[index]))
	})
	return columns
}

const partPhotoColumns = (column, photos) => ({
	[`${column.name.replace(/ +/g, '').toLowerCase()}photo`]:
		photos === '0' ? `${REPORT_ITEM_STATUS.code.OK}` : photos === '-1' ? `${REPORT_ITEM_STATUS.code.NA}` : photos
})

const formatPartPhotoColumns = (photos, elements) => {
	const columns = {}
	elements.forEach((el, index) => {
		return Object.assign(columns, partPhotoColumns(el, photos[index]))
	})
	return columns
}

const formatPartMeasurement = (
	data,
	convertToCurrentLengthUnit,
	convertToCurrentWeightUnit,
	lengthUnit,
	weightUnit
) => {
	if (!data) return null
	return {
		measurestatus: data.status,
		intdiameter: data.int_diameter,
		outdiameter: data.out_diameter,
		measured: convertToCurrentLengthUnit(data.measured_length, lengthUnit),
		measuredweight: convertToCurrentWeightUnit(data.measured_weight, weightUnit),
		makeuploss: data.makeuploss,
		effective: data.effective_length,
		measureresponsible: data.responsible,
		wall: data.wall_thickness,
		measureinstid: data.instruments_list_id,
		measureremarks: data.remarks
	}
}

const partThreadCompound = item => {
	let threadCompound = item.thread_compound ? _.omit(item.thread_compound, ['created_at', 'modified_at']) : null
	threadCompound != null ? (threadCompound['manufacturer'] = threadCompound.type_id) : (threadCompound = null)

	return threadCompound
}
const returnPartValidationData = partDetails => {
	if (!(partDetails.validation_data && Object.keys(partDetails.validation_data)).length) return {}
	else {
		const { status, revision } = partDetails.validation_data
		return {
			validation_revision: revision,
			status,
			updated_date: partDetails.validation_data.last_revision_change,
			validation_date: partDetails.validation_data.last_status_change,
			validation_responsible: partDetails.validation_data.responsible,
			plan_date: partDetails.created_at
		}
	}
}

const returnReportValidationData = reportDetails => {
	if (!(reportDetails.validation_data && Object.keys(reportDetails.validation_data).length)) return {}
	else
		return {
			validation_revision: reportDetails.validation_data.revision,
			status: reportDetails.validation_data.status,
			updated_date: reportDetails.validation_data.last_revision_change,
			validation_date: reportDetails.validation_data.last_status_change,
			validation_responsible: reportDetails.validation_data.responsible,
			revision: reportDetails.validation_data.revision,
			plan_date: reportDetails.validation_data.plan_date
		}
}

const returnSummaryData = reportDetails => {
	if (!(reportDetails.summary && Object.keys(reportDetails.summary).length)) return {}
	else {
		const {
			customer_request_reference,
			field_name,
			well_name,
			rig_name,
			tparty_name,
			tparty_location,
			delivery_location,
			ltpa_number,
			work_order_description
		} = reportDetails.summary
		return {
			customer_request_reference,
			field_name,
			well_name,
			rig_name,
			tparty_name,
			tparty_location,
			delivery_location,
			ltpa_number,
			work_order_description
		}
	}
}

const returnPartFiltersData = partDetails => {
	if (!(partDetails.filters && Object.keys(partDetails.filters).length)) return {}
	else {
		const { erp_reference, material_description, customer_order, customer_item, sales_order, sales_item } =
			partDetails.filters
		return {
			erp_reference,
			material_description,
			customer_order,
			customer_item,
			sales_order,
			sales_item,
			material_desc: partDetails.filters.material_description,
			source_material_desc: partDetails.filters.material_source_description,
			source_ownership: partDetails.filters.source_ownership_name,
			target_ownership: partDetails.filters.target_ownership_name,
			enduser: partDetails.filters.enduser_name
		}
	}
}
const returnReportFiltersData = reportDetails => {
	if (!(reportDetails.filters && Object.keys(reportDetails.filters).length)) return {}
	else {
		const { erp_reference, material_description, customer_order, customer_item, sales_order, sales_item } =
			reportDetails.filters
		return {
			erp_reference,
			material_description,
			customer_order,
			customer_item,
			sales_order,
			sales_item,
			material_desc: reportDetails.filters.material_description,
			source_material_desc: reportDetails.filters.material_source_description,
			source_ownership: reportDetails.filters.source_ownership_name,
			target_ownership: reportDetails.filters.target_ownership_name,
			enduser: reportDetails.filters.enduser_name
		}
	}
}
const formatItems = (
	partDetails,
	elements,
	convertToCurrentLengthUnit,
	convertToCurrentWeightUnit,
	lengthUnit,
	weightUnit
) => {
	return partDetails.items.map(item => ({
		content: item.content,
		level: item.level,
		valid: item.pipe_valid,
		puuid: item.pipe_puuid,
		finalreport: item.inspection.final_report,
		inspector: item.inspection.responsible || '',
		responsible: item.inspection.responsible || '',
		remarks: item.inspection.remarks || '',
		instlistid: item.inspection.instruments_list_id,
		status: item.inspection.status,
		...formatPartColumns(item.inspection.elements_reports, elements),
		...formatPartColumnsID(elements),
		...formatPartDefectColumns(item.inspection.elements_defects, elements),
		...formatPartPhotoColumns(item.inspection.elements_photos, elements),
		...formatPartMeasurement(
			item.measure,
			convertToCurrentLengthUnit,
			convertToCurrentWeightUnit,
			lengthUnit,
			weightUnit
		),
		hasPhoto: item.inspection.elements_photos.some(p => p !== `${REPORT_ITEM_STATUS.code.OK}`)
			? item.inspection.elements_photos.some(p => p !== 'N')
				? true
				: false
			: false,
		...partThreadCompound(item),
		nt_quantity: partDetails.nt_quantity || null
	}))
}
const formatPartInfo = (
	reportDetails,
	partDetails,
	subRow,
	elements,
	convertToCurrentLengthUnit,
	convertToCurrentWeightUnit,
	lengthUnit,
	weightUnit,
	materials
) => {
	let partDetailsData
	let reportDetailsData
	if (partDetails) {
		partDetailsData = {
			...returnPartValidationData(partDetails),
			...returnPartFiltersData(partDetails)
		}
	} else if (
		(reportDetails.validation_data && Object.keys(reportDetails.validation_data).length) ||
		reportDetails.summary
	) {
		reportDetailsData = {
			...returnReportFiltersData(reportDetails),
			...returnReportValidationData(reportDetails),
			...returnSummaryData(reportDetails)
		}
	}

	const unifiedMaterials =
		materials &&
		materials.length > 0 &&
		_.uniqBy(
			materials.filter(material => material.contentid === partDetails.id),
			'mvid'
		)

	return {
		...partDetailsData,
		...reportDetailsData,
		id: reportDetails.id,
		overall_validation_status: reportDetails.overall_validation_status || null,
		plan_responsible: reportDetails.plan_responsible || null,
		part: (subRow && subRow.part) || null,
		partid: partDetails.id,
		accepted_quantity: partDetails.accepted_quantity || reportDetails.accepted_quantity || null,
		total_quantity: partDetails.quantity || reportDetails.quantity || null,
		total_joints: partDetails.items.length,
		tobescrapped:
			partDetails.items &&
			partDetails.items.filter(item => item.inspection.final_report === STOCK_STATUS.code.ToBeScrapped).length,
		toberepaired:
			partDetails.items &&
			partDetails.items.filter(item => item.inspection.final_report === STOCK_STATUS.code.ToBeRepaired).length,
		has_measure: partDetails.items && partDetails.items.some(item => item.measure != null),
		items: formatItems(
			partDetails,
			elements,
			convertToCurrentLengthUnit,
			convertToCurrentWeightUnit,
			lengthUnit,
			weightUnit
		),
		materiallist: unifiedMaterials,
		erplist:
			unifiedMaterials &&
			unifiedMaterials.map(list => ({
				id: list.mvid,
				label: list.formatted.substring(0, list.formatted.indexOf(' '))
			}))
	}
}

const uploadPartFormatCommon = item => ({
	pipe_valid: item.valid,
	pipe_puuid: item.puuid,
	revision: item.revision || 0,
	content: item.content || 0,
	level: item.level || 0
})

const dataURLOrPath = url => {
	if (url.startsWith('data:')) return url
	else if (url.startsWith('/home')) return 'Y'
	else return 'N'
}

const uploadPartInspection = (data, elements) => {
	const elementValues = Object.values(elements.map(el => el.name.replace(/ +/g, '').toLowerCase()))
	const dataKeys = Object.keys(data)
	const inspection = {
		status: data.status || 0,
		final_report: data.finalreport || 0,
		remarks: data.remarks || 'remark',
		instruments_list_id: data.instlistid,
		responsible: data.responsible || '',
		elements_defects: [],
		elements_photos: [],
		elements_reports: []
	}
	dataKeys.forEach(k => {
		if (elementValues.some(el => el === k)) {
			data[k] != null && data[k] === 'Ok'
				? inspection.elements_reports.push(Number(0))
				: data[k] != null && data[k] === 'Rejected'
				? inspection.elements_reports.push(Number(1))
				: data[k] != null && inspection.elements_reports.push(Number(-1))
		}
		if (k.includes('defect')) data[k] != null && data[k] >= -1 && inspection.elements_defects.push(data[k])
		if (k.includes('photo')) data[k] != null && data[k] && inspection.elements_photos.push(dataURLOrPath(data[k]))
	})

	return inspection
}

const convertLengthToSend = (length, lengthUnit) => {
	const lengthFeetRatio = 304.8
	const unitPrefix = lengthUnit === 'mm' ? 1000 : lengthUnit === 'cm' ? 100 : 1

	return lengthUnit === 'mm' ? length : (length * lengthFeetRatio * unitPrefix).toFixed(0)
}

const convertWeightToSend = (weight, weightUnit) => {
	const weightFeetRatio = 0.45359237

	return weightUnit === 'kg' ? weight : weight * weightFeetRatio
}

const uploadPartMeasure = (data, lengthUnit, weightUnit) => {
	const measurements = {
		id: data.id,
		status: data.measurestatus,
		measured_length: convertLengthToSend(data.measured, lengthUnit),
		makeup_loss: data.makeuploss,
		effective_length: data.effective,
		int_diameter: data.intdiameter,
		out_diameter: data.outdiameter,
		wall_thickness: data.wall !== null ? data.wall : 0,
		measured_weight: convertWeightToSend(data.measeuredweight, weightUnit),
		responsible: data.measureresponsible ? data.measureresponsible : data.responsible,
		instruments_list_id: data.measureinstid,
		remarks: data.measureremarks ? data.measureremarks : 'remark'
	}
	return Object.fromEntries(Object.entries(measurements).filter(([v]) => v != null && v !== ''))
}

const uploadPartThreadCompound = (item, threadCompound) => {
	return threadCompound
		? {
				id: item.id ? item.id : 0,
				batch_number: item.batch_number ? item.batch_number : '',
				method_id: item.method_id ? item.method_id : 0,
				type_id: item.manufacturer ? item.manufacturer : 0,
				responsible: item.responsible ? item.responsible : ''
		  }
		: null
}

const uploadPartFormatInspection = (data, threadCompound, elements, lengthUnit, weightUnit) => {
	return data
		.filter(item => item.modified === true)
		.map(item => ({
			...uploadPartFormatCommon(item),
			inspection: uploadPartInspection(item, elements),
			measure: uploadPartMeasure(item, lengthUnit, weightUnit),
			thread_compound: uploadPartThreadCompound(item, threadCompound)
		}))
}

export default function formatPartReport(
	reportDetails,
	partDetails,
	subRow,
	elements,
	convertToCurrentLengthUnit,
	convertToCurrentWeightUnit,
	lengthUnit,
	weightUnit,
	isDownload,
	threadCompound,
	materials,
	items
) {
	return isDownload
		? formatPartInfo(
				reportDetails,
				partDetails,
				subRow,
				elements,
				convertToCurrentLengthUnit,
				convertToCurrentWeightUnit,
				lengthUnit,
				weightUnit,
				materials
		  )
		: uploadPartFormatInspection(items, threadCompound, elements, lengthUnit, weightUnit)
}
