/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getLastLevels: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`levels/${wid}/lastlevels`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response.data.lastlevels)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getPrintersConfig: token =>
		new Promise((resolve, reject) => {
			instance
				.get(`print/config`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	savePrinterConfig: (printer, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`print/config/${printer}`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	testPrinter: (wid, type, params = {}, token) =>
		new Promise((resolve, reject) => {
			if (type === 'Level') {
				instance
					.post(`print/${wid}/lastlevel`, params, {
						headers: {
							Authorization: `${token}`
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) resolve(response)
						else reject(new Error('CouldNotConnect'))
					})
					.catch(err => {
						reject(err.response || err)
					})
			} else if (type === 'Item') {
				instance
					.post(`print/items`, params, {
						headers: {
							Authorization: `${token}`
						}
					})
					.then(response => {
						if (response.status === 200 || response.status === 204) resolve(response)
						else reject(new Error('CouldNotConnect'))
					})
					.catch(err => {
						reject(err.response || err)
					})
			}
		})
}
