/** @format */

import { FlexView } from 'components/common'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ExtractInfo from '../containers/assets/agemgmt/ExtractInfo'
import ExtractList from '../containers/assets/agemgmt/ExtractsList'
import PageTemplate from '../containers/common/PageTemplate'
import { AssetsAgeMgmtContext } from '../stores/AssetsAgeMgmtStore'
import { assets, genParams } from '../utils/hubs'

const AssetsAgeMgmtPage = () => {
	const { t } = useTranslation()
	const { selectedExtract } = useContext(AssetsAgeMgmtContext)

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:AgeManagement'),
				path: '/wms/assets/agemanagement'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' height='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:AgeManagement')}
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView height='100%' width='55%' margin='0 8px 0 0'>
						<ExtractList />
					</FlexView>
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						{selectedExtract && <ExtractInfo />}
					</FlexView>
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default AssetsAgeMgmtPage
