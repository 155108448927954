/** @format */

import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Manager, Reference, Popper } from "react-popper"
import PropTypes from "prop-types"
import Bars from "assets/icons/bars.svg"
import Apps from "assets/icons/apps.svg"
import Calendar from "assets/icons/calendar.svg"
import Clock from "assets/icons/clock.svg"
import ArrowUp from "assets/icons/arrow-up.svg"
import ArrowRight from "assets/icons/arrow-right.svg"
import ArrowRightFast from "assets/icons/arrow-right-fast.svg"
import ArrowRightLast from "assets/icons/arrow-right-last.svg"
import ArrowDown from "assets/icons/arrow-down.svg"
import ArrowLeft from "assets/icons/arrow-left.svg"
import ArrowLeftFast from "assets/icons/arrow-left-fast.svg"
import ArrowLeftLast from "assets/icons/arrow-left-last.svg"
import ChevronUp from "assets/icons/chevron-up.svg"
import ChevronRight from "assets/icons/chevron-right.svg"
import ChevronDown from "assets/icons/chevron-down.svg"
import ChevronLeft from "assets/icons/chevron-left.svg"
import DoubleChevronUp from "assets/icons/double-chevron-up.svg"
import DoubleChevronRight from "assets/icons/double-chevron-right.svg"
import DoubleChevronDown from "assets/icons/double-chevron-down.svg"
import DoubleChevronLeft from "assets/icons/double-chevron-left.svg"
import Check from "assets/icons/check.svg"
import Cross from "assets/icons/cross.svg"
import Warning from "assets/icons/warning.svg"
import Info from "assets/icons/info.svg"
import Chart from "assets/icons/chart.svg"
import Download from "assets/icons/download.svg"
import User from "assets/icons/user.svg"
import Minus from "assets/icons/minus.svg"
import Plus from "assets/icons/plus.svg"
import Refresh from "assets/icons/refresh.svg"
import Settings from "assets/icons/settings.svg"
import Save from "assets/icons/save.svg"
import LockOpen from "assets/icons/lock-open.svg"
import LockClosed from "assets/icons/lock-closed.svg"
import Document from "assets/icons/document.svg"
import DocumentAdd from "assets/icons/document-add.svg"
import DocumentMinus from "assets/icons/document-minus.svg"
import Pen from "assets/icons/pen.svg"
import Trash from "assets/icons/trash.svg"
import Print from "assets/icons/print.svg"
import Alarm from "assets/icons/alarm.svg"
import ClockWarning from "assets/icons/clock-warning.svg"
import ClockAdd from "assets/icons/clock-add.svg"
import ClockMinus from "assets/icons/clock-minus.svg"
import Message from "assets/icons/message.svg"
import MessageQuestion from "assets/icons/message-question.svg"
import MessageWarning from "assets/icons/message-warning.svg"
import MessageCheck from "assets/icons/message-check.svg"
import MessageDots from "assets/icons/message-dots.svg"
import Shuffle from "assets/icons/shuffle.svg"
import BreakHorizontal from "assets/icons/break-horizontal.svg"
import UserGroup from "assets/icons/user-group.svg"
import UserAdd from "assets/icons/user-add.svg"
import UserMinus from "assets/icons/user-minus.svg"
import Mail from "assets/icons/mail.svg"
import MailAdd from "assets/icons/mail-add.svg"
import MailMinus from "assets/icons/mail-minus.svg"
import Upload from "assets/icons/upload.svg"
import Box from "assets/icons/box.svg"
import Time from "assets/icons/time.svg"
import DonwloadSq from "assets/icons/download-sq.svg"
import Collapse from "assets/icons/collapse.svg"
import Expand from "assets/icons/expand.svg"
import Folder from "assets/icons/folder.svg"
import Sonic from "assets/icons/sonic.svg"
import Cut from "assets/icons/cut.svg"
import Search from "assets/icons/search.svg"
import PDF from "assets/icons/pdf.svg"
import CSV from "assets/icons/csv.svg"
import Undo from "assets/icons/undo.svg"
import Change from "assets/icons/change.svg"
import Play from "assets/icons/play.svg"
import Connection from "assets/icons/connection.svg"
import ConnectionOff from "assets/icons/connection-off.svg"
import Help from "assets/icons/help.svg"
import ArrowDownUp from "assets/icons/arrows-down-up.svg"
import Add from "assets/icons/add.svg"
import Edit from "assets/icons/edit.svg"
import World from "assets/icons/world.svg"
import Filter from "assets/icons/filter.svg"
import Results from "assets/icons/results.svg"
import CrossSimple from "assets/icons/cross-simple.svg"
import BreakVertical from "assets/icons/break-vertical.svg"
import Publish from "assets/icons/publish.svg"
import Fix from "assets/icons/fix.svg"
import Gear from "assets/icons/gear.svg"
import Legend from "assets/icons/legend.svg"
import Pin from "assets/icons/pin.svg"
import Copy from "assets/icons/copy.svg"
import Pause from "assets/icons/pause.svg"
import Shop from "assets/icons/shop.svg"
import AddImage from "assets/icons/add-image.svg"
import NoImage from "assets/icons/no-image.svg"
import MoreVert from "assets/icons/more-vert.svg"
import Home from "assets/icons/home.svg"
import LowPriority from "assets/icons/low-priority.svg"

import { withMargin, withWidth, withHeight } from "utils/styled-decorators"

export const icons = {
  bars: Bars,
  apps: Apps,
  calendar: Calendar,
  clock: Clock,
  "clock-add": ClockAdd,
  "clock-minus": ClockMinus,
  "clock-warning": ClockWarning,
  "arrow-up": ArrowUp,
  "arrow-right": ArrowRight,
  "arrow-right-fast": ArrowRightFast,
  "arrow-right-last": ArrowRightLast,
  "arrow-down": ArrowDown,
  "arrow-left": ArrowLeft,
  "arrow-left-fast": ArrowLeftFast,
  "arrow-left-last": ArrowLeftLast,
  "chevron-up": ChevronUp,
  "chevron-right": ChevronRight,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  "double-chevron-up": DoubleChevronUp,
  "double-chevron-right": DoubleChevronRight,
  "double-chevron-down": DoubleChevronDown,
  "double-chevron-left": DoubleChevronLeft,
  check: Check,
  cross: Cross,
  warning: Warning,
  info: Info,
  chart: Chart,
  download: Download,
  user: User,
  minus: Minus,
  plus: Plus,
  refresh: Refresh,
  settings: Settings,
  save: Save,
  "lock-open": LockOpen,
  "lock-closed": LockClosed,
  document: Document,
  "document-add": DocumentAdd,
  "document-minus": DocumentMinus,
  "break-horizontal": BreakHorizontal,
  pen: Pen,
  trash: Trash,
  print: Print,
  alarm: Alarm,
  message: Message,
  "message-question": MessageQuestion,
  "message-warning": MessageWarning,
  "message-check": MessageCheck,
  "message-dots": MessageDots,
  shuffle: Shuffle,
  "user-group": UserGroup,
  "user-add": UserAdd,
  "user-minus": UserMinus,
  mail: Mail,
  "mail-add": MailAdd,
  "mail-minus": MailMinus,
  upload: Upload,
  box: Box,
  collapse: Collapse,
  expand: Expand,
  sonic: Sonic,
  folder: Folder,
  time: Time,
  "download-sq": DonwloadSq,
  cut: Cut,
  search: Search,
  pdf: PDF,
  csv: CSV,
  undo: Undo,
  change: Change,
  play: Play,
  connection: Connection,
  "connection-off": ConnectionOff,
  help: Help,
  "arrow-down-up": ArrowDownUp,
  add: Add,
  edit: Edit,
  world: World,
  filter: Filter,
  results: Results,
  "cross-simple": CrossSimple,
  "break-vertical": BreakVertical,
  publish: Publish,
  fix: Fix,
  gear: Gear,
  legend: Legend,
  pin: Pin,
  copy: Copy,
  pause: Pause,
  shop: Shop,
  "add-image": AddImage,
  "no-image": NoImage,
  "more-vert": MoreVert,
  home: Home,
  "low-priority": LowPriority,
}

const StyledIcon = styled.div`
  background-color: ${({ theme, color }) =>
    theme.colors[color] || theme.colors.gray};
  mask-image: url(${({ name }) => icons[name] || null});
  background-repeat: no-repeat;
  background-size: cover;
  mask-size: cover;
  min-width: ${({ width }) => width || "24px"};
  min-height: ${({ height }) => height || "24px"};
  ${({ onClick }) => onClick && "cursor: pointer;"}
  ${withMargin()}
  ${withWidth("24px")}
  ${withHeight("24px")}
  ${({ disabled }) => disabled && "opacity: 0.4; cursor: not-allowed;"}
  ${({ visible }) => !visible && "display: none;"}
`

const Tooltip = styled.div`
  position: relative;
  font-family: "Noto Sans";
  display: flex;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  flex-direction: column;
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  z-index: 999;
  transition: all 0.2s ease;
  ${({ theme }) => css`
    background-color: ${theme.colors.darkGray};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.small};
    box-shadow: ${theme.boxShadows.high};
  `}

  /* Arrow Style */
  div {
    content: "";
    transform: rotate(45deg);
    background: ${({ theme }) => theme.colors.darkGray};
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: -1;
  }

  &[data-placement="bottom"] div {
    top: -4px;
  }
  &[data-placement="right"] div {
    left: -4px;
  }
  &[data-placement="left"] div {
    right: -4px;
  }
  &[data-placement="top"] div {
    bottom: -4px;
  }
`

const TooltipPopper = React.forwardRef(
  (
    {
      style,
      scheduleUpdate,
      tooltipPosition,
      placement,
      visibility,
      tooltip,
      arrowProps,
    },
    ref
  ) => {
    useEffect(() => {
      scheduleUpdate()
    }, [visibility, scheduleUpdate])

    const tooltipStyle = {
      ...style,
      transform: `${style.transform} ${
        visibility ? "translateX(0)" : "scale(0.9)"
      }`,
    }

    return (
      <Tooltip
        open={visibility}
        tooltipPosition={tooltipPosition}
        ref={ref}
        style={tooltipStyle}
        data-placement={placement}
      >
        {tooltip}
        <div ref={arrowProps.ref} style={arrowProps.style} />
      </Tooltip>
    )
  }
)

const Icon = ({ tooltip, tooltipPosition, ...rest }) => {
  const [visibility, setVisibility] = useState(false)

  const showTooltip = () => setVisibility(true)
  const hideTooltip = () => setVisibility(false)

  const renderIcon = tooltip ? (
    <Manager>
      <Reference>
        {({ ref }) => (
          <StyledIcon
            {...rest}
            ref={ref}
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
          />
        )}
      </Reference>
      <Popper
        placement={tooltipPosition || "top"}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: "viewport",
          },
        }}
      >
        {({ ref, style, placement, scheduleUpdate, arrowProps }) => (
          <TooltipPopper
            {...{
              style,
              scheduleUpdate,
              tooltipPosition,
              placement,
              visibility,
              tooltip,
              arrowProps,
              ref,
            }}
          />
        )}
      </Popper>
    </Manager>
  ) : (
    <StyledIcon {...rest} />
  )

  return renderIcon
}

Icon.propTypes = {
  /**
   * Define the icon that should be rendered
   */
  name: PropTypes.string,
  /**
   * Tooltip text that will appear when you hover the icon
   */
  tooltip: PropTypes.string,
  /**
   * Tooltip position (top | right | bottom | left)
   */
  tooltipPosition: PropTypes.string,
  /**
   * A color key defined in the theme
   */
  color: PropTypes.string,
  /**
   * Override CSS width property. Must be a valid CSS width value as a string
   */
  width: PropTypes.string,
  /**
   * Override CSS height property. Must be a valid CSS height value as a string
   */
  height: PropTypes.string,
  /**
   * Override CSS margin property. Must be a valid CSS margin value as a string
   */
  margin: PropTypes.string,
  /**
   * Override CSS to disable the icon if true
   */
  disabled: PropTypes.bool,
  /**
   * Override CSS to hide the icon if false
   */
  visible: PropTypes.bool,
}

Icon.defaultProps = {
  disabled: false,
  visible: true,
}

export default Icon
