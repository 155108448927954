import React, { useContext, useState, useEffect } from 'react'
import { FlexView, Icon, Card, Link } from 'components/common'
import { Input } from 'components/form'
import UsersModal from './UsersModal'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { UsersContext } from 'apps/admin/stores/UsersStore'
import { toast } from 'react-toastify'


const UserCard = () => {
  const { users, setSelectedGroup, selectedGroup, getUsers } = useContext(UsersContext)
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [mode, setMode] = useState([])
  const [userData, setUserData] = useState([])
  const [pagination, setPagination] = useState({
    users: [],
    currentpage: 1,
    numperpage: 4,
    length: 0,
    max: true,
    min: true,
    begin: 0,
    end: 0
  })

  const handleChange = e => {
    setSearchTerm(e.target.value)
  }
  const [usersModalVisible, setUsersModalVisible] = useState(false)
  const openUsersModal = () => setUsersModalVisible(true)
  const closeUsersModal = () => setUsersModalVisible(false)

  useEffect(() => {
    const results = _.filter(users, function (obj) {
      return (obj.name).toLowerCase().indexOf(searchTerm) !== -1
    })

    setSearchResults(results)
    updatePagination()

  }, [searchTerm,users]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    updatePagination()
    renderItems()

  }, [searchResults]) // eslint-disable-line react-hooks/exhaustive-deps


  const editUser = (user) => e => {
    setUserData(user)
    setMode("edit")
    openUsersModal(true)
  }

  const openModalAddUser = e => {
    setMode("new")
    setUsersModalVisible(true)
  }

  const showAll = e => {
    setSelectedGroup(undefined)
    getUsers()
    updatePagination()
  }


  const onRefresh = e => {
    toast.info(t('admin:users.loadingData'))
    getUsers(selectedGroup ? selectedGroup.id : undefined)
    updatePagination()
  }

  const updatePagination = () => {
    pagination.length = searchResults.length
    pagination.currentpage = 1
    setPagination({ ...pagination })

    updatePaginationItems()
  }

  const updatePaginationItems = () => {
    const pages = { ...pagination }
    if (pages.currentpage < 1) {
      pages.currentpage = 1
      pages.min = true
    }
    else pages.min = false

    let begin = ((pages.currentpage - 1) * pages.numperpage)
    let end = begin + pages.numperpage

    if (end >= pages.length) {
      end = pages.length
      pages.max = true
    }
    else pages.max = false

    pages.begin = begin
    pages.end = end

    pages.users = searchResults.slice(begin, end)

    setPagination(pages)
  }

  const onNextPage = () => {

    pagination.currentpage += 1
    setPagination({ ...pagination })
    updatePaginationItems()
  }

  const onPrevPage = () => {
    pagination.currentpage -= 1
    setPagination({ ...pagination })
    updatePaginationItems()
  }

  const renderItems = () => {
    return _.map(pagination.users, (result) => (
      <Card height="80px" key={result.id} alignItems="stretch" onClick={editUser(result)} margin="3px">
        <FlexView flexDirection="row" alignItems="stretch" justifyContent="flex-start" width="100%" margin="15px 0px 0px 0px">
          <Icon name="user" width="32px" height="32px" margin="0px 15px 0px 0px" /> {result.displayName}
        </FlexView>
        <FlexView flexDirection="row" alignItems="stretch" justifyContent="flex-start" width="100%" margin="15px 0px 0px 0px">
          {t('admin:users.ID')} : {result.name} |<Icon name="mail" width="24px" height="24px" margin="0px 5px 0px 5px" /> - {result.email} | {result.enabled ? t('admin:users.enabled') : t('admin:users.disabled')}
        </FlexView>
      </Card>
    ))
  }

  return <>
    <FlexView flexDirection="row" alignItems="stretch" justifyContent="space-between" width="100%" backgroundColor="primary" color="white">
      <FlexView flexDirection="column" alignItems="stretch" justifyContent="flex-start" margin="15px 0px 0px 0px">
        {selectedGroup !== undefined ?
          <FlexView flexDirection="row" alignItems="stretch" justifyContent="flex-start" width="100%" margin="20px 0px 0px 0px" fontWeight="bold">
            {t('admin:users.users')} {'[' + selectedGroup.name + ']'} <Link onClick={showAll} color="yellow" margin="0px 10px">{t('admin:users.showAll')}</Link>
          </FlexView> :
          <FlexView flexDirection="row" alignItems="stretch" justifyContent="flex-start" width="100%" margin="25px 0px 0px 20px" fontWeight="bold">{t('admin:users.users')}</FlexView>
        }

      </FlexView>
      <FlexView flexDirection="column" alignItems="space-between" justifyContent="flex-end" width="60%" margin="15px 0px 0px 0px">
        <FlexView flexDirection="row" alignItems="stretch" justifyContent="flex-start" width="100%">
          <Icon name="search" width="24px" height="24px" margin="25px 15px" color="white" tooltip={t('admin:users.search')} />
          <Input type="text" width="100%" laceholder="Search" value={searchTerm} onChange={handleChange} />
          <Icon name="user-add" width="24px" height="24px" margin="25px 15px" color="white" tooltip={t('admin:users.addUser')} onClick={openModalAddUser} />
          <Icon name="refresh" width="24px" height="24px" margin="25px 15px" color="white" tooltip={t('admin:users.refresh')} onClick={onRefresh} />
          <Icon name="arrow-left" width="24px" height="24px" margin="25px 5px" color="white" tooltip={t('admin:users.previous')} onClick={onPrevPage} />
          <FlexView flexDirection="row" alignItems="stretch" justifyContent="center" width="35%" margin="25px 0px 0px 0px" fontWeight="bold"> {pagination.begin + 1}-{pagination.end}/{pagination.length}</FlexView>
          {pagination.max ? '' : <Icon name="arrow-right" width="24px" height="24px" margin="25px 5px" color="white" tooltip={t('admin:users.next')} onClick={onNextPage} />}
        </FlexView>
      </FlexView>

    </FlexView>
    <FlexView flexDirection="column" alignItems="stretch" justifyContent="flex-start" width="100%">
      {renderItems()}
    </FlexView>
    <UsersModal isOpen={usersModalVisible} onOutsideClick={closeUsersModal} mode={mode} data={userData} action={onRefresh} />
  </>
}

export default UserCard
