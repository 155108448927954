import React, { useMemo, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { StatusContext } from 'apps/admin/stores/StatusStore'
import { Table, FlexView, Icon, Button, LoadingOverlay } from 'components/common'
import ChartsModal from './ChartsModal'
import { DialogModal } from 'components/form'

const AppsTable = () => {
  const { t } = useTranslation()
  const { apps, host, getAppStatus, executeCommand } = useContext(StatusContext)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [dialogState, setDialogState] = useState({
    name: '',
    command: '',
    onConfirm: () => null,
    show: false
  })
  const { memtotal } = host

  const onDialogCancel = () => setDialogState(currentState => ({
    ...currentState,
    show: false
  }))

  const data = _.chain(apps).map(app => ({
    ...app,
    meancpu: app.meancpu.toFixed(2) + '%',
    maxcpu: app.maxcpu.toFixed(2) + '%',
    meanmem: ((app.meanmem/memtotal[0])*100).toFixed(2) + '%',
    maxmem: ((app.maxmem/memtotal[0])*100).toFixed(2) + '%',
    meandisk: (app.meandisk/1048576).toFixed(2) + ' MB',
    maxdisk: (app.maxdisk/1048576).toFixed(2) + ' MB'
  })).orderBy('name').value()

  const closeModal = () => {
    setModalOpen(false)
  }

  const columns = useMemo(() => {
    const onAppChartClick = app => () => {
      getAppStatus(app)
      setModalOpen(true)
    }

    const onDialogConfirm = url => async () => {
      setLoading(true)
      try {
        await executeCommand(url)
      }
      catch (e) {
        console.log(e)
        toast.error(e.data || t(`admin:errors.${e.message}`))
      }
      finally {
        setLoading(false)
        setDialogState(currentState => ({
          ...currentState,
          show: false
        }))
      }
    }

    const onCommandClick = (name, text, url) => async () => {
      setDialogState({
        name,
        command: t(`admin:status.commands.${text}`),
        onConfirm: onDialogConfirm(url),
        show: true
      })
    }

    return [
      {
        Header: t('admin:status.Info'),
        columns: [
          {
            Header: t('admin:status.Name'),
            accessor: 'name',
            Cell: ({ cell: { value, row } }) => <FlexView flexDirection="row" alignItems="center" justifyContent="space-between">
              <div>
                {value}
              </div>
              <Icon name={row.original.up ? 'check' : 'cross'} color={row.original.up ? 'success' : 'error'} width="24px" height="24px" margin="0px 0px 0px 8px" />
            </FlexView>
          },
          {
            Header: t('admin:status.Version'),
            accessor: 'version',
          },
          {
            Header: t('admin:status.HasFailed'),
            accessor: ('hasfailed'),
            Cell: ({ cell: { value } }) => <FlexView alignItems="center" justifyContet="center">
              <Icon name={value ? 'cross' : 'check'} color={value ? 'error' : 'success'} width="24px" height="24px" />
            </FlexView>
          },
        ],
      },
      {
        Header: t('admin:status.CPUUsage'),
        columns: [
          {
            Header: t('admin:status.Average'),
            accessor: 'meancpu',
          },
          {
            Header: t('admin:status.Maximum'),
            accessor: 'maxcpu',
          }
        ],
      },
      {
        Header: t('admin:status.MemoryUsage'),
        columns: [
          {
            Header: t('admin:status.Average'),
            accessor: 'meanmem',
          },
          {
            Header: t('admin:status.Maximum'),
            accessor: 'maxmem',
          }
        ],
      },
      {
        Header: t('admin:status.DiskUsage'),
        columns: [
          {
            Header: t('admin:status.Average'),
            accessor: 'meandisk',
          },
          {
            Header: t('admin:status.Maximum'),
            accessor: 'maxdisk',
          }
        ],
      },
      {
        Header: t('admin:status.Options'),
        columns: [
          {
            Header: t('admin:status.Charts'),
            id: 'charts',
            accessor: 'name',
            Cell: ({ cell: { value } }) => <FlexView alignItems="center" justifyContet="center">
              <Icon name="chart" width="24px" height="24px" onClick={onAppChartClick(value)} />
            </FlexView>
          },
          {
            Header: t('admin:status.Commands'),
            id: 'commands',
            accessor: 'commands',
            Cell: ({ cell: { value, row } }) => <FlexView flexDirection="row" alignItems="center" justifyContet="center">
              {_.map(_.chain(value).orderBy('text').filter(({ text }) => text !== 'status').value(), ({ text, url }) => (
                <Button key={text} margin="0px 4px" onClick={onCommandClick(row.original.name, text, url)}>{t(`admin:status.commands.${text}`)}</Button>
              ))}
            </FlexView>
          }
        ],
      }
    ]
  }, [t, getAppStatus, executeCommand])

  return <>
    <LoadingOverlay visible={isLoading} size="160px" />
    <DialogModal
      isOpen={dialogState.show}
      title={t('admin:status.Confirm Command')}
      text={t('admin:status.confirmCommandMessage', { command: dialogState.command, name: dialogState.name })}
      onConfirm={dialogState.onConfirm}
      onCancel={onDialogCancel}
    />
    <Table margin="16px" width="calc(100% - 32px)" columns={columns} data={data} />
    <ChartsModal isOpen={isModalOpen} onOutsideClick={closeModal} />
  </>
}

export default AppsTable
