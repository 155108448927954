/** @format */

import { FlexView } from 'components/common'
import React, { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PageTemplate from '../containers/common/PageTemplate'
import PerInspList from '../containers/periodic/List'
import PerInspInfo from '../containers/periodic/Info'
import { PerInspContext } from '../stores/PerInspStore'
import { genParams, inspection } from '../utils/hubs'
import { WMSContext } from '../stores/WmsStore'
import SearchBar from '../containers/common/SearchBar'

const PerInspPage = () => {
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const {
		summarized,
		getReports,
		searchPeriodicInsp,
		keyWord,
		setKeyWord,
		selectedInspection,
		reports,
		filteredReports,
		loadingReports
	} = useContext(PerInspContext)

	useEffect(() => {
		getReports().catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	const handleSearchInfo = event => {
		const name = 'Periodic Inspection'
		event.key === 'Enter' && keyWord !== '' && searchPeriodicInsp(name, event.target.value)
	}

	const searchParamReports = () => {
		const name = 'Periodic Inspection'
		searchPeriodicInsp(name, keyWord).catch(e => console.error(e))
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Inspection'),
				path: `/wms/inspection${genParams(inspection)}`
			},
			{
				name: t('wms:PeriodicInspection'),
				path: '/wms/manage/inspection/perinsp'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs} height='90%'>
			<FlexView width='100%' height='90%' maxWidth='calc(100% - 32px)' margin='0 0 16px 0'>
				<FlexView width='calc(100% - 12px)' flexDirection='row'>
					<FlexView width='82%' minWidth='50%' fontSize='40px' fontWeight='bold' margin='0 auto 24px 0'>
						{t('wms:PeriodicInspection')}
					</FlexView>
					<SearchBar
						loadingReports={loadingReports}
						keyWord={keyWord}
						inspSelected={selectedInspection}
						inspType={null}
						onSearchInfo={value => handleSearchInfo(value)}
						setKeyWord={setKeyWord}
						onSearchParams={() => searchParamReports()}
					/>
				</FlexView>
				<FlexView width='100%' height='85%' flexDirection='row' padding='0'>
					<PerInspList list={filteredReports && filteredReports.length > 0 ? filteredReports : reports} />
					{Object.keys(summarized).length > 0 && <PerInspInfo />}
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default PerInspPage
