import React, { useContext, useState, useEffect } from 'react'
import { StatusContext } from 'apps/admin/stores/StatusStore'
import { FlexView, Card } from 'components/common'
import StatusForm from '../containers/status/StatusForm'
import CpuChart from '../containers/status/CpuChart'
import MemoryChart from '../containers/status/MemoryChart'
import DiskChart from '../containers/status/DiskChart'
import { CSSTransition } from 'react-transition-group'
import AppsTable from '../containers/status/AppsTable'

const StatusPage = () => {
  const [show, setShow]= useState({
    host: false,
    apps: false
  })
  const { host, apps } = useContext(StatusContext)

  useEffect(() => {
    host && host.time.length && setShow(currentShow => ({
      ...currentShow,
      host: true
    }))
  }, [host, setShow])

  useEffect(() => {
    apps && apps.length && setShow(currentShow => ({
      ...currentShow,
      apps: true
    }))
  }, [apps, setShow])

  return (
    <FlexView width="100%" alignItems="stretch" position="relative">
      <Card margin="16px 16px 0px">
        <StatusForm />
      </Card>
      <CSSTransition
        in={show.host}
        timeout={800}
        classNames="slow-fade"
        unmountOnExit
      >
        <FlexView flexDirection="row" flexWrap="wrap">
          <Card flex="1 250px" flexDirection="row" margin="16px" style={{ overflow: 'hidden' }}>
            <CpuChart time={host.time} cpu={host.cpu} />
          </Card>
          <Card flex="1 250px" flexDirection="row" margin="16px" style={{ overflow: 'hidden' }}>
            <MemoryChart time={host.time} mem={host.memfree} memTotal={host.memtotal} isPercentage />
          </Card>
          <Card flex="1 250px" flexDirection="row" margin="16px" style={{ overflow: 'hidden' }}>
            <DiskChart time={host.time} disk={host.diskfree} diskTotal={host.disktotal} isPercentage />
          </Card>
        </FlexView>
      </CSSTransition>
      <CSSTransition
        in={show.apps}
        timeout={800}
        classNames="slow-fade"
        unmountOnExit
      >
        <AppsTable />
      </CSSTransition>
    </FlexView>
  )
}

export default StatusPage