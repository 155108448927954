/** @format */

import { BatchEntranceContext } from 'apps/wms/stores/BatchEntranceStore'
import { FlexView, LoadingOverlay } from 'components/common'
import { Input, Select } from 'components/form'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const BatchEntranceFilter = () => {
	const { t } = useTranslation()
	const {
		endUsers,
		endUser,
		setEndUser,
		material,
		materials,
		setMaterial,
		sourceType,
		customerOrder,
		setCustomerOrder,
		customerOrders,
		filteredPoItems,
		customerItem,
		setCustomerItem,
		expectedQuantity,
		setExpectedQuantity,
		salesOrder,
		setSalesOrder,
		salesOrders,
		salesItem,
		filteredSalesItems,
		setSalesItem,
		newContent,
		loadingEndUsers,
		loadingMaterialList,
		loadingOrders
	} = useContext(BatchEntranceContext)

	const selectOptionStr = t('wms:SelectOption')

	return (
		<FlexView flexDirection='column' width='100%' justifyContent='space-around' position='relative'>
			<LoadingOverlay visible={loadingEndUsers} />
			<Select
				label={t('wms:EndUser')}
				placeholder={selectOptionStr}
				value={endUser && endUser.id}
				options={endUsers.map(eu => ({ value: eu.id, label: eu.name }))}
				onChange={value => setEndUser(endUsers.find(eu => eu.id === value))}
				width='100%'
				margin='0 0 8px 0'
				disabled={newContent.length !== 0 || sourceType === null}
				searchable
				dataCy='select-end-user'></Select>
			<FlexView width='100%' position='relative'>
				<LoadingOverlay visible={loadingMaterialList} />
				<Select
					label={t('wms:Material')}
					placeholder={selectOptionStr}
					value={material}
					options={
						materials &&
						materials.map(m => {
							return { value: m.value, label: m.label }
						})
					}
					onChange={value => setMaterial(value)}
					searchable
					width='100%'
					margin='0 0 8px 0'
					disabled={newContent.length !== 0 || endUser === null}
					dataCy='select-material'></Select>
			</FlexView>

			{sourceType && sourceType === 'CUSTOMER_ITEM' ? (
				<FlexView flexDirection='row' width='100%' justifyContent='space-between' position='relative'>
					<LoadingOverlay visible={loadingOrders} />
					<Select
						label={t('wms:CustomerOrder')}
						placeholder={selectOptionStr}
						value={customerOrder}
						options={
							customerOrders &&
							customerOrders.map(c => {
								return { value: c.label, label: c.label }
							})
						}
						onChange={value => setCustomerOrder(value)}
						width='calc(50% - 8px)'
						margin='0 0 8px 0'
						disabled={newContent.length !== 0 || material === null}
						searchable
						dataCy='select-customer-order'></Select>
					<Select
						label={t('wms:CustomerItem')}
						placeholder={selectOptionStr}
						value={customerItem}
						options={
							filteredPoItems &&
							filteredPoItems.map(p => {
								return { value: p.label, label: p.label }
							})
						}
						onChange={value => setCustomerItem(value)}
						width='calc(50% - 8px)'
						margin='0 0 8px 0'
						disabled={newContent.length !== 0 || customerOrder === null}
						searchable
						dataCy='select-customer-item'></Select>
				</FlexView>
			) : null}
			{sourceType && sourceType === 'SALES_ITEM' ? (
				<FlexView flexDirection='row' width='100%' justifyContent='space-between' position='relative'>
					<LoadingOverlay visible={loadingOrders} />
					<Select
						label={t('wms:SalesOrder')}
						placeholder={selectOptionStr}
						value={salesOrder}
						options={
							salesOrders &&
							salesOrders.map(c => {
								return { value: c.label, label: c.label }
							})
						}
						onChange={value => setSalesOrder(value)}
						width='calc(50% - 8px)'
						margin='0'
						disabled={newContent.length !== 0 || material === null}
						dataCy='select-sales-order'></Select>
					<Select
						label={t('wms:SalesItem')}
						placeholder={selectOptionStr}
						value={salesItem}
						options={
							filteredSalesItems &&
							filteredSalesItems.map(p => {
								return { value: p.label, label: p.label }
							})
						}
						onChange={value => setSalesItem(value)}
						width='calc(50% - 8px)'
						margin='0 0 8px 0'
						disabled={newContent.length !== 0 || salesOrder === null}
						dataCy='select-sales-item'></Select>
				</FlexView>
			) : null}
			<Input
				message={t('wms:QuantityAllowed')}
				messageType='info'
				type='number'
				min='1'
				max='99999'
				label={t('wms:ExpectedQuantity')}
				value={expectedQuantity}
				onChange={e => setExpectedQuantity(e.target.value)}
				width='100%'
				minWidth='100%'
				margin='0 0 8px 0'
				disabled={salesItem === null && customerItem === null}
				data-cy='input-expected-quantity'
			/>
		</FlexView>
	)
}

export default BatchEntranceFilter
