/** @format */
/* eslint-disable react/prop-types */

import React from 'react'
import FlexView from 'components/common/FlexView'
import { Button, Icon, Link } from 'components/common'
import styled from 'styled-components'
import { useHistory } from 'react-router'

const ActionButton = styled(Button)`
	border-radius: 4px;
	font-size: 14px;
	line-height: 21px;
	padding: 4px 8px;
	font: Roboto;
	height: 40px;
	box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13);
`

const BreadCrumbs = ({ crumbs }) => {
	const history = useHistory()

	const handleNavigation = path => {
		path !== null && history.push(path)
	}

	return crumbs && crumbs.length ? (
		<FlexView flexDirection='row' alignItems='center' data-cy='flex-view-bread-crumbs'>
			<ActionButton
				backgroundColor='white'
				onClick={() =>
					handleNavigation(crumbs.length - 2 < 0 ? crumbs[crumbs.length - 1].path : crumbs[crumbs.length - 2].path)
				}
				margin='0 8px 0 0'>
				<Icon name='arrow-left' color='gray' height='14px' width='14px' margin='8px 4px' />
			</ActionButton>
			{crumbs.map((c, i) => (
				<>
					<Link noDecoration={true} margin='0 6px' onClick={() => handleNavigation(c.path)} key={i}>
						<FlexView
							flexDirection='row'
							alignItems='center'
							color={i === crumbs.length - 1 ? 'secondary' : 'gray'}
							style={{ textTransform: 'capitalize', cursor: 'pointer' }}>
							{!i ? <Icon name='home' color='gray' height='28px' width='28px' margin='4px' /> : null}
							{c.name}
						</FlexView>
					</Link>
					{i !== crumbs.length - 1 ? <FlexView color='gray'> / </FlexView> : null}
				</>
			))}
		</FlexView>
	) : null
}

export default BreadCrumbs
