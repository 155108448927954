/** @format */

import { NominalDataContext } from 'apps/wms/stores/NominalDataModifStore'
import { Button, Card, FlexView, Icon } from 'components/common'
import { Select } from 'components/form'
import _ from 'lodash'
import React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmAllModal from './ConfirmAllModal'
import ConfirmModal from './ConfirmModal'

const TargetMaterial = () => {
	const { t } = useTranslation()
	const {
		entranceSelected,
		entranceList,
		materials,
		matSelected,
		setMatSelected,
		modifAllEntrance,
		openConfirmModal,
		setOpenConfirmModal,
		changeEntrance,
		setChangeEntrance,
		selectedItem,
		itemsToShow
	} = useContext(NominalDataContext)

	const changeNomData = () => {
		;(modifAllEntrance && matSelected) || (selectedItem.length === itemsToShow.length && matSelected)
			? setOpenConfirmModal(true)
			: setChangeEntrance(true)
	}

	return (
		<FlexView width='25%' flexDirection='column' height='100%' style={{ position: 'relative' }}>
			<Card width='90%' height='100%'>
				{entranceSelected ? (
					<FlexView flexDirection='column' width='100%' height='100%'>
						<FlexView flexDirection='column' width='100%'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold'>
								{t('wms:CurrentMaterial')}
							</FlexView>
							<FlexView
								width='100%'
								fontSize='small'
								margin='8px 0 0 0'
								fontWeight='bold'
								color='metalic'
								style={{ textAlign: 'center' }}>
								{entranceSelected && _.find(entranceList, entrance => entrance.id === entranceSelected).matdesc}
							</FlexView>
						</FlexView>
						{materials && (
							<FlexView flexDirection='column' width='100%'>
								<Select
									searchable
									margin='24px 0 0 0'
									width='100%'
									label={t('wms:NewMaterial')}
									placeholder={t('wms:NewMaterial')}
									value={matSelected}
									options={_.map(materials, mat => {
										return { value: mat.value, label: mat.label }
									})}
									onChange={v => setMatSelected(v)}
								/>
								<FlexView
									width='100%'
									fontSize='small'
									margin='8px 0 0 0'
									fontWeight='bold'
									color='metalic'
									style={{ textAlign: 'center' }}>
									{matSelected && _.find(materials, mat => mat.value === matSelected).label}
								</FlexView>
							</FlexView>
						)}
						<Button
							margin='auto auto 0 auto'
							backgroundColor='secondary'
							color='white'
							onClick={() => changeNomData()}
							disabled={!((matSelected && selectedItem.length) || (matSelected && modifAllEntrance))}>
							{t('wms:ChangeNominalData')}
							<Icon name='change' color='white' width='14px' height='14px' margin='0 0 0 8px' />
						</Button>
					</FlexView>
				) : (
					<FlexView
						height='100%'
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						style={{ textAlign: 'center' }}
						data-cy='no-entrance-selected'>
						{t('wms:NoEntranceSelected')}
					</FlexView>
				)}
			</Card>
			{openConfirmModal && (
				<ConfirmAllModal isOpen={openConfirmModal} onOutsideClick={() => setOpenConfirmModal(false)} />
			)}
			{changeEntrance && <ConfirmModal isOpen={changeEntrance} onOutsideClick={() => setChangeEntrance(false)} />}
		</FlexView>
	)
}

export default TargetMaterial
