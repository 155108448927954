/** @format */

import styled from 'styled-components'
import { withBorderRadius, withElevation, withMargin, withWidth, withFontSize } from 'utils/styled-decorators'

const TableWrapper = styled.div`
	${withFontSize('medium')}
	${withMargin()}
  ${withWidth('fit-content')}
  ${withBorderRadius('component')}
  ${withElevation('medium')};

	/* This is required to make the table full-width */
	display: block;
	min-width: 580px;
	max-width: 100%;

	/* This will make the table scrollable when it gets too small */
	.table-wrap {
		display: block;
		max-width: 100%;
		overflow-x: auto;
		overflow-y: hidden;

		/* Customize website's scrollbar like Mac OS
    Not supports in Firefox and IE */

		/* total width */
		&::-webkit-scrollbar {
			background-color: #fff;
			width: 16px;
		}

		/* background of the scrollbar except button or resizer */
		&::-webkit-scrollbar-track {
			background-color: #fff;
		}

		/* scrollbar itself */
		&::-webkit-scrollbar-thumb {
			background-color: #babac0;
			border-radius: 16px;
			border: 4px solid #fff;
		}

		/* set button(top and bottom of the scrollbar) */
		&::-webkit-scrollbar-button {
			display: none;
		}
	}

	font-family: 'Noto Sans';
	color: ${({ theme }) => theme.colors.darkGray};

	table {
		width: 100%;
		border-spacing: 0;

		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		thead,
		tfoot {
			font-weight: bold;
			color: ${({ theme }) => theme.colors.metalic};
			text-align: center;
		}

		th.sortable {
			cursor: pointer;

			svg {
				margin-left: 8px;
				margin-bottom: 2px;
			}
		}

		th,
		td {
			margin: 0;
			padding: 8px;
			white-space: nowrap;
			border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
			border-right: 1px solid ${({ theme }) => theme.colors.lightGray};

			:last-child {
				border-right: 0;
			}

			/* Each cell should grow equally */
			width: 1%;
			/* But "collapsed" cells should be as small as possible */
			&.collapse {
				width: 0.0000000001%;
			}
		}

		th {
			border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
		}

		tbody {
			tr {
				transition: background-color 0.4s ease;

				&:hover {
					background-color: ${({ theme }) => theme.colors.offWhite};
				}
			}
		}
	}

	.pagination {
		padding: 8px 24px;
		border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
`

export default TableWrapper
