import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from 'stores/UserStore'
import userApi from '../api/users'
import { LoadingOverlay } from 'components/common'
import _ from 'lodash'

export const UsersContext = React.createContext()

export const UsersProvider = ({ children }) => {
  const [groups, setGroups] = useState([])
  const [optionsGroup, setOptionsGroup] = useState([])
  const [selectedGroup, setSelectedGroup] = useState([])
  const [users, setUsers] = useState([])
  const [initialLoading, setInitialLoading] = useState(true)
  const { token } = useContext(UserContext)

  const getGroups = () => new Promise((resolve, reject) => {
    userApi.getGroups(token).then(data => {
      setGroups(data)
      _.map(data, (g) => (
        optionsGroup.push({ value: g.id, label: g.name })
      ))
      setOptionsGroup(optionsGroup)
      resolve()
    }).catch(error => {
      reject(error)
    })
  })

  const getUsers = (groupID = null) => new Promise((resolve, reject) => {
    userApi.getUsers(groupID, token).then(data => {
      setUsers(data)
      resolve(data)
    }).catch(error => {
      reject(error)
    })
  })

  const addUser = (user, roles) => new Promise((resolve, reject) => {
    userApi.createNewUser(user, token).then(data => {
      getUsers().then(updatedUsers => {
        const userAdded = _.find(updatedUsers, { name: user.name })
        if (userAdded) {
          _.each(roles, (group) => {
            addUserToGroup(userAdded.id, group)
          })
          resolve()
        } else {
          reject(new Error("notFound"))
        }
      }).catch(error => {
        reject(error)
      })
    }).catch(error => {
      reject(error)
    })
  })

  const editUser = (user, removeRoles, addRoles) => new Promise((resolve, reject) => {
    userApi.editUser(user, token).then(data => {
      const actions = []
      if (removeRoles.length > 0) {
        _.each(removeRoles, (group) => {
          actions.push(removeUserFromGroup(user.id, group).catch(error => {
            reject(new Error("removeError"))
          }))
        })
      }
      if (addRoles.length > 0) {
        _.each(addRoles, (group) => {
          actions.push(addUserToGroup(user.id, group).catch(error => {
            reject(new Error("addError"))
          }))
        })
      }
      Promise.all(actions).then(() => {
        resolve()
      })
    }).catch(error => {
      reject(error)
    })
  })


  const addUserToGroup = (userId, groupId) => new Promise((resolve, reject) => {
    userApi.addUserToGroup(userId, groupId, token).then(data => {
      getUsers()
      resolve()
    }).catch(error => {
      reject(error)
    })
  })

  const removeUserFromGroup = (userId, groupId) => new Promise((resolve, reject) => {
    userApi.removeUserFromGroup(userId, groupId, token).then(data => {
      getUsers()
      resolve()
    }).catch(error => {
      reject(error)
    })
  })

  useEffect(() => {
    setUsers(users)
  }, [users])

  useEffect(() => {
    const hydrate = async () => {
      try {
        await getGroups()
        await getUsers()
        setSelectedGroup(undefined)
        setInitialLoading(false)
      }
      catch (e) {
        console.log(e)
      }
      finally {
        setInitialLoading(false)
      }
    }
    hydrate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderStore = initialLoading ?
    <LoadingOverlay visible={true} />
    :
    <UsersContext.Provider value={{
      groups,
      optionsGroup,
      selectedGroup,
      setSelectedGroup,
      users,
      getUsers,
      addUser,
      editUser,
      addUserToGroup,
      removeUserFromGroup
    }}>
      {children}
    </UsersContext.Provider>

  return renderStore
}
