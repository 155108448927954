import React, { useMemo, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { Table, LoadingOverlay, FlexView, Icon } from 'components/common'
import { LogsContext } from 'apps/admin/stores/LogsStore'

const AppsTable = () => {
  const { t } = useTranslation()
  const { logs, logControl, prev, next, setSelectedLog }= useContext(LogsContext)
  const [isLoading, setLoading] = useState(false)

  const { allowNext, nextCount } = logControl

  const onTableNavigate = handle => async () => {
    setLoading(true)
    try {
      await handle()
    }
    catch (e) {
      console.log(e)
      toast.error(e.data || t(`admin:errors.${e.message}`))
    }
    finally {
      setLoading(false)
    }
  }

  const columns = useMemo(() => {
    const selectLog = log => () => {
      console.log(123)
      setSelectedLog(log)
    }

    const getMessageProps = log => log.Message.length > 300 ? { onClick: selectLog(log), style: { cursor: 'pointer' }} : {}

    return [
      {
        Header: t('admin:logs.Date'),
        accessor: row => row.datetime.format('DD/MM/YYYY'),
        id: 'date',
        Cell: ({ cell: { value }}) => <FlexView alignItems="center" justifyContent="center">{value}</FlexView>
      },
      {
        Header: t('admin:logs.Time'),
        accessor: row => row.datetime.format('HH:mm:ss'),
        id: 'time',
        Cell: ({ cell: { value }}) => <FlexView alignItems="center" justifyContent="center">{value}</FlexView>
      },
      {
        Header: t('admin:logs.Type'),
        accessor: 'Type',
        Cell: ({ cell: { value }}) => <FlexView alignItems="center" justifyContent="center">{value}</FlexView>
      },
      {
        Header: t('admin:logs.Message'),
        accessor: 'Message',
        Cell: ({ cell: { value, row }}) => <FlexView {...getMessageProps(row.original)} width="700px">{_.truncate(value, { length: 300 })}</FlexView>
      },
    ]
  }, [t, setSelectedLog])

  return <>
    <FlexView flexDirection="row" alignItems="center" justifyContent="space-between" margin="16px 0px">
      <Icon name="arrow-left" height="24px" width="24px" margin="0px 64px" color={nextCount > 0 ? 'gray' : 'lightGray'} onClick={onTableNavigate(prev)} />
      <Icon name="arrow-right" height="24px" width="24px" margin="0px 64px" color={allowNext ? 'gray' : 'lightGray'} onClick={onTableNavigate(next)} />
    </FlexView>
    <FlexView margin="0px 16px" position="relative">
      <Table margin="0px 16px 16px" width="calc(100% - 32px)" columns={columns} data={logs} />
      <LoadingOverlay visible={isLoading} size="40px" />
    </FlexView>
  </>
}

export default AppsTable
