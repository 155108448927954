/** @format */

import React from 'react'

const TagServerPage = () => {
	return (
		<iframe
			src={`${process.env.REACT_APP_TAG_SERVER_URL}/tagserver/`}
			title='TAG_SERVER_IFRAME'
			width='100%'
			height='100%'
			sandbox='allow-scripts allow-forms allow-same-origin allow-top-navigation allow-popups allow-modals'></iframe>
	)
}
export default TagServerPage
