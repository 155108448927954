/** @format */

import { AssetsAttributesContext } from 'apps/wms/stores/AssetsAttributesStore'

import { FlexView } from 'components/common'
import { Select } from 'components/form'
import _ from 'lodash'
import React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const MandatoryAttributes = () => {
	const { t } = useTranslation()
	const { mandatoryAttr, attrSelected, setAttrSelected, mandatorySelected, setMandatorySelected } =
		useContext(AssetsAttributesContext)

	const selectAttribute = value => {
		setAttrSelected(value)
		setMandatorySelected(_.find(mandatoryAttr, opt => opt.id === value))
	}

	return (
		<FlexView width='100%' height='100%' maxHeight='100%' flexDirection='row'>
			<FlexView flexDirection='column' width='100%' height='100%' alignItems='center'>
				{mandatoryAttr && (
					<Select
						style={{ alignSelf: 'center' }}
						width='90%'
						label={t('wms:SelectAttribute')}
						placeholder={t('wms:SelectAttribute')}
						options={
							mandatoryAttr &&
							_.map(mandatoryAttr, opt => {
								return { value: opt.id, label: opt.description }
							})
						}
						value={attrSelected}
						onChange={v => selectAttribute(v)}
						data-cy='select-mandatory-attribute'
					/>
				)}
				{mandatorySelected ? (
					<FlexView flexDirection='column' width='90%' justifyContent='space-between' margin='16px 0 0 0'>
						{mandatorySelected && (
							<FlexView flexDirection='column'>
								<FlexView fontSize='tiny' fontWeight='bold' color='metalic' flexDirection='column'>
									{t('wms:Name')}
								</FlexView>
								{mandatorySelected.name}
							</FlexView>
						)}
						{mandatorySelected && (
							<FlexView flexDirection='column' margin='16px 0 0 0'>
								<FlexView fontSize='tiny' fontWeight='bold' color='metalic' flexDirection='column'>
									{t('wms:Description')}
								</FlexView>
								{mandatorySelected.description}
							</FlexView>
						)}
						{mandatorySelected && (
							<FlexView flexDirection='column' margin='16px 0 0 0'>
								<FlexView fontSize='tiny' fontWeight='bold' color='metalic' flexDirection='column'>
									{t('wms:Symbol')}
								</FlexView>
								{mandatorySelected.symbol}
							</FlexView>
						)}
					</FlexView>
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						style={{ textAlign: 'center' }}
						data-cy='no-attribute-list'>
						{t('wms:NoAttribute')}
					</FlexView>
				)}
			</FlexView>
		</FlexView>
	)
}

export default MandatoryAttributes
