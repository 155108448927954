/** @format */

import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Select } from 'components/form'
import moment from 'moment'
import React, { useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { ReportsContext } from 'apps/wms/stores/ReportStore'
import { toast } from 'react-toastify'
import DateRangePicker from 'apps/wms/components/forms/DatePicker/DateRangePicker'

const DailyReportFilter = () => {
	const { t } = useTranslation()

	const { endUsers, downloadDailyReport } = useContext(ReportsContext)

	const [loadingDailyReport, setLoadingDailyReport] = useState(false)

	const [endUser, setEndUser] = useState(null)
	const [startValue, setStartValue] = useState(null)
	const [endValue, setEndValue] = useState(null)

	const handleDailyReport = () => {
		setLoadingDailyReport(true)
		const payload = {
			enduser: endUser,
			periodtype: 'D',
			days: {
				startDate: moment(startValue).format('YYYY-MM-DD'),
				endDate: moment(endValue).format('YYYY-MM-DD')
			}
		}
		downloadDailyReport(payload)
			.then(() => {
				toast.success(t('wms:DownloadSuccessful'))
				setEndUser(null)
			})
			.catch(error => {
				console.error(error)
			})
			.finally(() => setLoadingDailyReport(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	const checkProperties = () => {
		let isDisabled = true
		isDisabled = isDisabled && (!endUser || startValue === null)

		return isDisabled
	}

	const selectEndValue = value => {
		const diffTime = Math.abs(value - startValue)
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
		const sixMonthsInDays = 180
		diffDays < sixMonthsInDays ? setEndValue(value) : value !== null && toast.warning(t('wms:TimeRangeExceeded'))
	}

	return (
		<Card
			data-cy='card-wrapper-spot-report'
			margin='0'
			justifySelf='center'
			width='100%'
			style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingDailyReport} borderRadius='16px' />
			<FlexView flexDirection='row' width='100%' justifyContent='center'>
				{endUsers && (
					<Select
						label={t('wms:EndUser')}
						placeholder={t('wms:EndUser')}
						options={_.map(endUsers, eu => {
							return { value: eu.id, label: eu.name }
						})}
						value={endUser}
						onChange={v => setEndUser(v)}
						data-cy='select-end-user'
					/>
				)}
				<DateRangePicker
					margin='8px 0 0 24px'
					startValue={startValue}
					onStartChange={v => {
						setStartValue(v)
						setEndValue(null)
					}}
					endValue={endValue}
					onEndChange={v => selectEndValue(v)}
					label={t('wms:SelectDateRange')}
					noFuture={true}
				/>
			</FlexView>
			<Button
				backgroundColor='secondary'
				color='white'
				margin='0 auto'
				fontSize='small'
				onClick={handleDailyReport}
				disabled={checkProperties()}
				data-cy='button-generate-spot-report'>
				<Icon name='csv' color='white' height='24px' width='24px' margin='0 8px 0 0' />
				{t('wms:GenerateReport')}
			</Button>
		</Card>
	)
}

export default DailyReportFilter
