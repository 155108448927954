/** @format */

import { UserContext } from 'stores/UserStore'
import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import batch from '../api/batch'
import { WMSContext } from './WmsStore'
import { toast } from 'react-toastify'
import _ from 'lodash'
import common from '../api/common'

export const PrepareEntranceContext = React.createContext()

export const PrepareEntranceProvider = ({ children }) => {
	const { t } = useTranslation()
	const { user, token } = useContext(UserContext)
	const { warehouse, getPrinters } = useContext(WMSContext)
	const [loadingMaterials, setLoadingMaterials] = useState(false)
	const [loadingErp, setLoadingErp] = useState(false)
	const [savingChanges, setSavingChanges] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [openRelocateModal, setOpenRelocateModal] = useState(false)
	const [isPrinting, setIsPrinting] = useState(false)
	const [materialList, setMaterialList] = useState([])
	const [endUsers, setEndeUsers] = useState(null)
	const [endUserSelected, setEndUserSelected] = useState(null)
	const [erpSelected, setErpSelected] = useState([])
	const [materialqty, setMaterialQty] = useState(0)
	const [newMatList, setNewMatList] = useState([])
	const [newMaterial, setNewMaterial] = useState({
		erpList: [],
		materialList: [],
		attributeFilters: [],
		erp: null,
		mid: null,
		desc: null,
		filter: false,
		quantity: '',
		pipes: [],
		salesorder: '',
		salesitem: '',
		customerorder: '',
		customeritem: '',
		consignmenttype: null,
		supplier: null
	})
	const [generatedVALIDs, setGeneratedVALIDs] = useState([])
	const [step, setStep] = useState(1)
	const [suppliers, setSuppliers] = useState(null)

	const [printersList, setPrintersList] = useState([])
	const [printerSelected, setPrinterSelected] = useState(null)

	const getEndUsers = () =>
		new Promise((resolve, reject) => {
			batch
				.getEndUsers(warehouse.id, token)
				.then(response => {
					resolve()
					setEndeUsers(response)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingEndUser')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getMaterialsByEndUser = () =>
		new Promise((resolve, reject) => {
			batch
				.getMaterials(warehouse.id, endUserSelected, token)
				.then(response => {
					resolve()
					const materials = response.map(mat => ({
						...mat,
						erpreference: mat.desc.substring(0, mat.desc.indexOf(' '))
					}))
					setMaterialList(materials)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingMatListEndUser')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const genValids = () =>
		new Promise((resolve, reject) => {
			setSavingChanges(true)
			let payload = {
				responsible: `${user.firstName} ${user.lastName}`,
				enduser: endUserSelected,
				items: _.map(newMatList, mat => ({
					mid: mat.mid,
					quantity: mat.quantity,
					customerpo: mat.customerorder,
					customeritem: mat.customeritem,
					consignmenttype: mat.consignmenttype,
					salesorder: mat.salesorder,
					salesitem: mat.salesitem,
					supplierid: mat.supplier
				}))
			}
			batch
				.prepareEntrance(warehouse.id, payload, token)
				.then(response => {
					setGeneratedVALIDs(response)
					resolve()
					toast.success(t('wms:VALIDGenerationSuccessfull'))
					setSavingChanges(false)
					setOpenRelocateModal(true)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGeneratingVALID')} [ ${e.status} ]: ${e.data}`)
					setSavingChanges(false)
				})
				.finally(() => {
					setSavingChanges(false)
				})
		})

	const resetProcess = () => {
		setEndUserSelected(null)
		setNewMaterial({
			erpList: [],
			materialList: [],
			attributeFilters: [],
			erp: null,
			mid: null,
			desc: null,
			filter: false,
			quantity: '',
			pipes: [],
			salesorder: '',
			salesitem: '',
			customerorder: '',
			customeritem: '',
			consignmenttype: null,
			supplier: null
		})
		setNewMatList([])
		setErpSelected([])
		setMaterialQty(0)
		setOpenConfirmModal(false)
		setOpenRelocateModal(false)
		setStep(1)
	}

	const getSuppliers = () =>
		new Promise((resolve, reject) => {
			batch
				.getSupplierList(warehouse.id, token)
				.then(response => {
					resolve()
					setSuppliers(response)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingSuupliers')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const _printItems = (printer, items, token) =>
		new Promise((resolve, reject) => {
			let payload = {
				printer: printer,
				items
			}
			setIsPrinting(true)
			common
				.setPrintersByItems(payload, token)
				.then(response => {
					resolve(response)
					toast.success(t('wms:Printing OK'))
					setPrinterSelected(null)
					resetProcess()
				})
				.catch(e => {
					reject(e)
					toast.error(`${t('wms:ErrorPrinting')} [${e.status}]: ${e.data}`)
					setIsPrinting(false)
					resetProcess()
				})
				.finally(() => {
					setIsPrinting(false)
				})
		})

	const printItems = useCallback(
		(printer, items) => _printItems(printer, items, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[token]
	)

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getEndUsers()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingErp(true)
				endUserSelected && (await getMaterialsByEndUser())
			} catch (e) {
				console.error(e)
				setLoadingErp(false)
			} finally {
				setLoadingErp(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endUserSelected])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getSuppliers()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		getPrinters()
			.then(list => setPrintersList(list))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	return (
		<PrepareEntranceContext.Provider
			value={{
				loadingMaterials,
				setLoadingMaterials,
				materialList,
				setMaterialList,
				endUsers,
				setEndeUsers,
				endUserSelected,
				setEndUserSelected,
				materialqty,
				setMaterialQty,
				newMatList,
				setNewMatList,
				newMaterial,
				setNewMaterial,
				erpSelected,
				setErpSelected,
				loadingErp,
				setLoadingErp,
				generatedVALIDs,
				setGeneratedVALIDs,
				genValids,
				savingChanges,
				setSavingChanges,
				openConfirmModal,
				setOpenConfirmModal,
				openRelocateModal,
				setOpenRelocateModal,
				resetProcess,
				step,
				setStep,
				suppliers,
				setSuppliers,
				printersList,
				setPrintersList,
				printerSelected,
				setPrinterSelected,
				printItems,
				isPrinting,
				setIsPrinting
			}}>
			{children}
		</PrepareEntranceContext.Provider>
	)
}
