/** @format */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Toggle } from 'components/form'
import { useTranslation } from 'react-i18next'
import { AssetsInspSettingsContext } from 'apps/wms/stores/AssetsInspSettingsStore'
import InspTypeModal from './InspTypeModal'
import { toast } from 'react-toastify'
import { useWindowSize } from 'apps/wms/utils/hooks'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import { INSPECTION_STAGES } from 'apps/wms/utils/enum'

const InspTypesList = () => {
	const { t } = useTranslation()

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const {
		stagesList,
		inspections,
		disableInspectionType,
		enableInspectionType,
		selectedInspection,
		setSelectedInspection,
		loadingConfig,
		triggerReload
	} = useContext(AssetsInspSettingsContext)
	const [inspTypeModalCtrl, setInspTypeModalCtrl] = useState(false)
	const [isLoadingResponse, setIsLoadingResponse] = useState(false)
	const [isConfirmationModalOn, setIsConfirmationModalOn] = useState(false)
	const [toDisableInspection, setToDisableInspection] = useState(null)
	const [isEditMode, setIsEditMode] = useState(false)

	const availableProps = useMemo(
		() => [
			{ name: 'WorkOrderDescription', enabled: true },
			{ name: 'FieldName', enabled: false },
			{ name: 'RigName', enabled: false },
			{ name: 'WellName', enabled: false },
			{ name: 'TpartyName', enabled: false },
			{ name: 'TpartyLocation', enabled: false },
			{ name: 'LtpaNumber', enabled: false },
			{ name: 'DeliveryLocation', enabled: false },
			{ name: 'AttachedCustomerRequest', enable: false }
		],
		[]
	)

	const onEnableInspectionType = async inspectionData => {
		try {
			const payload = {
				...inspectionData,
				config_summary: inspectionData.config_summary ? inspectionData.config_summary : [...availableProps],
				enabled: true
			}

			setIsLoadingResponse(true)
			await enableInspectionType(payload)
			setIsLoadingResponse(false)
			toast.success(t('wms:InspectionTypeEnabled'))

			triggerReload()
		} catch (error) {
			setIsLoadingResponse(false)
			console.error(error)
		}
	}

	const onDisableInspectionType = inspectionData => {
		setToDisableInspection(inspectionData)
		setIsConfirmationModalOn(true)
	}

	const disableInspection = async () => {
		try {
			setIsConfirmationModalOn(false)
			setIsLoadingResponse(true)
			await disableInspectionType(toDisableInspection.id)
			toast.success(t('wms:InspectionTypeDisabled'))
			setIsLoadingResponse(false)
			triggerReload()
		} catch (error) {
			setIsConfirmationModalOn(false)
			setIsLoadingResponse(false)
			console.error(error)
		}
	}

	return (
		<Card
			ref={listRef}
			margin='0px 8px'
			height='100%'
			width='calc(100% - 64px)'
			data-cy='types-list-card'
			style={{ overflowY: 'scroll', overflowX: 'hidden', position: 'relative' }}>
			<LoadingOverlay visible={loadingConfig || isLoadingResponse} borderRadius='card' />
			<FlexView height={`${listHeight}px`} width='100%	'>
				<FlexView width='100%' flexDirection='row' fontSize='medium' fontWeight='bold' margin='0 0 8px 0'>
					{t('wms:InspectionTypes')}
					<Button
						fontSize='tiny'
						margin='auto 0 auto auto'
						padding='8px 16px'
						color='white'
						backgroundColor='secondary'
						disabled={false}
						onClick={() => setInspTypeModalCtrl(true) || setIsEditMode(false)}>
						{`${t('wms:New')} ${t('wms:Type')}`}
					</Button>
				</FlexView>
				{stagesList ? (
					stagesList.map(stage => (
						<FlexView key={stage.id} width='100%' flexDirection='column' margin='0 0 8px 0'>
							<FlexView width='100%' fontSize='tiny'>
								{t('wms:Stage')}
							</FlexView>
							<FlexView
								width='100%'
								fontWeight='bold'
								margin='0 0 8px 0'
								style={{ borderBottom: '1px solid #E0E0E0' }}>
								{stage.name}
							</FlexView>

							{inspections &&
								inspections
									.filter(i => i.stage === stage.id)
									.map(inspection => (
										<FlexView
											backgroundColor={
												selectedInspection && selectedInspection.id === inspection.id ? 'background' : ''
											}
											key={inspection.id}
											width='100%'
											flexDirection='row'
											margin='0 0 16px 0'
											alignItems='center'
											justifyContent={
												inspection.stage === INSPECTION_STAGES.code.PERIODICAL
													? 'space-between'
													: 'flex-start'
											}
											onClick={() => setSelectedInspection(inspection)}>
											{inspection.name}

											{inspection.stage !== INSPECTION_STAGES.code.PERIODICAL && (
												<Toggle
													margin='0 0 0 auto'
													fontSize='small'
													state={inspection.enabled}
													textOff='Off'
													textOn='On'
													onChange={value =>
														value
															? onEnableInspectionType(inspection)
															: onDisableInspectionType(inspection)
													}
													data-cy='toggle-enabled-disabled-defects'
													alternate
												/>
											)}
											<Button
												fontSize='tiny'
												margin='0 0 0 16px'
												padding='8px'
												color='white'
												backgroundColor='secondary'
												disabled={false}
												onClick={() =>
													setSelectedInspection(inspection) ||
													setInspTypeModalCtrl(true) ||
													setIsEditMode(true)
												}>
												<Icon name='edit' color='white' width='16px' height='16px' margin='0' />
											</Button>
										</FlexView>
									))}
						</FlexView>
					))
				) : (
					<FlexView
						width=' 100%'
						fontWeight='bold'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						style={{ alignContent: 'center' }}
						justifyContent='center'
						data-cy='no-reports-list'>
						{t('wms:NoStagesSetup')}
					</FlexView>
				)}
			</FlexView>

			<InspTypeModal
				isOpen={inspTypeModalCtrl}
				onOutsideClick={() => setInspTypeModalCtrl(false)}
				data={selectedInspection}
				editMode={isEditMode}
				selectedStage={selectedInspection && selectedInspection.stage}
			/>
			<DialogModal
				isOpen={isConfirmationModalOn}
				text={t(`wms:ConfirmDisableInspection`)}
				onConfirm={() => disableInspection()}
				onCancel={() => setIsConfirmationModalOn(false)}
			/>
		</Card>
	)
}

export default InspTypesList
