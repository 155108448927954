/** @format */

import React, { useContext } from 'react'
import FlexView from 'components/common/FlexView'
import { Input } from 'components/form'
import { Button, Card, Icon } from 'components/common'
import { useTranslation } from 'react-i18next'
import { ContentContext } from 'apps/wms/stores/ContentStore'

const ItemFilter = () => {
	const { setSearchValid, setSearchPuuid, searchValid, searchPuuid, setSearchItems } = useContext(ContentContext)
	const { t } = useTranslation()

	const handleSearchClick = () => {
		setSearchItems(true)
	}

	return (
		<Card
			width='calc(100% - 132px)'
			flex='1'
			margin='0 0 0 16px'
			padding='0'
			style={{ position: 'relative' }}
			data-cy='card-item-filter'>
			<FlexView
				width='90%'
				flexDirection='row'
				alignItems='center'
				justifyContent='center'
				margin='16px'
				alignSelf='center'>
				<Input
					label='Valid'
					width='100%'
					margin='0 16px 0 0'
					placeholder={t('wms:TypeValid')}
					value={searchValid}
					onChange={e => setSearchValid(e.target.value)}
					data-cy='input-valid'
				/>
				<FlexView fontWeight='bold' margin='20px 0 0 0'>
					{t('wms:OR')}
				</FlexView>
				<Input
					label='IPPN'
					width='100%'
					margin='0 0 0 16px'
					placeholder={t('wms:TypeIPPN')}
					value={searchPuuid}
					onChange={e => setSearchPuuid(e.target.value)}
					data-cy='input-puuid'
				/>
			</FlexView>
			<FlexView flexDirection='row' width='100%' margin='auto 0 8px 0' justifyContent='center'>
				<Button
					fontSize='small'
					margin='0'
					backgroundColor='secondary'
					color='white'
					onClick={handleSearchClick}
					disabled={!searchValid && !searchPuuid}
					data-cy='button-search'>
					{t('wms:Search')}
					<Icon name='search' width='14px' height='14px' color='white' margin='0 0 0 8px' />
				</Button>
			</FlexView>
		</Card>
	)
}

export default ItemFilter
