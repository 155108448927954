/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getLogTypes: token =>
		new Promise((resolve, reject) => {
			instance
				.get('logs/types', {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getLogGroups: token =>
		new Promise((resolve, reject) => {
			instance
				.get('logs/groups', {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getLogs: (token, group, date, type, filter, nextToken, limit) =>
		new Promise((resolve, reject) => {
			instance
				.get('logs', {
					headers: {
						Authorization: `${token}`
					},
					params: {
						group,
						date,
						type,
						filter,
						nextToken,
						limit
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
