/** @format */

import { FlexView } from 'components/common'
import React from 'react'

// eslint-disable-next-line react/prop-types
const ProgressBar = ({ color, quantity }) => {
	const containerStyles = {
		height: '8px',
		width: '100%',
		backgroundColor: '#E0E0E0',
		borderRadius: 50
	}

	const fillerStyles = {
		height: '100%',
		width: `${quantity}%`,
		maxWidth: '100%',
		backgroundImage: color,
		borderRadius: 'inherit',
		textAlign: 'right'
	}

	return (
		<FlexView style={containerStyles}>
			<FlexView style={fillerStyles}></FlexView>
		</FlexView>
	)
}

export default ProgressBar
