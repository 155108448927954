/** @format */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Card, FlexView, Button } from 'components/common'
import { useTranslation } from 'react-i18next'
import Input from './Input'

const AlertModal = ({ isOpen, onConfirm, title, text, confirmationText, confirmText, danger }) => {
	const [confirmation, setConfirmation] = useState('')
	const { t } = useTranslation()

	const onConfirmationChange = e => {
		setConfirmation(e.target.value)
	}

	const onInputKeyPress = e => {
		e.key === 'Enter' && confirmation === confirmationText && onConfirm()
	}

	useEffect(() => {
		!isOpen && setConfirmation('')
	}, [isOpen])

	return (
		<Modal isOpen={isOpen} onOutsideClick={onConfirm}>
			<Card minWidth='300px' maxWidth='800px' style={{ lineHeight: '32px' }}>
				<FlexView
					width='100%'
					justifyContent='center'
					alignItems='center'
					fontSize='24px'
					fontWeight='bold'
					margin='0px 0px 8px'>
					{title || t('Confirm')}
				</FlexView>
				<FlexView flexDirection='column' alignSelf='center' alignItems='center'>
					{text.split('\n').map((t, index) => (
						<FlexView key={index} fontSize='20px'>
							{t}
							<br />
						</FlexView>
					))}
				</FlexView>
				{confirmationText && (
					<Input
						value={confirmation}
						onChange={onConfirmationChange}
						placeholder={confirmationText}
						onKeyPress={onInputKeyPress}
						width='100%'
						margin='8px 0px 0px'
					/>
				)}
				<FlexView flexDirection='row' alignSelf='stretch' justifyContent='center' margin='auto 0px 0px'>
					<Button
						margin='0px 0px 0px 16px'
						backgroundColor={danger ? 'error' : 'success'}
						color='white'
						disabled={confirmationText && confirmationText !== confirmation}
						onClick={onConfirm}
						style={{ borderRadius: '4px', boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,0.13)', height: '45px' }}>
						{confirmText || t('Yes')}
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

AlertModal.propTypes = {
	/**
	 * Boolean to control if the dialog is open or not
	 */
	isOpen: PropTypes.bool,
	/**
	 * Function called when the user confirms the dialog
	 */
	onConfirm: PropTypes.func,
	/**
	 * Dialog modal title
	 */
	title: PropTypes.string,
	/**
	 * Dialog modal text
	 */
	text: PropTypes.string,
	/**
	 * Confirmation text that the user must input before confirming the dialog
	 */
	confirmationText: PropTypes.string,
	/**
	 * Text that will appear in the confirm button
	 */
	confirmText: PropTypes.string,
	/**
	 * Change confirmation button color to indicate danger
	 */
	danger: PropTypes.bool
}

AlertModal.defaultProps = {
	isOpen: false
}

export default AlertModal
