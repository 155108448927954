/** @format */

import { AssetsAgeMgmtContext } from 'apps/wms/stores/AssetsAgeMgmtStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Card, FlexView } from 'components/common'
import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'utils/theme'

const ExtractInfo = () => {
	const { t } = useTranslation()
	const { selectedExtract, levels } = useContext(AssetsAgeMgmtContext)

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const showOwnership = owner => {
		let ownership = _.find(levels, o => o.id === owner)
		return owner && ownership ? ownership.label : '*****'
	}

	return (
		<Card
			width='calc(100% - 48px)'
			flex='1'
			ref={listRef}
			margin='0'
			style={{ position: 'relative' }}
			data-cy='card-extrac-info'>
			<FlexView
				flexDirection='column'
				width='100%'
				height={`${listHeight}px`}
				style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
					{t('wms:ExtractInfo')}
				</FlexView>
				<FlexView
					width='100%'
					margin='0'
					flexDirection='row'
					flexWrap='wrap'
					padding='0'
					justifyContent='space-between'>
					<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:ExtractName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedExtract.extractdesc}
						</FlexView>
					</FlexView>
					<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{`${t('wms:Period')} [${t('wms:Months')}]`}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedExtract.periodthreshold}
						</FlexView>
					</FlexView>
					<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:ConsignmentType')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedExtract &&
								_.map(selectedExtract.consignmenttypefilters, (consigment, index) => (
									<FlexView key={index} flexDirection='row' margin='0 0 4px 0' fontSize='medium' width='100%'>
										{consigment}
									</FlexView>
								))}
						</FlexView>
					</FlexView>
					<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:Ownership')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedExtract &&
								_.map(selectedExtract.ownershipfilters, (owner, index) => (
									<FlexView key={index} flexDirection='row' margin='0 0 4px 0' fontSize='medium' width='100%'>
										{showOwnership(owner)}
									</FlexView>
								))}
						</FlexView>
					</FlexView>
					<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:ProductType')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedExtract &&
								_.map(selectedExtract.producttypefilters, (product, index) => (
									<FlexView key={index} flexDirection='row' margin='0 0 4px 0' fontSize='medium' width='100%'>
										{product}
									</FlexView>
								))}
						</FlexView>
					</FlexView>
					<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:ErpReference')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{selectedExtract &&
								_.map(selectedExtract.erpreferencefilters, (erp, index) => (
									<FlexView key={index} flexDirection='row' margin='0 0 4px 0' fontSize='medium' width='100%'>
										{erp}
									</FlexView>
								))}
						</FlexView>
					</FlexView>
				</FlexView>
			</FlexView>
		</Card>
	)
}

export default ExtractInfo
