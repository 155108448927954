import React, { useContext, useState, useEffect } from 'react'
import { FlexView, Card } from 'components/common'
import LogsForm from '../containers/logs/LogsForm'
import LogsTable from '../containers/logs/LogsTable'
import { LogsContext } from '../stores/LogsStore'
import { CSSTransition } from 'react-transition-group'
import LogModal from './../containers/logs/LogModal'

const LogsPage = () => {
  const [show, setShow]= useState(false)
  const { logs } = useContext(LogsContext)

  useEffect(() => {
    logs.length && setShow(true)
  }, [logs, setShow])

  return (
    <FlexView width="100%" alignItems="stretch" position="relative">
      <Card margin="16px 16px 0px">
        <LogsForm />
      </Card>
      <CSSTransition
        in={show}
        timeout={800}
        classNames="slow-fade"
        unmountOnExit
      >
        <LogsTable />
      </CSSTransition>
      <LogModal />
    </FlexView>
  )
}

export default LogsPage