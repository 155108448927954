/** @format */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import { PerInspContext } from 'apps/wms/stores/PerInspStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import Table from '../../components/reports/PaginatedTable'
import { Card, FlexView, LoadingOverlay } from 'components/common'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'apps/wms/utils/enum'
import _ from 'lodash'

const PerInspList = ({ list = [] }) => {
	const { t, i18n } = useTranslation()
	const {
		loadingReports,
		setSelectedReport,
		selectedReport,
		canPreviousPage,
		setCanPreviousPage,
		canNextPage,
		setCanNextPage,
		totalPages,
		currentPage,
		setCurrentPage,
		setFetchingFirstPage,
		setFetchingLastPage,
		getMoreReports,
		totalItems,
		getReports,
		getSummarized
	} = useContext(PerInspContext)

	const listDeadSpace = 60
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)
	const breakSpaceStr = 'break-spaces'

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		selectedReport && selectedReport.id && handleSelect(selectedReport.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [list])

	const handleSelect = useCallback(
		id => {
			if (id) {
				let report = _.find(list, re => re.id === id)
				setSelectedReport(report)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list]
	)

	useEffect(() => {
		selectedReport && getSummarized(selectedReport.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedReport])

	const perInspColumns = useMemo(
		() => [
			{
				Header: '#',
				accessor: 'pipeqty',
				width: '20px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							padding='0'
							fontSize='12px'
							justifyContent='center'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							<FlexView
								width='100%'
								margin='0'
								padding='0'
								color={row.original.pipeqty === 0 ? theme.colors.red : theme.colors.green}
								backgroundColor={row.original.pipeqty === 0 ? theme.colors.red : theme.colors.green}>
								{value}
							</FlexView>
						</FlexView>
					)
				},
				disableFilters: true
			},
			{
				Header: 'ID',
				accessor: 'id',
				width: '30px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							fontSize='12px'
							justifyContent='center'
							alignItems='center'
							padding='2px'
							onClick={() => handleSelect(row.original.id)}>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: `${t('wms:ErpReference')}`,
				accessor: 'erpRef',
				width: '10px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							fontSize='12px'
							justifyContent='center'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: `${t('wms:CustomerOrder')}`,
				accessor: 'customerpo',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}
							fontSize='12px'
							justifyContent='center'
							alignItems='center'
							width='auto'
							onClick={() => handleSelect(row.original.id)}>
							{value ? value : '*****'}
						</FlexView>
					)
				}
			},
			{
				Header: `${t('wms:CustomerItem')}`,
				accessor: 'customeritem',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}
							fontSize='12px'
							justifyContent='center'
							alignItems='center'
							width='auto'
							onClick={() => handleSelect(row.original.id)}>
							{value ? value : '*****'}
						</FlexView>
					)
				}
			},
			{
				Header: `${t('wms:SalesOrder')}`,
				accessor: 'salesorder',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}
							justifyContent='center'
							fontSize='12px'
							alignItems='center'
							width='auto'
							onClick={() => handleSelect(row.original.id)}>
							{value ? value : '*****'}
						</FlexView>
					)
				}
			},
			{
				Header: `${t('wms:SalesItem')}`,
				accessor: 'salesitem',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}
							fontSize='12px'
							justifyContent='center'
							alignItems='center'
							width='auto'
							onClick={() => handleSelect(row.original.id)}>
							{value ? value : '*****'}
						</FlexView>
					)
				}
			},
			{
				Header: `${t('wms:EntranceDate')}`,
				accessor: 'entrancedate',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}
							fontSize='12px'
							justifyContent='center'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							{moment.locale(`${i18n.language}`) && moment(value).format('L')}
						</FlexView>
					)
				}
			},
			{
				Header: `${t('wms:NextInspection')}`,
				accessor: 'nextinspdate',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView
							style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}
							justifyContent='center'
							fontSize='12px'
							alignItems='center'
							onClick={() => handleSelect(row.original.id)}>
							{moment.locale(`${i18n.language}`) && moment(value).format('L')}
						</FlexView>
					)
				}
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleSelect, t, i18n.language]
	)

	return (
		<Card
			data-cy='card-list-wrapper'
			width='50%'
			height='90%'
			margin='0'
			ref={listRef}
			style={{ position: 'relative', overflowY: 'auto' }}>
			<LoadingOverlay visible={loadingReports} borderRadius='card' />
			{list && list.length > 0 ? (
				<Table
					width='100%'
					elevation='none'
					height={`${listHeight}px`}
					style={{ overflowY: 'auto', overflowX: 'hidden' }}
					columns={perInspColumns}
					data={list}
					sortable
					canPreviousPage={canPreviousPage}
					setCanPreviousPage={setCanPreviousPage}
					canNextPage={canNextPage}
					setCanNextPage={setCanNextPage}
					totalPages={totalPages}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					setFetchingFirstPage={setFetchingFirstPage}
					setFetchingLastPage={setFetchingLastPage}
					getMoreReports={getMoreReports}
					getReports={getReports}
					numberOfRecords={totalItems}
					loading={loadingReports}
					data-cy='table-periodic'
				/>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='no-periodic-list'>
					{t('wms:NoPeriodicInspections')}
				</FlexView>
			)}
		</Card>
	)
}

export default PerInspList
