/** @format */

import React from 'react'
import { REPORT_STATUS } from '../enum'
import ProgressBar from 'apps/wms/components/reports/ProgressBar'
import { Button } from 'components/common'
import { useTranslation } from 'react-i18next'

const ProgressColumn = ({ row, handleDownloadReport, handleCheckReport }) => {
	const { t } = useTranslation()

	const validatedStatus = row.original.status === REPORT_STATUS.code.VALIDATED
	const pendingOrRejectedStatus =
		row.original.status === REPORT_STATUS.code.PENDING || row.original.status === REPORT_STATUS.code.REJECTED

	return row.depth === 0 ? (
		<ProgressBar
			color={
				row.original.quantity >= row.original.maxqty
					? 'linear-gradient(90deg, #1F8600 0%, #34A06E 100%)'
					: 'linear-gradient(90deg, #F47408 0%, #FFB134 100%)'
			}
			quantity={(row.original.quantity / row.original.maxqty) * 100}
		/>
	) : validatedStatus ? (
		<Button
			width='100%'
			fontSize='tiny'
			margin='4px 0 4px 0'
			color='white'
			backgroundColor='primary'
			disabled={row.original.status !== REPORT_STATUS.code.VALIDATED}
			elevation='none'
			onClick={() => {
				handleDownloadReport(row.original.id, row.original.part)
			}}
			data-cy='button-download-report'>
			{t('wms:DownloadReport')}
		</Button>
	) : (
		pendingOrRejectedStatus && (
			<Button
				width='100%'
				fontSize='tiny'
				margin='4px 0 4px 0'
				color='white'
				backgroundColor='secondary'
				onClick={() => {
					handleCheckReport(row.original.id, row.original.part)
				}}
				data-cy='button-check-report'>
				{t('wms:CheckReport')}
			</Button>
		)
	)
}

export { ProgressColumn }
