/** @format */

import React from 'react'

import { FlexView, LoadingOverlay } from 'components/common'
import Subheader from './Subheader'

// eslint-disable-next-line react/prop-types
const PageTemplate = ({ children, loadingOverlay, title, overflow = 'inherit', crumbs, height = 'auto' }) => {
	return (
		<FlexView
			style={{ overflow: overflow }}
			width='100%'
			height={height}
			maxWidth='100%'
			flex='1'
			alignItems='center'
			margin='0'
			padding='0'
			backgroundColor='#F8F8F9'
			position='relative'>
			<LoadingOverlay visible={loadingOverlay} />
			<FlexView
				width='calc(100% - 32px)'
				fontSize='40px'
				fontWeight='bold'
				margin='0'
				padding='0 16px'
				justifyContent='space-between'
				flexDirection='row'
				alignItems='center'>
				{title ? title : null}
				<Subheader crumbs={crumbs} data-cy='subheader' />
			</FlexView>
			{children}
		</FlexView>
	)
}

export default PageTemplate
