/** @format */

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import { ContentContext } from 'apps/wms/stores/ContentStore'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Table from '../../components/content/Table'
import PipeData from '../common/PipeData'
import { RadioGroup } from 'components/form'
import { WMSContext } from 'apps/wms/stores/WmsStore'
import { listStatusTranslateKey } from 'apps/wms/utils/enum'

const LevelContentModal = ({ isOpen, onOutsideClick, setIsOpen, rack, items, loadingItems }) => {
	const {
		setPipe,
		setAllPrinters,
		printers,
		setExportExcel,
		setPrinterSelected,
		openPipeData,
		setOpenPipeData,
		downloading,
		printItems,
		setSearchValid
	} = useContext(ContentContext)
	const { lengthUnit, weightUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit } = useContext(WMSContext)
	const { t } = useTranslation()
	const [isOpenPrint, setIsOpenPrint] = useState(false)
	const [value, setValue] = useState(null)
	const [selectedItems, setSelectedItems] = useState([])
	const [loadingPrint, setLoadingPrint] = useState(false)
	const breakSpaceStr = 'break-spaces'

	const handleChange = e => {
		setValue(e)
		setPrinterSelected(e)
	}

	const handlePrint = () => {
		setSelectedItems([...data.map(item => ({ ...item.children[0], status: item.children[0].laststatus }))])
		setIsOpenPrint(true)
		setAllPrinters(true)
	}

	const handleSinglePrint = item => {
		setSelectedItems([{ ...item.children[0], status: item.children[0].laststatus }])
		setIsOpenPrint(true)
		setAllPrinters(true)
	}

	const handleExport = () => {
		setExportExcel(true)
	}

	const closeModal = () => {
		setIsOpen(false)
		setIsOpenPrint(false)
	}

	const handleDetails = pipe => {
		setSearchValid(pipe.valid)
		setPipe(pipe)
		setOpenPipeData(true)
	}

	const handlePrintItems = async () => {
		try {
			setLoadingPrint(true)
			await printItems(selectedItems, value)
			setLoadingPrint(false)
			setIsOpenPrint(false)
			setSelectedItems([])
		} catch (error) {
			setLoadingPrint(false)
			console.error(error)
		}
	}

	const data = useMemo(
		() =>
			_.chain(items)
				.groupBy('valid')
				.map((value, key) => ({
					valid: key,
					puuid: value[0].puuid,
					weightkg: value[0].weightkg,
					lengthmm: value[0].lengthmm,
					formatted: value[0].material.formatted,
					ownership: value[0].ownershipname,
					fullname: value[0].level.fullname,
					laststatus: value[0].laststatus,
					laststatusfeature: value[0].laststatusfeature,
					laststatuslist: value[0].laststatuslist,
					children: value || []
				}))
				.value(),
		[items]
	)

	const handleFontSize = (status, value) => {
		if (status === 'UnderInspection' && value === null) return 'tiny'
		else return 'small'
	}

	const handleFontWeight = (status, value) => {
		if (status === 'UnderInspection' && value === null) return 'bold'
		else return 'normal'
	}

	const columns = useMemo(
		() => [
			{
				Header: (
					<FlexView fontSize='small' fontWeight='bold' alignItems='center'>
						{t('wms:Print')}
					</FlexView>
				),
				accessor: 'print',
				width: 'auto',
				Cell: ({ cell: { row } }) => (
					<FlexView alignItems='center' justifyContent='center' width='30%' margin='0 auto'>
						<Icon
							name='print'
							margin='0px'
							onClick={() => handleSinglePrint(row.original)}
							width='16px'
							height='16px'
						/>
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='small' fontWeight='bold' alignItems='center'>
						{'Valid'}
					</FlexView>
				),
				accessor: 'valid',
				width: 'auto',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						width='100%'
						margin='0'
						fontSize='small'
						style={{ alignSelf: 'center', textAlign: 'center' }}>
						{value}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='small' fontWeight='bold' alignItems='center'>
						IPPN
					</FlexView>
				),
				accessor: 'puuid',
				width: '110px',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' width='110px' margin='0px' fontSize='small'>
						{value}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='small' fontWeight='bold' alignItems='center'>
						{t('wms:Weight')}
					</FlexView>
				),
				accessor: 'weightkg',
				width: '70px',
				Cell: ({ cell: { value } }) => (
					<FlexView width='100%' alignItems='center' justifyContent='center' fontSize='small'>
						{convertToCurrentWeightUnit(value, weightUnit)} {weightUnit}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='small' fontWeight='bold' alignItems='center'>
						{t('wms:Length')}
					</FlexView>
				),
				width: '80px',
				accessor: 'lengthmm',
				Cell: ({ cell: { value } }) => (
					<FlexView alignItems='center' justifyContent='center' fontSize='small' width='80px'>
						{convertToCurrentLengthUnit(value, lengthUnit)} {lengthUnit}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='small' fontWeight='bold' alignItems='center'>
						{t('wms:Material')}
					</FlexView>
				),
				accessor: 'formatted',
				width: '300px',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0px'
						fontSize='small'
						style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}>
						{value}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='small' fontWeight='bold' alignItems='center'>
						{t('wms:Ownership')}
					</FlexView>
				),
				accessor: 'ownership',
				width: '80px',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0px'
						fontSize='small'
						style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}>
						{value}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='small' fontWeight='bold' alignItems='center'>
						{'Status'}
					</FlexView>
				),
				accessor: 'laststatus',
				width: '70px',
				Cell: ({ cell: { value } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0px'
						fontSize='small'
						style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}>
						{t(listStatusTranslateKey(value))}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='small' fontWeight='bold' alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:InspectionType')}
					</FlexView>
				),
				accessor: 'laststatusfeature',
				width: '90px',
				Cell: ({ cell: { value, row } }) => (
					<FlexView
						width='120px'
						alignItems='center'
						justifyContent='center'
						margin='0px'
						fontSize={handleFontSize(row.original.status, value)}
						fontWeight={handleFontWeight(row.original.status, value)}
						style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}>
						{value || t('wms:OnlyForFutureInspections')}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='small' fontWeight='bold' alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:InspectionTypeID')}
					</FlexView>
				),
				accessor: 'laststatuslist',
				width: '120px',
				Cell: ({ cell: { value, row } }) => (
					<FlexView
						alignItems='center'
						justifyContent='center'
						margin='0px'
						fontSize={handleFontSize(row.original.status, value)}
						fontWeight={handleFontWeight(row.original.status, value)}
						style={{ whiteSpace: breakSpaceStr, textAlign: 'center' }}>
						{value || t('wms:OnlyForFutureInspections')}
					</FlexView>
				)
			},
			{
				Header: (
					<FlexView fontSize='small' fontWeight='bold' alignItems='center' style={{ textAlign: 'center' }}>
						{t('wms:Details')}
					</FlexView>
				),
				accessor: 'details',
				width: '25px',
				Cell: ({ cell: { row } }) => (
					<Button
						fontSize='tiny'
						height='20px'
						width='50px'
						padding='4px 8px'
						margin='0px 0px 0px 4px'
						backgroundColor='secondary'
						color='white'
						onClick={() => handleDetails(row.original)}>
						{t('wms:Details')}
					</Button>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, lengthUnit, weightUnit]
	)

	return (
		items &&
		items.length > 0 && (
			<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
				<LoadingOverlay visible={false} />
				<Card height='auto' max-height='750px' width='1360px' style={{ alignSelf: 'normal' }} data-cy='card-modal'>
					<LoadingOverlay visible={loadingItems || loadingPrint} />
					{openPipeData ? (
						<PipeData setOpenPipeData={setOpenPipeData} />
					) : isOpenPrint ? (
						<FlexView flexDirection='column'>
							<FlexView fontSize='24px'>{t('wms:SelectPrinter')}</FlexView>
							<RadioGroup
								name='printers'
								options={printers || []}
								value={value}
								onChange={evt => handleChange(evt)}
							/>
							<FlexView flexDirection='row' justifyContent='space-around'>
								<Button margin='0px 8px 0px 0px' onClick={closeModal}>
									{t('wms:Cancel')}
								</Button>
								<Button backgroundColor='secondary' color='white' margin='0px' onClick={handlePrintItems}>
									{t('wms:OK')}
								</Button>
							</FlexView>
						</FlexView>
					) : (
						<FlexView width='100%' flexDirection='column' justifyContent='space-between' height='100%'>
							<FlexView
								flexDirection='row'
								fontSize='36px'
								width='100%'
								justifyContent='space-between'
								margin='0 0 16px 0'>
								{rack && (
									<FlexView width='100%' fontSize='title'>
										{`Level: ${rack.fullname}`}
									</FlexView>
								)}
								<Button
									backgroundColor='white'
									elevation='high'
									margin='0px 16px 16px auto'
									onClick={handleExport}
									data-cy='button-export-excel'>
									{t('wms:ExportExcel')}
									<LoadingOverlay visible={downloading} />
									<Icon name='download' width='18px' height='18px' margin='0px 0px 0px 8px'></Icon>
								</Button>
								<Button
									backgroundColor='secondary'
									color='white'
									margin='0px 0px 16px 0px'
									onClick={handlePrint}
									data-cy='button-print-all'>
									<Icon name='print' color='white' width='18px' height='18px' margin='0px 8px 0px 0px' />
									{t('wms:PrintAll').toUpperCase()}
								</Button>
								<Button
									fontSize='tiny'
									margin='0px 0px 16px 16px'
									backgroundColor='error'
									disabled={false}
									onClick={closeModal}
									data-cy='button-close-modal'>
									<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
								</Button>
							</FlexView>
							<Table
								margin='4px 4px 0 4px '
								width='calc(100% - 8px)'
								height='520px'
								columns={columns}
								data={data}
								paginated
								sortable
								data-cy='table-level-content-modal'></Table>
						</FlexView>
					)}
				</Card>
			</Modal>
		)
	)
}

export default LevelContentModal
