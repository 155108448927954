/** @format */

import { PerInspContext } from 'apps/wms/stores/PerInspStore'
import { Button, Card, FlexView, LoadingOverlay, Modal } from 'components/common'
import { Input } from 'components/form'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'stores/UserStore'

// eslint-disable-next-line react/prop-types
const ManagePartsModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { user } = useContext(UserContext)
	const { parts, setParts, selectedReport, loadingNewPart, setNewQuantity, selectedInspection, updateParts } =
		useContext(PerInspContext)

	const handleChange = e => {
		setParts(e.target.value)
	}

	useEffect(() => {
		selectedReport &&
			setParts(
				selectedReport.contents.filter(c => {
					return c.status !== 0
				}).length
			)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedReport])

	const handleClick = () => {
		let params = {
			stage: selectedInspection.stage,
			number: parts,
			responsible: user.firstName,
			contents: selectedReport.contents.map(content => ({
				content_id: content.id,
				status: content.status,
				nt_quantity: content.nt_quantity,
				device: content.device
			}))
		}
		setNewQuantity(params, selectedReport.type, selectedReport.id)
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card data-cy='card-manage-parts-modal'>
				<FlexView
					width='-webkit-fill-available'
					backgroundColor='info'
					fontSize='title'
					fontWeight='bold'
					color='white'
					margin='-16px -24px 8px'
					padding='8px'
					borderRadius='8px 8px 0 0'>
					{t('wms:ManageParts')}
				</FlexView>
				<FlexView width='100%' flexDirection='column' justifyContent='space-around'>
					<Input
						label={`${t('wms:Part')}s`}
						placeholder={t('wms:TypeQuantityParts')}
						message={parts < 0 ? t('wms:PositiveNumberAllowed') : null}
						messageType={parts < 0 ? 'error' : 'default'}
						width='100%'
						type='number'
						min={
							selectedReport &&
							selectedReport.contents.filter(c => {
								return c.status !== 0
							}).length
						}
						max='99'
						value={parts}
						onChange={e => handleChange(e)}
						data-cy='input-perinsp-parts'
					/>
					<FlexView width='100%' flexDirection='row' justifyContent='space-between'>
						<Button backgroundColor='error' color='white' onClick={onOutsideClick} data-cy='button-cancel-parts'>
							{t('wms:Cancel')}
						</Button>
						<Button
							disabled={parts <= 0}
							backgroundColor='success'
							color='white'
							onClick={handleClick}
							loading={`${loadingNewPart}`}
							data-cy='button-confirm-parts'>
							{t('wms:OK')}
						</Button>
						<LoadingOverlay visible={updateParts} />
					</FlexView>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default ManagePartsModal
