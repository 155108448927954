/** @format */

import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { INSPECTION_STAGES, STATUS } from 'apps/wms/utils/enum'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import Button from 'apps/wms/components/commom/Button'
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import Pagination from 'apps/wms/components/reports/Pagination'
import { useContext } from 'react'
import { PrepareInspectionContext } from 'apps/wms/stores/PrepareInspectionStore'

const InspectionReportsList = () => {
	const {
		reports,
		inspName,
		selectedReport,
		loadingReports,
		selectedInsp,
		deleteInspList,
		setOpenUpdateModal,
		deleteWarning,
		setDeleteWarning,
		currentPage,
		setCurrentPage,
		totalPages,
		setFetchingFirstPage,
		setFetchingLastPage,
		getMoreReports,
		canPreviousPage,
		setCanPreviousPage,
		canNextPage,
		setCanNextPage,
		setSelectedReport
	} = useContext(PrepareInspectionContext)
	const { t, i18n } = useTranslation()

	const listDeadSpace = 140
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		currentPage === 1 ? setCanPreviousPage(false) : setCanPreviousPage(true)
		currentPage === totalPages ? setCanNextPage(false) : setCanNextPage(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage])

	const handleSelect = id => {
		let selected = id && _.find(reports, item => item.id === id)
		selected && selected.contents.sort((a, b) => a.id - b.id)
		setSelectedReport(selected)
	}

	const handleUpdate = id => {
		if (id != null) {
			handleSelect(id)
			setOpenUpdateModal(true)
		}
	}

	const handleDelete = id => {
		if (id != null) {
			handleSelect(id)
			setDeleteWarning(true)
		}
	}

	const canShowUpdateButton = stage => {
		return (
			stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION ||
			stage === INSPECTION_STAGES.code.ARRIVAL ||
			stage === INSPECTION_STAGES.code.RETURN
		)
	}

	return (
		<Card width='calc(100% - 48px)' flex='1' ref={listRef} margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingReports} borderRadius='card' />
			{reports && reports.length ? (
				<FlexView flexDirection='row' width='100%'>
					<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
						{`${t('wms:ListOf')} ${inspName}`}
					</FlexView>
				</FlexView>
			) : (
				''
			)}
			<FlexView
				width='100%'
				height={`${listHeight}px`}
				style={{ overflowY: 'auto', overflowX: 'hidden' }}
				margin='16px 0 0 0'>
				{reports && reports.length ? (
					_.map(reports, (re, index) => (
						<FlexView
							key={re.id}
							backgroundColor={selectedReport && selectedReport.id === re.id ? 'offWhite' : 'default'}
							width='100%'
							margin='0'
							flexDirection='row'
							padding='8px 0'
							style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}
							onClick={() => handleSelect(re.id)}>
							<FlexView flex='1' margin='auto 0'>
								<FlexView
									margin='0'
									style={{ textTransform: 'uppercase' }}
									fontWeight='bold'
									color='secondary'
									fontSize='tiny'>
									{selectedInsp && selectedInsp.name}
								</FlexView>
								<FlexView fontSize='small' fontWeight='bold' margin='4px 0'>
									{`${re.id}  - ${
										re.summary && re.summary.customer_request_reference
											? re.summary.customer_request_reference
											: re.summary.work_order_description
									} `}
								</FlexView>
								<FlexView flexDirection='row' fontSize='tiny'>
									<Icon name='clock' width='10px' height='10px' margin='2px 4px 0px 0px' />
									{`${DATE_FORMAT(re.expected_date, i18n.language)}`}
								</FlexView>
							</FlexView>
							{canShowUpdateButton(selectedInsp.stage) && (
								<Button
									fontSize='tiny'
									margin='auto 0'
									color='secondary'
									backgroundColor='white'
									hoverColor='darkGray'
									onClick={() => {
										handleUpdate(re.id)
									}}>
									<Icon name='refresh' color='secondary' height='12px' width='12px' margin='0 8px 0 0' />
									{t('wms:Update')}
								</Button>
							)}
							<Button
								fontSize='tiny'
								margin='auto 0 auto 8px'
								color='white'
								backgroundColor='error'
								disabled={!!re.contents.find(content => content.execution_status !== STATUS.code.NOTSTARTED)}
								onClick={() => {
									handleDelete(re.id)
								}}>
								<Icon name='trash' color='white' height='12px' width='12px' margin='0 8px 0 0' />
								{t('wms:Delete')}
							</Button>
						</FlexView>
					))
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'>
						{t('wms:NoAvailableInspections')}
					</FlexView>
				)}
			</FlexView>
			{totalPages > 1 && (
				<Pagination
					canPreviousPage={canPreviousPage}
					setCanPreviousPage={setCanPreviousPage}
					canNextPage={canNextPage}
					setCanNextPage={setCanNextPage}
					totalPages={totalPages}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					setFetchingFirstPage={setFetchingFirstPage}
					setFetchingLastPage={setFetchingLastPage}
					getMoreReports={getMoreReports}
				/>
			)}
			<DialogModal
				isOpen={deleteWarning}
				text={t('wms:ConfirmInspectionDeletion')}
				onConfirm={deleteInspList}
				onCancel={() => setDeleteWarning(false)}
			/>
		</Card>
	)
}

export default InspectionReportsList
