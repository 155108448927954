/** @format */

import { AssetsReportRefContext } from 'apps/wms/stores/AssetsReportRefStore'
import { Button, Card, FlexView, Icon } from 'components/common'
import LoadingOverlay from 'components/common/LoadingOverlay'
import Modal from 'components/common/Modal'
import { Input } from 'components/form'
import React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line react/prop-types
const UpdateRefModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { reference, setReference, update, setUpdate } = useContext(AssetsReportRefContext)

	const saveReference = () => {
		setUpdate(true)
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card data-cy='card-update-reference-report'>
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 8px 0'
					justifyContent='space-between'>
					{t('wms:UpdateReferenceReport')}
					<Button
						fontSize='tiny'
						margin='0 0 0 16px'
						backgroundColor='error'
						disabled={false}
						onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<Input
					width='100%'
					margin='16px 0 16px 0'
					type='text'
					label={t('wms:NewReference')}
					placeholder={t('wms:NewReference')}
					value={reference}
					onChange={e => setReference(e.target.value)}
					data-cy='input-new-reference'
				/>
				<Button
					backgroundColor='success'
					color='white'
					margin='auto 0 0 auto'
					onClick={() => saveReference()}
					disabled={!reference}
					data-cy='button-save-reference'>
					{t('wms:Save')}
					<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				</Button>
				<LoadingOverlay visible={update} borderRadius='card' />
			</Card>
		</Modal>
	)
}

export default UpdateRefModal
