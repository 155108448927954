/** @format */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import { useWindowSize } from 'apps/wms/utils/hooks'
import { Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import React, { useEffect, useRef, useState, useMemo, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Button from 'apps/wms/components/commom/Button'
import { AssetsEquipmentsContext } from 'apps/wms/stores/AssetsEquipmentsStore'
import Table from 'apps/wms/components/content/Table'
import moment from 'moment'
import Toggle from 'apps/wms/components/commom/Toggle'

const InstrumentList = () => {
	const {
		setIsModalOpen,
		setIsUpdate,
		equipmentList,
		loadingEquipments,
		selectedEquipment,
		setSelectedEquipment,
		disabledEquipList
	} = useContext(AssetsEquipmentsContext)
	const { t } = useTranslation()

	const [active, setActive] = useState(true)

	const currentDate = new Date()

	const listDeadSpace = 80
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		selectedEquipment && selectedEquipment.id && handleSelect(selectedEquipment.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [equipmentList, disabledEquipList])

	useEffect(() => {
		setSelectedEquipment(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active])

	const handleSelect = id => {
		active
			? id && setSelectedEquipment(_.find(equipmentList, equip => equip.id === id))
			: id && setSelectedEquipment(_.find(disabledEquipList, equip => equip.id === id))
	}

	const handleUpdate = useCallback(id => {
		if (id !== null) {
			setIsUpdate(true)
			setIsModalOpen(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleNewEquipment = () => {
		setIsUpdate(false)
		setIsModalOpen(true)
	}

	const activeEquipData = _.map(equipmentList, equip => ({
		...equip,
		props: {
			'data-cy': 'list-equipment',
			onClick: () => {
				handleSelect(equip.id)
			},
			className: selectedEquipment !== null && equip && equip.id === selectedEquipment.id ? 'selected' : ''
		}
	}))

	const inactiveEquipData = _.map(disabledEquipList, equip => ({
		...equip,
		props: {
			'data-cy': 'list-disabled-equipment',
			onClick: () => {
				handleSelect(equip.id)
			},
			className: selectedEquipment !== null && equip && equip.id === selectedEquipment.id ? 'selected' : ''
		}
	}))

	const equipmentColumns = useMemo(
		() => [
			{
				Header: (
					<FlexView fontWeight='bold' fontSize='tiny' alignItems='center' justifyContent='center'>
						ID
					</FlexView>
				),
				accessor: 'id',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView flex='1' margin='auto 0'>
							<FlexView
								margin='0'
								fontSize='small'
								width='100%'
								alignItems='center'
								color={moment(row.original.nextcalib).isBefore(moment(currentDate)) ? 'red' : 'inherit'}>
								{value}
							</FlexView>
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView fontWeight='bold' fontSize='tiny' alignItems='center' justifyContent='center'>
						{t('wms:Type')}
					</FlexView>
				),
				accessor: 'type',
				width: '120px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView flex='1' margin='auto 0'>
							<FlexView
								width='100%'
								alignItems='center'
								margin='0'
								fontSize='small'
								color={moment(row.original.nextcalib).isBefore(moment(currentDate)) ? 'red' : 'inherit'}>
								{value}
							</FlexView>
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView fontWeight='bold' fontSize='tiny' alignItems='center' justifyContent='center'>
						{t('wms:Reference')}
					</FlexView>
				),
				accessor: 'reference',
				width: 'auto',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView flex='1' margin='auto 0'>
							<FlexView
								width='100%'
								alignItems='center'
								margin='0'
								fontSize='small'
								color={moment(row.original.nextcalib).isBefore(moment(currentDate)) ? 'red' : 'inherit'}>
								{value}
							</FlexView>
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView fontWeight='bold' fontSize='tiny' alignItems='center' justifyContent='center'>
						{t('wms:CalibrationDate')}
					</FlexView>
				),
				accessor: 'calibdate',
				width: '110px',
				Cell: ({ cell: { value, row } }) => {
					return (
						<FlexView flex='1' margin='auto 0'>
							<FlexView
								width='100%'
								margin='0'
								alignItems='center'
								fontSize='small'
								color={moment(row.original.nextcalib).isBefore(moment(currentDate)) ? 'red' : 'inherit'}>
								{moment(value).format('L')}
							</FlexView>
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView fontWeight='bold' fontSize='tiny' alignItems='center' justifyContent='center'>
						{t('wms:NextCalibrationDate')}
					</FlexView>
				),
				accessor: 'nextcalib',
				width: '110px',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView flex='1' margin='auto 0'>
							<FlexView
								width='100%'
								margin='0'
								alignItems='center'
								fontSize='small'
								color={moment(value).isBefore(moment(currentDate)) ? 'red' : 'inherit'}>
								{moment(value).format('L')}
							</FlexView>
						</FlexView>
					)
				}
			},
			{
				Header: (
					<FlexView fontWeight='bold' fontSize='tiny' alignItems='center' justifyContent='center'>
						{t('wms:Action')}
					</FlexView>
				),
				accessor: 'action',
				width: '100px',
				Cell: ({ cell: { row } }) => {
					return (
						<Button
							fontSize='tiny'
							margin='auto 0'
							color='secondary'
							backgroundColor='white'
							hoverBackgroundColor='secondary'
							hoverColor='white'
							disabled={false}
							onClick={() => handleUpdate(row.original.id)}>
							<Icon name='refresh' color='secondary' height='12px' width='12px' margin='0 8px 0 0' />
							{t('wms:Update')}
						</Button>
					)
				}
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleUpdate, t]
	)

	return (
		<Card
			width='calc(100% - 48px)'
			ref={listRef}
			flex='1'
			margin='0'
			style={{ position: 'relative' }}
			data-cy='card-equipment-list'>
			<LoadingOverlay visible={loadingEquipments} borderRadius='card' />
			<FlexView flexDirection='row' width='100%' justifyContent='space-between' alignItems='center'>
				<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
					{t('wms:EquipmentList')}
				</FlexView>
				<FlexView width='100%' justifyContent='flex-end' alignItems='flex-end'>
					<Toggle
						state={active}
						textOff={t('wms:Inactive')}
						textOn={t('wms:Active')}
						onChange={value => setActive(value)}
						alternate
						data-cy='toggle-active-inactive-equipments'
					/>
				</FlexView>
			</FlexView>
			{equipmentList && equipmentList.length ? (
				<Table
					margin='0'
					width='100%'
					maxWidth='100%'
					textAlign='center'
					elevation='none'
					fontSize='14px'
					height={`${listHeight}px`}
					columns={equipmentColumns}
					data={active ? activeEquipData : inactiveEquipData}
					sortable
					gridless
				/>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'>
					{t('wms:NoEquipmentList')}
				</FlexView>
			)}
			<Button
				fontSize='small'
				margin='auto 0 0 auto'
				color='white'
				backgroundColor='secondary'
				disabled={false}
				onClick={handleNewEquipment}
				data-cy='button-add-equipment'>
				{t('wms:Add')}
				<Icon name='add' color='white' width='12px' height='12px' margin='0 0 0 8px' />
			</Button>
		</Card>
	)
}

export default InstrumentList
