/** @format */

import { AssetsReportRefContext } from 'apps/wms/stores/AssetsReportRefStore'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import { Select } from 'components/form'
import React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'utils/theme'

// eslint-disable-next-line react/prop-types
const UnitSystemModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const {
		reportLengthUnit,
		reportWeightUnit,
		reportDiameterUnit,
		setReportLengthUnit,
		setReportWeightUnit,
		setReportDiameterUnit,
		saveReportUnits,
		savingUnits
	} = useContext(AssetsReportRefContext)

	const availableLengthUnits = [
		{
			value: 'Mm',
			label: `${t('wms:Milimeter')}`
		},
		{
			value: 'Ft',
			label: `${t('wms:feet')}`
		}
	]

	const availableDiameterUnits = [
		{
			value: 'In',
			label: `${t('wms:Inch')}`
		},
		{
			value: 'Mm',
			label: `${t('wms:Milimeter')}`
		}
	]

	const availableWeightUnits = [
		{
			value: 'Kg',
			label: `${t('wms:kilogram')}`
		},
		{
			value: 'Lb',
			label: `${t('wms:pound')}`
		}
	]

	const saveWarehouseUnits = () => {
		saveReportUnits()
			.then(response => response)
			.catch(e => console.error(e))
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick} width='100%' height='100%'>
			<Card width='20%' height='35%'>
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 8px 0'
					justifyContent='space-between'>
					{t('wms:ManageUnitSystem')}
					<Button
						fontSize='tiny'
						margin='0 0 0 16px'
						backgroundColor='error'
						disabled={false}
						onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flexDirection='column' alignItems='center'>
					<Select
						inline
						width='60%'
						label={t('wms:Length')}
						options={availableLengthUnits}
						value={reportLengthUnit}
						onChange={value => setReportLengthUnit(value)}
					/>
					<Select
						inline
						width='60%'
						label='OD / ID'
						options={availableDiameterUnits}
						value={reportDiameterUnit}
						onChange={value => setReportDiameterUnit(value)}
					/>
					<Select
						inline
						width='60%'
						label={t('wms:Weight')}
						options={availableWeightUnits}
						value={reportWeightUnit}
						onChange={value => setReportWeightUnit(value)}
					/>
				</FlexView>
				<FlexView width='100%' margin='auto 0 0 0' flexDirection='row' justifyContent='space-between'>
					<FlexView
						width='100%'
						fontSize='small'
						fontWeight='bold'
						color={theme.colors.warning}
						alignItems='center'
						flexDirection='row'>
						{`${t('wms:AffectDashboard')}!`}
					</FlexView>
					<Button
						backgroundColor='success'
						color='white'
						margin='auto 0 0 0'
						onClick={() => saveWarehouseUnits()}
						disabled={!reportLengthUnit || !reportWeightUnit || !reportDiameterUnit}
						data-cy='button-upload-image'>
						{t('wms:Save')}
						<Icon name='save' color='white' width='16px' height='16px' margin='0 0 0 8px' />
					</Button>
					<LoadingOverlay visible={savingUnits} borderRadius='card' />
				</FlexView>
			</Card>
		</Modal>
	)
}

export default UnitSystemModal
