/** @format */

import { FlexView } from 'components/common'
import React, { useMemo, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Icon } from '../../../components/common'
import ReportsInfo from '../containers/assets/refReports/ReportsInfo'
import ReportsList from '../containers/assets/refReports/ReportsList'
import UploadLogoModal from '../containers/assets/refReports/UploadLogoModal'
import InspectionReportsInfo from '../containers/assets/refReports/InspectionReportsInfo'
import UpdateInspectionRefModal from '../containers/assets/refReports/UpdateInspectionRefModal'
import PageTemplate from '../containers/common/PageTemplate'
import { AssetsReportRefContext } from '../stores/AssetsReportRefStore'
import { assets, genParams } from '../utils/hubs'
import UnitSystemModal from '../containers/assets/refReports/UnitSystemModal'

const AssetsReportRefPage = () => {
	const { t } = useTranslation()
	const { selectedReport, selectedInspectionReport, openUnitSystem, setOpenUnitSystem } =
		useContext(AssetsReportRefContext)
	const [isOpen, setIsOpen] = useState(false)

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:ReportsManagement'),
				path: '/wms/assets/reportsmanagement'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' height='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='55%' margin='0 0 24px 0' flexDirection='row' alignItems='center' width='55%'>
					<FlexView justifyContent='space-between' fontSize='40px' fontWeight='bold' width='100%'>
						{t('wms:ReportsManagement')}
					</FlexView>
					<Button
						backgroundColor='secondary'
						color='white'
						fontSize='tiny'
						title={t('wms:ManageUnitSystem')}
						margin='auto 8px auto 0'
						onClick={() => setOpenUnitSystem(true)}
						elevation='none'>
						{t('wms:ManageUnitSystem')}
						<Icon name='fix' width='16px' height='16px' margin='0 0 0 8px' color='white' />
					</Button>
					<Button
						backgroundColor='secondary'
						color='white'
						fontSize='tiny'
						title={t('wms:ManageLogoSubtitle')}
						margin='auto 0 auto 0'
						onClick={() => setIsOpen(true)}
						elevation='none'>
						{t('wms:ManageLogo')}
						<Icon name='add-image' width='16px' height='16px' margin='0 0 0 8px' color='white' />
					</Button>
				</FlexView>

				<FlexView width='100%' flex='1' flexDirection='row' justifyContent='space-between'>
					<FlexView width='55%' height='100%'>
						<ReportsList />
					</FlexView>
					<FlexView width='calc(45% - 56px)'>
						{selectedReport ? <ReportsInfo /> : selectedInspectionReport && <InspectionReportsInfo />}
					</FlexView>
				</FlexView>
			</FlexView>
			<UnitSystemModal isOpen={openUnitSystem} onOutsideClick={() => setOpenUnitSystem(false)} />
			<UploadLogoModal
				isOpen={isOpen}
				title={t('wms:ManageLogo')}
				subtitle={t('wms:ManageLogoSubtitle')}
				onOutsideClick={() => setIsOpen(false)}
			/>
			<UpdateInspectionRefModal />
		</PageTemplate>
	)
}

export default AssetsReportRefPage
