/** @format */

import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'

import threadCompound from '../api/assetsThreadCompound'

export const ThreadCompoundContext = React.createContext()

export const ThreadCompoundProvider = ({ children }) => {
	const { warehouse } = useContext(WMSContext)
	const { token } = useContext(UserContext)
	const { t } = useTranslation()
	const [typesList, setTypesList] = useState([])
	const [methodsList, setMethodsList] = useState([])
	const [loadingConfig, setLoadingConfig] = useState(false)
	const [isTypeModalOpen, setIsTypeModalOpen] = useState(false)
	const [isMethodModalOpen, setIsMethodModalOpen] = useState(false)

	const getTypesList = (wid, token) =>
		new Promise((resolve, reject) => {
			threadCompound
				.getTypesList(wid, token)
				.then(list =>
					list.length
						? resolve(
								list
									.map(d => ({ ...d, originalSortIndex: d.sortindex }))
									.sort((a, b) => a.sortindex - b.sortindex)
						  )
						: resolve([])
				)
				.catch(e => {
					toast.error(`${t('wms:ErrorGetting')} ${t('wms:ThreadCompoundTypes')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	const getMethodsList = (wid, token) =>
		new Promise((resolve, reject) => {
			threadCompound
				.getMethodsList(wid, token)
				.then(list =>
					list.length
						? resolve(
								list
									.map(d => ({ ...d, originalSortIndex: d.sortindex }))
									.sort((a, b) => a.sortindex - b.sortindex)
						  )
						: resolve([])
				)
				.catch(e => {
					toast.error(`${t('wms:ErrorGetting')} ${t('wms:ApplicationMethods')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	const getThreadCompoundConfig = (wid, token) =>
		new Promise((resolve, reject) => {
			setLoadingConfig(true)
			Promise.allSettled([getTypesList(wid, token), getMethodsList(wid, token)])
				.then(listArray => {
					const types = listArray[0].status === 'fulfilled' && listArray[0].value.length ? listArray[0].value : []
					const methods =
						listArray[1].status === 'fulfilled' && listArray[1].value.length ? listArray[1].value : []
					resolve({ types, methods })
				})
				.catch(e => reject(e))
				.finally(() => setLoadingConfig(false))
		})

	const newType = useCallback(
		data => {
			return _newType(data, warehouse.id, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _newType = (data, wid, token) =>
		new Promise((resolve, reject) => {
			setLoadingConfig(true)
			const payload = {
				typedesc: data.typedesc,
				state: data.state,
				sortindex: data.sortindex
			}
			threadCompound
				.newType(payload, wid, token)
				.then(response => {
					toast.success(t(`wms:ThreadCompoundTypeCreated`))
					resolve(response)
				})
				.catch(e => {
					toast.error(t(`${t('wms:NewThreadCompoundTypeError')} [ ${e.status} ]: ${e.data}`))
					reject(e)
				})
				.finally(() => setLoadingConfig(false))
		})

	const newMethod = useCallback(
		data => {
			return _newMethod(data, warehouse.id, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _newMethod = (data, wid, token) =>
		new Promise((resolve, reject) => {
			setLoadingConfig(true)
			const payload = {
				methoddesc: data.methoddesc,
				sortindex: data.sortindex
			}
			threadCompound
				.newMethod(payload, wid, token)
				.then(response => {
					toast.success(t(`wms:ApplicationMethodCreated`))
					resolve(response)
				})
				.catch(e => {
					toast.error(t(`${t('wms:NewThreadCompoundMethodError')} [ ${e.status} ]: ${e.data}`))
					reject(e)
				})
				.finally(() => setLoadingConfig(false))
		})

	const deleteType = useCallback(
		id => {
			return _deleteType(id, warehouse.id, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _deleteType = (id, wid, token) =>
		new Promise((resolve, reject) => {
			setLoadingConfig(true)

			threadCompound
				.deleteType(id, wid, token)
				.then(response => {
					toast.success(t(`wms:ThreadCompoundTypeRemoved`))
					resolve(response)
				})
				.catch(e => reject(e))
				.finally(() => setLoadingConfig(false))
		})

	const deleteMethod = useCallback(
		id => {
			return _deleteMethod(id, warehouse.id, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _deleteMethod = (id, wid, token) =>
		new Promise((resolve, reject) => {
			setLoadingConfig(true)

			threadCompound
				.deleteMethod(id, wid, token)
				.then(response => {
					toast.success(t(`wms:ApplicationMethodRemoved`))
					resolve(response)
				})
				.catch(e => reject(e))
				.finally(() => setLoadingConfig(false))
		})

	const updateTypesList = useCallback(
		data => {
			return _updateTypesList(data, warehouse.id, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _updateTypesList = (data, wid, token) =>
		new Promise((resolve, reject) => {
			setLoadingConfig(true)

			const payload = data.map(type => ({
				typeid: type.typeid,
				state: type.state,
				sortindex: type.sortindex
			}))
			threadCompound
				.updateTypesList(payload, wid, token)
				.then(response => {
					toast.success(t(`wms:ThreadCompoundTypeOrderUpdate`))
					setTypesList(data)
					resolve(response)
				})
				.catch(e => {
					toast.error(t(`${e.data}`))
					reject(e)
				})
				.finally(() => setLoadingConfig(false))
		})

	const updateMethodsList = useCallback(
		data => {
			return _updateMethodsList(data, warehouse.id, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _updateMethodsList = (data, wid, token) =>
		new Promise((resolve, reject) => {
			const payload = data.map(method => ({
				methodid: method.methodid,
				sortindex: method.sortindex
			}))
			threadCompound
				.updateMethodsList(payload, wid, token)
				.then(response => {
					toast.success(t(`wms:ApplicationMethodOrderUpdate`))
					setMethodsList(data)
					resolve(response)
				})
				.catch(e => {
					toast.error(t(`${e.data}`))
					reject(e)
				})
		})

	const removeItemFromList = (list, itemId) => {
		const index = list.findIndex(
			item => (Object.hasOwnProperty.call(item, 'state') ? item.typeid : item.methodid) === itemId
		)
		let newList = [...list]
		newList.splice(index, 1)
		return newList
	}

	const reorderList = list => {
		let newList = [...list]
		newList = newList.map((item, i) => ({
			...item,
			sortindex: item.sortindex !== i + 1 ? i + 1 : item.sortindex
		}))
		return newList
	}

	const moveCardHandler = (dragIndex, hoverIndex, items, setFn) => {
		const dragItem = items[dragIndex]

		if (dragItem) {
			setFn(prevState => {
				const coppiedStateArray = [...prevState]
				const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem)
				coppiedStateArray.splice(dragIndex, 1, prevItem[0])
				return coppiedStateArray
			})
		}
	}

	useEffect(() => {
		warehouse.id &&
			getThreadCompoundConfig(warehouse.id, token)
				.then(({ types, methods }) => {
					setTypesList(types)
					setMethodsList(methods)
				})
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, token])

	return (
		<ThreadCompoundContext.Provider
			value={{
				typesList,
				setTypesList,
				methodsList,
				setMethodsList,
				loadingConfig,
				newType,
				newMethod,
				deleteType,
				deleteMethod,
				updateTypesList,
				updateMethodsList,
				reorderList,
				removeItemFromList,
				isTypeModalOpen,
				setIsTypeModalOpen,
				isMethodModalOpen,
				setIsMethodModalOpen,
				moveCardHandler
			}}>
			{children}
		</ThreadCompoundContext.Provider>
	)
}
