/** @format */

import { FlexView } from 'components/common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PageTemplate from '../containers/common/PageTemplate'
import AvailableItems from '../containers/pipedata/AvailableItems'
import SourceParts from '../containers/pipedata/SourceParts'
import TargetMaterial from '../containers/pipedata/TargetMaterial'

const NominalDataModifPage = () => {
	const { t } = useTranslation()

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:NominalDataModification'),
				path: '/wms/pipedata/nominaldatamodif'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' height='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:NominalDataModification')}
				</FlexView>
				<FlexView
					flexDirection='row'
					width='100%'
					height='100%'
					justifyContent='space-between'
					alignItems='stretch'>
					<SourceParts />
					<AvailableItems />
					<TargetMaterial />
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default NominalDataModifPage
