/** @format */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import { useTable, useSortBy, useExpanded } from 'react-table'
import FlexView from '../../../../components/common/FlexView'
import styled from 'styled-components'
import { Input, Select } from 'components/form'
import { Link, LoadingOverlay } from 'components/common'
import { useTranslation } from 'react-i18next'
import TableWrapper from '../content/TableWrapper'

const Table = styled(TableWrapper)`
	flex-direction: column;

	th {
		white-space: nowrap;
	}
	td {
		white-space: nowrap;
	}
	min-width: 0px;

	th input {
		font-size: ${({ theme }) => theme.fontSizes.small};
		padding: 4px;
		min-height: 0px;
	}
`

const FilterableTable = ({
	columns,
	data,
	hasFooter,
	sortable,
	paginated,
	borderRadius,
	height,
	setCanPreviousPage,
	canPreviousPage,
	canNextPage,
	setCanNextPage,
	totalPages,
	currentPage,
	setCurrentPage,
	getMoreReports,
	getReports,
	numberOfRecords,
	loading,
	...rest
}) => {
	const { t } = useTranslation()
	const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, page, prepareRow } = useTable(
		{
			columns,
			data: data,
			getSubRows: row => row.children || [],
			paginateExpandedRows: false,
			autoResetExpanded: false,
			autoResetPage: false,
			autoResetSortBy: false,
			disableSortBy: !sortable
		},
		useSortBy,
		useExpanded
	)

	const [pageSize, setPageSize] = useState(50)

	const gotoPage = value => {
		setCurrentPage(value)
		getMoreReports(value, pageSize)
	}

	const previousPage = () => {
		setCurrentPage(currentPage - 1)
		getMoreReports(currentPage - 1, pageSize)
	}

	const nextPage = () => {
		setCurrentPage(currentPage + 1)
		getMoreReports(currentPage + 1, pageSize)
	}

	const handleChange = value => {
		getReports(value)
	}

	useEffect(() => {
		currentPage === 1 ? setCanPreviousPage(false) : setCanPreviousPage(true)
		currentPage === totalPages ? setCanNextPage(false) : setCanNextPage(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, totalPages])

	// Render the UI for your table
	return (
		<FlexView
			flexDirection='column'
			height='100%'
			borderRadius='8px'
			width='100%'
			style={{ overflowY: 'auto', overflowX: 'auto' }}>
			<Table borderRadius={borderRadius} {...rest}>
				<div className='table-wrap' style={height ? { height: `calc(${height} - 60px)`, overflowY: 'auto' } : {}}>
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map((headerGroup, index) => (
								<tr key={index} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column, index) =>
										// Add the sorting props to control sorting. For this example
										// we can add them into the header props
										sortable ? (
											<th
												key={index}
												{...column.getHeaderProps(column.getSortByToggleProps())}
												className='sortable'>
												<div {...column.getSortByToggleProps({ title: '' })} style={{ fontSize: 'small' }}>
													{column.render('Header')}
													{/* Add a sort direction indicator */}
													<span style={{ display: 'flex', width: '20px' }}>
														{column.isSorted ? (
															column.isSortedDesc ? (
																<svg
																	width='13'
																	height='7'
																	viewBox='0 0 13 7'
																	fill='none'
																	xmlns='http://www.w3.org/2000/svg'
																	transform='rotate(180)'>
																	<path d='M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z' fill='#777777' />
																</svg>
															) : (
																<svg
																	width='13'
																	height='7'
																	viewBox='0 0 13 7'
																	fill='none'
																	xmlns='http://www.w3.org/2000/svg'>
																	<path d='M0 6.5L6.5 6.5L13 6.5L6.5 0L0 6.5Z' fill='#777777' />
																</svg>
															)
														) : (
															''
														)}
													</span>
												</div>
												<div>{column.canFilter ? column.render('Filter') : null}</div>
											</th>
										) : (
											<th key={index} {...column.getHeaderProps()} width={column.width}>
												{column.render('Header')}
											</th>
										)
									)}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{paginated
								? page.map((row, index) => {
										prepareRow(row)
										return (
											<tr key={index} {...row.getRowProps()} {...row.original.props}>
												{row.cells.map((cell, index) => {
													return (
														<td key={index} {...cell.getCellProps()}>
															{cell.render('Cell')}
														</td>
													)
												})}
											</tr>
										)
								  })
								: rows.map((row, i) => {
										prepareRow(row)
										return (
											<tr key={i} {...row.getRowProps()} {...row.original.props}>
												{row.cells.map((cell, i) => {
													return (
														<td key={i} {...cell.getCellProps()} style={{ padding: '0', width: '20px' }}>
															{cell.render('Cell')}
														</td>
													)
												})}
											</tr>
										)
								  })}
						</tbody>
						{hasFooter && (
							<tfoot>
								{footerGroups.map((group, index) => (
									<tr key={index} {...group.getFooterGroupProps()}>
										{group.headers.map((column, index) => (
											<td key={index} {...column.getFooterProps()}>
												{column.render('Footer')}
											</td>
										))}
									</tr>
								))}
							</tfoot>
						)}
					</table>
				</div>
				{numberOfRecords > 10 && (
					<div className='pagination'>
						<FlexView flexDirection='row' justifyContent='flex-start' alignItems='center'>
							<Input
								inline
								type='number'
								label={t('Go to page')}
								fontSize='small'
								margin='0px'
								padding='8px 4px'
								defaultValue={currentPage}
								onChange={e => {
									const page = e.target.value ? Number(e.target.value) : 1
									gotoPage(page)
								}}
								width='180px'
							/>
						</FlexView>
						<FlexView flex='1' flexDirection='row' alignItems='center' justifyContent='center' width='100%'>
							<Link
								noDecoration
								color='gray'
								fontSize='big'
								fontWeight='bold'
								padding='4px'
								margin='0px 4px'
								onClick={() => gotoPage(1)}
								disabled={!canPreviousPage}>
								{'«'}
							</Link>{' '}
							<LoadingOverlay visible={loading} />
							<Link
								noDecoration
								color='gray'
								fontSize='big'
								fontWeight='bold'
								padding='4px'
								margin='0px 4px'
								onClick={() => previousPage()}
								disabled={!canPreviousPage}>
								{'‹'}
							</Link>{' '}
							<LoadingOverlay visible={loading} />
							<FlexView flexDirection='row' margin='0px 8px' fontWeight='bold'>
								{currentPage} {t('of')} {totalPages}
							</FlexView>
							<Link
								noDecoration
								color='gray'
								fontSize='big'
								fontWeight='bold'
								padding='4px'
								margin='0px 4px'
								onClick={() => nextPage()}
								disabled={!canNextPage}>
								{'›'}
							</Link>{' '}
							<LoadingOverlay visible={loading} />
							<Link
								noDecoration
								color='gray'
								fontSize='big'
								fontWeight='bold'
								padding='4px'
								margin='0px 4px'
								onClick={() => gotoPage(totalPages)}
								disabled={!canNextPage}>
								{'»'}
							</Link>{' '}
							<LoadingOverlay visible={loading} />
						</FlexView>
						<FlexView flexDirection='row' justifyContent='flex-end' alignItems='center'>
							<Select
								margin='0px'
								value={pageSize}
								options={[5, 10, 20, 30, 50].map(pageSize => ({
									value: pageSize,
									label: `${t('Show')} ${pageSize}`
								}))}
								onChange={value => {
									handleChange(value)
									setPageSize(Number(value))
								}}
							/>
						</FlexView>
						<LoadingOverlay visible={loading} />
					</div>
				)}
			</Table>
		</FlexView>
	)
}

export default FilterableTable
