import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from 'stores/UserStore'
import logsApi from '../api/logs'
import { LoadingOverlay } from 'components/common'

const initialState = {
  services: [],
  logs: [],
  logControl: {
    settings: {
      service: '',
      startDate: null,
      endDate: null,
      filterType: 'all',
      filter: ''
    },
    allowNext: false,
    nextCount: 0
  },
  selectedLog: null
}

export const LogsContext = React.createContext(initialState)

export const LogsProvider = ({ children }) => {
  const [services, setServices] = useState(initialState.services)
  const [logs, setLogs] = useState(initialState.logs)
  const [selectedLog, setSelectedLog] = useState(initialState.selectedLog)
  const [logControl, setLogControl] = useState(initialState.logControl)
  const [initialLoading, setInitialLoading] = useState(true)
  const { token } = useContext(UserContext)

  const getServices = () => new Promise((resolve, reject) => {
    logsApi.getServices(token).then(data => {
      setServices(data)
      resolve()
    }).catch(error => {
      reject(error)
    })
  })

  const getLogs = (service, startDate, endDate, filterType, filter) => new Promise((resolve, reject) => {
    logsApi.getLogs(service, startDate, endDate, filterType, filter, token).then(({ logs, allowNext }) => {
      setLogs(logs)
      setLogControl({
        settings: {
          service,
          startDate,
          endDate,
          filterType,
          filter
        },
        allowNext,
        nextCount: 0
      })
      resolve()
    }).catch(error => {
      reject(error)
    })
  })

  const next = () => new Promise((resolve, reject) => {
    const { service, startDate, filterType, filter } = logControl.settings
    logControl.allowNext ? logsApi.next(service, startDate, filterType, filter, logs[0].Id, token).then(({ logs, allowNext }) => {
      setLogs(logs)
      setLogControl(currentState => ({
        ...currentState,
        allowNext,
        nextCount: currentState.nextCount + 1
      }))
      resolve()
    }).catch(error => {
      reject(error)
    }) : resolve()
  })

  const prev = () => new Promise((resolve, reject) => {
    const { service, endDate, filterType, filter } = logControl.settings
    logControl.nextCount > 0 ? logsApi.prev(service, endDate, filterType, filter, logs[0].Id, token).then(({ logs, allowNext }) => {
      setLogs(logs)
      setLogControl(currentState => ({
        ...currentState,
        allowNext,
        nextCount: currentState.nextCount - 1
      }))
      resolve()
    }).catch(error => {
      reject(error)
    }) : resolve()
  })


  useEffect(() => {
    getServices().catch(e => {
      console.log(e)
    }).finally(() => {
      setInitialLoading(false)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderStore = initialLoading ?
  <LoadingOverlay visible={true} />
  :
  <LogsContext.Provider value={{
    services,
    logs,
    logControl,
    selectedLog,
    setSelectedLog,
    getServices,
    getLogs,
    next,
    prev
  }}>
    {children}
  </LogsContext.Provider>

  return renderStore
}