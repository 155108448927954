/** @format */

import { AssetsInspSettingsContext } from 'apps/wms/stores/AssetsInspSettingsStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import { Input, Select } from 'components/form'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from 'apps/wms/components/forms/Checkbox'

const ItemsByInsp = () => {
	const { t } = useTranslation()
	const {
		inspections,
		selectedInsp,
		setSelectedInsp,
		selectedItems,
		setSelectedItems,
		items,
		loadingItems,
		saveInspSettings,
		filteredItems,
		setFilteredItems
	} = useContext(AssetsInspSettingsContext)

	const [savingConfig, setSavingConfig] = useState(false)
	const [isCheckAll, setIsCheckAll] = useState(false)
	const [element, setElement] = useState('')

	const listDeadSpace = 350
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	const selectInspection = value => {
		setSelectedInsp(value)
		let arrayItems = []
		let inspection = _.find(inspections, insp => insp.id === value)
		_.forEach(inspection.elements, el => {
			let item = _.find(items, it => it.id === el)
			item && arrayItems.push(item)
		})
		setSelectedItems(_.map(arrayItems, arr => arr.id))
		_.forEach(filteredItems, it => {
			selectItems(it, it.elements)
		})
	}

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	useEffect(() => {
		items && setFilteredItems(items)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items])

	const saveConfig = () => {
		setSavingConfig(true)
		saveInspSettings()
			.then(() => {
				setSelectedInsp(null)
				setSelectedItems([])
			})
			.catch(error => {
				console.error(error)
			})
			.finally(() => {
				setSavingConfig(false)
			})
	}

	const checkFields = () => {
		let isDisabled = true
		isDisabled = isDisabled && (!selectedInsp || selectedItems.length === 0)
		return isDisabled
	}

	const selectAllElements = v => {
		setIsCheckAll(v)
		if (v) {
			setSelectedItems(_.map(filteredItems, def => def.id))
		} else {
			setSelectedItems([])
		}
	}

	const selectItems = (value, selected) => {
		return _.indexOf(selected, value.id) > -1
	}

	const handleSelect = (value, selected) => {
		let i = selected.indexOf(value.id)
		i > -1 ? selected.splice(i, 1) : selected.push(value.id)
		setSelectedItems([...selectedItems])
	}

	return (
		<Card
			width='calc(100% - 48px)'
			ref={listRef}
			style={{ position: 'relative' }}
			height='100%'
			data-cy='card-inpection-settings'>
			<LoadingOverlay visible={loadingItems} borderRadius='card' />
			{inspections && inspections.length > 0 && items ? (
				<>
					<Select
						width='40%'
						label={t('wms:Inspections')}
						placeholder={t('wms:Inspections')}
						options={_.map(
							inspections.filter(i => i.enabled),
							insp => {
								return { value: insp.id, label: insp.name }
							}
						)}
						value={selectedInsp}
						onChange={v => selectInspection(v)}
						data-cy='select-inspections'
					/>
					{items && items.length > 0 && (
						<>
							<FlexView width='100%' fontSize='medium' fontWeight='bold'>
								{t('wms:Items')}
							</FlexView>
							<Input
								disabled={!selectedInsp}
								width='64%'
								type='text'
								margin='8px 0 8px 0'
								placeholder={t('wms:TypeToFilter')}
								value={element}
								onChange={e => {
									const filtered = items.filter(it => {
										return it.name.toUpperCase().includes(e.target.value.toUpperCase())
									})
									setFilteredItems(filtered)
									setElement(e.target.value)
								}}
								data-cy='input-filter-item'
							/>
							<Checkbox
								disabled={!selectedInsp}
								width='50%'
								fontSize='small'
								margin='8px 0 16px 0'
								id='selectAll'
								label={t('wms:SelectAll')}
								onChange={v => selectAllElements(v)}
								checked={isCheckAll}
								data-cy='checkbox-select-all'
							/>
							<FlexView
								flexDirection='row'
								flexWrap='wrap'
								width='100%'
								maxHeight={`${listHeight}px`}
								style={{ overflowY: 'auto', overflowX: 'hidden' }}>
								{filteredItems &&
									_.map(filteredItems, (filtered, index) => (
										<Checkbox
											disabled={!selectedInsp}
											width='45%'
											fontSize='small'
											key={index}
											checked={selectItems(filtered, selectedItems)}
											onChange={() => handleSelect(filtered, selectedItems)}
											label={filtered.name}
											data-cy='checkbox-available-items'
										/>
									))}
							</FlexView>
						</>
					)}
				</>
			) : (
				<FlexView
					width='100%'
					height='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='no-inspection-list'>
					{t('wms:NoInspections')}
				</FlexView>
			)}
			<Button
				margin='auto 0 0 auto'
				backgroundColor='success'
				color='white'
				onClick={() => saveConfig()}
				disabled={checkFields()}
				data-cy='button-save-config'>
				{t('wms:Save')}
				<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				<LoadingOverlay visible={savingConfig} borderRadius='card' />
			</Button>
		</Card>
	)
}

export default ItemsByInsp
