/** @format */

import { FlexView } from 'components/common'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import MaterialData from '../containers/batch/prepareEntrance/MaterialData'
import MaterialInfo from '../containers/batch/prepareEntrance/MaterialInfo'
import MaterialSelection from '../containers/batch/prepareEntrance/MaterialSelection'
import PageTemplate from '../containers/common/PageTemplate'
import { PrepareEntranceContext } from '../stores/PrepareEntranceStore'
import { entrance, genParams } from '../utils/hubs'

const PrepareEntrancePage = () => {
	const { t } = useTranslation()
	const { step } = useContext(PrepareEntranceContext)

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Entrance'),
				path: `/wms/entrance${genParams(entrance)}`
			},
			{
				name: t('wms:PrepareEntrance'),
				path: '/wms/entrance/inspection/recinsp'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='calc(100% - 320px)' height='100%' flex='1' margin='0 0 40px 8px'>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 0 20px'>
					{t('wms:PrepareEntrance')}
				</FlexView>
				{step === 1 && <MaterialSelection />}
				{step === 2 && <MaterialInfo />}
				{step === 3 && <MaterialData />}
			</FlexView>
		</PageTemplate>
	)
}

export default PrepareEntrancePage
