/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import _ from 'lodash'
import { DatePicker, Input, Select } from 'components/form'
import { Button, FlexView, LoadingOverlay } from 'components/common'
import { UserContext } from 'stores/UserStore'
import cloudLogs from '../../api/cloudLogs'
import { CloudLogsContext } from '../../stores/CloudLogsStore'

const FormWrapper = styled.form`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	& > *:not(:last-child) {
		margin-right: 24px;
	}

	& > button {
		align-self: flex-end;
		margin: 0 0 8px;
		min-width: 100px;
		min-height: 44px;
	}

	@media (max-width: 940px) {
		flex-direction: column;

		& > button {
			align-self: flex-start;
		}

		& > *:not(:first-child):not(:last-child) {
			margin-left: 0;
			margin-right: 0;
		}
	}
`

const CloudLogsForm = () => {
	const { t, i18n } = useTranslation()
	const { token } = useContext(UserContext)
	const { formData, changeFormData, isFormValid, fetchLogs, allType, setAllType, fetchingLogs, setCurrentPage } =
		useContext(CloudLogsContext)

	const [loading, setLoading] = useState(true)
	const [types, setTypes] = useState([])
	const [groups, setGroups] = useState([])

	const onSubmit = e => {
		e.preventDefault()

		if (!isFormValid()) return

		fetchLogs()
		setCurrentPage(1)
	}

	useEffect(
		() => {
			Promise.all([cloudLogs.getLogTypes(token), cloudLogs.getLogGroups(token)])
				.then(([typesList, groupsList]) => {
					typesList.push(allType)
					setTypes(typesList)
					setGroups(groupsList)
				})
				.catch(() => toast.error(t(`wms:ErrorGettingCloudLogsParams`)))
				.finally(() => setLoading(false))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	useEffect(() => {
		const newAllValue = i18n.language === 'en' ? 'All' : 'Todos'
		const newTypes = [...types]
		const prevAllValue = newTypes.pop()

		newTypes.push(newAllValue)

		setTypes(newTypes)
		setAllType(newAllValue)

		if (formData.type === prevAllValue) changeFormData('type', newAllValue)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language])

	return (
		<>
			<LoadingOverlay visible={loading} borderRadius='card' />
			{!loading &&
				(groups.length && types.length ? (
					<FormWrapper onSubmit={onSubmit}>
						<Select
							margin='0'
							width='240px'
							name='log-group'
							label={t('wms:Service')}
							value={formData.group}
							onChange={changeFormData.bind(this, 'group')}
							options={_.map(groups, g => ({ value: g, label: _.capitalize(g) }))}
						/>
						<DatePicker
							margin='0'
							name='log-date'
							label={t('wms:Date')}
							value={formData.date}
							onChange={changeFormData.bind(this, 'date')}
						/>
						<Select
							margin='0'
							width='240px'
							name='log-type'
							label={t('wms:Type')}
							value={formData.type}
							onChange={changeFormData.bind(this, 'type')}
							options={_.map(types, t => ({ value: t, label: t }))}
						/>
						<Input
							margin='0'
							width='200px'
							name='log-filter'
							label={t('wms:FilterBy')}
							value={formData.filter}
							onChange={changeFormData.bind(this, 'filter')}
						/>
						<Button
							margin='0'
							type='submit'
							backgroundColor='primary'
							color='white'
							disabled={!isFormValid() || fetchingLogs}
							isLoading={fetchingLogs}>
							{t('wms:Search')}
						</Button>
					</FormWrapper>
				) : (
					<FlexView
						alignSelf='stretch'
						margin='40px'
						justifyContent='center'
						alignItems='center'
						color='gray'
						fontSize='big'>
						{t('wms:NoServicesFound')}
					</FlexView>
				))}
		</>
	)
}

export default CloudLogsForm
