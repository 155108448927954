/** @format */

import { PerInspContext } from 'apps/wms/stores/PerInspStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ManagePartsModal from './ManagePartsModal'
import _ from 'lodash'
import { STATUS } from 'apps/wms/utils/enum'
import theme from 'utils/theme'
import { UserContext } from 'stores/UserStore'

const PerInspInfo = () => {
	const { t, i18n } = useTranslation()
	const {
		openManageParts,
		setOpenManageParts,
		selectedReport,
		summarized,
		loadingInfo,
		handleReopen,
		rackList,
		uploadPerList
	} = useContext(PerInspContext)
	const { user } = useContext(UserContext)
	const applicationID = process.env.REACT_APP_ID

	const allowReopen = true

	const listDeadSpace =
		selectedReport &&
		selectedReport.filters &&
		selectedReport.filters.customer_order &&
		selectedReport.filters.customer_item &&
		selectedReport.filters.sales_order &&
		selectedReport.filters.sales_item
			? 490
			: 430
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window, listDeadSpace])

	const openModal = () => {
		setOpenManageParts(true)
	}

	const getRackName = id => {
		let rackName = _.find(rackList, rack => rack.id === id).fullname
		return rackName ? rackName : '*****'
	}

	return (
		<Card
			height='90%'
			width='45%'
			margin='0 0 0 8px'
			ref={listRef}
			style={{ position: 'relative' }}
			data-cy='card-perinsp-info'>
			<LoadingOverlay visible={loadingInfo} borderRadius='card' />
			{selectedReport && (
				<FlexView flexDirection='column' width='100%' justifyContent='space-between' height='100%'>
					<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
						{t('wms:PeriodicInspectionInfo')}
					</FlexView>
					<FlexView
						width='100%'
						margin='0'
						flexDirection='row'
						flexWrap='wrap'
						pading='0'
						justifyContent='space-between'>
						{selectedReport && selectedReport.customerpo && (
							<FlexView width='45%' margin='0 8px 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
									{t('wms:CustomerOrder')}
								</FlexView>
								<FlexView fontSize='small' data-cy='list-label'>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{selectedReport.customerpo}
									</FlexView>
								</FlexView>
							</FlexView>
						)}
						{selectedReport.customeritem && (
							<FlexView width='45%' margin='0 8px 16px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
									{t('wms:CustomerItem')}
								</FlexView>
								<FlexView fontSize='small' data-cy='list-label'>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{selectedReport.customeritem}
									</FlexView>
								</FlexView>
							</FlexView>
						)}
						{selectedReport.salesorder && (
							<FlexView width='45%' margin='0 8px 20px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
									{t('wms:SalesOrder')}
								</FlexView>
								<FlexView fontSize='small' data-cy='list-label'>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{selectedReport.salesorder}
									</FlexView>
								</FlexView>
							</FlexView>
						)}
						{selectedReport.salesitem && (
							<FlexView width='45%' margin='0 8px 20px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
									{t('wms:SalesItem')}
								</FlexView>
								<FlexView fontSize='small' data-cy='list-label'>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{selectedReport.salesitem}
									</FlexView>
								</FlexView>
							</FlexView>
						)}
						{selectedReport && selectedReport.material && (
							<FlexView width='100%' margin='0 0 20px 0' padding='0' flexDirection='column'>
								<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
									{t('wms:Material')}
								</FlexView>
								<FlexView fontSize='small' data-cy='list-label'>
									<FlexView
										width='100%'
										style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
										padding='0 0 0 8px'>
										{selectedReport.material}
									</FlexView>
								</FlexView>
							</FlexView>
						)}
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:EntranceDate')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedReport.entrancedate
										? moment.locale(`${i18n.language}`) && moment(selectedReport.entrancedate).format('L')
										: '*****'}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:LastInspection')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedReport.lastdate
										? moment.locale(`${i18n.language}`) && moment(selectedReport.lastdate).format('L')
										: '*****'}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:NextInspection')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedReport.nextdate
										? moment.locale(`${i18n.language}`) && moment(selectedReport.nextdate).format('L')
										: '*****'}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:InitialQuantity')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{summarized.initial_qty}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{t('wms:CurrentQuantity')}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{summarized.to_date_qty}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 20px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
								{`${t('wms:Part')}s`}
							</FlexView>
							<FlexView fontSize='small' data-cy='list-label'>
								<FlexView
									width='100%'
									style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
									padding='0 0 0 8px'>
									{selectedReport.contents.length}
								</FlexView>
							</FlexView>
						</FlexView>
						<FlexView
							width='100%'
							margin='0'
							padding='0'
							height={`${listHeight}px`}
							flexDirection='column'
							style={{ overflowY: 'scroll', overflowX: 'auto' }}>
							<FlexView flexDirection='row' width='100%' margin='0 0 8px 0'>
								<FlexView flexDirection='column' width='33%' justifyContent='space-between' margin='0 0 8px 0'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
										{`${t('wms:Rack')}`}
									</FlexView>
									{summarized &&
										_.map(summarized.items, rack => (
											<FlexView
												key={rack.level_id}
												fontSize='small'
												width='100%'
												margin='0 8px 8px 0'
												data-cy='list-label'>
												<FlexView
													width='100%'
													style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
													padding='0 0 0 8px'>
													{getRackName(rack.level_id)}
												</FlexView>
											</FlexView>
										))}
								</FlexView>
								<FlexView flexDirection='column' width='33%' justifyContent='space-between' margin='0 0 8px 0'>
									<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0 0 4px 0'>
										{`${t('wms:QuantityByRack')}`}
									</FlexView>
									{summarized &&
										_.map(summarized.items, rack => (
											<FlexView
												key={rack.level_id}
												fontSize='small'
												margin='0 8px 8px 0'
												data-cy='list-label'>
												<FlexView
													width='100%'
													style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
													padding='0 0 0 8px'>
													{rack.qty}
												</FlexView>
											</FlexView>
										))}
								</FlexView>
							</FlexView>
							<FlexView width='100%' margin=' 8px 0 0 0'>
								{selectedReport &&
									selectedReport.contents &&
									selectedReport.contents.map((content, index) => (
										<FlexView
											key={index}
											width='100%'
											margin='0'
											flexDirection='row'
											padding='8px 0'
											style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
											<FlexView width='33%' fontSize='small' margin='auto 0' flexDirection='row'>
												<strong>{`${t('wms:Part')} ${index + 1}`}</strong>
											</FlexView>
											<FlexView width='33%' fontSize='small' margin='auto 0' flexDirection='row'>
												{`${t('wms:Quantity')}: ${content.quantity}`}
											</FlexView>
											<FlexView
												width='33%'
												margin='0'
												padding='0'
												flexDirection='row'
												justifyContent='center'
												alignItems='center'
												flexWrap='wrap'>
												<FlexView
													fontSize='tiny'
													color={STATUS.color[content.execution_status]}
													style={{
														borderLeft: `2px solid ${
															theme.colors[STATUS.color[content.execution_status]]
														}`,
														lineHeight: '24px'
													}}
													margin='0 0 0 auto'
													padding='8px 16px'>
													{t(`wms:${STATUS.literal[content.execution_status]}`)}
												</FlexView>
												{allowReopen &&
													content.execution_status === STATUS.code.RUNNING &&
													Object.keys(user.roles[applicationID]).find(r => r === 'ADMIN') && (
														<Button
															fontSize='tiny'
															margin='0 0 0 auto'
															color='white'
															backgroundColor='secondary'
															disabled={false}
															onClick={() => {
																handleReopen(content)
															}}>
															{t('wms:Reopen')}
														</Button>
													)}
												<LoadingOverlay visible={uploadPerList} />
											</FlexView>
										</FlexView>
									))}
							</FlexView>
						</FlexView>
					</FlexView>
					<FlexView width='100%' margin='auto 0 0 auto' alignItems='flex-end'>
						<Button
							margin='0'
							backgroundColor='#667EEA'
							color='white'
							fontSize='18px'
							onClick={openModal}
							data-cy='button-open-modal'>
							{t('wms:ManageParts')}
							<Icon
								name='low-priority'
								width='19px'
								height='22px'
								margin='0 0 0 8px'
								color='white'
								style={{ transform: 'rotate(180deg)' }}
							/>
						</Button>
					</FlexView>
				</FlexView>
			)}
			<ManagePartsModal isOpen={openManageParts} onOutsideClick={() => setOpenManageParts(false)} />
		</Card>
	)
}

export default PerInspInfo
