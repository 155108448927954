/** @format */
/* eslint-disable react/prop-types */

import { REPORT_STATUS } from 'apps/wms/utils/enum'
import { FlexView } from 'components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const ReportTitle = ({ reportData, allowEdit, zoom, title, reportPart }) => {
	const { t } = useTranslation()

	return (
		<FlexView
			style={{ zoom: zoom }}
			flexDirection='row'
			justifyContent='space-between'
			width='100%'
			margin='16px 0 24px'>
			<FlexView flexDirection='row' fontWeight='bold' fontSize='36px' alignItems='flex-end'>
				{title}
				<FlexView
					fontSize='tiny'
					padding='4px 8px'
					margin='0 0 8px 8px'
					borderRadius='16px'
					color='white'
					backgroundColor={
						REPORT_STATUS.color[
							reportPart
								? _.find(reportData.content, c => c.partnumber === reportPart)?.validationdata?.status
								: reportData.status
						]
					}>
					{t(
						REPORT_STATUS.translateKey[
							reportPart
								? _.find(reportData.content, c => c.partnumber === reportPart)?.validationdata?.status
								: reportData.status
						]
					)}
				</FlexView>
			</FlexView>
			<FlexView alignItems='flex-end'>
				<FlexView fontSize='medium'>{allowEdit ? t('wms:Editing') : t('wms:Viewing')}</FlexView>
				<FlexView fontWeight='bold' fontSize='big'>
					ID: {reportData.filters && reportData.filters.entrance ? reportData.filters.entrance : reportData.id} |{' '}
					{t('wms:Revision')}: {reportData.validation_revision ? reportData.validation_revision : '0'}
				</FlexView>
			</FlexView>
		</FlexView>
	)
}

export default ReportTitle
