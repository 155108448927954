/** @format */

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, LoadingOverlay, Icon } from 'components/common'
import { AssetsMaterialContext } from 'apps/wms/stores/AssetsMaterialStore'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import Select from 'apps/wms/components/forms/Select'
import ListTable from 'apps/wms/components/commom/ListTable'
import { WMSContext } from 'apps/wms/stores/WmsStore'

const MaterialCatalog = () => {
	const { t } = useTranslation()
	const {
		materialList,
		setMaterialList,
		deleteMaterial,
		setIsSyncOn,
		setIsMatHandlerOpen,
		isLoading,
		setMaterialToEdit,
		setIsMatEditorOpen,
		enduserList
	} = useContext(AssetsMaterialContext)
	const { lengthUnit, weightUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit } = useContext(WMSContext)

	const [materialToDelete, setMaterialToDelete] = useState(null)
	const [isDeleteWarningOn, setIsDeleteWarningOn] = useState(false)
	const [isWaitingResponse, setIsWaitingResponse] = useState(false)
	const [enduser, setEnduser] = useState(null)

	const handleCreateMaterial = () => {
		setIsMatHandlerOpen(true)
	}

	const handleEditMaterial = row => {
		setMaterialToEdit(row)
		setIsMatEditorOpen(true)
	}

	const handleSyncMaterial = useCallback(() => {
		setIsSyncOn(true)
	}, [setIsSyncOn])

	const handleDeleteMaterial = row => {
		setMaterialToDelete(row)
		setIsDeleteWarningOn(true)
	}

	const onDeleteMaterial = useCallback(
		async material => {
			try {
				setIsWaitingResponse(true)
				await deleteMaterial(material.mvid)
				setIsWaitingResponse(false)
				const mIndex = materialList.findIndex(m => m.mvid === material.mvid)
				const removedMaterial = mIndex !== -1 && materialList.splice(mIndex, 1)
				setMaterialList([...materialList])
				setMaterialToDelete(null)
				return removedMaterial
			} catch (error) {
				console.error(error)
				setIsWaitingResponse(false)
			}
		},
		[deleteMaterial, materialList, setMaterialList]
	)

	const materialColumns = useMemo(
		() => [
			{
				Header: t('wms:ErpReference'),
				accessor: 'erpreference',
				width: '10%',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='start'
							fontSize='tiny'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Description'),
				accessor: 'formatted',
				width: '70%',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='start'
							fontSize='tiny'
							margin='0'
							padding='0'>
							{value}
						</FlexView>
					)
				}
			},
			{
				Header: `${t('wms:Weight')} [${weightUnit}]`,
				accessor: 'weightkg',
				width: '5%',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='tiny'
							margin='0'
							padding='0'>
							{convertToCurrentWeightUnit(value, weightUnit)}
						</FlexView>
					)
				}
			},
			{
				Header: `${t('wms:Length')} [${lengthUnit}]`,
				accessor: 'lengthmm',
				width: '5%',
				Cell: ({ cell: { value } }) => {
					return (
						<FlexView
							flexDirection='row'
							alignItems='center'
							justifyContent='center'
							fontSize='tiny'
							margin='0'
							padding='0'>
							{convertToCurrentLengthUnit(value, lengthUnit)}
						</FlexView>
					)
				}
			},
			{
				Header: t('wms:Action'),
				width: '10%',
				Cell: ({ cell: { row } }) => (
					<FlexView flexDirection='row' justifyContent='space-between'>
						<Button
							fontSize='tiny'
							margin='0'
							padding='8px'
							color='white'
							backgroundColor='primary'
							elevation='none'
							onClick={() => {
								handleEditMaterial(row.original)
							}}
							data-cy='button-download-report'>
							<Icon name='edit' color='white' height='16px' width='16px' margin='0' />
						</Button>
						<Button
							fontSize='tiny'
							margin='0 0 0 8px'
							padding='8px'
							color='white'
							backgroundColor='error'
							title={t('wms:AvailableSoon')}
							onClick={() => {
								handleDeleteMaterial(row.original)
							}}
							data-cy='button-delete-material'>
							<Icon name='trash' color='white' height='16px' width='16px' margin='0' />
						</Button>
					</FlexView>
				)
			}
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, convertToCurrentLengthUnit, convertToCurrentWeightUnit, lengthUnit, weightUnit]
	)

	return (
		<Card data-cy='card-list-wrapper' width='calc(100% - 48px)' flex='1' margin='0' style={{ position: 'relative' }}>
			<LoadingOverlay borderRadius='card' visible={isLoading || isWaitingResponse} />
			<FlexView width='100%' flexDirection='row' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				<Select
					placeholder={t(`wms:EndUser`)}
					value={enduser}
					options={enduserList.map(({ id, name }) => ({
						value: id,
						label: name
					}))}
					onChange={value => {
						setEnduser(value)
					}}
					width='180px'
					inline={false}
					margin='auto 0'
					searchable
					data-cy='select-source-enduser'
				/>
				<Button
					fontSize='tiny'
					margin='auto auto auto 8px'
					padding='8px'
					color='white'
					backgroundColor='error'
					disabled={false}
					onClick={() => setEnduser(null)}>
					<Icon name='cross-simple' width='8px' height='8px' color='white' margin='0px' fontWeight='bold' />
				</Button>
				<Button
					fontSize='tiny'
					margin='auto 0 auto auto'
					padding='8px 16px'
					color='white'
					backgroundColor='secondary'
					disabled={false}
					onClick={() => handleCreateMaterial()}>
					{`${t('wms:New')} ${t('wms:Material')}`}
					<Icon name='add' width='16px' height='16px' color='white' margin='0px 0px 0px 8px' fontWeight='bold' />
				</Button>
				<Button
					fontSize='tiny'
					margin='auto 0 auto 8px'
					padding='8px 16px'
					color='white'
					backgroundColor='secondary'
					disabled={false}
					onClick={() => handleSyncMaterial()}>
					{`${t('wms:SyncMaterialList')}`}
					<Icon
						name='refresh'
						width='16px'
						height='16px'
						color='white'
						margin='0px 0px 0px 8px'
						fontWeight='bold'
					/>
				</Button>
			</FlexView>

			{materialList && materialList.length ? (
				<ListTable
					margin='0'
					width='100%'
					textAlign='center'
					elevation='none'
					fontSize='10px'
					columns={materialColumns}
					data={materialList.filter(mat => (enduser === null ? true : mat.enduserid === enduser))}
					sortable
					gridless
					paginated
				/>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='placeholder-material-list'>
					{t('wms:MaterialListEmpty')}
				</FlexView>
			)}
			<DialogModal
				isOpen={isDeleteWarningOn}
				text={t(`wms:ConfirmMaterialDeletion`)}
				onConfirm={() => onDeleteMaterial(materialToDelete) && setIsDeleteWarningOn(false)}
				onCancel={() => setIsDeleteWarningOn(false)}
			/>
		</Card>
	)
}

export default MaterialCatalog
