import React, { useContext } from 'react'
import { Modal, Card, FlexView, Icon } from 'components/common'
import { LogsContext } from 'apps/admin/stores/LogsStore'
import { TextArea } from 'components/form'
import { useTranslation } from 'react-i18next'

const downloadString = (text, fileType, fileName) => {
  var blob = new Blob([text], { type: fileType })

  var a = document.createElement('a')
  a.download = fileName
  a.href = URL.createObjectURL(blob)
  a.dataset.downloadurl = [fileType, a.download, a.href].join(':')
  a.style.display = "none"
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  setTimeout(function() { URL.revokeObjectURL(a.href) }, 1500)
}

const LogModal = () => {
  const { t } = useTranslation()
  const { logControl, selectedLog, setSelectedLog } = useContext(LogsContext)

  const { service } = logControl.settings

  const clearLog = () => {
    setSelectedLog(null)
  }

  const onDownloadClick = () => {
    downloadString(selectedLog.Message, 'text/plain', `${service}-${selectedLog.Type}-${selectedLog.datetime.format('YYYY-MM-DD_HH-mm')}.txt`)
  }

  return <Modal isOpen={!!selectedLog} onOutsideClick={clearLog}>
    {selectedLog && <Card maxWidth="800px" position="relative" alignItems="stretch">
      <FlexView fontSize="big" fontWeight="bold" margin="0px 0px 16px" flexDirection="row" alignItems="center" justifyContent="space-between">
        <span>{service} - {selectedLog.datetime.format('DD/MM/YYYY HH:mm')} - {selectedLog.Type}</span>
        <Icon tooltip={t('admin:logs.Download')} name="download" width="24px" height="24px" onClick={onDownloadClick} />
      </FlexView>
      <TextArea value={selectedLog.Message} width="600px" rows={20} onChange={() => null} />
    </Card>}
  </Modal>
}

export default LogModal