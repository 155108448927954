/** @format */

import { FlexView } from 'components/common'
import { Select } from 'components/form'
import React, { useMemo } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PageTemplate from '../containers/common/PageTemplate'
import ActualData from '../containers/pipedata/actualData/ActualData'
import ModifBatchPipes from '../containers/pipedata/actualData/ModifBatchPipes'
import ModifSinglePipe from '../containers/pipedata/actualData/ModifSinglePipe'
import PipeDataModif from '../containers/pipedata/actualData/PipeDataModif'
import { ActualDataContext } from '../stores/ActualDataModifStore'

const ActualDataModifPage = () => {
	const { t } = useTranslation()
	const { selectedType, setSelectedType } = useContext(ActualDataContext)

	const type = [
		{
			value: 'SINGLE',
			label: t('wms:SinglePipe')
		},
		{
			value: 'BATCH',
			label: t('wms:BatchPipes')
		}
	]

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:ActualDataModif'),
				path: '/wms/pipedata/modify'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs}>
			<FlexView width='100%' height='100%' maxWidth='calc(100% - 320px)' flex='1' padding='0 0 40px 0'>
				<FlexView flexDirection='row' width='100%' justifyContent='space-between'>
					<FlexView minWidth='80%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
						{t('wms:ActualDataModif')}
					</FlexView>
					<Select
						width='20%'
						label={`${t('wms:Type')}: `}
						placeholder={t('wms:Type')}
						value={selectedType}
						options={type}
						onChange={v => setSelectedType(v)}
						inline
						data-cy='select-type'
					/>
				</FlexView>
				<FlexView flexDirection='row' width='100%' height='100%' alignItems='stretch'>
					{selectedType === 'SINGLE' ? (
						<ModifSinglePipe />
					) : selectedType === 'BATCH' ? (
						<ModifBatchPipes />
					) : (
						<FlexView
							elevation='low'
							backgroundColor='white'
							borderRadius='16px'
							width='30%'
							height='95%'
							fontWeight='bold'
							fontSize='24px'
							color='lightGray'
							margin='8px 8px 0 0'
							padding='8px'
							alignItems='center'
							justifyContent='center'
							style={{ textAlign: 'center' }}
							data-cy='no-type-selected'>
							{t('wms:NoTypeSelected')}
						</FlexView>
					)}
					<PipeDataModif />
					<ActualData />
				</FlexView>
			</FlexView>
		</PageTemplate>
	)
}

export default ActualDataModifPage
