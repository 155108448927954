/** @format */

import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import ageManagement from '../api/ageManagement'
import entrance from '../api/entrance'
import dispatch from '../api/dispatch'
import reports from '../api/reports'
import ageExtract from '../api/assetsAgeMgmt'
import settings from '../api/assetsInspSettings'

import { toast } from 'react-toastify'

import { INSPECTION_STAGES, REPORT_TYPE } from '../utils/enum'

import { WMSContext } from '../stores/WmsStore'
import { UserContext } from 'stores/UserStore'
import moment from 'moment'

import instruments from '../api/instruments'
import formatReports from '../utils/reports/reportdetailsformatter'
import _ from 'lodash'
import formatReportData from '../utils/dataformater'
import generalInfoFormatter from '../utils/reports/generalinfoformatter'
import formatPartReport from '../utils/reports/partdetailsformatter'
import common from '../api/common'

export const ReportsContext = React.createContext()

export const ReportsProvider = ({ children }) => {
	const {
		warehouse,
		convertToCurrentLengthUnit,
		convertToCurrentWeightUnit,
		lengthUnit,
		weightUnit,
		getMaterialsDescpritions
	} = useContext(WMSContext)
	const { user, token } = useContext(UserContext)
	const { i18n, t } = useTranslation()

	const [inspType, setInspType] = useState(0)
	const [reportType, setReportType] = useState(null)
	const [reviewType, setReviewType] = useState(REPORT_TYPE.code.SLOW_ITEMS)
	const [loadingReports, setLoadingReports] = useState(false)
	const [fetchingFirstPage, setFetchingFirstPage] = useState(false)
	const [fetchingLastPage, setFetchingLastPage] = useState(false)
	const [canPreviousPage, setCanPreviousPage] = useState(false)
	const [canNextPage, setCanNextPage] = useState(false)
	const [inspReportList, setInspReportList] = useState([])
	const [selectedReport, setSelectedReport] = useState(null)
	const [reportDetails, setReportDetails] = useState(null)
	const [loadingDetails, setLoadingDetails] = useState(false)
	const [loadingPartDetails, setLoadingPartDetails] = useState(false)
	const [exportReportsList, setExportReportsList] = useState([])
	const [allAgeReports, setAllAgeReports] = useState([])
	const [reviewReportsList, setReviewReportsList] = useState([])
	const [selectedRevReport, setSelectedRevReport] = useState(null)
	const [isEditorOpen, setIsEditorOpen] = useState(false)
	const [extracts, setExtracts] = useState(null)
	const [selectedExtract, setSelectedExtract] = useState(null)
	const [endUsers, setEndUsers] = useState(null)
	const [inspections, setInspections] = useState(null)
	const [items, setItems] = useState(null)
	const [inspSelected, setInspSelected] = useState(null)
	const [elementsByInsp, setElementsByInsp] = useState(null)
	const [inspInfo, setInspInfo] = useState({})
	const [reportHeader, setReportHeader] = useState(null)
	const [inspName, setInspName] = useState(null)
	const [type, setType] = useState(null)
	const [oldInspection, setOldInspection] = useState(null)
	const [reportLinks, setReportLinks] = useState(null)
	const [totalItems, setTotalItems] = useState(null)
	const [totalPages, setTotalPages] = useState(null)
	const [currentPage, setCurrentPage] = useState(1)
	const [dialogData, setDialogData] = useState(null)
	const [downloadingReport, setDownloadingReport] = useState(false)
	const [filteredReports, setFilteredReports] = useState([])
	const [filteredExportList, setFilteredExportList] = useState([])
	const [keyWord, setKeyWord] = useState('')
	const [selectedRow, setSelectedRow] = useState({})
	const [selectedSubRow, setSelectedSubRow] = useState({})
	const [partDetails, setPartDetails] = useState({})
	const [instrumentList, setInstrumentList] = useState([])
	const [materials, setMaterials] = useState([])
	const errorGettingReports = t('wms:ErrorGettingReports')
	const warehouseNotDefined = t('wms:WarehouseNotDefined')
	const remarks = 'Edited on report editor'
	const errorDialogMessage = t('wms:ErrorDialogMessage')
	const errorDownloadingReport = t('wms:ErrorDownloadingReport')

	const getInstrumentList = instCaseId =>
		new Promise((resolve, reject) => {
			instruments
				.getInstrumentCaseList(warehouse.id, instCaseId, token)
				.then(response => {
					resolve({ id: instCaseId, list: response })
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingInstruments')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getReportInstrumentData = reportData =>
		new Promise((resolve, reject) => {
			let idsList = _.uniqBy(reportData.items, r => r.instlistid)
				.map(r => r.instlistid)
				.filter(r => r !== null && r !== 0)
			let reqList = idsList.map(i => getInstrumentList(i))
			Promise.allSettled(reqList)
				.then(promiseArray => {
					let instrumentList = promiseArray.map(promise => {
						if (promise.status === 'fulfilled') return promise.value
						else return null
					})
					resolve(instrumentList)
				})
				.catch(e => reject(e))
		})

	const formatDataWithInstruments = data =>
		new Promise((resolve, reject) => {
			const hydrate = async () => {
				try {
					const methodAndTypeRequired =
						inspSelected &&
						inspSelected.thread_compound_method_required != null &&
						inspSelected.thread_compound_type_required != null
					const methodRequired =
						inspSelected &&
						inspSelected.thread_compound_method_required != null &&
						!inspSelected.thread_compound_type_required
					const typeRequired =
						inspSelected &&
						inspSelected.thread_compound_type_required != null &&
						!inspSelected.thread_compound_method_required
					const instruments = await getReportInstrumentData(data)
					setInstrumentList(instruments)
					const formattedDataWithInstruments = {
						...data,
						instrumentlist: instruments,
						items: data.items.map(item => {
							const hasId = item.instlistid != null
							const hasInstruments = instruments && instruments.length !== 0
							const foundInstrument = instruments.find(list => list.id === item.instlistid)
							return (
								hasId &&
								hasInstruments &&
								foundInstrument && {
									...item,
									instrumentlist: foundInstrument.list
								}
							)
						})
					}
					const threadCompoundColumns = []
					if (methodAndTypeRequired)
						threadCompoundColumns.push(
							{ id: 1, name: 'Manufacturer', accessor: 'manufacturer' },
							{ id: 2, name: 'ApplicationMethod', accessor: 'method_id' }
						)
					else if (methodRequired)
						threadCompoundColumns.push({ id: 2, name: 'ApplicationMethod', accessor: 'method_id' })
					else if (typeRequired)
						threadCompoundColumns.push({ id: 1, name: 'Manufacturer', accessor: 'manufacturer' })
					const hasThreadCompound = inspSelected && inspSelected.has_thread_compound
					const formattedData = {
						...formattedDataWithInstruments,
						threadCompoundColumns,
						hasThreadCompound
					}
					resolve(formattedData)
				} catch (e) {
					console.error(e)
					reject(e)
				}
			}
			hydrate()
		})

	const getReports = (name, page) =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			name !== null &&
				name !== 0 &&
				reports
					.getClosedReports(warehouse.id, name, token)
					.then(response => {
						resolve(response)
						const generalInformation =
							page === 'delivery_notes'
								? generalInfoFormatter(response.items, true)
								: generalInfoFormatter(response.items, false)
						page === 'delivery_notes'
							? setExportReportsList(generalInformation)
							: setInspReportList(generalInformation)
						setReportLinks(response.links)
						setTotalItems(response.total_items)
						setTotalPages(response.total_pages)
						setFetchingFirstPage(false)
						setCurrentPage(response.current_page)
					})
					.catch(e => {
						setLoadingReports(false)
						reject(e)
						console.error(e)
						toast.error(`${errorGettingReports} [ ${e.status} ]: ${e.data}`)
					})
					.finally(() => {
						setLoadingReports(false)
					})
		})

	const getMoreReports = (page, numbersPerPage, pageName) =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			reports
				.getMoreClosedReports(warehouse.id, inspName, numbersPerPage, page, token, keyWord === '' ? null : keyWord)
				.then(response => {
					resolve(response.items)
					const generalInformation =
						pageName === 'delivery_notes'
							? generalInfoFormatter(response.items, true)
							: generalInfoFormatter(response.items, false)
					if (keyWord !== '' && pageName === 'delivery_notes') setFilteredExportList(generalInformation)
					else setFilteredReports(generalInformation)
					if (keyWord === '' && pageName === 'delivery_notes') setExportReportsList(generalInformation)
					else setInspReportList(generalInformation)
					setReportLinks(response.links)
					setTotalItems(response.total_items)
					setTotalPages(response.total_pages)
					setFetchingFirstPage(false)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingInspReports')} [ ${e.status} ]: ${e.data}`)
					setFetchingFirstPage(false)
					setFetchingLastPage(false)
				})
				.finally(() => {
					setFetchingFirstPage(false)
					setFetchingLastPage(false)
					setLoadingReports(false)
				})
		})

	const searchReports = (name, searchParam, page) =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			const isNameDefined = name !== null && name !== 0
			const isSearchParamDefined = searchParam !== ''
			isNameDefined &&
				isSearchParamDefined &&
				reports
					.searchInfo(warehouse.id, name, token, searchParam)
					.then(response => {
						if (response.items.length === 0) toast.error(t('wms:NoReportsFound'))
						resolve(response.items)
						const generalInformation =
							page === 'delivery_notes'
								? generalInfoFormatter(response.items, true)
								: generalInfoFormatter(response.items, false)
						page === 'delivery_notes'
							? setFilteredExportList(generalInformation)
							: setFilteredReports(generalInformation)
						setReportLinks(response.links)
						setTotalItems(response.total_items)
						setTotalPages(response.total_pages)
						setFetchingFirstPage(false)
						resolve(response.items)
					})
					.catch(e => {
						setReportLinks(null)
						setTotalItems(null)
						setTotalPages(null)
						setCurrentPage(null)
						reject(e)
						console.error(e)
						toast.error(`${errorGettingReports} [ ${e.status} ]: ${e.data}`)
					})
					.finally(() => {
						setLoadingReports(false)
					})
		})

	const formatResponseDetails = response => {
		let formattedData
		const partSelected = selectedSubRow && response.contents.find(content => content.id === selectedSubRow.partid)
		const isRigReturn =
			inspSelected && inspSelected.stage === INSPECTION_STAGES.code.RETURN && !inspSelected.is_modified_product
		if (isRigReturn) {
			const validList = response.contents.flatMap(content => content.items)
			return getMaterialsDescpritionsByValid(response, validList)
		} else if (response.contents.length === 1) {
			const part = response.contents[0]
			formattedData = formatPartReport(
				response,
				part,
				null,
				elementsByInsp,
				convertToCurrentLengthUnit,
				convertToCurrentWeightUnit,
				lengthUnit,
				weightUnit,
				true,
				false,
				[],
				[]
			)
		} else if (partSelected) {
			formattedData = formatPartReport(
				response,
				partSelected,
				selectedSubRow,
				elementsByInsp,
				convertToCurrentLengthUnit,
				convertToCurrentWeightUnit,
				lengthUnit,
				weightUnit,
				true,
				false,
				[],
				[]
			)
		} else {
			formattedData = formatReports(response, inspSelected, [])
		}

		if (partSelected || response.contents.length === 1) {
			return formatDataWithInstruments(formattedData)
				.then(list => {
					setPartDetails(list)
				})
				.catch(e => console.error(e))
		}

		return formattedData
	}

	const getReportDetails = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			if (selectedReport == null || elementsByInsp == null) {
				return Promise.resolve(null)
			}
			reports
				.getInspectionReportDetails(warehouse.id, selectedReport.listid, selectedReport.type, token)
				.then(response => {
					setType(selectedReport.type)
					resolve(response)
					const formattedData = formatResponseDetails(response)
					setInspInfo(formattedData)
					setReportDetails({ ...selectedReport, ...formattedData })
					resolve(response)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingInspDetails')} [ ${e.status} ]: ${e.data}`)
					reject(e)
					console.error(e)
				})
		})

	const getMaterialsDescpritionsByValid = (detailedReport, validList) =>
		new Promise((resolve, reject) => {
			setLoadingDetails(true)
			const hasOnePart = detailedReport && detailedReport.contents.length === 1
			const isGeneralInfo = selectedRow != null
			const partSelected =
				selectedSubRow && detailedReport.contents.find(content => content.id === selectedSubRow.partid)
			let formattedData
			Promise.allSettled(
				validList.map(async list => {
					return await common.getPipeData(warehouse.id, list.pipe_valid, null, token)
				})
			)
				.then(listArray => {
					let preList = []
					listArray.forEach(
						(list, index) => list.status === 'fulfilled' && list.value && (preList[index] = list.value)
					)
					const filteredMaterials = []
					preList.forEach(list => {
						const filteredMaterialList = materials.filter(
							material => material.erpreference === list.pipedata.catid
						)
						const mapMaterials = filteredMaterialList.map(material => {
							const validFound = validList.find(valid => valid.pipe_valid === list.pipedata.pipevalid)
							return {
								mvid: material.mvid,
								formatted: material.formatted,
								valid: list.pipedata.pipevalid,
								contentid: validFound && validFound.content
							}
						})
						filteredMaterials.push(...mapMaterials)
					})
					if (hasOnePart || partSelected) {
						const part = hasOnePart
							? detailedReport.contents[0]
							: detailedReport.contents.find(x => x.id === partSelected.id)
						formattedData = formatPartReport(
							detailedReport,
							part || partSelected,
							selectedSubRow,
							elementsByInsp,
							convertToCurrentLengthUnit,
							convertToCurrentWeightUnit,
							lengthUnit,
							weightUnit,
							true,
							false,
							filteredMaterials,
							[]
						)
						formatDataWithInstruments(formattedData).then(part => {
							setPartDetails(part)
							setInspInfo(part)
						})
					} else if (isGeneralInfo) {
						formattedData = formatReports(
							detailedReport,
							inspSelected,
							filteredMaterials,
							elementsByInsp,
							convertToCurrentLengthUnit,
							convertToCurrentWeightUnit,
							lengthUnit,
							weightUnit
						)
					}
					setInspInfo(formattedData)
					setReportDetails({ ...selectedReport, ...formattedData })
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
				})
				.finally(() => {
					setLoadingDetails(false)
				})
		})

	const defineType = selectedInsp => {
		const stage = selectedInsp.stage
		return stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION
			? INSPECTION_STAGES.type.MATERIAL_REQUISITION
			: stage === INSPECTION_STAGES.code.ARRIVAL
			? INSPECTION_STAGES.type.ARRIVAL
			: stage === INSPECTION_STAGES.code.PERIODICAL
			? INSPECTION_STAGES.type.PERIODICAL
			: stage === INSPECTION_STAGES.code.RETURN && selectedInsp.is_modified_product
			? INSPECTION_STAGES.type.MODIFIED_PRODUCT
			: INSPECTION_STAGES.type.RIG_RETURN
	}

	const downloadInspectionReport = (reportId, partNumber, selectedInsp) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				const type = defineType(selectedInsp)
				reportId != null &&
					type != null &&
					reports
						.downloadInspReports(warehouse.id, reportId, type, partNumber === null ? null : partNumber, token)
						.then(response => {
							const link = document.createElement('a')
							link.href = response
							link.target = '_blank'
							link.click()
							resolve(true)
						})
						.catch(e => {
							reject(e)
							console.error(e)
							toast.error(`${errorDownloadingReport} [ ${e.status} ]: ${e.data}`)
						})
			}
		})

	const downloadBackloadManifest = (reportId, partNumber) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				setDownloadingReport(true)
				reportId !== null &&
					reports
						.downloadBackload(warehouse.id, reportId, partNumber, token)
						.then(response => {
							const link = document.createElement('a')
							link.href = response
							link.target = '_blank'
							link.click()
							resolve(true)
						})
						.catch(e => {
							reject(e)
							console.error(e)
							toast.error(
								`${t('wms:ErrorDownloadingBackloadReport')} [ ${e.status} ]: ${
									e.statusText ? e.statusText : e.data
								}`
							)
							setDownloadingReport(false)
						})
			}
		})

	const downloadDeliveryNote = (reportId, partNumber) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				setDownloadingReport(true)
				reportId !== null &&
					reports
						.downloadDeliveryNotes(warehouse.id, reportId, partNumber, token)
						.then(response => {
							const link = document.createElement('a')
							link.href = response
							link.target = '_blank'
							link.click()
							resolve(true)
						})
						.catch(e => {
							reject(e)
							console.error(e)
							toast.error(
								`${t('wms:ErrorDownloadingDNReport')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
							)
							setDownloadingReport(false)
						})
						.finally(() => setDownloadingReport(false))
			}
		})

	const downloadDeliveryNotePdf = (reportId, partNumber) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				reportId !== null &&
					reports
						.downloadDeliveryNotesPdf(warehouse.id, reportId, partNumber, token)
						.then(response => {
							const link = document.createElement('a')
							link.href = response
							link.target = '_blank'
							link.click()
							resolve(true)
						})
						.catch(e => {
							toast.error(`${t('wms:ErrorDownloadingReport')} [ ${e.status} ]: ${e.data}`)
							reject(e)
						})
			}
		})

	const downloadDispatchReport = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				setDownloadingReport(true)
				reportId !== null &&
					reports
						.downloadDispatchReport(warehouse.id, reportId, token)
						.then(response => {
							const link = document.createElement('a')
							link.href = response
							link.click()
							resolve(true)
						})
						.catch(e => {
							reject(e)
							console.error(e)
							toast.error(
								`${t('wms:ErrorDownloadingDispatchReport')} [ ${e.status} ]: ${
									e.statusText ? e.statusText : e.data
								}`
							)
							setDownloadingReport(false)
						})
						.finally(() => setDownloadingReport(false))
			}
		})

	const downloadModifProductsReport = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				setDownloadingReport(true)
				reportId !== null &&
					reports
						.downloadModifProduct(warehouse.id, reportId, token)
						.then(response => {
							const link = document.createElement('a')
							link.href = response
							link.target = '_blank'
							link.click()
							resolve(true)
						})
						.catch(e => {
							reject(e)
							console.error(e)
							toast.error(
								`${t('wms:ErrorDownloadingModifProdReport')} [ ${e.status} ]: ${
									e.statusText ? e.statusText : e.data
								}`
							)
							setDownloadingReport(false)
						})
						.finally(() => setDownloadingReport(false))
			}
		})

	const downloadAgeManagementReport = reportId =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				reportId !== null &&
					reports
						.downloadAgeMgmtReport(warehouse.id, reportId, token)
						.then(response => {
							const link = document.createElement('a')
							link.href = response
							link.target = '_blank'
							link.click()
							resolve(true)
						})
						.catch(e => {
							reject(e)
							console.error(e)
							toast.error(`${errorDownloadingReport} [ ${e.status} ]: ${e.data}`)
						})
			}
		})

	const downloadMillReceipt = (reportId, partNumber) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				reportId !== null &&
					reports
						.downloadReceiptNote(warehouse.id, reportId, partNumber, token)
						.then(response => {
							const link = document.createElement('a')
							link.href = response
							link.click()
							resolve(true)
						})
						.catch(e => {
							toast.error(
								`${t('wms:ErrorDownloadingReceiptNote')} [ ${e.status} ]: ${
									e.statusText ? e.statusText : e.data
								}`
							)
							reject(e)
							setDownloadingReport(false)
						})
						.finally(() => setDownloadingReport(false))
			}
		})

	const downloadDailyReport = payload =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			payload !== null &&
				reports
					.generateDailyReport(warehouse.id, i18n.language, payload, token)
					.then(response => {
						let fileName = `ActivityReport_${moment(new Date()).format('M-DD-YYYY')}.xlsx`
						let a = document.createElement('a')
						let file = new Blob([response.data], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						})
						let fileURL = window.URL.createObjectURL(file)
						a.href = fileURL
						a.target = '_blank'
						a.download = fileName
						a.click()
						resolve(true)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingInspReport')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
		})

	const downloadTitleTransferReport = reportId =>
		new Promise((resolve, reject) => {
			reportId != null &&
				reports
					.downloadTitleTransfer(warehouse.id, reportId, token)
					.then(response => {
						const link = document.createElement('a')
						link.href = response
						link.target = '_blank'
						link.click()
						resolve(true)
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorGettingTitleTransferReport')} [ ${e.status} ]: ${e.data}`)
					})
		})

	const downloadTitleTransferReportPdf = reportId =>
		new Promise((resolve, reject) => {
			reportId != null &&
				reports
					.downloadTitleTransferPdfData(warehouse.id, reportId, token)
					.then(response => {
						const link = document.createElement('a')
						link.href = response
						link.target = '_blank'
						link.click()
						resolve(true)
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorGettingTitleTransferReport')} [ ${e.status} ]: ${e.data}`)
					})
		})

	const getMoreDeliveryNotes = (page, numbersPerPage) =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			reports
				.getMoreClosedReports(warehouse.id, inspName, numbersPerPage, page, token, keyWord)
				.then(response => {
					resolve(response.items)
					const generalInformation = generalInfoFormatter(response.items, true)
					keyWord !== '' ? setFilteredExportList(generalInformation) : setExportReportsList(generalInformation)
					setReportLinks(response.links)
					setTotalItems(response.total_items)
					setTotalPages(response.total_pages)
					setFetchingFirstPage(false)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${errorGettingReports} [ ${e.status} ]: ${e.data}`)
					setReportLinks(null)
					setTotalItems(null)
					setTotalPages(null)
					setCurrentPage(null)
					setFetchingFirstPage(false)
					setFetchingLastPage(false)
					setLoadingReports(false)
				})
				.finally(() => {
					setFetchingFirstPage(false)
					setFetchingLastPage(false)
					setLoadingReports(false)
				})
		})

	const getDeliveryNotesTitleTransfer = () =>
		new Promise((resolve, reject) => {
			ageManagement
				.getTitleTransferReports(warehouse.id, token)
				.then(response => {
					let filteredTitleTransfer = _.filter(response, res => res.periodthreshold >= 12)
					setExportReportsList(filteredTitleTransfer)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingTitleTransfer')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getDispatchReports = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				setLoadingReports(true)
				dispatch
					.getReports(warehouse.id, token)
					.then(
						response =>
							response &&
							(setExportReportsList(response.map(item => item.result).sort((a, b) => b.id - a.id)) ||
								resolve(response.map(item => item.result)))
					)
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingInspDetails')}`)
						reject(e)
						setLoadingReports(false)
					})
					.finally(() => setLoadingReports(false))
			}
		})

	const getOtherReports = name =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				setExportReportsList([])
				setLoadingReports(true)
				name !== null &&
					name !== 0 &&
					reports
						.getClosedReports(warehouse.id, name, token)
						.then(response => {
							resolve(response)
							const generalInformation = generalInfoFormatter(response.items, false)
							setExportReportsList(generalInformation)
							setReportLinks(response.links)
							setTotalItems(response.total_items)
							setTotalPages(response.total_pages)
							setCurrentPage(response.current_page)
						})
						.catch(e => {
							setLoadingReports(false)
							setReportLinks(null)
							setTotalItems(null)
							setTotalPages(null)
							setCurrentPage(null)
							toast.error(`${errorGettingReports} [ ${e.status} ]: ${e.data}`)
							reject(e)
						})
						.finally(() => {
							setLoadingReports(false)
						})
			}
		})

	const getMoreOtherReports = (page, numbersPerPage) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				setExportReportsList([])
				setLoadingReports(true)
				reports
					.getMoreClosedReports(warehouse.id, inspName, numbersPerPage, page, token, null)
					.then(response => {
						resolve(response)
						setExportReportsList(response.items.sort((a, b) => a.id - b.id))
						setReportLinks(response.links)
						setTotalItems(response.total_items)
						setTotalPages(response.total_pages)
						setCurrentPage(response.current_page)
					})
					.catch(e => {
						setLoadingReports(false)
						setReportLinks(null)
						setTotalItems(null)
						setTotalPages(null)
						setCurrentPage(null)
						toast.error(`${errorGettingReports} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
					.finally(() => {
						setLoadingReports(false)
					})
			}
		})

	const getAgeManagementReports = enduser =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			ageManagement
				.getReports(warehouse.id, selectedExtract, enduser, token)
				.then(response => {
					setAllAgeReports(response)
					setReviewReportsList(response)
				})
				.catch(e => reject(e))
				.finally(() => {
					setLoadingReports(false)
				})
		})

	const getBatchRecipts = enduser =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				entrance
					.getBatchReceipts(warehouse.id, enduser, token)
					.then(response => response && resolve(response))
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingMillReceiptsList')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
			}
		})

	const getMillReceipts = enduser =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			Promise.allSettled([getBatchRecipts(enduser)])
				.then(listArray => {
					let preList = []
					listArray.forEach(
						list => list.status === 'fulfilled' && list.value.length && preList.push(...list.value)
					)
					setReviewReportsList(preList.sort((a, b) => a.id - b.id))
					resolve(preList)
				})
				.catch(e => reject(e))
				.finally(() => {
					setLoadingReports(false)
				})
		})

	const getExtracts = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				ageExtract
					.getExtracts(warehouse.id, token)
					.then(response => {
						setExtracts(response)
						resolve()
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorGettingExtracts')} [ ${e.status} ]: ${e.data}`)
					})
			}
		})

	const getEndUsers = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				reports
					.getEndUsers(warehouse.id, token)
					.then(response => {
						resolve()
						setEndUsers(response)
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorGettingEndUsers')} [ ${e.status} ]: ${e.data}`)
					})
			}
		})

	const getInspections = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				settings
					.getInspections(warehouse.id, token)
					.then(response => {
						let filtered = _.filter(response, res => res.enabled === true)
						resolve()
						setInspections(
							filtered.concat({ id: 0, name: `${t('wms:ActivityReport')}` }).sort((a, b) => a.id - b.id)
						)
					})
					.catch(e => {
						console.error(e)
						toast.error(`${t('wms:ErrorGettingInspections')} [ ${e.status} ]: ${e.data}`)
					})
			}
		})

	const getElements = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			} else {
				settings
					.getItems(warehouse.id, token)
					.then(response => {
						resolve()
						setItems(response)
					})
					.catch(e => {
						console.error(e)
						toast.error(`${t('wms:ErrorGettingItems')} [ ${e.status} ]: ${e.data}`)
					})
			}
		})

	const getElementsByID = () =>
		new Promise((resolve, reject) => {
			oldInspection &&
				Promise.all(
					oldInspection.elements.map(async id => {
						return await settings.getItemsByID(warehouse.id, id, token)
					})
				)
					.then(listArray => {
						resolve()
						setElementsByInsp(listArray)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorGettingItems')} [ ${e.status} ]: ${e.data}`)
					})
		})

	const getOldInspections = value =>
		new Promise((resolve, reject) => {
			value !== null &&
				settings
					.getInspectionByID(warehouse.id, value, token)
					.then(response => {
						resolve()
						setOldInspection(response)
					})
					.catch(e => {
						console.error(e)
						reject(e)
					})
		})

	const updateReport = (items, reportType, reportId, revision, hasThreadCompound, isInspection) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			let formattedItems
			!isInspection && (reportType === REPORT_TYPE.code.AGE_EXTRACTS || reportType === REPORT_TYPE.code.MILL_RECEIPT )
				? (formattedItems = formatReportData(items, reportType, false))
				: (formattedItems = formatPartReport(
						null,
						null,
						null,
						elementsByInsp,
						null,
						null,
						lengthUnit,
						weightUnit,
						false,
						hasThreadCompound,
						[],
						items
				  ))
			const payload = {
				items: formattedItems,
				responsible: `${user.firstName} ${user.lastName}`,
				remarks: remarks,
				revision: revision
			}
			reports
				.updateReport(warehouse.id, reportType, reportId, payload, isInspection, token)
				.then(response => {
					resolve(response)
					getReports(inspName)
				})
				.catch(e => {
					toast.error(`${errorDialogMessage} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	const rejectInspection = (contentID, revision) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			const payload = {
				responsible: `${user.firstName} ${user.lastName}`,
				remarks: remarks,
				revision: revision
			}
			reports
				.rejectInspection(warehouse.id, contentID, payload, token)
				.then(response => {
					resolve(response)
					getReports(inspName)
				})
				.catch(e => {
					toast.error(`${errorDialogMessage} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	const validateInspection = (contentID, revision) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			const payload = {
				responsible: `${user.firstName} ${user.lastName}`,
				remarks: remarks,
				revision: revision
			}
			reports
				.validateInspection(warehouse.id, contentID, payload, token)
				.then(response => {
					resolve(response)
					getReports(inspName)
				})
				.catch(e => {
					toast.error(`${errorDialogMessage} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	const reopenReport = (contentID, revision) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(warehouseNotDefined))
			}
			const payload = {
				responsible: `${user.firstName} ${user.lastName}`,
				remarks: remarks,
				revision: revision
			}
			reports
				.reopenInspection(warehouse.id, contentID, payload, token)
				.then(response => {
					resolve(response)
					getReports(inspName)
				})
				.catch(e => {
					toast.error(`${errorDialogMessage} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	useEffect(() => {
		selectedReport && getOldInspections(selectedReport.type)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedReport])

	useEffect(() => {
		const hydrate = async () => {
			try {
				oldInspection && oldInspection.id > 0 && getElementsByID()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [oldInspection])

	useEffect(() => {
		setInspSelected(null)
		const hydrate = async () => {
			try {
				await getInspections()
				await getElements()
				await getExtracts()
				await getEndUsers()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setTotalItems(null)
		setTotalPages(null)
		setCurrentPage(1)
		setFetchingFirstPage(false)
		setFetchingLastPage(false)
		setCanPreviousPage(false)
		setCanNextPage(false)
	}, [warehouse, inspType])

	useEffect(() => {
		setInspInfo({})
		setReportDetails(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspType, warehouse, inspSelected])

	useEffect(() => {
		setReviewReportsList(allAgeReports[reviewType])
		setSelectedRevReport(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reviewType])

	useEffect(() => {
		if ((selectedReport && elementsByInsp !== null) || selectedRow || selectedSubRow) {
			getReportDetails().catch(e => {
				setReportDetails(null)
				console.error(e)
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedReport, elementsByInsp, selectedRow, selectedSubRow])

	useEffect(() => {
		getMaterialsDescpritions()
			.then(list => setMaterials(list))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setInspReportList([])
	}, [warehouse, inspSelected])

	useEffect(() => {
		setSelectedReport(null)
		setFilteredReports([])
		setKeyWord('')
		setSelectedRow(null)
		setSelectedSubRow(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspType])

	useEffect(() => {
		setSelectedReport(null)
		setFilteredExportList([])
		setKeyWord('')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportType])

	useEffect(() => {
		if (keyWord === '' && inspName != null) {
			setFilteredReports([])
			setFilteredExportList([])
			getReports(inspName)
			setSelectedReport(null)
			setInspInfo({})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyWord, inspName, warehouse])

	return (
		<ReportsContext.Provider
			value={{
				inspType,
				setInspType,
				loadingReports,
				inspReportList,
				setSelectedReport,
				loadingDetails,
				reportDetails,
				downloadInspectionReport,
				downloadDailyReport,
				getDispatchReports,
				getOtherReports,
				getMoreOtherReports,
				getAgeManagementReports,
				getMillReceipts,
				reportType,
				setReportType,
				exportReportsList,
				downloadBackloadManifest,
				downloadDeliveryNote,
				downloadDeliveryNotePdf,
				downloadDispatchReport,
				downloadModifProductsReport,
				downloadAgeManagementReport,
				downloadMillReceipt,
				reviewType,
				setReviewType,
				reviewReportsList,
				selectedRevReport,
				setSelectedRevReport,
				isEditorOpen,
				setIsEditorOpen,
				extracts,
				setExtracts,
				selectedExtract,
				setSelectedExtract,
				endUsers,
				setEndUsers,
				getEndUsers,
				setReportDetails,
				downloadingReport,
				setDownloadingReport,
				inspections,
				setInspections,
				items,
				setItems,
				inspSelected,
				setInspSelected,
				getReports,
				elementsByInsp,
				setElementsByInsp,
				inspInfo,
				setInspInfo,
				reportHeader,
				setReportHeader,
				inspName,
				setInspName,
				type,
				setType,
				fetchingFirstPage,
				setFetchingFirstPage,
				fetchingLastPage,
				setFetchingLastPage,
				canPreviousPage,
				setCanPreviousPage,
				canNextPage,
				setCanNextPage,
				reportLinks,
				setReportLinks,
				totalItems,
				setTotalItems,
				totalPages,
				setTotalPages,
				currentPage,
				setCurrentPage,
				getMoreReports,
				getMoreDeliveryNotes,
				getDeliveryNotesTitleTransfer,
				downloadTitleTransferReport,
				downloadTitleTransferReportPdf,
				dialogData,
				setDialogData,
				updateReport,
				filteredReports,
				setFilteredReports,
				keyWord,
				setKeyWord,
				searchReports,
				filteredExportList,
				setFilteredExportList,
				selectedRow,
				setSelectedRow,
				instrumentList,
				setInstrumentList,
				selectedSubRow,
				setSelectedSubRow,
				partDetails,
				setPartDetails,
				loadingPartDetails,
				setLoadingPartDetails,
				rejectInspection,
				validateInspection,
				reopenReport,
				selectedReport,
				getReportDetails
			}}>
			{children}
		</ReportsContext.Provider>
	)
}
