/** @format */

import axios from 'axios'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getOwnerships: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`ownership/${wid}/description`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	addOwnershipValue: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`ownership/${wid}/value`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	deleteOwnershipValue: (wid, vid, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`ownership/${wid}/value/${vid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getLevels: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`ownership/${wid}/levels`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	createLevels: (wid, description, token, params = {}) =>
		new Promise((resolve, reject) => {
			instance
				.post(`ownership/${wid}/level/${encodeURIComponent(description)}`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	deleteLevel: (wid, levelId, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`ownership/${wid}/level/${levelId}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getLocalEndUsers: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`enduser/${wid}/local`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response.data.locallist)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getGlobalEndUsers: token =>
		new Promise((resolve, reject) => {
			instance
				.get('enduser/global', {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) resolve(response.data.globallist)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	addEndUserValue: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`enduser/${wid}/include`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	deleteEndUser: (wid, euid, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`enduser/${wid}/local/${euid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
