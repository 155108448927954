/** @format */

import { Button, Card, FlexView, Icon } from 'components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { consignmentType } from 'apps/wms/utils/enum'
import { Input, Select } from 'components/form'
import _ from 'lodash'
import { useContext } from 'react'
import { ActualDataContext } from 'apps/wms/stores/ActualDataModifStore'
import { WMSContext } from 'apps/wms/stores/WmsStore'
import { useState } from 'react'
import NewCertificateModal from './NewCertificateModal'
import ActualDataChangeModal from './ActualDataChangeModal'
import { useEffect } from 'react'

const ActualData = () => {
	const { t } = useTranslation()
	const { lengthUnit, weightUnit } = useContext(WMSContext)
	const {
		pipeData,
		setPipeData,
		certificates,
		setAddCertificate,
		certificateVersions,
		selectedPipes,
		newInfo,
		setNewInfo,
		setCertificateVersions,
		addCertificate,
		setOpenConfirmModal,
		openConfirmModal,
		selectedType
	} = useContext(ActualDataContext)
	const [dataHasChanged, setDataHasChanged] = useState(false)

	const selectConsignment = value => {
		if (pipeData) {
			setDataHasChanged(true)
			setPipeData({ ...pipeData, consignmenttype: value })
		} else if (selectedPipes && selectedPipes.length > 0) setNewInfo({ ...newInfo, consignmenttype: value })
	}

	const handleChangeHeat = value => {
		if (pipeData) {
			setPipeData({ ...pipeData, heat: value })
			setDataHasChanged(true)
		} else if (selectedPipes && selectedPipes.length > 0) setNewInfo({ ...newInfo, heat: value })
	}

	const handleChangeLength = value => {
		if (pipeData) {
			setPipeData({ ...pipeData, length: value })
			setDataHasChanged(true)
			setNewInfo({ ...newInfo, length: value })
		} else if (selectedPipes && selectedPipes.length > 0) setNewInfo({ ...newInfo, length: value })
	}

	const handleChangeWeight = value => {
		if (pipeData) {
			setPipeData({ ...pipeData, weight: value })
			setDataHasChanged(true)
			setNewInfo({ ...newInfo, weight: value })
		} else if (selectedPipes && selectedPipes.length > 0) setNewInfo({ ...newInfo, weight: value })
	}

	const handleChangeCertificate = value => {
		let cert = _.find(certificates, c => c.number === value)
		if (pipeData) {
			setPipeData({ ...pipeData, certificateref: value })
		} else if (selectedPipes && selectedPipes.length > 0) setNewInfo({ ...newInfo, certificateref: value })
		cert !== undefined && setCertificateVersions(cert.version)
	}

	const handleChangeNewVersion = value => {
		if (pipeData) {
			setPipeData({ ...pipeData, certificateversion: value })
			setDataHasChanged(true)
		} else if (selectedPipes && selectedPipes.length > 0) setNewInfo({ ...newInfo, certificateversion: value })
	}

	const confirmChanges = () => {
		setOpenConfirmModal(true)
	}

	const checkProperties = () => {
		let isDisabled = true
		isDisabled = dataHasChanged
			? isDisabled &&
			  pipeData &&
			  !pipeData.consignmenttype &&
			  !pipeData.heat &&
			  !pipeData.length &&
			  !pipeData.weight &&
			  !pipeData.certificateref &&
			  !pipeData.certificateversion
			: isDisabled &&
			  !newInfo.consignmenttype &&
			  !newInfo.heat &&
			  !newInfo.certificateref &&
			  !newInfo.certificateversion &&
			  !newInfo.length &&
			  !newInfo.weight
		return isDisabled
	}

	useEffect(() => {
		setDataHasChanged(false)
	}, [selectedType])

	return (
		<FlexView flexDirection='column' width='31%' height='100%' margin='0 0 0 8px'>
			<Card width='calc(100% - 48px)' height='100%' margin='8px 0 0 0' data-cy='card-actual-data'>
				<FlexView width='100%' fontSize='big' fontWeight='bold'>
					{t('wms:ActualData')}
				</FlexView>
				{pipeData || (selectedPipes && selectedPipes.length > 0) ? (
					<FlexView flexDirection='column' width='100%' height='100%'>
						{selectedPipes && selectedPipes.length > 0 && (
							<FlexView width='100%' fontSize='medium' margin='16px 0'>{`${selectedPipes.length} ${t(
								'wms:SelectedItems'
							)}`}</FlexView>
						)}
						<Select
							width='100%'
							label={t('wms:ConsignmentType')}
							placeholder={t('wms:ConsignmentType')}
							value={pipeData ? pipeData.consignmenttype : newInfo.consignmenttype}
							options={
								consignmentType &&
								_.map(consignmentType, opt => {
									return { value: opt, label: opt }
								})
							}
							onChange={v => selectConsignment(v)}
							data-cy='select-consignment-type'
						/>
						<Input
							type='text'
							width='100%'
							label={t('wms:HeatNo')}
							placeholder={t('wms:HeatNo')}
							value={pipeData ? pipeData.heat : newInfo.heat ? newInfo.heat : null}
							onChange={e => handleChangeHeat(e.target.value)}
							data-cy='input-heat-number'
						/>
						<FlexView flexDirection='row' width='100%'>
							<Input
								type='number'
								width='100%'
								label={`${t('wms:Length')} [${lengthUnit}]`}
								placeholder={t('wms:Length')}
								value={newInfo.length}
								onChange={e => handleChangeLength(Math.abs(e.target.value))}
								data-cy='input-length'
							/>
							<Input
								margin='8px 0 0 16px'
								type='number'
								width='100%'
								label={`${t('wms:Weight')} [${weightUnit}]`}
								placeholder={t('wms:Weight')}
								value={newInfo.weight}
								onChange={e => handleChangeWeight(Math.abs(e.target.value))}
								data-cy='input-weight'
							/>
						</FlexView>
						<FlexView flexDirection='row' width='100%' alignItems='center' justifyContent='space-between'>
							<Select
								width='80%'
								label={t('wms:CertificateNumber')}
								placeholder={t('wms:CertificateNumber')}
								value={pipeData ? pipeData.certificateref : newInfo.certificateref}
								options={
									certificates &&
									_.map(certificates, cert => {
										return { value: cert.number, label: cert.number }
									})
								}
								onChange={v => handleChangeCertificate(v)}
								searchable
								data-cy='select-certificate-number'
							/>
							<Button
								margin='16px 0 0 16px'
								backgroundColor='secondary'
								color='white'
								onClick={() => setAddCertificate(true)}
								data-cy='button-add-certificate'>
								<Icon
									name='add'
									color='white'
									width='14px'
									height='14px'
									tooltip={t('wms:CreateCertificateNumber')}
									tooltipPosition='top'
								/>
							</Button>
						</FlexView>
						<Select
							width='100%'
							label={t('wms:QcVersion')}
							placeholder={t('wms:QcVersion')}
							value={pipeData ? pipeData.certificateversion : newInfo.certificateversion}
							options={
								certificateVersions &&
								_.map(certificateVersions, c => {
									return { value: c, label: c }
								})
							}
							onChange={v => handleChangeNewVersion(v)}
							data-cy='select-certificate-version'
						/>
					</FlexView>
				) : (
					<FlexView
						width='100%'
						height='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						style={{ textAlign: 'center' }}
						data-cy='no-pipe-selected'>
						{t('wms:NoPipeSelected')}
					</FlexView>
				)}
				<Button
					backgroundColor='success'
					color='white'
					margin='auto auto 0 auto'
					onClick={() => confirmChanges()}
					disabled={checkProperties()}
					data-cy='button-change-data'>
					{t('wms:ChangePipeData')}
				</Button>
			</Card>
			<NewCertificateModal isOpen={addCertificate} onOutsideClick={() => setAddCertificate(false)} />
			<ActualDataChangeModal isOpen={openConfirmModal} onOutsideClick={() => setOpenConfirmModal(false)} />
		</FlexView>
	)
}

export default ActualData
