/** @format */

import _ from 'lodash'
import { INSPECTION_STAGES } from './enum'

const formatSummary = data => {
	if (!data.summary) return {}

	const { id, type, file } = data
	const {
		customer_request_reference,
		field_name,
		well_name,
		rig_name,
		tparty_name,
		tparty_location,
		delivery_location,
		ltpa_number,
		work_order_description
	} = data.summary
	return {
		id,
		type,
		file,
		date: data.expected_date,
		closuredate: data.closure_date,
		revision: data.plan_revision,
		responsible: data.plan_responsible,
		revision_date: data.plan_date,
		customer_request_reference,
		field_name,
		well_name,
		rig_name,
		tparty_name,
		tparty_location,
		delivery_location,
		ltpa_number,
		work_order_description
	}
}

const returnDataFilters = data => {
	return {
		customerorder: data.filters.customer_order,
		customeritem: data.filters.customer_item,
		salesorder: data.filters.sales_order,
		salesitem: data.filters.sales_item,
		material: data.filters.material,
		materialdescription: data.filters.material_description,
		materialsource: data.filters.material_source,
		materialsourcedescription: data.filters.material_source_description,
		level: data.filters.level,
		sourceownership: data.filters.source_ownership,
		sourceownershipname: data.filters.source_ownership_name,
		targetownership: data.filters.target_ownership,
		targetownershipname: data.filters.target_ownership_name,
		source_ownership: data.filters.source_ownership,
		source_ownership_name: data.filters.source_ownership_name,
		target_ownership: data.filters.target_ownership,
		target_ownership_name: data.filters.target_ownership_name,
		enduser: data.filters.enduser,
		endusername: data.filters.enduser_name
	}
}

const returnInfoFilters = info => {
	return {
		customerorder: info.filters.customer_order,
		customeritem: info.filters.customer_item,
		salesorder: info.filters.sales_order,
		salesitem: info.filters.sales_item,
		material: info.filters.material,
		materialdescription: info.filters.material_description,
		materialsource: info.filters.material_source,
		materialsourcedescription: info.filters.material_source_description,
		level: info.filters.level,
		sourceownership: info.filters.source_ownership,
		sourceownershipname: info.filters.source_ownership_name,
		targetownership: info.filters.target_ownership,
		targetownershipname: info.filters.target_ownership_name,
		source_ownership: info.filters.source_ownership,
		source_ownership_name: info.filters.source_ownership_name,
		target_ownership: info.filters.target_ownership,
		target_ownership_name: info.filters.target_ownership_name,
		enduser: info.filters.enduser,
		endusername: info.filters.enduser_name
	}
}

const formatInspInfo = (info, data) => {
	if (!(data || info)) return null

	if (data.filters)
		return {
			...returnDataFilters(data),
			entrance_date: data.entrance_date
		}
	else
		return {
			...returnInfoFilters(info),
			entrance_date: data.entrance_date
		}
}

const formatPeriodicInfo = data => ({
	id: data.id,
	customerorder: data.filters && data.filters.customer_order ? data.filters.customer_order : null,
	customeritem: data.filters && data.filters.customer_item ? data.filters.customer_item : null,
	salesorder: data.filters && data.filters.sales_order ? data.filters.sales_order : null,
	salesitem: data.filters && data.filters.sales_item ? data.filters.sales_item : null,
	entrancedate: data.periodic && data.periodic.entrance_date ? data.periodic.entrance_date : null,
	nextinsp: data.periodic && data.periodic.next_date ? data.periodic.next_date : null,
	lastinsp: data.periodic && data.periodic.last_date ? data.periodic.last_date : null
})

const formatInfo = (data, stage, isFilterOnContent, materialList) => {
	const formattedInfo = []
	const content = data.contents

	const periodicinfo = stage === INSPECTION_STAGES.code.PERIODICAL ? formatPeriodicInfo(data) : null

	const inspSummary = formatSummary(data)

	formattedInfo.push(
		...content
			.sort((a, b) => a.id - b.id)
			.map((ct, index) => ({
				...formatInspInfo(ct, data),
				materiallist: _.uniqBy(
					materialList.filter(mat => mat.contentid === ct.id),
					'mvid'
				),
				acceptedquantity: ct.accepted_quantity,
				quantity: ct.quantity,
				status: ct.execution_status,
				part: index + 1,
				ntquantity: ct.nt_quantity,
				id: ct.id
			}))
	)
	const inspInfo =
		content.length > 0
			? content
					.sort((a, b) => a.id - b.id)
					.map((info, index) => ({
						...formatInspInfo(info, data),
						acceptedquantity: info.accepted_quantity,
						quantity: info.quantity,
						status: info.execution_status,
						part: index + 1,
						ntquantity: info.nt_quantity,
						id: info.id
					}))
					.sort((a, b) => a.id - b.id)
			: { ...formatInspInfo(data) }

	return materialList.length
		? { formattedInfo, content, periodicinfo, inspSummary }
		: { inspInfo, content, periodicinfo, inspSummary }
}

export default function formatReport(data, stage, isFilterOnContent, materialList) {
	return formatInfo(data, stage, isFilterOnContent, materialList)
}
