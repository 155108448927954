/** @format */

import { AssetsLevelsContext } from 'apps/wms/stores/AssetsLevelsStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import Button from 'components/common/Button'
import Card from 'components/common/Card'
import FlexView from 'components/common/FlexView'
import Icon from 'components/common/Icon'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { Checkbox, Input } from 'components/form'
import _ from 'lodash'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import DataMatrix from '../../../assets/images/datamatrix.svg'
import ConfirmDeletionModal from './ConfirmDeletionModal'
import PrintModal from './PrintModal'
import TagModal from './TagModal'

const LevelList = () => {
	const { t } = useTranslation()
	const {
		loadingLevels,
		selectedLevels,
		setSelectedLevels,
		levelDescription,
		level,
		setLevel,
		levelArray,
		openTagModal,
		setOpenTagModal,
		openConfirmModal,
		setOpenConfirmModal,
		saveSelectedLevel,
		newLevels,
		setNewLevels,
		addNewLevel,
		openPrintModal,
		setOpenPrintModal
	} = useContext(AssetsLevelsContext)

	const [parentLevelName, setParentLevelName] = useState(null)
	const [subLevel, setSubLevel] = useState(null)
	const [selected, setSelected] = useState(null)
	const [levelPrint, setLevelPrint] = useState(null)

	const listDeadSpace = 80
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	const descriptionStr = t('wms:Description')
	const inventoryFreqStr = t('wms:InventoryFreq')

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	const printAllLevels = levels => {
		setLevelPrint(levels)
		setOpenPrintModal(true)
	}

	const selectLevel = (level, selected) => {
		selectedLevels[`level${level}`] = selected
		for (var i = level + 1; i <= 6; i++) {
			selectedLevels[`level${i}`] = null
		}
		setSelectedLevels({ ...selectedLevels })
	}

	const changeDescription = (value, lv, level) => {
		level.name = value
		selectedLevels[`level${lv + 1}`] = level
		setSelectedLevels({ ...selectedLevels })
	}

	const changeInvFrequency = (value, lv, level) => {
		level.inventoryfreqindays = value
		selectedLevels[`level${lv + 1}`] = level
		setSelectedLevels({ ...selectedLevels })
	}

	const checkEntrance = (value, lv, level) => {
		level.isentrance = value
		selectedLevels[`level${lv + 1}`] = level
		setSelectedLevels({ ...selectedLevels })
	}

	const deleteLevel = (lv, level) => {
		setSubLevel(lv)
		setSelected(level)
		setOpenConfirmModal(true)
	}

	const saveLevel = (lv, level) => {
		saveSelectedLevel(lv, level)
	}

	const openModal = (levelName, l) => {
		setParentLevelName(levelName)
		setLevel(l)
		setOpenTagModal(true)
	}

	const printLevel = level => {
		setLevelPrint([level])
		setOpenPrintModal(true)
	}

	const changeNewDescription = (value, level) => {
		newLevels[`level${level}`].name = value
		setNewLevels({ ...newLevels })
	}

	const changeInventoryFrequency = (value, level) => {
		newLevels[`level${level}`].inventoryfreqindays = value
		setNewLevels({ ...newLevels })
	}

	const checkNewEntrance = (value, level) => {
		newLevels[`level${level}`].isentrance = value
		setNewLevels({ ...newLevels })
	}

	const addLevel = level => {
		addNewLevel(level)
		for (var i = 1; i <= 6; i++) {
			newLevels[`level${i}`].name = ''
			newLevels[`level${i}`].isentrance = false
			newLevels[`level${i}`].inventoryfreqindays = 30
		}
		setNewLevels({ ...newLevels })
		setSelectedLevels({ ...selectedLevels })
	}

	const checkProperties = level => {
		let isDisabled = true
		isDisabled = isDisabled && !newLevels[`level${level}`].name

		return isDisabled
	}

	return (
		<Card width='100%' ref={listRef} flex='1' margin='0' data-cy='card-level-list'>
			<LoadingOverlay visible={loadingLevels} borderRadius='card' />
			<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				{levelArray &&
					_.map(levelArray, (lv, index) => (
						<FlexView
							key={index}
							flexDirection='column'
							width='100%'
							alignItems='stretch'
							data-cy='flexview-levels'>
							<FlexView flexDirection='row' width='100%' margin='16px 0 0 16px'>
								<FlexView key={index} fontSize='subtitle' fontWeight='bold' style={{ alignSelf: 'center' }}>
									{selectedLevels[`level${lv}`] ? `${levelDescription[lv]}:` : null}
								</FlexView>
								{lv > 0 ? (
									<FlexView
										fontSize='subtitle'
										fontWeight='bold'
										margin='0 0 0 8px'
										style={{ alignSelf: 'center' }}>
										{selectedLevels[`level${lv}`] && selectedLevels[`level${lv}`].name}
									</FlexView>
								) : null}
								{selectedLevels[`level${lv}`] && lv + 1 === levelArray.length ? (
									<Button
										margin='0 0 0 16px'
										backgroundColor='secondary'
										color='white'
										onClick={() => printAllLevels(selectedLevels[`level${lv}`][`levels${lv + 1}`])}
										disabled={!selectedLevels[`level${lv}`][`levels${lv + 1}`].length}
										data-cy='button-print-all-levels'>
										{t('wms:Print')}
										<Icon name='print' color='white' width='14px' height='14px' margin='0 0 0 8px' />
									</Button>
								) : null}
							</FlexView>
							<FlexView
								flexDirection='row'
								width='100%'
								flexWrap='wrap'
								margin='16px 0 0 0'
								alignItems='stretch'>
								{selectedLevels[`level${lv}`] &&
									_.map(selectedLevels[`level${lv}`][`levels${lv + 1}`], (l, index) => (
										<FlexView key={index} flexDirection='row' alignItems='stretch'>
											<Card onClick={() => selectLevel(lv + 1, l)} margin='16px'>
												<FlexView flexDirection='row' width='100%'>
													<FlexView width='100%' fontSize='medium' fontWeight='bold'>
														{levelDescription[lv]} {index}
													</FlexView>
													{lv + 1 === levelArray.length ? (
														<Button
															margin='0'
															padding='0'
															backgroundColor='white'
															onClick={() => openModal(selectedLevels[`level${lv}`].name, l)}
															data-cy='button-open-datamatrix'>
															<img src={DataMatrix} alt='DataMatrix' style={{ width: '24px' }}></img>
														</Button>
													) : null}
												</FlexView>
												<Input
													margin='16px 0 0 0'
													type='text'
													label={descriptionStr}
													placeholder={descriptionStr}
													value={l.name}
													onChange={e => changeDescription(e.target.value, lv, l)}
													data-cy='input-level-description'
												/>
												{lv + 1 === levelArray.length ? (
													<Input
														margin='16px 0 0 0'
														type='number'
														label={inventoryFreqStr}
														placeholder={inventoryFreqStr}
														value={l.inventoryfreqindays}
														onChange={e => changeInvFrequency(Math.abs(e.target.value), lv, l)}
														data-cy='input-level-inv-frequency'
													/>
												) : null}
												{lv + 1 === levelArray.length ? (
													<Checkbox
														margin='16px 0 16px 0'
														label={t('wms:CheckEntrance')}
														checked={l.isentrance}
														onChange={v => checkEntrance(v, lv, l)}
													/>
												) : null}
												<FlexView flexDirection='column' width='100%' margin='auto 0 0 auto'>
													<FlexView flexDirection='row' width='100%' justifyContent='space-between'>
														<Button
															margin='0'
															backgroundColor='error'
															color='white'
															onClick={() => deleteLevel(lv + 1, l)}
															data-cy='button-delete-level'>
															{t('wms:Delete')}
															<Icon
																name='trash'
																color='white'
																width='14px'
																height='14px'
																margin='0 0 0 8px'
															/>
														</Button>
														<Button
															margin='0'
															backgroundColor='success'
															color='white'
															onClick={() => saveLevel(lv + 1, l)}>
															{t('wms:Save')}
															<Icon
																name='save'
																color='white'
																width='14px'
																height='14px'
																margin='0 0 0 8px'
															/>
														</Button>
													</FlexView>
													{lv + 1 === levelArray.length ? (
														<Button
															margin='16px auto auto auto'
															backgroundColor='secondary'
															color='white'
															onClick={() => printLevel(l)}
															data-cy='button-print-level'>
															{t('wms:Print')}
															<Icon
																name='print'
																color='white'
																width='18px'
																height='18px'
																margin='0 0 0 8px'
															/>
														</Button>
													) : null}
												</FlexView>
											</Card>
										</FlexView>
									))}
								{selectedLevels[`level${lv}`] && (
									<Card data-cy='card-new-levels'>
										<FlexView width='100%' fontSize='medium' fontWeight='bold'>
											{levelDescription[lv]} {index + 1}
										</FlexView>
										<Input
											margin='16px 0 0 0'
											type='text'
											label={descriptionStr}
											placeholder={descriptionStr}
											value={newLevels[`level${lv + 1}`].name}
											onChange={e => changeNewDescription(e.target.value, lv + 1)}
											data-cy='input-new-description'
										/>
										{lv + 1 === levelArray.length ? (
											<Input
												margin='16px 0 0 0'
												type='number'
												label={inventoryFreqStr}
												placeholder={inventoryFreqStr}
												value={newLevels[`level${lv + 1}`].inventoryfreqindays}
												onChange={e => changeInventoryFrequency(Math.abs(e.target.value), lv + 1)}
												data-cy='input-new-inventory-freq'
											/>
										) : null}
										{lv + 1 === levelArray.length ? (
											<Checkbox
												margin='16px 0 16px 0'
												label={t('wms:CheckEntrance')}
												checked={newLevels[`level${lv + 1}`].isentrance}
												onChange={v => checkNewEntrance(v, lv + 1)}
											/>
										) : null}
										<Button
											margin='auto 0 0 auto'
											backgroundColor='success'
											color='white'
											onClick={() => addLevel(lv + 1)}
											style={{ alignSelf: 'flex-end' }}
											disabled={checkProperties(lv + 1)}
											data-cy='button-add-level'>
											{t('wms:Add')}
											<Icon name='add' color='white' width='14px' height='14px' margin='0 0 0 8px' />
										</Button>
									</Card>
								)}
							</FlexView>
						</FlexView>
					))}
			</FlexView>
			<TagModal
				isOpen={openTagModal}
				onOutsideClick={() => setOpenTagModal(false)}
				parentlevelname={parentLevelName}
				level={level}
			/>
			<ConfirmDeletionModal
				isOpen={openConfirmModal}
				level={subLevel}
				selected={selected}
				onOutsideClick={() => setOpenConfirmModal(false)}
			/>
			<PrintModal level={levelPrint} isOpen={openPrintModal} onOutsideClick={() => setOpenPrintModal(false)} />
		</Card>
	)
}

export default LevelList
