/** @format */

import { AssetsReportRefContext } from 'apps/wms/stores/AssetsReportRefStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { FlexView } from 'components/common'
import Card from 'components/common/Card'
import React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'utils/theme'

const ReportsInfo = () => {
	const { t, i18n } = useTranslation()
	const { selectedReport } = useContext(AssetsReportRefContext)

	return (
		<Card
			width='calc(100% - 48px)'
			flex='1'
			margin='0'
			padding='24px'
			style={{ position: 'relative' }}
			data-cy='card-report-info'>
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 32px 0'>
				{t('wms:ReportInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:Reference')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedReport.reportreference ? selectedReport.reportreference : '****'}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:Responsible')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedReport.responsible ? selectedReport.responsible : '****'}
					</FlexView>
				</FlexView>
				<FlexView width='50%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0' data-cy='list-label'>
						{t('wms:CreationDate')}
					</FlexView>
					<FlexView
						width='100%'
						style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
						padding='0 0 0 8px'>
						{selectedReport.created ? DATE_FORMAT(selectedReport.created, i18n.language) : '****'}
					</FlexView>
				</FlexView>
			</FlexView>
		</Card>
	)
}

export default ReportsInfo
