/** @format */

import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import { UserContext } from "stores/UserStore"
import { AppContext } from "stores/AppStore"
import Header from "components/layout/Header"
import AppIcon from "assets/icons/app-logo.svg"
import { getSideMenuSettings } from "apps"

const verifyUserRoles = (sideMenuSettings, userRoles) => {
  const applicationID = process.env.REACT_APP_ID
  const roles = userRoles[applicationID]
  if (!roles) return
  else {
    const userHasRole = optionRoles =>
    !optionRoles ||
    _.intersection(Object.keys(roles), optionRoles).length
    
    return _.chain(sideMenuSettings)
      .filter(({ roles }) => userHasRole(roles || []))
      .map((option) => ({
        ...option,
        items:
          option.items &&
          _.filter(option.items, ({ roles }) => userHasRole(roles)),
      }))
      .value()
  }
}

const AppHeader = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { pageTitle, isHeaderVisible } = useContext(AppContext)
  const { user, authState, signOut } = useContext(UserContext)

  const redirectTo = (path) => () => {
    history.push(path)
  }

  const sideMenuSettings = {
    appLogoSrc: AppIcon,
    itemGroups: user &&
      user.roles && [
        ...verifyUserRoles(
          getSideMenuSettings(user, redirectTo, t),
          user.roles || [], user
        ),
      ]
  }

  const userSettings = {
    username: user ? `${user.firstName} ${user.lastName}` : "",
    options: [
      {
        key: "sign-out",
        label: t("Sign Out"),
        onClick: signOut,
      },
    ],
  }

  return isHeaderVisible && authState === "SIGNED_IN" ? (
    <Header
      title={pageTitle}
      appIconSrc={AppIcon}
      onAppIconClick={redirectTo("/")}
      sideMenuSettings={sideMenuSettings}
      userSettings={userSettings}
    />
  ) : null
}

export default AppHeader
