/** @format */

import _ from 'lodash'
import { REPORT_STATUS } from '../enum'

const returnSummaryInfo = summary => {
	if (!summary) return {}
	else
		return {
			workorderdescription: summary.work_order_description,
			customerrequestreference: summary.customer_request_reference
		}
}

const returnFiltersInfo = filters => {
	if (!filters) return {}
	else
		return {
			erpreference: filters.erp_reference,
			customerorder: filters.customer_order,
			customeritem: filters.customer_item,
			salesorder: filters.sales_order,
			salesitem: filters.sales_item
		}
}

const formatSubRows = general => {
	if (general.contents.length === 0) return []
	else
		return general.contents.map(content => ({
			id: general.id,
			partid: content.id,
			status:
				content.validation_data && content.validation_data.status
					? content.validation_data.status
					: (general.validation_data && general.validation_data.status) || null,
			part: content.part_number,
			closuredate:
				content.validation_data &&
				content.validation_data.last_status_change &&
				content.validation_data.last_status_change !== '0001-01-01T00:00:00'
					? content.validation_data.last_status_change
					: null,
			quantitylabel: `${
				content.validation_data?.status === REPORT_STATUS.code.REJECTED ? 0 : content.accepted_quantity
			}/${content.quantity}`
		}))
}

const formatDnSubRows = general => {
	if (general.contents.length === 0) return []
	else {
		return general.contents.map(content => {
			if (
				!(
					content.validation_data &&
					content.validation_data.last_status_change &&
					content.validation_data.last_status_change === '0001-01-01T00:00:00' &&
					content.filters &&
					content.filters.source_ownership &&
					content.validation_data.status
				)
			) {
				return {
					id: general.id,
					partid: content.id,
					part: content.part_number,
					closuredate: null,
					status: null,
					ownershipid: null,
					ownershipname: null
				}
			} else
				return {
					id: general.id,
					partid: content.id,
					part: content.part_number,
					closuredate: content.validation_data.last_status_change,
					status: content.validation_data.status,
					ownershipid: content.filters.source_ownership,
					ownershipname: content.filters.source_ownership_name
				}
		})
	}
}

const formatGeneralInfo = (info, isDeliveryNotes) => {
	const generalInformation = info
		.map(general => {
			return {
				listid: general.id,
				...returnFiltersInfo(general.filters),
				...returnSummaryInfo(general.summary),
				closuredate: general.closure_date,
				type: general.type,
				status: general.overall_validation_status,
				quantity: _.sumBy(general.contents, 'accepted_quantity'),
				maxqty: _.sumBy(general.contents, 'quantity'),
				quantitylabel: `${_.chain(general.contents)
					.filter(f => !(f.validation_data?.status === REPORT_STATUS.code.REJECTED))
					.sumBy('accepted_quantity')
					.value()}/${_.sumBy(general.contents, 'quantity')}`,
				subRows: formatSubRows(general),
				children: formatSubRows(general)
			}
		})
		.sort((a, b) => b.id - a.id)

	const deliveryNotesInfo = info
		.map(general => {
			if (!(general.summary || general.filters)) return {}
			return {
				listid: general.id,
				...returnFiltersInfo(general.filters),
				...returnSummaryInfo(general.summary),
				closuredate: general.closure_date || null,
				status: general.overall_validation_status,
				subRows: formatDnSubRows(general),
				children: formatDnSubRows(general),
				validation_data: general.validation_data
			}
		})
		.sort((a, b) => b.id - a.id)
	return isDeliveryNotes ? deliveryNotesInfo : generalInformation
}

export default function generalInfoFormatter(info, isDeliveryNotes) {
	return formatGeneralInfo(info, isDeliveryNotes)
}
