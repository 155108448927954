/** @format */

import { _ } from 'globalthis/implementation'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { UserContext } from 'stores/UserStore'
import ageManagement from '../api/ageManagement'
import { WMSContext } from './WmsStore'

export const AgeManagementContext = React.createContext()

export const AgeManagementProvider = ({ children }) => {
	const { token } = useContext(UserContext)
	const { t } = useTranslation()
	const { warehouse } = useContext(WMSContext)
	const [extracts, setExtracts] = useState(null)
	const [ageMgmtType, setAgeMgmtType] = useState(null)

	const getAgeMgmtListData = (ageMgmtType, enduser, date, ltpa) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error('wms:WarehouseNotDefined'))
			}
			const payload = {
				extractid: ageMgmtType,
				enduserid: enduser.id,
				enduser: enduser.name,
				triggerdate: date,
				ltpa: ltpa
			}
			ageManagement
				.getAgeManagementListData(warehouse.id, payload, token)
				.then(response => {
					response.list.length === 0 && toast.info(t(`wms:NoMatch`))
					resolve(response)
				})
				.catch(({ err, lastListPending }) => {
					lastListPending
						? toast.error(
								`${t('wms:ValidationPendingMessage1')} ${
									_.find(extracts, ex => ex.extractid === ageMgmtType).extractdesc
								}. ${t('wms:ValidationPendingMessage2')}`
						  )
						: toast.error(
								`${t('wms:ErrorGettingAgeManagementList')} [ ${err.response.status}]: ${err.response.data}`
						  )
					reject(err)
				})
		})

	const createAgeManagementList = (ageMgmtType, enduser, date, ltpa) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error('wms:WarehouseNotDefined'))
			}
			const payload = {
				extractid: ageMgmtType,
				enduserid: enduser.id,
				enduser: enduser.name,
				triggerdate: date,
				ltpa: ltpa
			}
			ageManagement
				.createAgeManagementList(warehouse.id, payload, token)
				.then(response => resolve(response))
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingAgeManagementList')} [${e.status}]: ${e.data}`)
					reject(e)
				})
		})

	const getExtracts = () =>
		new Promise((resolve, reject) => {
			ageManagement
				.getExtracts(warehouse.id, token)
				.then(response => {
					setExtracts(response)
					resolve()
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingExtracts')} [ ${e.status} ]: ${e.data}`)
					console.error(e)
					reject(e)
				})
		})

	const getEnduserList = () =>
		new Promise((resolve, reject) => {
			ageManagement
				.getEndUsers(warehouse.id, token)
				.then(response => {
					resolve(response.enduserlist)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingEndUser')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getExtracts()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	return (
		<AgeManagementContext.Provider
			value={{
				getAgeMgmtListData,
				createAgeManagementList,
				extracts,
				setExtracts,
				ageMgmtType,
				setAgeMgmtType,
				getEnduserList
			}}>
			{children}
		</AgeManagementContext.Provider>
	)
}
