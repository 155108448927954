/** @format */
/* eslint-disable react/prop-types */

import { Card, FlexView, LoadingOverlay } from 'components/common'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getReportColumns } from './reportColumns'
import {
	changeReportData,
	shouldConfirmInspectionItemModification,
	changeReportInspectionData,
	isReportFinalStatusConsistent,
	shouldConfirmRejectReasonModification,
	changeReportRejectReason,
	changeReportMeasureData,
	changeWeightData,
	changeReportPhoto,
	changeInstrumentsData
} from './businessRules'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import AlertModal from 'apps/wms/components/forms/Alert'
import EnhancedTable from 'apps/wms/components/commom/EnhancedTable'
import ReportImageGallery from './reportGallery'
import InstrumentEditor from './instrumentEditor'
import { WMSContext } from 'apps/wms/stores/WmsStore'
import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { changeManufacturerData } from './businessRules'
import { changeBatchNumberData } from './businessRules'
import { changeApplicationMethodData } from './businessRules'
import _ from 'lodash'

const ReportTable = ({
	entranceDateList,
	threadColumns,
	ageColumns,
	inspSelected,
	items,
	hasMeasure,
	hasThreadCompound,
	reportType,
	reportItems,
	elements,
	reportMeasurements,
	rejectReasons,
	allowEdit,
	loading,
	supervisor,
	onDataChange,
	onAllDataChange,
	height,
	zoom,
	manufacturer,
	applicationMethodList,
	isInspection
}) => {
	const { t, i18n } = useTranslation()
	const {
		lengthUnit,
		weightUnit,
		convertToCurrentLengthUnit,
		convertToCurrentWeightUnit,
		diameterUnit,
		convertToCurrentDiameterUnit
	} = useContext(WMSContext)
	const [confirmItemStatusChange, setConfirmItemStatusChange] = useState(false)
	const [confirmRejectReasonChange, setConfirmRejectReasonChange] = useState(false)
	const [confirmUpdateAllReportsEquipments, setConfirmUpdateAllReportsEquipments] = useState(false)
	const [consistencyStatusWarn, setConsistencyStatusWarn] = useState(false)
	const [dialogData, setDialogData] = useState(null)
	const [isReportGalleryOpen, setIsReportGalleryOpen] = useState(false)
	const [isInstrumentEditorOpen, setIsInstrumentEditorOpen] = useState(false)
	const [currentReportItem, setCurrentReportItem] = useState(null)

	const onReportChange = useCallback(
		(itemAcessor, value, reportRow) => {
			const change = {
				...reportRow.original,
				[itemAcessor]: value
			}
			const report = changeReportData(change, supervisor)
			onDataChange(report)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onDataChange]
	)

	const storeDialogData = useCallback((item, value, report) => {
		setDialogData({
			item: item,
			value: value,
			report: report
		})
	}, [])

	const changeFinalStatus = useCallback(
		(itemAcessor, value, reportRow) => {
			const change = {
				...reportRow.original,
				finalreport: value
			}
			const report = changeReportData(change, supervisor)
			onDataChange(report)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onDataChange]
	)

	const onFinalStatusChange = useCallback(
		(itemAcessor, value, reportRow) => {
			let elementsName = _.map(elements, el => ({
				...el,
				name: el.name.replace(/ +/g, '').toLowerCase()
			}))
			const change = {
				...reportRow.original,
				finalreport: value
			}
			isReportFinalStatusConsistent(change, itemAcessor, elementsName)
				? changeFinalStatus(itemAcessor, value, reportRow)
				: setConsistencyStatusWarn(true)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[reportType, changeFinalStatus]
	)

	const changeInspectionItem = useCallback(
		(itemCode, value, reportRow, itemDefect, itemPhoto) => {
			reportRow.original[itemCode] = value
			const change = reportRow.original
			const report = changeReportInspectionData(change, itemCode, itemDefect, itemPhoto, supervisor)
			onDataChange(report)
			return report
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[reportType, onDataChange]
	)

	const onInspectionItemChange = useCallback(
		(value, reportRow, itemCode, itemDefect, itemPhoto) => {
			storeDialogData(itemCode, value, reportRow)
			shouldConfirmInspectionItemModification(reportRow.original[itemCode])
				? setConfirmItemStatusChange(true)
				: changeInspectionItem(itemCode, value, reportRow, itemDefect, itemPhoto)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[changeInspectionItem]
	)

	const changeRejectReason = useCallback(
		(value, reportRow, itemDefect, itemPhoto) => {
			reportRow.original[itemDefect] = value
			const change = reportRow.original
			const report = changeReportRejectReason(change, itemPhoto, supervisor)
			onDataChange(report)
			return report
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onDataChange]
	)

	const onRejectReasonChange = useCallback(
		(value, reportRow, itemCode, itemDefect, itemPhoto) => {
			storeDialogData(itemCode, value, reportRow)
			shouldConfirmRejectReasonModification(reportRow.original[itemDefect])
				? setConfirmRejectReasonChange(true)
				: changeRejectReason(value, reportRow, itemDefect, itemPhoto)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[changeRejectReason]
	)

	const onManufacturerChange = useCallback(
		(itemAcessor, value, reportRow) => {
			const change = {
				...reportRow.original,
				[itemAcessor]: value
			}
			const report = changeManufacturerData(change, supervisor, value)
			onDataChange(report)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[reportType, onDataChange]
	)

	const onBatchNumberChange = useCallback(
		(itemAcessor, value, reportRow) => {
			const change = {
				...reportRow.original,
				[itemAcessor]: value
			}

			const report = changeBatchNumberData(change, supervisor, value)
			onDataChange(report)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[reportType, onDataChange]
	)

	const onApplicationMethodChange = useCallback(
		(itemAcessor, value, reportRow) => {
			const change = {
				...reportRow.original,
				[itemAcessor]: value
			}

			const report = changeApplicationMethodData(change, supervisor, value)
			onDataChange(report)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[reportType, onDataChange]
	)

	const onWeightChange = useCallback(
		(itemAcessor, value, reportRow) => {
			const change = {
				...reportRow.original,
				[itemAcessor]: value
			}
			const report = changeWeightData(change, supervisor)
			onDataChange(report)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[reportType, onDataChange]
	)

	const onLengthChange = useCallback(
		(itemAcessor, value, reportRow) => {
			const change = {
				...reportRow.original,
				[itemAcessor]: value
			}
			const report = changeReportMeasureData(change, supervisor)
			onDataChange(report)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[reportType, onDataChange]
	)

	const handleInstrumentCaseSelector = useCallback(reportRow => {
		setIsInstrumentEditorOpen(true)
		setCurrentReportItem(reportRow.original)
	}, [])

	const onInstrumentEditorClose = useCallback(() => {
		setIsInstrumentEditorOpen(false)
		setCurrentReportItem(null)
	}, [])

	const changeReportInstrumentData = useCallback(
		(reportRow, newCaseId, instrumentList) => {
			const report = changeInstrumentsData(reportRow, newCaseId, instrumentList, supervisor)
			onDataChange(report)
			return report
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onDataChange]
	)

	const onInstrumentChange = useCallback(
		(reportRow, newCaseId, instrumentList) => {
			const report = changeReportInstrumentData(reportRow, newCaseId, instrumentList, supervisor)
			storeDialogData(newCaseId, instrumentList, reportRow)
			setConfirmUpdateAllReportsEquipments(true)
			onInstrumentEditorClose()
			return report
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[changeReportInstrumentData]
	)

	const changeAllInstrumentCases = useCallback(
		(newCaseId, instrumentList) => {
			const modif = reportItems.map(ri => (ri = changeInstrumentsData(ri, newCaseId, instrumentList, supervisor)))
			onAllDataChange(modif)
			return true
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onDataChange]
	)

	const handlePhotoSelector = useCallback(reportRow => {
		setIsReportGalleryOpen(true)
		setCurrentReportItem(reportRow.original)
	}, [])

	const onImageChange = useCallback(
		(itemCode, value, reportRow) => {
			const change = reportRow
			const report = changeReportPhoto(change, itemCode, value, supervisor)
			onDataChange(report)
			setCurrentReportItem(report)
			return report
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onDataChange]
	)

	const onGalleryClose = useCallback(() => {
		setIsReportGalleryOpen(false)
		setCurrentReportItem(null)
	}, [])

	const config = {
		entranceDateList,
		hasMeasure,
		hasThreadCompound,
		elements,
		threadColumns,
		items,
		reportMeasurements,
		inspSelected,
		reportType,
		rejectReasons,
		allowEdit,
		t,
		i18n,
		DATE_FORMAT,
		convertToCurrentLengthUnit,
		lengthUnit,
		convertToCurrentWeightUnit,
		weightUnit,
		ageColumns,
		manufacturer,
		applicationMethodList,
		onReportChange,
		onFinalStatusChange,
		onInspectionItemChange,
		onRejectReasonChange,
		onManufacturerChange,
		onBatchNumberChange,
		onApplicationMethodChange,
		onWeightChange,
		onLengthChange,
		handlePhotoSelector,
		handleInstrumentCaseSelector,
		diameterUnit,
		convertToCurrentDiameterUnit,
		isInspection
	}

	const reportColumns = useMemo(() => getReportColumns(config), [config])

	return (
		<Card
			data-cy='card-list-wrapper'
			width='calc(100% - 16px)'
			flex='1'
			margin='0'
			padding='8px'
			borderRadius='4px'
			style={{ position: 'relative' }}>
			<LoadingOverlay visible={loading} borderRadius='4px' />
			{reportItems && reportItems.length ? (
				<EnhancedTable
					margin='0'
					padding='0'
					width='calc(100%)'
					height={`${height}px`}
					textAlign='center'
					elevation='none'
					fontSize='12px'
					columns={reportColumns}
					data={reportItems}
					sortable
					whiteSpace='break-spaces'
					stickyHeader={true}
					zoom={zoom}
					paginated
				/>
			) : (
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='24px'
					color='lightGray'
					margin='auto 0'
					alignItems='center'
					justifyContent='center'
					data-cy='nolist-placeholder'>
					{t('wms:EmptyList')}
				</FlexView>
			)}
			<DialogModal
				isOpen={confirmItemStatusChange}
				title={t(`wms:ConfirmModifyItemStatus`)}
				text={t(`wms:ConfirmModifyItemStatusText`)}
				onConfirm={() =>
					changeInspectionItem(dialogData.item, dialogData.value, dialogData.report) &&
					setConfirmItemStatusChange(false)
				}
				onCancel={() => setConfirmItemStatusChange(false)}
			/>
			<DialogModal
				isOpen={confirmRejectReasonChange}
				title={t(`wms:ConfirmModifyDefect`)}
				text={t(`wms:ConfirmModifyDefectText`)}
				onConfirm={() =>
					changeRejectReason(dialogData.item, dialogData.value, dialogData.report) &&
					setConfirmItemStatusChange(false)
				}
				onCancel={() => setConfirmRejectReasonChange(false)}
			/>
			<DialogModal
				isOpen={confirmUpdateAllReportsEquipments}
				title={t(`wms:ConfirmAllEquipmentsUpdate`)}
				text=''
				confirmText={t(`wms:UpdateAllPipes`)}
				onConfirm={() =>
					changeAllInstrumentCases(dialogData.item, dialogData.value, dialogData.report) &&
					setConfirmUpdateAllReportsEquipments(false)
				}
				onCancel={() => setConfirmUpdateAllReportsEquipments(false)}
			/>
			<AlertModal
				isOpen={consistencyStatusWarn}
				title={`${t('wms:FinalStatusError')} `}
				text={t(`wms:FinalStatusErrorText`)}
				confirmText='Ok'
				onConfirm={() => setConsistencyStatusWarn(false)}
			/>
			{currentReportItem !== null ? (
				<ReportImageGallery
					isOpen={isReportGalleryOpen}
					onOutsideClick={onGalleryClose}
					onImageChange={onImageChange}
					report={currentReportItem}
					allowEdit={allowEdit}
					rejectReasons={rejectReasons}
					reportType={reportType}
					elements={elements}
				/>
			) : null}
			{currentReportItem !== null ? (
				<InstrumentEditor
					isOpen={isInstrumentEditorOpen}
					onOutsideClick={onInstrumentEditorClose}
					report={currentReportItem}
					allowEdit={allowEdit}
					onInstrumentChange={onInstrumentChange}
				/>
			) : null}
		</Card>
	)
}

export default ReportTable
