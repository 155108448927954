/** @format */

import { AssetsAgeMgmtContext } from 'apps/wms/stores/AssetsAgeMgmtStore'
import { consignmentType } from 'apps/wms/utils/enum'
import { Button, Card, FlexView, Icon, Modal } from 'components/common'
import { Input, MultiSelect } from 'components/form'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

// eslint-disable-next-line react/prop-types
const UpdateExtractModal = ({ isOpen, onOutsideClick }) => {
	const { t } = useTranslation()
	const { updateExtract, setUpdateExtract, levels, productList, erpList, setSave, setIsNew } =
		useContext(AssetsAgeMgmtContext)

	useEffect(() => {
		if (updateExtract) {
			checkProperties()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateExtract])

	const checkProperties = () => {
		let isDisabled = true
		isDisabled = isDisabled && (updateExtract.extractdesc === '' || updateExtract.periodthreshold === '')
		return isDisabled
	}

	const saveExtract = () => {
		setSave(true)
		setIsNew(false)
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='50%' height='85%' maxHeight='85%' data-cy='card-update-extract' style={{ position: 'relative' }}>
				<FlexView width='100%' height='100%'>
					<FlexView
						width='100%'
						fontSize='big'
						flexDirection='row'
						fontWeight='bold'
						margin='0 0 8px 0'
						justifyContent='space-between'>
						{t('wms:UpdateExtract')}
						<Button fontSize='tiny' margin='0' backgroundColor='error' disabled={false} onClick={onOutsideClick}>
							<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
						</Button>
					</FlexView>
					<Card width='calc(100% - 48px)' margin='16px 0' data-cy='card-input-settings'>
						<FlexView width='100%' flexDirection='row' justifyContent='space-around'>
							<Input
								type='text'
								margin='0'
								placeholder={t('wms:ExtractName')}
								label={t('wms:ExtractName')}
								value={updateExtract && updateExtract.extractdesc}
								onChange={e => setUpdateExtract({ ...updateExtract, extractdesc: e.target.value })}
								data-cy='input-extract-name'
							/>
							<Input
								type='number'
								margin='0'
								min='1'
								max='999'
								placeholder={`${t('wms:Period')} [${t('wms:Months')}]`}
								label={`${t('wms:Period')} [${t('wms:Months')}]`}
								value={updateExtract && updateExtract.periodthreshold}
								onChange={e =>
									setUpdateExtract({ ...updateExtract, periodthreshold: Math.abs(e.target.value) })
								}
								data-cy='input-extract-period'
							/>
						</FlexView>
					</Card>
					<FlexView
						margin='0 0 16px 0'
						width='100%'
						flexDirection='column'
						height='100%'
						maxHeight='100%'
						style={{ overflowY: 'auto' }}>
						<FlexView
							flexDirection='row'
							width='99%'
							height='100%'
							justifyContent='space-between'
							padding='0 0 0 4px'
							alignItems='stretch'>
							<Card width='calc(100% - 48px)' margin='16px 16px 0 0' data-cy='card-select-consignment-type'>
								<MultiSelect
									width='100%'
									selectedValues={updateExtract && updateExtract.consignmenttypefilters}
									label={t('wms:ConsignmentType')}
									options={
										consignmentType &&
										_.map(consignmentType, opt => {
											return { value: opt, label: opt }
										})
									}
									onChange={v => setUpdateExtract({ ...updateExtract, consignmenttypefilters: v })}
									data-cy='multi-select-consignment-type'></MultiSelect>
							</Card>
							<Card width='calc(100% - 48px)' margin='16px 0 0 0' data-cy='card-select-ownership'>
								{updateExtract && levels && levels.length > 0 && (
									<MultiSelect
										width='100%'
										selectedValues={updateExtract && updateExtract.ownershipfilters}
										label={t('wms:Ownership')}
										options={_.map(levels, owner => {
											return { value: owner.id, label: owner.label }
										})}
										onChange={v => setUpdateExtract({ ...updateExtract, ownershipfilters: v })}
										data-cy='multi-select-ownership'></MultiSelect>
								)}
							</Card>
						</FlexView>
						<FlexView
							flexDirection='row'
							width='99%'
							height='100%'
							justifyContent='space-between'
							margin='16px 0 0 0'
							padding='0 0 0 4px'
							alignItems='stretch'>
							<Card width='calc(100% - 48px)' margin='16px 16px 16px 0' data-cy='card-select-product-type'>
								{updateExtract && productList && (
									<MultiSelect
										width='100%'
										selectedValues={updateExtract && updateExtract.producttypefilters}
										label={t('wms:ProductType')}
										options={_.map(productList, product => {
											return { value: product.value, label: product.value }
										})}
										onChange={v => setUpdateExtract({ ...updateExtract, producttypefilters: v })}
										data-cy='multi-select-product-type'></MultiSelect>
								)}
							</Card>
							<Card width='calc(100% - 48px)' margin='16px 0 16px 0' data-cy='card-select-erp-reference'>
								{updateExtract && erpList && (
									<MultiSelect
										width='100%'
										selectedValues={updateExtract && updateExtract.erpreferencefilters}
										label={t('wms:ErpReference')}
										options={_.map(erpList, erp => {
											return { value: erp, label: erp }
										})}
										onChange={v => setUpdateExtract({ ...updateExtract, erpreferencefilters: v })}
										data-cy='multi-select-erp-reference'></MultiSelect>
								)}
							</Card>
						</FlexView>
					</FlexView>
					<Button
						backgroundColor='success'
						color='white'
						margin='auto 0 0 auto'
						disabled={updateExtract && checkProperties()}
						onClick={() => saveExtract()}
						data-cy='button-save-extract'>
						{t('wms:Save')}
						<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default UpdateExtractModal
