/** @format */
/* eslint-disable react/prop-types */

import { FlexView } from 'components/common'
import Button from 'components/common/Button'
import Card from 'components/common/Card'
import Icon from 'components/common/Icon'
import Modal from 'components/common/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import bwipjs from 'bwip-js'
import { useEffect } from 'react'
import { useState } from 'react'

const TagModal = ({ isOpen, onOutsideClick, parentlevelname, level }) => {
	const { t } = useTranslation()
	const [src, setImageSrc] = useState(false)
	const [input, setInput] = useState(null)

	useEffect(() => {
		level && setInput(`${level.id}`)
	}, [level])

	useEffect(() => {
		let canvas = document.createElement('canvas')
		input &&
			bwipjs.toCanvas(canvas, {
				bcid: 'datamatrix',
				text: input,
				scale: 10,
				height: 8,
				includetext: false,
				textxalign: 'center'
			})
		setImageSrc(canvas.toDataURL('image/png'))
	}, [input])

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card data-cy='card-datamatrix-modal'>
				<FlexView
					width='-webkit-fill-available'
					flexDirection='row'
					justifyContent='space-between'
					backgroundColor='info'
					fontSize='title'
					fontWeight='bold'
					color='white'
					margin='-16px -24px 8px'
					padding='8px 16px'
					borderRadius='8px 8px 0 0'>
					{t('wms:Rack')}: {parentlevelname}.{level && level.name}
					<Button backgroundColor='info' color='white' onClick={() => onOutsideClick()}>
						<Icon name='cross-simple' color='white' width='14px' height='14px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
				<img
					src={src}
					alt={`data matrix from ${level && level.id}`}
					style={{ alignSelf: 'center', margin: 'auto 0 auto 0' }}
				/>
			</Card>
		</Modal>
	)
}

export default TagModal
