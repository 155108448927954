/** @format */

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import Select from 'apps/wms/components/forms/Select'
import {
	REPORT_ELEMENTS_STATUS,
	REPORT_ITEM_STATUS,
	THREAD_COMPOUND_COLUMNS,
	THREAD_COMPOUND_TYPE
} from 'apps/wms/utils/enum'
import { Button, FlexView, Icon } from 'components/common'
import { Input } from 'components/form'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ReportInput = styled(Input)`
	min-height: unset;
	font-weight: normal;
	margin: 0px;
	border-width: 1px;
	width: 80px;
`

const BorderlessButton = styled(Button)`
	border: none;
`
const typeFilterStr = 'wms:TypeToFilter'

const getStatusColor = status => REPORT_ITEM_STATUS.color[status]
const getElementStatusColor = status => {
	return status === REPORT_ELEMENTS_STATUS.codeString.NOTOK
		? 'error'
		: status === REPORT_ELEMENTS_STATUS.codeString.OK
		? 'success'
		: ''
}

const inspItemStatus = [
	{ value: REPORT_ELEMENTS_STATUS.codeString.OK, label: 'wms:Ok' },
	{ value: REPORT_ELEMENTS_STATUS.codeString.NOTOK, label: 'wms:Rejected' },
	{ value: REPORT_ELEMENTS_STATUS.codeString.NA, label: 'N.A.' }
]

const threadCompoundType = Object.values(THREAD_COMPOUND_TYPE.code)
	.map(code => ({
		label: THREAD_COMPOUND_TYPE.translateKey[code],
		value: code
	}))
	.concat({ value: null, label: 'wms:All' })

const consignmentType = [
	{ value: null, label: 'All' },
	{ value: 'Closed End', label: 'Closed End' },
	{ value: 'Open End', label: 'Open End' }
]

const defectsList = (config, values) => {
	let list = []
	values.defects.forEach(def => {
		let rejectReason = config.rejectReasons.find(rr => rr.id === def)
		return rejectReason ? list.push(rejectReason) : null
	})

	return list.length > 0 ? list : null
}

let applicationMethodList
let manufacturerList
let entranceDateList

export const getApplicationMethodList = config => {
	applicationMethodList = config && config.applicationMethodList
}

export const getManufacturerList = config => {
	manufacturerList = config && config.manufacturer
}

export const getEntranceDateList = config => {
	entranceDateList = config && config.entranceDateList.concat({ value: null, label: 'All' })
}

const getValueDefect = (row, element) => {
	let columnAccessor = `${element.name.replace(/ +/g, '').toLowerCase()}`
	let defectSelected = row.cells.find(col => col.column.id === columnAccessor)
	let defectAccessor = defectSelected && `${defectSelected.column.Header.replace(/ +/g, '').toLowerCase()}defect`
	let key = Object.keys(defectSelected.row.original).find(k => k === defectAccessor)
	return key && defectSelected.row.original[key]
}

const getDefaultReportsColumn = config => {
	return (
		config.elements &&
		config.elements.map(values => ({
			Header: values.name,
			accessor: `${values.name.replace(/ +/g, '').toLowerCase()}`,
			Filter: ElementStatusColumnFilter,
			Cell: ({ cell: { value, row } }) => {
				return config.allowEdit ? (
					<FlexView
						width='135px'
						flexDirection='column'
						justifyContent='center'
						alignItems='center'
						margin='0'
						padding='0'>
						<Select
							value={value}
							options={inspItemStatus.map(i => ({ value: i.value, label: config.t(i.label) }))}
							color={getElementStatusColor(value)}
							onChange={value => {
								config.onInspectionItemChange(
									value,
									row,
									`${values.name.replace(/ +/g, '').toLowerCase()}`,
									`${values.name.replace(/ +/g, '').toLowerCase()}defect`,
									`${values.name.replace(/ +/g, '').toLowerCase()}photo`
								)
							}}
							width='100px'
							inline={true}
							margin='0 0 4px 0'
							padding='0'
							fontSize='12px'
						/>
						{value === REPORT_ELEMENTS_STATUS.codeString.NOTOK ? (
							<Select
								value={getValueDefect(row, values)}
								options={
									defectsList(config, values) &&
									defectsList(config, values).map(rr => ({
										value: rr.id,
										label: rr.description
									}))
								}
								onChange={value => {
									config.onRejectReasonChange(
										value,
										row,
										`${values.name.replace(/ +/g, '').toLowerCase()}`,
										`${values.name.replace(/ +/g, '').toLowerCase()}defect`,
										`${values.name.replace(/ +/g, '').toLowerCase()}photo`
									)
								}}
								width='135px'
								inline={true}
								margin='0 0 4px 0'
								padding='0'
								fontSize='12px'
							/>
						) : null}
					</FlexView>
				) : (
					<FlexView
						fontSize='12px'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						margin='0'
						padding='0'
						color={value ? getElementStatusColor(value) : 'default'}>
						{value ? `${config.t('wms:')}${value}` : null}
					</FlexView>
				)
			}
		}))
	)
}

const ElementStatusColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
	const { t } = useTranslation()

	const options = useMemo(() => {
		const options = new Set()
		preFilteredRows.forEach(row => {
			options.add(row.values[id])
		})
		return [...options.values()]
			.map(opt => ({
				value: opt,
				label: opt
			}))
			.concat({ value: null, label: 'All' })
	}, [id, preFilteredRows])

	return (
		<Select
			width='100%'
			minWidth='100%'
			margin='8px 0'
			padding='0'
			fontSize='12px'
			value={filterValue || undefined}
			onChange={value => setFilter(value)}
			options={options.map(e => ({
				...e,
				label: `${t('wms:')}${e.label}`
			}))}
		/>
	)
}

const InspStatusColumnFilter = ({ column: { filterValue, setFilter } }) => {
	const { t } = useTranslation()
	const inspStatus = [
		{ value: null, label: t('wms:All') },
		{ value: REPORT_ITEM_STATUS.code.ACCEPT, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.ACCEPT] },
		{ value: REPORT_ITEM_STATUS.code.REJECT, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.REJECT] },
		{ value: REPORT_ITEM_STATUS.code.REPAIR, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.REPAIR] }
	]
	return (
		<Select
			width='80px'
			margin='8px 0'
			padding='0'
			fontSize='12px'
			value={filterValue}
			onChange={value => setFilter(value)}
			options={inspStatus.map(i => ({ ...i, label: t(i.label) }))}
		/>
	)
}

const ColumnFilter = ({ column: { filterValue, setFilter } }) => {
	const { t } = useTranslation()
	return (
		<Input
			width='100px'
			style={{ minHeight: '0px' }}
			padding='4px 8px'
			margin='0px'
			fontSize='12px'
			type='text'
			value={filterValue || ''}
			onChange={e => setFilter(e.target.value || undefined)}
			placeholder={t(typeFilterStr)}
		/>
	)
}

export const validColumn = () => ({
	Header: 'VALID',
	accessor: 'valid',
	Filter: ColumnFilter,
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value}
			</FlexView>
		)
	}
})

export const sourceValidColumn = ({ t }) => ({
	Header: t('wms:SourceValid'),
	accessor: 'sourcevalid',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value ? value : t('wms:Untraceable')}
			</FlexView>
		)
	},
	disableFilters: true
})

export const newValidColumn = ({ t }) => ({
	Header: `${t('wms:New')} VALID`,
	accessor: 'valid',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value}
			</FlexView>
		)
	},
	disableFilters: true
})

export const reportColumns = config => getDefaultReportsColumn(config)

const inspStatus = [
	{ value: REPORT_ITEM_STATUS.code.ACCEPT, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.ACCEPT] },
	{ value: REPORT_ITEM_STATUS.code.REJECT, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.REJECT] },
	{ value: REPORT_ITEM_STATUS.code.REPAIR, label: REPORT_ITEM_STATUS.translateKey[REPORT_ITEM_STATUS.code.REPAIR] }
]

export const inspStatusColumn = ({ t, allowEdit, onFinalStatusChange }) => ({
	Header: t('wms:FinalStatus'),
	accessor: 'finalreport',
	Filter: InspStatusColumnFilter,
	width: '120px',
	Cell: ({ cell: { value, row } }) => {
		return allowEdit ? (
			<FlexView flexDirection='column' justifyContent='center' alignItems='center' margin='0' padding='0'>
				<Select
					value={value}
					options={inspStatus.map(i => ({ ...i, label: t(i.label) }))}
					color={getStatusColor(value)}
					onChange={value => {
						onFinalStatusChange('finalreport', value, row)
					}}
					width='100%'
					inline={true}
					margin='0 0 4px 0'
					padding='0'
					fontSize='12px'
				/>
			</FlexView>
		) : (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				color={getStatusColor(value)}
				margin='0'
				padding='0'>
				{t(REPORT_ITEM_STATUS.translateKey[value])}
			</FlexView>
		)
	}
})

const InspectorColumnFilter = ({ column: { filterValue, setFilter } }) => {
	const { t } = useTranslation()
	return (
		<Input
			width='80px'
			style={{ minHeight: '0px' }}
			padding='4px 8px'
			margin='0px'
			fontSize='12px'
			type='text'
			value={filterValue || ''}
			onChange={e => setFilter(e.target.value || undefined)}
			placeholder={t(typeFilterStr)}
		/>
	)
}

export const inspectorColumn = ({ t }) => ({
	Header: t('wms:Inspector'),
	accessor: 'inspector',
	Filter: InspectorColumnFilter,
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value}
			</FlexView>
		)
	}
})

const ManufacturerColumnFilter = ({ column: { filterValue, setFilter } }) => {
	let options = manufacturerList && manufacturerList.concat({ typeid: null, typedesc: 'All' })
	return (
		<Select
			value={filterValue}
			options={options.map(i => ({ value: i.typeid, label: i.typedesc }))}
			onChange={value => setFilter(value)}
			width='80px'
			margin='8px 0'
			padding='0'
			fontSize='12px'
		/>
	)
}

const renderManufacturerColumn = ({
	cell: { value, row },
	column: {
		config: { allowEdit, threadColumns, manufacturer, onManufacturerChange }
	}
}) =>
	threadColumns &&
	threadColumns.some(column => column.id === THREAD_COMPOUND_COLUMNS.code.MANUFACTURER) &&
	allowEdit ? (
		<FlexView
			width='90%'
			maxWidth='95%'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			margin='0'
			padding='0'>
			<Select
				value={value}
				options={manufacturer && manufacturer.map(m => ({ value: m.typeid, label: m.typedesc }))}
				onChange={value => onManufacturerChange('manufacturer', value, row)}
				inline={true}
				width='80px'
				margin='0 0 4px 0'
				padding='0'
				fontSize='12px'
			/>
		</FlexView>
	) : (
		threadColumns &&
		threadColumns.some(column => column.id === THREAD_COMPOUND_COLUMNS.code.MANUFACTURER) && (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value ? value.id : '*****'}
			</FlexView>
		)
	)

export const manufacturerColumn = ({ threadColumns, t, manufacturer, onManufacturerChange, allowEdit }) => ({
	Header: t('wms:Manufacturer'),
	accessor:
		threadColumns.find(c => c.id === THREAD_COMPOUND_COLUMNS.code.MANUFACTURER) &&
		threadColumns.find(c => c.id === THREAD_COMPOUND_COLUMNS.code.MANUFACTURER).accessor,
	Filter: ManufacturerColumnFilter,
	config: { threadColumns, manufacturer, onManufacturerChange, allowEdit },
	Cell: renderManufacturerColumn
})

const renderApplicationMethodColumn = ({
	cell: { value, row },
	column: {
		config: { allowEdit, threadColumns, applicationMethodList, onApplicationMethodChange }
	}
}) =>
	threadColumns &&
	threadColumns.some(column => column.id === THREAD_COMPOUND_COLUMNS.code.APPLICATION_METHOD) &&
	allowEdit ? (
		<FlexView
			width='115px'
			maxWidth='95%'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			margin='0'
			padding='0'>
			<Select
				value={value}
				options={
					applicationMethodList &&
					applicationMethodList.map(app => ({ value: app.methodid, label: app.methoddesc }))
				}
				onChange={value => onApplicationMethodChange('method_id', value, row)}
				width='100px'
				inline={true}
				margin='0 0 4px 0'
				padding='0'
				fontSize='12px'
			/>
		</FlexView>
	) : (
		threadColumns &&
		threadColumns.some(column => column.id === THREAD_COMPOUND_COLUMNS.code.APPLICATION_METHOD) && (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value ? value.id : '*****'}
			</FlexView>
		)
	)

const ApplicationMethodColumnFilter = ({ column: { filterValue, setFilter } }) => {
	let options = applicationMethodList && applicationMethodList.concat({ methodid: null, methoddesc: 'All' })
	return (
		<Select
			value={filterValue}
			options={options.map(i => ({ value: i.methodid, label: i.methoddesc }))}
			onChange={value => setFilter(value)}
			width='100px'
			margin='8px 0'
			padding='0'
			fontSize='12px'
		/>
	)
}

export const applicationMethodColumn = ({
	threadColumns,
	t,
	applicationMethodList,
	onApplicationMethodChange,
	allowEdit
}) => ({
	Header: t('wms:ApplicationMethod'),
	accessor:
		threadColumns.find(c => c.id === THREAD_COMPOUND_COLUMNS.code.APPLICATION_METHOD) &&
		threadColumns.find(c => c.id === THREAD_COMPOUND_COLUMNS.code.APPLICATION_METHOD).accessor,
	Filter: ApplicationMethodColumnFilter,
	config: { threadColumns, applicationMethodList, onApplicationMethodChange, allowEdit },
	Cell: renderApplicationMethodColumn
})

export const threadCompoundColumns = config => ({
	Header: config.t('wms:ThreadCompound'),
	columns:
		config.threadColumns.some(c => c.id === THREAD_COMPOUND_COLUMNS.code.MANUFACTURER) &&
		config.threadColumns.some(c => c.id === THREAD_COMPOUND_COLUMNS.code.APPLICATION_METHOD)
			? [
					manufacturerColumn(config),
					applicationMethodColumn(config),
					batchNumberColumn(config),
					responsibleColumn(config),
					typeColumn(config)
			  ]
			: config.threadColumns.some(c => c.id === THREAD_COMPOUND_COLUMNS.code.MANUFACTURER)
			? [manufacturerColumn(config), batchNumberColumn(config), responsibleColumn(config), typeColumn(config)]
			: [applicationMethodColumn(config), batchNumberColumn(config), responsibleColumn(config), typeColumn(config)]
})

const TypeColumnFilter = ({ column: { filterValue, setFilter } }) => {
	const { t } = useTranslation()
	return (
		<Select
			value={filterValue}
			options={threadCompoundType.map(i => ({ ...i, label: t(i.label) }))}
			onChange={value => setFilter(value)}
			width='100px'
			margin='8px 0'
			padding='0'
			fontSize='12px'
		/>
	)
}

const typeColumn = ({ t, manufacturer }) => ({
	Header: t('wms:Type'),
	accessor: 'type_id',
	width: '120px',
	Filter: TypeColumnFilter,
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{manufacturer && manufacturer.find(m => m.typeid === value)
					? t(THREAD_COMPOUND_TYPE.translateKey[manufacturer.find(m => m.typeid === value).state])
					: '*****'}
			</FlexView>
		)
	}
})

const renderBatchNumberColumn = ({
	cell: { value, row },
	column: {
		config: { allowEdit, onBatchNumberChange }
	}
}) =>
	allowEdit ? (
		<ReportInput
			margin='0'
			padding='4px'
			value={value}
			onChange={e => {
				onBatchNumberChange('batch_number', e.target.value, row)
			}}
			fontSize='12px'
			width='100%'
		/>
	) : (
		<FlexView flexDirection='row' justifyContent='center' alignItems='center' fontSize='12px' margin='0' padding='0'>
			{value ? value : '*****'}
		</FlexView>
	)

const batchNumberColumn = ({ t, allowEdit, onBatchNumberChange }) => ({
	Header: t('wms:BatchNumber'),
	accessor: 'batch_number',
	width: '80px',
	config: { allowEdit, onBatchNumberChange },
	Filter: ColumnFilter,
	Cell: renderBatchNumberColumn
})

const responsibleColumn = ({ t }) => ({
	Header: t('wms:Responsible'),
	accessor: 'responsible',
	width: '80px',
	Filter: ColumnFilter,
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value ? value : '*****'}
			</FlexView>
		)
	}
})

const renderRemarksColumn = ({
	cell: { value, row },
	column: {
		config: { t, allowEdit, onReportChange }
	}
}) =>
	allowEdit ? (
		<ReportInput
			placeholder={t('wms:NoRemarks')}
			margin='0'
			padding='4px '
			value={value}
			onChange={e => {
				onReportChange('remarks', e.target.value, row)
			}}
			fontSize='12px'
			width='90%'
		/>
	) : (
		<FlexView flexDirection='row' justifyContent='center' alignItems='center' fontSize='12px' margin='0' padding='0'>
			{value}
		</FlexView>
	)

export const remarksColumn = ({ t, allowEdit, onReportChange }) => ({
	Header: t('wms:Remarks'),
	accessor: 'remarks',
	Filter: ColumnFilter,
	width: '150px',
	config: { t, allowEdit, onReportChange },
	Cell: renderRemarksColumn
})

export const measurementColumns = config => ({
	Header: config.t('wms:Measurement'),
	columns: [
		measuredWeightColumn(config),
		measuredLengthColumn(config),
		makeupLossColumn(),
		effectiveLengthColumn(config),
		intDiameterColumn(config),
		outDiameterColumn(config),
		measureResponsibleColumn(config)
	]
})

const renderMeasuredLengthColumn = ({
	cell: { value, row },
	column: {
		config: { allowEdit, onLengthChange }
	}
}) =>
	allowEdit ? (
		<ReportInput
			margin='0'
			padding='4px '
			value={value}
			onChange={e => {
				onLengthChange('measured', Number(e.target.value), row)
			}}
			fontSize='12px'
			width='100%'
		/>
	) : (
		<FlexView flexDirection='row' justifyContent='center' alignItems='center' fontSize='12px' margin='0' padding='0'>
			{value}
		</FlexView>
	)

const renderMeasuredWeightColumn = ({
	cell: { value, row },
	column: {
		config: { allowEdit, onWeightChange }
	}
}) =>
	allowEdit ? (
		<ReportInput
			margin='0'
			padding='4px '
			value={value}
			onChange={e => {
				onWeightChange('measuredweight', e.target.value, row)
			}}
			fontSize='12px'
			width='100%'
		/>
	) : (
		<FlexView flexDirection='row' justifyContent='center' alignItems='center' fontSize='12px' margin='0' padding='0'>
			{value}
		</FlexView>
	)

export const measuredLengthColumn = ({ t, allowEdit, onLengthChange, lengthUnit, convertToCurrentLengthUnit }) => ({
	Header: `${t('wms:LengthMeasured')} [${lengthUnit}]`,
	accessor: 'measured',
	width: '80px',
	config: { allowEdit, onLengthChange, lengthUnit, convertToCurrentLengthUnit },
	Cell: renderMeasuredLengthColumn,
	disableFilters: true
})

export const measuredWeightColumn = ({ t, allowEdit, onWeightChange, weightUnit, convertToCurrentWeightUnit }) => ({
	Header: `${t('wms:WeightMeasured')} [${weightUnit}]`,
	accessor: 'measuredweight',
	width: '80px',
	config: { allowEdit, onWeightChange, weightUnit, convertToCurrentWeightUnit },
	Cell: renderMeasuredWeightColumn,
	disableFilters: true
})

export const makeupLossColumn = () => ({
	Header: 'Make up loss [mm]',
	accessor: 'makeuploss',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value?.toFixed(3)}
			</FlexView>
		)
	},
	disableFilters: true
})

export const effectiveLengthColumn = ({ t, lengthUnit, convertToCurrentLengthUnit }) => ({
	Header: `${t('wms:EffectiveLength')} [${lengthUnit}]`,
	accessor: 'effective',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{convertToCurrentLengthUnit(value, lengthUnit)}
			</FlexView>
		)
	},
	disableFilters: true
})

export const intDiameterColumn = ({ t, diameterUnit, convertToCurrentDiameterUnit }) => ({
	Header: `${t('wms:InternalDiameter')} [${diameterUnit}]`,
	accessor: 'intdiameter',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{convertToCurrentDiameterUnit(value, diameterUnit)}
			</FlexView>
		)
	},
	disableFilters: true
})

export const outDiameterColumn = ({ t, diameterUnit, convertToCurrentDiameterUnit }) => ({
	Header: `${t('wms:OutsideDiameter')} [${diameterUnit}]`,
	accessor: 'outdiameter',
	width: '80px',
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{convertToCurrentDiameterUnit(value, diameterUnit)}
			</FlexView>
		)
	},
	disableFilters: true
})

export const measureResponsibleColumn = ({ t }) => ({
	Header: t('wms:InspectionResponsible'),
	accessor: 'measureresponsible',
	width: '120px',
	Filter: ColumnFilter,
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				fontSize='12px'
				margin='0'
				padding='0'>
				{value}
			</FlexView>
		)
	}
})

export const instrumentsColumn = ({ t, handleInstrumentCaseSelector }) => ({
	Header: t('wms:InstrumentCase'),
	accessor: 'instlistid',
	width: '120px',
	Cell: ({ cell: { row } }) => {
		return (
			<BorderlessButton
				fontSize='tiny'
				width='100%'
				height='100%'
				outline='true'
				margin='0'
				onClick={() => handleInstrumentCaseSelector(row)}>
				<FlexView
					flexDirection='row'
					flexWrap='wrap'
					justifyContent='center'
					alignItems='center'
					fontSize='12px'
					margin='0'
					padding='0'>
					{row.original.instrumentlist && row.original.instrumentlist.length ? (
						row.original.instrumentlist.map(instrument => (
							<FlexView
								key={instrument.id}
								flexDirection='row'
								justifyContent='center'
								alignItems='center'
								fontSize='12px'
								margin='0 4px'
								padding='0'>
								{instrument.reference}
							</FlexView>
						))
					) : (
						<FlexView
							flexDirection='row'
							justifyContent='center'
							alignItems='center'
							fontSize='12px'
							margin='0'
							padding='0'>
							{t('wms:NoEquipment')}
						</FlexView>
					)}
				</FlexView>
			</BorderlessButton>
		)
	},
	disableFilters: true
})

export const imageHandlerColumn = ({ t, allowEdit, handlePhotoSelector }) => ({
	Header: t('wms:Photos'),
	accessor: 'hasphoto',
	width: '80px',
	Cell: ({ cell: { value, row } }) => {
		return (
			<Button
				fontSize='tiny'
				padding='8px'
				outline='true'
				color={value ? 'success' : 'gray'}
				margin='0'
				width='100%'
				height='100%'
				disabled={!allowEdit && !value}
				onClick={() => handlePhotoSelector(row)}>
				<Icon name='edit' color={value ? 'success' : 'gray'} height='12px' width='12px' margin='0' />
			</Button>
		)
	},
	disableFilters: true
})

export const customerOrderColumn = ({ t }) => ({
	Header: t('wms:CustomerOrder'),
	accessor: 'customerpo',
	width: '100px',
	Filter: ColumnFilter,
	Cell: ({ cell: { value } }) => {
		return (
			<FlexView width='110px' alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
				{value}
			</FlexView>
		)
	}
})

export const customerItemColumn = ({ t }) => ({
	Header: t('wms:CustomerItem'),
	accessor: 'customeritem',
	minWidth: '80px',
	maxWidth: '80px',
	Filter: ColumnFilter,
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	)
})

export const salesOrderColumn = ({ t }) => ({
	Header: t('wms:SalesOrder'),
	accessor: 'salesorder',
	minWidth: '100px',
	maxWidth: '100px',
	Filter: ColumnFilter,
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	)
})

export const salesItemColumn = ({ t }) => ({
	Header: t('wms:SalesItem'),
	accessor: 'salesitem',
	minWidth: '80px',
	maxWidth: '80px',
	Filter: ColumnFilter,
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	)
})

export const mescColumn = ({ t }) => ({
	Header: t('wms:ErpReference'),
	accessor: 'erpreference',
	minWidth: '100px',
	Filter: ColumnFilter,
	maxWidth: '100px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	)
})

const MaterialDescColumnFilter = ({ column: { filterValue, setFilter } }) => {
	const { t } = useTranslation()
	return (
		<Input
			width='300px'
			style={{ minHeight: '0px', textAlign: 'center' }}
			padding='4px 8px'
			margin='0px'
			fontSize='12px'
			type='text'
			value={filterValue || ''}
			onChange={e => setFilter(e.target.value || undefined)}
			placeholder={t(typeFilterStr)}
		/>
	)
}

export const materialDescColumn = ({ t }) => ({
	Header: t('wms:Material'),
	accessor: 'materialdesc',
	minWidth: '300px',
	maxWidth: '300px',
	Filter: MaterialDescColumnFilter,
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	)
})

const AttributeColumnFilter = ({ column: { filterValue, setFilter } }) => {
	return (
		<Select
			width='80px'
			style={{ minHeight: '0px' }}
			padding='4px 8px'
			margin='8px 0'
			fontSize='12px'
			type='text'
			value={filterValue}
			options={consignmentType.map(consignment => ({ ...consignment }))}
			onChange={value => setFilter(value)}
		/>
	)
}

const getAttributeColumns = ({ ageColumns }) => {
	return ageColumns.map(col => ({
		Header: col.attributedesc,
		accessor: 'attributevalue',
		width: '120px',
		Filter: AttributeColumnFilter,
		Cell: ({ cell: { value } }) => {
			return (
				<FlexView alignItems='center' width='110px' justifyContent='center' margin='0' padding='0' fontSize='12px'>
					{value}
				</FlexView>
			)
		}
	}))
}

export const attributeColumns = config => getAttributeColumns(config)

export const ownershipColumn = ({ t }) => ({
	Header: t('wms:Ownership'),
	accessor: 'ownershipdesc',
	minWidth: '100px',
	maxWidth: '100px',
	Filter: ColumnFilter,
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	)
})

const EntranceDateColumnFilter = ({ column: { filterValue, setFilter } }) => {
	const { t } = useTranslation()
	return (
		<Select
			width='80px'
			style={{ minHeight: '0px' }}
			padding='4px 8px'
			margin='8px 0'
			fontSize='12px'
			placeholder={t('wms:Date')}
			value={filterValue || undefined}
			options={entranceDateList.map(entrance => ({
				value: entrance.label,
				label: entrance.label
			}))}
			onChange={value => setFilter(value === 'All' ? undefined : value)}
			searchable
		/>
	)
}

export const entranceDateColumn = ({ t }) => ({
	Header: t('wms:EntranceDate'),
	accessor: 'entrancedate',
	width: '120px',
	Filter: EntranceDateColumnFilter,
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' width='110px' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	)
})

export const ageColumn = ({ t }) => ({
	Header: `${t('wms:Age')}[${t('wms:Months')}]`,
	accessor: 'age',
	minWidth: '80px',
	maxWidth: '80px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value.toFixed(3)}
		</FlexView>
	),
	disableFilters: true
})

export const jointsColumn = ({ t }) => ({
	Header: `${t('wms:Joints')}/PCS`,
	accessor: 'quantity',
	minWidth: '80px',
	maxWidth: '80px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value}
		</FlexView>
	),
	disableFilters: true
})

export const lengthColumn = ({ t, convertToCurrentLengthUnit, lengthUnit }) => ({
	Header: `${t('wms:Length')} [${lengthUnit}]`,
	accessor: 'length',
	minWidth: '80px',
	maxWidth: '80px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{convertToCurrentLengthUnit(value, lengthUnit)}
		</FlexView>
	),
	disableFilters: true
})

export const weightColumn = ({ t, convertToCurrentWeightUnit, weightUnit }) => ({
	Header: `${t('wms:Weight')} [${weightUnit}]`,
	accessor: 'weight',
	minWidth: '80px',
	maxWidth: '80px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{convertToCurrentWeightUnit(value, weightUnit)}
		</FlexView>
	),
	disableFilters: true
})

export const heatNumberColumn = ({ t }) => ({
	Header: t('wms:HeatNo'),
	accessor: 'heatnumber',
	minWidth: '80px',
	maxWidth: '80px',
	Cell: ({ cell: { value } }) => (
		<FlexView alignItems='center' justifyContent='center' margin='0' padding='0' fontSize='12px'>
			{value ? value : '*****'}
		</FlexView>
	),
	disableFilters: true
})
