import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { StatusContext } from '../../stores/StatusStore'
import { FlexView, Button } from 'components/common'
import { DateRangePicker, RadioGroup } from 'components/form'

const intervalOptions = [
  {
    value: 1,
    label: '1m'
  },
  {
    value: 5,
    label: '5m'
  },
  {
    value: 10,
    label: '10m'
  },
  {
    value: 30,
    label: '30m'
  },
  {
    value: 60,
    label: '1h'
  },
  {
    value: 240,
    label: '4h'
  }
]

const FormWrapper = styled(FlexView)`
  flex-direction: row;
  flex-wrap: wrap;

  & > *:not(:first-child):not(:last-child) {
    margin-left: 16px;
    margin-right: 16px;
  }

  & > button {
    align-self: flex-end;
    margin: 0px 0px 20px;
  }

  @media (max-width: 1080px) {
    & > button {
      align-self: flex-start;
      margin: 8px 0px;
    }
  }

  @media (max-width: 940px) {
    flex-direction: column;

    & > *:not(:first-child):not(:last-child) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`

const defaultDate = moment().isAfter(moment().hours(5).minutes(0)) ? moment() : moment().subtract('1', 'day')

const StatusForm = () => {
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState(moment(defaultDate).hours(5).minutes(0))
  const [endDate, setEndDate] = useState(moment(defaultDate).hours(13).minutes(0))
  const [interval, setInterval] = useState(1)
  const [isLoading, setLoading] = useState(false)
  const { getReports } = useContext(StatusContext)

  const onSubmit = async () => {
    setLoading(true)
    try {
      await getReports(startDate, endDate, interval)
    }
    catch (e) {
      console.log(e)
      toast.error(e.data || t(`admin:errors.${e.message}`))
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <FlexView fontSize="title" fontWeight="bold">{t('admin:status.title')}</FlexView>
      <FormWrapper>
        <DateRangePicker label={t('admin:status.selectRange')} startValue={startDate} endValue={endDate} onStartChange={setStartDate} onEndChange={setEndDate} timePicker />
        <RadioGroup inline name="interval" label={t('admin:status.selectInterval')} value={interval} onChange={setInterval} options={intervalOptions} />
        <Button isLoading={isLoading} onClick={onSubmit} backgroundColor="primary" color="white">{t('admin:status.findReports')}</Button>
      </FormWrapper>
    </div>
  )
}

export default StatusForm