/** @format */

import React, { useState, useEffect, useContext } from 'react'
import _, { toInteger } from 'lodash'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { FlexView, Card, Button, Modal, Icon, LoadingOverlay } from 'components/common'
import { Select, Input } from 'components/form'
import DatePicker from 'apps/wms/components/forms/DatePicker/DatePicker'

import { DispatchContext } from '../../stores/DispatchStore'
import { toast } from 'react-toastify'

const DateWrapper = styled.div`
	width: 40%;

	> div {
		width: 100%;
	}
`

// eslint-disable-next-line react/prop-types
const NewDispatchModal = ({ isOpen, onOutsideClick }) => {
	const {
		lastLevelsList,
		materialList,
		getContent,
		newDispatch,
		vehiclesList,
		endUserList,
		getOwnershipByEnduser,
		loadingLevels,
		getMaterialByEndUser,
		setMaterialsByEU,
		materialsByEU
	} = useContext(DispatchContext)
	const { t } = useTranslation()
	const [sourceOwnership, setSourceOwnership] = useState(null)
	const [sourceMaterial, setSourceMaterial] = useState(null)
	const [sourceRack, setSourceRack] = useState(null)
	const [sourceQty, setSourceQty] = useState(0)

	const [content, setContent] = useState([])
	const [sourceMaterialList, setSourceMaterialList] = useState([])
	const [rackList, setRacksList] = useState([])
	const [levelsList, setLevelsList] = useState([])
	const [qntAvailable, setQntAvailable] = useState(0)
	const [loadingMaterials, setLoadingMaterials] = useState(false)
	const [loadingRacks, setLoadingRacks] = useState(false)
	const [sourceVehicle, setSourceVehicle] = useState(null)
	const [dispatchUpload, setDispatchUpload] = useState(false)
	const [endUserSelected, setEndUserSelected] = useState(null)

	const baseDispatch = {
		expecteddate: null,
		work_order_description: '',
		content: [],
		occupationfactor: 0,
		vehicle: '',
		vehicleid: 0
	}

	const [dispatchData, setDispatchData] = useState(baseDispatch)

	useEffect(() => {
		setSourceOwnership(null)
		endUserSelected &&
			getOwnershipByEnduser(endUserSelected)
				.then(list => setLevelsList(list))
				.catch(e => console.error(e))
		endUserSelected &&
			getMaterialByEndUser(endUserSelected)
				.then(list => setMaterialsByEU(list))
				.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endUserSelected])

	useEffect(() => {
		setSourceOwnership(null)
		setEndUserSelected(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	useEffect(() => {
		if (content.length) {
			let materials = []
			_.forEach(materialsByEU, mat => {
				let filtered = content.find(material => material.id === mat.id)
				filtered && materials.push(filtered)
			})
			if (materials.length > 0) setSourceMaterialList(materials)
			else {
				setSourceMaterialList([])
				toast.error(t('wms:NoMaterialForEndUser'))
			}
		} else setSourceMaterialList([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [content])

	const uniqueByMaterialAndRack = (materialList, param1, param2) => {
		const uniqueMaterials = {}
		materialList.forEach(material => {
			const key = material[param1] + '-' + material[param2]
			uniqueMaterials[key] = material
		})

		return Object.values(uniqueMaterials)
	}

	useEffect(() => {
		setSourceMaterial(null)
		if (sourceOwnership) {
			setLoadingMaterials(true)
			getContent(sourceOwnership)
				.then(contentList => {
					let filteredList = []
					if (contentList && contentList.length > 0) {
						contentList.forEach(content => {
							const foundMaterial = materialList.find(material => material.mvid === content.material.id)
							foundMaterial &&
								filteredList.push({
									id: foundMaterial.mvid,
									formatted: foundMaterial.formatted,
									weightkg: foundMaterial.weightkg,
									fullname: content.level.fullname,
									levelid: content.level.id,
									creationavg: content.creationavg,
									itemcount: content.itemcount
								})
						})
						filteredList = uniqueByMaterialAndRack(filteredList, 'id', 'levelid')
					}
					filteredList.length ? setContent(filteredList) : setContent([])
				})
				.catch(e => console.error(e))
				.finally(() => setLoadingMaterials(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceOwnership])

	useEffect(() => {
		setSourceRack(null)
		if (content.length && sourceMaterial) {
			setLoadingRacks(true)
			let aux = content
				.filter(item => item.id === sourceMaterial)
				.map(list => ({
					id: list.levelid,
					fullname: list.fullname,
					itemcount: list.itemcount
				}))
				.sort((a, b) => a.fullname - b.fullname)
			setRacksList(aux)
			setLoadingRacks(false)
		} else setRacksList([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceMaterial, content])

	useEffect(() => {
		setSourceQty(0)
		setQntAvailable(0)
		if (sourceRack && rackList.length) {
			let selectedRack = rackList.find(rack => rack.id === sourceRack)
			let decrease = 0
			dispatchData.content.forEach(
				content =>
					content.mid === sourceMaterial && content.levelid === selectedRack.id && (decrease += content.quantity)
			)
			decrease
				? selectedRack && setQntAvailable(selectedRack.itemcount - decrease)
				: selectedRack && setQntAvailable(selectedRack.itemcount)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceRack])

	const handleVehicleChange = id => {
		const vehicle = vehiclesList.find(v => v.id === id)
		if (vehicle !== undefined) {
			let v = {
				Name: vehicle.name,
				Weight: vehicle.maxweightkg,
				properties: vehicle.properties
			}
			dispatchData.vehicle = v
			dispatchData.vehicleid = id
			dispatchData.occupationfactor = getUpdateOccupation(dispatchData)
			setDispatchData({ ...dispatchData, vehicle: v, occupationfactor: dispatchData.occupationfactor })
			setSourceVehicle(id)
		}
	}

	const handleOccupationChange = event => {
		let value = parseFloat(event.target.value)
		!isNaN(value) &&
			value >= 0 &&
			value <= 100 &&
			setDispatchData({ ...dispatchData, occupationfactor: event.target.value })
	}

	const matFormatted = id => {
		return id !== null && materialList.length && materialList.find(mat => mat.mvid === id)
			? materialList.find(mat => mat.mvid === id).formatted
			: ''
	}

	const levelFullName = id => {
		return id !== null && lastLevelsList.length && lastLevelsList.find(level => level.id === id)
			? lastLevelsList.find(level => level.id === id).fullname
			: ''
	}

	const addContent = () => {
		if (!(sourceOwnership && sourceMaterial && sourceRack && sourceQty !== 0)) {
			return
		}

		let aux = dispatchData.content.find(c => c.mid === sourceMaterial && c.levelid === sourceRack)
		if (aux !== undefined) {
			return
		}

		let material = content.find(material => material.id === sourceMaterial)

		dispatchData.content = [
			...dispatchData.content,
			{
				mid: sourceMaterial,
				levelid: sourceRack,
				quantity: toInteger(sourceQty),
				enduserid: endUserSelected,
				ownershipid: sourceOwnership,
				weightkg: material && material.weightkg
			}
		]
		dispatchData.occupationfactor = getUpdateOccupation(dispatchData)
		setDispatchData({ ...dispatchData })
		setQntAvailable(qntAvailable - sourceQty)
		setSourceQty(0)
	}

	const getUpdateOccupation = dispatchData => {
		let occupation = dispatchData.occupationfactor
		if (
			dispatchData &&
			dispatchData.content &&
			dispatchData.content.length &&
			dispatchData.vehicle &&
			dispatchData.vehicle.Weight
		) {
			const sum = dispatchData.content.reduce((prev, curr) => {
				return prev + curr.weightkg * curr.quantity
			}, 0)
			occupation = (sum * 100.0) / dispatchData.vehicle.Weight
			return Number(occupation.toFixed(2))
		}
		return 0
	}

	const removeContent = index => {
		if (index !== null && index < dispatchData.content.length) {
			sourceMaterial === dispatchData.content[index].mid &&
				sourceRack === dispatchData.content[index].levelid &&
				setQntAvailable(qntAvailable + dispatchData.content[index].quantity)
			dispatchData.content.splice(index, 1)
			dispatchData.occupationfactor = getUpdateOccupation(dispatchData)
			setDispatchData({
				...dispatchData,
				content: [...dispatchData.content],
				occupationfactor: dispatchData.occupationfactor
			})
		}
	}

	const validateData = () => {
		let isValid = true
		isValid =
			isValid && dispatchData.expecteddate && dispatchData.work_order_description && dispatchData.content.length
		return isValid
	}

	const saveDispatch = async () => {
		try {
			setDispatchUpload(true)
			await newDispatch(dispatchData)
				.then(() => {
					setSourceOwnership(null)
					setSourceVehicle(null)
					setDispatchData(baseDispatch)
				})
				.finally(() => setDispatchUpload(false))
		} catch (e) {
			console.error(e)
		}
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='80%' height='85%' position='relative'>
				<LoadingOverlay visible={dispatchUpload} borderRadius='16px' />
				<FlexView
					width='100%'
					fontSize='big'
					flexDirection='row'
					fontWeight='bold'
					margin='0 0 16px 0'
					justifyContent='space-between'>
					{t('wms:NewDispatch')}
					<Button fontSize='tiny' margin='0' backgroundColor='error' disabled={false} onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					<FlexView maxWidth='35%' minWidth='35%' minHeight='100%' maxHeight='100%' margin='0 16px 0 0'>
						<Card
							margin='0 0 4px 0'
							maxHeight={`calc(85vh - ${90}px)`}
							style={{ overflowY: 'scroll', overflowX: 'hidden' }}
							width='calc(100% - 48px)'
							flex='1'>
							{endUserList && endUserList.length && (
								<Select
									label={t('wms:EndUser')}
									placeholder={t('wms:EndUser')}
									value={endUserSelected}
									options={_.map(endUserList, eu => ({
										value: eu.id,
										label: eu.name
									}))}
									onChange={value => setEndUserSelected(value)}
									width='100%'
									inline={false}
									margin='0 0 8px 0'
									searchable
								/>
							)}
							<FlexView width='100%' margin='0' padding='0' position='relative'>
								<LoadingOverlay visible={loadingLevels} />
								<Select
									label={t('wms:Ownership')}
									placeholder={t('wms:Ownership')}
									value={sourceOwnership}
									options={
										levelsList &&
										levelsList.length &&
										_.map(levelsList, lv => ({
											value: lv.id,
											label: lv.label
										}))
									}
									onChange={value => {
										setSourceOwnership(value)
									}}
									width='100%'
									inline={false}
									margin='0'
									disabled={!endUserSelected}
									searchable
								/>
							</FlexView>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='8px 0'>
								{t('wms:Material')}
							</FlexView>
							<FlexView width='100%' margin='0' padding='0' position='relative'>
								<LoadingOverlay visible={loadingMaterials} />
								<Select
									searchable
									placeholder={t('wms:SelectMaterial')}
									value={sourceMaterial}
									options={sourceMaterialList.map(material => ({
										label: material.formatted,
										value: material.id
									}))}
									onChange={value => {
										setSourceMaterial(value)
									}}
									width='100%'
									margin='0'
									disabled={sourceOwnership === null}
								/>
							</FlexView>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='8px 0'>
								{t('wms:SourceRack')}
							</FlexView>
							<FlexView width='100%' margin='0' padding='0' position='relative'>
								<LoadingOverlay visible={loadingRacks} />
								<Select
									searchable
									placeholder={t('wms:SelectRack')}
									value={sourceRack}
									options={rackList.map(rack => ({
										label: rack.fullname,
										value: rack.id
									}))}
									onChange={value => {
										setSourceRack(value)
									}}
									width='100%'
									margin='0'
									disabled={sourceMaterial === null}
								/>
							</FlexView>
							<Input
								label={`${t('wms:Quantity')} ${
									sourceRack ? `[${t('wms:AvailablePipes')}: ${qntAvailable}]` : ''
								}`}
								placeholder={`${qntAvailable}`}
								margin='8px 0 0 0'
								min='1'
								max={qntAvailable}
								type='number'
								value={sourceQty}
								onChange={e => {
									setSourceQty(toInteger(e.target.value))
								}}
								error={sourceQty > qntAvailable || sourceQty < 0}
								fontSize='small'
								width='100%'
								disabled={sourceRack === null}
							/>
							<Button
								fontSize='medium'
								margin='auto auto 0 auto'
								color='white'
								backgroundColor='secondary'
								disabled={
									!(
										!!sourceOwnership &&
										!!sourceMaterial &&
										!!sourceRack &&
										sourceQty !== 0 &&
										sourceQty <= qntAvailable &&
										sourceQty > 0
									)
								}
								onClick={() => {
									addContent()
								}}>
								{t('wms:AddPart')}
							</Button>
						</Card>
					</FlexView>
					<FlexView width='calc(65% - 16px)' minHeight='100%' maxHeight='100%'>
						<Card margin='0 0 4px 0' width='calc(100% - 48px)' minHeight='inherit' flex='1'>
							<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
								{t('wms:DispatchInfo')}
							</FlexView>
							<FlexView
								flexDirection='row'
								width='100%'
								maxWidth='100%'
								justifyContent='space-between'
								flexWrap='wrap'>
								<DateWrapper>
									<DatePicker
										label={`${t('wms:DispatchExpectedDate')}`}
										placeholder={`${t('wms:SelectDate')}`}
										margin='8px 0 0 0'
										timePicker={true}
										value={dispatchData.expecteddate}
										onChange={e => {
											setDispatchData({ ...dispatchData, expecteddate: e })
										}}
										fontSize='small'
										noPast={true}
									/>
								</DateWrapper>

								<Input
									label={`${t('wms:WorkOrderDescription')}`}
									placeholder={`${t('wms:WorkOrderDescription')}`}
									margin='8px 0 0 0'
									value={dispatchData.work_order_description}
									onChange={e => {
										setDispatchData({ ...dispatchData, work_order_description: e.target.value })
									}}
									fontSize='small'
								/>

								<Input
									label={`${t('wms:DispatchOccupationFactor')}`}
									placeholder={`${t('wms:DispatchOccupationFactor')}`}
									margin='8px 0 0 0'
									value={dispatchData.occupationfactor}
									onChange={e => {
										handleOccupationChange(e)
									}}
									fontSize='small'
									type='number'
									min='0'
									max='100'
								/>
							</FlexView>
							<FlexView
								flexDirection='row'
								width='100%'
								maxWidth='100%'
								justifyContent='space-between'
								flexWrap='wrap'>
								<Select
									searchable
									label={t('wms:Transport')}
									value={sourceVehicle}
									options={vehiclesList.map(vehicle => ({
										label: vehicle.name,
										value: vehicle.id
									}))}
									onChange={value => {
										handleVehicleChange(value)
									}}
									margin='8px 0 0 0'
								/>

								{dispatchData.vehicle &&
									dispatchData.vehicle.properties.map(prop => (
										<Input
											key={prop.name}
											label={`${prop.name}`}
											placeholder={`${prop.defaultvalue}`}
											margin='8px 8px 0 0'
											value={dispatchData.vehicle[prop.name]}
											onChange={e => {
												setDispatchData({
													...dispatchData,
													vehicle: { ...dispatchData.vehicle, [prop.name]: e.target.value }
												})
											}}
											fontSize='small'
											disabled={!prop.editable}
										/>
									))}
							</FlexView>
							{dispatchData !== null && dispatchData.content.length ? (
								<FlexView
									width='100%'
									height='calc((85vh - 90px)*0.40)'
									style={{ overflowY: 'scroll', overflowX: 'auto' }}>
									{dispatchData.content.map((content, index) => (
										<FlexView
											key={index}
											width='100%'
											margin='0'
											flexDirection='row'
											padding='8px 0'
											style={{ borderTop: !index ? 'none' : '1px solid whitesmoke' }}>
											<FlexView flex='1' fontSize='small' margin='auto 0'>
												{matFormatted(content.mid)} <br />
												<strong>
													{`${levelFullName(content.levelid)} | ${t('wms:Quantity')}: ${content.quantity}`}
												</strong>
											</FlexView>

											<FlexView
												width='25%'
												height='100%'
												margin='0'
												padding='0'
												flexDirection='row'
												justifyContent='center'>
												<Button
													fontSize='small'
													margin='auto 0 auto auto'
													padding='8px 16px'
													color='white'
													backgroundColor='error'
													disabled={false}
													onClick={() => {
														removeContent(index)
													}}>
													<Icon
														name='trash'
														color='white'
														height='16px'
														width='16px'
														margin='0'
														tooltip={`${t('wms:RemovePart')}`}
													/>
												</Button>
											</FlexView>
										</FlexView>
									))}
								</FlexView>
							) : null}
						</Card>
					</FlexView>
				</FlexView>
				<FlexView width='100%' flexDirection='row' margin='16px 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0 0 0 auto'
						color='white'
						backgroundColor='success'
						disabled={!validateData()}
						onClick={() => saveDispatch()}>
						{t('wms:Save')}
						<Icon name='save' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			</Card>
		</Modal>
	)
}

export default NewDispatchModal
