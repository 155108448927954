/** @format */

import { AssetsInspSettingsContext } from 'apps/wms/stores/AssetsInspSettingsStore'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, Card, FlexView, Icon, LoadingOverlay } from 'components/common'
import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddModal from './AddModal'
import Toggle from '../../../components/commom/Toggle'

const InspDefects = () => {
	const { t } = useTranslation()
	const { defects, disabledDef, openAddModal, setOpenAddModal, disableDefects, enableDefects, loadingDefects } =
		useContext(AssetsInspSettingsContext)
	const [enabled, setEnabled] = useState(false)

	const addDefects = () => {
		setOpenAddModal(true)
	}

	const handleDisable = id => {
		disableDefects(id)
	}

	const handleEnable = id => {
		enableDefects(id)
	}

	const listDeadSpace = 130
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window])

	return (
		<Card
			width='calc(100% - 48px)'
			minWidth='calc(100% - 48px)'
			ref={listRef}
			style={{ position: 'relative' }}
			height='100%'
			data-cy='card-inspection-defects'>
			<LoadingOverlay visible={loadingDefects} borderRadius='card' />
			<FlexView flexDirection='column' width='100%' height='100%'>
				<FlexView flexDirection='row' width='100%' justifyContent='space-between' alignItems='center'>
					<FlexView width='100%' fontSize='subtitle' fontWeight='bold'>
						{t('wms:Defects')}
					</FlexView>
					<FlexView width='100%' justifyContent='flex-end' alignItems='flex-end'>
						<Toggle
							state={enabled}
							textOff={t('wms:Enabled')}
							textOn={t('wms:Disabled')}
							onChange={value => setEnabled(value)}
							data-cy='toggle-enabled-disabled-defects'
							alternate
						/>
					</FlexView>
				</FlexView>
				<FlexView
					flexDirection='column'
					margin='16px 0 0 0'
					padding='0'
					justifyContent='flex-start'
					alignItems='flex-start'
					width='100%'
					height={`${listHeight}px`}
					style={{ overflowY: 'auto', overflowX: 'hidden' }}>
					<FlexView
						flexDirection='row'
						flexWrap='wrap'
						width='100%'
						height={
							defects && defects.length > 0 && !enabled
								? 'auto'
								: disabledDef && disabledDef.length > 0 && enabled
								? 'auto'
								: '100%'
						}>
						{defects && defects.length > 0 && !enabled ? (
							_.map(defects, defect => (
								<FlexView
									flexDirection='row'
									key={defect.id}
									width='50%'
									alignItems='flex-start'
									justifyContent='flex-start'
									data-cy='list-defect'>
									<FlexView
										width='40%'
										fontSize='tiny'
										borderRadius='4px'
										color='white'
										backgroundColor='info'
										padding='8px 16px'
										margin='16px 8px 0 0'
										alignItems='center'>
										{defect.description}
									</FlexView>
									<Button
										fontSize='tiny'
										margin='16px 8px 0 0'
										padding='8px 16px'
										color='white'
										backgroundColor='error'
										onClick={() => handleDisable(defect.id)}
										data-cy='button-disable-defect'>
										{t('wms:Disable')}
									</Button>
								</FlexView>
							))
						) : disabledDef && disabledDef.length > 0 && enabled ? (
							_.map(disabledDef, defect => (
								<FlexView
									flexDirection='row'
									key={defect.id}
									width='50%'
									alignItems='flex-start'
									justifyContent='flex-start'
									data-cy='list-item'>
									<FlexView
										width='40%'
										fontSize='tiny'
										borderRadius='4px'
										color='white'
										backgroundColor='info'
										padding='8px 16px'
										margin='16px 8px 0 0'
										alignItems='center'>
										{defect.description}
									</FlexView>
									<Button
										fontSize='tiny'
										margin='16px 8px 0 0'
										padding='8px 16px'
										color='white'
										backgroundColor='success'
										onClick={() => handleEnable(defect.id)}
										data-cy='button-enable-defect'>
										{t('wms:Enable')}
									</Button>
								</FlexView>
							))
						) : (
							<FlexView
								width='100%'
								height='calc(100% - 90px)'
								margin='16px 0 0 0'
								fontWeight='bold'
								fontSize='24px'
								color='lightGray'
								alignItems='center'
								justifyContent='center'
								data-cy='no-defects-list'>
								{t('wms:NoAvailableDefects')}
							</FlexView>
						)}
					</FlexView>
				</FlexView>
				<Button
					backgroundColor='secondary'
					color='white'
					onClick={() => addDefects()}
					margin='auto 0 0 auto'
					data-cy='button-add-defect'>
					{t('wms:Add')}
					<Icon name='add' color='white' width='14px' height='14px' margin='0 0 0 8px' />
				</Button>
			</FlexView>
			<AddModal isOpen={openAddModal} onOutsideClick={() => setOpenAddModal(false)} />
		</Card>
	)
}

export default InspDefects
