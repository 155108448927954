/** @format */
/* eslint-disable react/prop-types */

import { DATE_FORMAT } from 'apps/wms/utils/dateformat'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Button, Card, FlexView, LoadingOverlay } from 'components/common'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { INSPECTION_STAGES, STATUS } from 'apps/wms/utils/enum'
import theme from 'utils/theme'
import { PrepareInspectionContext } from 'apps/wms/stores/PrepareInspectionStore'
import UpdateFileModal from './UpdateFileModal'
import moment from 'moment'

const PrepareInspectionInfo = ({ report, rackList, selectedInspection, user, openFile, reopenContent }) => {
	const { t, i18n } = useTranslation()
	const {
		getSourceMaterialList,
		getTargetMaterialList,
		updateInspectionFile,
		openUpdateFileModal,
		setopenUpdateFileModal,
		uploadedFile,
		setUploadedFile,
		selectedReport,
		updateInspection,
		ownerships,
		endUsers,
		materials,
		downloadingFile,
		loadingInspInfo
	} = useContext(PrepareInspectionContext)

	const listDeadSpace = 280
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)

	const listRef = useRef(null)
	const materialListRef = useRef(null)
	const [sourceMaterialList, setSourceMaterialList] = useState([])
	const [targetMaterialList, setTargetMaterialList] = useState([])

	const [loadingMaterials, setLoadingMaterials] = useState(false)
	const [fileUpdate, setFileUpdate] = useState(false)
	const applicationID = process.env.REACT_APP_ID

	const materialStr = t('wms:Material')

	useEffect(() => {
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listRef, window])

	useEffect(() => {
		const isModifProd = selectedInspection.is_modified_product
		const hydrate = async () => {
			if (isModifProd) {
				setLoadingMaterials(true)
				try {
					report.summary.enduser
						? await getSourceMaterialList(report.summary.enduser).then(list =>
								setSourceMaterialList(_.uniqBy(list, 'id').sort((a, b) => a.id - b.id))
						  )
						: setSourceMaterialList([])
					report.summary.enduser
						? await getTargetMaterialList(report.summary.enduser).then(list =>
								setTargetMaterialList(_.uniqBy(list, 'id').sort((a, b) => a.id - b.id))
						  )
						: setTargetMaterialList([])
				} catch (e) {
					console.error(e)
				} finally {
					setLoadingMaterials(false)
				}
			} else {
				setSourceMaterialList([])
				setTargetMaterialList([])
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report, selectedInspection])

	const showSourceMaterial = id => {
		let mat = _.find(sourceMaterialList, material => material.id === id)
		return mat ? mat.desc : '*****'
	}

	const showTargetMaterial = id => {
		let mat = _.find(targetMaterialList, material => material.id === id)
		return mat ? mat.desc : '*****'
	}

	const showRack = id => {
		let rack = _.find(rackList, r => r.id === id)
		return rack ? rack.fullname : ''
	}

	const handleReopen = content => {
		reopenContent(content)
	}

	const updateFile = async () => {
		try {
			setFileUpdate(true)
			let data
			await updateInspectionFile(uploadedFile, selectedInspection.stage, uploadedFile.name.split('.').pop()).then(
				fileName => {
					const isReturn = selectedInspection && !selectedInspection.is_modified_product
					let expected_date = moment(new Date()).set('minute', 59)
					let closure_date = selectedReport.closure_date ? moment(selectedReport.closure_date) : null
					let contents = selectedReport.contents
					let summary = selectedReport.summary
					let file = fileName
					let deletedcontents = []
					let filters = selectedReport.filters
					let number_of_parts = selectedReport.contents.length
					isReturn
						? (data = {
								expected_date,
								closure_date,
								contents,
								summary,
								file,
								deletedcontents,
								filters,
								number_of_parts
						  })
						: selectedInspection.is_filter_on_content
						? (data = { expected_date, closure_date, contents, summary, file, deletedcontents })
						: (data = { expected_date, closure_date, contents, summary, file, deletedcontents, filters })
				}
			)
			await updateInspection(data, selectedInspection.stage, report.summary.id, report.summary.type)
				.then(response => response)
				.finally(() => setFileUpdate(false))
		} catch (e) {
			console.error(e)
			setFileUpdate(false)
		}
	}

	const closeModal = () => {
		setopenUpdateFileModal(false)
		setUploadedFile([])
	}

	const renderOwnership = owner => {
		let ownership = _.find(ownerships, o => o.id === owner)
		return ownership ? ownership.label : '****'
	}

	const renderEndUser = enduser => {
		let endUser = _.find(endUsers, eu => eu.id === enduser)
		return endUser ? endUser.name : '****'
	}

	const renderMaterial = material => {
		let mat = _.find(materials, m => m.mvid === material)
		return mat ? mat.formatted : '****'
	}

	return (
		<Card width='calc(100% - 48px)' flex='1' margin='0' ref={listRef} style={{ position: 'relative' }}>
			<LoadingOverlay visible={loadingInspInfo} borderRadius='card' />
			<FlexView width='100%' fontSize='medium' fontWeight='bold' margin='0 0 16px 0'>
				{t('wms:InspectionInfo')}
			</FlexView>
			<FlexView
				width='100%'
				margin='0'
				flexDirection='row'
				flexWrap='wrap'
				padding='0'
				justifyContent='space-between'>
				{report && report.summary.id && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column' data-cy='card-info'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							ID
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.summary.id}
						</FlexView>
					</FlexView>
				)}
				{report && report.summary.work_order_description && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column' data-cy='card-info'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:WorkOrderDescription')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.summary.work_order_description}
						</FlexView>
					</FlexView>
				)}
				{report &&
					report.summary.date &&
					selectedInspection &&
					selectedInspection.stage !== INSPECTION_STAGES.code.PERIODICAL && (
						<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column' data-cy='card-info'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:ExpectedDate')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{DATE_FORMAT(report.summary.date, i18n.language)}
							</FlexView>
						</FlexView>
					)}
				{report && report.summary.customer_request_reference && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column' data-cy='card-info'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:CustomerRequestReference')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.summary.customer_request_reference}
						</FlexView>
					</FlexView>
				)}
				{report && report.summary.delivery_location && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:DeliveryLocation')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.summary.delivery_location}
						</FlexView>
					</FlexView>
				)}
				{report && report.summary.tparty_name && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:ThirdPartyName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.summary.tparty_name}
						</FlexView>
					</FlexView>
				)}
				{report && report.summary.tparty_location && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:ThirdPartyLocation')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.summary.tparty_location}
						</FlexView>
					</FlexView>
				)}
				{report && report.summary.field_name && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:FieldName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.summary.field_name}
						</FlexView>
					</FlexView>
				)}
				{report && report.summary.well_name && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:WellName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.summary.well_name}
						</FlexView>
					</FlexView>
				)}
				{report && report.summary.rig_name && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:RigName')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.summary.rig_name}
						</FlexView>
					</FlexView>
				)}
				{report && report.summary.ltpa_number && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column' data-cy='card-info'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:NumberLTPA')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.summary.ltpa_number}
						</FlexView>
					</FlexView>
				)}
				{report && report.info.enduser && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:EndUser')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{renderEndUser(report.info.enduser)}
						</FlexView>
					</FlexView>
				)}
				{report && report.info.sourceownership && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:SourceOwnership')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{renderOwnership(report.info.sourceownership)}
						</FlexView>
					</FlexView>
				)}
				{report && report.info.targetownership && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:TargetOwnership')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{renderOwnership(report.info.targetownership)}
						</FlexView>
					</FlexView>
				)}
				{report && report.info.level && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:Rack')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{showRack(report.info.level)}
						</FlexView>
					</FlexView>
				)}
				<FlexView width='100%' flexDirection='row' justifyContent='space-between'>
					{report && report.info.customerorder && (
						<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:CustomerOrder')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{report.info.customerorder}
							</FlexView>
						</FlexView>
					)}
					{report && report.info.customeritem && (
						<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:CustomerItem')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{report.info.customeritem}
							</FlexView>
						</FlexView>
					)}
				</FlexView>
				{report && report.info.salesorder && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:SalesOrder')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.info.salesorder}
						</FlexView>
					</FlexView>
				)}
				{report && report.info.salesitem && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{t('wms:SalesItem')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{report.info.salesitem}
						</FlexView>
					</FlexView>
				)}
				{report && report.info.material && (
					<FlexView width='95%' margin='0 0 8px 0' padding='0' flexDirection='column'>
						<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
							{materialStr}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}` }}
							padding='0 0 0 8px'>
							{renderMaterial(report.info.material)}
						</FlexView>
					</FlexView>
				)}
				{selectedInspection.stage === INSPECTION_STAGES.code.RETURN &&
					selectedInspection.is_modified_product &&
					report &&
					report.info.materialsource && (
						<FlexView width='95%' margin='0 0 8px 0' padding='0' flexDirection='column'>
							<LoadingOverlay visible={loadingMaterials} />
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:SourceMaterial')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}` }}
								padding='0 0 0 8px'>
								{showSourceMaterial(report.info.materialsource)}
							</FlexView>
						</FlexView>
					)}
				{selectedInspection.stage === INSPECTION_STAGES.code.RETURN &&
					selectedInspection.is_modified_product &&
					report &&
					report.info.material && (
						<FlexView width='95%' margin='0 0 8px 0' padding='0' flexDirection='column'>
							<LoadingOverlay visible={loadingMaterials} />
							<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
								{t('wms:TargetMaterial')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}` }}
								padding='0 0 0 8px'>
								{showTargetMaterial(report.info.material)}
							</FlexView>
						</FlexView>
					)}
				{report && report.summary.revision && report.summary.responsible && report.summary.revision_date && (
					<FlexView width='100%' flexDirection='row'>
						<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column' data-cy='card-info'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:Revision')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{report.summary.revision}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column' data-cy='card-info'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:UpdatedBy')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{report.summary.responsible}
							</FlexView>
						</FlexView>
						<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column' data-cy='card-info'>
							<FlexView
								width='100%'
								fontWeight='bold'
								fontSize='tiny'
								color='metalic'
								margin='0'
								data-cy='list-label'>
								{t('wms:UpdatedOn')}
							</FlexView>
							<FlexView
								width='100%'
								style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
								padding='0 0 0 8px'>
								{DATE_FORMAT(report.summary.revision_date, i18n.language)}
							</FlexView>
						</FlexView>
					</FlexView>
				)}
				{report && report.summary.entrance_date && selectedInspection && (
					<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column' data-cy='card-info'>
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='tiny'
							color='metalic'
							margin='0'
							data-cy='list-label'>
							{t('wms:EntranceDate')}
						</FlexView>
						<FlexView
							width='100%'
							style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
							padding='0 0 0 8px'>
							{DATE_FORMAT(report.summary.entrance_date, i18n.language)}
						</FlexView>
					</FlexView>
				)}
				<FlexView
					width='100%'
					fontWeight='bold'
					fontSize='medium'
					margin='0 0 8px 0'
					style={{ borderTop: '1px solid whitesmoke' }}>
					{t('wms:Content')}
				</FlexView>
				<FlexView width='100%' height={`${listHeight}px`} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
					{report &&
						report.info &&
						report.info.length &&
						report.info.map((info, index) => (
							<FlexView
								key={info.id}
								width='100%'
								margin='0'
								flexDirection='row'
								flexWrap='wrap'
								padding='0'
								justifyContent='space-between'
								style={{
									borderTop: !index ? 'none' : '1px solid whitesmoke'
								}}>
								<FlexView
									flexDirection='row'
									width='100%'
									justifyContent='space-between'
									alignItems='center'
									margin='0 0 8px 0'>
									<FlexView fontSize='small' margin='0' flexDirection='row' width='70%'>
										<strong>{`${t('wms:Part')} ${info.part}`}</strong>
									</FlexView>
									{info.status === STATUS.code.DONE && info.acceptedquantity > 0 && (
										<>
											<FlexView
												width='100%'
												minWidth='200px'
												flexDirection='column'
												padding='0 0 0 8px'
												fontSize='small'>
												{`${t('wms:AcceptedQuantity')}: ${info.acceptedquantity}`}
											</FlexView>
											{selectedInspection && selectedInspection.stage === INSPECTION_STAGES.code.RETURN && (
												<FlexView
													fontSize='small'
													margin='0 5px 0 5px'
													width='100%'
													flexDirection='row'
													justifyContent='center'>
													{`${t('wms:Untraceable')}: ${info.ntquantity ? info.ntquantity : 0}`}
												</FlexView>
											)}
										</>
									)}
									{info.status !== STATUS.code.DONE && info.quantity > 0 && (
										<FlexView
											width='100%'
											minWidth='200px'
											flexDirection='column'
											padding='0 0 0 8px'
											fontSize='small'>
											{`${t('wms:Quantity')}: ${info.quantity}`}
										</FlexView>
									)}
									<FlexView
										width='100%'
										margin='0'
										padding='0'
										flexDirection='row'
										flexWrap='wrap'
										justifyContent='center'>
										<FlexView
											fontSize='small'
											color={STATUS.color[info.status]}
											style={{ borderLeft: `2px solid ${theme.colors[STATUS.color[info.status]]}` }}
											margin='0 0 0 auto'
											padding='8px 16px'>
											{t(`wms:${STATUS.literal[info.status]}`)}
										</FlexView>
										{info.status === STATUS.code.RUNNING &&
											Object.keys(user.roles[applicationID]).find(r => r === 'ADMIN') && (
												<Button
													fontSize='tiny'
													margin='0 0 0 8px'
													color='white'
													backgroundColor='secondary'
													disabled={false}
													onClick={() => handleReopen(info)}>
													{t('wms:Reopen')}
												</Button>
											)}
									</FlexView>
								</FlexView>
								{report && info.enduser && (
									<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:EndUser')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{renderEndUser(info.enduser)}
										</FlexView>
									</FlexView>
								)}
								{report && info.source_ownership_name && (
									<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:SourceOwnership')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{info.source_ownership_name}
										</FlexView>
									</FlexView>
								)}
								{report && info.target_ownership_name && (
									<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:TargetOwnership')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{info.target_ownership_name}
										</FlexView>
									</FlexView>
								)}
								{info.status === STATUS.code.DONE && info.materialList && info.materialList.length > 0 && (
									<FlexView
										width='100%'
										margin='0 0 8px 0'
										padding='0'
										flexDirection='column'
										ref={materialListRef}
										style={{ position: 'relative' }}>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:Materials')}
										</FlexView>
										<FlexView width='100%' margin='0'>
											{info.materiallist.map(material => (
												<FlexView
													key={material.mvid}
													width='100%'
													margin='4px 0 0 0'
													style={{
														borderLeft: `2px solid ${theme.colors.primary}`,
														lineHeight: '24px'
													}}
													padding='0 0 0 8px'>
													{material.formatted}
												</FlexView>
											))}
										</FlexView>
									</FlexView>
								)}
								{report && info.level && (
									<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:Rack')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{showRack(info.level)}
										</FlexView>
									</FlexView>
								)}
								{report && info.customerorder && (
									<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:CustomerOrder')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{info.customerorder}
										</FlexView>
									</FlexView>
								)}
								{report && info.customeritem && (
									<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:CustomerItem')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{info.customeritem}
										</FlexView>
									</FlexView>
								)}
								{report && info.salesorder && (
									<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:SalesOrder')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{info.salesorder}
										</FlexView>
									</FlexView>
								)}
								{report && info.salesitem && (
									<FlexView width='33%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{t('wms:SalesItem')}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{info.salesitem}
										</FlexView>
									</FlexView>
								)}
								{info.material && (
									<FlexView width='95%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{materialStr}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{renderMaterial(info.material)}
										</FlexView>
									</FlexView>
								)}
								{report && info.material == null && info.materialdescription && (
									<FlexView width='95%' margin='0 0 8px 0' padding='0' flexDirection='column'>
										<FlexView width='100%' fontWeight='bold' fontSize='tiny' color='metalic' margin='0'>
											{materialStr}
										</FlexView>
										<FlexView
											width='100%'
											style={{ borderLeft: `2px solid ${theme.colors.primary}`, lineHeight: '24px' }}
											padding='0 0 0 8px'>
											{info.materialdescription}
										</FlexView>
									</FlexView>
								)}
							</FlexView>
						))}
				</FlexView>
			</FlexView>
			<FlexView width='100%' justifyContent='flex-end' margin='auto 0 0 auto' flexDirection='row'>
				{report && report.summary.file && (
					<>
						<Button
							fontSize='small'
							color='white'
							margin='0'
							backgroundColor='secondary'
							disabled={false}
							onClick={() => setopenUpdateFileModal(true)}>
							{`${t('wms:Update')} ${t('wms:File')}`}
						</Button>
						<Button
							fontSize='small'
							color='white'
							margin='0 0 0 16px'
							backgroundColor='secondary'
							disabled={false}
							onClick={() => openFile()}>
							{`${t('wms:View')} ${t('wms:File')}`}
						</Button>
						<LoadingOverlay visible={downloadingFile} borderRadius='card' />
					</>
				)}
			</FlexView>
			<UpdateFileModal
				isOpen={openUpdateFileModal}
				onOutsideClick={() => closeModal()}
				uploadedFile={uploadedFile}
				setUploadedFile={setUploadedFile}
				onUpdateFile={() => updateFile()}
				loading={fileUpdate}
			/>
		</Card>
	)
}

export default PrepareInspectionInfo
