/** @format */

import React, { useState, useEffect } from 'react'
import { Icon, FlexView } from 'components/common'
import { Input } from 'components/form'

const isNumber = value => {
	const number = Number(value)
	return !isNaN(number) && String(value) === String(number)
}

const formatTimeItem = value => {
	return `${value || ''}00`.substr(0, 2)
}

const validateTimeAndCursor = (value = '', defaultValue = '', cursorPosition = 0) => {
	const [oldH, oldM] = defaultValue.split(':')

	let newCursorPosition = Number(cursorPosition)
	let [newH, newM] = String(value).split(':')

	newH = formatTimeItem(newH)
	if (Number(newH[0]) > 2) {
		newH = oldH
		newCursorPosition -= 1
	} else if (Number(newH[0]) === 2) {
		if (Number(oldH[0]) === 2 && Number(newH[1]) > 3) {
			newH = `2${oldH[1]}`
			newCursorPosition -= 2
		} else if (Number(newH[1]) > 3) {
			newH = '23'
		}
	}

	newM = formatTimeItem(newM)
	if (Number(newM[0]) > 5) {
		newM = oldM
		newCursorPosition -= 1
	}

	const validatedValue = `${newH}:${newM}`

	return [validatedValue, newCursorPosition]
}

// eslint-disable-next-line react/prop-types
const TimeInput = ({ value, onChange }) => {
	const [cursor, setCursor] = useState({
		element: null,
		position: null
	})

	useEffect(() => {
		if (cursor.element) {
			cursor.element.selectionStart = cursor.position
			cursor.element.selectionEnd = cursor.position
		}
	})

	const checkAddedCharacter = (
		position,
		newPosition,
		positionThreeOrSix,
		addedCharacter,
		newValue,
		inputValue,
		isNumberCharacter
	) => {
		if (position > 5) {
			newPosition = 5
		} else if (positionThreeOrSix && addedCharacter === ':') {
			newValue = `${inputValue.substr(0, position - 1)}:${inputValue.substr(position + 1)}`
		} else if (positionThreeOrSix && isNumberCharacter) {
			newValue = `${inputValue.substr(0, position - 1)}:${addedCharacter}${inputValue.substr(position + 2)}`
			newPosition = position + 1
		} else if (isNumberCharacter) {
			// user typed a number
			newValue = inputValue.substr(0, position - 1) + addedCharacter + inputValue.substr(position + 1)
			if (position === 2 || position === 5) {
				newPosition = position + 1
			}
		} else {
			// if user typed NOT a number, then keep old value & position
			newPosition = position - 1
		}
		return [newValue, newPosition]
	}

	const checkReplacedSingleCharacter = (
		isNumberCursorCharacter,
		position,
		newValue,
		inputValue,
		oldValue,
		newPosition
	) => {
		// user replaced only a single character
		if (isNumberCursorCharacter) {
			if (position - 1 === 2 || position - 1 === 5) {
				newValue = `${inputValue.substr(0, position - 1)}:${inputValue.substr(position)}`
			} else {
				newValue = inputValue
			}
		} else {
			// user replaced a number on some non-number character
			newValue = oldValue
			newPosition = position - 1
		}
		return [newValue, newPosition]
	}

	const onInputChange = event => {
		const oldValue = value
		const inputEl = event.target
		const inputValue = inputEl.value
		const position = inputEl.selectionEnd || 0
		const isTyped = inputValue.length > oldValue.length
		const cursorCharacter = inputValue[position - 1]
		const addedCharacter = isTyped ? cursorCharacter : null
		const removedCharacter = isTyped ? null : oldValue[position]
		const replacedSingleCharacter = inputValue.length === oldValue.length ? oldValue[position - 1] : null

		let newValue = oldValue
		let newPosition = position
		const positionThreeOrSix = position === 3 || position === 6
		const isNumberCharacter = isNumber(addedCharacter)
		const isNumberCursorCharacter = isNumber(cursorCharacter)

		if (addedCharacter !== null) {
			const values = checkAddedCharacter(
				position,
				newPosition,
				positionThreeOrSix,
				addedCharacter,
				newValue,
				inputValue,
				isNumberCharacter
			)
			newValue = values[0]
			newPosition = values[1]
		} else if (replacedSingleCharacter !== null) {
			const values = checkReplacedSingleCharacter(
				isNumberCursorCharacter,
				position,
				newValue,
				inputValue,
				oldValue,
				newPosition
			)
			newValue = values[0]
			newPosition = values[1]
		} else if (typeof cursorCharacter !== 'undefined' && cursorCharacter !== ':' && !isNumberCursorCharacter) {
			// set of characters replaced by non-number
			newValue = oldValue
			newPosition = position - 1
		} else if (removedCharacter !== null) {
			if ((position === 2 || position === 5) && removedCharacter === ':') {
				newValue = `${inputValue.substr(0, position - 1)}0:${inputValue.substr(position)}`
				newPosition = position - 1
			} else {
				// user removed a number
				newValue = `${inputValue.substr(0, position)}0${inputValue.substr(position)}`
			}
		}

		const [validatedTime, validatedCursorPosition] = validateTimeAndCursor(newValue, oldValue, newPosition)

		setCursor({
			element: inputEl,
			position: validatedCursorPosition
		})
		onChange(validatedTime)

		event.persist()
	}

	return (
		<FlexView width='fit-content' flexDirection='row' justifyContent='center' alignItems='center'>
			<Icon name='clock' width='32px' height='32px' margin='0px 8px 0px 0px' />
			<Input margin='0px' width='77px' value={value} onChange={onInputChange} align='center' />
		</FlexView>
	)
}

export default TimeInput
