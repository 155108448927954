/** @format */
/* eslint-disable react/prop-types */

import UploadFileBox from 'apps/wms/components/upload/UploadFileBox'
import { Button, Card, FlexView, Icon, LoadingOverlay, Modal } from 'components/common'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

const UpdateFileModal = ({ isOpen, onOutsideClick, uploadedFile, setUploadedFile, onUpdateFile, loading }) => {
	const { t } = useTranslation()
	const allowedFiles = ['.pdf', '.png', '.jpg', '.ods', '.doc', '.docx', '.odt', '.ppt', '.pptx', '.xls', '.xlsx']

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card width='30%' height='30%'>
				<FlexView
					width='100%'
					fontSize='title'
					fontWeight='bold'
					flexDirection='row'
					justifyContent='space-between'>
					{`${t('wms:Update')} ${t('wms:File')}`}
					<Button
						fontSize='tiny'
						margin='0 0 0 16px'
						backgroundColor='error'
						disabled={false}
						onClick={onOutsideClick}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<UploadFileBox
					fontSize='5'
					fileTypes={allowedFiles}
					setFile={setUploadedFile}
					text={!_.isEmpty(uploadedFile) ? uploadedFile.name : t('wms:UploadFileMessage')}
					height='50'
				/>
				<Button
					backgroundColor='secondary'
					color='white'
					margin='auto 0 0 auto'
					onClick={() => onUpdateFile()}
					disabled={!uploadedFile || uploadedFile.length === 0}>
					{t('wms:Update')}
					<Icon name={'refresh'} color='white' height='14px' width='14px' margin='0 0 0 8px' />
				</Button>
				<LoadingOverlay visible={loading} borderRadius='card' />
			</Card>
		</Modal>
	)
}

export default UpdateFileModal
