/** @format */

import React, { useState, useContext, useCallback, useRef, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexView } from 'components/common'

import PageTemplate from '../containers/common/PageTemplate'
import { AssetsMaterialContext } from '../stores/AssetsMaterialStore'
import MaterialCatalog from '../containers/assets/material/MaterialList'
import EnduserSelect from '../containers/assets/material/EnduserModal'
import { toast } from 'react-toastify'
import MaterialHandlerModal from '../containers/assets/material/MaterialCreateModal'
import MaterialEditModal from '../containers/assets/material/MaterialEditModal'
import MaterialConfig from '../containers/assets/material/MaterialConfig'
import { useWindowSize } from '../utils/hooks'
import { assets, genParams } from '../utils/hubs'

const AssetsMaterialPage = () => {
	const { t } = useTranslation()
	const {
		importMaterial,
		isSyncOn,
		setIsSyncOn,
		enduserList,
		loadMaterialList,
		isMatHandlerOpen,
		setIsMatHandlerOpen,
		isMatEditorOpen,
		setIsMatEditorOpen
	} = useContext(AssetsMaterialContext)
	const [isLoadingResponse, setIsLoadingResponse] = useState(false)
	const onOutsideClick = useCallback(() => {
		setIsSyncOn(false)
	}, [setIsSyncOn])

	const listDeadSpace = 80
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const cardRef = useRef(null)

	useEffect(() => {
		setListHeight(cardRef.current.offsetHeight - listDeadSpace)
	}, [cardRef, window])

	const closeHandler = useCallback(
		() => setIsMatHandlerOpen(false),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const closeEditor = useCallback(
		() => setIsMatEditorOpen(false),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const _onMaterialImport = async (enduser, importFn, isLoadingFn, isSyncFn, loadMaterialFn, t) => {
		try {
			isLoadingFn(true)
			await importFn(enduser)
			isLoadingFn(false)
			isSyncFn(false)
			loadMaterialFn()
		} catch (error) {
			isLoadingFn(false)
			console.error(error)
			toast.error(t(`${t('wms:ErrorOnPipeDataSync')}. ${error.data}`))
		}
	}

	const onMaterialImport = useCallback(
		async enduser =>
			_onMaterialImport(enduser, importMaterial, setIsLoadingResponse, setIsSyncOn, loadMaterialList, t),
		[importMaterial, setIsLoadingResponse, setIsSyncOn, loadMaterialList, t]
	)

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:Assets'),
				path: `/wms/assets${genParams(assets)}`
			},
			{
				name: t('wms:MaterialManagement'),
				path: '/wms/assets/material'
			}
		],
		[t]
	)

	return (
		<PageTemplate
			crumbs={crumbs}
			overflow={isMatHandlerOpen || isSyncOn || setIsMatEditorOpen ? 'hidden' : 'inherit'}>
			<FlexView
				width='100%'
				maxWidth='calc(100% - 80px)'
				flex='1'
				padding='0 0 40px 0'
				margin='0 40px'
				style={{ overflowX: 'scroll' }}>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:MaterialManagement')}
				</FlexView>
				<FlexView
					ref={cardRef}
					flexDirection='row'
					justifyContent='flex-start'
					padding='0'
					margin='0'
					width='calc(100%)'
					height='100%'>
					<FlexView width='fit-content' margin='0 24px 0 0' flexDirection='row' height='100%'>
						<MaterialConfig height={listHeight} />
					</FlexView>
					<FlexView minWidth='calc(100% - 148px)' flexDirection='row' height='100%'>
						<MaterialCatalog />
					</FlexView>
				</FlexView>
			</FlexView>
			<EnduserSelect
				isOpen={isSyncOn}
				onOutsideClick={onOutsideClick}
				enduserList={enduserList}
				onPipeDataSync={onMaterialImport}
				loadingResponse={isLoadingResponse}
			/>
			<MaterialHandlerModal isOpen={isMatHandlerOpen} onOutsideClick={closeHandler} />
			<MaterialEditModal isOpen={isMatEditorOpen} onOutsideClick={closeEditor} />
		</PageTemplate>
	)
}

export default AssetsMaterialPage
