/** @format */

import React from 'react'
import { useContext, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { AssetsReportRefContext } from 'apps/wms/stores/AssetsReportRefStore'
import { Button, Card, FlexView, Icon } from 'components/common'
import LoadingOverlay from 'components/common/LoadingOverlay'
import Modal from 'components/common/Modal'
import { Input } from 'components/form'

const UpdateInspectionRefModal = () => {
	const { t } = useTranslation()
	const {
		inspectionReportTypes,
		openUpdateInspectionModal,
		setOpenUpdateInspectionModal,
		inspectionReportFormData,
		setInspectionReportFormData,
		createInspectionReportReference,
		updateInspectionReportReference,
		isInspectionReportUpdate,
		isUpdatingInspectionReportData
	} = useContext(AssetsReportRefContext)
	const reportReferenceInputRef = useRef(null)
	const [newReference, setNewReference] = useState(inspectionReportFormData ? inspectionReportFormData.reference : '')
	const [submitting, setSubmitting] = useState(false)

	const getReportTypeName = typeId => {
		const reportType = _.find(inspectionReportTypes, t => t.id === typeId)
		if (reportType) return reportType.name
		return 'N/A'
	}

	const handleSubmit = async e => {
		e.preventDefault()
		reportReferenceInputRef.current.blur()
		setInspectionReportFormData(prev => ({ ...prev, reference: newReference }))
		setSubmitting(true)
	}

	useEffect(() => {
		const submit = async () => {
			try {
				if (isInspectionReportUpdate) await updateInspectionReportReference()
				else await createInspectionReportReference()
				setNewReference('')
			} finally {
				setSubmitting(false)
			}
		}
		if (submitting) submit()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submitting])

	useEffect(() => {
		if (inspectionReportFormData) setNewReference(inspectionReportFormData.reference)
	}, [inspectionReportFormData])

	return (
		<Modal isOpen={openUpdateInspectionModal} onOutsideClick={() => setOpenUpdateInspectionModal(false)}>
			<Card data-cy='card-update-reference-report'>
				<FlexView
					width='100%'
					flexDirection='row'
					justifyContent='space-between'
					alignItems='center'
					fontSize='big'
					fontWeight='bold'
					margin='0 0 32px 0'>
					{t('wms:UpdateInspectionReportReference')}
					<Button
						fontSize='tiny'
						margin='0 0 0 32px'
						padding='10px'
						backgroundColor='error'
						disabled={false}
						onClick={() => setOpenUpdateInspectionModal(false)}>
						<Icon name='cross-simple' color='white' height='12px' width='12px' margin='0' />
					</Button>
				</FlexView>
				<FlexView as='form' width='100%' alignItems='flex-end' onSubmit={handleSubmit}>
					<Input
						width='100%'
						margin='0 0 16px 0'
						type='text'
						label={t('wms:ReportReferenceType')}
						placeholder={t('wms:ReportReferenceType')}
						value={inspectionReportFormData && getReportTypeName(inspectionReportFormData.report_reference_type)}
						disabled
						data-cy='input-report-reference-type'
					/>
					<Input
						ref={reportReferenceInputRef}
						width='100%'
						margin='0 0 16px 0'
						type='text'
						label={t('wms:Reference')}
						placeholder={t('wms:Reference')}
						value={newReference}
						onChange={e => setNewReference(e.target.value)}
						data-cy='input-report-reference'
					/>
					<Button
						type='submit'
						backgroundColor='success'
						color='white'
						onClick={handleSubmit}
						disabled={!newReference || isUpdatingInspectionReportData}
						data-cy='button-save-reference'>
						{t('wms:Save')}
						<Icon name='save' color='white' width='14px' height='14px' margin='0 0 0 8px' />
					</Button>
					<LoadingOverlay visible={isUpdatingInspectionReportData} borderRadius='card' />
				</FlexView>
			</Card>
		</Modal>
	)
}

export default UpdateInspectionRefModal
