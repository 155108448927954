/** @format */

import { Button, FlexView, Icon } from 'components/common'
import { Input, Select } from 'components/form'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageTemplate from '../containers/common/PageTemplate'
import { DatePicker } from 'components/form'
import DialogModal from 'apps/wms/components/forms/DialogModal'

import styled from 'styled-components'
import { AgeManagementContext } from '../stores/PipeAgeManagementStore'
import AgeManagementItems from '../containers/pipeAgeManagement/items'
import { useWindowSize } from '../utils/hooks'
import { toast } from 'react-toastify'
import { _ } from 'globalthis/implementation'
import { WMSContext } from '../stores/WmsStore'
import moment from 'moment'
import { exportAsExcelFile } from '../utils/excelExport'

const DateWrapper = styled.div`
	min-width: 200px;
	width: 15%;
	margin-right: 16px;
	> div {
		width: 100%;
		> div[label] {
			width: calc(100% - 28px);
		}
	}
`

const PipeAgeManagementPage = () => {
	const { t } = useTranslation()
	const { getAgeMgmtListData, createAgeManagementList, extracts, ageMgmtType, setAgeMgmtType, getEnduserList } =
		useContext(AgeManagementContext)
	const { warehouse, lengthUnit, weightUnit, convertToCurrentLengthUnit, convertToCurrentWeightUnit } =
		useContext(WMSContext)

	const [endusers, setEndusers] = useState([])
	const [enduser, setEnduser] = useState(null)
	const [thresholdDate, setThresholdDate] = useState(null)
	const [ltpaNumber, setLtpaNumber] = useState('')
	const [loading, setLoading] = useState(false)
	const [issuedItems, setIssuedItems] = useState([])
	const [confirmListApproval, setConfirmListApproval] = useState(false)
	const [canDownloadExtract, setCanDownloadExtract] = useState(false)

	const modalDeadSpace = 170
	const window = useWindowSize()
	const [tableHeight, setTableHeight] = useState(0)
	const parentRef = useRef(null)
	const headerRef = useRef(null)

	const lengthString = t('wms:Length')
	const weightString = t('wms:Weight')

	const currentHeaderHeight = useMemo(
		() => (headerRef.current !== null ? headerRef.current.offsetHeight : 0),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[headerRef, ageMgmtType]
	)

	useEffect(() => {
		setTableHeight(parentRef.current.offsetHeight - modalDeadSpace - currentHeaderHeight)
	}, [parentRef, currentHeaderHeight, window])
	//const window = useWindowSize()

	useEffect(() => {
		getEnduserList()
			.then(endusersList => endusersList && endusersList.length && setEndusers(endusersList))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setIssuedItems([])
	}, [ageMgmtType, enduser, warehouse])

	const onIssueAgeMgmtList = () => {
		setLoading(true)
		getAgeMgmtListData(ageMgmtType, enduser, thresholdDate, ltpaNumber)
			.then(response => {
				const items =
					response &&
					response.list &&
					response.list.map(res => ({
						...res,
						length: parseFloat(convertToCurrentLengthUnit(res.length, lengthUnit).replace(',', '')),
						weight: parseFloat(convertToCurrentWeightUnit(res.weight, weightUnit).replace(',', ''))
					}))
				setIssuedItems(items)
			})
			.catch(e => console.error(e))
			.finally(() => setLoading(false))
	}

	const onApproveIssuedList = () => {
		setConfirmListApproval(true)
	}

	const resetData = useCallback(() => {
		setAgeMgmtType(null)
		setEnduser(null)
		setThresholdDate(null)
		setLtpaNumber('')
		setIssuedItems([])
		setCanDownloadExtract(false)
		return true
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const approveIssuedList = () => {
		setLoading(true)
		createAgeManagementList(ageMgmtType, enduser, thresholdDate, ltpaNumber)
			.then(() => resetData() && toast.success(t('wms:ListIssuedSuccessfully')))
			.catch(e => console.error(e))
			.finally(() => setLoading(false))
		return true
	}

	const mayIssueList = () =>
		ageMgmtType !== null && enduser !== null && thresholdDate !== null && ltpaNumber !== null && ltpaNumber !== ''

	const getListTranslateKey = () => {
		let age = _.find(extracts, extract => extract.extractid === ageMgmtType)
		return age.extractdesc
	}

	useEffect(() => {
		resetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setCanDownloadExtract(issuedItems.length > 0 ? true : false)
	}, [issuedItems])

	const columns = useMemo(
		() => [
			{
				accessor: 'customerpo',
				Header: t('wms:CustomerOrder'),
				show: true
			},
			{
				accessor: 'customeritem',
				Header: t('wms:CustomerItem'),
				show: true
			},
			{
				accessor: 'salesorder',
				Header: t('wms:SalesOrder'),
				show: true
			},
			{
				accessor: 'salesitem',
				Header: t('wms:SalesItem'),
				show: true
			},
			{
				accessor: 'erpreference',
				Header: t('wms:ErpReference'),
				show: true
			},
			{
				accessor: 'materialdesc',
				Header: 'Material',
				show: true
			},
			{
				accessor: 'filtercolumns',
				Header: t('wms:ConsignmentType'),
				formatter: attrib => (attrib[0].attributevalue ? attrib[0].attributevalue : ''),
				show: true
			},
			{
				accessor: 'ownershipdesc',
				Header: t('wms:Ownership'),
				show: true
			},
			{
				accessor: 'entrancedate',
				Header: t('wms:EntranceDate'),
				formatter: entrancedate => (entrancedate ? moment(entrancedate).format('MM/DD/YYYY h:mm:ss a') : ''),
				show: true
			},
			{
				accessor: 'age',
				Header: t('wms:Age') + '[Months]',
				formatter: age => (age ? Number(age).toFixed(3) : ''),
				show: true
			},
			{
				accessor: 'quantity',
				Header: t('wms:Joints'),
				show: true
			},
			{
				accessor: 'length',
				Header: `${lengthString} [${lengthUnit}]`,
				show: true
			},
			{
				accessor: 'weight',
				Header: `${weightString} [${weightUnit}]`,
				show: true
			}
		],
		[t, lengthString, lengthUnit, weightString, weightUnit]
	)

	const handleExtractDownload = () => {
		const excelData = _.map(issuedItems, row =>
			_.reduce(
				columns,
				(acc, { accessor, Header, formatter }) => {
					const value = _.get(row, accessor)
					return {
						...acc,
						[Header]: formatter ? formatter(value) : value
					}
				},
				{}
			)
		)

		const formattedColumms = excelData.map(item => {
			return {
				...item,
				[`${lengthString} [${lengthUnit}]`]: Number(item[`${lengthString} [${lengthUnit}]`]),
				[`${weightString} [${weightUnit}]`]: Number(item[`${weightString} [${weightUnit}]`])
			}
		})

		exportAsExcelFile(
			formattedColumms,
			`${t('wms:ExtractList')} ${moment(thresholdDate).format('MM/DD/YYYY h:mm:ss a')}`
		)
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: t('wms:AgeManagement'),
				path: '/wms/manage/pipeagemanagement'
			}
		],
		[t]
	)

	return (
		<PageTemplate crumbs={crumbs} loadingOverlay={loading}>
			<FlexView width='100%' maxWidth='calc(100% - 160px)' flex='1' padding='0 0 40px 0' ref={parentRef}>
				<FlexView minWidth='100%' fontSize='40px' fontWeight='bold' margin='0 0 24px 0'>
					{t('wms:AgeManagement')}
				</FlexView>
				<FlexView width='100%' flexWrap='wrap' flexDirection='row' aligntItems='center' ref={headerRef}>
					{extracts && (
						<Select
							label={`${t('wms:ExtractName')}:`}
							placeholder={t('wms:ExtractName')}
							value={ageMgmtType}
							options={
								extracts &&
								_.map(extracts, opt => {
									return { value: opt.extractid, label: opt.extractdesc }
								})
							}
							onChange={value => {
								setAgeMgmtType(value)
							}}
							width='25%'
							margin='0 16px 0 0'
							data-cy='select-age-mgmt-type'
							fontSize='small'
						/>
					)}
					<Select
						label={`${t('wms:EndUser')}:`}
						placeholder={t(`wms:EndUser`)}
						value={enduser && enduser.id}
						options={endusers.map(({ id, name }) => ({
							value: id,
							label: name
						}))}
						onChange={value => {
							setEnduser(endusers.find(eu => eu.id === value))
						}}
						width='15%'
						margin='0 16px 0 0'
						data-cy='select-source-enduser'
						searchable
						fontSize='small'
					/>
					<DateWrapper>
						<DatePicker
							label={`${t('wms:TriggerDate')}:`}
							placeholder={`${t('wms:SelectDate')}`}
							margin='0'
							timePicker={true}
							value={thresholdDate}
							onChange={e => {
								setThresholdDate(e)
							}}
							fontSize='small'
						/>
					</DateWrapper>
					<Input
						label={`${t('wms:NumberLTPA')}:`}
						placeholder={`${t('wms:NumberLTPA')}`}
						margin='0 16px 0 0'
						value={ltpaNumber}
						onChange={e => {
							setLtpaNumber(e.target.value)
						}}
						fontSize='small'
						width='150px'
					/>
					<Button
						fontSize='small'
						margin='auto 0 8px 0'
						padding='12px 16px'
						color='white'
						backgroundColor='secondary'
						width='calc(100% - 65% - 180px)'
						style={{ whiteSpace: 'break-spaces' }}
						disabled={!mayIssueList()}
						onClick={() => {
							onIssueAgeMgmtList()
						}}>
						{ageMgmtType ? `${t('wms:Issue')} ${t('wms:ListOf')} ${getListTranslateKey()}` : t('wms:Issue')}
					</Button>
					<Button
						title={t('wms:Download')}
						margin='auto 0 12px 16px'
						padding='0'
						backgroundColor='background'
						disabled={!canDownloadExtract}
						onClick={handleExtractDownload}
						elevation='none'>
						<Icon name='download' width='38px' height='38px' margin='0px' />
					</Button>
				</FlexView>
				<FlexView width='100%' flexWrap='wrap' flexDirection='row' aligntItems='center'>
					{issuedItems.length > 0 ? <AgeManagementItems issuedItems={issuedItems} height={tableHeight} /> : null}
				</FlexView>
				{issuedItems.length > 0 ? (
					<Button
						fontSize='small'
						margin='16px 0 8px auto'
						padding='12px 16px'
						color='white'
						backgroundColor='secondary'
						onClick={() => {
							onApproveIssuedList()
						}}>
						{`${t('wms:ApproveIssuedList')}`}
					</Button>
				) : (
					<FlexView
						width='100%'
						fontWeight='bold'
						fontSize='24px'
						color='lightGray'
						margin='auto 0'
						alignItems='center'
						justifyContent='center'
						data-cy='placeholder-pipes-list'>
						{t('wms:EmptyList')}
					</FlexView>
				)}
			</FlexView>
			<DialogModal
				isOpen={confirmListApproval}
				text={`${t('wms:ConfirmIssueList')} ${
					ageMgmtType !== null ? _.find(extracts, ex => ex.extractid === ageMgmtType).extractdesc : ''
				}?`}
				onConfirm={() => approveIssuedList() && setConfirmListApproval(false)}
				onCancel={() => setConfirmListApproval(false)}
			/>
		</PageTemplate>
	)
}

export default PipeAgeManagementPage
